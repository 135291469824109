// ----------------------------------------------------------------------------

// PLEASE DO NOT EDIT THIS FILE, IT IS GENERATED AND WILL BE OVERWRITTEN:
// https://github.com/ccxt/ccxt/blob/master/CONTRIBUTING.md#how-to-contribute-code
// EDIT THE CORRESPONDENT .ts FILE INSTEAD

import { SdkErrorCodes, SdkErrorMessages } from "./errorsMetadata.js";
/**
 * @description Base error for the SDK
 */
export class CedeSDKError extends Error {
    constructor(code, message) {
        super(message);
        this.code = code;
        this.message = message;
        this.isBaseError = true;
        this.metadata = {};
        this.notReportable = false;
        this.sentryName = undefined;
        this.name = "CedeSDKError";
    }
    addOriginalError(originalErrorMessage) {
        this.originalErrorMessage = originalErrorMessage;
        return this;
    }
    reportSentry() {
        const metadata = this.metadata;
        const sentry = this.sentry;
        // Clean metadata/sentry
        this.metadata = {};
        delete this.sentry;
        if (this.notReportable || !sentry) {
            return;
        }
        const clone = structuredClone(this);
        clone.name = this.sentryName || this.name;
        sentry.captureException(clone, {
            captureContext: {
                extra: {
                    ...metadata,
                    code: this.code,
                    exchangeId: this.exchangeId
                },
            },
        });
    }
    setSentryName(name) {
        this.sentryName = name;
    }
    setMetadata(metadata) {
        this.metadata = metadata;
    }
    addMetadata(key, value) {
        this.metadata[key] = value;
    }
    overwriteMessage(message) {
        this.message = message;
        return this;
    }
    addMessage(message) {
        this.message = `${this.message}. ${message}`;
        return this;
    }
    exchange(exchangeId) {
        this.exchangeId = exchangeId;
        return this;
    }
    apply({ shouldPrependExchangeId = false } = {}) {
        if (shouldPrependExchangeId && this.exchangeId) {
            this.message = `${this.exchangeId}: ${this.message}`;
        }
        this.reportSentry();
        return this;
    }
}
/**
 * @description Default error for the SDK
 */
export class DefaultError extends CedeSDKError {
    constructor(code = SdkErrorCodes.DEFAULT_ERROR, message = SdkErrorMessages.DEFAULT_ERROR) {
        super(code, message);
        this.name = "DefaultError";
    }
}
/**
 * @description Thrown when the the provided method params are invalid
 */
export class InvalidParamsError extends DefaultError {
    constructor(description) {
        super(SdkErrorCodes.INVALID_PARAMS, SdkErrorMessages.INVALID_PARAMS(description));
        this.name = "InvalidParamsError";
    }
}
/**
 * @description Thrown when the cache can't be persisted
 */
export class InvalidCacheError extends DefaultError {
    constructor(key) {
        super(SdkErrorCodes.INVALID_CACHE, SdkErrorMessages.INVALID_CACHE(key));
        this.name = "InvalidCacheError";
    }
}
/**
 * @description Thrown when we try to use the external cache and it's not set
 */
export class FetchFromCacheError extends DefaultError {
    constructor(message) {
        super(SdkErrorCodes.FETCH_FROM_CACHE_ERROR, SdkErrorMessages.FETCH_FROM_CACHE_ERROR(message));
        this.name = "FetchFromCacheError";
    }
}
/**
 * @description Thrown when the address is invalid
 */
export class InvalidAddressError extends DefaultError {
    constructor() {
        super(SdkErrorCodes.INVALID_ADDRESS, SdkErrorMessages.INVALID_ADDRESS);
        this.name = "InvalidAddressError";
    }
}
