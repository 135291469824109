import { useEffect, useRef, useState } from "react";
import { Any, userFacingMessages } from "@cede/types";
import { CEDE_CEX_LABELS, formatBalance } from "@cede/utils";
import { useDependencies } from "../../../hooks/useDependencies";
import { UseTradeRepeatParams } from "../types";
import { AlertTypes } from "@cedelabs/react-utils";

// @TODO: Change to Transaction Type

export const useRepeatTrade: (params: UseTradeRepeatParams) => {
  assetFromLoading: boolean;
  assetToLoading: boolean;
} = ({ useLocation, useAlert, dispatchForm, assetsFrom, assetsTo, tradeForm, resetAmounts, setFocusedInput }) => {
  const _location = useLocation();
  const { useAccounts } = useDependencies();
  const { droplistAccounts } = useAccounts();
  const _locationState = _location.state as Any;
  const prefillingForm = _locationState !== null;
  const [assetFromLoading, setAssetFromLoading] = useState<boolean>(prefillingForm);
  const [assetToLoading, setAssetToLoading] = useState<boolean>(prefillingForm);
  const hasBeenPrefilledRef = useRef(false);

  const setHasBeenPrefilled = (value: boolean) => {
    hasBeenPrefilledRef.current = value;
  };

  const _appendAlert = useAlert((state) => state.append);

  const _prefillSelectedCex = (transaction: Any) => {
    const selectedAccount = droplistAccounts?.find((account) => account.label === transaction.account.label);
    if (!selectedAccount) {
      return _appendAlert(userFacingMessages.TRANSACTION.ACCOUNT_NOT_FOUND, AlertTypes.ERROR);
    }
    dispatchForm({ field: "selectedAccount", value: selectedAccount });
  };

  const _prefillTokenFrom = (transaction: Any) => {
    const tokenFrom = assetsFrom.find((asset) => asset.label === transaction.currency.tokenSymbol);
    if (!tokenFrom) {
      return _appendAlert(userFacingMessages.TRANSACTION.TOKEN_FROM_NOT_FOUND, AlertTypes.ERROR);
    }
    dispatchForm({ field: "tokenFrom", value: tokenFrom });
  };

  const _prefillTokenToAndAmountFrom = (transaction: Any) => {
    const tokenTo = assetsTo.find((asset) => asset.label === transaction.tradeFields?.currencyTo.tokenSymbol);
    if (!tokenTo) {
      const exchangeId = CEDE_CEX_LABELS[tradeForm.selectedAccount?.cexValue || ""];
      return _appendAlert(
        `Token pair ${transaction.currency.tokenSymbol}/${transaction.tradeFields?.currencyTo.tokenSymbol} not found on ${exchangeId}`,
        AlertTypes.ERROR,
      );
    }
    dispatchForm({ field: "tokenTo", value: tokenTo });
    setFocusedInput("amountFrom");

    dispatchForm({ field: "amountFrom", value: formatBalance(transaction.currency.amount) });
  };

  // Complete some fields if we can prefill info
  useEffect(() => {
    if (!droplistAccounts) return;
    if (prefillingForm && droplistAccounts.length > 0 && !hasBeenPrefilledRef.current && !tradeForm.selectedAccount) {
      _prefillSelectedCex(_locationState.transaction);
    }
  }, [droplistAccounts]);

  useEffect(() => {
    if (prefillingForm && assetsFrom.length > 0 && !hasBeenPrefilledRef.current) {
      _prefillTokenFrom(_locationState.transaction);
    }
  }, [assetsFrom]);

  useEffect(() => {
    if (tradeForm.tokenFrom && tradeForm.tokenTo && tradeForm.amountFrom && tradeForm.selectedAccount) {
      setHasBeenPrefilled(true);
    }
  }, [tradeForm.tokenFrom, tradeForm.tokenTo, tradeForm.amountFrom, tradeForm.selectedAccount]);

  useEffect(() => {
    if (
      prefillingForm &&
      assetsTo.length > 0 &&
      tradeForm.tokenFrom?.label === _locationState.transaction.currency.tokenSymbol &&
      !hasBeenPrefilledRef.current
    ) {
      _prefillTokenToAndAmountFrom(_locationState.transaction);
    }
  }, [assetsTo]);

  // Set loading to false when amountTo is set
  useEffect(() => {
    if (prefillingForm && tradeForm.amountTo !== "0") {
      setAssetFromLoading(false);
      setAssetToLoading(false);
    }
  }, [tradeForm.amountTo]);

  useEffect(() => {
    if (!prefillingForm && !assetFromLoading && !assetToLoading) {
      resetAmounts();
    }
  }, [tradeForm.tokenFrom]);

  return {
    assetFromLoading,
    assetToLoading,
    prefillingForm,
  };
};
