import { SendWidgetTheme } from "@cede/types";
import { ThemeConfig, colors, lightColors, themeConfig } from "@cede/utils";

/**
 * @description Merge theme with widget theme
 */
export const inferTheme = (widgetTheme?: SendWidgetTheme): ThemeConfig => {
  const { palette, typography, custom } = themeConfig;
  const paletteConfig = palette(widgetTheme?.mode === "light" ? lightColors : colors, widgetTheme?.mode);
  return {
    ...themeConfig,
    palette: {
      ...paletteConfig,
      background: {
        ...paletteConfig.background,
        default: widgetTheme?.primaryColor ?? paletteConfig.background?.default,
        darkPaper: widgetTheme?.primaryColor ?? paletteConfig.background?.darkPaper,
        paper: widgetTheme?.secondaryColor ?? paletteConfig.background?.paper,
        component: widgetTheme?.secondaryColor ?? paletteConfig.background?.component,
      },
      text: {
        ...paletteConfig.text,
        primary: widgetTheme?.primaryTextColor ?? paletteConfig.text?.primary,
        secondary: widgetTheme?.secondaryTextColor ?? paletteConfig.text?.secondary,
      },
      error: {
        main: widgetTheme?.errorColor ?? paletteConfig.cedeErrorColor,
      },
      primary: {
        main: widgetTheme?.accentColor ?? paletteConfig.cedePrimaryColor,
      },
      warning: {
        main: widgetTheme?.warningColor ?? paletteConfig.cedeWarningColor,
      },
      cedeTextDisabled: widgetTheme?.primaryTextColor ?? paletteConfig.cedeTextDisabled,
      logoBorderColor: widgetTheme?.logoBorderColor ?? paletteConfig.logoBorderColor,
      cedeComponentHr: widgetTheme?.inputBorderColor ?? paletteConfig.cedeComponentHr,
    },
    typography: (palette) => ({
      ...typography(palette),
      fontFamily: widgetTheme?.fontFamily ?? typography(palette).fontFamily,
    }),
    shape: {
      borderRadius: widgetTheme?.borderRadius ?? themeConfig.shape.borderRadius,
    },
    custom: {
      ...custom,
      storeSize: {
        ...custom.storeSize,
        widgetWidth: widgetTheme?.width ?? custom.storeSize.widgetWidth,
      },
    },
    breakpoints: themeConfig.breakpoints,
  };
};
