import { PropsWithChildren } from "react";
import { Box, useTheme } from "@mui/material";

export const TransactionInformationsBlock = ({ children }: PropsWithChildren) => {
  const theme = useTheme();
  return (
    <Box
      display={"grid"}
      gridTemplateColumns={"repeat(2, auto)"}
      gap={theme.custom.gutterMargin}
      alignItems={"center"}
      justifyItems={"left"}
      paddingY={theme.custom.gutterMargin}
      sx={{
        "&:not(:last-of-type)": {
          borderBottom: `1px solid ${theme.palette.cedeComponentHr}`,
        },
      }}
    >
      {children}
    </Box>
  );
};
