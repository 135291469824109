import { useEffect, useMemo, useRef, useState } from "react";
import ccxt from "@cede/ccxt";
import { ValidationTextFieldRef, useNavBar } from "@cede/ds";
import { Any, ViewEnvironment, WithdrawToDefiParams, userFacingMessages } from "@cede/types";
import { CEDE_CEXS, CEDE_CEX_LABELS, QueryKeys, STORE_ROUTES, WIDGET_ROUTES, getErrorMessage } from "@cede/utils";
import { DropListWhitelistedAddress } from "../../../hooks";
import { useDependencies } from "../../../hooks/useDependencies";
import { AlertTypes, generateFakeSendTransactionFromStore, useLayout } from "../../../store";
import { HelpLink } from "../../HelpLink/HelpLink";
import { AddressInputType, SendForm, UseSendState } from "../types";
import { useSendApi } from "./useSendApi";
import { useSendValidation } from "./useSendValidation";
import { getTradePathLabel } from "./utils";
import { useMediaQuery, useTheme } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

type UseSendOpts = {
  addCex?: () => void;
  onSubmitDemo?: () => void;
};

export const useSend: (opts?: UseSendOpts) => UseSendState<SendForm> = (opts) => {
  const {
    useSendStore,
    useSettings,
    useLoading,
    backgroundHandler,
    useAlert,
    useVaults,
    useNavigate,
    useViewEnvironment,
    usePrice,
    useSelectedTransaction,
    useAddressInputValue,
    useSendMinAmount,
  } = useDependencies();
  const { environment, source, origin } = useViewEnvironment();
  const addBigPendingRequest = useLoading((state) => state.addBigPendingRequest);
  const removeBigPendingRequest = useLoading((state) => state.removeBigPendingRequest);
  const setDescription = useLoading((state) => state.setDescription);
  const navigate = useNavigate();
  const { activeVault } = useVaults();
  const appendAlert = useAlert((state) => state.append);
  const form = useSendStore();
  const setTransaction = useSelectedTransaction((state) => state.setTransaction);
  const setError = useSelectedTransaction((state) => state.setError);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { setTabContainerWidth, setTabContainerHeight, resetTabContainerSize } = useLayout();
  const isDemo = backgroundHandler?.isReal === false;
  const {
    areCurrenciesLoading,
    droplistCurrencies,
    fromAccounts,
    toAccounts,
    networksAreLoading,
    droplistNetworks,
    droplistAllNetworks,
    droplistAddressBook,
    droplistWhitelistedAddresses,
    isWhitelistedAddressesLoading,
    droplistDestinationTokens,
    droplistDestinationTokensAreLoading,
    droplistDestinationTokensError,
    refetchWhitelistedAddresses,
    isSendAllowed,
    isSendSupported,
    isReceiveSupported,
    areAccountsLoading,
    isDefiTab,
    isBetweenCexTab,
    disabledCexText,
    shouldDisableCex,
    shouldUseTradeAndSend,
    addressInputType,
    tradePath,
  } = useSendApi();

  const {
    validateAddress,
    validateAssetValue,
    isValid,
    displayWhitelistTooltip,
    isAddressWhitelisted,
    checkWhitelist,
    isTutorialCheckboxChecked,
    setIsTutorialCheckboxChecked,
    needWhitelist,
  } = useSendValidation({
    addressInputType,
    shouldUseTradeAndSend,
    supportedTokensAreLoading: droplistDestinationTokensAreLoading,
  });
  const { minAmount, minAmountIsLoading } = useSendMinAmount({
    shouldUseTradeAndSend,
    supportedTokensAreLoading: droplistDestinationTokensAreLoading,
  });
  const { currency } = useSettings();
  const { dispatchInput, dispatchPrefill, setTutorialIsOpen, tutorialIsOpen } = form;
  const [isTradeSendConfirmModalOpened, setTradeSendConfirmModalOpened] = useState(false);
  const tokenPrice = usePrice(form.currency?.value ?? "");
  const config = form.initialProps;
  const queryClient = useQueryClient();
  const validateAssetValueRef = useRef<ValidationTextFieldRef>();
  const [isRecipientInputFocused, setIsRecipientInputFocused] = useState(false);
  const isSendSupportedRef = useRef(isSendSupported); // temporary quickfix to avoid showing the loader when the send supported status is fetched.

  const { value: recipientAddress } = useAddressInputValue({
    address: form.recipientAddress ?? undefined,
    isFocused: isRecipientInputFocused,
  });

  const switchToMinimalLayout = async () => {
    // If the tutorial is open, we await the resetTabContainerSize to let the transition finish
    if ((displayWhitelistTooltip && !isMobile) || isMobile) {
      await resetTabContainerSize();
    } else resetTabContainerSize();
  };

  const { setGoBack, setRightSide } = useNavBar();
  const goBack = async () => {
    form.reset();
    await switchToMinimalLayout();
    return environment !== ViewEnvironment.WIDGET ? navigate(STORE_ROUTES.VAULT_DETAILS_ROUTE) : navigate(-1);
  };

  const handleAddAddressManually = () => {
    if (addressInputType === AddressInputType.WHITELIST) {
      // Open the whitelist tutorial
      setTutorialIsOpen(true);
      return;
    }

    // @TODO: Can't implement this on widget side for now
    if (environment === ViewEnvironment.WIDGET) return;

    navigate(STORE_ROUTES.ADD_ADDRESS_ROUTE, {
      state: { mode: "add" },
    });
  };

  const addCexRedirect = () => {
    if (opts?.addCex) {
      opts.addCex();
      return;
    }

    if (environment === ViewEnvironment.WIDGET) {
      backgroundHandler?.addCex({});
    }
  };

  const submit: UseSendState["submit"] = async (e) => {
    e.preventDefault();

    if (isDemo) {
      opts?.onSubmitDemo?.();
      return;
    }

    if (shouldUseTradeAndSend) {
      setTradeSendConfirmModalOpened(true);
      return;
    }

    addBigPendingRequest();

    if (isValid && activeVault && form.fromAccount && form.currency && form.recipientAddress && backgroundHandler) {
      await backgroundHandler
        .prepareWithdrawal({
          accountId: form.fromAccount?.value,
          tokenSymbol: form.currency?.value,
          amount: parseFloat(form.assetValue),
          network: form.network?.value,
          opts: {
            isInternalTransfer: config.isInternalTransfer,
          },
        })
        .catch((e: Error) => {
          appendAlert(getErrorMessage(e), AlertTypes.ERROR);
        });

      if (environment === ViewEnvironment.WIDGET) {
        setDescription(
          <>
            {userFacingMessages.WIDGETS.SEND.TRANSACTION_LOADING_FIRST_LINE}
            <br />
            {userFacingMessages.WIDGETS.SEND.TRANSACTION_LOADING_SECOND_LINE}
          </>,
        );
        try {
          if (!form.fromAccount || !form.currency || !form.assetValue || !form.recipientAddress || !form.network) {
            // TODO implement a way to throw custom errors on the frontend side
            throw new Error("Missing required fields");
          }

          const address =
            addressInputType === AddressInputType.WHITELIST &&
            form.fromAccount.cexValue === CEDE_CEXS.KRAKEN &&
            form.whitelistedAddress?.key
              ? form.whitelistedAddress?.key
              : form.recipientAddress;

          const params: WithdrawToDefiParams = {
            accountId: form.fromAccount.value,
            tokenSymbol: form.currency.value,
            amount: form.assetValue,
            address: address,
            network: form.network.value,
            isInternalTransfer: config.isInternalTransfer,
            metadata: {
              referrerSource: source,
              origin,
              clientTxId: config.clientTxId,
              callbackUrl: config.callbackUrl,
            },
          };
          if (form.tagValue !== "") {
            params.withdrawalTag = form.tagValue;
          }
          const transaction = await backgroundHandler.withdrawToDefi(params);
          backgroundHandler.emit("sendTxStatusUpdate", {
            status: "submitted",
          });
          setTransaction(transaction);
        } catch (e: Any) {
          backgroundHandler.emit("sendTxStatusUpdate", {
            status: "failed",
          });
          setError(getErrorMessage(e), e?.code);
          setTransaction(generateFakeSendTransactionFromStore(form, tokenPrice));
        } finally {
          setDescription(null);
          // Invalidate the withdrawable balances query to update the balances after the transaction
          queryClient.invalidateQueries({ queryKey: [QueryKeys.WITHDRAWABLE_CURRENCIES, form.fromAccount.value] });
          removeBigPendingRequest();
          navigate(WIDGET_ROUTES.COMPLETED_TRANSACTION_ROUTE);
        }
        return;
      }

      await switchToMinimalLayout();
      removeBigPendingRequest();
      navigate(STORE_ROUTES.CONFIRM_SEND_ROUTE);
    } else removeBigPendingRequest();
  };

  const onTradeSendModalContinue = () => {
    navigate(WIDGET_ROUTES.MULTIPLE_TRANSACTIONS);
  };

  useEffect(() => {
    if (addressInputType === AddressInputType.WHITELIST) {
      form.dispatchInput({ field: "recipientAddress", value: null });
      form.dispatchInput({ field: "whitelistedAddress", value: null });
    }
  }, [form.network, form.currency]);

  // Temporary quickfix to show the loader when the send supported status is fetched.
  // Otherwise the "Send will be availble soon is shown" is shown to the user.
  useEffect(() => {
    // we don't put the loader once we've fetched the send supported status
    if (isSendSupportedRef.current !== undefined) return;
    if (isSendSupported === undefined) {
      addBigPendingRequest();
    } else {
      removeBigPendingRequest();
      isSendSupportedRef.current = isSendSupported;
    }
  }, [isSendSupported]);

  const warningMessage = useMemo(() => {
    if (form.fromAccount && isSendSupported === false) {
      return `${CEDE_CEX_LABELS[form.fromAccount?.cexValue]} will be supported soon`;
    }

    if (form.toAccount && !isReceiveSupported) {
      return `${CEDE_CEX_LABELS[form.toAccount.cexValue]} will be supported soon`;
    }

    return "";
  }, [form.fromAccount, isSendAllowed, isSendSupported]);

  useEffect(() => {
    const selectedNetwork = form.network;
    if (selectedNetwork && !networksAreLoading && droplistNetworks.length) {
      // The network id might not change, properties like minWithdrawal could
      const updatedValue = droplistNetworks?.find((network) => network.network === selectedNetwork.network);
      dispatchInput({ field: "network", value: updatedValue });
    }
  }, [networksAreLoading, form.currency, droplistNetworks]);

  useEffect(() => {
    if (!tutorialIsOpen) refetchWhitelistedAddresses();
  }, [tutorialIsOpen]);

  const prefillTokenSymbol = () => {
    if (config?.tokenSymbol && form.shouldPrefill?.tokenSymbol && form.fromAccount) {
      droplistCurrencies?.forEach((currency) => {
        if (currency.label === config.tokenSymbol) {
          dispatchInput({ field: "currency", value: currency });
          dispatchPrefill({
            tokenSymbol: false,
          });

          if (config.amount) {
            dispatchInput({ field: "assetValue", value: config.amount });
            dispatchPrefill({
              amount: false,
            });
          }
        }
      });
    }
  };

  const prefillNetwork = () => {
    if (config?.network && droplistNetworks && form.shouldPrefill?.network) {
      droplistNetworks?.forEach((network) => {
        if (network.network === config.network || network.chainId === config.network) {
          dispatchInput({ field: "network", value: network });
          dispatchPrefill({
            network: false,
          });
        }
      });
    }
  };

  const prefillAddress = () => {
    if (config?.address && form.shouldPrefill?.address) {
      dispatchInput({ field: "recipientAddress", value: config.address });
      dispatchPrefill({
        address: false,
      });
    }
  };

  const prefillWhitelistedAddress = () => {
    if (isWhitelistedAddressesLoading) return;
    let address: DropListWhitelistedAddress | undefined = undefined;
    // If a prefiled address is provided, we check if it's whitelisted
    if (config?.address && form.shouldPrefill?.address) {
      address = droplistWhitelistedAddresses.find(
        (address) => address.address.toLowerCase() === (config.address ?? "").toLocaleLowerCase(),
      );
      dispatchPrefill({
        address: false,
      });
    } // Else, if a network and a currency are selected and only one whitelisted address is available, we prefill the address
    else if (form.network && form.currency && droplistWhitelistedAddresses.length === 1) {
      address = droplistWhitelistedAddresses[0];
    }
    if (!address) {
      form.dispatchInput({ field: "recipientAddress", value: "" });
      form.dispatchInput({
        field: "whitelistedAddress",
        value: null,
      });
      return;
    }
    form.dispatchInput({ field: "recipientAddress", value: address?.address });
    form.dispatchInput({
      field: "whitelistedAddress",
      value: address,
    });
  };

  const prefillAccount = () => {
    if (config?.exchangeIds && config.exchangeIds.length === 1 && form.shouldPrefill?.account) {
      fromAccounts.forEach((account) => {
        if (config.exchangeIds?.includes(account.cexValue)) {
          dispatchInput({ field: "fromAccount", value: account });
          dispatchPrefill({
            account: false,
          });
        }
      });
    }

    // we need to clean the account value if the account is not part of the exchangeIds
    // example: available accounts are gateio and kraken, but okx is loaded from the persistent state
    if (config?.exchangeIds && config.exchangeIds.length > 1 && form.shouldPrefill?.account && form.fromAccount) {
      if (!config.exchangeIds.includes(form.fromAccount.cexValue)) {
        dispatchInput({ field: "fromAccount", value: null });
        dispatchPrefill({
          account: false,
        });
      }
    }
  };

  useEffect(() => {
    prefillAccount();
  }, [fromAccounts, form.shouldPrefill?.account, config]);

  useEffect(() => {
    prefillTokenSymbol();
  }, [form.fromAccount, droplistCurrencies, form.shouldPrefill?.tokenSymbol, config]);

  useEffect(() => {
    prefillNetwork();
  }, [form.currency, droplistNetworks, form.shouldPrefill?.network, config]);

  useEffect(() => {
    if (addressInputType === undefined) return;
    if (addressInputType === AddressInputType.WHITELIST) prefillWhitelistedAddress();
    else prefillAddress();
  }, [
    form.shouldPrefill?.address,
    config,
    addressInputType,
    droplistWhitelistedAddresses,
    form.network,
    form.currency,
    isWhitelistedAddressesLoading,
  ]);

  useEffect(() => {
    if (shouldUseTradeAndSend && form.destinationCurrency) {
      if (tradePath && tradePath[0]) {
        appendAlert(
          userFacingMessages.WIDGETS.TRADE_SEND.PERFORM_TRADE_PATH(
            getTradePathLabel(tradePath),
            form.network?.label || "",
          ),
          AlertTypes.INFO,
        );
        return;
      }
    }
  }, [shouldUseTradeAndSend, form.destinationCurrency, tradePath]);

  useEffect(() => {
    if (droplistDestinationTokensError) {
      appendAlert(getErrorMessage(droplistDestinationTokensError.message), AlertTypes.ERROR);
    }
  }, [droplistDestinationTokensError]);

  useEffect(() => {
    if (
      shouldUseTradeAndSend &&
      (!form.destinationCurrency ||
        // We want to change the destination currency to the first supported token if the selected one is not supported
        !droplistDestinationTokens.some((token) => token.value === form.destinationCurrency?.value))
    ) {
      dispatchInput({
        field: "destinationCurrency",
        value: droplistDestinationTokens[0],
      });
    }
  }, [droplistDestinationTokens, shouldUseTradeAndSend, form.destinationCurrency, form.network]);

  useEffect(() => {
    setGoBack(goBack);
  }, [displayWhitelistTooltip, isMobile]);

  useEffect(() => {
    setTransaction(null);
  }, []);

  useEffect(() => {
    if (!validateAssetValueRef.current) return;

    if ((form.network && !form.currency) || !minAmount || form.assetValue === "") {
      validateAssetValueRef.current.cancelValidation();
      return;
    }

    validateAssetValueRef.current.validate();
  }, [form.network, form.currency, minAmount]);

  useEffect(() => {
    if (!isMobile && displayWhitelistTooltip && environment) {
      setTabContainerWidth(theme.custom.storeSize.bigTabWidth);
      setTabContainerHeight(theme.custom.storeSize.bigTabHeight);

      if (environment !== ViewEnvironment.WIDGET) {
        setRightSide(null);
      }
    } else if (isMobile && environment) {
      setTabContainerWidth("100vw");
      setTabContainerHeight("100vh");

      if (environment !== ViewEnvironment.WIDGET) {
        setRightSide(<HelpLink />);
      }
    }

    return () => {
      resetTabContainerSize();

      if (environment && environment !== ViewEnvironment.WIDGET) {
        setRightSide(<HelpLink />);
      }
    };
  }, [isMobile, displayWhitelistTooltip, environment]);

  useEffect(() => {
    if (backgroundHandler) {
      form.setIsDemo(isDemo);
    }
  }, [isDemo, backgroundHandler]);

  return {
    // #Form
    form,
    dispatchInput,
    switchSelectedCexs: form.switchAccounts,
    setMaxAssetValue: form.setMaxAssetValue,
    submit,
    setIsRecipientInputFocused,

    // #Validation
    validateAddress,
    validateAssetValue,
    isValid,
    displayWhitelistTooltip,
    isAddressWhitelisted,
    checkWhitelist,
    validateAssetValueRef,
    isTutorialCheckboxChecked,
    setIsTutorialCheckboxChecked,

    // #Data
    fromAccounts:
      config?.exchangeIds && config.exchangeIds.length > 0
        ? fromAccounts.filter((account) => config.exchangeIds?.includes(account.cexValue))
        : fromAccounts,
    toAccounts,
    currencies: droplistCurrencies ?? [],
    currenciesAreLoading: areCurrenciesLoading,
    areAccountsLoading,
    networks:
      config?.network && config?.lockNetwork
        ? droplistNetworks.filter((network) => network.network === config.network)
        : droplistNetworks,
    allNetworks: droplistAllNetworks,
    networksAreLoading,
    droplistAddressBook,
    droplistWhitelistedAddresses,
    fiatCurrency: currency,
    isOauth: form.fromAccount?.isOauth,
    needWhitelist,
    isDefiTab,
    isBetweenCexTab,
    disabledCexText,
    shouldDisableCex,
    recipientAddress,
    addressInputType,
    isWhitelistedAddressesLoading,
    droplistDestinationTokens,
    droplistDestinationTokensError,
    droplistDestinationTokensAreLoading,
    shouldUseTradeAndSend: !!shouldUseTradeAndSend,
    minAmount,
    minAmountIsLoading,
    tradePath,
    refValue: isDemo ? ccxt.Precise.stringMul(form.assetValue, String(tokenPrice)) : form.refValue,

    // #Send logic
    tutorialIsOpen,
    setTutorialIsOpen,
    warningMessage,
    handleAddAddressManually,
    isTradeSendConfirmModalOpened,
    setTradeSendConfirmModalOpened,
    onTradeSendModalContinue,

    // #Add CEX
    addCexRedirect,

    // #Demo
    isDemo,
  };
};
