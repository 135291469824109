import { UserRewardState } from "@cede/types";
import { forgeNetworkURL } from "../cdn-client";
import { CEDE_CEXS } from "../cexs";
import { WALLET_TYPES_TEST_CONFIG } from "../configs";
import { EXISTING_FIAT_CURRENCIES } from "../fiat";
import { API_ENDPOINT } from "./types";

export const API_MOCK_DATA = {
  [API_ENDPOINT.EXCHANGES_STATUS]: [
    {
      id: CEDE_CEXS.COINBASE,
      name: "Coinbase",
      status: "ok",
    },
    {
      id: CEDE_CEXS.BINANCE,
      name: "Binance",
      status: "ok",
    },
    {
      id: CEDE_CEXS.KRAKEN,
      name: "Kraken",
      status: "ok",
    },
    {
      id: CEDE_CEXS.GATEIO,
      name: "Gate.io",
      status: "ok",
    },
    {
      id: CEDE_CEXS.BITFINEX,
      name: "Bitfinex",
      status: "ok",
    },
    {
      id: CEDE_CEXS.HTX,
      name: "HTX",
      status: "ok",
    },
    {
      id: CEDE_CEXS.KUCOIN,
      name: "KuCoin",
      status: "ok",
    },
    {
      id: CEDE_CEXS.BITSTAMP,
      name: "Bitstamp",
      status: "ok",
    },
    {
      id: CEDE_CEXS.OKX,
      name: "OKX",
      status: "ok",
    },
    {
      id: CEDE_CEXS.BITGET,
      name: "Bitget",
      status: "ok",
    },
    {
      id: CEDE_CEXS.BITMART,
      name: "BitMart",
      status: "ok",
    },
    {
      id: CEDE_CEXS.BYBIT,
      name: "Bybit",
      status: "ok",
    },
  ],
  [API_ENDPOINT.TOKEN_NAMES_AND_SYMBOLS]: [
    {
      symbol: "zoc",
      name: "01coin",
    },
    {
      symbol: "zcn",
      name: "Zus",
    },
    {
      symbol: "0kn",
      name: "0 Knowledge Network",
    },
    {
      symbol: "vix",
      name: "0VIX Protocol",
    },
    {
      symbol: "zrx",
      name: "0x Protocol",
    },
    {
      symbol: "0x0",
      name: "0x0.ai: AI Smart Contract",
    },
    {
      symbol: "0x1",
      name: "0x1.tools: AI Multi-tool",
    },
    {
      symbol: "0xa",
      name: "0xAuto.io : Contract Auto Deployer",
    },
    {
      symbol: "coco",
      name: "0xCoco",
    },
    {
      symbol: "oxd",
      name: "0xDAO",
    },
    {
      symbol: "oxd v2",
      name: "0xDAO V2",
    },
    {
      symbol: "cafe",
      name: "0xDEFCAFE",
    },
    {
      symbol: "0xf",
      name: "0xFriend",
    },
    {
      symbol: "0xgas",
      name: "0xGasless",
    },
    {
      symbol: "0xmr",
      name: "0xMonero",
    },
    {
      symbol: "$0xs",
      name: "0xS",
    },
    {
      symbol: "shield",
      name: "0xShield",
    },
    {
      symbol: "0xs",
      name: "0xSniper",
    },
    {
      symbol: "tshp",
      name: "12Ships",
    },
    {
      symbol: "1art",
      name: "OneArt",
    },
    {
      symbol: "1eco",
      name: "1eco",
    },
    {
      symbol: "water",
      name: "1Hive Water",
    },
    {
      symbol: "1inch",
      name: "1inch",
    },
    {
      symbol: "yv1inch",
      name: "1INCH yVault",
    },
    {
      symbol: "1mil",
      name: "1MillionNFTs",
    },
    {
      symbol: "1mb",
      name: "1minBET",
    },
    {
      symbol: "1mt",
      name: "1Move Token",
    },
    {
      symbol: "1peco",
      name: "1peco",
    },
    {
      symbol: "1rt",
      name: "1Reward Token",
    },
    {
      symbol: "safu",
      name: "1SAFU",
    },
    {
      symbol: "1sol",
      name: "1Sol",
    },
    {
      symbol: "1sol",
      name: "1sol.io (Wormhole)",
    },
    {
      symbol: "₿",
      name: "₿",
    },
    {
      symbol: "2049",
      name: "2049",
    },
    {
      symbol: "20weth-80bal",
      name: "20WETH-80BAL",
    },
    {
      symbol: "vck",
      name: "28VCK",
    },
    {
      symbol: "arms",
      name: "2ACoin",
    },
    {
      symbol: "2crz",
      name: "2crazyNFT",
    },
    {
      symbol: "2dai",
      name: "2DAI.io",
    },
    {
      symbol: "2gcc",
      name: "2G Carbon Coin",
    },
    {
      symbol: "2omb",
      name: "2omb",
    },
    {
      symbol: "2shares",
      name: "2SHARE",
    },
  ],
  [API_ENDPOINT.FIAT_CURRENCIES]: [
    {
      symbol: "USD",
      name: "United States Dollar",
      symbolNative: "$",
    },
    {
      symbol: "EUR",
      name: "Euro",
      symbolNative: "€",
    },
    {
      symbol: "GBP",
      name: "British Pound Sterling",
      symbolNative: "£",
    },
  ],
  [API_ENDPOINT.STANDARDIZED_CHAINS]: {
    binance: [
      {
        ecid: "ETH",
        icid: "ethereum",
        icn: "Ethereum",
        chainId: 1,
      },
      {
        ecid: "DOT",
        icid: "polkadot",
        icn: "Polkadot",
        chainId: 2,
      },
      {
        ecid: "AVAX",
        icid: "avalanche",
        icn: "Avalanche",
        chainId: 3,
      },
    ],
    coinbase: [
      {
        ecid: "ethereum",
        icid: "ethereum",
        icn: "Ethereum",
        chainId: 1,
      },
    ],
    okx: [
      {
        ecid: "ERC20",
        icid: "ethereum",
        icn: "Ethereum",
        chainId: 1,
      },
    ],
  },
  [API_ENDPOINT.PRICES]: {
    data: {
      updatedAt: "2021-09-20T08:51:12.000Z",
      tokens: [
        {
          tokenSymbol: "BTC",
          average: 26854.01620548817,
          exchanges: {
            [CEDE_CEXS.BITSTAMP]: 26859.833,
            [CEDE_CEXS.COINBASE]: 26840.316203576476,
            [CEDE_CEXS.BITGET]: 26856.019189999995,
            [CEDE_CEXS.KUCOIN]: 26985.197453932367,
            [CEDE_CEXS.OKX]: 26790.1762308,
            [CEDE_CEXS.BITFINEX]: 26857.831,
            [CEDE_CEXS.BYBIT]: 26829.1276505092,
            [CEDE_CEXS.HTX]: 26855.298469999998,
            [CEDE_CEXS.KRAKEN]: 26840.1,
            [CEDE_CEXS.GATEIO]: 26857.630799999995,
            [CEDE_CEXS.BITMART]: 26835.50246841,
            [CEDE_CEXS.BINANCE]: 26841.16199863,
          },
        },
        {
          tokenSymbol: "ETH",
          average: 1638.3047911131491,
          exchanges: {
            [CEDE_CEXS.BITSTAMP]: 1638.5368999999998,
            [CEDE_CEXS.COINBASE]: 1637.67902,
            [CEDE_CEXS.BITGET]: 1637.74500528,
            [CEDE_CEXS.KUCOIN]: 1640.448408955305,
            [CEDE_CEXS.OKX]: 1637.67902,
            [CEDE_CEXS.BITFINEX]: 1638.8372,
            [CEDE_CEXS.BYBIT]: 1637.7762899224856,
            [CEDE_CEXS.HTX]: 1639.2075699999998,
            [CEDE_CEXS.KRAKEN]: 1637.88,
            [CEDE_CEXS.GATEIO]: 1639.1274899999999,
            [CEDE_CEXS.BITMART]: 1637.27651,
            [CEDE_CEXS.BINANCE]: 1637.4640791999998,
          },
        },
        {
          tokenSymbol: "AVAX",
          average: 9.455109874685059,
          exchanges: {
            [CEDE_CEXS.BITSTAMP]: 9.473832728420724,
            [CEDE_CEXS.GATEIO]: 9.448,
            [CEDE_CEXS.BYBIT]: 9.4598918778,
            [CEDE_CEXS.BITFINEX]: 9.453,
            [CEDE_CEXS.HTX]: 9.4524,
            [CEDE_CEXS.OKX]: 9.445359,
            [CEDE_CEXS.KRAKEN]: 9.46,
            [CEDE_CEXS.BITGET]: 9.4516,
            [CEDE_CEXS.KUCOIN]: 9.444792210000001,
            [CEDE_CEXS.COINBASE]: 9.454222679999999,
            [CEDE_CEXS.BINANCE]: 9.46242,
            [CEDE_CEXS.BITMART]: 9.4558,
          },
        },
        {
          tokenSymbol: "USDT",
          average: 0.995892009896678,
          exchanges: {
            [CEDE_CEXS.BITSTAMP]: 0.9994891104662439,
            [CEDE_CEXS.GATEIO]: 0.998761239,
            [CEDE_CEXS.BYBIT]: 1.0007220978867657,
            [CEDE_CEXS.BITFINEX]: 1.0004608327,
            [CEDE_CEXS.OKX]: 0.999978,
            [CEDE_CEXS.KRAKEN]: 0.9999809474200001,
            [CEDE_CEXS.BITGET]: 0.9996880566905006,
            [CEDE_CEXS.KUCOIN]: 0.9613928192032687,
            [CEDE_CEXS.COINBASE]: 1.0001779956,
            [CEDE_CEXS.BINANCE]: 0.998269,
          },
        },
        {
          tokenSymbol: "LINK",
          average: 7.521685779649785,
          exchanges: {
            [CEDE_CEXS.BITSTAMP]: 7.5147387,
            [CEDE_CEXS.GATEIO]: 7.5317,
            [CEDE_CEXS.BYBIT]: 7.5281343774,
            [CEDE_CEXS.BITFINEX]: 7.5375,
            [CEDE_CEXS.HTX]: 7.5327,
            [CEDE_CEXS.OKX]: 7.5068532,
            [CEDE_CEXS.KRAKEN]: 7.513076760000001,
            [CEDE_CEXS.BITGET]: 7.511691809999999,
            [CEDE_CEXS.KUCOIN]: 7.521034533600001,
            [CEDE_CEXS.COINBASE]: 7.51917054,
            [CEDE_CEXS.BINANCE]: 7.510729434797414,
            [CEDE_CEXS.BITMART]: 7.5329,
          },
        },
        {
          tokenSymbol: "BNB",
          average: 212.78111366,
          exchanges: {
            [CEDE_CEXS.GATEIO]: 213,
            [CEDE_CEXS.BYBIT]: 212.8291,
            [CEDE_CEXS.HTX]: 213.04,
            [CEDE_CEXS.OKX]: 212.83531752000002,
            [CEDE_CEXS.BITGET]: 212.98,
            [CEDE_CEXS.KUCOIN]: 212.51532456,
            [CEDE_CEXS.BINANCE]: 212.4316432,
            [CEDE_CEXS.BITMART]: 212.617524,
          },
        },
        {
          tokenSymbol: "DOT",
          average: 3.771710283627618,
          exchanges: {
            [CEDE_CEXS.BITSTAMP]: 3.7801075875314116,
            [CEDE_CEXS.GATEIO]: 3.7784,
            [CEDE_CEXS.BYBIT]: 3.777916884,
            [CEDE_CEXS.BITFINEX]: 3.7676,
            [CEDE_CEXS.HTX]: 3.7736,
            [CEDE_CEXS.OKX]: 3.76762023,
            [CEDE_CEXS.KRAKEN]: 3.7692799200000002,
            [CEDE_CEXS.BITGET]: 3.7768,
            [CEDE_CEXS.KUCOIN]: 3.768917082,
            [CEDE_CEXS.COINBASE]: 3.7559843999999996,
            [CEDE_CEXS.BINANCE]: 3.7691973,
            [CEDE_CEXS.BITMART]: 3.7751,
          },
        },
      ],
    },
  },
  [API_ENDPOINT.SUPPORTED_TOKENS]: {
    kraken: [
      {
        tokenSymbol: "1INCH",
        isDepositable: true,
        isWithdrawable: true,
        networks: [
          {
            name: "Ethereum",
            network: "ethereum",
            depositEnabled: true,
            withdrawalEnabled: true,
            withdrawFee: "3.82",
            withdrawMin: "2",
            withdrawMax: "22513350",
            chainId: 1,
            iconUrl: forgeNetworkURL("ethereum"),
          },
        ],
      },
    ],
    okx: [
      {
        tokenSymbol: "USDT",
        isDepositable: true,
        isWithdrawable: true,
        networks: [
          {
            name: "OKT Chain",
            network: "oktchain",
            depositEnabled: true,
            withdrawalEnabled: false,
            withdrawFee: "0",
            withdrawMin: "0",
            withdrawMax: "22513350",
            chainId: 66,
            iconUrl: forgeNetworkURL("oktchain"),
          },
          {
            name: "Ethereum",
            network: "ethereum",
            depositEnabled: true,
            withdrawalEnabled: true,
            withdrawFee: "3.82",
            withdrawMin: "2",
            withdrawMax: "22513350",
            chainId: 1,
            iconUrl: forgeNetworkURL("ethereum"),
          },
        ],
      },
      {
        tokenSymbol: "USDC",
        isDepositable: true,
        isWithdrawable: true,
        networks: [
          {
            name: "OKT Chain",
            network: "oktchain",
            depositEnabled: true,
            withdrawalEnabled: false,
            withdrawFee: "0",
            withdrawMin: "0",
            withdrawMax: "22531375",
            chainId: 66,
            iconUrl: forgeNetworkURL("oktchain"),
          },
          {
            name: "Ethereum",
            network: "ethereum",
            depositEnabled: true,
            withdrawalEnabled: true,
            withdrawFee: "11.52",
            withdrawMin: "1",
            withdrawMax: "22531375",
            chainId: 1,
            iconUrl: forgeNetworkURL("ethereum"),
          },
        ],
      },
    ],
  },
  [API_ENDPOINT.SUPPORTED_TOKENS_BY_EXCHANGE]: [
    "AGLD",
    "STPT",
    "MXN",
    "MATICUSDCE",
    "UGX",
    "GLM",
    "RAY",
    "NEAR",
    "AUDIO",
    "WLD",
    "FDUSD",
    "FARM",
    "SDG",
    "XNO",
    "AVAOLD",
    "DGB",
    "ZRX",
    "SANTOS",
    "WING",
    "WNXM",
    "BCH",
    "AI",
    "JST",
    "HOT",
    "XOF",
    "GMT",
    "AR",
    "IRIS",
    "GMX",
    "SEI",
    "GNF",
    "QAR",
    "JTO",
    "FLM",
    "GNO",
    "VITE",
    "GNS",
    "VANRY",
    "CFX",
    "T",
    "LYD",
    "KWD",
    "SFP",
    "DIA",
    "ACA",
    "ISK",
    "ARDR",
    "ACE",
    "ACH",
    "BEL",
    "JUV",
    "ACM",
    "MINA",
    "TIA",
    "VTHO",
    "PAB",
    "WOO",
    "REI",
    "BLUR",
    "REN",
    "ADA",
    "ELF",
    "REQ",
    "STORJ",
    "DF",
    "RARE",
    "PAXG",
    "PAX",
    "CHR",
    "VND",
    "WAVES",
    "CHZ",
    "ADX",
    "XRP",
    "PGALA",
    "FLOKI",
    "JASMY",
    "TJS",
    "AED",
    "FIDA",
    "SAND",
    "OCEAN",
    "FOR",
    "UMA",
    "SCRT",
    "TUSD",
    "TKO",
    "IDRT",
    "BHD",
    "ENJ",
    "KZT",
    "OAX",
    "GRT",
    "AFN",
    "HARD",
    "TFUEL",
    "ENS",
    "TROY",
    "UNI",
    "TLM",
    "HUF",
    "CKB",
    "WRX",
    "LUNC",
    "XTZ",
    "LUNA",
    "FUSDC",
    "BCHA",
    "EOS",
    "RIF",
    "SKL",
    "GTC",
    "PEN",
    "SOLO",
    "TMT",
    "BURGER",
    "HOOK",
    "XVG",
    "EPS",
    "TND",
    "CLV",
    "EPX",
    "FLOW",
    "XVS",
    "MEME",
    "STEEM",
    "SLP",
    "VRT",
    "ID",
    "DOP",
    "LAZIO",
    "DOT",
    "IQ",
    "1INCH",
    "CHESS",
    "MAD",
    "DEGO",
    "GYEN",
    "UNFI",
    "FTM",
    "POWR",
    "ERN",
    "MAV",
    "FTT",
    "VOXEL",
    "PHA",
    "RLC",
    "BEAMX",
    "PHB",
    "ARKM",
    "ATOM",
    "PENDLE",
    "QUICK",
    "BLZ",
    "MBL",
    "SNT",
    "PHP",
    "SNX",
    "FUN",
    "COP",
    "COS",
    "API3",
    "QKC",
    "ROSE",
    "GLMR",
    "SOL",
    "ETB",
    "CITY",
    "ETC",
    "BNB",
    "CELR",
    "OGN",
    "LAK",
    "ETH",
    "BND",
    "NEO",
    "CELO",
    "KLAY",
    "AUCTION",
    "TUSDOLD",
    "BADGER",
    "MULTI",
    "HIGH",
    "TRB",
    "BNT",
    "LBA",
    "BNX",
    "UTK",
    "ORDI",
    "WSOL",
    "MDL",
    "AMB",
    "TRU",
    "WBNB",
    "AMD",
    "DREP",
    "NFP",
    "TRY",
    "TRX",
    "MDT",
    "NFT",
    "MDX",
    "AERGO",
    "EUR",
    "AMP",
    "HIFI",
    "NULS",
    "USTC",
    "RON",
    "RDNTOLD",
    "NGN",
    "CRC",
    "PKR",
    "EGLD",
    "PUNDIX",
    "SPELL",
    "FXS",
    "PLA",
    "EVX",
    "CRV",
    "BAKE",
    "ANT",
    "FLUX",
    "RPL",
    "TORN",
    "PLN",
    "MAGIC",
    "LDO",
    "QNT",
    "ALICE",
    "OG",
    "ETHW",
    "OM",
    "AEUR",
    "OP",
    "BTTOLD",
    "BETH",
    "WETH",
    "BETA",
    "APE",
    "SSV",
    "BUSD",
    "CTK",
    "ARPA",
    "BRL",
    "ALCX",
    "MATIC",
    "APT",
    "STG",
    "IOTX",
    "SHIB",
    "FRONT",
    "KDA",
    "ZAR",
    "QUICKOLD",
    "DOCK",
    "STX",
    "PNT",
    "QI",
    "DENT",
    "MBOX",
    "TWD",
    "IOST",
    "SUI",
    "CAKE",
    "BSW",
    "WBETH",
    "OMG",
    "BAND",
    "SUN",
    "ASTR",
    "TRXOLD",
    "BTC",
    "TWT",
    "NKN",
    "RSR",
    "UYU",
    "ARB",
    "IOTA",
    "CVC",
    "REEF",
    "OMR",
    "KES",
    "ARK",
    "LOKA",
    "CVP",
    "KEY",
    "ARS",
    "CVX",
    "ONE",
    "ONG",
    "ANKR",
    "SUSHI",
    "ALGO",
    "UZS",
    "SC",
    "WBTC",
    "ONT",
    "BTTC",
    "RUB",
    "PIVX",
    "ASR",
    "AST",
    "MANA",
    "ATA",
    "DZD",
    "NMR",
    "MKR",
    "DODO",
    "LIT",
    "KGS",
    "ICP",
    "ATM",
    "JEX",
    "XAF",
    "ICX",
    "POLYX",
    "XAI",
    "LOOM",
    "KP3R",
    "DOGE",
    "DUSK",
    "ALPHA",
    "HBAR",
    "SXP",
    "RVN",
    "TZS",
    "MLN",
    "PEPE",
    "AUD",
    "KHR",
    "CTSI",
    "BWP",
    "KAVA",
    "C98",
    "OSMO",
    "PSG",
    "SYN",
    "MMK",
    "VIDT",
    "NOK",
    "AVA",
    "SYS",
    "STRAX",
    "GAL",
    "CZK",
    "GAS",
    "COMBO",
    "THETA",
    "WAN",
    "ORN",
    "PERL",
    "NEXO",
    "MASK",
    "AAVE",
    "MNT",
    "GBP",
    "BONK",
    "PERP",
    "BYN",
    "BOND",
    "YFI",
    "LQTY",
    "BICO",
    "XEC",
    "YGG",
    "PEOPLE",
    "AXS",
    "ZIL",
    "VAI",
    "XEM",
    "CTXC",
    "BIDR",
    "COMP",
    "HFT",
    "OOKI",
    "RUNE",
    "FORTH",
    "KMD",
    "GHST",
    "ALPINE",
    "LEVER",
    "IDEX",
    "GEL",
    "DEXE",
    "AVAX",
    "AZN",
    "UAH",
    "KNC",
    "PROS",
    "PROM",
    "BGBP",
    "LPT",
    "HIVE",
    "GFT",
    "BIFI",
    "RDNT",
    "PORTO",
    "OPUSDCE",
    "PYR",
    "WAXP",
    "DAI",
    "DAR",
    "FET",
    "LRC",
    "VET",
    "MTL",
    "ALPACA",
    "USDT",
    "OXT",
    "USDP",
    "DASH",
    "ILS",
    "ILV",
    "GHS",
    "EDU",
    "JOD",
    "HKD",
    "JOE",
    "CAN",
    "DCR",
    "CREAM",
    "DATA",
    "IMX",
    "USDC",
    "LTC",
    "WIN",
    "INJ",
    "TCT",
    "SAR",
    "LTO",
    "VGX",
    "DYDX",
    "EFI",
    "AGIX",
    "POND",
    "LINA",
    "1000SATS",
    "XLM",
    "LINK",
    "QTUM",
    "CYBER",
    "UFT",
    "SUPER",
    "POLS",
    "KSM",
    "ARBUSDCE",
    "FIL",
    "STMX",
    "RNDR",
    "BAL",
    "FIO",
    "GALA",
    "VIB",
    "VIC",
    "FIS",
    "BAR",
    "RAD",
    "BAT",
    "AKRO",
    "NZD",
    "MOVR",
    "COTI",
  ],
  [API_ENDPOINT.SUPPORTED_NETWORKS_BY_EXCHANGE]: [
    {
      name: "Ethereum",
      network: "ethereum",
      withdrawFee: "335",
      withdrawMax: "10000000000",
      withdrawMin: "670",
      depositEnabled: true,
      withdrawalEnabled: true,
      chainId: 1,
      iconUrl: forgeNetworkURL("ethereum"),
    },
    {
      name: "Polygon",
      network: "polygon",
      withdrawFee: "1",
      withdrawMax: "100000000",
      withdrawMin: "10",
      depositEnabled: true,
      withdrawalEnabled: true,
      chainId: 137,
      iconUrl: forgeNetworkURL("polygon"),
    },
    {
      name: "Solana",
      network: "solana",
      withdrawFee: "1",
      withdrawMax: "250000000",
      withdrawMin: "10",
      depositEnabled: true,
      withdrawalEnabled: true,
      chainId: 1399811149,
      iconUrl: forgeNetworkURL("solana"),
    },
    {
      name: "BNB Smart Chain",
      network: "bsc",
      withdrawFee: "6.28",
      withdrawMax: "10000000000",
      withdrawMin: "12",
      depositEnabled: true,
      withdrawalEnabled: true,
      chainId: 56,
      iconUrl: forgeNetworkURL("bsc"),
    },
    {
      name: "Near Protocol",
      network: "near",
      withdrawFee: "1",
      withdrawMax: "9999999",
      withdrawMin: "10",
      depositEnabled: true,
      withdrawalEnabled: true,
      iconUrl: forgeNetworkURL("near"),
    },
    {
      name: "BNB Beacon Chain",
      network: "bnb",
      withdrawFee: "2.61",
      withdrawMax: "10000000000",
      withdrawMin: "5.22",
      depositEnabled: true,
      withdrawalEnabled: true,
      iconUrl: forgeNetworkURL("bnb"),
    },
  ],
  [API_ENDPOINT.ALL_FIAT_CURRENCIES]: EXISTING_FIAT_CURRENCIES,
  [API_ENDPOINT.TRADE_PATH]: [
    {
      from: "NEAR",
      to: "BNB",
      orderSide: "sell",
      pairSymbol: "NEAR/BNB",
      precision: {
        amount: 1e-7,
        price: 1e-7,
      },
    },
    {
      from: "BNB",
      to: "SUI",
      orderSide: "sell",
      pairSymbol: "BNB/SUI",
      precision: {
        amount: 1e-7,
        price: 1e-7,
      },
    },
  ],
  [API_ENDPOINT.PARTNERS]: [],
  [API_ENDPOINT.KRAKEN_WITHDRAWAL_NETWORKS_INFO]: [
    {
      asset: "ZUSD",
      method: "Bank Frick (SWIFT) Europe",
      network: null,
      minimum: "100.00",
    },
    {
      asset: "ZEUR",
      method: "Bank Frick (SWIFT) Europe",
      network: null,
      minimum: "100.00",
    },
    {
      asset: "ZEUR",
      method: "Banking Circle (SEPA & SEPA Instant)",
      network: null,
      minimum: "2.00",
    },
    {
      asset: "ZGBP",
      method: "Bank Frick (SWIFT) Europe",
      network: null,
      minimum: "100.00",
    },
    {
      asset: "ZCAD",
      method: "Bank Frick (SWIFT) Europe",
      network: null,
      minimum: "100.00",
    },
    {
      asset: "ZJPY",
      method: "Bank Frick (SWIFT) Europe",
      network: null,
      minimum: "15000.00",
    },
    {
      asset: "CHF",
      method: "Bank Frick (SWIFT) Europe",
      network: null,
      minimum: "100.00",
    },
    {
      asset: "XXBT",
      method: "Bitcoin",
      network: "Bitcoin",
      minimum: "0.0004",
    },
    {
      asset: "XETH",
      method: "Ether",
      network: "Ethereum",
      minimum: "0.005",
    },
    {
      asset: "XETH",
      method: "Arbitrum One",
      network: "Arbitrum One",
      minimum: "0.0020",
    },
    {
      asset: "XETH",
      method: "Ethereum (Polygon)",
      network: "Polygon",
      minimum: "0.0020",
    },
    {
      asset: "XETH",
      method: "Arbitrum Nova",
      network: "Arbitrum Nova",
      minimum: "0.0020",
    },
    {
      asset: "XETH",
      method: "Optimism",
      network: "Optimism",
      minimum: "0.0020",
    },
    {
      asset: "XETH",
      method: "zkSync Era",
      network: "zkSync Era",
      minimum: "0.0031",
    },
    {
      asset: "XETC",
      method: "Ether Classic",
      network: "Ethereum Classic",
      minimum: "0.01",
    },
    {
      asset: "USDT",
      method: "Tether USD (ERC20)",
      network: "Ethereum",
      minimum: "16.00",
    },
    {
      asset: "USDT",
      method: "Tether USD (TRC20)",
      network: "Tron",
      minimum: "6.00",
    },
    {
      asset: "USDT",
      method: "Tether USD (SPL)",
      network: "Solana",
      minimum: "2.00",
    },
    {
      asset: "USDT",
      method: "Tether USD (Polygon)",
      network: "Polygon",
      minimum: "2.00",
    },
    {
      asset: "USDT",
      method: "Arbitrum One Network",
      network: "Arbitrum One",
      minimum: "4.00",
    },
    {
      asset: "USDT",
      method: "Optimism",
      network: "Optimism",
      minimum: "2.50",
    },
    {
      asset: "LINK",
      method: "Link",
      network: "Ethereum",
      minimum: "1.50",
    },
    {
      asset: "DOT",
      method: "Polkadot",
      network: "Polkadot",
      minimum: "1.05",
    },
    {
      asset: "AVAX",
      method: "Avalanche C-Chain",
      network: "Avalanche C-Chain",
      minimum: "0.5",
    },
  ],
  [API_ENDPOINT.WALLET_TYPES_CONFIG]: WALLET_TYPES_TEST_CONFIG,
  [API_ENDPOINT.USER_REWARDS]: {
    storeUserId: "1",
    totalPoints: "100",
    activeMutlipliers: [],
  } as UserRewardState,
  [API_ENDPOINT.TRADE_PATH_BY_SUPPORTED_TOKENS]: {
    USDT: [
      {
        from: "ALGO",
        to: "USDT",
        orderSide: "sell",
        pairSymbol: "ALGO/USDT",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
    USDC: [
      {
        from: "ALGO",
        to: "USDC",
        orderSide: "sell",
        pairSymbol: "ALGO/USDC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
    KNC: [
      {
        from: "ALGO",
        to: "BTC",
        orderSide: "sell",
        pairSymbol: "ALGO/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
      {
        from: "BTC",
        to: "KNC",
        orderSide: "buy",
        pairSymbol: "KNC/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
    DATA: [
      {
        from: "ALGO",
        to: "BTC",
        orderSide: "sell",
        pairSymbol: "ALGO/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
      {
        from: "BTC",
        to: "DATA",
        orderSide: "buy",
        pairSymbol: "DATA/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
    MATIC: [
      {
        from: "ALGO",
        to: "BTC",
        orderSide: "sell",
        pairSymbol: "ALGO/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
      {
        from: "BTC",
        to: "MATIC",
        orderSide: "buy",
        pairSymbol: "MATIC/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
    DAI: [
      {
        from: "ALGO",
        to: "BTC",
        orderSide: "sell",
        pairSymbol: "ALGO/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
      {
        from: "BTC",
        to: "DAI",
        orderSide: "sell",
        pairSymbol: "BTC/DAI",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
    SAND: [
      {
        from: "ALGO",
        to: "BTC",
        orderSide: "sell",
        pairSymbol: "ALGO/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
      {
        from: "BTC",
        to: "SAND",
        orderSide: "buy",
        pairSymbol: "SAND/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
    GRT: [
      {
        from: "ALGO",
        to: "BTC",
        orderSide: "sell",
        pairSymbol: "ALGO/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
      {
        from: "BTC",
        to: "GRT",
        orderSide: "buy",
        pairSymbol: "GRT/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
    QUICK: [
      {
        from: "ALGO",
        to: "BTC",
        orderSide: "sell",
        pairSymbol: "ALGO/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
      {
        from: "BTC",
        to: "QUICK",
        orderSide: "buy",
        pairSymbol: "QUICK/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
    PYR: [
      {
        from: "ALGO",
        to: "BTC",
        orderSide: "sell",
        pairSymbol: "ALGO/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
      {
        from: "BTC",
        to: "PYR",
        orderSide: "buy",
        pairSymbol: "PYR/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
    VOXEL: [
      {
        from: "ALGO",
        to: "BTC",
        orderSide: "sell",
        pairSymbol: "ALGO/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
      {
        from: "BTC",
        to: "VOXEL",
        orderSide: "buy",
        pairSymbol: "VOXEL/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
    GMT: [
      {
        from: "ALGO",
        to: "BTC",
        orderSide: "sell",
        pairSymbol: "ALGO/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
      {
        from: "BTC",
        to: "GMT",
        orderSide: "buy",
        pairSymbol: "GMT/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
    NEXO: [
      {
        from: "ALGO",
        to: "BTC",
        orderSide: "sell",
        pairSymbol: "ALGO/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
      {
        from: "BTC",
        to: "NEXO",
        orderSide: "buy",
        pairSymbol: "NEXO/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
    STG: [
      {
        from: "ALGO",
        to: "BTC",
        orderSide: "sell",
        pairSymbol: "ALGO/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
      {
        from: "BTC",
        to: "STG",
        orderSide: "buy",
        pairSymbol: "STG/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
    SYN: [
      {
        from: "ALGO",
        to: "BTC",
        orderSide: "sell",
        pairSymbol: "ALGO/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
      {
        from: "BTC",
        to: "SYN",
        orderSide: "buy",
        pairSymbol: "SYN/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
    VANRY: [
      {
        from: "ALGO",
        to: "BTC",
        orderSide: "sell",
        pairSymbol: "ALGO/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
      {
        from: "BTC",
        to: "VANRY",
        orderSide: "buy",
        pairSymbol: "VANRY/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
    AXL: [
      {
        from: "ALGO",
        to: "BTC",
        orderSide: "sell",
        pairSymbol: "ALGO/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
      {
        from: "BTC",
        to: "AXL",
        orderSide: "buy",
        pairSymbol: "AXL/BTC",
        precision: {
          amount: 1e-7,
          price: 1e-7,
        },
      },
    ],
  },
  [API_ENDPOINT.GET_DISCORD_INVITATION]: { invitationUrl: "https://discord.gg/invite" },
  [API_ENDPOINT.GET_REFERRAL_CODE]: { code: "cede is cool" },
  [API_ENDPOINT.USER_ACTIVE_REWARD_MULTIPLIERS]: {
    totalMultiplier: "0",
    nextEndDate: undefined,
    activeMultipliers: [],
    referrerMultipliers: [],
    referreeMultiplier: null,
  },
  [API_ENDPOINT.TRADE_LEADERBOARD]: [],
};
