import React from "react";
import { theme } from "./themeConfig";
import { CssBaseline, StyledEngineProvider, ThemeProvider as MUIThemeProvider } from "@mui/material";

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};
