import { ApiPermissions, ConnectionStatus } from "@cede/types";
import { CEDE_CEXS } from "../cexs";
import { firstLetterToUpperCase } from "../text";

export const fakeAccount = {
  id: "11111-11111",
  accountName: "Binance 1",
  exchangeId: CEDE_CEXS.BINANCE,
  permissions: [ApiPermissions.READ, ApiPermissions.TRADE],
  status: ConnectionStatus.ONLINE,
};

export const fakeAccount2 = {
  id: "22222-22222",
  accountName: "Coinbase 1",
  exchangeId: CEDE_CEXS.COINBASE,
  permissions: [ApiPermissions.READ, ApiPermissions.WITHDRAW, ApiPermissions.TRADE],
  status: ConnectionStatus.ONLINE,
};

export const fakeAccount3 = {
  id: "33333-33333",
  accountName: "Bitfinex 1",
  exchangeId: CEDE_CEXS.BITFINEX,
  permissions: [ApiPermissions.READ, ApiPermissions.WITHDRAW, ApiPermissions.TRADE],
  status: ConnectionStatus.ONLINE,
};

export const fakeAccount4 = {
  id: "44444-44444",
  accountName: "Kraken 1",
  exchangeId: CEDE_CEXS.KRAKEN,
  permissions: [ApiPermissions.READ, ApiPermissions.WITHDRAW, ApiPermissions.TRADE],
  status: ConnectionStatus.ONLINE,
};

export const fakeAccount5 = {
  id: "55555-55555",
  accountName: "Bitmart 1",
  exchangeId: CEDE_CEXS.BITMART,
  permissions: [ApiPermissions.READ, ApiPermissions.WITHDRAW, ApiPermissions.TRADE],
  status: ConnectionStatus.ONLINE,
};

export const fakeAccount6 = {
  ...fakeAccount,
  permissions: [ApiPermissions.READ, ApiPermissions.WITHDRAW, ApiPermissions.TRADE],
};

export const fakeAccountsForAllExchanges = Object.values(CEDE_CEXS)
  .filter((exchange) => ![CEDE_CEXS.BITMART, CEDE_CEXS.BITSTAMP].includes(exchange))
  .map((exchange, index) => ({
    id: `${exchange}-${index}`,
    accountName: `${firstLetterToUpperCase(exchange)} 1`,
    exchangeId: exchange,
    permissions: [ApiPermissions.READ, ApiPermissions.WITHDRAW, ApiPermissions.TRADE],
    status: ConnectionStatus.ONLINE,
  }));
