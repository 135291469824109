import BINANCE_LOGO from "./assets/exchanges_logo/binance_logo.svg";
import BITFINEX_LOGO from "./assets/exchanges_logo/bitfinexLogo.svg";
import BITGET_LOGO from "./assets/exchanges_logo/bitgetLogo.svg";
import BITMART_LOGO from "./assets/exchanges_logo/bitmartLogo.svg";
import BITSTAMP_LOGO from "./assets/exchanges_logo/bitstampLogo.svg";
import BYBIT_LOGO from "./assets/exchanges_logo/bybit_logo.svg";
import COINBASE_LOGO from "./assets/exchanges_logo/coinbase.svg";
import GATEIO_LOGO from "./assets/exchanges_logo/gateioLogo.svg";
import HTX_LOGO from "./assets/exchanges_logo/htxLogo.svg";
import KRAKEN_LOGO from "./assets/exchanges_logo/kraken.svg";
import KUCOIN_LOGO from "./assets/exchanges_logo/kucoin.svg";
import MEXC_LOGO from "./assets/exchanges_logo/mexc_logo.svg";
import OKX_LOGO from "./assets/exchanges_logo/okx_logo.svg";
import { CEDE_CEXS } from "../cexs";

export const EXCHANGE_LOGOS: { [key: string]: string } = {
  [CEDE_CEXS.COINBASE]: COINBASE_LOGO,
  [CEDE_CEXS.BINANCE]: BINANCE_LOGO,
  [CEDE_CEXS.KRAKEN]: KRAKEN_LOGO,
  [CEDE_CEXS.GATEIO]: GATEIO_LOGO,
  [CEDE_CEXS.OKX]: OKX_LOGO,
  [CEDE_CEXS.BITFINEX]: BITFINEX_LOGO,
  [CEDE_CEXS.HTX]: HTX_LOGO,
  [CEDE_CEXS.KUCOIN]: KUCOIN_LOGO,
  [CEDE_CEXS.BYBIT]: BYBIT_LOGO,
  [CEDE_CEXS.BITGET]: BITGET_LOGO,
  [CEDE_CEXS.BITSTAMP]: BITSTAMP_LOGO,
  [CEDE_CEXS.BITMART]: BITMART_LOGO,
  [CEDE_CEXS.MEXC]: MEXC_LOGO,
};
