/**
 *
 * This function generates a Random number from a given range
 *
 * @param minRange - Max value of the range
 * @param maxRange - Min value of the range
 * @returns - return an integer random number
 */
export const generateRandomNumber = (minRange: number, maxRange: number): number => {
  return Math.floor(Math.random() * (maxRange - minRange + 1)) + minRange;
};

// If we pass the amount as '$1000.20' or 'EUR1000.20'
// as string instead of a number,
// use this to get the number back
export const getNumberFromAmount = (amount: string | number) => {
  if (typeof amount === "number") return amount;
  return +amount.replace(/[$a-z]+/i, "").replace(/\s/g, "");
};

export const euclideanDivision = (a: number, b: number): [number, number] => {
  const q = Math.floor(a / b);
  const r = a - b * q;
  return [q, r];
};
