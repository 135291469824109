import { formatBalance } from "@cede/utils";
import { useDependencies } from "../../hooks";
import { Typography } from "@mui/material";

export const TotalFees = () => {
  const { useSelectedTransaction } = useDependencies();
  const from = useSelectedTransaction((state) => state.transaction?.from);

  if (!from) return null;
  return (
    <>
      <Typography variant={"body2"}>Total fees</Typography>
      <Typography variant={"body1"} justifySelf={"right"}>
        {formatBalance(from.transaction.fee.amount)} {from.transaction.fee.tokenSymbol}
      </Typography>
    </>
  );
};
