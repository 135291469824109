import { CopyIconButton } from "@cede/ds";
import { CopyBoxProps } from "./types";
import { Box, useTheme } from "@mui/material";

export function CopyBox({ children, value }: CopyBoxProps) {
  const theme = useTheme();
  return (
    <Box
      position={"relative"}
      data-testid="copy-box"
      sx={{
        backgroundColor: theme.palette.background.darkPaper,
        padding: theme.custom.gutterMargin,
        borderRadius: theme.custom.defaultBoxRadius,
        fontSize: "14px",
      }}
    >
      <Box position={"absolute"} right={theme.custom.smallGutterMargin} top={theme.custom.smallGutterMargin}>
        <CopyIconButton testId="CopyIconButton" size="medium" text={value} />
      </Box>
      {children}
    </Box>
  );
}
