// ----------------------------------------------------------------------------

// PLEASE DO NOT EDIT THIS FILE, IT IS GENERATED AND WILL BE OVERWRITTEN:
// https://github.com/ccxt/ccxt/blob/master/CONTRIBUTING.md#how-to-contribute-code
// EDIT THE CORRESPONDENT .ts FILE INSTEAD

import { SdkErrorCodes, SdkErrorMessages } from "./errorsMetadata.js";
import { CedeSDKError } from "./default.js";
/**
 * @description Default error for withdrawal
 */
export class WithdrawalError extends CedeSDKError {
    constructor(code = SdkErrorCodes.WITHDRAWAL_ERROR, message = SdkErrorMessages.WITHDRAWAL_ERROR) {
        super(code, message);
        this.name = "WithdrawalError";
    }
}
/**
 * @description Thrown when readonly binance exchange instance id is not provided
 */
export class ReadonlyExchangeNotProvidedError extends WithdrawalError {
    constructor() {
        super(SdkErrorCodes.READONLY_EXCHANGE_NOT_PROVIDED_ERROR, SdkErrorMessages.READONLY_EXCHANGE_NOT_PROVIDED_ERROR);
        this.name = "ReadonlyExchangeNotProvidedError";
        this.notReportable = true;
    }
}
/**
 * @description Thrown when the token symbol isn't found
 */
export class TokenSymbolNotFoundError extends WithdrawalError {
    constructor(tokenSymbol) {
        super(SdkErrorCodes.TOKEN_SYMBOL_NOT_FOUND, SdkErrorMessages.TOKEN_SYMBOL_NOT_FOUND(tokenSymbol));
        this.name = "TokenSymbolNotFoundError";
    }
}
/**
 * @description Thrown when the withdrawal is not found
 */
export class WithdrawalNotFoundError extends WithdrawalError {
    constructor() {
        super(SdkErrorCodes.WITHDRAWAL_NOT_FOUND, SdkErrorMessages.WITHDRAWAL_NOT_FOUND);
        this.name = "WithdrawalNotFoundError";
    }
}
/**
 * @description Thrown when the withdrawal amount is under the minimum allowed to withdraw
 */
export class UnderWithdrawMinimumError extends WithdrawalError {
    constructor() {
        super(SdkErrorCodes.UNDER_WITHDRAW_MINIMUM, SdkErrorMessages.UNDER_WITHDRAW_MINIMUM);
        this.name = "UnderWithdrawMinimumError";
    }
}
/**
 * @description Thrown when the withdrawal amount is over the maximum allowed to withdraw
 */
export class OverWithdrawMaximumError extends WithdrawalError {
    constructor() {
        super(SdkErrorCodes.OVER_WITHDRAW_MAXIMUM, SdkErrorMessages.OVER_WITHDRAW_MAXIMUM);
        this.name = "OverWithdrawMaximumError";
    }
}
/**
 * @description Thrown when the provided address is not whitelisted
 */
export class WhitelistCheckFailedError extends WithdrawalError {
    constructor() {
        super(SdkErrorCodes.WHITELIST_CHECK_FAILED, SdkErrorMessages.WHITELIST_CHECK_FAILED);
        this.name = "WhitelistCheckFailedError";
        this.notReportable = true;
    }
}
export class WithdrawalForbiddenError extends WithdrawalError {
    constructor() {
        super(SdkErrorCodes.WITHDRAWAL_FORBIDDEN, SdkErrorMessages.WITHDRAWAL_FORBIDDEN);
        this.name = "WithdrawalForbiddenError";
    }
}
