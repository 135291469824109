import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const CexTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 380,
    zIndex: 90,
    backgroundColor: theme.palette.background.paper,
  },
}));
