import { IconTextSubText } from "@cede/ds";
import { isCefiInfoTx } from "@cede/types";
import { forgeTokenURL, formatBalance } from "@cede/utils";
import { useDependencies } from "../../hooks";
import { EastRounded } from "@mui/icons-material";
import { useTheme } from "@mui/material";

export const TradeHeader = () => {
  const theme = useTheme();
  const { useSelectedTransaction } = useDependencies();
  const { transaction, isTrade } = useSelectedTransaction(({ transaction, isTrade }) => ({
    transaction,
    isTrade,
  }));
  if (!transaction) return null;
  const from = transaction.from;
  const to = transaction.to;

  if (!isCefiInfoTx(from) || !isCefiInfoTx(to) || !isTrade) return null;

  return (
    <>
      <IconTextSubText
        iconName={from.transaction.tokenSymbol}
        icon={forgeTokenURL(from.transaction.tokenSymbol)}
        text={`${formatBalance(from.transaction.amount)} ${from.transaction.tokenSymbol}`}
        subText={"From"}
        sx={{ width: "auto" }}
      />
      <EastRounded
        sx={{
          color: theme.palette.text.primary,
        }}
      />
      <IconTextSubText
        iconName={to.transaction.tokenSymbol}
        icon={forgeTokenURL(to.transaction.tokenSymbol)}
        text={`${formatBalance(to.transaction.amount)} ${to.transaction.tokenSymbol}`}
        subText={"To"}
        sx={{ width: "auto" }}
        sxSubGroup={{
          alignItems: "flex-end",
        }}
      />
    </>
  );
};
