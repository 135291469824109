import { IconButtonWithPopper } from "@cede/ds";
import { CircularProgress, Typography, useTheme } from "@mui/material";

export const PendingTransactionLoader = () => {
  const theme = useTheme();

  return (
    <IconButtonWithPopper icon={<CircularProgress size={20} thickness={8} color={"warning"} />}>
      <Typography variant="caption" color={theme.palette.text.primary}>
        Transaction is pending, please wait for status update
      </Typography>
    </IconButtonWithPopper>
  );
};
