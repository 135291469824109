export type TokenInfo = {
  token: string;
  minimumQuantity: number;
  fee: number;
};

export type KrakenWithdrawalNetworkDto = {
  asset: string;
  method: string;
  network: string;
  minimum: string;
}[];

export enum ExchangeStatus {
  Ok = "ok",
  Shutdown = "shutdown",
  Error = "error",
  Maintenance = "maintenance",
  Unknown = "unknown",
}

export type ExchangeNameStatus = {
  name: string;
  id: string;
  status: ExchangeStatus;
};

export type StandardizedChain = {
  icid: string;
  ecid: string;
  icn: string;
  chainId: number | undefined;
};

export type ExchangeTokenNetworks = Record<string, string[]>;

export type ExchangeTokenNetworksData = {
  networks: ExchangeTokenNetworks;
  updatedAt: string;
};

export type ExchangeTokenNetworksResponse = {
  data: ExchangeTokenNetworksData;
};

export type ExchangeStatusesUpdateResponse = {
  hasBackOnline: Array<string>;
  hasGoneOffline: Array<string>;
};
