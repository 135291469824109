import { BackButtonProps } from "./types";
import { Box, Typography, useTheme } from "@mui/material";

export const BackButton = (props: BackButtonProps) => {
  const theme = useTheme();

  return (
    <Box
      data-testid="back-button"
      display="flex"
      alignItems="center"
      onClick={() => props.handleReturn()}
      sx={{
        ":hover": {
          cursor: "pointer",
        },
      }}
    >
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 5.25H2.8725L7.065 1.0575L6 0L0 6L6 12L7.0575 10.9425L2.8725 6.75H12V5.25Z"
          fill={props.color || theme.palette.text.primary}
        />
      </svg>

      <Typography fontSize="13px" fontWeight="500" ml="11px" color={props.color || theme.palette.text.primary}>
        Back
      </Typography>
    </Box>
  );
};
