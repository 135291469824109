import { Any, userFacingMessages } from "@cede/types";

/**
 * Get the error message from an error. Used in the UI to display the error to the user.
 */
export const getErrorMessage = (error: Any, defaultMessage = userFacingMessages.UNKNOWN_ERROR): string => {
  if (error?.isBaseError) {
    return error.userFacingDescription;
  }

  // This case happens when the error comes from an RPC request
  if (error?.data?.userFacingDescription) {
    return error.data.userFacingDescription;
  }

  return defaultMessage;
};
