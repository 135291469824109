import "./index.css";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import { ThemeProvider } from "@cede/ds";
import App from "./App";

const tagManagerArgs = {
  gtmId: "GTM-WBHG22TX",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ThemeProvider>
    <App />
  </ThemeProvider>,
);
