import { Route } from "react-router-dom";
import { AppRoute } from "@cedelabs/react-utils";

// @TODO: We might need to use sentry, remove `renderRoutes` from store and externalize this one to react-utils
export function renderRoutes(routes: AppRoute[]) {
  let key = 0;
  return routes.map((route) => {
    key++;
    if (route.element) {
      const Element = route.element;
      return <Route element={<Element />} key={key} path={route.path} />;
    }

    if (route.wrapper && route.routes) {
      const Wrapper = route.wrapper;
      return (
        <Route key={key} element={<Wrapper />}>
          {renderRoutes(route.routes)}
        </Route>
      );
    }

    return null;
  });
}
