import { AmountValidator, PropertyType } from "@cede/types";

/**
 * Capitalize a given string
 *
 * @param string - string to capitalize
 * @returns Return a capitalized string
 *
 * @example
 * Here is an example of how to use the function
 * ```
 * // Prints Hello
 * capitalize("hello")
 * ```
 * @public
 */
export const capitalize = (string?: string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.substring(1);
};

const plainWords = new Set(["the", "of", "to"]);

/**
 * Capitalize all words in a given string
 *
 * @remarks
 * This is just a method to illustrate how can we document or use
 * in other packages
 *
 * @example
 * Here is an example of how to use id
 * ```
 * // Prints Hello World
 * makeFistLettersCapital("hello world")
 * ```
 *
 * @param rawStr - array to check
 * @param usePlainwords - if true it will not capitalize some words
 * @param plainWordList - array of words it should not capitalize. By default [the, of, to] is the array
 * @returns The capitalized string
 */
export const makeFirstLettersCapital = (rawStr: string, usePlainwords?: boolean, plainWordList?: string[]): string => {
  if (usePlainwords) {
    const customPlainWords = plainWordList && plainWordList.length ? new Set(plainWordList) : plainWords;
    return (
      rawStr
        // eslint-disable-next-line no-useless-escape
        .split(/[\s\_]+/)
        .map((word) => (customPlainWords.has(word) ? word : capitalize(word)))
        .join(" ")
    );
  }
  return (
    rawStr
      // eslint-disable-next-line no-useless-escape
      .split(/[\s\_]+/)
      .map(capitalize)
      .join(" ")
  );
};

export const countOccurence = (text: string, occurence: string) => {
  return text.split(occurence).length - 1;
};

export const formatNumericString = (numericString: string) => {
  let value: string = numericString.replace(/\s/g, "");
  value = value.length === 2 && value[0] === "0" && value[1] !== "." ? value.slice(1) : value;
  value = !value ? "0" : value;
  return value;
};

export const isNumericString = (numericString: string) => /^\d*\.?\d*$/.test(numericString);

export const cropTextInMiddle = (text: string, maxLength: number) => {
  if (maxLength > text.length) return text;
  const middleLength = Math.floor(maxLength / 2);
  const part1 = text.slice(0, middleLength);
  const part2 = text.slice(-middleLength);
  return `${part1}...${part2}`;
};

export function formatShortAddress(address: string): string {
  if (address.length < 15) return address;
  const newAddress = "";
  return newAddress.concat(address.slice(0, 6), "...", address.slice(-6));
}

export function generateAcronym(words: string): string {
  const matches = words.match(/\b(\w)/g);
  if (!matches) return "";
  return matches.join("");
}

export const validateAmountFrom: PropertyType<AmountValidator, "validate"> = (integerPart, decimalPart, opts) => {
  if (opts?.decimals) {
    if (decimalPart.length > opts.decimals) {
      return false;
    }
  }
  if (opts?.integer) {
    if (integerPart.length > opts.integer) {
      return false;
    }
  }
  return true;
};

export function replacePlaceholdersByValue(content: string, mappingTable: Record<string, string | undefined>) {
  return Object.entries(mappingTable).reduce((acc, [key, value]) => {
    if (!value) return acc;
    return acc.replace(`{${key}}`, value);
  }, content);
}
