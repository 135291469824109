import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Logo, LogoSizeEnum, useNavBar } from "@cede/ds";
import { CEDE_STORE_URL } from "@cede/utils";
import { useDependencies } from "../../hooks/useDependencies";
import { PresentationalSend } from "@cedelabs/react-utils";
import { Box, useTheme } from "@mui/material";
import { CedeOnboardingModal } from "../../features/CedeOnboardingModal";

export const Send = () => {
  const { useSend, widgetProps } = useDependencies();
  const theme = useTheme();
  const [isOnboardingModalOpened, setIsOnboardingModalOpened] = useState(false);
  const controller = useSend({
    onSubmitDemo: () => setIsOnboardingModalOpened(true),
  });
  const { setTitle, setIsDisplayed, setLeftSide } = useNavBar();
  const { navbarLogo } = widgetProps;

  useEffect(() => {
    setLeftSide(
      navbarLogo !== undefined ? (
        navbarLogo
      ) : (
        <Link to={CEDE_STORE_URL} target="_blank">
          <Logo size={LogoSizeEnum.SMALL} />
        </Link>
      ),
    );
    setIsDisplayed(true);
    setTitle("Send");
  }, [navbarLogo]);

  return (
    <Box
      display={"flex"}
      flexDirection="column"
      alignItems={"stretch"}
      gap={theme.custom.gutterMargin}
      paddingTop={theme.custom.gutterMargin}
      justifyContent={"space-between"}
      flex={1}
      height="calc(100% - 60px)"
    >
      <CedeOnboardingModal open={isOnboardingModalOpened} exit={() => setIsOnboardingModalOpened(false)} />
      <PresentationalSend controller={controller} disableAddressBook={true} />
    </Box>
  );
};
