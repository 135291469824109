import { TutorialBoxProps } from "./types";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Tooltip, Typography, tooltipClasses, useTheme } from "@mui/material";

export function TutorialBox({ children, title, help }: TutorialBoxProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.background.darkPaper,
        position: "relative",
        padding: `20px ${theme.custom.gutterMargin}`,
        borderRadius: "16px",
        textAlign: "left",
      }}
    >
      <Typography
        component={"h2"}
        sx={{
          padding: `4px ${theme.custom.bigGutterMargin}`,
          fontWeight: "bold",
          borderRadius: theme.custom.buttonRoundedRadius,
          background: theme.palette.primary.main,
          display: "inline-block",
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: theme.palette.background.default,
        }}
      >
        {title}
      </Typography>
      {help && (
        <Tooltip
          title={
            <Typography
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              fontSize="14px"
              sx={{
                minWidth: "164px",
              }}
            >
              {help}
            </Typography>
          }
          sx={{
            fontSize: "20px",
            color: theme.palette.text.primary,
            position: "absolute",
            top: theme.custom.smallGutterMargin,
            right: theme.custom.smallGutterMargin,
          }}
          placement="bottom-end"
          arrow
          PopperProps={{
            disablePortal: true,
            sx: {
              [`& .${tooltipClasses.tooltip}`]: {
                marginTop: "6px!important",
                padding: "8px",
              },
            },
          }}
        >
          <HelpOutlineIcon color="inherit" fontSize="inherit" cursor="pointer" />
        </Tooltip>
      )}
      {children}
    </Box>
  );
}
