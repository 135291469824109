import { VaultItem } from "@cede/types";
import {
  fakeAccount,
  fakeAccount2,
  fakeAccount3,
  fakeAccount4,
  fakeAccount5,
  fakeAccountsForAllExchanges,
} from "./accounts";

export const fakeActiveVault: VaultItem = {
  id: "fakeId",
  name: "fakeName",
  accounts: [fakeAccount, fakeAccount2, fakeAccount3, fakeAccount4, fakeAccount5],
  image: "fakeImage",
  isActive: true,
};

export const fakeActiveVault2: VaultItem = {
  id: "fakeId2",
  name: "fakeName2",
  accounts: [fakeAccount, fakeAccount2, fakeAccount3, fakeAccount4, fakeAccount5],
  image: "fakeImage2",
  isActive: false,
};

export const fakeActiveVaultForDemo: VaultItem = {
  id: "11111-11111",
  name: "Main vault",
  accounts: fakeAccountsForAllExchanges,
  image: "fakeImage",
  isActive: true,
};
