import { randomId } from "@cede/utils";
import { UseAlertState } from "./types";
import { create, StoreApi, UseBoundStore } from "zustand";

const ALERT_TIMEOUT = 5000;

export type UseAlertHook = UseBoundStore<StoreApi<UseAlertState>>;

export const useAlert = create<UseAlertState>((set, get) => ({
  alertQueue: [],
  append: (message, type, data = {}, onClick) => {
    const alertItem = {
      message,
      type,
      id: randomId(),
      vault: data.vault,
      account: data.account,
      onClick: onClick,
    };

    if (!data?.noTimeout) {
      setTimeout(() => {
        get().remove(alertItem.id);
      }, data?.customMsTimeout ?? ALERT_TIMEOUT);
    }

    set((state) => ({ alertQueue: [...state.alertQueue, alertItem] }));
  },
  remove: (id) => {
    set((state) => ({
      alertQueue: state.alertQueue.filter((alert) => alert.id !== id),
    }));
  },
  removeByMessage: (message: string) => {
    set((state) => ({
      alertQueue: state.alertQueue.filter((alert) => !alert.message.includes(message)),
    }));
  },
}));
