import { QueryKeys } from "@cede/utils";
import { useDependencies } from ".";
import { useQuery } from "@tanstack/react-query";

export const useConfig = () => {
  const { backgroundHandler } = useDependencies();

  const { data: config, refetch } = useQuery({
    queryKey: [QueryKeys.CONFIG],
    queryFn: () => backgroundHandler.getConfig(),
    enabled: !!backgroundHandler?.isReady,
    staleTime: Infinity,
    gcTime: Infinity,
    refetchOnWindowFocus: false,
  });
  return {
    config,
    refetchConfig: refetch,
  };
};
