import { useMemo } from "react";
import { useDependencies } from "..";

type UseTradePathParams = {
  enabled: boolean;
  accountId: string;
  fromToken: string;
  toToken?: string;
  network?: string;
  activeWithdrawal: boolean;
};

export const useTradePath = ({
  enabled,
  accountId,
  fromToken,
  network,
  activeWithdrawal,
  toToken,
}: UseTradePathParams) => {
  const { useTradePathBySupportedTokens } = useDependencies();

  const { tradePathBySupportedTokens, error, isLoading, droplistSupportedTokens } = useTradePathBySupportedTokens({
    accountId,
    fromToken,
    network,
    activeWithdrawal,
    enabled,
  });

  const tradePath = useMemo(() => {
    if (!tradePathBySupportedTokens) return null;
    return tradePathBySupportedTokens[toToken ?? ""];
  }, [tradePathBySupportedTokens, toToken, fromToken, network]);

  return {
    tradePath,
    tradePathBySupportedTokens,
    error,
    isLoading,
    droplistDestinationTokens: droplistSupportedTokens,
  };
};
