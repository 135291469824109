import { Routes } from "react-router-dom";
import root from "react-shadow/emotion";
import { SendWidgetProps, WidgetType } from "@cede/types";
import { Container } from "../components/Container";
import { Providers } from "../providers";
import { renderRoutes } from "../routes/renderRoutes";
import { SEND_ROUTES } from "../routes/sendRoutes";
import { getUseMultipleTransactionsStore } from "@cedelabs/react-utils/src/hooks/useMultipleTransactionsStore";

export const CedeSend = (widgetProps: SendWidgetProps = {}) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <root.div>
      <Providers
        dependencies={{
          widgetProps,
          widgetType: WidgetType.SEND,
          useMultipleTransactionsStore: getUseMultipleTransactionsStore(WidgetType.SEND),
        }}
      >
        <Container>
          <Routes>{renderRoutes(SEND_ROUTES)}</Routes>
        </Container>
      </Providers>
    </root.div>
  );
};
