export const PandaBasic = () => {
  return (
    <svg viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.00745909 26.3991C0.00745909 11.815 11.8292 0 26.4048 0H83.6027C98.1857 0 110 11.8225 110 26.3991V83.6009C110 98.185 98.1783 110 83.6027 110H26.2704C11.762 110 0 98.2372 0 83.7278V26.3917L0.00745909 26.3991Z"
        fill="url(#paint0_linear_2187_113375)"
      />
      <path
        d="M6.35721 29.6979C6.35721 16.8006 16.8131 6.34399 29.7095 6.34399H80.31C93.2064 6.34399 103.662 16.8006 103.662 29.6979V80.3018C103.662 93.1991 93.2064 103.656 80.31 103.656H29.5901C16.7534 103.656 6.34974 93.2513 6.34974 80.4138V29.6904L6.35721 29.6979Z"
        fill="black"
      />
      <path
        d="M12.6995 32.9969C12.6995 21.7789 21.7896 12.6881 33.0068 12.6881H77.0098C88.227 12.6881 97.3171 21.7789 97.3171 32.9969V77.0029C97.3171 88.2208 88.227 97.3116 77.0098 97.3116H32.9098C21.7523 97.3116 12.7069 88.2656 12.7069 77.0999V32.9969H12.6995Z"
        fill="white"
      />
      <path
        d="M45.7361 59.2219C49.8786 52.0466 48.6935 43.6066 43.0892 40.3708C37.485 37.1349 29.5838 40.3286 25.4414 47.5039C21.2989 54.6793 22.484 63.1192 28.0882 66.355C33.6925 69.5909 41.5937 66.3973 45.7361 59.2219Z"
        fill="black"
      />
      <path
        d="M41.3015 52.747C43.7375 52.747 45.7123 50.7721 45.7123 48.3359C45.7123 45.8998 43.7375 43.9249 41.3015 43.9249C38.8656 43.9249 36.8908 45.8998 36.8908 48.3359C36.8908 50.7721 38.8656 52.747 41.3015 52.747Z"
        fill="white"
      />
      <path
        d="M82.1606 66.4148C87.7649 63.1789 88.9499 54.739 84.8075 47.5636C80.6651 40.3883 72.7639 37.1947 67.1596 40.4305C61.5554 43.6663 60.3703 52.1063 64.5127 59.2817C68.6551 66.457 76.5564 69.6506 82.1606 66.4148Z"
        fill="black"
      />
      <path
        d="M68.9501 52.814C71.3861 52.814 73.3608 50.8392 73.3608 48.403C73.3608 45.9669 71.3861 43.9919 68.9501 43.9919C66.5141 43.9919 64.5393 45.9669 64.5393 48.403C64.5393 50.8392 66.5141 52.814 68.9501 52.814Z"
        fill="white"
      />
      <path
        d="M55.1234 71.4422C55.1234 71.4422 51.9739 71.3153 50.8469 72.1213C49.72 72.9274 49.7946 74.1739 49.7946 74.1739C49.7946 74.1739 49.6454 75.4501 50.0185 76.2786C50.3917 77.1071 52.5784 76.9653 53.6531 78.8088C53.6531 78.8088 54.6382 79.1447 55.1234 79.0477"
        fill="black"
      />
      <path
        d="M55.1234 71.4422C55.1234 71.4422 58.273 71.3154 59.4 72.1215C60.5269 72.9276 60.4523 74.174 60.4523 74.174C60.4523 74.174 60.6015 75.4503 60.2284 76.2788C59.8552 77.1073 57.6685 76.9655 56.5938 78.809C56.5938 78.809 55.6085 79.1447 55.1234 79.0477"
        fill="black"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2187_113375"
          x1="55"
          y1="0"
          x2="55"
          y2="123.049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" stopOpacity="0.4" />
        </linearGradient>
      </defs>
    </svg>
  );
};
