import { useMemo } from "react";
import { SupportedTokensDropListWithIconItem, TokenWithMarketNetworkAndTokenName } from "@cede/types";
import { forgeTokenURL, USE_SUPPORTED_TOKENS_CACHE_TIME } from "@cede/utils";
import { UseSendState } from "../../components/Send/types";
import { useDependencies } from "../../hooks/useDependencies";
import { UseSupportedTokensProps } from "./types";
import { useQuery } from "@tanstack/react-query";

export const useSupportedTokens = ({ network, accountId, onlyWithdrawable = false }: UseSupportedTokensProps) => {
  const { backgroundHandler } = useDependencies();

  const { data: supportedTokens, isFetching: supportedTokensAreLoading } = useQuery<
    TokenWithMarketNetworkAndTokenName[]
  >({
    queryKey: ["supportedTokens", accountId, network],
    queryFn: async () => {
      const supportedTokens = await backgroundHandler.getSupportedTokens({
        accountId,
        network,
      });
      return supportedTokens;
    },
    enabled: !!backgroundHandler?.isReady && !!accountId,
    staleTime: USE_SUPPORTED_TOKENS_CACHE_TIME,
    gcTime: USE_SUPPORTED_TOKENS_CACHE_TIME,
  });

  const isWithdrawEnabledNetwork = ({
    network,
    token,
  }: {
    network?: string;
    token: TokenWithMarketNetworkAndTokenName;
  }) => {
    return token.networks?.find((n) => n.network === network)?.withdrawalEnabled;
  };

  const droplistSupportedTokens: UseSendState["droplistDestinationTokens"] = useMemo(() => {
    if (supportedTokens) {
      let allSupportedTokens: SupportedTokensDropListWithIconItem[] = [];
      allSupportedTokens = supportedTokens.map((token) => {
        return {
          ...token,
          label: token.name ?? token.tokenSymbol,
          value: token.tokenSymbol,
          searchableTerms: [token.tokenSymbol, token.name ?? ""],
          img: forgeTokenURL(token.tokenSymbol),
          isDisabled: onlyWithdrawable && !isWithdrawEnabledNetwork({ network, token }),
        };
      });

      if (onlyWithdrawable) {
        allSupportedTokens = allSupportedTokens.filter((token) => token.isWithdrawable);
      }

      return allSupportedTokens;
    } else {
      return [];
    }
  }, [network, accountId, supportedTokens]);

  return {
    supportedTokensAreLoading,
    droplistSupportedTokens,
    supportedTokens,
  };
};
