import { formatBalance } from "@cede/utils";
import { useDependencies } from "../../hooks/useDependencies";
import { WithdrawMinAmountProps } from "./types";
import { Link, Skeleton, Typography, useTheme } from "@mui/material";

export const WithdrawMinAmount = ({ controller }: WithdrawMinAmountProps) => {
  const { useSupportedExchanges } = useDependencies();
  const { supportedExchangesById } = useSupportedExchanges();
  const theme = useTheme();

  const {
    form: { currency, fromAccount },
  } = controller;

  let amountValue: JSX.Element | null = null;

  if (controller.minAmount) {
    amountValue = (
      <>
        {formatBalance(controller.minAmount)} {currency?.value.toUpperCase()}
      </>
    );
  }

  if (
    !controller.minAmount &&
    fromAccount?.cexValue &&
    supportedExchangesById &&
    supportedExchangesById[fromAccount.cexValue]?.minimumWithdrawalUrl
  ) {
    amountValue = (
      <Link
        data-testid={"withdrawmin-link"}
        sx={{ textDecoration: "none" }}
        target="_blank"
        href={supportedExchangesById[fromAccount.cexValue]?.minimumWithdrawalUrl}
      >
        see here
      </Link>
    );
  }

  return amountValue || (controller.minAmountIsLoading && controller.form.network && controller.form.currency) ? (
    <Typography paddingLeft={theme.custom.smallGutterMargin} data-testid={"withdrawmin-value"} variant={"caption"}>
      Minimum amount:{" "}
      {controller.minAmountIsLoading ? (
        <Skeleton
          sx={{
            display: "inline-block",
          }}
          width={30}
          height={20}
        />
      ) : (
        amountValue
      )}
    </Typography>
  ) : null;
};
