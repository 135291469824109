import { Outlet } from "react-router-dom";
import { NotSupported } from "../components/NotSupported";

/**
 * @description This component is used to check if the browser is chromium based.
 * If not, it will display the NotSupported component.
 */
export const chromiumMiddleware = () => {
  if (!window.chrome) {
    return <NotSupported />;
  }
  return <Outlet />;
};
