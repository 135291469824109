import { MarketNetwork, TokenSymbol } from "@cede/types";
import { forgeNetworkURL } from "../cdn-client";

const generateMarketNetwork = (name: string, network: string, min: string, max: string) => ({
  name,
  network,
  withdrawFee: String(Number(min) / 2),
  withdrawMax: max,
  withdrawMin: min,
  withdrawalEnabled: true,
  depositEnabled: true,
  iconUrl: forgeNetworkURL(network),
});

export const mockedNetworks: MarketNetwork[] = [
  generateMarketNetwork("Ethereum", "ethereum", "0.001", "2000"),
  generateMarketNetwork("Polygon", "polygon", "0.001", "2000"),
];

export const mockedNetworksByToken: Record<TokenSymbol, MarketNetwork[]> = {
  WBTC: [
    generateMarketNetwork("Ethereum", "ethereum", "0", "2000"),
    generateMarketNetwork("Polygon", "polygon", "0", "2000"),
  ],
  USDT: [
    generateMarketNetwork("Ethereum", "ethereum", "0", "2000"),
    generateMarketNetwork("Polygon", "polygon", "0", "2000"),
  ],
  ETH: [
    generateMarketNetwork("Ethereum", "ethereum", "0", "2000"),
    generateMarketNetwork("Polygon", "polygon", "0", "2000"),
  ],
  MATIC: [
    generateMarketNetwork("Ethereum", "ethereum", "0", "2000"),
    generateMarketNetwork("Polygon", "polygon", "0", "2000"),
  ],
};
