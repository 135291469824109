import { useEffect, useMemo, useRef } from "react";
import { ExchangeStatusesUpdateResponse, userFacingMessages } from "@cede/types";
import { CEDE_CEX_LABELS } from "@cede/utils";
import { useDependencies } from "../hooks/useDependencies";
import { AlertTypes } from "../store";

export const useExchangesStatusAlerts = () => {
  const { useSupportedExchanges, useAlert, backgroundHandler, useVaults, useLoginStore } = useDependencies();
  const { downExchanges } = useSupportedExchanges();
  const appendAlert = useAlert((state) => state.append);
  const hasSentInitialAlerts = useRef<boolean>(false);
  const { activeVault } = useVaults();
  const { isLoggedIn } = useLoginStore();

  const downCexNames = useMemo<Array<string[]>>(() => {
    const groups: Array<string[]> = [];
    if (!downExchanges || !activeVault?.accounts) return groups;

    // We only want to show alerts for exchanges that are connected to the active vault
    const filteredExchanges = downExchanges.filter((cex) =>
      activeVault.accounts.some((acc) => acc.exchangeId === cex.id),
    );

    const downExchangesNames = filteredExchanges.map((cex) => CEDE_CEX_LABELS[cex.id] ?? cex.name);

    for (let i = 0; i < downExchangesNames.length; i += 3) {
      groups.push(downExchangesNames.slice(i, i + 3));
    }
    return groups;
  }, [downExchanges, activeVault]);

  const displayAlerts = (names: Array<string>, alertType: AlertTypes) => {
    let alertMessage = "";

    if (alertType === AlertTypes.ERROR) {
      if (names[0] && names.length === 1) {
        alertMessage = userFacingMessages.EXCHANGES_STATUSES.SINGLE_DOWN(names[0]);
      } else if (names[0] && names[1] && names.length === 2) {
        alertMessage = userFacingMessages.EXCHANGES_STATUSES.TWO_DOWN(names[0], names[1]);
      } else if (names[0] && names[1] && names[2] && names.length === 3) {
        alertMessage = userFacingMessages.EXCHANGES_STATUSES.THREE_DOWN(names[0], names[1], names[2]);
      }
    } else {
      if (names[0] && names.length === 1) {
        alertMessage = userFacingMessages.EXCHANGES_STATUSES.SINGLE_BACK_ONLINE(names[0]);
      } else if (names[0] && names[1] && names.length === 2) {
        alertMessage = userFacingMessages.EXCHANGES_STATUSES.TWO_BACK_ONLINE(names[0], names[1]);
      } else if (names[0] && names[1] && names[2] && names.length === 3) {
        alertMessage = userFacingMessages.EXCHANGES_STATUSES.THREE_BACK_ONLINE(names[0], names[1], names[2]);
      }
    }

    appendAlert(alertMessage, alertType);
  };

  useEffect(() => {
    if (!activeVault?.accounts || hasSentInitialAlerts.current) return;

    const listenerId = backgroundHandler.on(
      "exchangesStatusesUpdate",
      ({ hasGoneOffline, hasBackOnline }: ExchangeStatusesUpdateResponse) => {
        const filteredOnlineCex = hasBackOnline.filter((cex) =>
          activeVault?.accounts?.some((acc) => acc.exchangeId === cex),
        );
        const filteredOfflineCex = hasGoneOffline.filter((cex) =>
          activeVault?.accounts?.some((acc) => acc.exchangeId === cex),
        );

        const backOnlineCexNames = filteredOnlineCex.map((cex) => CEDE_CEX_LABELS[cex] ?? cex);
        const goneOfflineCexNames = filteredOfflineCex.map((cex) => CEDE_CEX_LABELS[cex] ?? cex);

        if (backOnlineCexNames.length) {
          for (let i = 0; i < backOnlineCexNames.length; i += 3) {
            displayAlerts(backOnlineCexNames.slice(i, i + 3), AlertTypes.SUCCESS);
          }
        }

        if (goneOfflineCexNames.length) {
          for (let i = 0; i < goneOfflineCexNames.length; i += 3) {
            displayAlerts(goneOfflineCexNames.slice(i, i + 3), AlertTypes.ERROR);
          }
        }
      },
    );

    return () => {
      backgroundHandler.off(listenerId);
    };
  }, [activeVault?.accounts]);

  useEffect(() => {
    if (downCexNames.length === 0 || !isLoggedIn || hasSentInitialAlerts.current) return;
    hasSentInitialAlerts.current = true;

    downCexNames.map((names) => {
      displayAlerts(names, AlertTypes.ERROR);
    });
  }, [downCexNames, isLoggedIn]);
};
