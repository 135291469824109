import { Any } from "@cede/types";

/**
 * Provides a price for a given symbol of a token. (Only for testing)
 */
export const fakePrices = new Proxy(
  {
    BTC: 28000,
    ETH: 1800,
  },
  {
    get: (target: Any, property: Any) => {
      if (target[property]) {
        return target[property];
      }

      return 1;
    },
  },
);
