import { CopyIconButton } from "@cede/ds";
import { isDefiInfoTx } from "@cede/types";
import { cropTextInMiddle } from "@cede/utils";
import { useDependencies } from "../../hooks";
import { Box, Typography, useTheme } from "@mui/material";

export const RecipientAddressLine = () => {
  const theme = useTheme();
  const { useSelectedTransaction } = useDependencies();
  const transaction = useSelectedTransaction((state) => state.transaction);

  if (!transaction || !isDefiInfoTx(transaction.to) || !transaction.to.account.address) return null;

  return (
    <>
      <Typography variant={"body2"}>Recipient address</Typography>
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          justifySelf: "right",
          gap: theme.custom.smallGutterMargin,
        }}
      >
        <Typography variant={"body1"} justifySelf={"right"}>
          {cropTextInMiddle(transaction.to.account.address, 10)}
        </Typography>
        <CopyIconButton
          size="small"
          sx={{
            color: "text.primary",
          }}
          text={transaction.to.account.address}
        />
      </Box>
    </>
  );
};
