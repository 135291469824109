import { useState } from "react";
import { BackButton, Button } from "@cede/ds";
import { useDependencies } from "../../hooks";
import { ExtensionImageProps } from "./types";
import { Checklist, ZoomOutMap } from "@mui/icons-material";
import { Box, Fade, Modal, useTheme } from "@mui/material";

export const ExtensionImage = ({ path, alt, icon, canZoom, iconColor, width }: ExtensionImageProps) => {
  const { useExtensionAsset } = useDependencies();
  const [isZoom, setIsZoom] = useState(false);

  const { fullPath } = useExtensionAsset({ path, disable: true });
  const theme = useTheme();

  const onZoom = () => {
    setIsZoom(true);
  };

  const onClose = () => {
    setIsZoom(false);
  };

  if (!fullPath) return null;

  return (
    <>
      <Box position={"relative"} width="100%">
        {icon && (
          <Checklist
            sx={{
              position: "absolute",
              right: theme.custom.smallGutterMargin,
              bottom: theme.custom.smallGutterMargin,
              color: iconColor,
            }}
          />
        )}
        {canZoom && (
          <ZoomOutMap
            sx={{
              position: "absolute",
              right: "1px",
              bottom: "12px",
              color: theme.palette.primary.main,
              pointerEvents: "none",
            }}
          />
        )}
        <Box
          component={"img"}
          src={fullPath}
          width={width ?? "100%"}
          alt={alt}
          sx={{
            margin: `${theme.custom.smallGutterMargin} 0`,
            borderRadius: theme.custom.defaultBoxRadius,
            maxHeight: canZoom ? "50px" : "inherit",
          }}
          onClick={canZoom ? onZoom : undefined}
        />
      </Box>

      {canZoom && (
        <Modal open={isZoom} onClose={onClose} closeAfterTransition>
          <Fade in={isZoom}>
            <Box
              sx={{
                "width": `100%`,
                "maxWidth": `calc(${theme.custom.storeSize.bigTabWidth} + ${theme.custom.bigGutterMargin})`,
                "height": "max-content",
                "padding": theme.custom.gutterMargin,
                "position": "absolute",
                "top": "50%",
                "left": "50%",
                "transform": "translate(-50%, -50%)",
                "background": theme.palette.background.darkPaper,
                "borderRadius": theme.custom.mediumBoxRadius,
                ":focus": {
                  outline: "none",
                },
              }}
            >
              <Button>
                <BackButton handleReturn={onClose} />
              </Button>
              <Box
                component={"img"}
                src={fullPath}
                width={"100%"}
                alt={alt}
                sx={{
                  margin: `${theme.custom.smallGutterMargin} 0`,
                  marginTop: theme.custom.bigGutterMargin,
                  borderRadius: theme.custom.defaultBoxRadius,
                }}
              />
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  );
};
