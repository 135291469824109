import { useState, useMemo } from "react";
import { ProviderHandler } from "@cede/types";
import { defaultDependencies } from "./dependencies";
import { CedeProvider } from "@cedelabs/providers/src";
import type { DependenciesProviderProps } from "@cedelabs/react-utils";
import { DependenciesProvider as DependenciesProviderUtil } from "@cedelabs/react-utils";

export function DependenciesProvider<T extends Record<string, unknown>>({
  children,
  dependencies,
}: DependenciesProviderProps<T>) {
  const [backgroundHandler, setBackgroundHandler] = useState<(ProviderHandler & CedeProvider) | null>(null);

  const finalDependencies = useMemo(() => {
    return {
      backgroundHandler,
      setBackgroundHandler,
      ...defaultDependencies,
      ...dependencies,
    };
  }, [dependencies, backgroundHandler]);

  return <DependenciesProviderUtil dependencies={finalDependencies}>{children}</DependenciesProviderUtil>;
}
