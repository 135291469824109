import { forwardRef } from "react";
import { ActionButtonProps } from "./types";
import { alpha, Button } from "@mui/material";

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ children, color = "primary", ...props }, ref) => {
    return (
      <Button
        {...props}
        ref={ref}
        color={color}
        variant="contained"
        sx={(theme) => ({
          "backgroundColor": alpha(theme.palette[color]?.main, 0.3),
          "borderRadius": theme.custom.buttonSmallRadius,
          "color": theme.palette[color].main,
          "textTransform": "none",
          "border": "1px solid transparent",
          ":hover": {
            backgroundColor: alpha(theme.palette[color]?.main, 0.3),
            borderColor: theme.palette[color].main,
          },
          ...props.sx,
        })}
      >
        {children}
      </Button>
    );
  },
);
