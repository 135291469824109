import { WidgetType } from "@cede/types";
import packageJson from "../../../package.json";
import { AlertQueue, HelpLink, Loading, NavBar, useDependencies } from "@cedelabs/react-utils";
import { Box, useTheme } from "@mui/material";

export const Container = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const { widgetProps, widgetType } = useDependencies();
  const { theme: themeProp } = widgetProps;

  return (
    <Box
      sx={{
        maxWidth: theme.custom.storeSize.widgetMaxWidth,
        minWidth: theme.custom.storeSize.widgetMinWidth,
        height:
          widgetType === WidgetType.SEND
            ? theme.custom.storeSize.widgetHeight
            : theme.custom.storeSize.tradeWidgetHeight,
        width: themeProp?.width ?? theme.custom.storeSize.widgetWidth,
        backgroundColor: "background.default",
        borderRadius: 3,
        border: "1px solid",
        borderColor: widgetProps?.theme?.borderColor ?? "transparent",
        padding: "12px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        fontSize: "100%",
      }}
      data-version={packageJson.version}
    >
      <Box position="relative" mt="6px">
        <Box zIndex={theme.custom.zIndex.topLayer} position="relative">
          <NavBar />
        </Box>

        <Box sx={{ position: "absolute", zIndex: "1299", right: "4px", top: "50%", transform: "translateY(-50%)" }}>
          <HelpLink />
        </Box>
      </Box>

      <AlertQueue />
      <Loading />
      {children}
    </Box>
  );
};
