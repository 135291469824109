import { useEffect } from "react";
import { MarketRate, OrderSide, PairSymbol, TradeForm } from "@cede/types";
import { getCurrentRate } from "@cede/utils";
import { useDependencies } from "../../../hooks/useDependencies";
import { useQuery } from "@tanstack/react-query";

type UseTradeMinAmountParams = {
  tradeForm: TradeForm;
  marketRate: MarketRate;
  shouldUseTradeSquare: boolean;
};

export const useTradeMinAmount = ({ tradeForm, marketRate, shouldUseTradeSquare }: UseTradeMinAmountParams) => {
  const { backgroundHandler, useVaults, useMultipleTransactionsMinAmount } = useDependencies();
  const { activeVault } = useVaults();

  const { isLoading: multipleTransactionMinAmountIsLoading, minAmount: multipleTransactionsMinAmount } =
    useMultipleTransactionsMinAmount({
      enabled: shouldUseTradeSquare,
      accountId: tradeForm.selectedAccount?.value || "",
      fromToken: tradeForm.tokenFrom?.value || "",
      toToken: tradeForm.tokenTo?.value || "",
      activeWithdrawal: false,
    });

  /**
   * Find the minimum amount to trade for the tokenFrom
   */
  const getMinAmountFrom = async (pairSymbol: PairSymbol, orderSide: OrderSide) => {
    if (!tradeForm.selectedAccount || !activeVault || getCurrentRate(marketRate, orderSide) === "0") return "0";
    const minAmounts = await backgroundHandler.getMinAmounts({
      accountId: tradeForm.selectedAccount.value,
      pairSymbol,
      orderSide,
      price: getCurrentRate(marketRate, orderSide),
    });
    return orderSide === "buy" ? minAmounts.minQuoteAmount : minAmounts.minBaseAmount;
  };

  const {
    data: classicMinAmount,
    isLoading: classicMinAmountIsLoading,
    refetch: refetchClassicMinAmount,
  } = useQuery({
    queryKey: ["tradeMinAmount", tradeForm.market?.pairSymbol, tradeForm.orderSide],
    queryFn: () => getMinAmountFrom(tradeForm.market?.pairSymbol || "", tradeForm.orderSide!),
    enabled:
      !!backgroundHandler?.isReady &&
      !!tradeForm.market?.pairSymbol &&
      !!tradeForm.orderSide &&
      !!tradeForm.selectedAccount,
  });

  const minAmount = shouldUseTradeSquare ? multipleTransactionsMinAmount : classicMinAmount;

  // we need to refetch the min amount when the market rate changes
  // /!\ We shouldn't put the market rate as dependency of the useQuery because the min amount
  // will have the undefined value during the fetching, which could lead to clipping issues
  useEffect(() => {
    refetchClassicMinAmount();
  }, [marketRate]);

  return {
    minAmount,
    isLoading: shouldUseTradeSquare ? multipleTransactionMinAmountIsLoading : classicMinAmountIsLoading,
  };
};
