import { UserSettings } from "@cede/types";

export const fakeSettings: UserSettings = {
  currency: {
    symbol: "USD",
    rate: 1.1,
  },
  language: "fr",
  balanceBelow: 1,
  extensionUrl: "",
};

export const mockedFiatCurrencies = [
  {
    symbol: "USD",
    name: "United States Dollar",
    symbolNative: "$",
  },
  {
    symbol: "EUR",
    name: "Euro",
    symbolNative: "€",
  },
  {
    symbol: "GBP",
    name: "British Pound Sterling",
    symbolNative: "£",
  },
];
