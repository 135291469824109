import { IconText } from "@cede/ds";
import { isDefiInfoTx } from "@cede/types";
import { firstLetterToUpperCase, forgeNetworkURL } from "@cede/utils";
import { useDependencies } from "../../hooks";
import { Typography } from "@mui/material";

export const NetworkLine = () => {
  const { useSelectedTransaction } = useDependencies();
  const { networkLabel, transaction } = useSelectedTransaction(({ networkLabel, transaction }) => ({
    networkLabel,
    transaction,
  }));
  if (!transaction || !networkLabel || !isDefiInfoTx(transaction.to)) return null;

  return (
    <>
      <Typography variant={"body2"}>Network</Typography>
      <IconText
        justifySelf={"flex-end"}
        iconName={networkLabel}
        iconUrl={forgeNetworkURL(transaction.to.account.network)}
        text={firstLetterToUpperCase(networkLabel) ?? ""}
        padding={0}
        hover={false}
      />
    </>
  );
};
