import { ApiPermissions } from "@cede/types";
import { CEDE_CEXS } from "../cexs";

/**
 * Some endpoints' rate limit is based on the IP address. These endpoints
 * cannot be proxified. That's why we require the user to create the second key,
 * which doesn't have any IP whitelist and is not proxyfied.
 */
export const TWO_KEYS_PERMISSIONS_WITHOUT_PROXY: { [exchangeId: string]: ApiPermissions[] | undefined } = {
  [CEDE_CEXS.BINANCE]: [ApiPermissions.READ, ApiPermissions.TRADE],
};

/**
 * Several CEXs require IP whitelisting for API keys.
 * As the user's IP address changes frequently, we use the proxy
 * to whitelist the proxy's IP address instead of the user's.
 *
 * To proxify request, we must be sure that the rate limit is not based on the IP.
 */
export const TWO_KEYS_PERMISSIONS_WITH_PROXY: { [exchangeId: string]: ApiPermissions[] | undefined } =
  Object.fromEntries(
    Object.entries(TWO_KEYS_PERMISSIONS_WITHOUT_PROXY).map(([cex, permissions]) => [
      cex,
      Object.values(ApiPermissions).filter((permission) => !permissions?.includes(permission)),
    ]),
  );
