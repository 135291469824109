// ----------------------------------------------------------------------------

// PLEASE DO NOT EDIT THIS FILE, IT IS GENERATED AND WILL BE OVERWRITTEN:
// https://github.com/ccxt/ccxt/blob/master/CONTRIBUTING.md#how-to-contribute-code
// EDIT THE CORRESPONDENT .ts FILE INSTEAD

import { SdkErrorCodes, SdkErrorMessages } from "./errorsMetadata.js";
import { CedeSDKError } from "./default.js";
/**
 * @description Thrown for internal errors
 */
export class InternalError extends CedeSDKError {
    constructor(code = SdkErrorCodes.INTERNAL_ERROR, message = SdkErrorMessages.INTERNAL_ERROR) {
        super(code, message);
        this.name = "InternalError";
    }
}
/**
 * @description Thrown when the exchange instance id is invalid and therefore the exchange instance is not found
 */
export class InvalidExchangeInstanceIdError extends InternalError {
    constructor(exchangeInstanceId) {
        super(SdkErrorCodes.INVALID_EXCHANGE_INSTANCE_ID, SdkErrorMessages.INVALID_EXCHANGE_INSTANCE_ID(exchangeInstanceId));
        this.name = "InvalidExchangeInstanceIdError";
        this.notReportable = true;
    }
}
/**
 * @description Thrown when the exchange id is invalid and therefore the exchange is not found
 */
export class InvalidExchangeIdError extends InternalError {
    constructor(exchangeId) {
        super(SdkErrorCodes.INVALID_EXCHANGE_ID, SdkErrorMessages.INVALID_EXCHANGE_ID(exchangeId));
        this.name = "InvalidExchangeIdError";
    }
}
