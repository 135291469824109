import { useMemo } from "react";
import { ApiPermissions } from "@cede/types";
import { CEDE_CEXS, CEDE_CEX_LABELS } from "@cede/utils";
import { TutorialReader } from "../Tutorial";
import { useWhitelistAddressTutorials } from "./Tutorials/useWhitelistAddressTutorials";
import { WhitelistAddressTutorialProps } from "./types";
import { Box, useTheme } from "@mui/material";

const EMPTY_PERMISSIONS: ApiPermissions[] = [];

export const FullPageWhitelistTutorial = ({
  name,
  address,
  onClose,
  exchangeId,
  checkWhitelist,
  asset,
  tokenSymbol,
  network,
}: WhitelistAddressTutorialProps) => {
  const theme = useTheme();

  const { tutorialType, mdContent, status } = useWhitelistAddressTutorials({
    checkWhitelist,
    onClose,
    exchangeId,
  });

  const getCustomHref = () => {
    switch (exchangeId) {
      case CEDE_CEXS.BINANCE:
        return "https://www.binance.com/en/my/security/address-management";
      case CEDE_CEXS.OKX:
        return "https://www.okx.com/balance/withdrawal";
      case CEDE_CEXS.BITGET:
        return "https://www.bitget.com/asset/address";
      case CEDE_CEXS.BYBIT:
        return "https://www.bybit.com/en/login?redirect_url=https://www.bybit.com/user/assets/money-address";
      case CEDE_CEXS.KRAKEN:
        return `https://www.kraken.com/u/funding/withdraw?asset=${asset ? asset : "BTC"}`;
      case CEDE_CEXS.HTX:
        return "https://www.htx.com/en-us/finance/address/";
      case CEDE_CEXS.GATEIO:
        return "https://www.gate.io/myaccount/withdraw_address";
      case CEDE_CEXS.KUCOIN:
        return "https://www.kucoin.com/withdraw-addr-manage";
      default:
        return "";
    }
  };

  const tutorialReaderData = useMemo(() => {
    return {
      customHref: getCustomHref(),
      name,
      address,
      status,
      ...(tokenSymbol && { tokenSymbol }),
      ...(network && { network }),
    };
  }, [name, address, status, network, tokenSymbol]);

  return (
    <Box
      display={"flex"}
      flexDirection="column"
      flex={"1"}
      paddingBottom={theme.custom.gutterMargin}
      height="100%"
      sx={{
        overflowY: "scroll",
      }}
    >
      <TutorialReader
        folder={tutorialType}
        mdContent={mdContent}
        permissions={EMPTY_PERMISSIONS}
        accountPermissions={EMPTY_PERMISSIONS}
        exchangeId={CEDE_CEX_LABELS[exchangeId] || exchangeId}
        data={tutorialReaderData}
      />
    </Box>
  );
};
