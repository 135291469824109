// ----------------------------------------------------------------------------

// PLEASE DO NOT EDIT THIS FILE, IT IS GENERATED AND WILL BE OVERWRITTEN:
// https://github.com/ccxt/ccxt/blob/master/CONTRIBUTING.md#how-to-contribute-code
// EDIT THE CORRESPONDENT .ts FILE INSTEAD

// -------------------------------------------------------------------------------
import { Exchange as _Exchange } from '../base/Exchange.js';
class Exchange extends _Exchange {
}
export default Exchange;
