import { Any, CedeNews, CMSPartnerDto, CMSQuestDto, CMSReferralRewardPointDTO, CMSTokenData } from "@cede/types";
import {
  mapCMSPartnerDTOToPartner,
  mapCMSQuestDTOToQuest,
  mapMSReferralRewardPointDTOToReferralRewardPoint,
} from "./CMSMapper";

export const fetchNews = async (queryParams: { filters: string; apiUrl?: string }) => {
  const query = `what-s-news?populate=*&sort=publishedAt:desc&${queryParams.filters}`;
  return await requestCMS<CedeNews[]>(query);
};

export const fetchPartners = async (queryParams: { filters: string }) => {
  const query = `partners?populate=*&${queryParams.filters}`;
  const partners = await requestCMS<CMSPartnerDto[]>(query);
  return partners.map(mapCMSPartnerDTOToPartner);
};

export const fetchReferralRewards = async () => {
  const query = `referral-reward-point`;
  const referralReward = await requestCMS<CMSReferralRewardPointDTO>(query);
  return mapMSReferralRewardPointDTOToReferralRewardPoint(referralReward);
};

export const fetchQuests = async (queryParams: { filters: string }) => {
  const query = `quests?populate[quest_requirements][populate]=*&populate[reward][populate]=*&populate[partners]=*&${queryParams.filters}`;
  const quests = await requestCMS<CMSQuestDto[]>(query);
  return quests.map(mapCMSQuestDTOToQuest);
};

export const fetchPopularTokens = async () => {
  const query = "token";
  const tokensData = await requestCMS<CMSTokenData>(query);
  if (!tokensData) return [];
  const SEPERATOR = ";";
  const popular = tokensData.attributes.popular;
  return popular.split(SEPERATOR);
};

const requestCMS = async <T>(query: string) => {
  const res = await fetch(`${process.env.CMS_API_URL}/api/${query}`, {
    headers: {
      Authorization: `Bearer ${process.env.CMS_API_TOKEN}`,
    },
  });
  const json = await res.json();
  return json.data as T;
};

/**
 *
 * It converts a query object to a query string
 * ````
 * { filters: { title: { $eq: "123" }, description: { $gte: "123" } } }
 * ````
 * becomes
 * ````
 * "filters[title][$eq]=123&filters[description][$gte]=123"
 * ````
 *
 * @param queryObject
 * @param initialQuery
 * @param withBracket
 * @returns
 */
export const buildQueryString = (queryObject: Record<string, Any>, initialQuery = "", withBracket?: boolean) => {
  const startBracket = withBracket ? "[" : "";
  const endBracket = withBracket ? "]" : "";
  const subQueries: string[] = [];

  Object.entries(queryObject).forEach(([key, currentObject]) => {
    if (currentObject === undefined) return;

    let subQuery = `${initialQuery}${startBracket}${key}${endBracket}`;

    if (typeof currentObject === "object") {
      if (Array.isArray(currentObject)) {
        currentObject = currentObject.reduce(
          (newObject, item, index) => ({
            ...newObject,
            [index]: item,
          }),
          {},
        );
      }
      subQuery = buildQueryString(currentObject, subQuery, true);
    } else {
      subQuery += `=${currentObject}`;
    }
    subQueries.push(subQuery);
  });

  return subQueries.join("&");
};
