export enum LogoSizeEnum {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
}

export interface LogoProps {
  size?: LogoSizeEnum;
  isLoading?: boolean;
}
