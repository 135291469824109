import { create } from "zustand";

export type OnboardingModalState = {
  isOnboardingModalOpened: boolean;
  setIsOnboardingModalOpened: (value: boolean) => void;
};

export const useOnboardingModal = create<OnboardingModalState>((set) => ({
  isOnboardingModalOpened: false,
  setIsOnboardingModalOpened: (isOnboardingModalOpened: boolean) => {
    set(() => ({
      isOnboardingModalOpened,
    }));
  },
}));
