// ----------------------------------------------------------------------------

// PLEASE DO NOT EDIT THIS FILE, IT IS GENERATED AND WILL BE OVERWRITTEN:
// https://github.com/ccxt/ccxt/blob/master/CONTRIBUTING.md#how-to-contribute-code
// EDIT THE CORRESPONDENT .ts FILE INSTEAD

/**
 * @description It aggregates all the error codes that can be thrown by the SDK.
 * It should be the only source of truth for the error codes.
 * You should separate each error type by a blank line.
 * Use `_` to separate the group of digits
 */
export var SdkErrorCodes;
(function (SdkErrorCodes) {
    SdkErrorCodes[SdkErrorCodes["DEFAULT_ERROR"] = 10000] = "DEFAULT_ERROR";
    SdkErrorCodes[SdkErrorCodes["INVALID_PARAMS"] = 10001] = "INVALID_PARAMS";
    SdkErrorCodes[SdkErrorCodes["INVALID_CACHE"] = 10002] = "INVALID_CACHE";
    SdkErrorCodes[SdkErrorCodes["INVALID_ADDRESS"] = 10003] = "INVALID_ADDRESS";
    SdkErrorCodes[SdkErrorCodes["FETCH_FROM_CACHE_ERROR"] = 10004] = "FETCH_FROM_CACHE_ERROR";
    SdkErrorCodes[SdkErrorCodes["INTERNAL_ERROR"] = 11000] = "INTERNAL_ERROR";
    SdkErrorCodes[SdkErrorCodes["INVALID_EXCHANGE_ID"] = 11001] = "INVALID_EXCHANGE_ID";
    SdkErrorCodes[SdkErrorCodes["INVALID_EXCHANGE_INSTANCE_ID"] = 11002] = "INVALID_EXCHANGE_INSTANCE_ID";
    SdkErrorCodes[SdkErrorCodes["AUTHENTICATION_ERROR"] = 12000] = "AUTHENTICATION_ERROR";
    SdkErrorCodes[SdkErrorCodes["INVALID_CREDENTIALS"] = 12001] = "INVALID_CREDENTIALS";
    SdkErrorCodes[SdkErrorCodes["AUTHENTICATION_FLOW_NOT_SUPPORTED"] = 12002] = "AUTHENTICATION_FLOW_NOT_SUPPORTED";
    SdkErrorCodes[SdkErrorCodes["OAUTH_ACCESS_REVOKED"] = 12003] = "OAUTH_ACCESS_REVOKED";
    SdkErrorCodes[SdkErrorCodes["WITHDRAWAL_ERROR"] = 13000] = "WITHDRAWAL_ERROR";
    SdkErrorCodes[SdkErrorCodes["READONLY_EXCHANGE_NOT_PROVIDED_ERROR"] = 13001] = "READONLY_EXCHANGE_NOT_PROVIDED_ERROR";
    SdkErrorCodes[SdkErrorCodes["TOKEN_SYMBOL_NOT_FOUND"] = 13002] = "TOKEN_SYMBOL_NOT_FOUND";
    SdkErrorCodes[SdkErrorCodes["WITHDRAWAL_NOT_FOUND"] = 13003] = "WITHDRAWAL_NOT_FOUND";
    SdkErrorCodes[SdkErrorCodes["UNDER_WITHDRAW_MINIMUM"] = 13004] = "UNDER_WITHDRAW_MINIMUM";
    SdkErrorCodes[SdkErrorCodes["OVER_WITHDRAW_MAXIMUM"] = 13005] = "OVER_WITHDRAW_MAXIMUM";
    SdkErrorCodes[SdkErrorCodes["WHITELIST_CHECK_FAILED"] = 13006] = "WHITELIST_CHECK_FAILED";
    SdkErrorCodes[SdkErrorCodes["WITHDRAWAL_FORBIDDEN"] = 13007] = "WITHDRAWAL_FORBIDDEN";
    SdkErrorCodes[SdkErrorCodes["TRADE_ERROR"] = 14000] = "TRADE_ERROR";
    SdkErrorCodes[SdkErrorCodes["MARKET_ERROR"] = 14001] = "MARKET_ERROR";
    SdkErrorCodes[SdkErrorCodes["MARKET_NOT_FOUND"] = 14002] = "MARKET_NOT_FOUND";
    SdkErrorCodes[SdkErrorCodes["ORDER_NOT_FOUND"] = 14003] = "ORDER_NOT_FOUND";
    SdkErrorCodes[SdkErrorCodes["MARKET_NOT_LOADED"] = 14004] = "MARKET_NOT_LOADED";
    SdkErrorCodes[SdkErrorCodes["MARKET_TYPE_NOT_SUPPORTED"] = 14005] = "MARKET_TYPE_NOT_SUPPORTED";
    SdkErrorCodes[SdkErrorCodes["INVALID_OHLCV_FORMAT"] = 14006] = "INVALID_OHLCV_FORMAT";
    SdkErrorCodes[SdkErrorCodes["CANNOT_PROVIDE_OHLCV"] = 14007] = "CANNOT_PROVIDE_OHLCV";
    SdkErrorCodes[SdkErrorCodes["PORTFOLIO_ERROR"] = 15000] = "PORTFOLIO_ERROR";
    SdkErrorCodes[SdkErrorCodes["NOT_ENOUGH_BALANCE"] = 15001] = "NOT_ENOUGH_BALANCE";
    SdkErrorCodes[SdkErrorCodes["DEPOSIT_ERROR"] = 16000] = "DEPOSIT_ERROR";
    SdkErrorCodes[SdkErrorCodes["GET_DEPOSIT_ADDRESS"] = 16001] = "GET_DEPOSIT_ADDRESS";
    SdkErrorCodes[SdkErrorCodes["CREATE_DEPOSIT_ADDRESS"] = 16002] = "CREATE_DEPOSIT_ADDRESS";
    SdkErrorCodes[SdkErrorCodes["NETWORK_ERROR"] = 17000] = "NETWORK_ERROR";
    SdkErrorCodes[SdkErrorCodes["NETWORK_NOT_FOUND"] = 17001] = "NETWORK_NOT_FOUND";
    SdkErrorCodes[SdkErrorCodes["NETWORK_NOT_FOUND_FOR_TOKEN_SYMBOL"] = 17002] = "NETWORK_NOT_FOUND_FOR_TOKEN_SYMBOL";
    SdkErrorCodes[SdkErrorCodes["API_ERROR"] = 18000] = "API_ERROR";
    SdkErrorCodes[SdkErrorCodes["INVALID_API_REQUEST"] = 18001] = "INVALID_API_REQUEST";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_ERROR"] = 19000] = "EXCHANGE_ERROR";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_SERVER_DOWN"] = 19001] = "EXCHANGE_SERVER_DOWN";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_UNDER_MAINTENANCE"] = 19002] = "EXCHANGE_UNDER_MAINTENANCE";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_REQUEST_EXPIRED"] = 19003] = "EXCHANGE_REQUEST_EXPIRED";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_INVALID_PERMISSION"] = 19004] = "EXCHANGE_INVALID_PERMISSION";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_TRANSFER_NOT_ALLOWED"] = 19005] = "EXCHANGE_TRANSFER_NOT_ALLOWED";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_TRANSFER_ERROR"] = 19006] = "EXCHANGE_TRANSFER_ERROR";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_DDOS_PROTECTION"] = 19007] = "EXCHANGE_DDOS_PROTECTION";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_RATE_LIMIT_EXCEEDED"] = 19008] = "EXCHANGE_RATE_LIMIT_EXCEEDED";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_INTERNAL_ERROR"] = 19009] = "EXCHANGE_INTERNAL_ERROR";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_UNKNOWN_ASSET"] = 19010] = "EXCHANGE_UNKNOWN_ASSET";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_OPERATION_FAILED"] = 19011] = "EXCHANGE_OPERATION_FAILED";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_ACCOUNT_SUSPENDED"] = 19012] = "EXCHANGE_ACCOUNT_SUSPENDED";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_NOT_ALLOWED_METHOD"] = 19013] = "EXCHANGE_NOT_ALLOWED_METHOD";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_NOT_AVAILABLE"] = 19014] = "EXCHANGE_NOT_AVAILABLE";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_INVALID_NONCE"] = 19015] = "EXCHANGE_INVALID_NONCE";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_UNAVAILABLE_IN_COUNTRY"] = 19016] = "EXCHANGE_UNAVAILABLE_IN_COUNTRY";
    SdkErrorCodes[SdkErrorCodes["EXCHANGE_WALLET_TYPE_NOT_SUPPORTED"] = 19017] = "EXCHANGE_WALLET_TYPE_NOT_SUPPORTED";
})(SdkErrorCodes || (SdkErrorCodes = {}));
/**
 * @description It aggregates all the error messages that can be thrown by the SDK.
 * You should separate each error type by a blank line.
 */
export const SdkErrorMessages = {
    DEFAULT_ERROR: "Default error",
    INVALID_PARAMS: (description) => `Invalid params: ${description}`,
    INVALID_CACHE: (key) => `Invalid cache state for key: ${key}`,
    INVALID_ADDRESS: "Invalid address",
    FETCH_FROM_CACHE_ERROR: (message) => `An error occured while fetching from the cache: ${message}`,
    INTERNAL_ERROR: "Internal error",
    INVALID_EXCHANGE_INSTANCE_ID: (exchangeInstanceId) => `Invalid exchange instance id: ${exchangeInstanceId}`,
    INVALID_EXCHANGE_ID: (exchangeId) => `Invalid exchange id: ${exchangeId}`,
    AUTHENTICATION_ERROR: "Authentication error",
    INVALID_CREDENTIALS: "Invalid credentials provided",
    AUTHENTICATION_FLOW_NOT_SUPPORTED: "Authentication flow not supported",
    OAUTH_ACCESS_REVOKED: "OAuth access revoked",
    WITHDRAWAL_ERROR: "Withdrawal error",
    READONLY_EXCHANGE_NOT_PROVIDED_ERROR: "Readonly exchange account not provided",
    TOKEN_SYMBOL_NOT_FOUND: (tokenSymbol) => `Token symbol not found: ${tokenSymbol}`,
    WITHDRAWAL_NOT_FOUND: "Withdrawal not found",
    UNDER_WITHDRAW_MINIMUM: "Under withdrawal minimum",
    OVER_WITHDRAW_MAXIMUM: "Over withdrawal maximum",
    WHITELIST_CHECK_FAILED: "The receipt address has not been whitelisted",
    WITHDRAWAL_FORBIDDEN: "Withdrawal forbidden",
    TRADE_ERROR: "Trade error",
    MARKET_ERROR: "Market error",
    MARKET_NOT_FOUND: (market) => `Market not found: ${market}`,
    ORDER_NOT_FOUND: "Order not found",
    MARKET_NOT_LOADED: `Market not loaded`,
    MARKET_TYPE_NOT_SUPPORTED: (marketType) => `Market type not supported: ${marketType}`,
    ORDER_UNDER_MINIMUM: 'Order quantity below minimum limit. Please use getMinAmounts() to get the minimum quantity for the order.',
    INVALID_OHLCV_FORMAT: (message) => `Invalid OHLCV format: ${message}`,
    CANNOT_PROVIDE_OHLCV: `Cannot provide OHLCV data`,
    PORTFOLIO_ERROR: "Portfolio error",
    NOT_ENOUGH_BALANCE: "Not enough balance",
    DEPOSIT_ERROR: "Deposit error",
    GET_DEPOSIT_ADDRESS: (tokenSymbol, network) => `An error occured while fetching the deposit address for token symbol ${tokenSymbol}${network ? " on network " + network : ""}`,
    CREATE_DEPOSIT_ADDRESS: (tokenSymbol, network) => `An error occured while creating the deposit address for token symbol ${tokenSymbol}${network ? " on network " + network : ""}`,
    NETWORK_ERROR: "Network error",
    NETWORK_NOT_FOUND: (network) => `Network not found: ${network}`,
    NETWORK_NOT_FOUND_FOR_TOKEN_SYMBOL: (tokenSymbol) => `Network not found for token symbol: ${tokenSymbol}`,
    API_ERROR: "API error",
    INVALID_API_REQUEST: (errorMessage) => `Invalid request: ${errorMessage}`,
    EXCHANGE_ERROR: "Exchange error",
    EXCHANGE_SERVER_DOWN: "Exchange server down",
    EXCHANGE_UNDER_MAINTENANCE: "Exchange under maintenance",
    EXCHANGE_REQUEST_EXPIRED: "Exchange request expired",
    EXCHANGE_INVALID_PERMISSION: "Missing API permissions, please reimport your API key with the valid permissions",
    EXCHANGE_TRANSFER_NOT_ALLOWED: `Transfer not allowed`,
    EXCHANGE_TRANSFER_ERROR: `Transfer error`,
    EXCHANGE_DDOS_PROTECTION: "DDoS protection error",
    EXCHANGE_RATE_LIMIT_EXCEEDED: "Rate limit exceeded",
    EXCHANGE_INTERNAL_ERROR: "Internal exchange error",
    EXCHANGE_UNKNOWN_ASSET: (asset) => `Unknown asset ${asset}`,
    EXCHANGE_OPERATION_FAILED: "Operation failed",
    EXCHANGE_ACCOUNT_SUSPENDED: "Account suspended",
    EXCHANGE_NOT_ALLOWED_METHOD: (methodName) => `Method not allowed: ${methodName}`,
    EXCHANGE_NOT_AVAILABLE: "Exchange not available",
    EXCHANGE_INVALID_NONCE: "Invalid nonce",
    EXCHANGE_UNAVAILABLE_IN_COUNTRY: "Exchange unavailable in the country",
    EXCHANGE_WALLET_TYPE_NOT_SUPPORTED: (walletType) => `Wallet type ${walletType} not supported or not available for transfers`,
};
/**
 * @description It aggregates all the documentation of the SDK errors.
 * It should be used in the sdk documentation website.
 * The first element in the array is the error class that is thrown and the second element is the error description.
 *
 * [Error Class, Error Description, ParentErrorClass?]
 */
export const SdkErrorDocumentation = {
    "Cede SDK Errors": {
        [SdkErrorCodes.DEFAULT_ERROR]: ["DefaultError", "Default SDK error"],
        [SdkErrorCodes.INVALID_PARAMS]: ["InvalidParamsError", "Invalid parameters provided to a SDK method", "DefaultError"],
        [SdkErrorCodes.INVALID_CACHE]: ["InvalidCacheError", "Thrown when the cache can't be persisted", "DefaultError"],
        [SdkErrorCodes.INVALID_ADDRESS]: ["InvalidAddressError", "The provided address is invalid", "DefaultError"],
        [SdkErrorCodes.FETCH_FROM_CACHE_ERROR]: ["FetchFromCacheError", "An error occured while fetching from the cache", "DefaultError"],
    },
    "Internal Errors": {
        [SdkErrorCodes.INTERNAL_ERROR]: ["InternalError", "Default error for internal SDK errors"],
        [SdkErrorCodes.INVALID_EXCHANGE_INSTANCE_ID]: [
            "InvalidExchangeInstanceIdError",
            "An invalid exchange instance id is provided",
            "InternalError"
        ],
        [SdkErrorCodes.INVALID_EXCHANGE_ID]: ["InvalidExchangeIdError", "An invalid exchange id is provided", "InternalError"],
    },
    "Authentication Errors": {
        [SdkErrorCodes.AUTHENTICATION_ERROR]: ["AuthenticationError", "Default error for authentication errors"],
        [SdkErrorCodes.INVALID_CREDENTIALS]: ["InvalidCredentialsError", "Invalid credentials provided", "AuthenticationError"],
        [SdkErrorCodes.AUTHENTICATION_FLOW_NOT_SUPPORTED]: [
            "AuthenticationFlowNotSupportedError",
            "Authentication flow not supported",
            "AuthenticationError",
        ],
        [SdkErrorCodes.OAUTH_ACCESS_REVOKED]: [
            "OAuthAccessRevokedError",
            "Can't perform the operation since the OAuth access are revoked",
            "AuthenticationError",
        ],
    },
    "Withdrawal Errors": {
        [SdkErrorCodes.WITHDRAWAL_ERROR]: ["WithdrawalError", "Default error for withdrawal errors"],
        [SdkErrorCodes.READONLY_EXCHANGE_NOT_PROVIDED_ERROR]: [
            "ReadonlyExchangeNotProvidedError",
            "Readonly account not provided. It needs to be provided to perform some operations",
            "WithdrawalError",
        ],
        [SdkErrorCodes.TOKEN_SYMBOL_NOT_FOUND]: [
            "TokenSymbolNotFoundError",
            "The withdrawal can't be executed since the token symbol isn't found",
            "WithdrawalError",
        ],
        [SdkErrorCodes.WITHDRAWAL_NOT_FOUND]: ["WithdrawalNotFoundError", "The withdrawal isn't found", 'WithdrawalError'],
        [SdkErrorCodes.UNDER_WITHDRAW_MINIMUM]: [
            "UnderWithdrawMinimumError",
            "The withdrawal amount is under the minimum amount allowed by the exchange",
            "WithdrawalError"
        ],
        [SdkErrorCodes.OVER_WITHDRAW_MAXIMUM]: [
            "OverWithdrawMaximumError",
            "The withdrawal amount is over the maximum amount allowed by the exchange",
            "WithdrawalError"
        ],
        [SdkErrorCodes.WHITELIST_CHECK_FAILED]: [
            "WhitelistCheckFailedError",
            "The provided address isn't whitelisted for the withdrawal",
            "WithdrawalError"
        ],
    },
    "Trade Errors": {
        [SdkErrorCodes.TRADE_ERROR]: ["TradeError", "Default error for trade errors"],
        [SdkErrorCodes.MARKET_NOT_FOUND]: ["MarketNotFoundError", "The given pair symbol was not found", "TradeError"],
        [SdkErrorCodes.ORDER_NOT_FOUND]: ["OrderNotFoundError", "The order isn't found", "TradeError"],
        [SdkErrorCodes.CANNOT_PROVIDE_OHLCV]: ["CannotProvideOHLCVError", "Cannot provide OHLCV data", "TradeError"],
        [SdkErrorCodes.INVALID_OHLCV_FORMAT]: ["InvalidOHLCVFormatError", "The OHLCV format is invalid", "TradeError"],
        [SdkErrorCodes.MARKET_ERROR]: ["MarketError", "Default error for market errors", "TradeError"],
        [SdkErrorCodes.MARKET_TYPE_NOT_SUPPORTED]: ["MarketTypeNotSupportedError", "The market type isn't supported", "MarketError"],
        [SdkErrorCodes.MARKET_NOT_LOADED]: ["MarketNotLoadedError", "The market isn't loaded", "MarketError"],
    },
    "Portfolio Errors": {
        [SdkErrorCodes.PORTFOLIO_ERROR]: ["PortfolioError", "Default error for portfolio errors"],
        [SdkErrorCodes.NOT_ENOUGH_BALANCE]: [
            "InsufficientBalanceError",
            "The user doesn't have enough balance to perform the operation",
            "PortfolioError"
        ],
    },
    "Deposit Errors": {
        [SdkErrorCodes.DEPOSIT_ERROR]: ["DepositError", "Default error for deposit errors"],
        [SdkErrorCodes.GET_DEPOSIT_ADDRESS]: ["GetDepositAddressError", "Can't get the deposit address", "DepositError"],
        [SdkErrorCodes.CREATE_DEPOSIT_ADDRESS]: ["CreateDepositAddressError", "Can't create the deposit address", "DepositError"],
    },
    "Network Errors": {
        [SdkErrorCodes.NETWORK_ERROR]: ["NetworkError", "Default error for network errors"],
        [SdkErrorCodes.NETWORK_NOT_FOUND]: ["NetworkNotFoundError", "The network isn't found", "NetworkError"],
    },
    "API Errors": {
        [SdkErrorCodes.API_ERROR]: ["APIError", "Default error for API errors"],
        [SdkErrorCodes.INVALID_API_REQUEST]: ["InvalidApiRequestError", "The API request is invalid", "APIError"],
    },
    "Exchange Errors": {
        [SdkErrorCodes.EXCHANGE_ERROR]: ["ExchangeError", "Default error for exchange errors"],
        [SdkErrorCodes.EXCHANGE_SERVER_DOWN]: ["ExchangeServerError", "The exchange server is down", "ExchangeError"],
        [SdkErrorCodes.EXCHANGE_UNDER_MAINTENANCE]: [
            "ExchangeUnderMaintenanceError",
            "The exchange API is under maintenance",
            "ExchangeError"
        ],
        [SdkErrorCodes.EXCHANGE_REQUEST_EXPIRED]: ["ExchangeRequestExpiredError", "The exchange request is expired", "ExchangeError"],
        [SdkErrorCodes.EXCHANGE_INVALID_PERMISSION]: [
            "ExchangeInvalidPermissionsError",
            "The permissions are invalid to perform the operation",
            "ExchangeError"
        ],
        [SdkErrorCodes.EXCHANGE_TRANSFER_NOT_ALLOWED]: ["ExchangeTransferNotAllowedError", "Transfer is not allowed", "ExchangeError"],
        [SdkErrorCodes.EXCHANGE_TRANSFER_ERROR]: ["ExchangeTransferError", "Transfer error", "ExchangeError"],
        [SdkErrorCodes.EXCHANGE_DDOS_PROTECTION]: ["ExchangeDDosProtectionError", "DDoS protection error", "ExchangeError"],
        [SdkErrorCodes.EXCHANGE_RATE_LIMIT_EXCEEDED]: ["ExchangeRateLimitExceededError", "Rate limit exceeded", "ExchangeError"],
        [SdkErrorCodes.EXCHANGE_OPERATION_FAILED]: ["ExchangeOperationFailedError", "Operation failed", "ExchangeError"],
        [SdkErrorCodes.EXCHANGE_UNKNOWN_ASSET]: ["ExchangeUnknownAssetError", "Unknown asset", "ExchangeError"],
        [SdkErrorCodes.EXCHANGE_ACCOUNT_SUSPENDED]: ["ExchangeAccountSuspendedError", "Account suspended", "ExchangeError"],
        [SdkErrorCodes.EXCHANGE_NOT_ALLOWED_METHOD]: [
            "ExchangeNotAllowedMethodError",
            "Method isn't allowed to be called for an exchange",
            "ExchangeError"
        ],
        [SdkErrorCodes.EXCHANGE_NOT_AVAILABLE]: ["ExchangeNotAvailableError", "Exchange is not available", "ExchangeError"],
        [SdkErrorCodes.EXCHANGE_INVALID_NONCE]: ["ExchangeInvalidNonceError", "The nonce is invalid", "ExchangeError"],
        [SdkErrorCodes.EXCHANGE_UNAVAILABLE_IN_COUNTRY]: ["ExchangeUnavailableInCountryError", "Exchange is unavailable in the country", "ExchangeError"],
        [SdkErrorCodes.EXCHANGE_WALLET_TYPE_NOT_SUPPORTED]: ["ExchangeWalletTypeNotSupportedError", "Wallet type not supported or not available for transfers", "ExchangeError"],
    },
};
