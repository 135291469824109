import { Button, StoreLogo } from "@cede/ds";
import { userFacingMessages, WidgetType } from "@cede/types";
import { useDependencies } from "../../hooks/useDependencies";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const DefaultLogin = () => {
  const { useWidgetLogin, widgetType } = useDependencies();
  const theme = useTheme();
  const { handleLogin } = useWidgetLogin();

  return (
    <Box
      component="section"
      sx={{
        height: "100%",
      }}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"stretch"}
      justifyContent={"space-between"}
      paddingY={theme.custom.gutterMargin}
    >
      <Box
        component="div"
        sx={{
          marginTop: "110px",
          textAlign: "center",
        }}
      >
        <StoreLogo size="large" />
        <Typography component="h1" variant="h1" fontSize="20px">
          Welcome to{" "}
          <Typography
            component={"a"}
            target="_blank"
            href="https://cede.store"
            variant="h1"
            fontSize="20px"
            sx={{
              "display": "inline",
              "color": "primary.main",
              "textDecoration": "none",
              ":hover": { textDecoration: "underline" },
            }}
          >
            {"cede.store "}
          </Typography>
          {widgetType === WidgetType.SEND ? "send" : "trade"} portal
        </Typography>
        <Typography component="h2" variant="body2" sx={{ marginTop: "4px" }}>
          {widgetType === WidgetType.SEND
            ? userFacingMessages.WIDGETS.SEND.TRANSFER_CEX_ASSETS
            : userFacingMessages.WIDGETS.TRADE.WELCOME_MESSAGE}
        </Typography>
      </Box>

      <Box
        sx={{
          marginTop: "40px",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: theme.custom.gutterMargin,
            paddingTop: theme.custom.bigGutterMargin,
          }}
        >
          <Button
            data-testid={"widget-connect-button"}
            onClick={() => handleLogin({ onboard: true })}
            variant={"contained"}
            color={"primary"}
            height={"tall"}
          >
            {widgetType === WidgetType.SEND
              ? userFacingMessages.WIDGETS.SEND.CONNECT
              : userFacingMessages.WIDGETS.TRADE.CONNECT}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
