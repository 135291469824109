import { useEffect, useRef } from "react";
import { SearchTransactionParams, TxStatus, ViewEnvironment, isCefiInfoTx } from "@cede/types";
import { QueryKeys, STORE_ROUTES, WIDGET_ROUTES } from "@cede/utils";
import { useDependencies } from "../hooks/useDependencies";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useTriggerUpdateTransaction = () => {
  const { useSelectedTransaction, backgroundHandler, useNavigate, useViewEnvironment } = useDependencies();
  const transaction = useSelectedTransaction((state) => state.transaction);
  const setTransaction = useSelectedTransaction((state) => state.setTransaction);
  const startTime = useRef(Date.now());
  const navigate = useNavigate();
  const { environment } = useViewEnvironment();
  const queryClient = useQueryClient();

  // @TODO: The number value should use refresh_status.max_time from the app config but we have no way to retrieve it for now
  const shouldContinuePolling = Date.now() - startTime.current > 60 * 10 * 1000;

  const { data: foundTransaction } = useQuery({
    queryKey: [
      "searchTransaction",
      {
        transactionId:
          transaction && isCefiInfoTx(transaction.from) ? transaction?.from.transaction.transactionId : undefined,
        tokenOrPairSymbol:
          transaction && isCefiInfoTx(transaction.from) ? transaction?.from.transaction.tokenSymbol : undefined,
      },
    ],
    queryFn: async () => {
      if (!transaction || !isCefiInfoTx(transaction.from)) return;

      const params: SearchTransactionParams = {
        accountId: transaction?.from.account.accountId,
        transactionId: transaction?.from.transaction.transactionId,
        transactionType: transaction?.type === "order" ? "trade" : "withdrawal",
        tokenOrPairSymbol: transaction.pairSymbol ?? transaction.from.transaction.tokenSymbol,
        timestamp: transaction.from.transaction.timestamp,
      };
      return await backgroundHandler.searchTransaction(params);
    },
    gcTime: 0,
    staleTime: 0,
    // @TODO: Retrieve the refresh_status.interval from config, we currently can't get this information from anywhere using the provider
    refetchInterval: 5000,
    enabled:
      !!backgroundHandler.isReady &&
      !!transaction &&
      transaction?.from.transaction.status === TxStatus.PENDING &&
      !shouldContinuePolling,
  });

  useEffect(() => {
    if (!foundTransaction || foundTransaction.from.transaction.status === TxStatus.PENDING) return;

    queryClient.invalidateQueries({ queryKey: [QueryKeys.VAULT_TRANSACTIONS] });
    setTransaction(foundTransaction);
  }, [foundTransaction]);

  useEffect(() => {
    if (!transaction) {
      navigate(environment === ViewEnvironment.POPUP ? STORE_ROUTES.VAULT_DETAILS_ROUTE : WIDGET_ROUTES.ACTION_ROUTE);
    }
  }, [transaction]);

  return transaction;
};
