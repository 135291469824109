import { useMemo } from "react";
import {
  AddNewCexButton,
  Button,
  DropListWithIconModal,
  EmptyState,
  EmptyStateTypeEnum,
  ResizeFieldsWrapper,
  TextFieldWithIcon,
  ValidationDropList,
  ValidationTextFieldWithIcon,
} from "@cede/ds";
import { DropListWithIconItem, ViewEnvironment, userFacingMessages } from "@cede/types";
import { CEDE_CEX_LABELS, cropTextInMiddle } from "@cede/utils";
import { useDependencies } from "../../hooks";
import { HelpButton } from "../HelpButton/HelpButton";
import { MainFooter } from "../MainFooter/MainFooter";
import { FullPageWhitelistTutorial, WhitelistAddressTutorial } from "../WhitelistAddressTutorial";
import { TradeSendConfirmModal } from "./TradeSendConfirmModal";
import { WithdrawMinAmount } from "./WithdrawMinAmount";
import { getTradePathLabel } from "./hooks/utils";
import { AddressInputType, PresentationalSendProps } from "./types";
import { Add, Create, SwapHoriz } from "@mui/icons-material";
import { Box, Checkbox, Link, Skeleton, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";

const unfocus = () => {
  document.activeElement instanceof HTMLElement ? document.activeElement.blur() : undefined;
};

export const PresentationalSend = ({ controller, disableAddressBook = false }: PresentationalSendProps) => {
  const { useViewEnvironment } = useDependencies();
  const { environment } = useViewEnvironment();

  const isWidget = environment === ViewEnvironment.WIDGET;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const {
    dispatchInput,
    validateAddress,
    setMaxAssetValue,
    submit,
    validateAssetValue,
    form,
    isValid,
    droplistAddressBook,
    droplistWhitelistedAddresses,
    fromAccounts,
    currencies,
    networks,
    warningMessage,
    networksAreLoading,
    currenciesAreLoading,
    fiatCurrency,
    isOauth,
    addressInputType,
    isWhitelistedAddressesLoading,
    displayWhitelistTooltip,
    setTutorialIsOpen,
    handleAddAddressManually,
    areAccountsLoading,
    toAccounts,
    switchSelectedCexs,
    addCexRedirect,
    isDefiTab,
    isBetweenCexTab,
    disabledCexText,
    shouldDisableCex,
    validateAssetValueRef,
    setIsRecipientInputFocused,
    droplistDestinationTokens,
    droplistDestinationTokensAreLoading,
    droplistDestinationTokensError,
    shouldUseTradeAndSend,
    recipientAddress,
    isTutorialCheckboxChecked,
    setIsTutorialCheckboxChecked,
    isDemo,
    refValue,
  } = controller;

  const memoizedCurrencyNetworkFields = useMemo(
    () => (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
          marginRight={theme.custom.gutterMargin}
          data-testid={"currency-network"}
        >
          <DropListWithIconModal
            inputProps={{ label: "Currency", id: "select-currency", placeholder: "Currency" }}
            items={currencies}
            value={form.currency}
            emptyMessage={"No currencies available"}
            onChange={(value) => {
              dispatchInput({ field: "currency", value });
            }}
            title={"Select Currency"}
            loading={currenciesAreLoading}
            disableModalPortal={true}
            onClear={() => dispatchInput({ field: "currency", value: null })}
          />
          {form.currency ? (
            <Typography
              width="100%"
              display="block"
              textAlign="left"
              variant={"caption"}
              px={theme.custom.smallGutterMargin}
              data-testid={"select-currency-balance"}
            >
              Balance: {form.currency.balance} {form.currency.label}
            </Typography>
          ) : (
            <Box height={theme.custom.gutterMargin} />
          )}
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <DropListWithIconModal
            inputProps={{
              id: "select-network",
              label: "Network",
              placeholder: "Network",
            }}
            title={"Select Network"}
            value={form.network}
            items={networks}
            loading={networksAreLoading && networks.length === 0}
            emptyMessage={"No networks available"}
            onChange={(value) => dispatchInput({ field: "network", value })}
            disableModalPortal={true}
            onClear={() => dispatchInput({ field: "network", value: null })}
            disabledTextItem={() => "Under maintenance"}
          />
          {shouldUseTradeAndSend ? (
            <DropListWithIconModal
              inputProps={{
                id: "select-destination-token",
                label: "Destination Token",
                placeholder: "Search token",
              }}
              description="The token you chose is not available for the selected network. You will have to convert it to another token."
              disableModalPortal={true}
              value={form.destinationCurrency}
              onChange={(value) => dispatchInput({ field: "destinationCurrency", value })}
              title={"Select destination token"}
              loading={droplistDestinationTokensAreLoading}
              baseElement={({ onClick }) => (
                <Link
                  color={droplistDestinationTokensError ? theme.palette.error.main : theme.palette.primary.main}
                  onClick={onClick}
                  sx={{
                    "cursor": "pointer",
                    ":hover": {
                      opacity: ".6",
                    },
                    "fontWeight": "700",
                    "fontSize": "12px",
                  }}
                >
                  Destination Token:{" "}
                  {form.destinationCurrency?.label ||
                    (!droplistDestinationTokensError ? (
                      <Skeleton
                        sx={{
                          display: "inline-block",
                        }}
                        width={30}
                        height={20}
                      />
                    ) : (
                      userFacingMessages.WIDGETS.TRADE_SEND.ERROR
                    ))}
                </Link>
              )}
              items={droplistDestinationTokens}
            />
          ) : null}
        </Box>
      </Box>
    ),
    [
      form.currency,
      form.network,
      currencies,
      networks,
      networksAreLoading,
      form.fromAccount,
      currenciesAreLoading,
      droplistDestinationTokens,
      droplistDestinationTokensAreLoading,
      form.destinationCurrency,
    ],
  );

  const setRecipientAddressValue = async (address: string) => {
    dispatchInput({ field: "recipientAddress", value: address });
    if (controller.form.whitelistedAddress !== null) dispatchInput({ field: "whitelistedAddress", value: null });
  };

  const renderItem = (item: DropListWithIconItem, onclick: VoidFunction) => (
    <Tooltip title={item.isDisabled ? "This address is not valid" : ""} arrow>
      <Box
        data-testid={item.testId}
        key={item.label}
        onClick={
          item.disabled
            ? undefined
            : () => {
                dispatchInput({ field: "recipientAddress", value: item.value });
                onclick && onclick();
              }
        }
        sx={{
          "textTransform": "none",
          "borderRadius": "0px",
          "display": "flex",
          "flexDirection": "row",
          "width": "100%",
          "justifyContent": "flex-start",
          "padding": "10px 16px",
          "alignItems": "center",
          "gap": "6px",
          "boxSizing": "border-box",
          "&:hover": {
            backgroundColor: item.disabled ? "cedePaperDarkBackgroundColor" : "cedePaperLightBackgroundColor",
          },
          "cursor": item.disabled ? "initial" : "pointer",
          "backgroundColor": "cedePaperDarkBackgroundColor",
        }}
      >
        <Typography variant={item.disabled ? "body2" : "body1"}>{item.label}</Typography>
        <Box
          sx={{
            width: "5px",
            height: "5px",
            borderRadius: "50%",
            backgroundColor: item.disabled ? "text.secondary" : "text.primary",
          }}
        ></Box>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          {cropTextInMiddle(item.value, 8)}
        </Typography>
      </Box>
    </Tooltip>
  );

  const recipientAddressInputText = (
    <ValidationDropList
      id={"recipient-address"}
      sx={{
        width: "100%",
      }}
      value={recipientAddress}
      setValue={setRecipientAddressValue}
      validate={validateAddress}
      selectTitle={"Address Book"}
      items={droplistAddressBook}
      placeholder={"Recipient address"}
      renderItem={renderItem}
      onFocus={() => {
        setIsRecipientInputFocused(true);
      }}
      onBlur={() => {
        setIsRecipientInputFocused(false);
      }}
      noOptionsText={
        !disableAddressBook || addressInputType === AddressInputType.WHITELIST ? (
          <Button
            variant="text"
            onClick={() => {
              unfocus();
              handleAddAddressManually();
            }}
            data-testid="add-address-manually-button"
            sx={{
              "width": "calc(100% + 32px)",
              "height": "44px",
              "padding": 0,
              "padding-left": theme.custom.gutterMargin,
              "color": theme.palette.text.secondary,
              "margin": `-${theme.custom.gutterMargin}`,
              "px": theme.custom.gutterMargin,
              "borderRadius": 0,
              "justifyContent": "flex-start",
              "&:hover": {
                backgroundColor: theme.palette.cedePaperLightBackgroundColor,
              },
            }}
            startIcon={<Create />}
          >
            Add Address Manually
          </Button>
        ) : undefined
      }
      disableOptions={disableAddressBook}
      timeout={400}
      bottomElement={
        !disableAddressBook ? (
          <Box
            sx={{
              ":hover": {
                backgroundColor: "cedePaperLightBackgroundColor",
              },
              "width": "100%",
            }}
          >
            <Button
              sx={{
                ":hover": {
                  background: "transparent",
                },
                "display": "flex",
                "alignItems": "center",
                "gap": theme.custom.smallGutterMargin,
                "borderRadius": 0,
                "color": theme.palette.primary.main,
              }}
              variant="text"
              fullWidth
              onClick={() => {
                unfocus();
                handleAddAddressManually();
              }}
            >
              <Add />
              Add a new address
            </Button>
          </Box>
        ) : undefined
      }
    />
  );

  const whitelistedAddressDropList = (
    <DropListWithIconModal
      inputProps={{
        id: "select-whitelisted-address",
        label: `${CEDE_CEX_LABELS[form.fromAccount?.cexValue ?? ""]}'s Address Book`,
        placeholder: "Address key",
      }}
      title={"Address Book"}
      value={form.whitelistedAddress || null}
      items={droplistWhitelistedAddresses}
      loading={isWhitelistedAddressesLoading}
      onChange={(value) => {
        dispatchInput({ field: "whitelistedAddress", value });
        if (value) dispatchInput({ field: "recipientAddress", value: value?.address });
      }}
      disableModalPortal={true}
      actionButton={
        <Button
          sx={{
            gap: theme.custom.smallGutterMargin,
            display: "flex",
            marginTop: theme.custom.smallGutterMargin,
            marginLeft: "auto",
            marginRight: "auto",
            color: theme.palette.primary.main,
            alignItems: "center",
          }}
          variant="text"
          onClick={() => {
            unfocus();
            handleAddAddressManually();
          }}
        >
          <Add />
          Add a new address
        </Button>
      }
      renderItem={(item: (typeof droplistWhitelistedAddresses)[number]) => {
        return (
          <Box
            sx={{
              py: theme.custom.smallGutterMargin,
              px: theme.custom.gutterMargin,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {item.label}
            </Typography>
            <Typography
              variant="body2"
              color={theme.palette.text.secondary}
              sx={{
                fontSize: "10px",
              }}
            >
              {cropTextInMiddle(item.address, 45)}
            </Typography>
          </Box>
        );
      }}
      itemSize={() => 48}
    />
  );
  const recipientAddressInput =
    addressInputType === AddressInputType.WHITELIST ? whitelistedAddressDropList : recipientAddressInputText;

  return (
    <>
      {shouldUseTradeAndSend && (
        <TradeSendConfirmModal
          open={controller.isTradeSendConfirmModalOpened}
          setOpen={controller.setTradeSendConfirmModalOpened}
          tradePath={getTradePathLabel(controller?.tradePath ?? [])}
          recipientAddress={controller.form.recipientAddress || ""}
          destinationCurrency={controller.form.destinationCurrency?.label || ""}
          network={controller.form.network?.label || ""}
          onConfirm={controller.onTradeSendModalContinue}
        />
      )}
      {controller.form.fromAccount && isDefiTab && (
        <WhitelistAddressTutorial
          name={controller.form.whitelistedAddress?.label ?? ""}
          address={controller.form.whitelistedAddress?.address ?? controller.form.recipientAddress ?? ""}
          isOpen={controller.tutorialIsOpen}
          onClose={function (): void {
            controller.setTutorialIsOpen(false);
          }}
          exchangeId={controller.form.fromAccount.cexValue}
          checkWhitelist={controller.needWhitelist ? controller.checkWhitelist : undefined}
          asset={form.destinationCurrency?.label ?? form.currency?.label}
          tokenSymbol={form.destinationCurrency?.label ?? form.currency?.label}
          network={
            controller.form.fromAccount.cexValue === "bybit" && form.network?.label === "Moonbeam"
              ? "GLMR"
              : form.network?.label
          }
        />
      )}
      <Box
        height="100%"
        display={"flex"}
        paddingX={!isWidget && displayWhitelistTooltip && !isMobile ? theme.custom.gutterMargin : 0}
        paddingBottom={isWidget ? 0 : isMobile ? theme.custom.smallGutterMargin : theme.custom.gutterMargin}
        alignItems={"stretch"}
        justifyContent={"center"}
        width="100%"
        textAlign="left"
      >
        <Box
          sx={{
            "transition": ".2s linear",
            "transitionDelay": "0.1s",
            "width": "100%",
            "&.half-width": {
              width: "50%",
            },
          }}
          className={displayWhitelistTooltip && !isMobile && !isWidget ? "half-width" : ""}
        >
          <Box
            component={"form"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"stretch"}
            justifyContent={displayWhitelistTooltip && !isWidget && !isMobile ? "flex-start" : "space-between"}
            id="defi-form"
            onSubmit={submit}
            maxWidth={displayWhitelistTooltip && !isMobile && !isWidget ? "380px" : "100%"}
            margin="auto"
            height={displayWhitelistTooltip && !isWidget && !isMobile ? "calc(100% - 60px)" : "100%"}
            mb={displayWhitelistTooltip && !isWidget ? theme.custom.smallGutterMargin : 0}
          >
            <Box display="flex" flexDirection="column" gap={theme.custom.gutterMargin}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: isBetweenCexTab ? "row" : "column",
                  alignItems: isBetweenCexTab ? "flex-start" : "stretch",
                }}
              >
                {!isWidget && displayWhitelistTooltip && (
                  <Typography
                    variant="h3"
                    component="h1"
                    mb={theme.custom.mediumGutterMargin}
                    fontWeight="bold"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    Withdraw asset to your DeFi wallet:
                    {displayWhitelistTooltip && isMobile && (
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          setTutorialIsOpen(true);
                        }}
                        type="button"
                        sx={{
                          marginLeft: theme.custom.smallGutterMargin,
                          verticalAlign: "middle",
                          fontWeight: "bold",
                        }}
                      >
                        Whitelisting tutorial
                      </Link>
                    )}
                  </Typography>
                )}
                <DropListWithIconModal
                  inputProps={{
                    id: "select-from-account",
                    label: "From",
                    placeholder: "Account",
                  }}
                  title={"Select account"}
                  value={form.fromAccount}
                  items={fromAccounts}
                  loading={areAccountsLoading}
                  onChange={(value) => {
                    dispatchInput({ field: "fromAccount", value: value });
                  }}
                  disableModalPortal={true}
                  actionButton={addCexRedirect ? <AddNewCexButton onClick={addCexRedirect} /> : undefined}
                  shouldDisableItem={shouldDisableCex}
                  disabledTextItem={disabledCexText}
                />{" "}
                {!form.fromAccount && (
                  <Typography
                    component="p"
                    fontSize="12px"
                    color={theme.palette.text.secondary}
                    textAlign="left"
                    margin="4px 8px"
                  >
                    Please, choose an account to continue.
                  </Typography>
                )}
                {isBetweenCexTab && (
                  <>
                    <Box sx={{ padding: "10px", cursor: "pointer" }} onClick={switchSelectedCexs}>
                      <SwapHoriz
                        sx={{
                          "path": { fill: theme.palette.action.disabled },
                          ":hover": { path: { fill: theme.palette.action.active } },
                        }}
                      />
                    </Box>
                    <DropListWithIconModal
                      inputProps={{
                        id: "select-to-account",
                        label: "To",
                        placeholder: "Account",
                      }}
                      title={"Select account"}
                      value={form.toAccount ? form.toAccount : null}
                      loading={areAccountsLoading}
                      items={toAccounts}
                      onChange={(value) => {
                        dispatchInput({ field: "toAccount", value: value });
                      }}
                    />
                  </>
                )}
              </Box>
              {form.fromAccount && !warningMessage && (
                <>
                  {memoizedCurrencyNetworkFields}
                  {isDefiTab && (
                    <Box width="100%">
                      <Box
                        width={"100%"}
                        display="flex"
                        justifyContent={"space-between"}
                        alignItems={"flex-start"}
                        gap={theme.custom.gutterMargin}
                      >
                        <ResizeFieldsWrapper
                          fieldElements={[
                            recipientAddressInput,
                            <TextFieldWithIcon
                              label={"Memo (optional)"}
                              id={"tag-value"}
                              value={form.tagValue}
                              handleChange={(e) =>
                                dispatchInput({
                                  field: "tagValue",
                                  value: e.target.value,
                                })
                              }
                              icon={<div />}
                              sx={{
                                flex: 1,
                              }}
                            />,
                          ]}
                        />
                      </Box>

                      {isWidget && displayWhitelistTooltip && (
                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => {
                            setTutorialIsOpen(true);
                          }}
                          type="button"
                          sx={{
                            marginLeft: theme.custom.smallGutterMargin,
                            verticalAlign: "middle",
                            fontWeight: "bold",
                            fontSize: "12px",
                            lineHeight: "1",
                            textAlign: "left",
                          }}
                        >
                          Whitelisting tutorial
                        </Link>
                      )}
                    </Box>
                  )}
                  <Box
                    display="flex"
                    justifyContent={"space-between"}
                    gap={theme.custom.gutterMargin}
                    data-testid={"ref-asset-values"}
                    position="relative"
                  >
                    <ValidationTextFieldWithIcon
                      validate={validateAssetValue}
                      id={"asset-value"}
                      label={form.currency?.label}
                      value={String(form.assetValue)}
                      sx={{
                        flex: 1,
                        flexBasis: "50%",
                      }}
                      helperSx={{
                        paddingLeft: 0,
                        top: "inherit",
                        position: "inherit",
                      }}
                      handleChange={(e) =>
                        dispatchInput({
                          field: "assetValue",
                          value: e.target.value,
                        })
                      }
                      elementRef={validateAssetValueRef}
                      icon={
                        <Button variant={"contained"} onClick={setMaxAssetValue}>
                          Max
                        </Button>
                      }
                      subCaption={<WithdrawMinAmount controller={controller} />}
                    />
                    <TextFieldWithIcon
                      label={fiatCurrency.symbol.toUpperCase()}
                      id={"ref-value"}
                      value={refValue}
                      icon={<div />}
                      sx={{
                        "cursor": "not-allowed",
                        "opacity": 0.6,
                        "& .MuiOutlinedInput-root.Mui-disabled input": {
                          cursor: "not-allowed",
                        },
                      }}
                      disabled
                    />
                  </Box>
                </>
              )}
              {warningMessage && <EmptyState type={EmptyStateTypeEnum.WARNING} title={warningMessage} />}

              <Box>
                {form.fromAccount && isOauth && (
                  <Typography
                    sx={{
                      color: theme.palette.text.secondary,
                    }}
                  >
                    For this send, you will have to login again to {CEDE_CEX_LABELS[form.fromAccount.cexValue]}.
                  </Typography>
                )}
              </Box>
            </Box>

            <MainFooter sx={{ paddingTop: theme.custom.smallGutterMargin, pb: 0 }}>
              <Box>
                {displayWhitelistTooltip && (
                  <Box
                    display="flex"
                    alignItems="center"
                    data-testid="tutorial-checkbox"
                    onClick={() => setIsTutorialCheckboxChecked(!isTutorialCheckboxChecked)}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    mb={theme.custom.smallGutterMargin}
                  >
                    <Checkbox checked={isTutorialCheckboxChecked} />
                    <Typography component="p" variant="h3" fontSize={theme.typography.body1.fontSize} textAlign="left">
                      I had correctly followed the <strong>tutorial</strong> and <strong>whitelisted</strong> the
                      recipient address.
                    </Typography>
                  </Box>
                )}

                <Button
                  type="submit"
                  form="defi-form"
                  height={"tall"}
                  variant={"contained"}
                  fullWidth
                  disabled={!isValid}
                  data-testid={"send-button"}
                >
                  {isDemo ? "Connect to cede.store" : "Send"}
                </Button>
              </Box>
            </MainFooter>
          </Box>
          {displayWhitelistTooltip && !isWidget && !isMobile && <HelpButton />}
        </Box>
        {controller.form.fromAccount && isDefiTab && (
          <Box
            height="100%"
            pb="20px"
            sx={{
              "transition": ".2s linear",
              "transitionDelay": ".2s",
              "opacity": 0,
              "pointerEvents": "none",
              "width": "0%",
              "&.half-width": {
                width: "50%",
                pointerEvents: "all",
                opacity: 1,
              },
            }}
            className={displayWhitelistTooltip && !isMobile && !isWidget ? "half-width" : ""}
          >
            <FullPageWhitelistTutorial
              name={controller.form.whitelistedAddress?.label ?? ""}
              address={controller.form.whitelistedAddress?.address ?? controller.form.recipientAddress ?? ""}
              isOpen={displayWhitelistTooltip && isDefiTab}
              onClose={function (): void {
                controller.setTutorialIsOpen(false);
              }}
              exchangeId={controller.form.fromAccount.cexValue}
              checkWhitelist={controller.needWhitelist ? controller.checkWhitelist : undefined}
              asset={form.destinationCurrency?.label ?? form.currency?.label}
              tokenSymbol={form.destinationCurrency?.label ?? form.currency?.label}
              network={form.network?.label}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
