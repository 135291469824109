import { SendWidgetConfig } from "@cede/types";
import { forgeTokenURL } from "@cede/utils";
import { FormAction, SendForm, ShouldPrefillObject } from "../../components/Send/types";
import { getTimeLimitedPersistStorage } from "../../utils/timeLimitedPersistStorage";
import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialState: SendForm = {
  shouldPrefill: {
    tokenSymbol: true,
    network: true,
    address: true,
    amount: true,
    account: true,
  },
  initialProps: {
    tokenSymbol: undefined,
    network: undefined,
    address: undefined,
    amount: undefined,
    exchangeIds: [],
  },
  fromAccount: null,
  toAccount: null,
  currency: null,
  destinationCurrency: null,
  network: null,
  recipientAddress: null,
  whitelistedAddress: undefined,
  assetValue: "",
  refValue: "",
  tagValue: "",
  tutorialIsOpen: false,
  isDemo: false,
};

export type SendStoreState = SendForm & {
  dispatchInput: (action: FormAction<SendForm>) => void;
  dispatchPrefill: (action: Partial<ShouldPrefillObject>) => void;
  setMaxAssetValue: () => void;
  partialReset: () => void;
  switchAccounts: () => void;
  reset: (params?: { keepInitialProps?: boolean }) => void;
  setInitialProps: (props: SendWidgetConfig) => void;
  setTutorialIsOpen: (isOpen: boolean) => void;
  /**
   * It stamps the demo status in the state and resets the form if the status changes
   * Therefore, the state is directly linked to the demo status and any conflict is impossible
   */
  setIsDemo: (isDemo: boolean) => void;
};

const PERSISTANCE_DURATION = 1000 * 60 * 10; // 10 minutes

export const useSendStore = create<SendStoreState>()(
  persist(
    (set) => ({
      ...initialState,
      transaction: null,
      error: undefined,
      dispatchPrefill: (value: Partial<ShouldPrefillObject>) =>
        set((state) => {
          return {
            shouldPrefill: {
              tokenSymbol: value.tokenSymbol ?? !!state.shouldPrefill?.tokenSymbol,
              network: value.network ?? !!state.shouldPrefill?.network,
              address: value.address ?? !!state.shouldPrefill?.address,
              amount: value.amount ?? !!state.shouldPrefill?.amount,
              account: value.account ?? !!state.shouldPrefill?.account,
            },
          };
        }),
      dispatchInput: (action: FormAction<SendForm>) =>
        set((state) => {
          const newState = {} as SendStoreState;
          if (action.field === "fromAccount") {
            state.partialReset();
            newState.shouldPrefill = {
              tokenSymbol: true,
              network: true,
              address: true,
              amount: true,
              account: false,
            };
          }

          if (action.field === "currency") {
            newState.assetValue = "";
            newState.refValue = "";
          }

          if (action.field === "assetValue" && state.currency && action.value) {
            const ratio = Number(action.value) / state.currency.freeBalance;
            newState.refValue = (ratio * Number(state.currency.amount)).toString();
          }

          return { [action.field]: action.value, ...newState };
        }),
      reset: ({ keepInitialProps = false } = {}) => {
        set((state) => {
          return { ...initialState, initialProps: keepInitialProps ? state.initialProps : initialState.initialProps };
        }, false);
      },
      switchAccounts: () => {
        set((state) => {
          state.partialReset();
          return { fromAccount: state.toAccount, toAccount: state.fromAccount };
        }, false);
      },
      setTutorialIsOpen: (isOpen: boolean) => {
        set(() => {
          return { tutorialIsOpen: isOpen };
        });
      },
      partialReset: () => {
        set(() => {
          return {
            destinationCurrency: null,
            currency: null,
            network: null,
            assetValue: "",
            refValue: "",
            amount: "",
            tagValue: "",
            recipientAddress: null,
            whitelistedAddress: undefined,
          };
        });
      },
      setMaxAssetValue: () => {
        set((state) => {
          if (!state.currency) return {};

          const ratio = Number(state.currency.freeBalance) / state.currency.freeBalance;

          return {
            assetValue: String(state.currency.freeBalance),
            refValue: (ratio * Number(state.currency.amount)).toString(),
          };
        });
      },
      setInitialProps: (initialProps: SendWidgetConfig) =>
        set(() => {
          return {
            initialProps,
          };
        }),
      setIsDemo: (isDemo: boolean) =>
        set((state) => {
          if (state.isDemo !== isDemo) {
            state.reset();
          }
          return {
            isDemo,
          };
        }),
    }),
    {
      name: "SendStore",
      storage: getTimeLimitedPersistStorage<SendStoreState>({
        ttl: PERSISTANCE_DURATION,
        onHydration: (state) => {
          if (state.currency) {
            state.currency.img = forgeTokenURL(state.currency.value);
          }
          return state;
        },
      }),
      // We don't persist the shouldPrefill object, as we need to populate the form with config values on each mount
      partialize: (state) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { shouldPrefill, ...persistableState } = state;
        return persistableState;
      },
    },
  ),
);
