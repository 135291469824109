import { useRef } from "react";
import { TradeForm, TradeWidgetConfig } from "@cede/types";
import { forgeTokenURL } from "@cede/utils";
import { getTimeLimitedPersistStorage } from "../../../utils/timeLimitedPersistStorage";
import { TradeFormAction } from "../types";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type TradeFormState = {
  dispatchForm: (action: TradeFormAction<TradeForm>) => void;
  setInitialProps: (props: TradeWidgetConfig) => void;
  resetForm: (keepSelectedAccount?: boolean) => void;
} & TradeForm;

const _initialFormState: TradeForm = {
  tokenFrom: null,
  amountFrom: "0",
  tokenTo: null,
  amountTo: "0",
  selectedAccount: null,
  market: null,
  orderSide: null,
  noCommonMarket: false,
  initialProps: {
    tokenSymbols: undefined,
  },
};

const PERSISTANCE_DURATION = 1000 * 60 * 10; // 10 minutes

export const useTradeFormStore = create(
  persist<TradeFormState>(
    (set) => ({
      ..._initialFormState,
      resetForm: (keepSelectedAccount = false) => {
        set({
          ..._initialFormState,
          selectedAccount: keepSelectedAccount ? useTradeFormStore.getState().selectedAccount : null,
          initialProps: useTradeFormStore.getState().initialProps,
        });
      },
      setInitialProps: (initialProps: TradeWidgetConfig) =>
        set(() => {
          return {
            initialProps,
          };
        }),
      dispatchForm: (action) => {
        const state = useTradeFormStore.getState();

        if (action.field === "revertToken") {
          set({
            tokenFrom: state.tokenTo,
            tokenTo: state.tokenFrom,
          });
          return;
        }
        // If the user changes the tokenFrom or tokenTo, we need to reset the noCommonMarket flag
        // It will be set to true if the user selects two tokens that don't have a common market in further steps
        if (
          (action.field === "tokenFrom" && state.tokenFrom !== action.value) ||
          (action.field === "tokenTo" && state.tokenTo !== action.value)
        ) {
          set({ noCommonMarket: false });
        }
        set({
          [action.field]: action.value,
        });
      },
    }),
    {
      name: "TradeStore",
      storage: getTimeLimitedPersistStorage<TradeFormState>({
        ttl: PERSISTANCE_DURATION,
        onHydration: (state) => {
          if (state.tokenFrom) {
            state.tokenFrom.img = forgeTokenURL(state.tokenFrom.value);
          }
          if (state.tokenTo) {
            state.tokenTo.img = forgeTokenURL(state.tokenTo.value);
          }
          return state;
        },
      }),
    },
  ),
);

export const useTradeForm = () => {
  const { dispatchForm, resetForm, ...tradeForm } = useTradeFormStore();
  const focusedInput = useRef<"amountFrom" | "amountTo">();

  const setFocusedInput = (focused: "amountFrom" | "amountTo") => {
    focusedInput.current = focused;
  };

  const hasSelectedBothTokens = !tradeForm.tokenFrom || !tradeForm.tokenTo;

  const addMax = (amount: string | number | null) => {
    dispatchForm({
      field: "amountFrom",
      value: amount?.toString() ?? "0",
    });
  };

  const resetAmounts = () => {
    dispatchForm({
      field: "amountFrom",
      value: "0",
    });
    dispatchForm({
      field: "amountTo",
      value: "0",
    });
  };

  return {
    tradeForm,
    dispatchForm,
    setFocusedInput,
    focusedInput,
    resetForm,
    hasSelectedBothTokens,
    addMax,
    resetAmounts,
  };
};
