import { CopyCard } from "@cede/ds";
import { AddressDescriptionProps } from "./types";
import { Box, Typography, useTheme } from "@mui/material";

export function AddressDescription({ name, address }: AddressDescriptionProps) {
  const theme = useTheme();
  return (
    <Box width="100%" display="flex" flexDirection="column" gap="5px">
      <Typography variant="body1" textAlign="left" fontWeight={500} color={theme.palette.text.primary} width="100%">
        Address Description
      </Typography>
      <CopyCard
        testId="name-copy-icon"
        title={"Address Name"}
        content={name}
        onCopy={async () => {
          if ("clipboard" in navigator) {
            return navigator.clipboard.writeText(name);
          } else {
            return new Promise(() => document.execCommand("copy", true, name));
          }
        }}
      />
      <Typography
        variant="body1"
        textAlign="left"
        fontWeight={500}
        color={theme.palette.text.primary}
        width="100%"
        marginTop="13px"
      >
        Address
      </Typography>
      <CopyCard
        testId="address-copy-icon"
        title="Address"
        content={address}
        onCopy={async () => {
          if ("clipboard" in navigator) {
            return navigator.clipboard.writeText(address);
          } else {
            return new Promise(() => document.execCommand("copy", true, address));
          }
        }}
      />
    </Box>
  );
}
