import { useEffect, useMemo, useState } from "react";
import { ValidateFunction } from "@cede/ds";
import { useAddressBook } from "../../../hooks";
import { useDependencies } from "../../../hooks/useDependencies";
import { getQueryClient } from "../../../utils";
import { AddressInputType, SendForm, ValidateAssetFunction } from "../types";
import { SendTabsValues, useSendTabs } from "./useSendTabs";
import { useQuery } from "@tanstack/react-query";

type UseSendValidationProps = {
  shouldUseTradeAndSend?: boolean;
  addressInputType: AddressInputType;
  supportedTokensAreLoading: boolean;
};

export const useSendValidation = ({
  shouldUseTradeAndSend = false,
  addressInputType,
  supportedTokensAreLoading,
}: UseSendValidationProps) => {
  const {
    useSendStore,
    useSupportedExchanges,
    backgroundHandler,
    useVaults,
    useLoading,
    useSendMinAmount,
    useWhitelistedAddresses,
    useTradePath,
  } = useDependencies();
  const { selectedTab } = useSendTabs();
  const { isBlockingLoading } = useLoading((state) => ({
    isBlockingLoading: state.isBlockingLoading,
  }));
  const { supportedExchangesById } = useSupportedExchanges();
  const form = useSendStore();
  const { tradePath } = useTradePath({
    enabled: shouldUseTradeAndSend,
    accountId: form.fromAccount?.value ?? "",
    fromToken: form.currency?.value ?? "",
    toToken: form.destinationCurrency?.value ?? "",
    network: form.network?.value ?? "",
    activeWithdrawal: true,
  });
  const { droplistAddressBook } = useAddressBook();
  const { activeVault } = useVaults();
  const [needWhitelist, setNeedWhitelist] = useState(false);
  const [isTutorialCheckboxChecked, setIsTutorialCheckboxChecked] = useState(false);
  const [displayWhitelistTooltip, setDisplayWhitelistTooltip] = useState(false);
  const queryClient = getQueryClient();
  const { droplistWhitelistedAddresses, isWhitelistedAddressesError } = useWhitelistedAddresses({
    accountId: form.fromAccount?.value ?? "",
    tokenSymbol: form.destinationCurrency?.value ?? form.currency?.value,
    network: form.network?.value,
  });
  const { minAmount, minAmountIsLoading } = useSendMinAmount({ shouldUseTradeAndSend, supportedTokensAreLoading });
  // For CEXs requiring whitelist, the user needs to pick an address from the whitelisted addresses, It cannot be invalid
  // For other CEXs, there is no validation
  const validateAddressWhitelist: ValidateFunction<string> = () => {
    if (!droplistAddressBook && addressInputType === AddressInputType.ADDRESS_BOOK)
      return { color: "error", message: "Couldn't retrieve the address book" };
    else if (isWhitelistedAddressesError && addressInputType === AddressInputType.WHITELIST)
      return { color: "error", message: "Couldn't retrieve the whitelisted addresses" };
    return { color: "success" };
  };

  const { isRequiringWhitelist, shouldCheckWhitelist } = useMemo(() => {
    if (!supportedExchangesById || !form.fromAccount?.cexValue) {
      setNeedWhitelist(false);
      setDisplayWhitelistTooltip(false);

      return {
        isRequiringWhitelist: false,
        shouldCheckWhitelist: false,
      };
    }

    const isRequiringWhitelist =
      supportedExchangesById[form.fromAccount?.cexValue]?.isRequiringAddressWhitelisting || false;
    const shouldCheckWhitelist = supportedExchangesById[form.fromAccount?.cexValue]?.shouldCheckWhitelist;

    setDisplayWhitelistTooltip(isRequiringWhitelist);
    setNeedWhitelist(isRequiringWhitelist && !!shouldCheckWhitelist);

    return { isRequiringWhitelist, shouldCheckWhitelist };
  }, [form.fromAccount?.cexValue, supportedExchangesById]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [
        "checkAddressIsWhitelisted",
        form.recipientAddress,
        form.whitelistedAddress,
        form.currency,
        form.network,
      ],
    });
  }, [form.recipientAddress, form.whitelistedAddress, form.currency, form.network]);

  const { data: isAddressWhitelisted, refetch: checkWhitelist } = useQuery<boolean>({
    queryKey: [
      "checkAddressIsWhitelisted",
      form.fromAccount?.label,
      form.whitelistedAddress,
      form.recipientAddress,
      form.currency?.value,
      form.network?.value,
      activeVault?.id,
      {
        isRequiringWhitelist,
        shouldCheckWhitelist,
      },
    ],
    queryFn: async () => {
      if (!form.fromAccount || !form.currency || !form.recipientAddress || !activeVault) return undefined;
      if (!form.whitelistedAddress || form.whitelistedAddress.address !== form.recipientAddress) {
        return false;
      }

      const result = await backgroundHandler.checkAddressIsWhitelisted({
        accountId: form.fromAccount.value,
        key: form.whitelistedAddress.key,
        address: form.whitelistedAddress.address,
        tokenSymbol: form.currency.value,
      });

      return result;
    },
    enabled: !!backgroundHandler?.isReady && isRequiringWhitelist && shouldCheckWhitelist,
  });

  useEffect(() => {
    if (isAddressWhitelisted !== undefined) {
      setNeedWhitelist(!isAddressWhitelisted);
      setDisplayWhitelistTooltip(!isAddressWhitelisted);
    }
  }, [isAddressWhitelisted]);

  const validateAddress: ValidateFunction<string> = (v) => {
    if (!form.fromAccount?.cexValue) return { color: "error", message: "No CEX selected" };

    if (isRequiringWhitelist) {
      return validateAddressWhitelist(v);
    }

    // TODO we need to rework the address validation
    // // @TODO: Should we do a method to retrieve if the network is supported by the CEX?
    //   let isValid;
    //   try {
    //     isValid = validateCoinAddress(v, form.network?.value || form.network?.default_network);
    //   } catch (e) {
    //     if (e instanceof Error) Logger.error(e);
    //     isValid = /^(0x)[0-9A-Fa-f]{40}$/.test(v);
    //   }
    //   if (!isValid) {
    //     return { color: "error", message: "Invalid address" };
    // }

    return { color: "success" };
  };

  const validateAssetValue: ValidateAssetFunction = () => {
    if (form.isDemo) {
      return {
        color: "success",
      };
    }

    const assetValueNumber = Number(form.assetValue);
    const withdrawMinWithFee = Number(minAmount || "0");

    if (assetValueNumber < withdrawMinWithFee) {
      return {
        color: "error",
        message: `The amount should be at least ${withdrawMinWithFee}`,
      };
    }

    if (form.currency?.balance && assetValueNumber > form.currency.freeBalance) {
      return {
        color: "error",
        message: `The amount should be max ${form.currency?.balance}`,
      };
    }

    return {
      color: "success",
    };
  };

  const validateForm = (form: SendForm): boolean => {
    return !!(
      Number(form.assetValue) &&
      // @TODO: Should we do a method to retrieve if the network is supported by the CEX?
      form.network &&
      form.fromAccount &&
      form.currency
    );
  };

  const shouldDisplayWhitelistTooltip =
    displayWhitelistTooltip && !isBlockingLoading && !!form.recipientAddress && !!form.currency && !!form.network;

  const isValid = useMemo(() => {
    return (
      ((shouldDisplayWhitelistTooltip && isTutorialCheckboxChecked) || !shouldDisplayWhitelistTooltip) &&
      (shouldUseTradeAndSend ? !!tradePath && tradePath.length > 0 : true) &&
      !minAmountIsLoading &&
      validateForm(form) &&
      validateAssetValue().color === "success" &&
      ((form.toAccount && selectedTab === SendTabsValues.BetweenCex) ||
        (form.recipientAddress != undefined &&
          form.recipientAddress != "" &&
          selectedTab === SendTabsValues.DefiAddress &&
          validateAddress(form.recipientAddress).color === "success" &&
          !needWhitelist))
    );
  }, [
    form,
    needWhitelist,
    droplistAddressBook,
    selectedTab,
    droplistWhitelistedAddresses,
    isTutorialCheckboxChecked,
    shouldDisplayWhitelistTooltip,
    minAmount,
    shouldUseTradeAndSend,
    tradePath,
  ]);

  return {
    validateAddress,
    validateAssetValue,
    isValid,
    isAddressWhitelisted,
    needWhitelist,
    checkWhitelist,
    setIsTutorialCheckboxChecked,
    isTutorialCheckboxChecked,
    displayWhitelistTooltip: shouldDisplayWhitelistTooltip,
  };
};
