import { useEffect } from "react";
import { TradeWidgetConfig, WidgetType } from "@cede/types";
import { TRADE_WIDGET_ROUTES, WIDGET_ROUTES } from "@cede/utils";
import { mockedHandlerFactory } from "@cede/utils";
import { useDependencies } from "../hooks/useDependencies";
import { useTradeFormStore } from "@cedelabs/react-utils";

export const useInitialize = () => {
  const {
    useVaults,
    setBackgroundHandler,
    useBackgroundHandler,
    useSetupSettings,
    useLoading,
    useProviderEvents,
    useStandardizedNetworks,
    useSendStore,
    useNavigate,
    widgetProps,
    useLogin,
    widgetType,
    useMultipleTransactionsStore,
    backgroundHandler,
  } = useDependencies();
  const navigate = useNavigate();
  const { addBigPendingRequest, removeBigPendingRequest } = useLoading((state) => ({
    addBigPendingRequest: state.addBigPendingRequest,
    removeBigPendingRequest: state.removeBigPendingRequest,
  }));
  const { refetchVaults } = useVaults();
  const { isLoggedIn, checkAutoLogin } = useLogin();
  const hasStartedMultipleTransactions = useMultipleTransactionsStore((state) => state.hasStartedTheProcess);
  useSetupSettings();
  useStandardizedNetworks();

  const setSendInitialProps = useSendStore((state) => state.setInitialProps);
  const setTradeInitialProps = useTradeFormStore((state) => state.setInitialProps);

  useProviderEvents({
    onUnlock: () => {
      refetchVaults();
      removeBigPendingRequest();
    },
    onLock: () => {
      refetchVaults();
      navigate(WIDGET_ROUTES.LOGIN);
    },
    onAccountsChanged: () => {
      refetchVaults();
    },
  });

  useBackgroundHandler({
    onProviderNotFound: () => {
      setBackgroundHandler(mockedHandlerFactory());
    },
    onProviderFound: (nativeBackgroundHandler) => {
      setBackgroundHandler(nativeBackgroundHandler);
    },
  });

  useEffect(() => {
    // Display the loader by default, then if the provider isn't found, remove it
    // if it's found, when the backgroundHandler is set, remove it.
    addBigPendingRequest();

    const timeout = setTimeout(() => {
      // if the background handler isn't present or not ready, remove the loader
      if (!backgroundHandler?.isReady) {
        navigate(WIDGET_ROUTES.LOGIN);
        removeBigPendingRequest();
      }
    }, 3000);
    return () => {
      clearTimeout(timeout);
      removeBigPendingRequest();
    };
  }, [backgroundHandler]);

  // It manages the initial routing of the widget
  useEffect(() => {
    if (backgroundHandler?.isReady) {
      if (!isLoggedIn) {
        checkAutoLogin(backgroundHandler)
          .then((isLoggedIn) => {
            if (!isLoggedIn) {
              navigate(WIDGET_ROUTES.LOGIN);
            }
          })
          .finally(removeBigPendingRequest);
      } else {
        if (widgetType === WidgetType.TRADE && !localStorage.getItem("defiAddress")) {
          navigate(TRADE_WIDGET_ROUTES.ADD_DEFI_ADDRESS);
          return;
        }

        if (hasStartedMultipleTransactions) {
          navigate(WIDGET_ROUTES.MULTIPLE_TRANSACTIONS);
          return;
        }
        // if page is login, redirect to action route
        // we need this condition for an edge case with the defi address page
        navigate(WIDGET_ROUTES.ACTION_ROUTE);
        removeBigPendingRequest();
      }
    }
  }, [isLoggedIn, backgroundHandler?.isReady]);

  useEffect(() => {
    if (!widgetProps?.config) return;

    if (widgetType === WidgetType.SEND) setSendInitialProps(widgetProps.config);
    else if (widgetType === WidgetType.TRADE) setTradeInitialProps(widgetProps.config as TradeWidgetConfig);
  }, [widgetProps.config]);
};
