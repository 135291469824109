// ----------------------------------------------------------------------------

// PLEASE DO NOT EDIT THIS FILE, IT IS GENERATED AND WILL BE OVERWRITTEN:
// https://github.com/ccxt/ccxt/blob/master/CONTRIBUTING.md#how-to-contribute-code
// EDIT THE CORRESPONDENT .ts FILE INSTEAD

//  ---------------------------------------------------------------------------
import Exchange from './abstract/okx.js';
import { Precise } from './base/Precise.js';
import { TICK_SIZE } from './base/functions/number.js';
import { AuthenticationError, ExchangeAccountSuspendedError, ExchangeError, ExchangeInvalidNonceError, ExchangeInvalidPermissionsError, ExchangeNotAllowedMethodError, ExchangeNotAvailableError, ExchangeOperationFailedError, ExchangeRateLimitExceededError, ExchangeRequestExpiredError, ExchangeTransferError, ExchangeUnderMaintenanceError, ExchangeUnknownAssetError, GetDepositAddressError, InsufficientBalanceError, InternalError, InvalidAddressError, InvalidParamsError, MarketTypeNotSupportedError, OrderNotFoundError, OverWithdrawMaximumError, TradeError, UnderWithdrawMinimumError, WhitelistCheckFailedError, WithdrawalError, WithdrawalForbiddenError } from './sdk/errors.js';
import { SdkErrorMessages } from './sdk/errorsMetadata.js';
import { sha256 } from './static_dependencies/noble-hashes/sha256.js';
//  ---------------------------------------------------------------------------
/**
 * @class okx
 * @augments Exchange
 */
export default class okx extends Exchange {
    describe() {
        return this.deepExtend(super.describe(), {
            'id': 'okx',
            'name': 'OKX',
            'countries': ['CN', 'US'],
            'version': 'v5',
            'rateLimit': 100 * 1.03,
            'pro': true,
            'certified': true,
            'termId': '',
            'has': {
                'CORS': undefined,
                'spot': true,
                'margin': true,
                'swap': true,
                'future': true,
                'option': true,
                'cancelAllOrders': false,
                'cancelOrder': true,
                'cancelOrders': true,
                'createDepositAddress': false,
                'createMarketBuyOrderWithCost': true,
                'createMarketSellOrderWithCost': true,
                'createOrder': true,
                'createOrders': true,
                'createOrderWithTakeProfitAndStopLoss': true,
                'createTakeProfitOrder': true,
                'createTrailingPercentOrder': true,
                'createTriggerOrder': true,
                'editOrder': true,
                'fetchAccounts': true,
                'fetchBalance': true,
                'fetchCanceledOrders': true,
                'fetchClosedOrder': undefined,
                'fetchClosedOrders': true,
                'fetchCurrencies': true,
                'fetchDeposit': true,
                'fetchDepositAddress': true,
                'fetchDepositAddresses': false,
                'fetchDepositAddressesByNetwork': true,
                'fetchDeposits': true,
                'fetchDepositsWithdrawals': false,
                'fetchDepositWithdrawFee': 'emulated',
                'fetchDepositWithdrawFees': true,
                'fetchLedger': true,
                'fetchLedgerEntry': undefined,
                'fetchMarkets': true,
                'fetchMyTrades': true,
                'fetchOHLCV': true,
                'fetchOpenOrder': undefined,
                'fetchOpenOrders': true,
                'fetchOrder': true,
                'fetchOrderBook': true,
                'fetchOrderBooks': false,
                'fetchOrders': false,
                'fetchOrderTrades': true,
                'fetchPermissions': undefined,
                'fetchPosition': true,
                'fetchPositions': true,
                'fetchPositionsForSymbol': true,
                'fetchPositionsRisk': false,
                'fetchStatus': true,
                'fetchTicker': true,
                'fetchTickers': true,
                'fetchTime': true,
                'fetchTrades': true,
                'fetchTradingFee': true,
                'fetchTradingFees': false,
                'fetchTradingLimits': false,
                'fetchTransactionFee': false,
                'fetchTransactionFees': false,
                'fetchTransactions': false,
                'fetchTransfer': true,
                'fetchTransfers': true,
                'fetchWithdrawal': true,
                'fetchWithdrawals': true,
                'fetchWithdrawalWhitelist': false,
                'transfer': true,
                'withdraw': true,
            },
            'timeframes': {
                '1m': '1m',
                '3m': '3m',
                '5m': '5m',
                '15m': '15m',
                '30m': '30m',
                '1h': '1H',
                '2h': '2H',
                '4h': '4H',
                '6h': '6H',
                '12h': '12H',
                '1d': '1D',
                '1w': '1W',
                '1M': '1M',
                '3M': '3M',
            },
            'hostname': 'www.okx.com',
            'urls': {
                'logo': 'https://user-images.githubusercontent.com/1294454/152485636-38b19e4a-bece-4dec-979a-5982859ffc04.jpg',
                'api': {
                    'rest': 'https://{hostname}',
                },
                'www': 'https://www.okx.com',
                'doc': 'https://www.okx.com/docs-v5/en/',
                'fees': 'https://www.okx.com/pages/products/fees.html',
                'referral': {
                    // https://www.okx.com/join/CCXT2023 - I removed the code for now, because CWS is blocking until we explain our affiliate program to our users
                    // old reflink 0% discount https://www.okx.com/join/1888677
                    // new reflink 20% discount https://www.okx.com/join/CCXT2023
                    'url': 'https://www.okx.com/',
                    'discount': 0.2,
                },
                'test': {
                    'rest': 'https://{hostname}',
                },
            },
            'api': {
                'public': {
                    'get': {
                        'market/books-full': 2,
                        'market/tickers': 1,
                        'market/ticker': 1,
                        'market/index-tickers': 1,
                        'market/books': 1 / 2,
                        'market/books-lite': 5 / 3,
                        'market/candles': 1 / 2,
                        'market/history-candles': 1,
                        'market/index-candles': 1,
                        'market/history-index-candles': 2,
                        'market/mark-price-candles': 1,
                        'market/history-mark-price-candles': 2,
                        'market/trades': 1 / 5,
                        'market/history-trades': 2,
                        'market/option/instrument-family-trades': 1,
                        'market/platform-24-volume': 10,
                        'market/open-oracle': 50,
                        'market/exchange-rate': 20,
                        'market/index-components': 1,
                        'public/economic-calendar': 50,
                        'market/block-tickers': 1,
                        'market/block-ticker': 1,
                        'public/block-trades': 1,
                        'public/instruments': 1,
                        'public/delivery-exercise-history': 1 / 2,
                        'public/open-interest': 1,
                        'public/funding-rate': 1,
                        'public/funding-rate-history': 1,
                        'public/price-limit': 1,
                        'public/opt-summary': 1,
                        'public/estimated-price': 2,
                        'public/discount-rate-interest-free-quota': 10,
                        'public/time': 2,
                        'public/mark-price': 2,
                        'public/position-tiers': 2,
                        'public/interest-rate-loan-quota': 10,
                        'public/vip-interest-rate-loan-quota': 10,
                        'public/underlying': 1,
                        'public/insurance-fund': 2,
                        'public/convert-contract-coin': 2,
                        'public/option-trades': 1,
                        'public/instrument-tick-bands': 4,
                        'rubik/stat/trading-data/support-coin': 4,
                        'rubik/stat/taker-volume': 4,
                        'rubik/stat/margin/loan-ratio': 4,
                        // long/short
                        'rubik/stat/contracts/long-short-account-ratio': 4,
                        'rubik/stat/contracts/open-interest-volume': 4,
                        'rubik/stat/option/open-interest-volume': 4,
                        // put/call
                        'rubik/stat/option/open-interest-volume-ratio': 4,
                        'rubik/stat/option/open-interest-volume-expiry': 4,
                        'rubik/stat/option/open-interest-volume-strike': 4,
                        'rubik/stat/option/taker-block-volume': 4,
                        'system/status': 50,
                        // public api
                        'sprd/spreads': 1,
                        'sprd/books': 1 / 2,
                        'sprd/ticker': 1,
                        'sprd/public-trades': 1 / 5,
                        'tradingBot/grid/ai-param': 1,
                        'tradingBot/grid/min-investment': 1,
                        'tradingBot/public/rsi-back-testing': 1,
                        'asset/exchange-list': 5 / 3,
                        'finance/staking-defi/eth/apy-history': 5 / 3,
                        'finance/savings/lending-rate-summary': 5 / 3,
                        'finance/savings/lending-rate-history': 5 / 3,
                        // public broker
                        'finance/sfp/dcd/products': 2 / 3,
                        // copytrading
                        'copytrading/public-lead-traders': 4,
                        'copytrading/public-weekly-pnl': 4,
                        'copytrading/public-stats': 4,
                        'copytrading/public-preference-currency': 4,
                        'copytrading/public-current-subpositions': 4,
                        'copytrading/public-subpositions-history': 4,
                    },
                },
                'private': {
                    'get': {
                        // rfq
                        'rfq/counterparties': 4,
                        'rfq/maker-instrument-settings': 4,
                        'rfq/mmp-config': 4,
                        'rfq/rfqs': 10,
                        'rfq/quotes': 10,
                        'rfq/trades': 4,
                        'rfq/public-trades': 4,
                        // sprd
                        'sprd/order': 1 / 3,
                        'sprd/orders-pending': 1 / 3,
                        'sprd/orders-history': 1 / 2,
                        'sprd/orders-history-archive': 1 / 2,
                        'sprd/trades': 1 / 3,
                        // trade
                        'trade/order': 1 / 3,
                        'trade/orders-pending': 1 / 3,
                        'trade/orders-history': 1 / 2,
                        'trade/orders-history-archive': 1,
                        'trade/fills': 1 / 3,
                        'trade/fills-history': 2.2,
                        'trade/fills-archive': 2,
                        'trade/order-algo': 1,
                        'trade/orders-algo-pending': 1,
                        'trade/orders-algo-history': 1,
                        'trade/easy-convert-currency-list': 20,
                        'trade/easy-convert-history': 20,
                        'trade/one-click-repay-currency-list': 20,
                        'trade/one-click-repay-history': 20,
                        'trade/account-rate-limit': 1,
                        // asset
                        'asset/currencies': 5 / 3,
                        'asset/balances': 5 / 3,
                        'asset/non-tradable-assets': 5 / 3,
                        'asset/asset-valuation': 10,
                        'asset/transfer-state': 10,
                        'asset/bills': 5 / 3,
                        'asset/deposit-lightning': 5,
                        'asset/deposit-address': 5 / 3,
                        'asset/deposit-history': 5 / 3,
                        'asset/withdrawal-history': 5 / 3,
                        'asset/deposit-withdraw-status': 20,
                        'asset/convert/currencies': 5 / 3,
                        'asset/convert/currency-pair': 5 / 3,
                        'asset/convert/history': 5 / 3,
                        'asset/monthly-statement': 2,
                        // account
                        'account/balance': 2,
                        'account/positions': 2,
                        'account/positions-history': 100,
                        'account/account-position-risk': 2,
                        'account/bills': 5 / 3,
                        'account/bills-archive': 5 / 3,
                        'account/config': 4,
                        'account/max-size': 1,
                        'account/max-avail-size': 1,
                        'account/leverage-info': 1,
                        'account/adjust-leverage-info': 4,
                        'account/max-loan': 1,
                        'account/trade-fee': 4,
                        'account/interest-accrued': 4,
                        'account/interest-rate': 4,
                        'account/max-withdrawal': 1,
                        'account/risk-state': 2,
                        'account/quick-margin-borrow-repay-history': 4,
                        'account/borrow-repay-history': 4,
                        'account/vip-interest-accrued': 4,
                        'account/vip-interest-deducted': 4,
                        'account/vip-loan-order-list': 4,
                        'account/vip-loan-order-detail': 4,
                        'account/interest-limits': 4,
                        'account/greeks': 2,
                        'account/position-tiers': 2,
                        'account/mmp-config': 4,
                        // subaccount
                        'users/subaccount/list': 10,
                        'account/subaccount/balances': 10 / 3,
                        'asset/subaccount/balances': 10 / 3,
                        'account/subaccount/max-withdrawal': 1,
                        'asset/subaccount/bills': 5 / 3,
                        'asset/subaccount/managed-subaccount-bills': 5 / 3,
                        'users/entrust-subaccount-list': 10,
                        'account/subaccount/interest-limits': 4,
                        // grid trading
                        'tradingBot/grid/orders-algo-pending': 1,
                        'tradingBot/grid/orders-algo-history': 1,
                        'tradingBot/grid/orders-algo-details': 1,
                        'tradingBot/grid/sub-orders': 1,
                        'tradingBot/grid/positions': 1,
                        'tradingBot/grid/ai-param': 1,
                        'tradingBot/signal/signals': 1,
                        'tradingBot/signal/orders-algo-details': 1,
                        'tradingBot/signal/orders-algo-history': 1,
                        'tradingBot/signal/positions': 1,
                        'tradingBot/signal/positions-history': 1,
                        'tradingBot/signal/sub-orders': 1,
                        'tradingBot/signal/event-history': 1,
                        'tradingBot/recurring/orders-algo-pending': 1,
                        'tradingBot/recurring/orders-algo-history': 1,
                        'tradingBot/recurring/orders-algo-details': 1,
                        'tradingBot/recurring/sub-orders': 1,
                        // earn
                        'finance/savings/balance': 5 / 3,
                        'finance/savings/lending-history': 5 / 3,
                        'finance/staking-defi/offers': 10 / 3,
                        'finance/staking-defi/orders-active': 10 / 3,
                        'finance/staking-defi/orders-history': 10 / 3,
                        // eth staking
                        'finance/staking-defi/eth/balance': 5 / 3,
                        'finance/staking-defi/eth/purchase-redeem-history': 5 / 3,
                        // copytrading
                        'copytrading/current-subpositions': 1,
                        'copytrading/subpositions-history': 1,
                        'copytrading/instruments': 4,
                        'copytrading/profit-sharing-details': 4,
                        'copytrading/total-profit-sharing': 4,
                        'copytrading/unrealized-profit-sharing-details': 4,
                        'copytrading/copy-settings': 4,
                        'copytrading/batch-leverage-info': 4,
                        'copytrading/current-lead-traders': 4,
                        'copytrading/lead-traders-history': 4,
                        // broker
                        'broker/nd/info': 10,
                        'broker/nd/subaccount-info': 10,
                        'broker/nd/subaccount/apikey': 10,
                        'asset/broker/nd/subaccount-deposit-address': 5 / 3,
                        'asset/broker/nd/subaccount-deposit-history': 4,
                        'asset/broker/nd/subaccount-withdrawal-history': 4,
                        'broker/nd/rebate-daily': 100,
                        'broker/nd/rebate-per-orders': 300,
                        'finance/sfp/dcd/order': 2,
                        'finance/sfp/dcd/orders': 2,
                        'broker/fd/rebate-per-orders': 300,
                        'broker/fd/if-rebate': 5,
                        // affiliate
                        'affiliate/invitee/detail': 1,
                        'users/partner/if-rebate': 1,
                    },
                    'post': {
                        // rfq
                        'rfq/create-rfq': 4,
                        'rfq/cancel-rfq': 4,
                        'rfq/cancel-batch-rfqs': 10,
                        'rfq/cancel-all-rfqs': 10,
                        'rfq/execute-quote': 15,
                        'rfq/maker-instrument-settings': 4,
                        'rfq/mmp-reset': 4,
                        'rfq/mmp-config': 100,
                        'rfq/create-quote': 0.4,
                        'rfq/cancel-quote': 0.4,
                        'rfq/cancel-batch-quotes': 10,
                        'rfq/cancel-all-quotes': 10,
                        // sprd
                        'sprd/order': 1,
                        'sprd/cancel-order': 1,
                        'sprd/mass-cancel': 1,
                        'sprd/amend-order': 1,
                        // trade
                        'trade/order': 1 / 3,
                        'trade/batch-orders': 1 / 15,
                        'trade/cancel-order': 1 / 3,
                        'trade/cancel-batch-orders': 1 / 15,
                        'trade/amend-order': 1 / 3,
                        'trade/amend-batch-orders': 1 / 150,
                        'trade/close-position': 1,
                        'trade/fills-archive': 172800,
                        'trade/order-algo': 1,
                        'trade/cancel-algos': 1,
                        'trade/amend-algos': 1,
                        'trade/cancel-advance-algos': 1,
                        'trade/easy-convert': 20,
                        'trade/one-click-repay': 20,
                        'trade/mass-cancel': 4,
                        'trade/cancel-all-after': 10,
                        // asset
                        'asset/transfer': 10,
                        'asset/withdrawal': 5 / 3,
                        'asset/withdrawal-lightning': 5,
                        'asset/cancel-withdrawal': 5 / 3,
                        'asset/convert-dust-assets': 10,
                        'asset/convert/estimate-quote': 1,
                        'asset/convert/trade': 1,
                        'asset/monthly-statement': 1,
                        // account
                        'account/set-position-mode': 4,
                        'account/set-leverage': 1,
                        'account/position/margin-balance': 1,
                        'account/set-greeks': 4,
                        'account/set-isolated-mode': 4,
                        'account/quick-margin-borrow-repay': 4,
                        'account/borrow-repay': 5 / 3,
                        'account/simulated_margin': 10,
                        'account/set-riskOffset-type': 2,
                        'account/activate-option': 4,
                        'account/set-auto-loan': 4,
                        'account/set-account-level': 4,
                        'account/mmp-reset': 4,
                        'account/mmp-config': 100,
                        // subaccount
                        'users/subaccount/modify-apikey': 10,
                        'asset/subaccount/transfer': 10,
                        'users/subaccount/set-transfer-out': 10,
                        'account/subaccount/set-loan-allocation': 4,
                        // grid trading
                        'tradingBot/grid/order-algo': 1,
                        'tradingBot/grid/amend-order-algo': 1,
                        'tradingBot/grid/stop-order-algo': 1,
                        'tradingBot/grid/close-position': 1,
                        'tradingBot/grid/cancel-close-order': 1,
                        'tradingBot/grid/order-instant-trigger': 1,
                        'tradingBot/grid/withdraw-income': 1,
                        'tradingBot/grid/compute-margin-balance': 1,
                        'tradingBot/grid/margin-balance': 1,
                        'tradingBot/grid/min-investment': 1,
                        'tradingBot/signal/create-signal': 1,
                        'tradingBot/signal/order-algo': 1,
                        'tradingBot/signal/stop-order-algo': 1,
                        'tradingBot/signal/margin-balance': 1,
                        'tradingBot/signal/amendTPSL': 1,
                        'tradingBot/signal/set-instruments': 1,
                        'tradingBot/signal/close-position': 1,
                        'tradingBot/signal/sub-order': 1,
                        'tradingBot/signal/cancel-sub-order': 1,
                        'tradingBot/recurring/order-algo': 1,
                        'tradingBot/recurring/amend-order-algo': 1,
                        'tradingBot/recurring/stop-order-algo': 1,
                        // earn
                        'finance/savings/purchase-redempt': 5 / 3,
                        'finance/savings/set-lending-rate': 5 / 3,
                        'finance/staking-defi/purchase': 3,
                        'finance/staking-defi/redeem': 3,
                        'finance/staking-defi/cancel': 3,
                        // eth staking
                        'finance/staking-defi/eth/purchase': 5,
                        'finance/staking-defi/eth/redeem': 5,
                        // copytrading
                        'copytrading/algo-order': 1,
                        'copytrading/close-subposition': 1,
                        'copytrading/set-instruments': 4,
                        'copytrading/first-copy-settings': 4,
                        'copytrading/amend-copy-settings': 4,
                        'copytrading/stop-copy-trading': 4,
                        'copytrading/batch-set-leverage': 4,
                        // broker
                        'broker/nd/create-subaccount': 0.25,
                        'broker/nd/delete-subaccount': 1,
                        'broker/nd/subaccount/apikey': 0.25,
                        'broker/nd/subaccount/modify-apikey': 1,
                        'broker/nd/subaccount/delete-apikey': 1,
                        'broker/nd/set-subaccount-level': 4,
                        'broker/nd/set-subaccount-fee-rate': 4,
                        'broker/nd/set-subaccount-assets': 0.25,
                        'asset/broker/nd/subaccount-deposit-address': 1,
                        'asset/broker/nd/modify-subaccount-deposit-address': 5 / 3,
                        'broker/nd/rebate-per-orders': 36000,
                        'finance/sfp/dcd/quote': 10,
                        'finance/sfp/dcd/order': 10,
                        'broker/nd/report-subaccount-ip': 0.25,
                        'broker/fd/rebate-per-orders': 36000,
                    },
                },
            },
            'fees': {
                'trading': {
                    'taker': this.parseNumber('0.0015'),
                    'maker': this.parseNumber('0.0010'),
                },
                'spot': {
                    'taker': this.parseNumber('0.0015'),
                    'maker': this.parseNumber('0.0010'),
                },
                'future': {
                    'taker': this.parseNumber('0.0005'),
                    'maker': this.parseNumber('0.0002'),
                },
                'swap': {
                    'taker': this.parseNumber('0.00050'),
                    'maker': this.parseNumber('0.00020'),
                },
            },
            'requiredCredentials': {
                'apiKey': true,
                'secret': true,
                'password': true,
            },
            'exceptions': {
                'exact': {
                    // Public error codes from 50000-53999
                    // General Class
                    '1': ExchangeOperationFailedError,
                    '2': ExchangeOperationFailedError,
                    '50000': InvalidParamsError,
                    '50001': ExchangeUnderMaintenanceError,
                    '50002': InvalidParamsError,
                    '50004': ExchangeRequestExpiredError,
                    '50005': ExchangeNotAvailableError,
                    '50006': InvalidParamsError,
                    '50007': ExchangeAccountSuspendedError,
                    '50008': AuthenticationError,
                    '50009': ExchangeAccountSuspendedError,
                    '50010': InvalidParamsError,
                    '50011': ExchangeRateLimitExceededError,
                    '50012': ExchangeError,
                    '50013': ExchangeNotAvailableError,
                    '50014': InvalidParamsError,
                    '50015': InvalidParamsError,
                    '50016': InvalidParamsError,
                    '50017': ExchangeError,
                    '50018': ExchangeError,
                    '50019': ExchangeError,
                    '50020': ExchangeError,
                    '50021': ExchangeError,
                    '50022': ExchangeError,
                    '50023': ExchangeError,
                    '50024': InvalidParamsError,
                    '50025': InvalidParamsError,
                    '50026': ExchangeNotAvailableError,
                    '50027': ExchangeInvalidPermissionsError,
                    '50028': TradeError,
                    '50044': TradeError,
                    // API Class
                    '50100': ExchangeNotAvailableError,
                    '50101': AuthenticationError,
                    '50102': ExchangeInvalidNonceError,
                    '50103': AuthenticationError,
                    '50104': AuthenticationError,
                    '50105': AuthenticationError,
                    '50106': AuthenticationError,
                    '50107': AuthenticationError,
                    '50108': InvalidParamsError,
                    '50109': ExchangeError,
                    '50110': ExchangeInvalidPermissionsError,
                    '50111': AuthenticationError,
                    '50112': AuthenticationError,
                    '50113': AuthenticationError,
                    '50114': AuthenticationError,
                    '50115': ExchangeNotAllowedMethodError,
                    '50120': ExchangeInvalidPermissionsError,
                    // Trade Class
                    '51000': InvalidParamsError,
                    '51001': InvalidParamsError,
                    '51002': InvalidParamsError,
                    '51003': InvalidParamsError,
                    '51004': InvalidParamsError,
                    '51005': InvalidParamsError,
                    '51006': InvalidParamsError,
                    '51007': TradeError,
                    '51008': InsufficientBalanceError,
                    '51009': ExchangeAccountSuspendedError,
                    '51010': ExchangeInvalidPermissionsError,
                    '51011': TradeError,
                    '51012': ExchangeUnknownAssetError,
                    '51014': ExchangeError,
                    '51015': ExchangeError,
                    '51016': TradeError,
                    '51017': InvalidParamsError,
                    '51018': ExchangeError,
                    '51019': ExchangeError,
                    '51020': InvalidParamsError,
                    '51021': ExchangeError,
                    '51022': ExchangeError,
                    '51023': TradeError,
                    '51024': ExchangeAccountSuspendedError,
                    '51025': TradeError,
                    '51026': ExchangeError,
                    '51027': ExchangeError,
                    '51028': ExchangeError,
                    '51029': ExchangeError,
                    '51030': ExchangeError,
                    '51031': InvalidParamsError,
                    '51046': InvalidParamsError,
                    '51047': InvalidParamsError,
                    '51072': TradeError,
                    '51073': TradeError,
                    '51074': TradeError,
                    '51100': InvalidParamsError,
                    '51101': InvalidParamsError,
                    '51102': InvalidParamsError,
                    '51103': InvalidParamsError,
                    '51104': InvalidParamsError,
                    '51105': InvalidParamsError,
                    '51106': InvalidParamsError,
                    '51107': InvalidParamsError,
                    '51108': TradeError,
                    '51109': TradeError,
                    '51110': TradeError,
                    '51111': TradeError,
                    '51112': TradeError,
                    '51113': ExchangeRateLimitExceededError,
                    '51115': TradeError,
                    '51116': InvalidParamsError,
                    '51117': TradeError,
                    '51118': TradeError,
                    '51119': InsufficientBalanceError,
                    '51120': InvalidParamsError,
                    '51121': InvalidParamsError,
                    '51122': InvalidParamsError,
                    '51124': TradeError,
                    '51125': TradeError,
                    '51126': TradeError,
                    '51127': InsufficientBalanceError,
                    '51128': TradeError,
                    '51129': TradeError,
                    '51130': TradeError,
                    '51131': InsufficientBalanceError,
                    '51132': InvalidParamsError,
                    '51133': TradeError,
                    '51134': TradeError,
                    '51135': InvalidParamsError,
                    '51136': InvalidParamsError,
                    '51137': InvalidParamsError,
                    '51138': InvalidParamsError,
                    '51139': TradeError,
                    '51156': TradeError,
                    '51159': TradeError,
                    '51162': TradeError,
                    '51163': TradeError,
                    '51166': TradeError,
                    '51174': TradeError,
                    '51185': InvalidParamsError,
                    '51201': TradeError,
                    '51202': InvalidParamsError,
                    '51203': InvalidParamsError,
                    '51204': InvalidParamsError,
                    '51205': InvalidParamsError,
                    '51250': InvalidParamsError,
                    '51251': InvalidParamsError,
                    '51252': InvalidParamsError,
                    '51253': TradeError,
                    '51254': TradeError,
                    '51255': TradeError,
                    '51256': TradeError,
                    '51257': TradeError,
                    '51258': TradeError,
                    '51259': TradeError,
                    '51260': TradeError,
                    '51261': TradeError,
                    '51262': TradeError,
                    '51263': TradeError,
                    '51264': TradeError,
                    '51265': TradeError,
                    '51267': TradeError,
                    '51268': TradeError,
                    '51269': TradeError,
                    '51270': TradeError,
                    '51271': TradeError,
                    '51272': TradeError,
                    '51273': TradeError,
                    '51274': TradeError,
                    '51275': TradeError,
                    '51276': TradeError,
                    '51277': TradeError,
                    '51278': TradeError,
                    '51279': TradeError,
                    '51280': TradeError,
                    '51321': TradeError,
                    '51322': TradeError,
                    '51323': TradeError,
                    '51324': TradeError,
                    '51325': TradeError,
                    '51327': TradeError,
                    '51328': TradeError,
                    '51329': TradeError,
                    '51330': TradeError,
                    '51400': OrderNotFoundError,
                    '51401': OrderNotFoundError,
                    '51402': OrderNotFoundError,
                    '51403': TradeError,
                    '51404': TradeError,
                    '51405': TradeError,
                    '51406': TradeError,
                    '51407': InvalidParamsError,
                    '51408': TradeError,
                    '51409': InvalidParamsError,
                    '51410': TradeError,
                    '51500': InvalidParamsError,
                    '51501': TradeError,
                    '51502': InsufficientBalanceError,
                    '51503': TradeError,
                    '51506': TradeError,
                    '51508': TradeError,
                    '51509': TradeError,
                    '51510': TradeError,
                    '51511': TradeError,
                    '51600': InvalidParamsError,
                    '51601': InvalidParamsError,
                    '51602': InvalidParamsError,
                    '51603': OrderNotFoundError,
                    '51732': AuthenticationError,
                    '51733': AuthenticationError,
                    '51734': AuthenticationError,
                    '51735': ExchangeError,
                    '51736': InsufficientBalanceError,
                    // Data class
                    '52000': ExchangeError,
                    // SPOT/MARGIN error codes 54000-54999
                    '54000': ExchangeError,
                    '54001': ExchangeError,
                    // FUNDING error codes 58000-58999
                    '58000': ExchangeError,
                    '58001': AuthenticationError,
                    '58002': ExchangeInvalidPermissionsError,
                    '58003': ExchangeUnknownAssetError,
                    '58004': ExchangeAccountSuspendedError,
                    '58005': InvalidParamsError,
                    '58006': ExchangeError,
                    '58007': InternalError,
                    '58100': ExchangeTransferError,
                    '58101': ExchangeAccountSuspendedError,
                    '58102': ExchangeRateLimitExceededError,
                    '58103': ExchangeError,
                    '58104': ExchangeError,
                    '58105': ExchangeError,
                    '58106': ExchangeError,
                    '58107': ExchangeError,
                    '58108': ExchangeError,
                    '58109': ExchangeError,
                    '58110': ExchangeTransferError,
                    '58111': ExchangeTransferError,
                    '58112': ExchangeTransferError,
                    '58114': InvalidParamsError,
                    '58115': ExchangeError,
                    '58116': ExchangeTransferError,
                    '58117': ExchangeError,
                    '58125': ExchangeTransferError,
                    '58126': ExchangeTransferError,
                    '58127': ExchangeTransferError,
                    '58128': ExchangeTransferError,
                    '58200': WithdrawalForbiddenError,
                    '58201': WithdrawalForbiddenError,
                    '58202': WithdrawalError,
                    '58203': InvalidAddressError,
                    '58204': ExchangeAccountSuspendedError,
                    '58205': OverWithdrawMaximumError,
                    '58206': UnderWithdrawMinimumError,
                    '58207': WhitelistCheckFailedError,
                    '58208': WithdrawalError,
                    '58209': WithdrawalError,
                    '58210': WithdrawalError,
                    '58211': WithdrawalError,
                    '58212': WithdrawalError,
                    '58213': AuthenticationError,
                    '58221': InvalidAddressError,
                    '58222': InvalidAddressError,
                    '58224': WithdrawalError,
                    '58227': WithdrawalError,
                    '58228': WithdrawalError,
                    '58229': InsufficientBalanceError,
                    '58300': GetDepositAddressError,
                    '58350': InsufficientBalanceError,
                    // Account error codes 59000-59999
                    '59000': ExchangeError,
                    '59001': ExchangeError,
                    '59100': TradeError,
                    '59101': TradeError,
                    '59102': TradeError,
                    '59103': InsufficientBalanceError,
                    '59104': TradeError,
                    '59105': TradeError,
                    '59106': TradeError,
                    '59107': TradeError,
                    '59108': InsufficientBalanceError,
                    '59109': TradeError,
                    '59128': TradeError,
                    '59200': InsufficientBalanceError,
                    '59201': InsufficientBalanceError,
                    '59216': TradeError,
                    '59260': ExchangeInvalidPermissionsError,
                    '59262': ExchangeInvalidPermissionsError,
                    '59300': ExchangeError,
                    '59301': ExchangeError,
                    '59313': ExchangeError,
                    '59401': ExchangeError,
                    '59500': ExchangeInvalidPermissionsError,
                    '59501': AuthenticationError,
                    '59502': ExchangeError,
                    '59503': AuthenticationError,
                    '59504': ExchangeNotAllowedMethodError,
                    '59505': AuthenticationError,
                    '59506': AuthenticationError,
                    '59507': ExchangeTransferError,
                    '59508': ExchangeAccountSuspendedError,
                    '59642': ExchangeError,
                    '59643': ExchangeError,
                    // WebSocket error Codes from 60000-63999
                    '60001': AuthenticationError,
                    '60002': AuthenticationError,
                    '60003': AuthenticationError,
                    '60004': AuthenticationError,
                    '60005': AuthenticationError,
                    '60006': ExchangeInvalidNonceError,
                    '60007': AuthenticationError,
                    '60008': AuthenticationError,
                    '60009': AuthenticationError,
                    '60010': AuthenticationError,
                    '60011': AuthenticationError,
                    '60012': InvalidParamsError,
                    '60013': InvalidParamsError,
                    '60014': ExchangeRateLimitExceededError,
                    '60015': ExchangeError,
                    '60016': ExchangeNotAvailableError,
                    '60017': InvalidParamsError,
                    '60018': InvalidParamsError,
                    '60019': InvalidParamsError,
                    '63999': InternalError,
                    '70010': InternalError,
                    '70013': InvalidParamsError,
                    '70016': InvalidParamsError, // Please specify your instrument settings for at least one instType.
                },
                'broad': {
                    'Internal Server Error': ExchangeNotAvailableError,
                    'server error': ExchangeNotAvailableError, // {"code":500,"data":{},"detailMsg":"","error_code":"500","error_message":"server error 1236805249","msg":"server error 1236805249"}
                },
                'messages': {
                    'Order amount should be greater than the minimum available amount': SdkErrorMessages.ORDER_UNDER_MINIMUM,
                },
            },
            'httpExceptions': {
                '429': ExchangeNotAvailableError, // https://github.com/ccxt/ccxt/issues/9612
            },
            'precisionMode': TICK_SIZE,
            'options': {
                'sandboxMode': false,
                'timeDifference': 0,
                'defaultNetwork': 'ERC20',
                'defaultNetworks': {
                    'ETH': 'ERC20',
                    'BTC': 'BTC',
                    'USDT': 'TRC20',
                },
                'networks': {
                    'BTC': 'Bitcoin',
                    'BTCLN': 'Lightning',
                    'BEP20': 'BSC',
                    'ERC20': 'ERC20',
                    'TRC20': 'TRC20',
                    'CRC20': 'Crypto',
                    // sorted
                    'ACA': 'Acala',
                    'ALGO': 'Algorand',
                    'BHP': 'BHP',
                    'APT': 'Aptos',
                    'ARBONE': 'Arbitrum One',
                    'AVAXC': 'Avalanche C',
                    'AVAXX': 'Avalanche X-Chain',
                    'ARK': 'ARK',
                    'AR': 'Arweave',
                    'ASTR': 'Astar',
                    'BCH': 'BitcoinCash',
                    'BSV': 'Bitcoin SV',
                    'BTM': 'Bytom',
                    'ADA': 'Cardano',
                    'CSPR': 'Casper',
                    'CELO': 'CELO',
                    'XCH': 'Chia',
                    'CHZ': 'Chiliz',
                    'ATOM': 'Cosmos',
                    'TRUE': 'TrueChain',
                    'DCR': 'Decred',
                    'DGB': 'Digibyte',
                    'DOGE': 'Dogecoin',
                    'XEC': 'XEC',
                    'EGLD': 'Elrond',
                    'EOS': 'EOS',
                    'ETC': 'Ethereum Classic',
                    'ETHW': 'EthereumPow',
                    'FTM': 'Fantom',
                    'FIL': 'Filecoin',
                    'FLOW': 'FLOW',
                    'FSN': 'Fusion',
                    'ONE': 'Harmony',
                    'HBAR': 'Hedera',
                    'HNT': 'Helium',
                    'ZEN': 'Horizen',
                    'ICX': 'ICON',
                    'ICP': 'Dfinity',
                    'IOST': 'IOST',
                    'IOTA': 'MIOTA',
                    'KDA': 'Kadena',
                    'KAR': 'KAR',
                    'KLAY': 'Klaytn',
                    'KSM': 'Kusama',
                    'LSK': 'Lisk',
                    'LTC': 'Litecoin',
                    'METIS': 'Metis',
                    'MINA': 'Mina',
                    'XMR': 'Monero',
                    'GLRM': 'Moonbeam',
                    'MOVR': 'Moonriver',
                    'NANO': 'Nano',
                    'NEAR': 'NEAR',
                    'NAS': 'Nebulas',
                    'NEM': 'New Economy Movement',
                    'NULS': 'NULS',
                    'OASYS': 'OASYS',
                    'OKC': 'OKC',
                    'ONT': 'Ontology',
                    'OPTIMISM': 'Optimism',
                    'LAT': 'PlatON',
                    'DOT': 'Polkadot',
                    'MATIC': 'Polygon',
                    'RVN': 'Ravencoin',
                    'XRP': 'Ripple',
                    'SC': 'Siacoin',
                    'SOL': 'Solana',
                    'STX': 'l-Stacks',
                    'XLM': 'Stellar Lumens',
                    'XTZ': 'Tezos',
                    'TON': 'TON',
                    'THETA': 'Theta',
                    'VSYS': 'VSYSTEMS',
                    'WAVES': 'WAVES',
                    'WAX': 'Wax',
                    'ZEC': 'Zcash',
                    'ZIL': 'Zilliqa',
                    'ZKSYNC': 'ZKSYNC',
                    // 'NEON3': 'N3', // tbd
                    // undetermined : "CELO-TOKEN", "Digital Cash", Khala
                    // todo: uncomment below after consensus
                    // 'AELF': 'AELF',
                    // 'BITCOINDIAMOND': 'Bitcoin Diamond',
                    // 'BITCOINGOLD': 'BitcoinGold',
                    // 'YOYOW': 'YOYOW',
                    // 'QTUM': 'Quantum',
                    // 'INTCHAIN': 'INTCHAIN',
                    // 'YOUCHAIN': 'YOUCHAIN',
                    // 'RONIN': 'Ronin',
                    // 'OEC': 'OEC',
                    // 'WAYIKICHAIN': 'WGRT',
                    // 'MDNA': 'DNA',
                    // 'STEP': 'Step Network',
                    // 'EMINER': 'Eminer',
                    // 'CYBERMILES': 'CyberMiles',
                    // 'HYPERCASH': 'HyperCash',
                    // 'CONFLUX': 'Conflux',
                    // 'CORTEX': 'Cortex',
                    // 'TERRA': 'Terra',
                    // 'TERRACLASSIC': 'Terra Classic',
                },
                'fetchOHLCV': {
                    // 'type': 'Candles', // Candles or HistoryCandles, IndexCandles, MarkPriceCandles
                    'timezone': 'UTC', // UTC, HK
                },
                'fetchPositions': {
                    'method': 'privateGetAccountPositions', // privateGetAccountPositions or privateGetAccountPositionsHistory
                },
                'createOrder': 'privatePostTradeBatchOrders',
                'createMarketBuyOrderRequiresPrice': false,
                'fetchMarkets': ['spot', 'future', 'swap', 'option'],
                'defaultType': 'spot',
                // 'fetchBalance': {
                //     'type': 'spot', // 'funding', 'trading', 'spot'
                // },
                'fetchLedger': {
                    'method': 'privateGetAccountBills', // privateGetAccountBills, privateGetAccountBillsArchive, privateGetAssetBills
                },
                // 6: Funding account, 18: Trading account
                'fetchOrder': {
                    'method': 'privateGetTradeOrder', // privateGetTradeOrdersAlgoHistory
                },
                'fetchOpenOrders': {
                    'method': 'privateGetTradeOrdersPending', // privateGetTradeOrdersAlgoPending
                },
                'cancelOrders': {
                    'method': 'privatePostTradeCancelBatchOrders', // privatePostTradeCancelAlgos
                },
                'fetchCanceledOrders': {
                    'method': 'privateGetTradeOrdersHistory', // privateGetTradeOrdersAlgoHistory
                },
                'fetchClosedOrders': {
                    'method': 'privateGetTradeOrdersHistory', // privateGetTradeOrdersAlgoHistory
                },
                'withdraw': {
                    // a funding password credential is required by the exchange for the
                    // withdraw call (not to be confused with the api password credential)
                    'password': undefined,
                    'pwd': undefined, // password or pwd both work
                },
                'algoOrderTypes': {
                    'conditional': true,
                    'trigger': true,
                    'oco': true,
                    'move_order_stop': true,
                    'iceberg': true,
                    'twap': true,
                },
                'accountsByType': {
                    'funding': '6',
                    'trading': '18',
                    'spot': '18',
                    'future': '18',
                    'futures': '18',
                    'margin': '18',
                    'swap': '18',
                    'option': '18',
                },
                'accountsById': {
                    '6': 'funding',
                    '18': 'trading', // unified trading account
                },
                'exchangeType': {
                    'spot': 'SPOT',
                    'margin': 'MARGIN',
                    'swap': 'SWAP',
                    'future': 'FUTURES',
                    'futures': 'FUTURES',
                    'option': 'OPTION',
                    'SPOT': 'SPOT',
                    'MARGIN': 'MARGIN',
                    'SWAP': 'SWAP',
                    'FUTURES': 'FUTURES',
                    'OPTION': 'OPTION',
                },
                'brokerId': 'e847386590ce4dBC',
            },
            'commonCurrencies': {
                // the exchange refers to ERC20 version of Aeternity (AEToken)
                'AE': 'AET',
                'WIN': 'WINTOKEN', // https://github.com/ccxt/ccxt/issues/5701
            },
        });
    }
    isUsingForcedProxy(params = {}, api = 'public') {
        if (api === 'private') {
            return true;
        }
        return false;
    }
    nonce() {
        return this.milliseconds() - this.options['timeDifference'];
    }
    handleMarketTypeAndParams(methodName, market = undefined, params = {}) {
        const instType = this.safeString(params, 'instType');
        params = this.omit(params, 'instType');
        const type = this.safeString(params, 'type');
        if ((type === undefined) && (instType !== undefined)) {
            params['type'] = instType;
        }
        return super.handleMarketTypeAndParams(methodName, market, params);
    }
    convertToInstrumentType(type) {
        const exchangeTypes = this.safeValue(this.options, 'exchangeType', {});
        return this.safeString(exchangeTypes, type, type);
    }
    convertExpireDate(date) {
        // parse YYMMDD to timestamp
        const year = date.slice(0, 2);
        const month = date.slice(2, 4);
        const day = date.slice(4, 6);
        const reconstructedDate = '20' + year + '-' + month + '-' + day + 'T00:00:00Z';
        return reconstructedDate;
    }
    createExpiredOptionMarket(symbol) {
        // support expired option contracts
        const quote = 'USD';
        const optionParts = symbol.split('-');
        const symbolBase = symbol.split('/');
        let base = undefined;
        if (symbol.indexOf('/') > -1) {
            base = this.safeString(symbolBase, 0);
        }
        else {
            base = this.safeString(optionParts, 0);
        }
        const settle = base;
        const expiry = this.safeString(optionParts, 2);
        const strike = this.safeString(optionParts, 3);
        const optionType = this.safeString(optionParts, 4);
        const datetime = this.convertExpireDate(expiry);
        const timestamp = this.parse8601(datetime);
        return {
            'id': base + '-' + quote + '-' + expiry + '-' + strike + '-' + optionType,
            'symbol': base + '/' + quote + ':' + settle + '-' + expiry + '-' + strike + '-' + optionType,
            'base': base,
            'quote': quote,
            'settle': settle,
            'baseId': base,
            'quoteId': quote,
            'settleId': settle,
            'active': false,
            'type': 'option',
            'linear': undefined,
            'inverse': undefined,
            'spot': false,
            'swap': false,
            'future': false,
            'option': true,
            'margin': false,
            'contract': true,
            'contractSize': this.parseNumber('1'),
            'expiry': timestamp,
            'expiryDatetime': datetime,
            'optionType': (optionType === 'C') ? 'call' : 'put',
            'strike': this.parseNumber(strike),
            'precision': {
                'amount': undefined,
                'price': undefined,
            },
            'limits': {
                'amount': {
                    'min': undefined,
                    'max': undefined,
                },
                'price': {
                    'min': undefined,
                    'max': undefined,
                },
                'cost': {
                    'min': undefined,
                    'max': undefined,
                },
            },
            'info': undefined,
        };
    }
    safeMarket(marketId = undefined, market = undefined, delimiter = undefined, marketType = undefined) {
        const isOption = (marketId !== undefined) && ((marketId.indexOf('-C') > -1) || (marketId.indexOf('-P') > -1));
        if (isOption && !(marketId in this.markets_by_id)) {
            // handle expired option contracts
            return this.createExpiredOptionMarket(marketId);
        }
        return super.safeMarket(marketId, market, delimiter, marketType);
    }
    async fetchStatus(params = {}) {
        /**
         * @method
         * @name okx#fetchStatus
         * @description the latest known information on the availability of the exchange API
         * @see https://www.okx.com/docs-v5/en/#status-get-status
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a [status structure]{@link https://docs.ccxt.com/#/?id=exchange-status-structure}
         */
        const response = await this.publicGetSystemStatus(params);
        //
        // Note, if there is no maintenance around, the 'data' array is empty
        //
        //     {
        //         "code": "0",
        //         "msg": "",
        //         "data": [
        //             {
        //                 "begin": "1621328400000",
        //                 "end": "1621329000000",
        //                 "href": "https://www.okx.com/support/hc/en-us/articles/360060882172",
        //                 "scheDesc": "",
        //                 "serviceType": "1", // 0 WebSocket, 1 Spot/Margin, 2 Futures, 3 Perpetual, 4 Options, 5 Trading service
        //                 "state": "scheduled", // ongoing, completed, canceled
        //                 "system": "classic", // classic, unified
        //                 "title": "Classic Spot System Upgrade"
        //             },
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        const dataLength = data.length;
        const update = {
            'updated': undefined,
            'status': (dataLength === 0) ? 'ok' : 'maintenance',
            'eta': undefined,
            'url': undefined,
            'info': response,
        };
        for (let i = 0; i < data.length; i++) {
            const event = data[i];
            const state = this.safeString(event, 'state');
            update['eta'] = this.safeInteger(event, 'end');
            update['url'] = this.safeString(event, 'href');
            if (state === 'ongoing') {
                update['status'] = 'maintenance';
            }
            else if (state === 'scheduled') {
                update['status'] = 'ok';
            }
            else if (state === 'completed') {
                update['status'] = 'ok';
            }
            else if (state === 'canceled') {
                update['status'] = 'ok';
            }
        }
        return update;
    }
    async fetchTime(params = {}) {
        /**
         * @method
         * @name okx#fetchTime
         * @description fetches the current integer timestamp in milliseconds from the exchange server
         * @see https://www.okx.com/docs-v5/en/#public-data-rest-api-get-system-time
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {int} the current integer timestamp in milliseconds from the exchange server
         */
        const response = await this.publicGetPublicTime(params);
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {"ts": "1621247923668"}
        //         ],
        //         "msg": ""
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        const first = this.safeValue(data, 0, {});
        return this.safeInteger(first, 'ts');
    }
    async fetchAccounts(params = {}) {
        /**
         * @method
         * @name okx#fetchAccounts
         * @description fetch all the accounts associated with a profile
         * @see https://www.okx.com/docs-v5/en/#trading-account-rest-api-get-account-configuration
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a dictionary of [account structures]{@link https://docs.ccxt.com/#/?id=account-structure} indexed by the account type
         */
        const response = await this.privateGetAccountConfig(params);
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "acctLv": "2",
        //                 "autoLoan": false,
        //                 "ctIsoMode": "automatic",
        //                 "greeksType": "PA",
        //                 "level": "Lv1",
        //                 "levelTmp": "",
        //                 "mgnIsoMode": "automatic",
        //                 "posMode": "long_short_mode",
        //                 "uid": "88018754289672195"
        //             }
        //         ],
        //         "msg": ""
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        const result = [];
        for (let i = 0; i < data.length; i++) {
            const account = data[i];
            const accountId = this.safeString(account, 'uid');
            const type = this.safeString(account, 'acctLv');
            result.push({
                'id': accountId,
                'type': type,
                'currency': undefined,
                'info': account,
                'code': undefined,
            });
        }
        return result;
    }
    async fetchMarkets(params = {}) {
        /**
         * @method
         * @name okx#fetchMarkets
         * @description retrieves data on all markets for okx
         * @see https://www.okx.com/docs-v5/en/#rest-api-public-data-get-instruments
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object[]} an array of objects representing market data
         */
        const types = this.safeValue(this.options, 'fetchMarkets');
        let promises = [];
        let result = [];
        for (let i = 0; i < types.length; i++) {
            promises.push(this.fetchMarketsByType(types[i], params));
        }
        promises = await Promise.all(promises);
        for (let i = 0; i < promises.length; i++) {
            result = this.arrayConcat(result, promises[i]);
        }
        return result;
    }
    parseMarket(market) {
        //
        //     {
        //         "alias": "", // this_week, next_week, quarter, next_quarter
        //         "baseCcy": "BTC",
        //         "category": "1",
        //         "ctMult": "",
        //         "ctType": "", // inverse, linear
        //         "ctVal": "",
        //         "ctValCcy": "",
        //         "expTime": "",
        //         "instId": "BTC-USDT", // BTC-USD-210521, CSPR-USDT-SWAP, BTC-USD-210517-44000-C
        //         "instType": "SPOT", // SPOT, FUTURES, SWAP, OPTION
        //         "lever": "10",
        //         "listTime": "1548133413000",
        //         "lotSz": "0.00000001",
        //         "minSz": "0.00001",
        //         "optType": "",
        //         "quoteCcy": "USDT",
        //         "settleCcy": "",
        //         "state": "live",
        //         "stk": "",
        //         "tickSz": "0.1",
        //         "uly": ""
        //     }
        //
        //     {
        //         "alias": "",
        //         "baseCcy": "",
        //         "category": "1",
        //         "ctMult": "0.1",
        //         "ctType": "",
        //         "ctVal": "1",
        //         "ctValCcy": "BTC",
        //         "expTime": "1648195200000",
        //         "instId": "BTC-USD-220325-194000-P",
        //         "instType": "OPTION",
        //         "lever": "",
        //         "listTime": "1631262612280",
        //         "lotSz": "1",
        //         "minSz": "1",
        //         "optType": "P",
        //         "quoteCcy": "",
        //         "settleCcy": "BTC",
        //         "state": "live",
        //         "stk": "194000",
        //         "tickSz": "0.0005",
        //         "uly": "BTC-USD"
        //     }
        //
        const id = this.safeString(market, 'instId');
        let type = this.safeStringLower(market, 'instType');
        if (type === 'futures') {
            type = 'future';
        }
        const spot = (type === 'spot');
        const future = (type === 'future');
        const swap = (type === 'swap');
        const option = (type === 'option');
        const contract = swap || future || option;
        let baseId = this.safeString(market, 'baseCcy');
        let quoteId = this.safeString(market, 'quoteCcy');
        const settleId = this.safeString(market, 'settleCcy');
        const settle = this.safeCurrencyCode(settleId);
        const underlying = this.safeString(market, 'uly');
        if ((underlying !== undefined) && !spot) {
            const parts = underlying.split('-');
            baseId = this.safeString(parts, 0);
            quoteId = this.safeString(parts, 1);
        }
        const base = this.safeCurrencyCode(baseId);
        const quote = this.safeCurrencyCode(quoteId);
        let symbol = base + '/' + quote;
        let expiry = undefined;
        let strikePrice = undefined;
        let optionType = undefined;
        if (contract) {
            symbol = symbol + ':' + settle;
            expiry = this.safeInteger(market, 'expTime');
            if (future) {
                const ymd = this.yymmdd(expiry);
                symbol = symbol + '-' + ymd;
            }
            else if (option) {
                strikePrice = this.safeString(market, 'stk');
                optionType = this.safeString(market, 'optType');
                const ymd = this.yymmdd(expiry);
                symbol = symbol + '-' + ymd + '-' + strikePrice + '-' + optionType;
                optionType = (optionType === 'P') ? 'put' : 'call';
            }
        }
        const tickSize = this.safeString(market, 'tickSz');
        const fees = this.safeValue2(this.fees, type, 'trading', {});
        let maxLeverage = this.safeString(market, 'lever', '1');
        maxLeverage = Precise.stringMax(maxLeverage, '1');
        const maxSpotCost = this.safeNumber(market, 'maxMktSz');
        return this.extend(fees, {
            'id': id,
            'symbol': symbol,
            'base': base,
            'quote': quote,
            'settle': settle,
            'baseId': baseId,
            'quoteId': quoteId,
            'settleId': settleId,
            'type': type,
            'spot': spot,
            'margin': spot && (Precise.stringGt(maxLeverage, '1')),
            'swap': swap,
            'future': future,
            'option': option,
            'active': true,
            'contract': contract,
            'linear': contract ? (quoteId === settleId) : undefined,
            'inverse': contract ? (baseId === settleId) : undefined,
            'contractSize': contract ? this.safeNumber(market, 'ctVal') : undefined,
            'expiry': expiry,
            'expiryDatetime': this.iso8601(expiry),
            'strike': strikePrice,
            'optionType': optionType,
            'created': this.safeInteger(market, 'listTime'),
            'precision': {
                'amount': this.safeNumber(market, 'lotSz'),
                'price': this.parseNumber(tickSize),
            },
            'limits': {
                'leverage': {
                    'min': this.parseNumber('1'),
                    'max': this.parseNumber(maxLeverage),
                },
                'amount': {
                    'min': this.safeNumber(market, 'minSz'),
                    'max': undefined,
                },
                'price': {
                    'min': undefined,
                    'max': undefined,
                },
                'cost': {
                    'min': undefined,
                    'max': contract ? undefined : maxSpotCost,
                },
            },
            'info': market,
        });
    }
    async fetchMarketsByType(type, params = {}) {
        const request = {
            'instType': this.convertToInstrumentType(type),
        };
        if (type === 'option') {
            const optionsUnderlying = this.safeValue(this.options, 'defaultUnderlying', ['BTC-USD', 'ETH-USD']);
            const promises = [];
            for (let i = 0; i < optionsUnderlying.length; i++) {
                const underlying = optionsUnderlying[i];
                request['uly'] = underlying;
                promises.push(this.publicGetPublicInstruments(this.extend(request, params)));
            }
            const promisesResult = await Promise.all(promises);
            let markets = [];
            for (let i = 0; i < promisesResult.length; i++) {
                const res = this.safeValue(promisesResult, i, {});
                const options = this.safeValue(res, 'data', []);
                markets = this.arrayConcat(markets, options);
            }
            return this.parseMarkets(markets);
        }
        const response = await this.publicGetPublicInstruments(this.extend(request, params));
        //
        // spot, future, swap, option
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "alias": "", // this_week, next_week, quarter, next_quarter
        //                 "baseCcy": "BTC",
        //                 "category": "1",
        //                 "ctMult": "",
        //                 "ctType": "", // inverse, linear
        //                 "ctVal": "",
        //                 "ctValCcy": "",
        //                 "expTime": "",
        //                 "instId": "BTC-USDT", // BTC-USD-210521, CSPR-USDT-SWAP, BTC-USD-210517-44000-C
        //                 "instType": "SPOT", // SPOT, FUTURES, SWAP, OPTION
        //                 "lever": "10",
        //                 "listTime": "1548133413000",
        //                 "lotSz": "0.00000001",
        //                 "minSz": "0.00001",
        //                 "optType": "",
        //                 "quoteCcy": "USDT",
        //                 "settleCcy": "",
        //                 "state": "live",
        //                 "stk": "",
        //                 "tickSz": "0.1",
        //                 "uly": ""
        //             }
        //         ],
        //         "msg": ""
        //     }
        //
        const dataResponse = this.safeValue(response, 'data', []);
        return this.parseMarkets(dataResponse);
    }
    safeNetwork(networkId) {
        const networksById = {
            'Bitcoin': 'BTC',
            'Optimism (V2)': 'Optimism',
            'Omni': 'OMNI',
            'TRON': 'TRC20',
        };
        return this.safeString(networksById, networkId, networkId);
    }
    async fetchCurrencies(params = {}) {
        /**
         * @method
         * @name okx#fetchCurrencies
         * @description fetches all available currencies on an exchange
         * @see https://www.okx.com/docs-v5/en/#rest-api-funding-get-currencies
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} an associative dictionary of currencies
         */
        // this endpoint requires authentication
        // while fetchCurrencies is a public API method by design
        // therefore we check the keys here
        // and fallback to generating the currencies from the markets
        const isSandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        if (!this.checkRequiredCredentials(false) || isSandboxMode) {
            return undefined;
        }
        //
        // has['fetchCurrencies'] is currently set to true, but an unauthorized request returns
        //
        //     {"msg":"Request header “OK_ACCESS_KEY“ can't be empty.","code":"50103"}
        //
        const response = await this.privateGetAssetCurrencies(params);
        //
        //    {
        //        "code": "0",
        //        "data": [
        //            {
        //                "canDep": true,
        //                "canInternal": false,
        //                "canWd": true,
        //                "ccy": "USDT",
        //                "chain": "USDT-TRC20",
        //                "logoLink": "https://static.coinall.ltd/cdn/assets/imgs/221/5F74EB20302D7761.png",
        //                "mainNet": false,
        //                "maxFee": "1.6",
        //                "maxWd": "8852150",
        //                "minFee": "0.8",
        //                "minWd": "2",
        //                "name": "Tether",
        //                "usedWdQuota": "0",
        //                "wdQuota": "500",
        //                "wdTickSz": "3"
        //            },
        //            {
        //                "canDep": true,
        //                "canInternal": false,
        //                "canWd": true,
        //                "ccy": "USDT",
        //                "chain": "USDT-ERC20",
        //                "logoLink": "https://static.coinall.ltd/cdn/assets/imgs/221/5F74EB20302D7761.png",
        //                "mainNet": false,
        //                "maxFee": "16",
        //                "maxWd": "8852150",
        //                "minFee": "8",
        //                "minWd": "2",
        //                "name": "Tether",
        //                "usedWdQuota": "0",
        //                "wdQuota": "500",
        //                "wdTickSz": "3"
        //            },
        //            ...
        //        ],
        //        "msg": ""
        //    }
        //
        const data = this.safeValue(response, 'data', []);
        const result = {};
        const dataByCurrencyId = this.groupBy(data, 'ccy');
        const currencyIds = Object.keys(dataByCurrencyId);
        for (let i = 0; i < currencyIds.length; i++) {
            const currencyId = currencyIds[i];
            const currency = this.safeCurrency(currencyId);
            const code = currency['code'];
            const chains = dataByCurrencyId[currencyId];
            const networks = {};
            let currencyActive = false;
            let isTokenDepositable = false;
            let isTokenWithdrawable = false;
            let maxPrecision = undefined;
            for (let j = 0; j < chains.length; j++) {
                const chain = chains[j];
                const isDepositEnabled = this.safeValue(chain, 'canDep');
                const isWithdrawalEnabled = this.safeValue(chain, 'canWd');
                isTokenDepositable = isDepositEnabled || isTokenDepositable;
                isTokenWithdrawable = isWithdrawalEnabled || isTokenWithdrawable;
                const canInternal = this.safeValue(chain, 'canInternal');
                const active = (isDepositEnabled && isWithdrawalEnabled && canInternal) ? true : false;
                currencyActive = (active) ? active : currencyActive;
                const networkId = this.safeString(chain, 'chain');
                if ((networkId !== undefined) && (networkId.indexOf('-') >= 0)) {
                    const parts = networkId.split('-');
                    const chainPart = parts.slice(1).join('-');
                    const networkCode = this.safeNetwork(chainPart);
                    const precision = this.parsePrecision(this.safeString(chain, 'wdTickSz'));
                    if (maxPrecision === undefined) {
                        maxPrecision = precision;
                    }
                    else {
                        maxPrecision = Precise.stringMin(maxPrecision, precision);
                    }
                    networks[networkCode] = {
                        'id': networkId,
                        'network': networkCode,
                        'active': active,
                        'deposit': isDepositEnabled,
                        'withdraw': isWithdrawalEnabled,
                        'fee': this.safeNumber(chain, 'minFee'),
                        'precision': this.parseNumber(precision),
                        'limits': {
                            'withdraw': {
                                'min': this.safeNumber(chain, 'minWd'),
                                'max': this.safeNumber(chain, 'maxWd'),
                            },
                        },
                        'info': chain,
                    };
                }
            }
            const firstChain = this.safeValue(chains, 0);
            result[code] = {
                'info': undefined,
                'code': code,
                'id': currencyId,
                'name': this.safeString(firstChain, 'name'),
                'active': currencyActive,
                'deposit': isTokenDepositable,
                'withdraw': isTokenWithdrawable,
                'fee': undefined,
                'precision': this.parseNumber(maxPrecision),
                'limits': {
                    'amount': {
                        'min': undefined,
                        'max': undefined,
                    },
                },
                'networks': networks,
            };
        }
        return result;
    }
    async fetchOrderBook(symbol, limit = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchOrderBook
         * @description fetches information on open orders with bid (buy) and ask (sell) prices, volumes and other data
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-market-data-get-order-book
         * @param {string} symbol unified symbol of the market to fetch the order book for
         * @param {int} [limit] the maximum amount of order book entries to return
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.method] 'publicGetMarketBooksFull' or 'publicGetMarketBooks' default is 'publicGetMarketBooks'
         * @returns {object} A dictionary of [order book structures]{@link https://docs.ccxt.com/#/?id=order-book-structure} indexed by market symbols
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        const request = {
            'instId': market['id'],
        };
        let method = undefined;
        [method, params] = this.handleOptionAndParams(params, 'fetchOrderBook', 'method', 'publicGetMarketBooks');
        if (method === 'publicGetMarketBooksFull' && limit === undefined) {
            limit = 5000;
        }
        limit = (limit === undefined) ? 100 : limit;
        if (limit !== undefined) {
            request['sz'] = limit; // max 400
        }
        let response = undefined;
        if ((method === 'publicGetMarketBooksFull') || (limit > 400)) {
            response = await this.publicGetMarketBooksFull(this.extend(request, params));
        }
        else {
            response = await this.publicGetMarketBooks(this.extend(request, params));
        }
        //
        //     {
        //         "code": "0",
        //         "msg": "",
        //         "data": [
        //             {
        //                 "asks": [
        //                     ["0.07228","4.211619","0","2"], // price, amount, liquidated orders, total open orders
        //                     ["0.0723","299.880364","0","2"],
        //                     ["0.07231","3.72832","0","1"],
        //                 ],
        //                 "bids": [
        //                     ["0.07221","18.5","0","1"],
        //                     ["0.0722","18.5","0","1"],
        //                     ["0.07219","0.505407","0","1"],
        //                 ],
        //                 "ts": "1621438475342"
        //             }
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        const first = this.safeValue(data, 0, {});
        const timestamp = this.safeInteger(first, 'ts');
        return this.parseOrderBook(first, symbol, timestamp);
    }
    parseTicker(ticker, market = undefined) {
        //
        //     {
        //         "instType": "SPOT",
        //         "instId": "ETH-BTC",
        //         "last": "0.07319",
        //         "lastSz": "0.044378",
        //         "askPx": "0.07322",
        //         "askSz": "4.2",
        //         "bidPx": "0.0732",
        //         "bidSz": "6.050058",
        //         "open24h": "0.07801",
        //         "high24h": "0.07975",
        //         "low24h": "0.06019",
        //         "volCcy24h": "11788.887619",
        //         "vol24h": "167493.829229",
        //         "ts": "1621440583784",
        //         "sodUtc0": "0.07872",
        //         "sodUtc8": "0.07345"
        //     }
        //
        const timestamp = this.safeInteger(ticker, 'ts');
        const marketId = this.safeString(ticker, 'instId');
        market = this.safeMarket(marketId, market, '-');
        const symbol = market['symbol'];
        const last = this.safeString(ticker, 'last');
        const open = this.safeString(ticker, 'open24h');
        const spot = this.safeBool(market, 'spot', false);
        const quoteVolume = spot ? this.safeString(ticker, 'volCcy24h') : undefined;
        const baseVolume = this.safeString(ticker, 'vol24h');
        const high = this.safeString(ticker, 'high24h');
        const low = this.safeString(ticker, 'low24h');
        return this.safeTicker({
            'symbol': symbol,
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'high': high,
            'low': low,
            'bid': this.safeString(ticker, 'bidPx'),
            'bidVolume': this.safeString(ticker, 'bidSz'),
            'ask': this.safeString(ticker, 'askPx'),
            'askVolume': this.safeString(ticker, 'askSz'),
            'vwap': undefined,
            'open': open,
            'close': last,
            'last': last,
            'previousClose': undefined,
            'change': undefined,
            'percentage': undefined,
            'average': undefined,
            'baseVolume': baseVolume,
            'quoteVolume': quoteVolume,
            'info': ticker,
        }, market);
    }
    async fetchTicker(symbol, params = {}) {
        /**
         * @method
         * @name okx#fetchTicker
         * @description fetches a price ticker, a statistical calculation with the information calculated over the past 24 hours for a specific market
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-market-data-get-ticker
         * @param {string} symbol unified symbol of the market to fetch the ticker for
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a [ticker structure]{@link https://docs.ccxt.com/#/?id=ticker-structure}
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        const request = {
            'instId': market['id'],
        };
        const response = await this.publicGetMarketTicker(this.extend(request, params));
        //
        //     {
        //         "code": "0",
        //         "msg": "",
        //         "data": [
        //             {
        //                 "instType": "SPOT",
        //                 "instId": "ETH-BTC",
        //                 "last": "0.07319",
        //                 "lastSz": "0.044378",
        //                 "askPx": "0.07322",
        //                 "askSz": "4.2",
        //                 "bidPx": "0.0732",
        //                 "bidSz": "6.050058",
        //                 "open24h": "0.07801",
        //                 "high24h": "0.07975",
        //                 "low24h": "0.06019",
        //                 "volCcy24h": "11788.887619",
        //                 "vol24h": "167493.829229",
        //                 "ts": "1621440583784",
        //                 "sodUtc0": "0.07872",
        //                 "sodUtc8": "0.07345"
        //             }
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        const first = this.safeValue(data, 0, {});
        return this.parseTicker(first, market);
    }
    async fetchTickersByType(type, symbols = undefined, params = {}) {
        await this.loadMarkets();
        const request = {
            'instType': this.convertToInstrumentType(type),
        };
        if (type === 'option') {
            const defaultUnderlying = this.safeValue(this.options, 'defaultUnderlying', 'BTC-USD');
            const currencyId = this.safeString2(params, 'uly', 'marketId', defaultUnderlying);
            if (currencyId === undefined) {
                throw new InvalidParamsError('fetchTickersByType() requires an underlying uly or marketId parameter for options markets').exchange(this.id);
            }
            else {
                request['uly'] = currencyId;
            }
        }
        const response = await this.publicGetMarketTickers(this.extend(request, params));
        //
        //     {
        //         "code": "0",
        //         "msg": "",
        //         "data": [
        //             {
        //                 "instType": "SPOT",
        //                 "instId": "BCD-BTC",
        //                 "last": "0.0000769",
        //                 "lastSz": "5.4788",
        //                 "askPx": "0.0000777",
        //                 "askSz": "3.2197",
        //                 "bidPx": "0.0000757",
        //                 "bidSz": "4.7509",
        //                 "open24h": "0.0000885",
        //                 "high24h": "0.0000917",
        //                 "low24h": "0.0000596",
        //                 "volCcy24h": "9.2877",
        //                 "vol24h": "124824.1985",
        //                 "ts": "1621441741434",
        //                 "sodUtc0": "0.0000905",
        //                 "sodUtc8": "0.0000729"
        //             },
        //         ]
        //     }
        //
        const tickers = this.safeValue(response, 'data', []);
        return this.parseTickers(tickers, symbols);
    }
    async fetchTickers(symbols = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchTickers
         * @description fetches price tickers for multiple markets, statistical information calculated over the past 24 hours for each market
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-market-data-get-tickers
         * @param {string[]|undefined} symbols unified symbols of the markets to fetch the ticker for, all market tickers are returned if not assigned
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a dictionary of [ticker structures]{@link https://docs.ccxt.com/#/?id=ticker-structure}
         */
        await this.loadMarkets();
        symbols = this.marketSymbols(symbols);
        const first = this.safeString(symbols, 0);
        let market = undefined;
        if (first !== undefined) {
            market = this.market(first);
        }
        const [type, query] = this.handleMarketTypeAndParams('fetchTickers', market, params);
        return await this.fetchTickersByType(type, symbols, query);
    }
    parseTrade(trade, market = undefined) {
        //
        // public fetchTrades
        //
        //     {
        //         "instId": "ETH-BTC",
        //         "side": "sell",
        //         "sz": "0.119501",
        //         "px": "0.07065",
        //         "tradeId": "15826757",
        //         "ts": "1621446178316"
        //     }
        //
        // option: fetchTrades
        //
        //     {
        //         "fillVol": "0.46387625976562497",
        //         "fwdPx": "26299.754935451125",
        //         "indexPx": "26309.7",
        //         "instFamily": "BTC-USD",
        //         "instId": "BTC-USD-230526-26000-C",
        //         "markPx": "0.042386283557554236",
        //         "optType": "C",
        //         "px": "0.0415",
        //         "side": "sell",
        //         "sz": "90",
        //         "tradeId": "112",
        //         "ts": "1683907480154"
        //     }
        //
        // private fetchMyTrades
        //
        //     {
        //         "side": "buy",
        //         "fillSz": "0.007533",
        //         "fillPx": "2654.98",
        //         "fee": "-0.000007533",
        //         "ordId": "317321390244397056",
        //         "instType": "SPOT",
        //         "instId": "ETH-USDT",
        //         "clOrdId": "",
        //         "posSide": "net",
        //         "billId": "317321390265368576",
        //         "tag": "0",
        //         "execType": "T",
        //         "tradeId": "107601752",
        //         "feeCcy": "ETH",
        //         "ts": "1621927314985"
        //     }
        //
        const id = this.safeString(trade, 'tradeId');
        const marketId = this.safeString(trade, 'instId');
        market = this.safeMarket(marketId, market, '-');
        const symbol = market['symbol'];
        const timestamp = this.safeInteger(trade, 'ts');
        const price = this.safeString2(trade, 'fillPx', 'px');
        const amount = this.safeString2(trade, 'fillSz', 'sz');
        const side = this.safeString(trade, 'side');
        const orderId = this.safeString(trade, 'ordId');
        const feeCostString = this.safeString(trade, 'fee');
        let fee = undefined;
        if (feeCostString !== undefined) {
            const feeCostSigned = Precise.stringNeg(feeCostString);
            const feeCurrencyId = this.safeString(trade, 'feeCcy');
            const feeCurrencyCode = this.safeCurrencyCode(feeCurrencyId);
            fee = {
                'cost': feeCostSigned,
                'currency': feeCurrencyCode,
            };
        }
        let takerOrMaker = this.safeString(trade, 'execType');
        if (takerOrMaker === 'T') {
            takerOrMaker = 'taker';
        }
        else if (takerOrMaker === 'M') {
            takerOrMaker = 'maker';
        }
        return this.safeTrade({
            'info': trade,
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'symbol': symbol,
            'id': id,
            'order': orderId,
            'type': undefined,
            'takerOrMaker': takerOrMaker,
            'side': side,
            'price': price,
            'amount': amount,
            'cost': undefined,
            'fee': fee,
        }, market);
    }
    async fetchTrades(symbol, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchTrades
         * @description get the list of most recent trades for a particular symbol
         * @see https://www.okx.com/docs-v5/en/#rest-api-market-data-get-trades
         * @see https://www.okx.com/docs-v5/en/#rest-api-public-data-get-option-trades
         * @param {string} symbol unified symbol of the market to fetch trades for
         * @param {int} [since] timestamp in ms of the earliest trade to fetch
         * @param {int} [limit] the maximum amount of trades to fetch
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {boolean} [params.paginate] *only applies to publicGetMarketHistoryTrades* default false, when true will automatically paginate by calling this endpoint multiple times
         * @returns {Trade[]} a list of [trade structures]{@link https://docs.ccxt.com/#/?id=public-trades}
         */
        await this.loadMarkets();
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchTrades', 'paginate');
        if (paginate) {
            return await this.fetchPaginatedCallCursor('fetchTrades', symbol, since, limit, params, 'tradeId', 'after', undefined, 100);
        }
        const market = this.market(symbol);
        const request = {
            'instId': market['id'],
        };
        let response = undefined;
        if (market['option']) {
            response = await this.publicGetPublicOptionTrades(this.extend(request, params));
        }
        else {
            if (limit !== undefined) {
                request['limit'] = limit; // default 100
            }
            let method = undefined;
            [method, params] = this.handleOptionAndParams(params, 'fetchTrades', 'method', 'publicGetMarketTrades');
            if (method === 'publicGetMarketTrades') {
                response = await this.publicGetMarketTrades(this.extend(request, params));
            }
            else if (method === 'publicGetMarketHistoryTrades') {
                response = await this.publicGetMarketHistoryTrades(this.extend(request, params));
            }
        }
        //
        //     {
        //         "code": "0",
        //         "msg": "",
        //         "data": [
        //             {"instId":"ETH-BTC","side":"sell","sz":"0.119501","px":"0.07065","tradeId":"15826757","ts":"1621446178316"},
        //             {"instId":"ETH-BTC","side":"sell","sz":"0.03","px":"0.07068","tradeId":"15826756","ts":"1621446178066"},
        //             {"instId":"ETH-BTC","side":"buy","sz":"0.507","px":"0.07069","tradeId":"15826755","ts":"1621446175085"},
        //         ]
        //     }
        //
        // option
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "fillVol": "0.46387625976562497",
        //                 "fwdPx": "26299.754935451125",
        //                 "indexPx": "26309.7",
        //                 "instFamily": "BTC-USD",
        //                 "instId": "BTC-USD-230526-26000-C",
        //                 "markPx": "0.042386283557554236",
        //                 "optType": "C",
        //                 "px": "0.0415",
        //                 "side": "sell",
        //                 "sz": "90",
        //                 "tradeId": "112",
        //                 "ts": "1683907480154"
        //             },
        //         ],
        //         "msg": ""
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        return this.parseTrades(data, market, since, limit);
    }
    parseOHLCV(ohlcv, market = undefined) {
        //
        //     [
        //         "1678928760000", // timestamp
        //         "24341.4", // open
        //         "24344", // high
        //         "24313.2", // low
        //         "24323", // close
        //         "628", // contract volume
        //         "2.5819", // base volume
        //         "62800", // quote volume
        //         "0" // candlestick state
        //     ]
        //
        const res = this.handleMarketTypeAndParams('fetchOHLCV', market, undefined);
        const type = res[0];
        const volumeIndex = (type === 'spot') ? 5 : 6;
        return [
            this.safeInteger(ohlcv, 0),
            this.safeNumber(ohlcv, 1),
            this.safeNumber(ohlcv, 2),
            this.safeNumber(ohlcv, 3),
            this.safeNumber(ohlcv, 4),
            this.safeNumber(ohlcv, volumeIndex),
        ];
    }
    async fetchOHLCV(symbol, timeframe = '1m', since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchOHLCV
         * @description fetches historical candlestick data containing the open, high, low, and close price, and the volume of a market
         * @see https://www.okx.com/docs-v5/en/#rest-api-market-data-get-candlesticks
         * @see https://www.okx.com/docs-v5/en/#rest-api-market-data-get-candlesticks-history
         * @see https://www.okx.com/docs-v5/en/#rest-api-market-data-get-mark-price-candlesticks
         * @see https://www.okx.com/docs-v5/en/#rest-api-market-data-get-mark-price-candlesticks-history
         * @see https://www.okx.com/docs-v5/en/#rest-api-market-data-get-index-candlesticks
         * @see https://www.okx.com/docs-v5/en/#rest-api-market-data-get-index-candlesticks-history
         * @param {string} symbol unified symbol of the market to fetch OHLCV data for
         * @param {string} timeframe the length of time each candle represents
         * @param {int} [since] timestamp in ms of the earliest candle to fetch
         * @param {int} [limit] the maximum amount of candles to fetch
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.price] "mark" or "index" for mark price and index price candles
         * @param {int} [params.until] timestamp in ms of the latest candle to fetch
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [availble parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @returns {int[][]} A list of candles ordered as timestamp, open, high, low, close, volume
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchOHLCV', 'paginate');
        if (paginate) {
            return await this.fetchPaginatedCallDeterministic('fetchOHLCV', symbol, since, limit, timeframe, params, 200);
        }
        const price = this.safeString(params, 'price');
        params = this.omit(params, 'price');
        const options = this.safeValue(this.options, 'fetchOHLCV', {});
        const timezone = this.safeString(options, 'timezone', 'UTC');
        if (limit === undefined) {
            limit = 100; // default 100, max 100
        }
        const duration = this.parseTimeframe(timeframe);
        let bar = this.safeString(this.timeframes, timeframe, timeframe);
        if ((timezone === 'UTC') && (duration >= 21600)) { // if utc and timeframe >= 6h
            bar += timezone.toLowerCase();
        }
        const request = {
            'instId': market['id'],
            'bar': bar,
            'limit': limit,
        };
        let defaultType = 'Candles';
        if (since !== undefined) {
            const now = this.milliseconds();
            const durationInMilliseconds = duration * 1000;
            // switch to history candles if since is past the cutoff for current candles
            const historyBorder = now - ((1440 - 1) * durationInMilliseconds);
            if (since < historyBorder) {
                defaultType = 'HistoryCandles';
            }
            const startTime = Math.max(since - 1, 0);
            request['before'] = startTime;
            request['after'] = this.sum(since, durationInMilliseconds * limit);
        }
        const until = this.safeInteger(params, 'until');
        if (until !== undefined) {
            request['after'] = until;
            params = this.omit(params, 'until');
        }
        defaultType = this.safeString(options, 'type', defaultType); // Candles or HistoryCandles
        const type = this.safeString(params, 'type', defaultType);
        params = this.omit(params, 'type');
        const isHistoryCandles = (type === 'HistoryCandles');
        let response = undefined;
        if (price === 'mark') {
            if (isHistoryCandles) {
                response = await this.publicGetMarketHistoryMarkPriceCandles(this.extend(request, params));
            }
            else {
                response = await this.publicGetMarketMarkPriceCandles(this.extend(request, params));
            }
        }
        else if (price === 'index') {
            request['instId'] = market['info']['instFamily']; // okx index candles require instFamily instead of instId
            if (isHistoryCandles) {
                response = await this.publicGetMarketHistoryIndexCandles(this.extend(request, params));
            }
            else {
                response = await this.publicGetMarketIndexCandles(this.extend(request, params));
            }
        }
        else {
            if (isHistoryCandles) {
                response = await this.publicGetMarketHistoryCandles(this.extend(request, params));
            }
            else {
                response = await this.publicGetMarketCandles(this.extend(request, params));
            }
        }
        //
        //     {
        //         "code": "0",
        //         "msg": "",
        //         "data": [
        //             ["1678928760000","24341.4","24344","24313.2","24323","628","2.5819","62800","0"],
        //             ["1678928700000","24324.1","24347.6","24321.7","24341.4","2565","10.5401","256500","1"],
        //             ["1678928640000","24300.2","24324.1","24288","24324.1","3304","13.5937","330400","1"],
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        return this.parseOHLCVs(data, market, timeframe, since, limit);
    }
    parseBalanceByType(type, response) {
        if (type === 'funding') {
            return this.parseFundingBalance(response);
        }
        else {
            return this.parseTradingBalance(response);
        }
    }
    parseTradingBalance(response) {
        const result = { 'info': response };
        const data = this.safeValue(response, 'data', []);
        const first = this.safeValue(data, 0, {});
        const timestamp = this.safeInteger(first, 'uTime');
        const details = this.safeValue(first, 'details', []);
        for (let i = 0; i < details.length; i++) {
            const balance = details[i];
            const currencyId = this.safeString(balance, 'ccy');
            const code = this.safeCurrencyCode(currencyId);
            const account = this.account();
            // it may be incorrect to use total, free and used for swap accounts
            const eq = this.safeString(balance, 'eq');
            const availEq = this.safeString(balance, 'availEq');
            if ((eq === undefined) || (availEq === undefined)) {
                account['free'] = this.safeString(balance, 'availBal');
                account['used'] = this.safeString(balance, 'frozenBal');
            }
            else {
                account['total'] = eq;
                account['free'] = availEq;
            }
            result[code] = account;
        }
        result['timestamp'] = timestamp;
        result['datetime'] = this.iso8601(timestamp);
        return this.safeBalance(result);
    }
    parseFundingBalance(response) {
        const result = { 'info': response };
        const data = this.safeValue(response, 'data', []);
        for (let i = 0; i < data.length; i++) {
            const balance = data[i];
            const currencyId = this.safeString(balance, 'ccy');
            const code = this.safeCurrencyCode(currencyId);
            const account = this.account();
            // it may be incorrect to use total, free and used for swap accounts
            account['total'] = this.safeString(balance, 'bal');
            account['free'] = this.safeString(balance, 'availBal');
            account['used'] = this.safeString(balance, 'frozenBal');
            result[code] = account;
        }
        return this.safeBalance(result);
    }
    parseTradingFee(fee, market = undefined) {
        // https://www.okx.com/docs-v5/en/#rest-api-account-get-fee-rates
        //
        //     {
        //         "category": "1",
        //         "delivery": "",
        //         "exercise": "",
        //         "instType": "SPOT",
        //         "level": "Lv1",
        //         "maker": "-0.0008",
        //         "taker": "-0.001",
        //         "ts": "1639043138472"
        //     }
        //
        return {
            'info': fee,
            'symbol': this.safeSymbol(undefined, market),
            // OKX returns the fees as negative values opposed to other exchanges, so the sign needs to be flipped
            'maker': this.parseNumber(Precise.stringNeg(this.safeString2(fee, 'maker', 'makerU'))),
            'taker': this.parseNumber(Precise.stringNeg(this.safeString2(fee, 'taker', 'takerU'))),
        };
    }
    async fetchTradingFee(symbol, params = {}) {
        /**
         * @method
         * @name okx#fetchTradingFee
         * @description fetch the trading fees for a market
         * @see https://www.okx.com/docs-v5/en/#trading-account-rest-api-get-fee-rates
         * @param {string} symbol unified market symbol
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a [fee structure]{@link https://docs.ccxt.com/#/?id=fee-structure}
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        const request = {
            'instType': this.convertToInstrumentType(market['type']), // SPOT, MARGIN, SWAP, FUTURES, OPTION
            // "instId": market["id"], // only applicable to SPOT/MARGIN
            // "uly": market["id"], // only applicable to FUTURES/SWAP/OPTION
            // "category": "1", // 1 = Class A, 2 = Class B, 3 = Class C, 4 = Class D
        };
        if (market['spot']) {
            request['instId'] = market['id'];
        }
        else if (market['swap'] || market['future'] || market['option']) {
            request['uly'] = market['baseId'] + '-' + market['quoteId'];
        }
        else {
            throw new MarketTypeNotSupportedError(market['type'])
                .addMessage('fetchTradingFee() supports spot, swap, future or option markets only')
                .exchange(this.id);
        }
        const response = await this.privateGetAccountTradeFee(this.extend(request, params));
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "category": "1",
        //                 "delivery": "",
        //                 "exercise": "",
        //                 "instType": "SPOT",
        //                 "level": "Lv1",
        //                 "maker": "-0.0008",
        //                 "taker": "-0.001",
        //                 "ts": "1639043138472"
        //             }
        //         ],
        //         "msg": ""
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        const first = this.safeValue(data, 0, {});
        return this.parseTradingFee(first, market);
    }
    async fetchBalance(params = {}) {
        /**
         * @method
         * @name okx#fetchBalance
         * @description query for balance and get the amount of funds available for trading or funds locked in orders
         * @see https://www.okx.com/docs-v5/en/#funding-account-rest-api-get-balance
         * @see https://www.okx.com/docs-v5/en/#trading-account-rest-api-get-balance
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a [balance structure]{@link https://docs.ccxt.com/#/?id=balance-structure}
         */
        await this.loadMarkets();
        const [marketType, query] = this.handleMarketTypeAndParams('fetchBalance', undefined, params);
        const request = {
        // 'ccy': 'BTC,ETH', // comma-separated list of currency ids
        };
        let response = undefined;
        if (marketType === 'funding') {
            response = await this.privateGetAssetBalances(this.extend(request, query));
        }
        else {
            response = await this.privateGetAccountBalance(this.extend(request, query));
        }
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "adjEq": "",
        //                 "details": [
        //                     {
        //                         "availBal": "",
        //                         "availEq": "28.21006347",
        //                         "cashBal": "28.21006347",
        //                         "ccy": "USDT",
        //                         "crossLiab": "",
        //                         "disEq": "28.2687404020176",
        //                         "eq":"28 .21006347",
        //                         "eqUsd": "28.2687404020176",
        //                         "frozenBal": "0",
        //                         "interest": "",
        //                         "isoEq": "0",
        //                         "isoLiab": "",
        //                         "liab": "",
        //                         "maxLoan": "",
        //                         "mgnRatio": "",
        //                         "notionalLever": "0",
        //                         "ordFrozen": "0",
        //                         "twap": "0",
        //                         "uTime": "1621556539861",
        //                         "upl": "0",
        //                         "uplLiab": ""
        //                     }
        //                 ],
        //                 "imr": "",
        //                 "isoEq": "0",
        //                 "mgnRatio": "",
        //                 "mmr": "",
        //                 "notionalUsd": "",
        //                 "ordFroz": "",
        //                 "totalEq": "28.2687404020176",
        //                 "uTime": "1621556553510"
        //             }
        //         ],
        //         "msg": ""
        //     }
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "adjEq": "",
        //                 "details": [
        //                     {
        //                         "availBal": "0.049",
        //                         "availEq": "",
        //                         "cashBal": "0.049",
        //                         "ccy": "BTC",
        //                         "crossLiab": "",
        //                         "disEq": "1918.55678",
        //                         "eq": "0.049",
        //                         "eqUsd": "1918.55678",
        //                         "frozenBal": "0",
        //                         "interest": "",
        //                         "isoEq": "",
        //                         "isoLiab": "",
        //                         "liab": "",
        //                         "maxLoan": "",
        //                         "mgnRatio": "",
        //                         "notionalLever": "",
        //                         "ordFrozen": "0",
        //                         "twap": "0",
        //                         "uTime": "1621973128591",
        //                         "upl": "",
        //                         "uplLiab": ""
        //                     }
        //                 ],
        //                 "imr": "",
        //                 "isoEq": "",
        //                 "mgnRatio": "",
        //                 "mmr": "",
        //                 "notionalUsd": "",
        //                 "ordFroz": "",
        //                 "totalEq": "1918.55678",
        //                 "uTime": "1622045126908"
        //             }
        //         ],
        //         "msg": ""
        //     }
        //
        // funding
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "availBal": "0.00005426",
        //                 "bal": 0.0000542600000000,
        //                 "ccy": "BTC",
        //                 "frozenBal": "0"
        //             }
        //         ],
        //         "msg": ""
        //     }
        //
        return this.parseBalanceByType(marketType, response);
    }
    async createMarketBuyOrderWithCost(symbol, cost, params = {}) {
        /**
         * @method
         * @name okx#createMarketBuyOrderWithCost
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-trade-post-place-order
         * @description create a market buy order by providing the symbol and cost
         * @param {string} symbol unified symbol of the market to create an order in
         * @param {float} cost how much you want to trade in units of the quote currency
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} an [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        if (!market['spot']) {
            throw new MarketTypeNotSupportedError(market['type'])
                .addMessage('createMarketBuyOrderWithCost() supports spot markets only')
                .exchange(this.id);
        }
        params['createMarketBuyOrderRequiresPrice'] = false;
        params['tgtCcy'] = 'quote_ccy';
        return await this.createOrder(symbol, 'market', 'buy', cost, undefined, params);
    }
    async createMarketSellOrderWithCost(symbol, cost, params = {}) {
        /**
         * @method
         * @name okx#createMarketSellOrderWithCost
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-trade-post-place-order
         * @description create a market buy order by providing the symbol and cost
         * @param {string} symbol unified symbol of the market to create an order in
         * @param {float} cost how much you want to trade in units of the quote currency
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} an [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        if (!market['spot']) {
            throw new MarketTypeNotSupportedError(market['type'])
                .addMessage('createMarketSellOrderWithCost() supports spot markets only')
                .exchange(this.id);
        }
        params['createMarketBuyOrderRequiresPrice'] = false;
        params['tgtCcy'] = 'quote_ccy';
        return await this.createOrder(symbol, 'market', 'sell', cost, undefined, params);
    }
    createOrderRequest(symbol, type, side, amount, price = undefined, params = {}) {
        const market = this.market(symbol);
        const request = {
            'instId': market['id'],
            // 'ccy': currency['id'], // only applicable to cross MARGIN orders in single-currency margin
            // 'clOrdId': clientOrderId, // up to 32 characters, must be unique
            // 'tag': tag, // up to 8 characters
            'side': side,
            // 'posSide': 'long', // long, short, // required in the long/short mode, and can only be long or short (only for future or swap)
            'ordType': type,
            // 'ordType': type, // privatePostTradeOrder: market, limit, post_only, fok, ioc, optimal_limit_ioc
            // 'ordType': type, // privatePostTradeOrderAlgo: conditional, oco, trigger, move_order_stop, iceberg, twap
            'sz': this.amountToPrecision(symbol, amount),
            // 'px': this.priceToPrecision (symbol, price), // limit orders only
            // 'reduceOnly': false,
            //
            // 'triggerPx': 10, // stopPrice (trigger orders)
            // 'orderPx': 10, // Order price if -1, the order will be executed at the market price. (trigger orders)
            // 'triggerPxType': 'last', // Conditional default is last, mark or index (trigger orders)
            //
            // 'tpTriggerPx': 10, // takeProfitPrice (conditional orders)
            // 'tpTriggerPxType': 'last', // Conditional default is last, mark or index (conditional orders)
            // 'tpOrdPx': 10, // Order price for Take-Profit orders, if -1 will be executed at market price (conditional orders)
            //
            // 'slTriggerPx': 10, // stopLossPrice (conditional orders)
            // 'slTriggerPxType': 'last', // Conditional default is last, mark or index (conditional orders)
            // 'slOrdPx': 10, // Order price for Stop-Loss orders, if -1 will be executed at market price (conditional orders)
        };
        const spot = market['spot'];
        const contract = market['contract'];
        const triggerPrice = this.safeValueN(params, ['triggerPrice', 'stopPrice', 'triggerPx']);
        const timeInForce = this.safeString(params, 'timeInForce', 'GTC');
        const takeProfitPrice = this.safeValue2(params, 'takeProfitPrice', 'tpTriggerPx');
        const tpOrdPx = this.safeValue(params, 'tpOrdPx', price);
        const tpTriggerPxType = this.safeString(params, 'tpTriggerPxType', 'last');
        const stopLossPrice = this.safeValue2(params, 'stopLossPrice', 'slTriggerPx');
        const slOrdPx = this.safeValue(params, 'slOrdPx', price);
        const slTriggerPxType = this.safeString(params, 'slTriggerPxType', 'last');
        const clientOrderId = this.safeString2(params, 'clOrdId', 'clientOrderId');
        const stopLoss = this.safeValue(params, 'stopLoss');
        const stopLossDefined = (stopLoss !== undefined);
        const takeProfit = this.safeValue(params, 'takeProfit');
        const takeProfitDefined = (takeProfit !== undefined);
        const trailingPercent = this.safeString2(params, 'trailingPercent', 'callbackRatio');
        const isTrailingPercentOrder = trailingPercent !== undefined;
        const defaultMarginMode = this.safeString2(this.options, 'defaultMarginMode', 'marginMode', 'cross');
        let marginMode = this.safeString2(params, 'marginMode', 'tdMode'); // cross or isolated, tdMode not ommited so as to be extended into the request
        let margin = false;
        if ((marginMode !== undefined) && (marginMode !== 'cash')) {
            margin = true;
        }
        else {
            marginMode = defaultMarginMode;
            margin = this.safeBool(params, 'margin', false);
        }
        if (spot) {
            if (margin) {
                const defaultCurrency = (side === 'buy') ? market['quote'] : market['base'];
                const currency = this.safeString(params, 'ccy', defaultCurrency);
                request['ccy'] = this.safeCurrencyCode(currency);
            }
            const tradeMode = margin ? marginMode : 'cash';
            request['tdMode'] = tradeMode;
        }
        else if (contract) {
            if (market['swap'] || market['future']) {
                let positionSide = undefined;
                [positionSide, params] = this.handleOptionAndParams(params, 'createOrder', 'positionSide');
                if (positionSide !== undefined) {
                    request['posSide'] = positionSide;
                }
            }
            request['tdMode'] = marginMode;
        }
        const isMarketOrder = type === 'market';
        let postOnly = false;
        [postOnly, params] = this.handlePostOnly(isMarketOrder, type === 'post_only', params);
        params = this.omit(params, ['currency', 'ccy', 'marginMode', 'timeInForce', 'stopPrice', 'triggerPrice', 'clientOrderId', 'stopLossPrice', 'takeProfitPrice', 'slOrdPx', 'tpOrdPx', 'margin', 'stopLoss', 'takeProfit', 'trailingPercent']);
        const ioc = (timeInForce === 'IOC') || (type === 'ioc');
        const fok = (timeInForce === 'FOK') || (type === 'fok');
        const trigger = (triggerPrice !== undefined) || (type === 'trigger');
        const conditional = (stopLossPrice !== undefined) || (takeProfitPrice !== undefined) || (type === 'conditional');
        const marketIOC = (isMarketOrder && ioc) || (type === 'optimal_limit_ioc');
        const defaultTgtCcy = this.safeString(this.options, 'tgtCcy', 'base_ccy');
        const tgtCcy = this.safeString(params, 'tgtCcy', defaultTgtCcy);
        if ((!contract) && (!margin)) {
            request['tgtCcy'] = tgtCcy;
        }
        if (isMarketOrder || marketIOC) {
            request['ordType'] = 'market';
            if (spot && (side === 'buy')) {
                // spot market buy: "sz" can refer either to base currency units or to quote currency units
                // see documentation: https://www.okx.com/docs-v5/en/#rest-api-trade-place-order
                if (tgtCcy === 'quote_ccy') {
                    // quote_ccy: sz refers to units of quote currency
                    let createMarketBuyOrderRequiresPrice = true;
                    [createMarketBuyOrderRequiresPrice, params] = this.handleOptionAndParams(params, 'createOrder', 'createMarketBuyOrderRequiresPrice', true);
                    let notional = this.safeNumber2(params, 'cost', 'sz');
                    params = this.omit(params, ['cost', 'sz']);
                    if (createMarketBuyOrderRequiresPrice) {
                        if (price !== undefined) {
                            if (notional === undefined) {
                                const amountString = this.numberToString(amount);
                                const priceString = this.numberToString(price);
                                const quoteAmount = Precise.stringMul(amountString, priceString);
                                notional = this.parseNumber(quoteAmount);
                            }
                        }
                        else if (notional === undefined) {
                            throw new InvalidParamsError("createOrder() requires the price argument with market buy orders to calculate total order cost (amount to spend), where cost = amount * price. Supply a price argument to createOrder() call if you want the cost to be calculated for you from price and amount, or, alternatively, add .options['createMarketBuyOrderRequiresPrice'] = false and supply the total cost value in the 'amount' argument or in the 'cost' unified extra parameter or in exchange-specific 'sz' extra parameter (the exchange-specific behaviour)").exchange(this.id);
                        }
                    }
                    else {
                        notional = (notional === undefined) ? amount : notional;
                    }
                    request['sz'] = this.costToPrecision(symbol, notional);
                }
            }
            if (marketIOC && contract) {
                request['ordType'] = 'optimal_limit_ioc';
            }
        }
        else {
            if ((!trigger) && (!conditional)) {
                request['px'] = this.priceToPrecision(symbol, price);
            }
        }
        if (postOnly) {
            request['ordType'] = 'post_only';
        }
        else if (ioc && !marketIOC) {
            request['ordType'] = 'ioc';
        }
        else if (fok) {
            request['ordType'] = 'fok';
        }
        if (isTrailingPercentOrder) {
            const convertedTrailingPercent = Precise.stringDiv(trailingPercent, '100');
            request['callbackRatio'] = convertedTrailingPercent;
            request['ordType'] = 'move_order_stop';
        }
        else if (stopLossDefined || takeProfitDefined) {
            if (stopLossDefined) {
                const stopLossTriggerPrice = this.safeValueN(stopLoss, ['triggerPrice', 'stopPrice', 'slTriggerPx']);
                if (stopLossTriggerPrice === undefined) {
                    throw new InvalidParamsError('createOrder() requires a trigger price in params["stopLoss"]["triggerPrice"], or params["stopLoss"]["stopPrice"], or params["stopLoss"]["slTriggerPx"] for a stop loss order').exchange(this.id);
                }
                request['slTriggerPx'] = this.priceToPrecision(symbol, stopLossTriggerPrice);
                const stopLossLimitPrice = this.safeValueN(stopLoss, ['price', 'stopLossPrice', 'slOrdPx']);
                const stopLossOrderType = this.safeString(stopLoss, 'type');
                if (stopLossOrderType !== undefined) {
                    const stopLossLimitOrderType = (stopLossOrderType === 'limit');
                    const stopLossMarketOrderType = (stopLossOrderType === 'market');
                    if ((!stopLossLimitOrderType) && (!stopLossMarketOrderType)) {
                        throw new InvalidParamsError('createOrder() params["stopLoss"]["type"] must be either "limit" or "market"').exchange(this.id);
                    }
                    else if (stopLossLimitOrderType) {
                        if (stopLossLimitPrice === undefined) {
                            throw new InvalidParamsError('createOrder() requires a limit price in params["stopLoss"]["price"] or params["stopLoss"]["slOrdPx"] for a stop loss limit order').exchange(this.id);
                        }
                        else {
                            request['slOrdPx'] = this.priceToPrecision(symbol, stopLossLimitPrice);
                        }
                    }
                    else if (stopLossOrderType === 'market') {
                        request['slOrdPx'] = '-1';
                    }
                }
                else if (stopLossLimitPrice !== undefined) {
                    request['slOrdPx'] = this.priceToPrecision(symbol, stopLossLimitPrice); // limit sl order
                }
                else {
                    request['slOrdPx'] = '-1'; // market sl order
                }
                const stopLossTriggerPriceType = this.safeString2(stopLoss, 'triggerPriceType', 'slTriggerPxType', 'last');
                if (stopLossTriggerPriceType !== undefined) {
                    if ((stopLossTriggerPriceType !== 'last') && (stopLossTriggerPriceType !== 'index') && (stopLossTriggerPriceType !== 'mark')) {
                        throw new InvalidParamsError('createOrder() stop loss trigger price type must be one of "last", "index" or "mark"').exchange(this.id);
                    }
                    request['slTriggerPxType'] = stopLossTriggerPriceType;
                }
            }
            if (takeProfitDefined) {
                const takeProfitTriggerPrice = this.safeValueN(takeProfit, ['triggerPrice', 'stopPrice', 'tpTriggerPx']);
                if (takeProfitTriggerPrice === undefined) {
                    throw new InvalidParamsError('createOrder() requires a trigger price in params["takeProfit"]["triggerPrice"], or params["takeProfit"]["stopPrice"], or params["takeProfit"]["tpTriggerPx"] for a take profit order').exchange(this.id);
                }
                request['tpTriggerPx'] = this.priceToPrecision(symbol, takeProfitTriggerPrice);
                const takeProfitLimitPrice = this.safeValueN(takeProfit, ['price', 'takeProfitPrice', 'tpOrdPx']);
                const takeProfitOrderType = this.safeString(takeProfit, 'type');
                if (takeProfitOrderType !== undefined) {
                    const takeProfitLimitOrderType = (takeProfitOrderType === 'limit');
                    const takeProfitMarketOrderType = (takeProfitOrderType === 'market');
                    if ((!takeProfitLimitOrderType) && (!takeProfitMarketOrderType)) {
                        throw new InvalidParamsError('createOrder() params["takeProfit"]["type"] must be either "limit" or "market"').exchange(this.id);
                    }
                    else if (takeProfitLimitOrderType) {
                        if (takeProfitLimitPrice === undefined) {
                            throw new InvalidParamsError('createOrder() requires a limit price in params["takeProfit"]["price"] or params["takeProfit"]["tpOrdPx"] for a take profit limit order').exchange(this.id);
                        }
                        else {
                            request['tpOrdPx'] = this.priceToPrecision(symbol, takeProfitLimitPrice);
                        }
                    }
                    else if (takeProfitOrderType === 'market') {
                        request['tpOrdPx'] = '-1';
                    }
                }
                else if (takeProfitLimitPrice !== undefined) {
                    request['tpOrdPx'] = this.priceToPrecision(symbol, takeProfitLimitPrice); // limit tp order
                }
                else {
                    request['tpOrdPx'] = '-1'; // market tp order
                }
                const takeProfitTriggerPriceType = this.safeString2(takeProfit, 'triggerPriceType', 'tpTriggerPxType', 'last');
                if (takeProfitTriggerPriceType !== undefined) {
                    if ((takeProfitTriggerPriceType !== 'last') && (takeProfitTriggerPriceType !== 'index') && (takeProfitTriggerPriceType !== 'mark')) {
                        throw new InvalidParamsError('createOrder() take profit trigger price type must be one of "last", "index" or "mark"').exchange(this.id);
                    }
                    request['tpTriggerPxType'] = takeProfitTriggerPriceType;
                }
            }
        }
        else if (trigger) {
            request['ordType'] = 'trigger';
            request['triggerPx'] = this.priceToPrecision(symbol, triggerPrice);
            request['orderPx'] = isMarketOrder ? '-1' : this.priceToPrecision(symbol, price);
        }
        else if (conditional) {
            request['ordType'] = 'conditional';
            const twoWayCondition = ((takeProfitPrice !== undefined) && (stopLossPrice !== undefined));
            // if TP and SL are sent together
            // as ordType 'conditional' only stop-loss order will be applied
            if (twoWayCondition) {
                request['ordType'] = 'oco';
            }
            if (takeProfitPrice !== undefined) {
                request['tpTriggerPx'] = this.priceToPrecision(symbol, takeProfitPrice);
                request['tpOrdPx'] = (tpOrdPx === undefined) ? '-1' : this.priceToPrecision(symbol, tpOrdPx);
                request['tpTriggerPxType'] = tpTriggerPxType;
            }
            if (stopLossPrice !== undefined) {
                request['slTriggerPx'] = this.priceToPrecision(symbol, stopLossPrice);
                request['slOrdPx'] = (slOrdPx === undefined) ? '-1' : this.priceToPrecision(symbol, slOrdPx);
                request['slTriggerPxType'] = slTriggerPxType;
            }
        }
        if (clientOrderId === undefined) {
            const brokerId = this.safeString(this.options, 'brokerId');
            if (brokerId !== undefined) {
                request['clOrdId'] = brokerId + this.uuid16();
                request['tag'] = brokerId;
            }
        }
        else {
            request['clOrdId'] = clientOrderId;
            params = this.omit(params, ['clOrdId', 'clientOrderId']);
        }
        return this.extend(request, params);
    }
    async createOrder(symbol, type, side, amount, price = undefined, params = {}) {
        /**
         * @method
         * @name okx#createOrder
         * @description create a trade order
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-trade-post-place-order
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-trade-post-place-multiple-orders
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-algo-trading-post-place-algo-order
         * @param {string} symbol unified symbol of the market to create an order in
         * @param {string} type 'market' or 'limit'
         * @param {string} side 'buy' or 'sell'
         * @param {float} amount how much of currency you want to trade in units of base currency
         * @param {float} [price] the price at which the order is to be fullfilled, in units of the quote currency, ignored in market orders
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {bool} [params.reduceOnly] a mark to reduce the position size for margin, swap and future orders
         * @param {bool} [params.postOnly] true to place a post only order
         * @param {object} [params.takeProfit] *takeProfit object in params* containing the triggerPrice at which the attached take profit order will be triggered (perpetual swap markets only)
         * @param {float} [params.takeProfit.triggerPrice] take profit trigger price
         * @param {float} [params.takeProfit.price] used for take profit limit orders, not used for take profit market price orders
         * @param {string} [params.takeProfit.type] 'market' or 'limit' used to specify the take profit price type
         * @param {object} [params.stopLoss] *stopLoss object in params* containing the triggerPrice at which the attached stop loss order will be triggered (perpetual swap markets only)
         * @param {float} [params.stopLoss.triggerPrice] stop loss trigger price
         * @param {float} [params.stopLoss.price] used for stop loss limit orders, not used for stop loss market price orders
         * @param {string} [params.stopLoss.type] 'market' or 'limit' used to specify the stop loss price type
         * @param {string} [params.positionSide] if position mode is one-way: set to 'net', if position mode is hedge-mode: set to 'long' or 'short'
         * @param {string} [params.trailingPercent] the percent to trail away from the current market price
         * @returns {object} an [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        let request = this.createOrderRequest(symbol, type, side, amount, price, params);
        let method = this.safeString(this.options, 'createOrder', 'privatePostTradeBatchOrders');
        const requestOrdType = this.safeString(request, 'ordType');
        if ((requestOrdType === 'trigger') || (requestOrdType === 'conditional') || (requestOrdType === 'move_order_stop') || (type === 'move_order_stop') || (type === 'oco') || (type === 'iceberg') || (type === 'twap')) {
            method = 'privatePostTradeOrderAlgo';
        }
        if ((method !== 'privatePostTradeOrder') && (method !== 'privatePostTradeOrderAlgo') && (method !== 'privatePostTradeBatchOrders')) {
            throw new InternalError()
                .addMessage('createOrder() this.options["createOrder"] must be either privatePostTradeBatchOrders or privatePostTradeOrder or privatePostTradeOrderAlgo')
                .exchange(this.id);
        }
        if (method === 'privatePostTradeBatchOrders') {
            // keep the request body the same
            // submit a single order in an array to the batch order endpoint
            // because it has a lower ratelimit
            request = [request];
        }
        let response = undefined;
        if (method === 'privatePostTradeOrder') {
            response = await this.privatePostTradeOrder(request);
        }
        else if (method === 'privatePostTradeOrderAlgo') {
            response = await this.privatePostTradeOrderAlgo(request);
        }
        else {
            response = await this.privatePostTradeBatchOrders(request);
        }
        const data = this.safeValue(response, 'data', []);
        const first = this.safeValue(data, 0);
        const order = this.parseOrder(first, market);
        order['type'] = type;
        order['side'] = side;
        return order;
    }
    async createOrders(orders, params = {}) {
        /**
         * @method
         * @name okx#createOrders
         * @description create a list of trade orders
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-trade-post-place-multiple-orders
         * @param {Array} orders list of orders to create, each object should contain the parameters required by createOrder, namely symbol, type, side, amount, price and params
         * @returns {object} an [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const ordersRequests = [];
        for (let i = 0; i < orders.length; i++) {
            const rawOrder = orders[i];
            const marketId = this.safeString(rawOrder, 'symbol');
            const type = this.safeString(rawOrder, 'type');
            const side = this.safeString(rawOrder, 'side');
            const amount = this.safeValue(rawOrder, 'amount');
            const price = this.safeValue(rawOrder, 'price');
            const orderParams = this.safeValue(rawOrder, 'params', {});
            const extendedParams = this.extend(orderParams, params); // the request does not accept extra params since it's a list, so we're extending each order with the common params
            const orderRequest = this.createOrderRequest(marketId, type, side, amount, price, extendedParams);
            ordersRequests.push(orderRequest);
        }
        const response = await this.privatePostTradeBatchOrders(ordersRequests);
        // {
        //     "code": "0",
        //     "data": [
        //        {
        //           "clOrdId": "e847386590ce4dBCc7f2a1b4c4509f82",
        //           "ordId": "636305438765568000",
        //           "sCode": "0",
        //           "sMsg": "Order placed",
        //           "tag": "e847386590ce4dBC"
        //        },
        //        {
        //           "clOrdId": "e847386590ce4dBC0b9993fe642d8f62",
        //           "ordId": "636305438765568001",
        //           "sCode": "0",
        //           "sMsg": "Order placed",
        //           "tag": "e847386590ce4dBC"
        //        }
        //     ],
        //     "inTime": "1697979038584486",
        //     "msg": "",
        //     "outTime": "1697979038586493"
        // }
        const data = this.safeValue(response, 'data', []);
        return this.parseOrders(data);
    }
    editOrderRequest(id, symbol, type, side, amount = undefined, price = undefined, params = {}) {
        const market = this.market(symbol);
        const request = {
            'instId': market['id'],
        };
        let isAlgoOrder = undefined;
        if ((type === 'trigger') || (type === 'conditional') || (type === 'move_order_stop') || (type === 'oco') || (type === 'iceberg') || (type === 'twap')) {
            isAlgoOrder = true;
        }
        const clientOrderId = this.safeString2(params, 'clOrdId', 'clientOrderId');
        if (clientOrderId !== undefined) {
            if (isAlgoOrder) {
                request['algoClOrdId'] = clientOrderId;
            }
            else {
                request['clOrdId'] = clientOrderId;
            }
        }
        else {
            if (isAlgoOrder) {
                request['algoId'] = id;
            }
            else {
                request['ordId'] = id;
            }
        }
        let stopLossTriggerPrice = this.safeValue2(params, 'stopLossPrice', 'newSlTriggerPx');
        let stopLossPrice = this.safeValue(params, 'newSlOrdPx');
        const stopLossTriggerPriceType = this.safeString(params, 'newSlTriggerPxType', 'last');
        let takeProfitTriggerPrice = this.safeValue2(params, 'takeProfitPrice', 'newTpTriggerPx');
        let takeProfitPrice = this.safeValue(params, 'newTpOrdPx');
        const takeProfitTriggerPriceType = this.safeString(params, 'newTpTriggerPxType', 'last');
        const stopLoss = this.safeValue(params, 'stopLoss');
        const takeProfit = this.safeValue(params, 'takeProfit');
        const stopLossDefined = (stopLoss !== undefined);
        const takeProfitDefined = (takeProfit !== undefined);
        if (isAlgoOrder) {
            if ((stopLossTriggerPrice === undefined) && (takeProfitTriggerPrice === undefined)) {
                throw new InvalidParamsError('editOrder() requires a stopLossPrice or takeProfitPrice parameter for editing an algo order').exchange(this.id);
            }
            if (stopLossTriggerPrice !== undefined) {
                if (stopLossPrice === undefined) {
                    throw new InvalidParamsError('editOrder() requires a newSlOrdPx parameter for editing an algo order').exchange(this.id);
                }
                request['newSlTriggerPx'] = this.priceToPrecision(symbol, stopLossTriggerPrice);
                request['newSlOrdPx'] = (type === 'market') ? '-1' : this.priceToPrecision(symbol, stopLossPrice);
                request['newSlTriggerPxType'] = stopLossTriggerPriceType;
            }
            if (takeProfitTriggerPrice !== undefined) {
                if (takeProfitPrice === undefined) {
                    throw new InvalidParamsError('editOrder() requires a newTpOrdPx parameter for editing an algo order').exchange(this.id);
                }
                request['newTpTriggerPx'] = this.priceToPrecision(symbol, takeProfitTriggerPrice);
                request['newTpOrdPx'] = (type === 'market') ? '-1' : this.priceToPrecision(symbol, takeProfitPrice);
                request['newTpTriggerPxType'] = takeProfitTriggerPriceType;
            }
        }
        else {
            if (stopLossTriggerPrice !== undefined) {
                request['newSlTriggerPx'] = this.priceToPrecision(symbol, stopLossTriggerPrice);
                request['newSlOrdPx'] = (type === 'market') ? '-1' : this.priceToPrecision(symbol, stopLossPrice);
                request['newSlTriggerPxType'] = stopLossTriggerPriceType;
            }
            if (takeProfitTriggerPrice !== undefined) {
                request['newTpTriggerPx'] = this.priceToPrecision(symbol, takeProfitTriggerPrice);
                request['newTpOrdPx'] = (type === 'market') ? '-1' : this.priceToPrecision(symbol, takeProfitPrice);
                request['newTpTriggerPxType'] = takeProfitTriggerPriceType;
            }
            if (stopLossDefined) {
                stopLossTriggerPrice = this.safeValue(stopLoss, 'triggerPrice');
                stopLossPrice = this.safeValue(stopLoss, 'price');
                const stopLossType = this.safeString(stopLoss, 'type');
                request['newSlTriggerPx'] = this.priceToPrecision(symbol, stopLossTriggerPrice);
                request['newSlOrdPx'] = (stopLossType === 'market') ? '-1' : this.priceToPrecision(symbol, stopLossPrice);
                request['newSlTriggerPxType'] = stopLossTriggerPriceType;
            }
            if (takeProfitDefined) {
                takeProfitTriggerPrice = this.safeValue(takeProfit, 'triggerPrice');
                takeProfitPrice = this.safeValue(takeProfit, 'price');
                const takeProfitType = this.safeString(takeProfit, 'type');
                request['newTpTriggerPx'] = this.priceToPrecision(symbol, takeProfitTriggerPrice);
                request['newTpOrdPx'] = (takeProfitType === 'market') ? '-1' : this.priceToPrecision(symbol, takeProfitPrice);
                request['newTpTriggerPxType'] = takeProfitTriggerPriceType;
            }
        }
        if (amount !== undefined) {
            request['newSz'] = this.amountToPrecision(symbol, amount);
        }
        if (!isAlgoOrder) {
            if (price !== undefined) {
                request['newPx'] = this.priceToPrecision(symbol, price);
            }
        }
        params = this.omit(params, ['clOrdId', 'clientOrderId', 'takeProfitPrice', 'stopLossPrice', 'stopLoss', 'takeProfit']);
        return this.extend(request, params);
    }
    async editOrder(id, symbol, type, side, amount = undefined, price = undefined, params = {}) {
        /**
         * @method
         * @name okx#editOrder
         * @description edit a trade order
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-trade-post-amend-order
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-algo-trading-post-amend-algo-order
         * @param {string} id order id
         * @param {string} symbol unified symbol of the market to create an order in
         * @param {string} type 'market' or 'limit'
         * @param {string} side 'buy' or 'sell'
         * @param {float} amount how much of the currency you want to trade in units of the base currency
         * @param {float} [price] the price at which the order is to be fullfilled, in units of the quote currency, ignored in market orders
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.clientOrderId] client order id, uses id if not passed
         * @param {float} [params.stopLossPrice] stop loss trigger price
         * @param {float} [params.newSlOrdPx] the stop loss order price, set to stopLossPrice if the type is market
         * @param {string} [params.newSlTriggerPxType] 'last', 'index' or 'mark' used to specify the stop loss trigger price type, default is 'last'
         * @param {float} [params.takeProfitPrice] take profit trigger price
         * @param {float} [params.newTpOrdPx] the take profit order price, set to takeProfitPrice if the type is market
         * @param {string} [params.newTpTriggerPxType] 'last', 'index' or 'mark' used to specify the take profit trigger price type, default is 'last'
         * @param {object} [params.stopLoss] *stopLoss object in params* containing the triggerPrice at which the attached stop loss order will be triggered
         * @param {float} [params.stopLoss.triggerPrice] stop loss trigger price
         * @param {float} [params.stopLoss.price] used for stop loss limit orders, not used for stop loss market price orders
         * @param {string} [params.stopLoss.type] 'market' or 'limit' used to specify the stop loss price type
         * @param {object} [params.takeProfit] *takeProfit object in params* containing the triggerPrice at which the attached take profit order will be triggered
         * @param {float} [params.takeProfit.triggerPrice] take profit trigger price
         * @param {float} [params.takeProfit.price] used for take profit limit orders, not used for take profit market price orders
         * @param {string} [params.takeProfit.type] 'market' or 'limit' used to specify the take profit price type
         * @returns {object} an [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        const request = this.editOrderRequest(id, symbol, type, side, amount, price, params);
        let isAlgoOrder = undefined;
        if ((type === 'trigger') || (type === 'conditional') || (type === 'move_order_stop') || (type === 'oco') || (type === 'iceberg') || (type === 'twap')) {
            isAlgoOrder = true;
        }
        let response = undefined;
        if (isAlgoOrder) {
            response = await this.privatePostTradeAmendAlgos(this.extend(request, params));
        }
        else {
            response = await this.privatePostTradeAmendOrder(this.extend(request, params));
        }
        //
        //     {
        //        "code": "0",
        //        "data": [
        //            {
        //                 "clOrdId": "e847386590ce4dBCc1a045253497a547",
        //                 "ordId": "559176536793178112",
        //                 "reqId": "",
        //                 "sCode": "0",
        //                 "sMsg": ""
        //            }
        //        ],
        //        "msg": ""
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        const first = this.safeValue(data, 0);
        const order = this.parseOrder(first, market);
        order['type'] = type;
        order['side'] = side;
        return order;
    }
    async cancelOrder(id, symbol = undefined, params = {}) {
        /**
         * @method
         * @name okx#cancelOrder
         * @description cancels an open order
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-trade-post-cancel-order
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-algo-trading-post-cancel-algo-order
         * @param {string} id order id
         * @param {string} symbol unified symbol of the market the order was made in
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {boolean} [params.trigger] true if trigger orders
         * @param {boolean} [params.trailing] set to true if you want to cancel a trailing order
         * @returns {object} An [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        if (symbol === undefined) {
            throw new InvalidParamsError('cancelOrder() requires a symbol argument').exchange(this.id);
        }
        const stop = this.safeValue2(params, 'stop', 'trigger');
        const trailing = this.safeBool(params, 'trailing', false);
        if (stop || trailing) {
            const orderInner = await this.cancelOrders([id], symbol, params);
            return this.safeValue(orderInner, 0);
        }
        await this.loadMarkets();
        const market = this.market(symbol);
        const request = {
            'instId': market['id'],
            // 'ordId': id, // either ordId or clOrdId is required
            // 'clOrdId': clientOrderId,
        };
        const clientOrderId = this.safeString2(params, 'clOrdId', 'clientOrderId');
        if (clientOrderId !== undefined) {
            request['clOrdId'] = clientOrderId;
        }
        else {
            request['ordId'] = id;
        }
        const query = this.omit(params, ['clOrdId', 'clientOrderId']);
        const response = await this.privatePostTradeCancelOrder(this.extend(request, query));
        // {"code":"0","data":[{"clOrdId":"","ordId":"317251910906576896","sCode":"0","sMsg":""}],"msg":""}
        const data = this.safeValue(response, 'data', []);
        const order = this.safeValue(data, 0);
        return this.parseOrder(order, market);
    }
    parseIds(ids) {
        /**
         * @ignore
         * @method
         * @name okx#parseIds
         * @param {string[]|string} ids order ids
         * @returns {string[]} list of order ids
         */
        if ((ids !== undefined) && typeof ids === 'string') {
            return ids.split(',');
        }
        else {
            return ids;
        }
    }
    async cancelOrders(ids, symbol = undefined, params = {}) {
        /**
         * @method
         * @name okx#cancelOrders
         * @description cancel multiple orders
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-trade-post-cancel-multiple-orders
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-algo-trading-post-cancel-algo-order
         * @param {string[]} ids order ids
         * @param {string} symbol unified market symbol
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {boolean} [params.trigger] whether the order is a stop/trigger order
         * @param {boolean} [params.trailing] set to true if you want to cancel trailing orders
         * @returns {object} an list of [order structures]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        // TODO : the original endpoint signature differs, according to that you can skip individual symbol and assign ids in batch. At this moment, `params` is not being used too.
        if (symbol === undefined) {
            throw new InvalidParamsError('cancelOrders() requires a symbol argument').exchange(this.id);
        }
        await this.loadMarkets();
        const market = this.market(symbol);
        const request = [];
        const options = this.safeValue(this.options, 'cancelOrders', {});
        const defaultMethod = this.safeString(options, 'method', 'privatePostTradeCancelBatchOrders');
        let method = this.safeString(params, 'method', defaultMethod);
        const clientOrderIds = this.parseIds(this.safeValue2(params, 'clOrdId', 'clientOrderId'));
        const algoIds = this.parseIds(this.safeValue(params, 'algoId'));
        const stop = this.safeValue2(params, 'stop', 'trigger');
        const trailing = this.safeBool(params, 'trailing', false);
        if (stop || trailing) {
            method = 'privatePostTradeCancelAlgos';
        }
        if (clientOrderIds === undefined) {
            ids = this.parseIds(ids);
            if (algoIds !== undefined) {
                for (let i = 0; i < algoIds.length; i++) {
                    request.push({
                        'algoId': algoIds[i],
                        'instId': market['id'],
                    });
                }
            }
            for (let i = 0; i < ids.length; i++) {
                if (trailing || stop) {
                    request.push({
                        'algoId': ids[i],
                        'instId': market['id'],
                    });
                }
                else {
                    request.push({
                        'ordId': ids[i],
                        'instId': market['id'],
                    });
                }
            }
        }
        else {
            for (let i = 0; i < clientOrderIds.length; i++) {
                request.push({
                    'instId': market['id'],
                    'clOrdId': clientOrderIds[i],
                });
            }
        }
        let response = undefined;
        if (method === 'privatePostTradeCancelAlgos') {
            response = await this.privatePostTradeCancelAlgos(request); // * dont extend with params, otherwise ARRAY will be turned into OBJECT
        }
        else {
            response = await this.privatePostTradeCancelBatchOrders(request); // * dont extend with params, otherwise ARRAY will be turned into OBJECT
        }
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "clOrdId": "e123456789ec4dBC1123456ba123b45e",
        //                 "ordId": "405071912345641543",
        //                 "sCode": "0",
        //                 "sMsg": ""
        //             },
        //             ...
        //         ],
        //         "msg": ""
        //     }
        //
        // Algo order
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "algoId": "431375349042380800",
        //                 "sCode": "0",
        //                 "sMsg": ""
        //             }
        //         ],
        //         "msg": ""
        //     }
        //
        const ordersData = this.safeValue(response, 'data', []);
        return this.parseOrders(ordersData, market, undefined, undefined, params);
    }
    parseOrderStatus(status) {
        const statuses = {
            'canceled': 'canceled',
            'order_failed': 'canceled',
            'live': 'open',
            'partially_filled': 'open',
            'filled': 'closed',
            'effective': 'closed',
        };
        return this.safeString(statuses, status, status);
    }
    parseOrder(order, market = undefined) {
        //
        // createOrder
        //
        //     {
        //         "clOrdId": "oktswap6",
        //         "ordId": "312269865356374016",
        //         "tag": "",
        //         "sCode": "0",
        //         "sMsg": ""
        //     }
        //
        // editOrder
        //
        //     {
        //         "clOrdId": "e847386590ce4dBCc1a045253497a547",
        //         "ordId": "559176536793178112",
        //         "reqId": "",
        //         "sCode": "0",
        //         "sMsg": ""
        //     }
        //
        // Spot and Swap fetchOrder, fetchOpenOrders
        //
        //     {
        //         "accFillSz": "0",
        //         "avgPx": "",
        //         "cTime": "1621910749815",
        //         "category": "normal",
        //         "ccy": "",
        //         "clOrdId": "",
        //         "fee": "0",
        //         "feeCcy": "ETH",
        //         "fillPx": "",
        //         "fillSz": "0",
        //         "fillTime": "",
        //         "instId": "ETH-USDT",
        //         "instType": "SPOT",
        //         "lever": "",
        //         "ordId": "317251910906576896",
        //         "ordType": "limit",
        //         "pnl": "0",
        //         "posSide": "net",
        //         "px": "2000",
        //         "rebate": "0",
        //         "rebateCcy": "USDT",
        //         "side": "buy",
        //         "slOrdPx": "",
        //         "slTriggerPx": "",
        //         "state": "live",
        //         "sz": "0.001",
        //         "tag": "",
        //         "tdMode": "cash",
        //         "tpOrdPx": "",
        //         "tpTriggerPx": "",
        //         "tradeId": "",
        //         "uTime": "1621910749815"
        //     }
        //
        // Algo Order fetchOpenOrders, fetchCanceledOrders, fetchClosedOrders
        //
        //     {
        //         "activePx": "",
        //         "activePxType": "",
        //         "actualPx": "",
        //         "actualSide": "buy",
        //         "actualSz": "0",
        //         "algoId": "431375349042380800",
        //         "cTime": "1649119897778",
        //         "callbackRatio": "",
        //         "callbackSpread": "",
        //         "ccy": "",
        //         "ctVal": "0.01",
        //         "instId": "BTC-USDT-SWAP",
        //         "instType": "SWAP",
        //         "last": "46538.9",
        //         "lever": "125",
        //         "moveTriggerPx": "",
        //         "notionalUsd": "467.059",
        //         "ordId": "",
        //         "ordPx": "50000",
        //         "ordType": "trigger",
        //         "posSide": "long",
        //         "pxLimit": "",
        //         "pxSpread": "",
        //         "pxVar": "",
        //         "side": "buy",
        //         "slOrdPx": "",
        //         "slTriggerPx": "",
        //         "slTriggerPxType": "",
        //         "state": "live",
        //         "sz": "1",
        //         "szLimit": "",
        //         "tag": "",
        //         "tdMode": "isolated",
        //         "tgtCcy": "",
        //         "timeInterval": "",
        //         "tpOrdPx": "",
        //         "tpTriggerPx": "",
        //         "tpTriggerPxType": "",
        //         "triggerPx": "50000",
        //         "triggerPxType": "last",
        //         "triggerTime": "",
        //         "uly": "BTC-USDT"
        //     }
        //
        const scode = this.safeString(order, 'sCode');
        if ((scode !== undefined) && (scode !== '0')) {
            return this.safeOrder({
                'id': this.safeString(order, 'ordId'),
                'clientOrderId': this.safeString(order, 'clOrdId'),
                'status': 'rejected',
                'info': order,
            });
        }
        const id = this.safeString2(order, 'algoId', 'ordId');
        const timestamp = this.safeInteger(order, 'cTime');
        const lastUpdateTimestamp = this.safeInteger(order, 'uTime');
        const lastTradeTimestamp = this.safeInteger(order, 'fillTime');
        const side = this.safeString(order, 'side');
        let type = this.safeString(order, 'ordType');
        let postOnly = undefined;
        let timeInForce = undefined;
        if (type === 'post_only') {
            postOnly = true;
            type = 'limit';
        }
        else if (type === 'fok') {
            timeInForce = 'FOK';
            type = 'limit';
        }
        else if (type === 'ioc') {
            timeInForce = 'IOC';
            type = 'limit';
        }
        const marketId = this.safeString(order, 'instId');
        market = this.safeMarket(marketId, market);
        const symbol = this.safeSymbol(marketId, market, '-');
        const filled = this.safeString(order, 'accFillSz');
        const price = this.safeString2(order, 'px', 'ordPx');
        const average = this.safeString(order, 'avgPx');
        const status = this.parseOrderStatus(this.safeString(order, 'state'));
        const feeCostString = this.safeString(order, 'fee');
        let amount = undefined;
        let cost = undefined;
        // spot market buy: "sz" can refer either to base currency units or to quote currency units
        // see documentation: https://www.okx.com/docs-v5/en/#rest-api-trade-place-order
        const defaultTgtCcy = this.safeString(this.options, 'tgtCcy', 'base_ccy');
        const tgtCcy = this.safeString(order, 'tgtCcy', defaultTgtCcy);
        const instType = this.safeString(order, 'instType');
        if ((side === 'buy') && (type === 'market') && (instType === 'SPOT') && (tgtCcy === 'quote_ccy')) {
            // "sz" refers to the cost
            cost = this.safeString(order, 'sz');
        }
        else {
            // "sz" refers to the trade currency amount
            amount = this.safeString(order, 'sz');
        }
        let fee = undefined;
        if (feeCostString !== undefined) {
            const feeCostSigned = Precise.stringNeg(feeCostString);
            const feeCurrencyId = this.safeString(order, 'feeCcy');
            const feeCurrencyCode = this.safeCurrencyCode(feeCurrencyId);
            fee = {
                'cost': this.parseNumber(feeCostSigned),
                'currency': feeCurrencyCode,
            };
        }
        let clientOrderId = this.safeString(order, 'clOrdId');
        if ((clientOrderId !== undefined) && (clientOrderId.length < 1)) {
            clientOrderId = undefined; // fix empty clientOrderId string
        }
        const stopLossPrice = this.safeNumber2(order, 'slTriggerPx', 'slOrdPx');
        const takeProfitPrice = this.safeNumber2(order, 'tpTriggerPx', 'tpOrdPx');
        const stopPrice = this.safeNumberN(order, ['triggerPx', 'moveTriggerPx']);
        const reduceOnlyRaw = this.safeString(order, 'reduceOnly');
        let reduceOnly = false;
        if (reduceOnly !== undefined) {
            reduceOnly = (reduceOnlyRaw === 'true');
        }
        return this.safeOrder({
            'info': order,
            'id': id,
            'clientOrderId': clientOrderId,
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'lastTradeTimestamp': lastTradeTimestamp,
            'lastUpdateTimestamp': lastUpdateTimestamp,
            'symbol': symbol,
            'type': type,
            'timeInForce': timeInForce,
            'postOnly': postOnly,
            'side': side,
            'price': price,
            'stopLossPrice': stopLossPrice,
            'takeProfitPrice': takeProfitPrice,
            'stopPrice': stopPrice,
            'triggerPrice': stopPrice,
            'average': average,
            'cost': cost,
            'amount': amount,
            'filled': filled,
            'remaining': undefined,
            'status': status,
            'fee': fee,
            'trades': undefined,
            'reduceOnly': reduceOnly,
        }, market);
    }
    async fetchOrder(id, symbol = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchOrder
         * @description fetch an order by the id
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-trade-get-order-details
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-algo-trading-get-algo-order-details
         * @param {string} id the order id
         * @param {string} symbol unified market symbol
         * @param {object} [params] extra and exchange specific parameters
         * @param {boolean} [params.trigger] true if fetching trigger orders
         * @returns [an order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        if (symbol === undefined) {
            throw new InvalidParamsError('fetchOrder() requires a symbol argument').exchange(this.id);
        }
        await this.loadMarkets();
        const market = this.market(symbol);
        const request = {
            'instId': market['id'],
            // 'clOrdId': 'abcdef12345', // optional, [a-z0-9]{1,32}
            // 'ordId': id,
            // 'instType': // spot, swap, futures, margin
        };
        const clientOrderId = this.safeString2(params, 'clOrdId', 'clientOrderId');
        const options = this.safeValue(this.options, 'fetchOrder', {});
        const defaultMethod = this.safeString(options, 'method', 'privateGetTradeOrder');
        let method = this.safeString(params, 'method', defaultMethod);
        const stop = this.safeValue2(params, 'stop', 'trigger');
        if (stop) {
            method = 'privateGetTradeOrderAlgo';
            if (clientOrderId !== undefined) {
                request['algoClOrdId'] = clientOrderId;
            }
            else {
                request['algoId'] = id;
            }
        }
        else {
            if (clientOrderId !== undefined) {
                request['clOrdId'] = clientOrderId;
            }
            else {
                request['ordId'] = id;
            }
        }
        const query = this.omit(params, ['method', 'clOrdId', 'clientOrderId', 'stop', 'trigger']);
        let response = undefined;
        if (method === 'privateGetTradeOrderAlgo') {
            response = await this.privateGetTradeOrderAlgo(this.extend(request, query));
        }
        else {
            response = await this.privateGetTradeOrder(this.extend(request, query));
        }
        //
        // Spot and Swap
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "accFillSz": "0",
        //                 "avgPx": "",
        //                 "cTime": "1621910749815",
        //                 "category": "normal",
        //                 "ccy": "",
        //                 "clOrdId": "",
        //                 "fee": "0",
        //                 "feeCcy": "ETH",
        //                 "fillPx": "",
        //                 "fillSz": "0",
        //                 "fillTime": "",
        //                 "instId": "ETH-USDT",
        //                 "instType": "SPOT",
        //                 "lever": "",
        //                 "ordId": "317251910906576896",
        //                 "ordType": "limit",
        //                 "pnl": "0",
        //                 "posSide": "net",
        //                 "px":"20 00",
        //                 "rebate": "0",
        //                 "rebateCcy": "USDT",
        //                 "side": "buy",
        //                 "slOrdPx": "",
        //                 "slTriggerPx": "",
        //                 "state": "live",
        //                 "sz":"0. 001",
        //                 "tag": "",
        //                 "tdMode": "cash",
        //                 "tpOrdPx": "",
        //                 "tpTriggerPx": "",
        //                 "tradeId": "",
        //                 "uTime": "1621910749815"
        //             }
        //         ],
        //         "msg": ""
        //     }
        //
        // Algo order
        //     {
        //         "code":"0",
        //         "msg":"",
        //         "data":[
        //             {
        //                 "instType":"FUTURES",
        //                 "instId":"BTC-USD-200329",
        //                 "ordId":"123445",
        //                 "ccy":"BTC",
        //                 "clOrdId":"",
        //                 "algoId":"1234",
        //                 "sz":"999",
        //                 "closeFraction":"",
        //                 "ordType":"oco",
        //                 "side":"buy",
        //                 "posSide":"long",
        //                 "tdMode":"cross",
        //                 "tgtCcy": "",
        //                 "state":"effective",
        //                 "lever":"20",
        //                 "tpTriggerPx":"",
        //                 "tpTriggerPxType":"",
        //                 "tpOrdPx":"",
        //                 "slTriggerPx":"",
        //                 "slTriggerPxType":"",
        //                 "triggerPx":"99",
        //                 "triggerPxType":"last",
        //                 "ordPx":"12",
        //                 "actualSz":"",
        //                 "actualPx":"",
        //                 "actualSide":"",
        //                 "pxVar":"",
        //                 "pxSpread":"",
        //                 "pxLimit":"",
        //                 "szLimit":"",
        //                 "tag": "adadadadad",
        //                 "timeInterval":"",
        //                 "callbackRatio":"",
        //                 "callbackSpread":"",
        //                 "activePx":"",
        //                 "moveTriggerPx":"",
        //                 "reduceOnly": "false",
        //                 "triggerTime":"1597026383085",
        //                 "last": "16012",
        //                 "failCode": "",
        //                 "algoClOrdId": "",
        //                 "cTime":"1597026383000"
        //             }
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        const order = this.safeValue(data, 0);
        return this.parseOrder(order, market);
    }
    async fetchOpenOrders(symbol = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchOpenOrders
         * @description fetch all unfilled currently open orders
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-trade-get-order-list
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-algo-trading-get-algo-order-list
         * @param {string} symbol unified market symbol
         * @param {int} [since] the earliest time in ms to fetch open orders for
         * @param {int} [limit] the maximum number of  open orders structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {bool} [params.stop] True if fetching trigger or conditional orders
         * @param {string} [params.ordType] "conditional", "oco", "trigger", "move_order_stop", "iceberg", or "twap"
         * @param {string} [params.algoId] Algo ID "'433845797218942976'"
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [availble parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @param {boolean} [params.trailing] set to true if you want to fetch trailing orders
         * @returns {Order[]} a list of [order structures]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchOpenOrders', 'paginate');
        if (paginate) {
            return await this.fetchPaginatedCallDynamic('fetchOpenOrders', symbol, since, limit, params);
        }
        const request = {
        // 'instType': 'SPOT', // SPOT, MARGIN, SWAP, FUTURES, OPTION
        // 'uly': currency['id'],
        // 'instId': market['id'],
        // 'ordType': 'limit', // market, limit, post_only, fok, ioc, comma-separated, stop orders: conditional, oco, trigger, move_order_stop, iceberg, or twap
        // 'state': 'live', // live, partially_filled
        // 'after': orderId,
        // 'before': orderId,
        // 'limit': limit, // default 100, max 100
        };
        let market = undefined;
        if (symbol !== undefined) {
            market = this.market(symbol);
            request['instId'] = market['id'];
        }
        if (limit !== undefined) {
            request['limit'] = limit; // default 100, max 100
        }
        const options = this.safeValue(this.options, 'fetchOpenOrders', {});
        const algoOrderTypes = this.safeValue(this.options, 'algoOrderTypes', {});
        const defaultMethod = this.safeString(options, 'method', 'privateGetTradeOrdersPending');
        let method = this.safeString(params, 'method', defaultMethod);
        const ordType = this.safeString(params, 'ordType');
        const stop = this.safeValue2(params, 'stop', 'trigger');
        const trailing = this.safeBool(params, 'trailing', false);
        if (trailing || stop || (ordType in algoOrderTypes)) {
            method = 'privateGetTradeOrdersAlgoPending';
        }
        if (trailing) {
            request['ordType'] = 'move_order_stop';
        }
        else if (stop && (ordType === undefined)) {
            request['ordType'] = 'trigger';
        }
        const query = this.omit(params, ['method', 'stop', 'trigger', 'trailing']);
        let response = undefined;
        if (method === 'privateGetTradeOrdersAlgoPending') {
            response = await this.privateGetTradeOrdersAlgoPending(this.extend(request, query));
        }
        else {
            response = await this.privateGetTradeOrdersPending(this.extend(request, query));
        }
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "accFillSz": "0",
        //                 "avgPx": "",
        //                 "cTime": "1621910749815",
        //                 "category": "normal",
        //                 "ccy": "",
        //                 "clOrdId": "",
        //                 "fee": "0",
        //                 "feeCcy": "ETH",
        //                 "fillPx": "",
        //                 "fillSz": "0",
        //                 "fillTime": "",
        //                 "instId": "ETH-USDT",
        //                 "instType": "SPOT",
        //                 "lever": "",
        //                 "ordId": "317251910906576896",
        //                 "ordType": "limit",
        //                 "pnl": "0",
        //                 "posSide": "net",
        //                 "px":"20 00",
        //                 "rebate": "0",
        //                 "rebateCcy": "USDT",
        //                 "side": "buy",
        //                 "slOrdPx": "",
        //                 "slTriggerPx": "",
        //                 "state": "live",
        //                 "sz":"0. 001",
        //                 "tag": "",
        //                 "tdMode": "cash",
        //                 "tpOrdPx": "",
        //                 "tpTriggerPx": "",
        //                 "tradeId": "",
        //                 "uTime": "1621910749815"
        //             }
        //         ],
        //         "msg":""
        //     }
        //
        // Algo order
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "activePx": "",
        //                 "activePxType": "",
        //                 "actualPx": "",
        //                 "actualSide": "buy",
        //                 "actualSz": "0",
        //                 "algoId": "431375349042380800",
        //                 "cTime": "1649119897778",
        //                 "callbackRatio": "",
        //                 "callbackSpread": "",
        //                 "ccy": "",
        //                 "ctVal": "0.01",
        //                 "instId": "BTC-USDT-SWAP",
        //                 "instType": "SWAP",
        //                 "last": "46538.9",
        //                 "lever": "125",
        //                 "moveTriggerPx": "",
        //                 "notionalUsd": "467.059",
        //                 "ordId": "",
        //                 "ordPx": "50000",
        //                 "ordType": "trigger",
        //                 "posSide": "long",
        //                 "pxLimit": "",
        //                 "pxSpread": "",
        //                 "pxVar": "",
        //                 "side": "buy",
        //                 "slOrdPx": "",
        //                 "slTriggerPx": "",
        //                 "slTriggerPxType": "",
        //                 "state": "live",
        //                 "sz": "1",
        //                 "szLimit": "",
        //                 "tag": "",
        //                 "tdMode": "isolated",
        //                 "tgtCcy": "",
        //                 "timeInterval": "",
        //                 "tpOrdPx": "",
        //                 "tpTriggerPx": "",
        //                 "tpTriggerPxType": "",
        //                 "triggerPx": "50000",
        //                 "triggerPxType": "last",
        //                 "triggerTime": "",
        //                 "uly": "BTC-USDT"
        //             }
        //         ],
        //         "msg": ""
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        return this.parseOrders(data, market, since, limit);
    }
    async fetchCanceledOrders(symbol = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchCanceledOrders
         * @description fetches information on multiple canceled orders made by the user
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-trade-get-order-history-last-7-days
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-algo-trading-get-algo-order-history
         * @param {string} symbol unified market symbol of the market orders were made in
         * @param {int} [since] timestamp in ms of the earliest order, default is undefined
         * @param {int} [limit] max number of orders to return, default is undefined
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {bool} [params.stop] True if fetching trigger or conditional orders
         * @param {string} [params.ordType] "conditional", "oco", "trigger", "move_order_stop", "iceberg", or "twap"
         * @param {string} [params.algoId] Algo ID "'433845797218942976'"
         * @param {int} [params.until] timestamp in ms to fetch orders for
         * @param {boolean} [params.trailing] set to true if you want to fetch trailing orders
         * @returns {object} a list of [order structures]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const request = {
        // 'instType': type.toUpperCase (), // SPOT, MARGIN, SWAP, FUTURES, OPTION
        // 'uly': currency['id'],
        // 'instId': market['id'],
        // 'ordType': 'limit', // market, limit, post_only, fok, ioc, comma-separated stop orders: conditional, oco, trigger, move_order_stop, iceberg, or twap
        // 'state': 'canceled', // filled, canceled
        // 'after': orderId,
        // 'before': orderId,
        // 'limit': limit, // default 100, max 100
        // 'algoId': "'433845797218942976'", // Algo order
        };
        let market = undefined;
        if (symbol !== undefined) {
            market = this.market(symbol);
            request['instId'] = market['id'];
        }
        let type = undefined;
        let query = undefined;
        [type, query] = this.handleMarketTypeAndParams('fetchCanceledOrders', market, params);
        request['instType'] = this.convertToInstrumentType(type);
        if (limit !== undefined) {
            request['limit'] = limit; // default 100, max 100
        }
        request['state'] = 'canceled';
        const options = this.safeValue(this.options, 'fetchCanceledOrders', {});
        const algoOrderTypes = this.safeValue(this.options, 'algoOrderTypes', {});
        const defaultMethod = this.safeString(options, 'method', 'privateGetTradeOrdersHistory');
        let method = this.safeString(params, 'method', defaultMethod);
        const ordType = this.safeString(params, 'ordType');
        const stop = this.safeValue2(params, 'stop', 'trigger');
        const trailing = this.safeBool(params, 'trailing', false);
        if (trailing) {
            method = 'privateGetTradeOrdersAlgoHistory';
            request['ordType'] = 'move_order_stop';
        }
        else if (stop || (ordType in algoOrderTypes)) {
            method = 'privateGetTradeOrdersAlgoHistory';
            const algoId = this.safeString(params, 'algoId');
            if (algoId !== undefined) {
                request['algoId'] = algoId;
                params = this.omit(params, 'algoId');
            }
            if (stop) {
                if (ordType === undefined) {
                    throw new InvalidParamsError('fetchCanceledOrders() requires an "ordType" string parameter, "conditional", "oco", "trigger", "move_order_stop", "iceberg", or "twap"').exchange(this.id);
                }
            }
        }
        else {
            if (since !== undefined) {
                request['begin'] = since;
            }
            const until = this.safeInteger2(query, 'till', 'until');
            if (until !== undefined) {
                request['end'] = until;
                query = this.omit(query, ['until', 'till']);
            }
        }
        const send = this.omit(query, ['method', 'stop', 'trigger', 'trailing']);
        let response = undefined;
        if (method === 'privateGetTradeOrdersAlgoHistory') {
            response = await this.privateGetTradeOrdersAlgoHistory(this.extend(request, send));
        }
        else {
            response = await this.privateGetTradeOrdersHistory(this.extend(request, send));
        }
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "accFillSz": "0",
        //                 "avgPx": "",
        //                 "cTime": "1644037822494",
        //                 "category": "normal",
        //                 "ccy": "",
        //                 "clOrdId": "",
        //                 "fee": "0",
        //                 "feeCcy": "BTC",
        //                 "fillPx": "",
        //                 "fillSz": "0",
        //                 "fillTime": "",
        //                 "instId": "BTC-USDT",
        //                 "instType": "SPOT",
        //                 "lever": "",
        //                 "ordId": "410059580352409602",
        //                 "ordType": "limit",
        //                 "pnl": "0",
        //                 "posSide": "net",
        //                 "px": "30000",
        //                 "rebate": "0",
        //                 "rebateCcy": "USDT",
        //                 "side": "buy",
        //                 "slOrdPx": "",
        //                 "slTriggerPx": "",
        //                 "slTriggerPxType": "",
        //                 "source": "",
        //                 "state": "canceled",
        //                 "sz": "0.0005452",
        //                 "tag": "",
        //                 "tdMode": "cash",
        //                 "tgtCcy": "",
        //                 "tpOrdPx": "",
        //                 "tpTriggerPx": "",
        //                 "tpTriggerPxType": "",
        //                 "tradeId": "",
        //                 "uTime": "1644038165667"
        //             }
        //         ],
        //         "msg": ""
        //     }
        //
        // Algo order
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "activePx": "",
        //                 "activePxType": "",
        //                 "actualPx": "",
        //                 "actualSide": "buy",
        //                 "actualSz": "0",
        //                 "algoId": "433845797218942976",
        //                 "cTime": "1649708898523",
        //                 "callbackRatio": "",
        //                 "callbackSpread": "",
        //                 "ccy": "",
        //                 "ctVal": "0.01",
        //                 "instId": "BTC-USDT-SWAP",
        //                 "instType": "SWAP",
        //                 "last": "39950.4",
        //                 "lever": "125",
        //                 "moveTriggerPx": "",
        //                 "notionalUsd": "1592.1760000000002",
        //                 "ordId": "",
        //                 "ordPx": "29000",
        //                 "ordType": "trigger",
        //                 "posSide": "long",
        //                 "pxLimit": "",
        //                 "pxSpread": "",
        //                 "pxVar": "",
        //                 "side": "buy",
        //                 "slOrdPx": "",
        //                 "slTriggerPx": "",
        //                 "slTriggerPxType": "",
        //                 "state": "canceled",
        //                 "sz": "4",
        //                 "szLimit": "",
        //                 "tag": "",
        //                 "tdMode": "isolated",
        //                 "tgtCcy": "",
        //                 "timeInterval": "",
        //                 "tpOrdPx": "",
        //                 "tpTriggerPx": "",
        //                 "tpTriggerPxType": "",
        //                 "triggerPx": "30000",
        //                 "triggerPxType": "last",
        //                 "triggerTime": "",
        //                 "uly": "BTC-USDT"
        //             },
        //         ],
        //         "msg": ""
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        return this.parseOrders(data, market, since, limit);
    }
    async fetchClosedOrders(symbol = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchClosedOrders
         * @description fetches information on multiple closed orders made by the user
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-trade-get-order-history-last-7-days
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-algo-trading-get-algo-order-history
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-trade-get-order-history-last-3-months
         * @param {string} symbol unified market symbol of the market orders were made in
         * @param {int} [since] the earliest time in ms to fetch orders for
         * @param {int} [limit] the maximum number of order structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {bool} [params.trigger] True if fetching trigger or conditional orders
         * @param {string} [params.ordType] "conditional", "oco", "trigger", "move_order_stop", "iceberg", or "twap"
         * @param {string} [params.algoId] Algo ID "'433845797218942976'"
         * @param {int} [params.until] timestamp in ms to fetch orders for
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [availble parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @param {string} [params.method] method to be used, either 'privateGetTradeOrdersHistory', 'privateGetTradeOrdersHistoryArchive' or 'privateGetTradeOrdersAlgoHistory' default is 'privateGetTradeOrdersHistory'
         * @param {boolean} [params.trailing] set to true if you want to fetch trailing orders
         * @returns {Order[]} a list of [order structures]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchClosedOrders', 'paginate');
        if (paginate) {
            return await this.fetchPaginatedCallDynamic('fetchClosedOrders', symbol, since, limit, params);
        }
        const request = {
        // 'instType': type.toUpperCase (), // SPOT, MARGIN, SWAP, FUTURES, OPTION
        // 'uly': currency['id'],
        // 'instId': market['id'],
        // 'ordType': 'limit', // market, limit, post_only, fok, ioc, comma-separated stop orders: conditional, oco, trigger, move_order_stop, iceberg, or twap
        // 'state': 'filled', // filled, effective
        // 'after': orderId,
        // 'before': orderId,
        // 'limit': limit, // default 100, max 100
        // 'algoId': "'433845797218942976'", // Algo order
        };
        let market = undefined;
        if (symbol !== undefined) {
            market = this.market(symbol);
            request['instId'] = market['id'];
        }
        let type = undefined;
        let query = undefined;
        [type, query] = this.handleMarketTypeAndParams('fetchClosedOrders', market, params);
        request['instType'] = this.convertToInstrumentType(type);
        if (limit !== undefined) {
            request['limit'] = limit; // default 100, max 100
        }
        const options = this.safeDict(this.options, 'fetchClosedOrders', {});
        const algoOrderTypes = this.safeDict(this.options, 'algoOrderTypes', {});
        const defaultMethod = this.safeString(options, 'method', 'privateGetTradeOrdersHistory');
        let method = this.safeString(params, 'method', defaultMethod);
        const ordType = this.safeString(params, 'ordType');
        const stop = this.safeBool2(params, 'stop', 'trigger');
        const trailing = this.safeBool(params, 'trailing', false);
        if (trailing || stop || (ordType in algoOrderTypes)) {
            method = 'privateGetTradeOrdersAlgoHistory';
            request['state'] = 'effective';
        }
        if (trailing) {
            request['ordType'] = 'move_order_stop';
        }
        else if (stop) {
            if (ordType === undefined) {
                request['ordType'] = 'trigger';
            }
        }
        else {
            if (since !== undefined) {
                request['begin'] = since;
            }
            const until = this.safeInteger2(query, 'till', 'until');
            if (until !== undefined) {
                request['end'] = until;
                query = this.omit(query, ['until', 'till']);
            }
            request['state'] = 'filled';
        }
        const send = this.omit(query, ['method', 'stop', 'trigger', 'trailing']);
        let response = undefined;
        if (method === 'privateGetTradeOrdersAlgoHistory') {
            response = await this.privateGetTradeOrdersAlgoHistory(this.extend(request, send));
        }
        else if (method === 'privateGetTradeOrdersHistoryArchive') {
            response = await this.privateGetTradeOrdersHistoryArchive(this.extend(request, send));
        }
        else {
            response = await this.privateGetTradeOrdersHistory(this.extend(request, send));
        }
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "accFillSz": "0",
        //                 "avgPx": "",
        //                 "cTime": "1621910749815",
        //                 "category": "normal",
        //                 "ccy": "",
        //                 "clOrdId": "",
        //                 "fee": "0",
        //                 "feeCcy": "ETH",
        //                 "fillPx": "",
        //                 "fillSz": "0",
        //                 "fillTime": "",
        //                 "instId": "ETH-USDT",
        //                 "instType": "SPOT",
        //                 "lever": "",
        //                 "ordId": "317251910906576896",
        //                 "ordType": "limit",
        //                 "pnl": "0",
        //                 "posSide": "net",
        //                 "px": "2000",
        //                 "rebate": "0",
        //                 "rebateCcy": "USDT",
        //                 "side": "buy",
        //                 "slOrdPx": "",
        //                 "slTriggerPx": "",
        //                 "state": "live",
        //                 "sz": "0.001",
        //                 "tag": "",
        //                 "tdMode": "cash",
        //                 "tpOrdPx": "",
        //                 "tpTriggerPx": "",
        //                 "tradeId": "",
        //                 "uTime": "1621910749815"
        //             }
        //         ],
        //         "msg": ""
        //     }
        //
        // Algo order
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "activePx": "",
        //                 "activePxType": "",
        //                 "actualPx": "",
        //                 "actualSide": "buy",
        //                 "actualSz": "0",
        //                 "algoId": "433845797218942976",
        //                 "cTime": "1649708898523",
        //                 "callbackRatio": "",
        //                 "callbackSpread": "",
        //                 "ccy": "",
        //                 "ctVal": "0.01",
        //                 "instId": "BTC-USDT-SWAP",
        //                 "instType": "SWAP",
        //                 "last": "39950.4",
        //                 "lever": "125",
        //                 "moveTriggerPx": "",
        //                 "notionalUsd": "1592.1760000000002",
        //                 "ordId": "",
        //                 "ordPx": "29000",
        //                 "ordType": "trigger",
        //                 "posSide": "long",
        //                 "pxLimit": "",
        //                 "pxSpread": "",
        //                 "pxVar": "",
        //                 "side": "buy",
        //                 "slOrdPx": "",
        //                 "slTriggerPx": "",
        //                 "slTriggerPxType": "",
        //                 "state": "effective",
        //                 "sz": "4",
        //                 "szLimit": "",
        //                 "tag": "",
        //                 "tdMode": "isolated",
        //                 "tgtCcy": "",
        //                 "timeInterval": "",
        //                 "tpOrdPx": "",
        //                 "tpTriggerPx": "",
        //                 "tpTriggerPxType": "",
        //                 "triggerPx": "30000",
        //                 "triggerPxType": "last",
        //                 "triggerTime": "",
        //                 "uly": "BTC-USDT"
        //             },
        //         ],
        //         "msg": ""
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        return this.parseOrders(data, market, since, limit);
    }
    async fetchMyTrades(symbol = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchMyTrades
         * @description fetch all trades made by the user
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-trade-get-transaction-details-last-3-months
         * @param {string} symbol unified market symbol
         * @param {int} [since] the earliest time in ms to fetch trades for
         * @param {int} [limit] the maximum number of trades structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {int} [params.until] Timestamp in ms of the latest time to retrieve trades for
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [availble parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @returns {Trade[]} a list of [trade structures]{@link https://docs.ccxt.com/#/?id=trade-structure}
         */
        await this.loadMarkets();
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchMyTrades', 'paginate');
        if (paginate) {
            return await this.fetchPaginatedCallDynamic('fetchMyTrades', symbol, since, limit, params);
        }
        let request = {
        // 'instType': 'SPOT', // SPOT, MARGIN, SWAP, FUTURES, OPTION
        // 'uly': currency['id'],
        // 'instId': market['id'],
        // 'ordId': orderId,
        // 'after': billId,
        // 'before': billId,
        // 'limit': limit, // default 100, max 100
        };
        let market = undefined;
        if (symbol !== undefined) {
            market = this.market(symbol);
            request['instId'] = market['id'];
        }
        if (since !== undefined) {
            request['begin'] = since;
        }
        [request, params] = this.handleUntilOption('end', request, params);
        const [type, query] = this.handleMarketTypeAndParams('fetchMyTrades', market, params);
        request['instType'] = this.convertToInstrumentType(type);
        if ((limit !== undefined) && (since === undefined)) { // let limit = n, okx will return the n most recent results, instead of the n results after limit, so limit should only be sent when since is undefined
            request['limit'] = limit; // default 100, max 100
        }
        const response = await this.privateGetTradeFillsHistory(this.extend(request, query));
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "side": "buy",
        //                 "fillSz": "0.007533",
        //                 "fillPx": "2654.98",
        //                 "fee": "-0.000007533",
        //                 "ordId": "317321390244397056",
        //                 "instType": "SPOT",
        //                 "instId": "ETH-USDT",
        //                 "clOrdId": "",
        //                 "posSide": "net",
        //                 "billId": "317321390265368576",
        //                 "tag": "0",
        //                 "execType": "T",
        //                 "tradeId": "107601752",
        //                 "feeCcy": "ETH",
        //                 "ts": "1621927314985"
        //             }
        //         ],
        //         "msg": ""
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        return this.parseTrades(data, market, since, limit, query);
    }
    async fetchOrderTrades(id, symbol = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchOrderTrades
         * @description fetch all the trades made from a single order
         * @see https://www.okx.com/docs-v5/en/#order-book-trading-trade-get-transaction-details-last-3-months
         * @param {string} id order id
         * @param {string} symbol unified market symbol
         * @param {int} [since] the earliest time in ms to fetch trades for
         * @param {int} [limit] the maximum number of trades to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object[]} a list of [trade structures]{@link https://docs.ccxt.com/#/?id=trade-structure}
         */
        const request = {
            // 'instrument_id': market['id'],
            'ordId': id,
            // 'after': '1', // return the page after the specified page number
            // 'before': '1', // return the page before the specified page number
            // 'limit': limit, // optional, number of results per request, default = maximum = 100
        };
        return await this.fetchMyTrades(symbol, since, limit, this.extend(request, params));
    }
    async fetchLedger(code = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchLedger
         * @description fetch the history of changes, actions done by the user or operations that altered balance of the user
         * @see https://www.okx.com/docs-v5/en/#rest-api-account-get-bills-details-last-7-days
         * @see https://www.okx.com/docs-v5/en/#rest-api-account-get-bills-details-last-3-months
         * @see https://www.okx.com/docs-v5/en/#rest-api-funding-asset-bills-details
         * @param {string} code unified currency code, default is undefined
         * @param {int} [since] timestamp in ms of the earliest ledger entry, default is undefined
         * @param {int} [limit] max number of ledger entrys to return, default is undefined
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.marginMode] 'cross' or 'isolated'
         * @param {int} [params.until] the latest time in ms to fetch entries for
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [availble parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @returns {object} a [ledger structure]{@link https://docs.ccxt.com/#/?id=ledger-structure}
         */
        await this.loadMarkets();
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchLedger', 'paginate');
        if (paginate) {
            return await this.fetchPaginatedCallDynamic('fetchLedger', code, since, limit, params);
        }
        const options = this.safeValue(this.options, 'fetchLedger', {});
        let method = this.safeString(options, 'method');
        method = this.safeString(params, 'method', method);
        params = this.omit(params, 'method');
        let request = {
        // 'instType': undefined, // 'SPOT', 'MARGIN', 'SWAP', 'FUTURES", 'OPTION'
        // 'ccy': undefined, // currency['id'],
        // 'mgnMode': undefined, // 'isolated', 'cross'
        // 'ctType': undefined, // 'linear', 'inverse', only applicable to FUTURES/SWAP
        // 'type': varies depending the 'method' endpoint :
        //     - https://www.okx.com/docs-v5/en/#rest-api-account-get-bills-details-last-7-days
        //     - https://www.okx.com/docs-v5/en/#rest-api-funding-asset-bills-details
        //     - https://www.okx.com/docs-v5/en/#rest-api-account-get-bills-details-last-3-months
        // 'after': 'id', // return records earlier than the requested bill id
        // 'before': 'id', // return records newer than the requested bill id
        // 'limit': 100, // default 100, max 100
        };
        let marginMode = undefined;
        [marginMode, params] = this.handleMarginModeAndParams('fetchLedger', params);
        if (marginMode === undefined) {
            marginMode = this.safeString(params, 'mgnMode');
        }
        if (method !== 'privateGetAssetBills') {
            if (marginMode !== undefined) {
                request['mgnMode'] = marginMode;
            }
        }
        const [type, query] = this.handleMarketTypeAndParams('fetchLedger', undefined, params);
        if (type !== undefined) {
            request['instType'] = this.convertToInstrumentType(type);
        }
        if (limit !== undefined) {
            request['limit'] = limit;
        }
        let currency = undefined;
        if (code !== undefined) {
            currency = this.currency(code);
            request['ccy'] = currency['id'];
        }
        [request, params] = this.handleUntilOption('end', request, params);
        let response = undefined;
        if (method === 'privateGetAccountBillsArchive') {
            response = await this.privateGetAccountBillsArchive(this.extend(request, query));
        }
        else if (method === 'privateGetAssetBills') {
            response = await this.privateGetAssetBills(this.extend(request, query));
        }
        else {
            response = await this.privateGetAccountBills(this.extend(request, query));
        }
        //
        // privateGetAccountBills, privateGetAccountBillsArchive
        //
        //     {
        //         "code": "0",
        //         "msg": "",
        //         "data": [
        //             {
        //                 "bal": "0.0000819307998198",
        //                 "balChg": "-664.2679586599999802",
        //                 "billId": "310394313544966151",
        //                 "ccy": "USDT",
        //                 "fee": "0",
        //                 "from": "",
        //                 "instId": "LTC-USDT",
        //                 "instType": "SPOT",
        //                 "mgnMode": "cross",
        //                 "notes": "",
        //                 "ordId": "310394313519800320",
        //                 "pnl": "0",
        //                 "posBal": "0",
        //                 "posBalChg": "0",
        //                 "subType": "2",
        //                 "sz": "664.26795866",
        //                 "to": "",
        //                 "ts": "1620275771196",
        //                 "type": "2"
        //             }
        //         ]
        //     }
        //
        // privateGetAssetBills
        //
        //     {
        //         "code": "0",
        //         "msg": "",
        //         "data": [
        //             {
        //                 "billId": "12344",
        //                 "ccy": "BTC",
        //                 "balChg": "2",
        //                 "bal": "12",
        //                 "type": "1",
        //                 "ts": "1597026383085"
        //             }
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        return this.parseLedger(data, currency, since, limit);
    }
    parseLedgerEntryType(type) {
        const types = {
            '1': 'transfer',
            '2': 'trade',
            '3': 'trade',
            '4': 'rebate',
            '5': 'trade',
            '6': 'transfer',
            '7': 'trade',
            '8': 'fee',
            '9': 'trade',
            '10': 'trade',
            '11': 'trade', // system token conversion
        };
        return this.safeString(types, type, type);
    }
    parseLedgerEntry(item, currency = undefined) {
        //
        // privateGetAccountBills, privateGetAccountBillsArchive
        //
        //     {
        //         "bal": "0.0000819307998198",
        //         "balChg": "-664.2679586599999802",
        //         "billId": "310394313544966151",
        //         "ccy": "USDT",
        //         "fee": "0",
        //         "from": "",
        //         "instId": "LTC-USDT",
        //         "instType": "SPOT",
        //         "mgnMode": "cross",
        //         "notes": "",
        //         "ordId": "310394313519800320",
        //         "pnl": "0",
        //         "posBal": "0",
        //         "posBalChg": "0",
        //         "subType": "2",
        //         "sz": "664.26795866",
        //         "to": "",
        //         "ts": "1620275771196",
        //         "type": "2"
        //     }
        //
        // privateGetAssetBills
        //
        //     {
        //         "billId": "12344",
        //         "ccy": "BTC",
        //         "balChg": "2",
        //         "bal": "12",
        //         "type": "1",
        //         "ts": "1597026383085"
        //     }
        //
        const id = this.safeString(item, 'billId');
        const account = undefined;
        const referenceId = this.safeString(item, 'ordId');
        const referenceAccount = undefined;
        const type = this.parseLedgerEntryType(this.safeString(item, 'type'));
        const code = this.safeCurrencyCode(this.safeString(item, 'ccy'), currency);
        const amountString = this.safeString(item, 'balChg');
        const amount = this.parseNumber(amountString);
        const timestamp = this.safeInteger(item, 'ts');
        const feeCostString = this.safeString(item, 'fee');
        let fee = undefined;
        if (feeCostString !== undefined) {
            fee = {
                'cost': this.parseNumber(Precise.stringNeg(feeCostString)),
                'currency': code,
            };
        }
        const before = undefined;
        const afterString = this.safeString(item, 'bal');
        const after = this.parseNumber(afterString);
        const status = 'ok';
        const marketId = this.safeString(item, 'instId');
        const symbol = this.safeSymbol(marketId, undefined, '-');
        return {
            'id': id,
            'info': item,
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'account': account,
            'referenceId': referenceId,
            'referenceAccount': referenceAccount,
            'type': type,
            'currency': code,
            'symbol': symbol,
            'amount': amount,
            'before': before,
            'after': after,
            'status': status,
            'fee': fee,
        };
    }
    parseDepositAddress(depositAddress, currency = undefined) {
        //
        //     {
        //         "addr": "okbtothemoon",
        //         "memo": "971668", // may be missing
        //         "tag":"52055", // may be missing
        //         "pmtId": "", // may be missing
        //         "ccy": "BTC",
        //         "to": "6", // 1 SPOT, 3 FUTURES, 6 FUNDING, 9 SWAP, 12 OPTION, 18 Unified account
        //         "selected": true
        //     }
        //
        //     {
        //         "ccy":"usdt-erc20",
        //         "to":"6",
        //         "addr":"0x696abb81974a8793352cbd33aadcf78eda3cfdfa",
        //         "selected":true
        //     }
        //
        //     {
        //        "chain": "ETH-OKExChain",
        //        "addrEx": { "comment": "6040348" }, // some currencies like TON may have this field,
        //        "ctAddr": "72315c",
        //        "ccy": "ETH",
        //        "to": "6",
        //        "addr": "0x1c9f2244d1ccaa060bd536827c18925db10db102",
        //        "selected": true
        //     }
        //
        const address = this.safeString(depositAddress, 'addr');
        let tag = this.safeStringN(depositAddress, ['tag', 'pmtId', 'memo']);
        if (tag === undefined) {
            const addrEx = this.safeValue(depositAddress, 'addrEx', {});
            tag = this.safeString(addrEx, 'comment');
        }
        const currencyId = this.safeString(depositAddress, 'ccy');
        currency = this.safeCurrency(currencyId, currency);
        const code = currency['code'];
        const chain = this.safeString(depositAddress, 'chain');
        const networks = this.safeValue(currency, 'networks', {});
        const networksById = this.indexBy(networks, 'id');
        const networkData = this.safeValue(networksById, chain);
        const network = this.safeString(networkData, 'network');
        this.checkAddress(address);
        return {
            'currency': code,
            'address': address,
            'tag': tag,
            'network': network,
            'info': depositAddress,
        };
    }
    async fetchDepositAddressesByNetwork(code, params = {}) {
        /**
         * @method
         * @name okx#fetchDepositAddressesByNetwork
         * @description fetch a dictionary of addresses for a currency, indexed by network
         * @see https://www.okx.com/docs-v5/en/#funding-account-rest-api-get-deposit-address
         * @param {string} code unified currency code of the currency for the deposit address
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a dictionary of [address structures]{@link https://docs.ccxt.com/#/?id=address-structure} indexed by the network
         */
        await this.loadMarkets();
        const currency = this.currency(code);
        const request = {
            'ccy': currency['id'],
        };
        const response = await this.privateGetAssetDepositAddress(this.extend(request, params));
        //
        //     {
        //         "code": "0",
        //         "msg": "",
        //         "data": [
        //             {
        //                 "addr": "okbtothemoon",
        //                 "memo": "971668", // may be missing
        //                 "tag":"52055", // may be missing
        //                 "pmtId": "", // may be missing
        //                 "ccy": "BTC",
        //                 "to": "6", // 1 SPOT, 3 FUTURES, 6 FUNDING, 9 SWAP, 12 OPTION, 18 Unified account
        //                 "selected": true
        //             },
        //             // {"ccy":"usdt-erc20","to":"6","addr":"0x696abb81974a8793352cbd33aadcf78eda3cfdfa","selected":true},
        //             // {"ccy":"usdt-trc20","to":"6","addr":"TRrd5SiSZrfQVRKm4e9SRSbn2LNTYqCjqx","selected":true},
        //             // {"ccy":"usdt_okexchain","to":"6","addr":"0x696abb81974a8793352cbd33aadcf78eda3cfdfa","selected":true},
        //             // {"ccy":"usdt_kip20","to":"6","addr":"0x696abb81974a8793352cbd33aadcf78eda3cfdfa","selected":true},
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        const filtered = this.filterBy(data, 'selected', true);
        const parsed = this.parseDepositAddresses(filtered, [currency['code']], false);
        return this.indexBy(parsed, 'network');
    }
    async fetchDepositAddress(code, params = {}) {
        /**
         * @method
         * @name okx#fetchDepositAddress
         * @description fetch the deposit address for a currency associated with this account
         * @see https://www.okx.com/docs-v5/en/#funding-account-rest-api-get-deposit-address
         * @param {string} code unified currency code
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} an [address structure]{@link https://docs.ccxt.com/#/?id=address-structure}
         */
        const network = this.safeString(params, 'network');
        const networks = this.safeValue(this.options, 'networks', {});
        params = this.omit(params, 'network');
        const response = await this.fetchDepositAddressesByNetwork(code, params);
        let result = undefined;
        if (network === undefined) {
            result = this.safeValue(response, code);
            if (result === undefined) {
                const alias = this.safeString(networks, code, code);
                result = this.safeValue(response, alias);
                if (result === undefined) {
                    const defaultNetwork = this.safeString(this.options, 'defaultNetwork', 'ERC20');
                    result = this.safeValue(response, defaultNetwork);
                    if (result === undefined) {
                        const values = Object.values(response);
                        result = this.safeValue(values, 0);
                        if (result === undefined) {
                            throw new GetDepositAddressError(code, network).exchange(this.id);
                        }
                    }
                }
            }
            return result;
        }
        result = this.safeValue(response, network);
        if (result === undefined) {
            throw new GetDepositAddressError(code, network).exchange(this.id);
        }
        return result;
    }
    async withdraw(code, amount, address, tag = undefined, params = {}) {
        /**
         * @method
         * @name okx#withdraw
         * @description make a withdrawal
         * @see https://www.okx.com/docs-v5/en/#funding-account-rest-api-withdrawal
         * @param {string} code unified currency code
         * @param {float} amount the amount to withdraw
         * @param {string} address the address to withdraw to
         * @param {string} tag
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a [transaction structure]{@link https://docs.ccxt.com/#/?id=transaction-structure}
         */
        [tag, params] = this.handleWithdrawTagAndParams(tag, params);
        this.checkAddress(address);
        await this.loadMarkets();
        const currency = this.currency(code);
        if ((tag !== undefined) && (tag.length > 0)) {
            address = address + ':' + tag;
        }
        const request = {
            'ccy': currency['id'],
            'toAddr': address,
            'dest': '4', // 2 = OKCoin International, 3 = OKX 4 = others
        };
        let network = this.safeString(params, 'network'); // this line allows the user to specify either ERC20 or ETH
        if (network !== undefined) {
            const networks = this.safeValue(this.options, 'networks', {});
            network = this.safeString(networks, network.toUpperCase(), network); // handle ETH>ERC20 alias
            request['chain'] = currency['id'] + '-' + network;
            params = this.omit(params, 'network');
        }
        const precisionAmount = this.currencyToPrecision(code, amount, network);
        request['amt'] = this.numberToString(precisionAmount);
        let fee = this.safeString(params, 'fee');
        if (fee === undefined) {
            const currencies = await this.fetchCurrencies();
            this.currencies = this.deepExtend(this.currencies, currencies);
            const targetNetwork = this.safeValue(currency['networks'], this.networkIdToCode(network), {});
            fee = this.safeString(targetNetwork, 'fee');
            if (fee === undefined) {
                throw new InvalidParamsError('withdraw() requires a "fee" string parameter, network transaction fee must be ≥ 0. Withdrawals to OKCoin or OKX are fee-free, please set "0". Withdrawing to external digital asset address requires network transaction fee.').exchange(this.id);
            }
        }
        request['fee'] = this.numberToString(fee); // withdrawals to OKCoin or OKX are fee-free, please set 0
        if ('password' in params) {
            request['pwd'] = params['password'];
        }
        else if ('pwd' in params) {
            request['pwd'] = params['pwd'];
        }
        else {
            const options = this.safeValue(this.options, 'withdraw', {});
            const password = this.safeString2(options, 'password', 'pwd');
            if (password !== undefined) {
                request['pwd'] = password;
            }
        }
        const query = this.omit(params, ['fee', 'password', 'pwd']);
        if (!('pwd' in request)) {
            throw new InvalidParamsError('withdraw() requires a password parameter or a pwd parameter, it must be the funding password, not the API passphrase')
                .exchange(this.id);
        }
        const response = await this.privatePostAssetWithdrawal(this.extend(request, query));
        //
        //     {
        //         "code": "0",
        //         "msg": "",
        //         "data": [
        //             {
        //                 "amt": "0.1",
        //                 "wdId": "67485",
        //                 "ccy": "BTC"
        //             }
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        const transaction = this.safeValue(data, 0);
        return this.parseTransaction(transaction, currency);
    }
    async fetchDeposits(code = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchDeposits
         * @description fetch all deposits made to an account
         * @see https://www.okx.com/docs-v5/en/#rest-api-funding-get-deposit-history
         * @param {string} code unified currency code
         * @param {int} [since] the earliest time in ms to fetch deposits for
         * @param {int} [limit] the maximum number of deposits structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {int} [params.until] the latest time in ms to fetch entries for
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [availble parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @returns {object[]} a list of [transaction structures]{@link https://docs.ccxt.com/#/?id=transaction-structure}
         */
        await this.loadMarkets();
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchDeposits', 'paginate');
        if (paginate) {
            return await this.fetchPaginatedCallDynamic('fetchDeposits', code, since, limit, params);
        }
        let request = {
        // 'ccy': currency['id'],
        // 'state': 2, // 0 waiting for confirmation, 1 deposit credited, 2 deposit successful
        // 'after': since,
        // 'before' this.milliseconds (),
        // 'limit': limit, // default 100, max 100
        };
        let currency = undefined;
        if (code !== undefined) {
            currency = this.currency(code);
            request['ccy'] = currency['id'];
        }
        if (since !== undefined) {
            request['before'] = Math.max(since - 1, 0);
        }
        if (limit !== undefined) {
            request['limit'] = limit; // default 100, max 100
        }
        [request, params] = this.handleUntilOption('after', request, params);
        const response = await this.privateGetAssetDepositHistory(this.extend(request, params));
        //
        //     {
        //         "code": "0",
        //         "msg": "",
        //         "data": [
        //             {
        //                 "amt": "0.01044408",
        //                 "txId": "1915737_3_0_0_asset",
        //                 "ccy": "BTC",
        //                 "from": "13801825426",
        //                 "to": "",
        //                 "ts": "1597026383085",
        //                 "state": "2",
        //                 "depId": "4703879"
        //             },
        //             {
        //                 "amt": "491.6784211",
        //                 "txId": "1744594_3_184_0_asset",
        //                 "ccy": "OKB",
        //                 "from": "",
        //                 "to": "",
        //                 "ts": "1597026383085",
        //                 "state": "2",
        //                 "depId": "4703809"
        //             },
        //             {
        //                 "amt": "223.18782496",
        //                 "txId": "6d892c669225b1092c780bf0da0c6f912fc7dc8f6b8cc53b003288624c",
        //                 "ccy": "USDT",
        //                 "from": "",
        //                 "to": "39kK4XvgEuM7rX9frgyHoZkWqx4iKu1spD",
        //                 "ts": "1597026383085",
        //                 "state": "2",
        //                 "depId": "4703779"
        //             }
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        return this.parseTransactions(data, currency, since, limit, params);
    }
    async fetchDeposit(id, code = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchDeposit
         * @description fetch data on a currency deposit via the deposit id
         * @see https://www.okx.com/docs-v5/en/#rest-api-funding-get-deposit-history
         * @param {string} id deposit id
         * @param {string} code filter by currency code
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a [transaction structure]{@link https://docs.ccxt.com/#/?id=transaction-structure}
         */
        await this.loadMarkets();
        const request = {
            'depId': id,
        };
        let currency = undefined;
        if (code !== undefined) {
            currency = this.currency(code);
            request['ccy'] = currency['id'];
        }
        const response = await this.privateGetAssetDepositHistory(this.extend(request, params));
        const data = this.safeValue(response, 'data');
        const deposit = this.safeValue(data, 0, {});
        return this.parseTransaction(deposit, currency);
    }
    async fetchWithdrawals(code = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchWithdrawals
         * @description fetch all withdrawals made from an account
         * @see https://www.okx.com/docs-v5/en/#rest-api-funding-get-withdrawal-history
         * @param {string} code unified currency code
         * @param {int} [since] the earliest time in ms to fetch withdrawals for
         * @param {int} [limit] the maximum number of withdrawals structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {int} [params.until] the latest time in ms to fetch entries for
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [availble parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @returns {object[]} a list of [transaction structures]{@link https://docs.ccxt.com/#/?id=transaction-structure}
         */
        await this.loadMarkets();
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchWithdrawals', 'paginate');
        if (paginate) {
            return await this.fetchPaginatedCallDynamic('fetchWithdrawals', code, since, limit, params);
        }
        let request = {
        // 'ccy': currency['id'],
        // 'state': 2, // -3: pending cancel, -2 canceled, -1 failed, 0, pending, 1 sending, 2 sent, 3 awaiting email verification, 4 awaiting manual verification, 5 awaiting identity verification
        // 'after': since,
        // 'before': this.milliseconds (),
        // 'limit': limit, // default 100, max 100
        };
        let currency = undefined;
        if (code !== undefined) {
            currency = this.currency(code);
            request['ccy'] = currency['id'];
        }
        if (since !== undefined) {
            request['before'] = Math.max(since - 1, 0);
        }
        if (limit !== undefined) {
            request['limit'] = limit; // default 100, max 100
        }
        [request, params] = this.handleUntilOption('after', request, params);
        const response = await this.privateGetAssetWithdrawalHistory(this.extend(request, params));
        //
        //     {
        //         "code": "0",
        //         "msg": "",
        //         "data": [
        //             {
        //                 "amt": "0.094",
        //                 "wdId": "4703879",
        //                 "fee": "0.01000000eth",
        //                 "txId": "0x62477bac6509a04512819bb1455e923a60dea5966c7caeaa0b24eb8fb0432b85",
        //                 "ccy": "ETH",
        //                 "from": "13426335357",
        //                 "to": "0xA41446125D0B5b6785f6898c9D67874D763A1519",
        //                 "ts": "1597026383085",
        //                 "state": "2"
        //             },
        //             {
        //                 "amt": "0.01",
        //                 "wdId": "4703879",
        //                 "fee": "0.00000000btc",
        //                 "txId": "",
        //                 "ccy": "BTC",
        //                 "from": "13426335357",
        //                 "to": "13426335357",
        //                 "ts": "1597026383085",
        //                 "state": "2"
        //             }
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        return this.parseTransactions(data, currency, since, limit, params);
    }
    async fetchWithdrawal(id, code = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchWithdrawal
         * @description fetch data on a currency withdrawal via the withdrawal id
         * @see https://www.okx.com/docs-v5/en/#rest-api-funding-get-withdrawal-history
         * @param {string} id withdrawal id
         * @param {string} code unified currency code of the currency withdrawn, default is undefined
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a [transaction structure]{@link https://docs.ccxt.com/#/?id=transaction-structure}
         */
        await this.loadMarkets();
        const request = {
            'wdId': id,
        };
        let currency = undefined;
        if (code !== undefined) {
            currency = this.currency(code);
            request['ccy'] = currency['id'];
        }
        const response = await this.privateGetAssetWithdrawalHistory(this.extend(request, params));
        //
        //    {
        //        "code": "0",
        //        "data": [
        //            {
        //                "chain": "USDT-TRC20",
        //                "clientId": '',
        //                "fee": "0.8",
        //                "ccy": "USDT",
        //                "amt": "54.561",
        //                "txId": "00cff6ec7fa7c7d7d184bd84e82b9ff36863f07c0421188607f87dfa94e06b70",
        //                "from": "example@email.com",
        //                "to": "TEY6qjnKDyyq5jDc3DJizWLCdUySrpQ4yp",
        //                "state": "2",
        //                "ts": "1641376485000",
        //                "wdId": "25147041"
        //            }
        //        ],
        //        "msg": ''
        //    }
        //
        const data = this.safeValue(response, 'data');
        const withdrawal = this.safeValue(data, 0, {});
        return this.parseTransaction(withdrawal);
    }
    parseTransactionStatus(status) {
        //
        // deposit statuses
        //
        //     {
        //         "0": "waiting for confirmation",
        //         "1": "deposit credited",
        //         "2": "deposit successful"
        //     }
        //
        // withdrawal statuses
        //
        //     {
        //        '-3': "pending cancel",
        //        "-2": "canceled",
        //        "-1": "failed",
        //         "0": "pending",
        //         "1": "sending",
        //         "2": "sent",
        //         "3": "awaiting email verification",
        //         "4": "awaiting manual verification",
        //         "5": "awaiting identity verification"
        //     }
        //
        const statuses = {
            '-3': 'pending',
            '-2': 'canceled',
            '-1': 'failed',
            '0': 'pending',
            '1': 'pending',
            '2': 'ok',
            '3': 'pending',
            '4': 'pending',
            '5': 'pending',
        };
        return this.safeString(statuses, status, status);
    }
    parseTransaction(transaction, currency = undefined) {
        //
        // withdraw
        //
        //     {
        //         "amt": "0.1",
        //         "wdId": "67485",
        //         "ccy": "BTC"
        //     }
        //
        // fetchWithdrawals
        //
        //     {
        //         "amt": "0.094",
        //         "wdId": "4703879",
        //         "fee": "0.01000000eth",
        //         "txId": "0x62477bac6509a04512819bb1455e923a60dea5966c7caeaa0b24eb8fb0432b85",
        //         "ccy": "ETH",
        //         "from": "13426335357",
        //         "to": "0xA41446125D0B5b6785f6898c9D67874D763A1519",
        //         "tag",
        //         "pmtId",
        //         "memo",
        //         "ts": "1597026383085",
        //         "state": "2"
        //     }
        //
        // fetchDeposits
        //
        //     {
        //         "amt": "0.01044408",
        //         "txId": "1915737_3_0_0_asset",
        //         "ccy": "BTC",
        //         "from": "13801825426",
        //         "to": "",
        //         "ts": "1597026383085",
        //         "state": "2",
        //         "depId": "4703879"
        //     }
        //
        let type = undefined;
        let id = undefined;
        const withdrawalId = this.safeString(transaction, 'wdId');
        const addressFrom = this.safeString(transaction, 'from');
        const addressTo = this.safeString(transaction, 'to');
        const address = addressTo;
        let tagTo = this.safeString2(transaction, 'tag', 'memo');
        tagTo = this.safeString2(transaction, 'pmtId', tagTo);
        if (withdrawalId !== undefined) {
            type = 'withdrawal';
            id = withdrawalId;
        }
        else {
            // the payment_id will appear on new deposits but appears to be removed from the response after 2 months
            id = this.safeString(transaction, 'depId');
            type = 'deposit';
        }
        const currencyId = this.safeString(transaction, 'ccy');
        const code = this.safeCurrencyCode(currencyId);
        const amount = this.safeNumber(transaction, 'amt');
        const status = this.parseTransactionStatus(this.safeString(transaction, 'state'));
        const txid = this.safeString(transaction, 'txId');
        const timestamp = this.safeInteger(transaction, 'ts');
        let feeCost = undefined;
        if (type === 'deposit') {
            feeCost = 0;
        }
        else {
            feeCost = this.safeNumber(transaction, 'fee');
        }
        // todo parse tags
        return {
            'info': transaction,
            'id': id,
            'currency': code,
            'amount': amount,
            'network': undefined,
            'addressFrom': addressFrom,
            'addressTo': addressTo,
            'address': address,
            'tagFrom': undefined,
            'tagTo': tagTo,
            'tag': tagTo,
            'status': status,
            'type': type,
            'updated': undefined,
            'txid': txid,
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'internal': undefined,
            'comment': undefined,
            'fee': {
                'currency': code,
                'cost': feeCost,
            },
        };
    }
    async fetchPosition(symbol, params = {}) {
        /**
         * @method
         * @name okx#fetchPosition
         * @description fetch data on a single open contract trade position
         * @see https://www.okx.com/docs-v5/en/#rest-api-account-get-positions
         * @param {string} symbol unified market symbol of the market the position is held in, default is undefined
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.instType] MARGIN, SWAP, FUTURES, OPTION
         * @returns {object} a [position structure]{@link https://docs.ccxt.com/#/?id=position-structure}
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        const [type, query] = this.handleMarketTypeAndParams('fetchPosition', market, params);
        const request = {
            // instType String No Instrument type, MARGIN, SWAP, FUTURES, OPTION
            'instId': market['id'],
            // posId String No Single position ID or multiple position IDs (no more than 20) separated with comma
        };
        if (type !== undefined) {
            request['instType'] = this.convertToInstrumentType(type);
        }
        const response = await this.privateGetAccountPositions(this.extend(request, query));
        //
        //     {
        //         "code": "0",
        //         "msg": "",
        //         "data": [
        //             {
        //                 "adl": "1",
        //                 "availPos": "1",
        //                 "avgPx": "2566.31",
        //                 "cTime": "1619507758793",
        //                 "ccy": "ETH",
        //                 "deltaBS": "",
        //                 "deltaPA": "",
        //                 "gammaBS": "",
        //                 "gammaPA": "",
        //                 "imr": "",
        //                 "instId": "ETH-USD-210430",
        //                 "instType": "FUTURES",
        //                 "interest": "0",
        //                 "last": "2566.22",
        //                 "lever": "10",
        //                 "liab": "",
        //                 "liabCcy": "",
        //                 "liqPx": "2352.8496681818233",
        //                 "margin": "0.0003896645377994",
        //                 "mgnMode": "isolated",
        //                 "mgnRatio": "11.731726509588816",
        //                 "mmr": "0.0000311811092368",
        //                 "optVal": "",
        //                 "pTime": "1619507761462",
        //                 "pos": "1",
        //                 "posCcy": "",
        //                 "posId": "307173036051017730",
        //                 "posSide": "long",
        //                 "thetaBS": "",
        //                 "thetaPA": "",
        //                 "tradeId": "109844",
        //                 "uTime": "1619507761462",
        //                 "upl": "-0.0000009932766034",
        //                 "uplRatio": "-0.0025490556801078",
        //                 "vegaBS": "",
        //                 "vegaPA": ""
        //             }
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        const position = this.safeValue(data, 0);
        if (position === undefined) {
            return undefined;
        }
        return this.parsePosition(position, market);
    }
    async fetchPositions(symbols = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchPositions
         * @see https://www.okx.com/docs-v5/en/#rest-api-account-get-positions
         * @description fetch all open positions
         * @param {string[]|undefined} symbols list of unified market symbols
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.instType] MARGIN, SWAP, FUTURES, OPTION
         * @returns {object[]} a list of [position structure]{@link https://docs.ccxt.com/#/?id=position-structure}
         */
        await this.loadMarkets();
        const request = {
        // 'instType': 'MARGIN', // optional string, MARGIN, SWAP, FUTURES, OPTION
        // 'instId': market['id'], // optional string, e.g. 'BTC-USD-190927-5000-C'
        // 'posId': '307173036051017730', // optional string, Single or multiple position IDs (no more than 20) separated with commas
        };
        if (symbols !== undefined) {
            const marketIds = [];
            for (let i = 0; i < symbols.length; i++) {
                const entry = symbols[i];
                const market = this.market(entry);
                marketIds.push(market['id']);
            }
            const marketIdsLength = marketIds.length;
            if (marketIdsLength > 0) {
                request['instId'] = marketIds.join(',');
            }
        }
        const fetchPositionsOptions = this.safeValue(this.options, 'fetchPositions', {});
        const method = this.safeString(fetchPositionsOptions, 'method', 'privateGetAccountPositions');
        let response = undefined;
        if (method === 'privateGetAccountPositionsHistory') {
            response = await this.privateGetAccountPositionsHistory(this.extend(request, params));
        }
        else {
            response = await this.privateGetAccountPositions(this.extend(request, params));
        }
        //
        //     {
        //         "code": "0",
        //         "msg": "",
        //         "data": [
        //             {
        //                 "adl": "1",
        //                 "availPos": "1",
        //                 "avgPx": "2566.31",
        //                 "cTime": "1619507758793",
        //                 "ccy": "ETH",
        //                 "deltaBS": "",
        //                 "deltaPA": "",
        //                 "gammaBS": "",
        //                 "gammaPA": "",
        //                 "imr": "",
        //                 "instId": "ETH-USD-210430",
        //                 "instType": "FUTURES",
        //                 "interest": "0",
        //                 "last": "2566.22",
        //                 "lever": "10",
        //                 "liab": "",
        //                 "liabCcy": "",
        //                 "liqPx": "2352.8496681818233",
        //                 "margin": "0.0003896645377994",
        //                 "mgnMode": "isolated",
        //                 "mgnRatio": "11.731726509588816",
        //                 "mmr": "0.0000311811092368",
        //                 "optVal": "",
        //                 "pTime": "1619507761462",
        //                 "pos": "1",
        //                 "posCcy": "",
        //                 "posId": "307173036051017730",
        //                 "posSide": "long",
        //                 "thetaBS": "",
        //                 "thetaPA": "",
        //                 "tradeId": "109844",
        //                 "uTime": "1619507761462",
        //                 "upl": "-0.0000009932766034",
        //                 "uplRatio": "-0.0025490556801078",
        //                 "vegaBS": "",
        //                 "vegaPA": ""
        //             }
        //         ]
        //     }
        //
        const positions = this.safeValue(response, 'data', []);
        const result = [];
        for (let i = 0; i < positions.length; i++) {
            result.push(this.parsePosition(positions[i]));
        }
        return this.filterByArrayPositions(result, 'symbol', symbols, false);
    }
    async fetchPositionsForSymbol(symbol, params = {}) {
        /**
         * @method
         * @name okx#fetchPositions
         * @see https://www.okx.com/docs-v5/en/#rest-api-account-get-positions
         * @description fetch all open positions for specific symbol
         * @param {string} symbol unified market symbol
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.instType] MARGIN (if needed)
         * @returns {object[]} a list of [position structure]{@link https://docs.ccxt.com/#/?id=position-structure}
         */
        return await this.fetchPositions([symbol], params);
    }
    parsePosition(position, market = undefined) {
        //
        //     {
        //        "adl": "3",
        //        "availPos": "1",
        //        "avgPx": "34131.1",
        //        "cTime": "1627227626502",
        //        "ccy": "USDT",
        //        "deltaBS": "",
        //        "deltaPA": "",
        //        "gammaBS": "",
        //        "gammaPA": "",
        //        "imr": "170.66093041794787",
        //        "instId": "BTC-USDT-SWAP",
        //        "instType": "SWAP",
        //        "interest": "0",
        //        "last": "34134.4",
        //        "lever": "2",
        //        "liab": "",
        //        "liabCcy": "",
        //        "liqPx": "12608.959083877446",
        //        "markPx": "4786.459271773621",
        //        "margin": "",
        //        "mgnMode": "cross",
        //        "mgnRatio": "140.49930117599155",
        //        "mmr": "1.3652874433435829",
        //        "notionalUsd": "341.5130010779638",
        //        "optVal": "",
        //        "pos": "1",
        //        "posCcy": "",
        //        "posId": "339552508062380036",
        //        "posSide": "long",
        //        "thetaBS": "",
        //        "thetaPA": "",
        //        "tradeId": "98617799",
        //        "uTime": "1627227626502",
        //        "upl": "0.0108608358957281",
        //        "uplRatio": "0.0000636418743944",
        //        "vegaBS": "",
        //        "vegaPA": ""
        //    }
        //
        const marketId = this.safeString(position, 'instId');
        market = this.safeMarket(marketId, market);
        const symbol = market['symbol'];
        const pos = this.safeString(position, 'pos'); // 'pos' field: One way mode: 0 if position is not open, 1 if open | Two way (hedge) mode: -1 if short, 1 if long, 0 if position is not open
        const contractsAbs = Precise.stringAbs(pos);
        let side = this.safeString(position, 'posSide');
        const hedged = side !== 'net';
        const contracts = this.parseNumber(contractsAbs);
        if (market['margin']) {
            // margin position
            if (side === 'net') {
                const posCcy = this.safeString(position, 'posCcy');
                const parsedCurrency = this.safeCurrencyCode(posCcy);
                if (parsedCurrency !== undefined) {
                    side = (market['base'] === parsedCurrency) ? 'long' : 'short';
                }
            }
            if (side === undefined) {
                side = this.safeString(position, 'direction');
            }
        }
        else {
            if (pos !== undefined) {
                if (side === 'net') {
                    if (Precise.stringGt(pos, '0')) {
                        side = 'long';
                    }
                    else if (Precise.stringLt(pos, '0')) {
                        side = 'short';
                    }
                    else {
                        side = undefined;
                    }
                }
            }
        }
        const contractSize = this.safeNumber(market, 'contractSize');
        const contractSizeString = this.numberToString(contractSize);
        const markPriceString = this.safeString(position, 'markPx');
        let notionalString = this.safeString(position, 'notionalUsd');
        if (market['inverse']) {
            notionalString = Precise.stringDiv(Precise.stringMul(contractsAbs, contractSizeString), markPriceString);
        }
        const notional = this.parseNumber(notionalString);
        const marginMode = this.safeString(position, 'mgnMode');
        let initialMarginString = undefined;
        const entryPriceString = this.safeString(position, 'avgPx');
        const unrealizedPnlString = this.safeString(position, 'upl');
        const leverageString = this.safeString(position, 'lever');
        let initialMarginPercentage = undefined;
        let collateralString = undefined;
        if (marginMode === 'cross') {
            initialMarginString = this.safeString(position, 'imr');
            collateralString = Precise.stringAdd(initialMarginString, unrealizedPnlString);
        }
        else if (marginMode === 'isolated') {
            initialMarginPercentage = Precise.stringDiv('1', leverageString);
            collateralString = this.safeString(position, 'margin');
        }
        const maintenanceMarginString = this.safeString(position, 'mmr');
        const maintenanceMargin = this.parseNumber(maintenanceMarginString);
        const maintenanceMarginPercentageString = Precise.stringDiv(maintenanceMarginString, notionalString);
        if (initialMarginPercentage === undefined) {
            initialMarginPercentage = this.parseNumber(Precise.stringDiv(initialMarginString, notionalString, 4));
        }
        else if (initialMarginString === undefined) {
            initialMarginString = Precise.stringMul(initialMarginPercentage, notionalString);
        }
        const rounder = '0.00005'; // round to closest 0.01%
        const maintenanceMarginPercentage = this.parseNumber(Precise.stringDiv(Precise.stringAdd(maintenanceMarginPercentageString, rounder), '1', 4));
        const liquidationPrice = this.safeNumber(position, 'liqPx');
        const percentageString = this.safeString(position, 'uplRatio');
        const percentage = this.parseNumber(Precise.stringMul(percentageString, '100'));
        const timestamp = this.safeInteger(position, 'uTime');
        const marginRatio = this.parseNumber(Precise.stringDiv(maintenanceMarginString, collateralString, 4));
        return this.safePosition({
            'info': position,
            'id': undefined,
            'symbol': symbol,
            'notional': notional,
            'marginMode': marginMode,
            'liquidationPrice': liquidationPrice,
            'entryPrice': this.parseNumber(entryPriceString),
            'unrealizedPnl': this.parseNumber(unrealizedPnlString),
            'percentage': percentage,
            'contracts': contracts,
            'contractSize': contractSize,
            'markPrice': this.parseNumber(markPriceString),
            'lastPrice': undefined,
            'side': side,
            'hedged': hedged,
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'lastUpdateTimestamp': undefined,
            'maintenanceMargin': maintenanceMargin,
            'maintenanceMarginPercentage': maintenanceMarginPercentage,
            'collateral': this.parseNumber(collateralString),
            'initialMargin': this.parseNumber(initialMarginString),
            'initialMarginPercentage': this.parseNumber(initialMarginPercentage),
            'leverage': this.parseNumber(leverageString),
            'marginRatio': marginRatio,
            'stopLossPrice': undefined,
            'takeProfitPrice': undefined,
        });
    }
    async transfer(code, amount, fromAccount, toAccount, params = {}) {
        /**
         * @method
         * @name okx#transfer
         * @description transfer currency internally between wallets on the same account
         * @see https://www.okx.com/docs-v5/en/#rest-api-funding-funds-transfer
         * @param {string} code unified currency code
         * @param {float} amount amount to transfer
         * @param {string} fromAccount account to transfer from
         * @param {string} toAccount account to transfer to
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a [transfer structure]{@link https://docs.ccxt.com/#/?id=transfer-structure}
         */
        await this.loadMarkets();
        const currency = this.currency(code);
        const accountsByType = this.safeValue(this.options, 'accountsByType', {});
        const fromId = this.safeString(accountsByType, fromAccount, fromAccount);
        const toId = this.safeString(accountsByType, toAccount, toAccount);
        const request = {
            'ccy': currency['id'],
            'amt': this.currencyToPrecision(code, amount),
            'type': '0',
            'from': fromId,
            'to': toId, // beneficiary account, 6: Funding account, 18: Trading account
            // 'subAcct': 'sub-account-name', // optional, only required when type is 1, 2 or 4
            // 'loanTrans': false, // Whether or not borrowed coins can be transferred out under Multi-currency margin and Portfolio margin. The default is false
            // 'clientId': 'client-supplied id', // A combination of case-sensitive alphanumerics, all numbers, or all letters of up to 32 characters
            // 'omitPosRisk': false, // Ignore position risk. Default is false. Applicable to Portfolio margin
        };
        if (fromId === 'master') {
            request['type'] = '1';
            request['subAcct'] = toId;
            request['from'] = this.safeString(params, 'from', '6');
            request['to'] = this.safeString(params, 'to', '6');
        }
        else if (toId === 'master') {
            request['type'] = '2';
            request['subAcct'] = fromId;
            request['from'] = this.safeString(params, 'from', '6');
            request['to'] = this.safeString(params, 'to', '6');
        }
        const response = await this.privatePostAssetTransfer(this.extend(request, params));
        //
        //     {
        //         "code": "0",
        //         "msg": "",
        //         "data": [
        //             {
        //                 "transId": "754147",
        //                 "ccy": "USDT",
        //                 "from": "6",
        //                 "amt": "0.1",
        //                 "to": "18"
        //             }
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        const rawTransfer = this.safeValue(data, 0, {});
        return this.parseTransfer(rawTransfer, currency);
    }
    parseTransfer(transfer, currency = undefined) {
        //
        // transfer
        //
        //     {
        //         "transId": "754147",
        //         "ccy": "USDT",
        //         "from": "6",
        //         "amt": "0.1",
        //         "to": "18"
        //     }
        //
        // fetchTransfer
        //
        //     {
        //         "amt": "5",
        //         "ccy": "USDT",
        //         "from": "18",
        //         "instId": "",
        //         "state": "success",
        //         "subAcct": "",
        //         "to": "6",
        //         "toInstId": "",
        //         "transId": "464424732",
        //         "type": "0"
        //     }
        //
        // fetchTransfers
        //
        //     {
        //         "bal": "70.6874353780312913",
        //         "balChg": "-4.0000000000000000", // negative means "to funding", positive meand "from funding"
        //         "billId": "588900695232225299",
        //         "ccy": "USDT",
        //         "execType": "",
        //         "fee": "",
        //         "from": "18",
        //         "instId": "",
        //         "instType": "",
        //         "mgnMode": "",
        //         "notes": "To Funding Account",
        //         "ordId": "",
        //         "pnl": "",
        //         "posBal": "",
        //         "posBalChg": "",
        //         "price": "0",
        //         "subType": "12",
        //         "sz": "-4",
        //         "to": "6",
        //         "ts": "1686676866989",
        //         "type": "1"
        //     }
        //
        const id = this.safeString2(transfer, 'transId', 'billId');
        const currencyId = this.safeString(transfer, 'ccy');
        const code = this.safeCurrencyCode(currencyId, currency);
        let amount = this.safeNumber(transfer, 'amt');
        const fromAccountId = this.safeString(transfer, 'from');
        const toAccountId = this.safeString(transfer, 'to');
        const accountsById = this.safeValue(this.options, 'accountsById', {});
        const timestamp = this.safeInteger(transfer, 'ts', this.milliseconds());
        const balanceChange = this.safeString(transfer, 'sz');
        if (balanceChange !== undefined) {
            amount = this.parseNumber(Precise.stringAbs(balanceChange));
        }
        return {
            'info': transfer,
            'id': id,
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'currency': code,
            'amount': amount,
            'fromAccount': this.safeString(accountsById, fromAccountId),
            'toAccount': this.safeString(accountsById, toAccountId),
            'status': this.parseTransferStatus(this.safeString(transfer, 'state')),
        };
    }
    parseTransferStatus(status) {
        const statuses = {
            'success': 'ok',
        };
        return this.safeString(statuses, status, status);
    }
    async fetchTransfer(id, code = undefined, params = {}) {
        await this.loadMarkets();
        const request = {
            'transId': id,
            // 'type': 0, // default is 0 transfer within account, 1 master to sub, 2 sub to master
        };
        const response = await this.privateGetAssetTransferState(this.extend(request, params));
        //
        //     {
        //         "code": "0",
        //         "data": [
        //             {
        //                 "amt": "5",
        //                 "ccy": "USDT",
        //                 "from": "18",
        //                 "instId": "",
        //                 "state": "success",
        //                 "subAcct": "",
        //                 "to": "6",
        //                 "toInstId": "",
        //                 "transId": "464424732",
        //                 "type": "0"
        //             }
        //         ],
        //         "msg": ""
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        const transfer = this.safeValue(data, 0);
        return this.parseTransfer(transfer);
    }
    async fetchTransfers(code = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchTransfers
         * @description fetch a history of internal transfers made on an account
         * @see https://www.okx.com/docs-v5/en/#trading-account-rest-api-get-bills-details-last-3-months
         * @param {string} code unified currency code of the currency transferred
         * @param {int} [since] the earliest time in ms to fetch transfers for
         * @param {int} [limit] the maximum number of transfers structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object[]} a list of [transfer structures]{@link https://docs.ccxt.com/#/?id=transfer-structure}
         */
        await this.loadMarkets();
        let currency = undefined;
        const request = {
            'type': '1', // https://www.okx.com/docs-v5/en/#rest-api-account-get-bills-details-last-3-months
        };
        if (code !== undefined) {
            currency = this.currency(code);
            request['ccy'] = currency['id'];
        }
        if (since !== undefined) {
            request['begin'] = since;
        }
        if (limit !== undefined) {
            request['limit'] = limit;
        }
        const response = await this.privateGetAccountBillsArchive(this.extend(request, params));
        //
        //    {
        //        "code": "0",
        //        "data": [
        //            {
        //                "bal": "70.6874353780312913",
        //                "balChg": "-4.0000000000000000",
        //                "billId": "588900695232225299",
        //                "ccy": "USDT",
        //                "execType": "",
        //                "fee": "",
        //                "from": "18",
        //                "instId": "",
        //                "instType": "",
        //                "mgnMode": "",
        //                "notes": "To Funding Account",
        //                "ordId": "",
        //                "pnl": "",
        //                "posBal": "",
        //                "posBalChg": "",
        //                "price": "0",
        //                "subType": "12",
        //                "sz": "-4",
        //                "to": "6",
        //                "ts": "1686676866989",
        //                "type": "1"
        //            },
        //            ...
        //        ],
        //        "msg": ""
        //    }
        //
        const transfers = this.safeValue(response, 'data', []);
        return this.parseTransfers(transfers, currency, since, limit, params);
    }
    sign(path, api = 'public', method = 'GET', params = {}, headers = undefined, body = undefined) {
        const isArray = Array.isArray(params);
        const request = '/api/' + this.version + '/' + this.implodeParams(path, params);
        const query = this.omit(params, this.extractParams(path));
        let url = this.implodeHostname(this.urls['api']['rest']) + request;
        // const type = this.getPathAuthenticationType (path);
        if (api === 'public') {
            if (Object.keys(query).length) {
                url += '?' + this.urlencode(query);
            }
        }
        else if (api === 'private') {
            this.checkRequiredCredentials();
            if (this.token) {
                headers = {
                    'Authorization': 'Bearer ' + this.token,
                    'Content-Type': 'application/json',
                    'TERMID': this.termId,
                };
                if (method !== 'GET') {
                    if (Object.keys(query).length) {
                        body = this.json(query);
                    }
                }
                else {
                    if (Object.keys(query).length) {
                        url += '?' + this.urlencode(query);
                    }
                }
            }
            else {
                // inject id in implicit api call
                if (method === 'POST' && (path === 'trade/batch-orders' || path === 'trade/order-algo' || path === 'trade/order')) {
                    const brokerId = this.safeString(this.options, 'brokerId', 'e847386590ce4dBC');
                    if (Array.isArray(params)) {
                        for (let i = 0; i < params.length; i++) {
                            const entry = params[i];
                            const clientOrderId = this.safeString(entry, 'clOrdId');
                            if (clientOrderId === undefined) {
                                entry['clOrdId'] = brokerId + this.uuid16();
                                entry['tag'] = brokerId;
                                params[i] = entry;
                            }
                        }
                    }
                    else {
                        const clientOrderId = this.safeString(params, 'clOrdId');
                        if (clientOrderId === undefined) {
                            params['clOrdId'] = brokerId + this.uuid16();
                            params['tag'] = brokerId;
                        }
                    }
                }
                const timestamp = this.iso8601(this.nonce());
                headers = {
                    'OK-ACCESS-KEY': this.apiKey,
                    'OK-ACCESS-PASSPHRASE': this.password,
                    'OK-ACCESS-TIMESTAMP': timestamp,
                    // 'OK-FROM': '',
                    // 'OK-TO': '',
                    // 'OK-LIMIT': '',
                };
                let auth = timestamp + method + request;
                if (method === 'GET') {
                    if (Object.keys(query).length) {
                        const urlencodedQuery = '?' + this.urlencode(query);
                        url += urlencodedQuery;
                        auth += urlencodedQuery;
                    }
                }
                else {
                    if (isArray || Object.keys(query).length) {
                        body = this.json(query);
                        auth += body;
                    }
                    headers['Content-Type'] = 'application/json';
                }
                const signature = this.hmac(this.encode(auth), this.encode(this.secret), sha256, 'base64');
                headers['OK-ACCESS-SIGN'] = signature;
            }
        }
        return { 'url': url, 'method': method, 'body': body, 'headers': headers };
    }
    async fetchDepositWithdrawFees(codes = undefined, params = {}) {
        /**
         * @method
         * @name okx#fetchDepositWithdrawFees
         * @description fetch deposit and withdraw fees
         * @see https://www.okx.com/docs-v5/en/#rest-api-funding-get-currencies
         * @param {string[]|undefined} codes list of unified currency codes
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object[]} a list of [fees structures]{@link https://docs.ccxt.com/#/?id=fee-structure}
         */
        await this.loadMarkets();
        const response = await this.privateGetAssetCurrencies(params);
        //
        //    {
        //        "code": "0",
        //        "data": [
        //            {
        //                "canDep": true,
        //                "canInternal": false,
        //                "canWd": true,
        //                "ccy": "USDT",
        //                "chain": "USDT-TRC20",
        //                "logoLink": "https://static.coinall.ltd/cdn/assets/imgs/221/5F74EB20302D7761.png",
        //                "mainNet": false,
        //                "maxFee": "1.6",
        //                "maxWd": "8852150",
        //                "minFee": "0.8",
        //                "minWd": "2",
        //                "name": "Tether",
        //                "usedWdQuota": "0",
        //                "wdQuota": "500",
        //                "wdTickSz": "3"
        //            },
        //            {
        //                "canDep": true,
        //                "canInternal": false,
        //                "canWd": true,
        //                "ccy": "USDT",
        //                "chain": "USDT-ERC20",
        //                "logoLink": "https://static.coinall.ltd/cdn/assets/imgs/221/5F74EB20302D7761.png",
        //                "mainNet": false,
        //                "maxFee": "16",
        //                "maxWd": "8852150",
        //                "minFee": "8",
        //                "minWd": "2",
        //                "name": "Tether",
        //                "usedWdQuota": "0",
        //                "wdQuota": "500",
        //                "wdTickSz": "3"
        //            },
        //            ...
        //        ],
        //        "msg": ""
        //    }
        //
        const data = this.safeValue(response, 'data');
        return this.parseDepositWithdrawFees(data, codes);
    }
    parseDepositWithdrawFees(response, codes = undefined, currencyIdKey = undefined) {
        //
        // [
        //   {
        //       "canDep": true,
        //       "canInternal": false,
        //       "canWd": true,
        //       "ccy": "USDT",
        //       "chain": "USDT-TRC20",
        //       "logoLink": "https://static.coinall.ltd/cdn/assets/imgs/221/5F74EB20302D7761.png",
        //       "mainNet": false,
        //       "maxFee": "1.6",
        //       "maxWd": "8852150",
        //       "minFee": "0.8",
        //       "minWd": "2",
        //       "name": "Tether",
        //       "usedWdQuota": "0",
        //       "wdQuota": "500",
        //       "wdTickSz": "3"
        //   }
        // ]
        //
        const depositWithdrawFees = {};
        codes = this.marketCodes(codes);
        for (let i = 0; i < response.length; i++) {
            const feeInfo = response[i];
            const currencyId = this.safeString(feeInfo, 'ccy');
            const code = this.safeCurrencyCode(currencyId);
            if ((codes === undefined) || (this.inArray(code, codes))) {
                const depositWithdrawFee = this.safeValue(depositWithdrawFees, code);
                if (depositWithdrawFee === undefined) {
                    depositWithdrawFees[code] = this.depositWithdrawFee({});
                }
                depositWithdrawFees[code]['info'][currencyId] = feeInfo;
                const chain = this.safeString(feeInfo, 'chain');
                const chainSplit = chain.split('-');
                const networkId = this.safeValue(chainSplit, 1);
                const withdrawFee = this.safeNumber(feeInfo, 'minFee');
                const withdrawResult = {
                    'fee': withdrawFee,
                    'percentage': (withdrawFee !== undefined) ? false : undefined,
                };
                const depositResult = {
                    'fee': undefined,
                    'percentage': undefined,
                };
                const networkCode = this.networkIdToCode(networkId, code);
                depositWithdrawFees[code]['networks'][networkCode] = {
                    'withdraw': withdrawResult,
                    'deposit': depositResult,
                };
            }
        }
        const depositWithdrawCodes = Object.keys(depositWithdrawFees);
        for (let i = 0; i < depositWithdrawCodes.length; i++) {
            const code = depositWithdrawCodes[i];
            const currency = this.currency(code);
            depositWithdrawFees[code] = this.assignDefaultDepositWithdrawFees(depositWithdrawFees[code], currency);
        }
        return depositWithdrawFees;
    }
    handleErrors(httpCode, reason, url, method, headers, body, response, requestHeaders, requestBody) {
        if (!response) {
            return undefined; // fallback to default error handler
        }
        //
        //    {
        //        "code": "1",
        //        "data": [
        //            {
        //                "clOrdId": "",
        //                "ordId": "",
        //                "sCode": "51119",
        //                "sMsg": "Order placement failed due to insufficient balance. ",
        //                "tag": ""
        //            }
        //        ],
        //        "msg": ""
        //    },
        //    {
        //        "code": "58001",
        //        "data": [],
        //        "msg": "Incorrect trade password"
        //    }
        //
        const code = this.safeString(response, 'code');
        if ((code !== '0') && (code !== '2')) { // 2 means that bulk operation partially succeeded
            const data = this.safeValue(response, 'data', []);
            let message;
            let userMessage;
            for (let i = 0; i < data.length; i++) {
                const error = data[i];
                const errorCode = this.safeString(error, 'sCode');
                message = this.safeString(error, 'sMsg');
                userMessage = errorCode + ': ' + message;
                this.throwExactlyMatchedException(this.exceptions['exact'], errorCode, userMessage);
                this.throwBroadlyMatchedException(this.exceptions['broad'], message, userMessage);
            }
            message = this.safeString(response, 'msg');
            userMessage = code + ': ' + message;
            this.throwExactlyMatchedException(this.exceptions['exact'], code, userMessage);
            throw new ExchangeError().addMessage(message).exchange(this.id); // unknown message
        }
        return undefined;
    }
}
