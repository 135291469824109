import { MemoryRouter } from "react-router-dom";
import { SendWidgetProps, TradeWidgetProps } from "@cede/types";
import { DependenciesProvider } from "../contexts/dependencies/provider";
import { useCustomTheme } from "../hooks/useCustomTheme";
import { CssBaseline, ThemeProvider as MUIThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const Providers = <T extends Record<string, unknown> & { widgetProps: SendWidgetProps | TradeWidgetProps }>({
  children,
  dependencies,
}: {
  children: React.ReactNode;
  dependencies: T;
}) => {
  const theme = useCustomTheme(dependencies);

  return (
    <QueryClientProvider client={queryClient}>
      <DependenciesProvider dependencies={dependencies}>
        <MemoryRouter>
          <MUIThemeProvider theme={theme}>
            <CssBaseline />
            {children}
          </MUIThemeProvider>
        </MemoryRouter>
      </DependenciesProvider>
    </QueryClientProvider>
  );
};
