import format from "./formatNumber";

const MAX_DIGITS = 10;
const MAX_DECIMALS = 8;

/**
 * Formats a number to a string with a limited number of decimals, and spaces for thousands
 *
 * @param {number} balance - The number to format
 * @param {object} [options] - Additional options
 *     param {boolean} [roundForFiat=false] - Whether to only show 2 decimals for fiat currencies
 *     param {number} [maxDigits=MAX_DIGITS] - The maximum number of digits to display
 *     param {boolean} [canHideNumberOverMaxDigits=false] - If true, numbers over maxDigits or after the first non-digit will be hidden with a > sign
 * @returns {string}
 */
export const formatBalance = (
  balance: number | string,
  options?: {
    roundForFiat?: boolean;
    maxDigits?: number;
    hideBalance?: boolean;
    keepExponential?: boolean;
    canHideNumberOverMaxDigits?: boolean;
  },
): string => {
  // cast balance to number if needed
  balance = typeof balance === "string" ? Number(balance) : balance;
  const hideBalance = options?.hideBalance ?? false;
  const roundForFiat = options?.roundForFiat ?? false;
  const maxDigits = options?.maxDigits ?? MAX_DIGITS;
  const keepExponential = options?.keepExponential ?? false;
  const canHideNumberOverMaxDigits = options?.canHideNumberOverMaxDigits ?? false;

  const value = Math.abs(balance);
  const sign = balance < 0 ? "-" : "";

  // Avoid scientific notation for very small numbers
  if (value < 0.000001 && value > 0 && !canHideNumberOverMaxDigits) {
    return sign + (keepExponential ? value : value.toFixed(MAX_DECIMALS));
  }
  // Force scientific notation for very large numbers
  if (value > 9999999999) {
    return sign + value.toPrecision(MAX_DECIMALS);
  }

  if (value < 0.09 && canHideNumberOverMaxDigits) {
    const decimalsNumber = value.toString().split("-")[1];
    const formattedValue = value.toFixed(decimalsNumber ? parseInt(decimalsNumber) + 1 : value.toString().length - 1);
    const firstNonZeroDigit = formattedValue.match(/[1-9]/);

    if (firstNonZeroDigit && firstNonZeroDigit.index && formattedValue[firstNonZeroDigit.index + 1] !== "0") {
      // Include all zeros before the first non-zero digit
      const zerosBeforeNonZero = `0.${"0".repeat(firstNonZeroDigit.index - 2)}`;

      return `>${zerosBeforeNonZero}${firstNonZeroDigit[0]}`;
    }
  }

  if (hideBalance) return "****";

  const round = (() => {
    if (roundForFiat) return 2;
    if (value > 10 ** (maxDigits - 1)) return 0;
    return MAX_DECIMALS;
  })();

  const params = {
    decimal: ".",
    integerSeparator: " ",
  };

  const formattedValue = format({ round, ...params })(value);

  const int = (formattedValue.split(".")[0] || "").replace(/\s/g, "");

  const diff = maxDigits - int.length;

  return sign + format({ round: diff >= 0 ? diff : 0, ...params })(Number(formattedValue.replace(/\s/g, "")));
};

export function unformatBalance(balance: string): number {
  return parseFloat(balance.replace(/\s/g, "") || "0");
}
