import { EmptyStateProps, EmptyStateTypeEnum } from "./EmptyState.types";
import { Alert, Typography } from "@mui/material";
import { Box } from "@mui/material";

export const EmptyState = ({ icon, title, description, type, actionButton }: EmptyStateProps) => {
  // const theme = useTheme();

  const emptyStateType = (() => {
    if (type === EmptyStateTypeEnum.SUCCESS) {
      return "success";
    }
    if (type === EmptyStateTypeEnum.INFO) {
      return "info";
    }
    if (type === EmptyStateTypeEnum.ERROR) {
      return "error";
    }
    if (type === EmptyStateTypeEnum.WARNING) {
      return "warning";
    }
    return "warning";
  })();

  return (
    <Alert
      variant="outlined"
      icon={false}
      color={emptyStateType}
      sx={(theme) => ({
        "display": "flex",
        ".MuiAlert-message": {
          display: "flex",
          flex: 1,
          flexDirection: "column",
          gap: theme.custom.smallGutterMargin,
        },
      })}
    >
      {icon && (
        <Box
          sx={() => ({
            width: "100%",
            flex: 1,
          })}
        >
          {icon}
        </Box>
      )}
      <Typography
        variant="h4"
        sx={(theme) => ({
          fontSize: theme.typography.body1,
          marginBottom: 0,
          fontWeight: theme.typography.fontWeightBold,
        })}
      >
        {title}
      </Typography>
      {description && (
        <Typography
          variant="body1"
          sx={(theme) => ({
            fontSize: theme.typography.body1,
            marginBottom: 0,
            fontWeight: theme.typography.fontWeightMedium,
          })}
        >
          {description}
        </Typography>
      )}
      {actionButton && actionButton}
    </Alert>
  );
};
