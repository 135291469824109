export const tweets = {
  TRADE:
    "Hey Frens! Just made a trade with my {cexLabel} asset via cede.store! Huge shoutout to @cedelabs for the seamless flow! 🚀 #CeFi #Crypto #UnlockingLiquidity",
  SEND_WITH_PARTNER:
    "Hey Frens! I just moved my {cexLabel} assets to my {network} wallet thanks to cede.store! Shoutout to @cedelabs and {partner} for fast CEX to DeFi transfers! 🚀 #DeFi #Crypto",
  SEND_WITHOUT_PARTNER:
    "Hey Frens! I just moved my {cexLabel} assets to my {network} wallet thanks to cede.store! Shoutout to @cedelabs for fast CEX to DeFi transfers! 🚀 #DeFi #Crypto",
  REFERRAL:
    'Hey Frens! Install @cedelabs to track and manage all your CeFi assets ! Use my referral code "{referralCode}" for a special reward! 🚀 #CeFi #Crypto',
};

/** Used to identify twitter handles for networks in the config @example network_twitter_handle:ethereum */
export const NETWORK_TWITTER_HANDLE_CONFIG_IDENTIFIER = "network_twitter_handle:";

export enum TweetDataKey {
  CEX_LABEL = "cexLabel",
  NETWORK = "network",
  PARTNER = "partner",
}

export const POST_ON_TWITTER_URL = "https://twitter.com/intent/tweet?text=";
