import { useMemo } from "react";
import { AddressBookItem, DropListWithIconItem } from "@cede/types";
import { QueryKeys, STORE_ROUTES } from "@cede/utils";
import { useDependencies } from "../hooks/useDependencies";
import { useQuery } from "@tanstack/react-query";

export const useAddressBook = () => {
  const { backgroundHandler, useNavigate, useGlobalMenu } = useDependencies();
  const setIsOpen = useGlobalMenu((state) => state.setIsOpen);
  const navigate = typeof useNavigate === "function" ? useNavigate() : () => undefined;

  const {
    data: addressBook,
    refetch,
    isLoading,
    isError,
  } = useQuery<AddressBookItem[], Error>({
    queryKey: [QueryKeys.ADDRESS_BOOK],
    queryFn: async () => {
      if (!backgroundHandler?.isReady) return [];
      const addressBook = await backgroundHandler.getAddressBook();
      return addressBook;
    },
    enabled: !!backgroundHandler?.isReady,
    staleTime: 0,
    gcTime: 0,
  });

  const droplistAddressBook: DropListWithIconItem[] = useMemo(() => {
    if (!addressBook) return [];

    return addressBook.map<DropListWithIconItem>((book) => {
      return {
        img: "",
        name: book.name,
        value: book.address,
        label: book.name,
      };
    });
  }, [addressBook]);

  const goBack = () => {
    navigate(-1);
    setIsOpen(true);
  };

  const addAddress = () => {
    navigate(STORE_ROUTES.ADD_ADDRESS_ROUTE, { state: { mode: "add" } });
  };

  const modifyAddress = (item: AddressBookItem) => {
    navigate(STORE_ROUTES.ADD_ADDRESS_ROUTE, { state: { item, mode: "edit" } });
  };

  return {
    addressBook,
    isAddressBookLoading: isLoading,
    isAddressBookError: isError,
    droplistAddressBook,
    goBack,
    addAddress,
    modifyAddress,
    refetchAddressBook: refetch,
  };
};
