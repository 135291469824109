import { Any, CedeError } from "@cede/types";

export type ExtractedError = {
  code: number;
  message: string;
  documentation: string;
};

export type CedeExposedErrors = {
  [key: string]: ExtractedError | CedeExposedErrors;
};

export type AnyErrorObject = {
  [key: string]: CedeError | AnyErrorObject | number | string;
};

export const sanitizeErrors = (errorObj: AnyErrorObject): CedeExposedErrors => {
  const flattened: CedeExposedErrors = {};

  function recursiveExtract(obj: AnyErrorObject, parentKey = "") {
    for (const key in obj) {
      const currentObj = obj[key];
      const fullKey = parentKey ? `${parentKey}.${key}` : key;

      if (
        typeof currentObj === "object" &&
        "code" in currentObj &&
        typeof currentObj.code === "number" &&
        typeof currentObj.documentation === "string" &&
        typeof currentObj.message === "string"
      ) {
        flattened[fullKey] = {
          code: currentObj.code,
          message: currentObj.message,
          documentation: currentObj.documentation,
        };
      } else if (typeof currentObj === "object") {
        recursiveExtract(currentObj, fullKey);
      }
    }
  }

  recursiveExtract(errorObj);

  return flattened;
};

export function flattenErrors(obj: Any, result: CedeError[] = []): CedeError[] {
  if (obj && typeof obj === "object") {
    if (
      obj.code !== undefined &&
      obj.message !== undefined &&
      obj.description !== undefined &&
      obj.documentation !== undefined
    ) {
      result.push(obj);
    } else {
      for (const key in obj) {
        flattenErrors(obj[key], result);
      }
    }
  }
  return result;
}
