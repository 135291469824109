import { userFacingMessages } from "@cede/types";
import { CurrencyAmountFormatter } from "../../CurrencyAmountFormatter";
import { Icon } from "../../Icon";
import { DropListWithIconItemElement } from "../types";
import { alpha, Box, Skeleton, Typography } from "@mui/material";

export const AmountDropListWithIconItemElement: DropListWithIconItemElement<{
  amount?: number;
  balance?: number;
  isLoading?: boolean;
  isDisabled?: boolean;
  disabledText?: string;
}> = ({ setOpen, theme, shouldDisableItem, disabledTextItem, ...props }, option) => {
  const isDisabled = option.isDisabled || shouldDisableItem?.(option);

  return (
    <Box
      component="li"
      display={"flex"}
      alignItems={"center"}
      paddingX={theme.custom.gutterMargin}
      paddingY={theme.custom.smallGutterMargin}
      {...props}
      onClick={(e) => {
        if (isDisabled) return;

        props.onClick && props.onClick(e);
        setOpen(false);
      }}
      sx={{
        "& > img": { mr: 2, flexShrink: 0 },
        '&[aria-selected="true"]': {
          backgroundColor: alpha(theme.palette.background.default, 0.3) + "!important",
        },
        "cursor": isDisabled ? "not-allowed" : "pointer",
      }}
    >
      {option.img && (
        <Box
          display={"inline-flex"}
          marginRight={theme.custom.smallGutterMargin}
          sx={{
            filter: isDisabled ? "grayscale(1)" : "none",
          }}
        >
          <Icon iconUrl={option.img} iconName={option.label} iconSize={"medium"} />
        </Box>
      )}
      <Box
        flex="1"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        color={!isDisabled ? theme.palette.text.primary : theme.palette.text.disabled}
      >
        <Typography
          sx={
            isDisabled
              ? {
                  textWrap: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: theme.palette.text.disabled,
                  maxWidth: "135px",
                  filter: isDisabled ? "grayscale(1)" : "none",
                }
              : {
                  color: theme.palette.text.primary,
                }
          }
          title={option.label}
        >
          {option.label}
        </Typography>
        <Box display={"flex"} flexDirection="column" alignItems={"flex-end"}>
          {option.isLoading && !isDisabled && <Skeleton variant="text" sx={{ width: "50px" }} />}
          {option.balance && !option.isLoading && (
            <Typography>
              {option.balance} {option.label}
            </Typography>
          )}
          {option.amount !== undefined && !option.isLoading && !isDisabled && (
            <CurrencyAmountFormatter
              amount={option.amount}
              colors={{
                integer: theme.palette.text.primary,
                decimal: theme.palette.text.primary,
              }}
              currency={{ symbol: "USD", rate: 1 }}
              size="small"
            />
          )}
          {isDisabled && (
            <Typography color={!isDisabled ? theme.palette.text.primary : theme.palette.text.disabled}>
              {option.disabledText ||
                (disabledTextItem
                  ? disabledTextItem(option)
                  : userFacingMessages.EXCHANGES_STATUSES.TEMPORARILY_UNAVAILABLE)}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
