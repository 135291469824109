import React from "react";
import { ViewEnvironment } from "@cede/types";
import { useDependencies } from "../../hooks";
import { AppBar, Box, useTheme } from "@mui/material";

interface Props {
  leftSide?: React.ReactElement | null;
  center?: React.ReactElement | null;
  rightSide?: React.ReactElement | null;
  isDisplayed: boolean;
}

export const NavBar = ({ leftSide = null, center = null, rightSide = null, isDisplayed }: Props) => {
  const { useViewEnvironment } = useDependencies();
  const theme = useTheme();
  const { environment: viewEnvironment } = useViewEnvironment();

  return (
    <>
      {isDisplayed && (
        <AppBar
          sx={{
            backgroundColor: "transparent",
            backgroundImage: "none",
            position: "sticky",
            top: "0",
            left: "0",
            width: "100%",
            boxShadow: "none",
            paddingX: theme.custom.gutterMargin,
          }}
        >
          <Box
            height={
              viewEnvironment === ViewEnvironment.WIDGET
                ? theme.custom.navBarSize.widgetHeight
                : theme.custom.navBarSize.height
            }
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr auto 1fr",
            }}
          >
            <Box display="flex" alignItems="center">
              {leftSide}
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              {center}
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              // On widget we have the help icon on every screens
              pr={viewEnvironment === ViewEnvironment.WIDGET ? "24px" : "0"}
            >
              {rightSide}
            </Box>
          </Box>
        </AppBar>
      )}
    </>
  );
};
