import { forwardRef } from "react";
import { Any } from "@cede/types";
import { BubbleProps } from "./types";
import { Box, Popper, useTheme } from "@mui/material";

export const Bubble = forwardRef<HTMLDivElement, BubbleProps>(
  (
    {
      children,
      isOpen,
      testId,
      id,
      anchor,
      placement = "top",
      sx = {},
      background,
      hideArrow,
      onMouseOut,
      disablePortal,
    },
    ref,
  ) => {
    const theme = useTheme();
    const popperBackground = background || theme.palette.cedeBubbleBackgroundColor;

    if (!anchor) return null;
    return (
      <Popper
        ref={ref}
        placement={placement}
        id={id}
        open={isOpen}
        anchorEl={anchor}
        sx={[{ padding: "10px", zIndex: 1 }, sx as Any]}
        data-testid={testId}
        onMouseOut={onMouseOut}
        disablePortal={disablePortal}
      >
        <Box
          sx={{
            paddingX: theme.custom.smallGutterMargin,
            paddingY: `calc(${theme.custom.smallGutterMargin} / 2)`,
            borderRadius: theme.custom.defaultBoxRadius,
            background: popperBackground,
            position: "relative",
            display: "flex",
            transform: placement?.includes("bottom") ? "rotate(180deg)" : "rotate(0)",
          }}
        >
          <Box
            display={"inline-flex"}
            sx={{
              transform: placement?.includes("bottom") ? "rotate(180deg)" : "rotate(0)",
            }}
          >
            {children}
          </Box>
          {!hideArrow && (
            <Box
              sx={{
                background: popperBackground,
                clipPath: "polygon(0% 0, 100% 0%, 50% 100%)",
                width: "12px",
                height: "6px",
                position: "absolute",
                bottom: "-6px",
                left: "calc(50% - 6px)",
              }}
            />
          )}
        </Box>
      </Popper>
    );
  },
);
