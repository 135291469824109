import { useEffect, useMemo } from "react";
import ReactMarkdown from "react-markdown";
import { AddressDescription, CopyBox, ExternalLink, SpinnerLoader, theme } from "@cede/ds";
import { Any, ApiPermissions } from "@cede/types";
import {
  buildStringWithRequirements,
  checkRequiredPermissionStatement,
  extractPages,
  hasPermissionsWithProxy,
  hasPermissionsWithoutProxy,
  makeFirstLettersCapital,
  redirectInBrowser,
} from "@cede/utils";
import { useDependencies } from "../../../hooks";
import { ExtensionImage } from "../../ExtensionImage/ExtensionImage";
import { ExtensionImageProps } from "../../ExtensionImage/types";
import { TutorialAffiliation } from "../TutorialAffiliation/TutorialAffiliation";
import { TutorialBox } from "../TutorialBox/TutorialBox";
import { TutorialInfo } from "../TutorialInfo/TutorialInfo";
import { TutorialList } from "../TutorialList/TutorialList";
import { TutorialModal } from "../TutorialModal/TutorialModal";
import { TutorialModalProps } from "../TutorialModal/types";
import { TutorialVideoCard } from "../TutorialVideoCard/TutorialVideoCard";
import { ActionProps, TutorialReaderProps, UseTutoModalManagerState } from "./types";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { Box, CardActionArea, Divider, Theme, Typography, useTheme } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import rehypeRaw from "rehype-raw";
import { create } from "zustand";

const DEFAULT_DATA = {};
const DEFAULT_ACTION_PROPS = {};
const REHYPE_PLUGINS = [rehypeRaw] as Any[];

// Manage the active modal
const useTutoModalManager = create<UseTutoModalManagerState>((set) => ({
  activeModal: null,
  setActiveModal: (activeModal: string | null) => set({ activeModal }),
}));

// Wrap the TutorialModal to manage the active modal
const WrappedTutorialModal = (props: TutorialModalProps & { id: string }) => {
  const { activeModal, setActiveModal } = useTutoModalManager();
  return <TutorialModal {...props} onClose={() => setActiveModal(null)} open={props.id === activeModal} />;
};

// Wrap the ExtensionImage to manage the active modal
const WrappedExtensionImage = (
  props: ExtensionImageProps & { modal?: string; type?: string; data: Record<string, string | number>; width?: string },
) => {
  const { setActiveModal } = useTutoModalManager();
  if (!props.modal && !props.type) {
    return <ExtensionImage {...props} />;
  }
  // Specific case for Coinbase
  else if (props.type === "allowedValueToSend") {
    return (
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Typography
          sx={{
            zIndex: 999,
            position: "absolute",
            bottom: "25%",
            left: "15%",
            color: theme.palette.common.black,
            fontSize: theme.custom.textSize.medium,
          }}
        >
          {props.data["allowedValueToSend"]}
        </Typography>
        <ExtensionImage {...props} />
      </Box>
    );
  }
  return (
    <CardActionArea onClick={() => setActiveModal(props.modal as string)}>
      <ExtensionImage {...props} />
    </CardActionArea>
  );
};

// Render children as a string or as a component
function renderChildren(children: Any) {
  return children.map((child: Any, index: number) => {
    if (typeof child === "string") {
      return (
        <Typography key={child.toString() + `${index}`} component={"p"} sx={{ display: "inline" }}>
          {child}
        </Typography>
      );
    }

    return child;
  });
}

function divTransformer(
  componentType: string,
  properties: Record<string, Any>,
  theme: Theme,
  permissions: ApiPermissions[],
) {
  // Check the permissions of the current process to display the section or not
  if (properties.require && !checkRequiredPermissionStatement(properties.require, permissions)) return null;
  switch (componentType) {
    case "section": {
      const title = buildStringWithRequirements(properties.title, permissions);
      return (
        <TutorialBox title={title} help={properties.help}>
          {renderChildren(properties.children)}
        </TutorialBox>
      );
    }
    case "info": {
      return <TutorialInfo>{renderChildren(properties.children)}</TutorialInfo>;
    }
    case "video": {
      return (
        <TutorialVideoCard title={properties.children.join("")} onClick={() => redirectInBrowser(properties.url)} />
      );
    }
    case "copy": {
      return (
        <Box marginTop={theme.custom.smallGutterMargin}>
          <CopyBox value={properties.value}>{renderChildren(properties.children)}</CopyBox>
        </Box>
      );
    }
    case "modal": {
      return <WrappedTutorialModal data-testid="tutorial-modal" {...(properties as Any)} />;
    }
    case "affiliation": {
      return <TutorialAffiliation {...(properties as Any)}></TutorialAffiliation>;
    }
    case "divider": {
      return (
        <Divider
          sx={{
            marginY: "1rem",
            color: theme.palette.cedeComponentHr,
          }}
        />
      );
    }
    case "withBackground": {
      return (
        <Box
          sx={{
            "paddingY": theme.custom.smallGutterMargin,
            "paddingX": theme.custom.smallGutterMargin,
            "marginTop": theme.custom.smallGutterMargin,
            "backgroundColor": theme.palette.background.lightPaper,
            "borderRadius": theme.custom.mediumBoxRadius,
            "color": `${theme.palette.text.primary} !important`,
            "textTransform": "none",
            "gap": theme.custom.smallGutterMargin,
            "fontSize": theme.custom.textSize.medium,
            "overflowX": "scroll",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {renderChildren(properties.children)}
        </Box>
      );
    }
    default: {
      return <div {...properties} />;
    }
  }
}

export function reactMarkdownComponents(
  theme: Theme,
  folder: string,
  permissions: ApiPermissions[],
  accountPermissions: ApiPermissions[],
  exchangeId: string,
  actionProps: ActionProps,
  data: Record<string, string | number> = {},
  requireTwoKeys: boolean,
  onLinkClick?: (url: string, trackedType: string | undefined) => void,
) {
  const cex = exchangeId.toLowerCase();

  return {
    div: ({ node, ...props }: Any) => {
      const componentType = props.type;
      const identifier = props.identifier || "";
      const actions = actionProps[identifier];

      // Used for nested elements to handle the componentType and the properties
      if (!componentType) {
        const properties = node.children[0] || {};
        const { type, ...rest } = properties;

        return divTransformer(type, { ...rest, ...actions, children: props.children }, theme, permissions);
      }

      return divTransformer(
        componentType,
        {
          ...props,
          ...actions,
        },
        theme,
        permissions,
      );
    },
    a: (props: Any) => {
      if (data[props.custom]) {
        return (
          <ExternalLink
            label={props.children.join("")}
            url={String(data[props.custom])}
            onClick={() => onLinkClick?.(String(data[props.custom]), props.tracked)}
          />
        );
      } else {
        return (
          <ExternalLink
            label={props.children.join("")}
            url={String(props.href)}
            onClick={() => onLinkClick?.(String(props.href), props.tracked)}
          />
        );
      }
    },
    img: (props: Any) => {
      // Check the permissions of the current process to display the image or not
      if (!checkRequiredPermissionStatement(props.require, permissions)) return null;

      return (
        <WrappedExtensionImage
          alt={props.alt}
          path={`tutorials/${folder}/` + props.src.replace("./", "")}
          width={props.width}
          canZoom={props.zoom}
          icon={props.icon}
          modal={props.modal}
          iconColor={props.iconcolor}
          type={props["data-type"]}
          data={data}
        />
      );
    },
    strong: (props: Any) => {
      return (
        <Typography
          component={"strong"}
          sx={{
            display: "inline",
            fontWeight: "bold",
            ...(props.color === "disabled"
              ? {
                  color: theme.palette.text.secondary,
                }
              : {}),
          }}
        >
          {props.children}
        </Typography>
      );
    },
    h1: (props: Any) => {
      return (
        <Typography
          component={"p"}
          fontSize="16px"
          sx={{
            display: "inline",
            ...(props.color === "disabled"
              ? {
                  color: theme.palette.text.secondary,
                }
              : {}),
          }}
          style={props.style || {}}
        >
          {props.children}
        </Typography>
      );
    },
    p: (props: Any) => {
      return (
        <Typography
          component={"p"}
          sx={{
            display: "inline",
            ...(props.color === "disabled"
              ? {
                  color: theme.palette.text.secondary,
                }
              : {}),
          }}
          style={props.style || {}}
        >
          {props.children}
        </Typography>
      );
    },
    span: (props: Any) => {
      if (props.type === "var") {
        let value = "";
        if (props.name === "permissions") {
          value = permissions.map((perm) => makeFirstLettersCapital(perm)).join("/");
        } else if (props.name === "step") {
          if (requireTwoKeys) {
            // Account does not exist
            if (accountPermissions.length === 0) {
              // If the permissions are proxyfied, we'll have another step (1/2), else it's (1/1)
              value = hasPermissionsWithProxy(permissions, cex) ? "(1/2)" : "(1/1)";
            } else {
              // Account exists
              // Permissions are both proxyfied and whitelisted, so it's the second step
              if (hasPermissionsWithProxy(permissions, cex) && hasPermissionsWithoutProxy(permissions, cex)) {
                value = "(2/2)";
                // We're just updating/adding a single key
              } else {
                value = "(1/1)";
              }
            }
          } else {
            value = "(1/1)";
          }
        } else if (props.name === "publicKey") {
          return (
            <Typography component={"span"} sx={{ display: "inline", color: theme.palette.text.primary }}>
              {data[props.name]}
            </Typography>
          );
        } else if (props.name === "address-description") {
          return <AddressDescription name={String(data.name)} address={String(data.address)} />;
        } else if (props.name === "address-verification") {
          switch (data.status) {
            case "waiting":
              return <SpinnerLoader />;
            case "success":
              return <CheckCircle color={"success"} style={{ width: "30px", height: "30px" }} />;
            case "failed":
              return <Cancel color={"error"} style={{ width: "30px", height: "30px" }} />;
            default:
              return <></>;
          }
        } else if (data[props.name]) {
          return (
            <Typography component={"span"} sx={{ display: "inline", color: theme.palette.primary.main }}>
              {data[props.name]}
            </Typography>
          );
        }

        if (!value) return null;
        return (
          <Typography component={"span"} sx={{ display: "inline" }}>
            {value}
          </Typography>
        );
      }
      return null;
    },
    ul: (props: Any) => {
      if (props.require && !checkRequiredPermissionStatement(props.require, permissions)) return null;
      return <TutorialList strippable={props.strippable}>{props.children}</TutorialList>;
    },
    ol: (props: Any) => {
      return (
        <Box
          component={"p"}
          sx={{ textAlign: "left", display: "flex", alignItems: "baseline", gap: theme.custom.smallGutterMargin }}
          {...props}
        >
          <Typography component={"p"}>{props.start ?? "1"}.</Typography>
          {props.children}
        </Box>
      );
    },
    i: (props: Any) => {
      return (
        <Typography component={"p"} sx={{ display: "inline", fontStyle: "italic" }}>
          {props.children}
        </Typography>
      );
    },
  };
}

export function TutorialReader({
  mdContent,
  currentPage = 0,
  setMaxPage,
  folder,
  permissions,
  accountPermissions,
  exchangeId,
  actionProps = DEFAULT_ACTION_PROPS,
  data = DEFAULT_DATA,
  ...props
}: TutorialReaderProps) {
  const { useSupportedExchanges, useBackgroundHandler } = useDependencies();
  const { backgroundHandler } = useBackgroundHandler();
  const theme = useTheme();
  const { supportedExchangesById } = useSupportedExchanges();
  const { mutateAsync: trackEvent } = useMutation({
    mutationFn: async (params: { link: string; exchangeId: string }) => {
      if (!backgroundHandler) return;

      return backgroundHandler.trackLink({ link: params.link, exchangeId: params.exchangeId });
    },
  });
  const { mutateAsync: openTabWithHelper } = useMutation({
    mutationFn: async (params: { link: string; exchangeId: string; permissions: ApiPermissions[] }) => {
      if (!backgroundHandler) return;

      return backgroundHandler.openPageWithHelper({ link: params.link, permissions, exchangeId: params.exchangeId });
    },
  });

  const onLinkClick = async (link: string, trackedType: string | undefined) => {
    if (!trackedType) return;

    event?.preventDefault();

    if (trackedType === "helper") {
      await openTabWithHelper({ link, exchangeId, permissions });
    } else {
      await trackEvent({ link, exchangeId });
    }
  };

  const pages = useMemo(() => {
    if (!mdContent) return [];
    return extractPages(mdContent, !setMaxPage ? true : false);
  }, [mdContent]);
  // mdContent, folder, exchangeId

  const components = useMemo(() => {
    const mdComponents = reactMarkdownComponents(
      theme,
      folder,
      permissions,
      accountPermissions,
      exchangeId,
      actionProps,
      data,
      supportedExchangesById[exchangeId]?.requiresTwoKeys ?? false,
      onLinkClick,
    );
    return mdComponents;
  }, [data, supportedExchangesById, exchangeId]);

  useEffect(() => {
    setMaxPage?.(pages.length - 1);
  }, [pages]);

  return (
    <Box
      display={"flex"}
      flexDirection="column"
      gap={"30px"}
      height="100%"
      {...props}
      sx={{
        "& > p:first-of-type, & > p:first-of-type span": {
          fontWeight: "bold",
        },
        ...props.sx,
      }}
    >
      <ReactMarkdown
        rehypePlugins={REHYPE_PLUGINS}
        components={components}
        children={typeof pages === "object" ? pages[currentPage] ?? "" : pages || ""}
      />
    </Box>
  );
}
