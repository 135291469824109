import { useCallback, useEffect, useRef, useState } from "react";
import { Any } from "@cede/types";

export const useDebounceFn = <T extends Any[]>(fn: (...args: T) => void, delay: number) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const callbackRef = useRef(fn);

  useEffect(() => {
    callbackRef.current = fn;

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [fn]);

  return useCallback(
    (...args: T) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        callbackRef.current(...args);
      }, delay);
    },
    [delay],
  );
};

export const useDebounceValue = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isDebouncing, setIsDebouncing] = useState(false);

  useEffect(() => {
    setIsDebouncing(true);
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
      setIsDebouncing(false);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay]);

  return { debouncedValue, isDebouncing };
};
