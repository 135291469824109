import { useEffect } from "react";
import { Button, ShareOnTwitter, useNavBar } from "@cede/ds";
import { TxStatus, ViewEnvironment, isCefiInfoTx, isDefiInfoTx } from "@cede/types";
import { CEDE_CEX_LABELS, TweetDataKey, WIDGET_ROUTES, tweets } from "@cede/utils";
import { HelpLink, Page } from "../..";
import { AccountLine } from "../../components/Transaction/AccountLine";
import { DateLine } from "../../components/Transaction/DateLine";
import { ErrorLine } from "../../components/Transaction/ErrorLine";
import { FeeLine } from "../../components/Transaction/FeeLine";
import { NetworkLine } from "../../components/Transaction/NetworkLine";
import { PriceLine } from "../../components/Transaction/PriceLine";
import { RecipientAddressLine } from "../../components/Transaction/RecipientAddressLine";
import { StatusLine } from "../../components/Transaction/StatusLine";
import { useDependencies } from "../../hooks/useDependencies";
import { isOnrampMoney } from "../CompletedTransaction/utils";
import { TransactionInformationsBlock } from "./ui/TransactionInformationsBlock";
import { TransactionStatus } from "./ui/TransactionStatus";
import { TxExplorerLink } from "./ui/TxExplorerLink";
import { Loop } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";

export const CompletedTransactionDetails = () => {
  const theme = useTheme();
  const { setIsDisplayed, setGoBack, setCenter, setRightSide } = useNavBar();
  const {
    useSelectedTransaction,
    useSendStore,
    useNavigate,
    useViewEnvironment,
    useTriggerUpdateTransaction,
    useShareOnTwitter,
  } = useDependencies();
  const transaction = useTriggerUpdateTransaction();
  const isReceive = useSelectedTransaction((state) => state.isReceive);
  const isTrade = useSelectedTransaction((state) => state.isTrade);
  const isSend = useSelectedTransaction((state) => state.isSend);
  const { setInitialProps } = useSendStore((state) => ({
    setInitialProps: state.setInitialProps,
  }));
  const navigate = useNavigate();
  const { environment } = useViewEnvironment();

  const goBack = () => {
    navigate(-1);
  };

  const { networkName, partnerName } = useShareOnTwitter(transaction);

  const transactionStatus = transaction?.from.transaction.status;

  const onRepeat = () => {
    if (transaction && isCefiInfoTx(transaction.from)) {
      setInitialProps({
        tokenSymbol: transaction?.from.transaction.tokenSymbol,
        network: transaction?.from.account.network ?? "",
        address: isDefiInfoTx(transaction.to) ? transaction?.to.account.address : "",
        amount: transaction?.from.transaction.amount.toString(),
        exchangeIds: [transaction.from.account.exchangeId],
      });
    }
    let path = `/${isTrade ? "trade" : "send"}`;

    if (environment === ViewEnvironment.WIDGET) {
      path = WIDGET_ROUTES.ACTION_ROUTE;
    }
    navigate(path);
  };

  const definePageTitle = () => {
    if (isTrade) return "Trade";
    if (isSend) return "Withdrawal";
    if (isReceive) return "Deposit";
    return "";
  };

  const getTweetContent = () => {
    if (isTrade) return tweets.TRADE;
    if (isSend) return partnerName ? tweets.SEND_WITH_PARTNER : tweets.SEND_WITHOUT_PARTNER;
    return "";
  };

  const getTweetMappingTable = () => {
    if (transaction && isCefiInfoTx(transaction.from)) {
      return {
        [TweetDataKey.CEX_LABEL]: CEDE_CEX_LABELS[transaction.from.account.exchangeId],
        [TweetDataKey.NETWORK]: networkName,
        [TweetDataKey.PARTNER]: partnerName,
      };
    }
    return {};
  };

  useEffect(() => {
    setIsDisplayed(true);
    setGoBack(goBack);
    setCenter(
      <Typography component="h1" variant="h3" margin={0}>
        {definePageTitle()}
      </Typography>,
    );

    return () => {
      setRightSide(null);
    };
  }, []);

  if (!transaction) return null;

  useEffect(() => {
    // @TODO: Implement this for the trades, including the store
    if (transaction.from.transaction.status === TxStatus.OK && isSend) {
      setRightSide(
        <Box display="flex" alignItems="center">
          <Button
            variant={"text"}
            sx={{
              display: "inline-flex",
              alignItems: "center",
              gap: theme.custom.smallGutterMargin,
              mr: theme.custom.smallGutterMargin,
            }}
            onClick={onRepeat}
          >
            <Loop
              sx={{
                width: theme.custom.iconSize.small,
                height: theme.custom.iconSize.small,
              }}
            />
            <Box component={"span"}>Repeat</Box>
          </Button>
          {environment !== ViewEnvironment.WIDGET && <HelpLink />}
        </Box>,
      );
    } else {
      if (environment !== ViewEnvironment.WIDGET) {
        setRightSide(<HelpLink />);
      }
    }
  }, [transaction.from.transaction.status, isSend, isTrade]);

  return (
    <Page displayFlex sx={{ overflowY: "scroll" }}>
      <Box>
        <TransactionStatus />

        <TransactionInformationsBlock>
          <AccountLine />
          <StatusLine />
          <ErrorLine />
          <DateLine />
        </TransactionInformationsBlock>

        <TransactionInformationsBlock>
          <PriceLine />
          <NetworkLine />
          <RecipientAddressLine />
        </TransactionInformationsBlock>

        <TransactionInformationsBlock>
          <FeeLine />
        </TransactionInformationsBlock>
      </Box>

      <TxExplorerLink />

      {(isTrade || isSend) &&
        (transactionStatus === TxStatus.OK || transactionStatus === TxStatus.PENDING) &&
        !isOnrampMoney && <ShareOnTwitter content={getTweetContent()} mappingTable={getTweetMappingTable()} />}
    </Page>
  );
};
