import {
  DetailedChain,
  DiscordInvitation,
  ExchangeNameStatus,
  ExchangeToStandarizedChainsMapping,
  FiatCurrencyMetadata,
  GasData,
  KrakenWithdrawalNetworkDto,
  MarketNetwork,
  Partner,
  SupportedTokensWithNetworks,
  TokenPriceResponse,
  TokenSymbolName,
  TradeLeaderBoardUser,
  TradePathBySupportedTokens,
  TradePathData,
  UserMultiplierState,
  UserRewardState,
  WalletTypesConfig,
} from "@cede/types";

export type TokenPrices = {
  [key: string]: number;
};

export type GasPriceResponseDto = GasData[];

export type DetailedChainsResponseDto = DetailedChain[];

export type AccountBalanceResponseDto = {
  balances: AccountBalance[];
  message: string;
};

// Account Balance
export type AccountBalance = {
  protocolSymbol: string | null;
  protocolName: string;
  positionType: string;
  quantity: number;
  value: number; // value in USD
  price: number; // price per token in USD
  changes: {
    absolute_1d: number;
    percent_1d: number;
  };
  token: Currency;
  updatedAt: string;
  chainId: string;
};

export type Currency = {
  name: string;
  icon: string;
  symbol: string;
};

export type AllFiatCurrencies = {
  [key: string]: boolean;
};

export type ServerApiError = { statusCode: number; message: string; error: string };

export const isServerApiError = (data: unknown): data is ServerApiError => {
  return (data as ServerApiError).statusCode !== undefined;
};

export type APIResponseMap = {
  [API_ENDPOINT.EXCHANGES_STATUS]: ExchangeNameStatus[];
  [API_ENDPOINT.TOKEN_NAMES_AND_SYMBOLS]: TokenSymbolName[];
  [API_ENDPOINT.FIAT_CURRENCIES]: FiatCurrencyMetadata[];
  [API_ENDPOINT.STANDARDIZED_CHAINS]: ExchangeToStandarizedChainsMapping;
  [API_ENDPOINT.PRICES]: TokenPriceResponse;
  [API_ENDPOINT.SUPPORTED_TOKENS]: SupportedTokensWithNetworks;
  [API_ENDPOINT.SUPPORTED_TOKENS_BY_EXCHANGE]: string[];
  [API_ENDPOINT.SUPPORTED_NETWORKS_BY_EXCHANGE]: MarketNetwork[];
  [API_ENDPOINT.TRADE_PATH]: TradePathData[];
  [API_ENDPOINT.PARTNERS]: Partner[];
  [API_ENDPOINT.ALL_FIAT_CURRENCIES]: AllFiatCurrencies;
  [API_ENDPOINT.KRAKEN_WITHDRAWAL_NETWORKS_INFO]: KrakenWithdrawalNetworkDto;
  [API_ENDPOINT.WALLET_TYPES_CONFIG]: WalletTypesConfig;
  [API_ENDPOINT.USER_REWARDS]: UserRewardState;
  [API_ENDPOINT.TRADE_PATH_BY_SUPPORTED_TOKENS]: TradePathBySupportedTokens | ServerApiError;
  [API_ENDPOINT.GET_DISCORD_INVITATION]: DiscordInvitation | ServerApiError;
  [API_ENDPOINT.GET_REFERRAL_CODE]: { code: string };
  [API_ENDPOINT.USER_ACTIVE_REWARD_MULTIPLIERS]: UserMultiplierState;
  [API_ENDPOINT.TRADE_LEADERBOARD]: TradeLeaderBoardUser[];
};

export enum API_ENDPOINT {
  EXCHANGES_STATUS = "v1/exchange/status",
  TOKEN_NAMES_AND_SYMBOLS = "v1/tokens",
  FIAT_CURRENCIES = "v1/fiat/currencies",
  STANDARDIZED_CHAINS = "v1/exchange/map/chains",
  PRICES = "v2/price/crypto",
  SUPPORTED_TOKENS = "v1/tokens/supported-tokens",
  SUPPORTED_TOKENS_BY_EXCHANGE = "v1/tokens/supported-tokens-by-exchange",
  SUPPORTED_NETWORKS_BY_EXCHANGE = "v1/tokens/supported-networks-by-exchange",
  TRADE_PATH = "v1/trade/path",
  PARTNERS = "v1/misc/partners",
  ALL_FIAT_CURRENCIES = "v1/fiat/currencies/all",
  KRAKEN_WITHDRAWAL_NETWORKS_INFO = "v1/exchange/kraken/withdrawal_networks_info",
  ACCOUNTS = "v1/accounts",
  WALLET_TYPES_CONFIG = "v1/misc/walletTypesConfig",
  USER_REWARDS = "v2/rewards/users",
  USER_ACTIVE_REWARD_MULTIPLIERS = "v2/rewards/activeMultipliers",
  UNINSTALL = "v1/misc/uninstall",
  TRADE_PATH_BY_SUPPORTED_TOKENS = "v1/trade/path-by-supported-tokens",
  GET_DISCORD_INVITATION = "v1/social/discord-invite",
  GET_REFERRAL_CODE = "v2/rewards/referral-code",
  CLAIM_REFERRAL = "v2/rewards/refer",
  TRADE_LEADERBOARD = "v1/trade/leaderboard",
}
