// ----------------------------------------------------------------------------

// PLEASE DO NOT EDIT THIS FILE, IT IS GENERATED AND WILL BE OVERWRITTEN:
// https://github.com/ccxt/ccxt/blob/master/CONTRIBUTING.md#how-to-contribute-code
// EDIT THE CORRESPONDENT .ts FILE INSTEAD

import { SdkErrorCodes, SdkErrorMessages } from "./errorsMetadata.js";
import { CedeSDKError } from "./default.js";
/**
 * @description Default error for trade
 */
export class TradeError extends CedeSDKError {
    constructor(code = SdkErrorCodes.TRADE_ERROR, message = SdkErrorMessages.TRADE_ERROR) {
        super(code, message);
    }
}
/**
 * @description Thrown when the order is not found
 */
export class OrderNotFoundError extends TradeError {
    constructor() {
        super(SdkErrorCodes.ORDER_NOT_FOUND, SdkErrorMessages.ORDER_NOT_FOUND);
    }
}
/**
 * @description Thrown when the market is not found (based on pair symbol)
 */
export class MarketNotFoundError extends TradeError {
    constructor(market) {
        super(SdkErrorCodes.MARKET_NOT_FOUND, SdkErrorMessages.MARKET_NOT_FOUND(market));
        this.name = "MarketNotFoundError";
    }
}
export class InvalidOHLCVFormatError extends TradeError {
    constructor(message) {
        super(SdkErrorCodes.INVALID_OHLCV_FORMAT, SdkErrorMessages.INVALID_OHLCV_FORMAT(message));
        this.name = "InvalidOHLCVFormatError";
    }
}
export class CannotProvideOHLCVError extends TradeError {
    constructor() {
        super(SdkErrorCodes.CANNOT_PROVIDE_OHLCV, SdkErrorMessages.CANNOT_PROVIDE_OHLCV);
        this.name = "CannotProvideOHLCVError";
    }
}
export class MarketError extends TradeError {
    constructor(code = SdkErrorCodes.MARKET_ERROR, message = SdkErrorMessages.MARKET_ERROR) {
        super(code, message);
        this.name = "MarketError";
    }
}
export class MarketNotLoadedError extends MarketError {
    constructor() {
        super(SdkErrorCodes.MARKET_NOT_LOADED, SdkErrorMessages.MARKET_NOT_LOADED);
        this.name = "MarketNotLoadedError";
    }
}
export class MarketTypeNotSupportedError extends MarketError {
    constructor(marketType) {
        super(SdkErrorCodes.MARKET_TYPE_NOT_SUPPORTED, SdkErrorMessages.MARKET_TYPE_NOT_SUPPORTED(marketType));
        this.name = "MarketTypeNotSupportedError";
    }
}
