export const USE_NETWORKS_CACHE_TIME = 120_000;
export const USE_DEPOSITABLE_TOKENS_CACHE_TIME = 120_000;
export const TOKENS_NAMES_AND_SYMBOLS_CACHE_TIME = 60 * 60 * 1000;
export const USE_PORTFOLIO_REFETCH_INTERVAL = 60 * 1000;
export const SUPPORTED_EXCHANGES_CACHE_TIME = 60 * 1000;
export const USE_SUPPORTED_TOKENS_CACHE_TIME = 60 * 1000;
export const WITHDRAWABLE_BALANCES_TTL = 10_000;
export const DEFAULT_SDK_CACHE_TTL = 300_000;
export const USE_WHITELISTED_ADDRESSES_CACHE_TIME = 30 * 1000;
export const USE_WHITELISTED_ADDRESSES_STALE_TIME = 15 * 1000;
export const AVAILABLE_EXCHANGES_CACHE_TIME = 60 * 60 * 1000;
export const WALLET_TYPES_CONFIG_CACHE_TTL = 10 * 1000;
export const WITHDRAWAL_FEE_CACHE_TTL = 5 * 1000;
export const TRADE_PATH_BY_SUPPORTED_TOKENS_CACHE_TTL = 60 * 1000;
