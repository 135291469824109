import { ApiPermissions } from "@cede/types";

// Check if the current process has the required permissions
export function checkRequiredPermissionStatement(requireStatement: string | undefined, permissions: ApiPermissions[]) {
  if (!requireStatement) return true;
  const requiredPermissions = requireStatement.split(",") as ApiPermissions[];
  return (
    requiredPermissions.every((permission) => permissions.includes(permission)) &&
    requiredPermissions.length === permissions.length
  );
}
