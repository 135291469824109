import { useEffect } from "react";
import { useDependencies } from "../../hooks/useDependencies";

export const DefaultPage = () => {
  const { useLoading } = useDependencies();
  const addBigPendingRequest = useLoading((state) => state.addBigPendingRequest);
  const removeBigPendingRequest = useLoading((state) => state.removeBigPendingRequest);
  useEffect(() => {
    addBigPendingRequest();
    return () => {
      removeBigPendingRequest();
    };
  }, []);
  return null;
};
