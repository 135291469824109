import { Button } from "@cede/ds";
import { userFacingMessages } from "@cede/types";
import { firstLetterToUpperCase } from "@cede/utils";
import { Box, Fade, Modal, Typography, useTheme } from "@mui/material";

export type TradeRouteInfoModalProps = {
  id?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  tradePath: string;
  cexName: string;
  tokenFrom: string;
  tokenTo: string;
};

export function TradeRouteInfoModal({
  id,
  open,
  setOpen,
  tradePath,
  tokenFrom,
  tokenTo,
  cexName,
}: TradeRouteInfoModalProps) {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        position: "absolute",
      }}
      closeAfterTransition
      disablePortal={true}
    >
      <Fade in={open} timeout={300}>
        <Box
          data-testid={`${id || ""}-permission-changed-modal`}
          sx={{
            "width": `calc(${theme.custom.storeSize.width} * 0.9)`,
            "height": "max-content",
            "padding": theme.custom.gutterMargin,
            "position": "absolute",
            "top": "50%",
            "left": "50%",
            "transform": "translate(-50%, -50%)",
            "background": theme.palette.background.darkPaper,
            "borderRadius": theme.custom.mediumBoxRadius,
            "display": "flex",
            "flexDirection": "column",
            "alignItems": "center",
            "justifyContent": "center",
            "gap": theme.custom.gutterMargin,
            ":focus": {
              outline: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: theme.custom.smallGutterMargin,
            }}
          >
            <Typography fontSize={theme.typography.h2.fontSize}>Confirmation</Typography>
            <Typography color={theme.palette.text.disabled}>
              {userFacingMessages.WIDGETS.TRADE_SQUARE.ACTIONS_LABEL}
            </Typography>
          </Box>
          <Typography sx={{ marginTop: theme.custom.smallGutterMargin, textAlign: "left" }}>
            {userFacingMessages.WIDGETS.TRADE_SQUARE.INFO_DETAILS(
              firstLetterToUpperCase(cexName),
              tokenFrom,
              tokenTo,
            ).map((text, index) => (
              <Typography component={"span"} fontWeight={index % 2 !== 0 ? "bold" : "normal"} key={index}>
                {text}
              </Typography>
            ))}
          </Typography>
          <Typography textAlign={"center"} width={"100%"}>
            {tradePath}
          </Typography>
          <Button
            variant="contained"
            height="tall"
            fullWidth
            onClick={() => {
              setOpen(false);
            }}
          >
            {userFacingMessages.WIDGETS.TRADE_SQUARE.CLOSE}
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
}
