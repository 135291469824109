import DEFAULT_NETWORK_ICON_SVG from "./defaultNetwork.svg";
import DEFAULT_TOKEN_ICON_SVG from "./defaultToken.svg";
import { InternalChainId, TokenSymbol } from "@cede/types";
import { TokenIcon } from "@cede/types";
import { CDN_MODULES } from "./types";

export const CEDE_CDN_URL = "https://cdn.cedelabs.io";
export const DEFAULT_NETWORK_ICON = DEFAULT_NETWORK_ICON_SVG;
export const DEFAULT_TOKEN_ICON = DEFAULT_TOKEN_ICON_SVG;
// Icons from CoinMarketCap
let icons: TokenIcon[] = [];

export async function fetchIcons(fetcher: () => Promise<{ data: TokenIcon[] }>) {
  const { data } = await fetcher();
  icons = data;
}

export const forgeCDNUrl = (module: CDN_MODULES, path: string, cdnUrl?: string) =>
  `${cdnUrl ?? CEDE_CDN_URL}/${module}/${path}`;

export const forgeNetworkURL = (icid?: InternalChainId): string => {
  if (!icid) return DEFAULT_NETWORK_ICON;
  return forgeCDNUrl(CDN_MODULES.NETWORK, `${icid}.png`);
};

export const forgeTokenURL = (token?: TokenSymbol): string => {
  if (!token) return DEFAULT_TOKEN_ICON;
  return forgeCDNUrl(CDN_MODULES.TOKEN, `${token.toLowerCase()}.svg`);
};

export const resolveCDNMiss = (url: URL) => {
  const path = url.pathname.split("/");
  if (path.length < 3) return "";

  const module = path[1];
  const target = path[2]?.replace(".png", "").replace(".svg", "");
  switch (module) {
    case CDN_MODULES.NETWORK:
      return DEFAULT_NETWORK_ICON;
    case CDN_MODULES.TOKEN:
      return icons.find((icon) => icon.token.toLowerCase() === target)?.icon || DEFAULT_TOKEN_ICON;
    default:
      return "";
  }
};

export * from "./types";
