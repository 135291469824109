import { useEffect, useRef } from "react";
import { Button, useNavBar } from "@cede/ds";
import { ViewEnvironment, userFacingMessages } from "@cede/types";
import { CEDE_CEXS, WIDGET_ROUTES, formatBalance } from "@cede/utils";
import { SingleTransaction } from "../../components/SingleTransaction/SingleTransaction";
import { isMultipleTransactionsPreparedOrder } from "../../hooks/useMultipleTransactions";
import {
  MultipleTransactionsPreparedOrder,
  MultipleTransactionsPreparedWithdrawal,
} from "../../hooks/useMultipleTransactionsStore";
import { HelpLink, MainFooter, Page, useDependencies } from "@cedelabs/react-utils";
import { Box, Typography } from "@mui/material";

type PresentationalMultipleTransactionsProps = {
  goBack: () => void;
  environment: ViewEnvironment | null;
  transactions: (MultipleTransactionsPreparedOrder | MultipleTransactionsPreparedWithdrawal)[];
  txTracker: number;
  isLoading: boolean;
  onContinue: () => void;
};

export const PresentationalMultipleTransactions = ({
  goBack,
  environment,
  transactions,
  txTracker,
  isLoading,
  onContinue,
}: PresentationalMultipleTransactionsProps) => {
  const { setGeneral, setGoBack, setRightSide } = useNavBar();

  useEffect(() => {
    setGeneral({
      leftSide: null,
      center: <Typography>Confirm Transactions</Typography>,
      rightSide: <div />,
      isDisplayed: true,
    });
    if (goBack) {
      setGoBack(goBack);
    }

    if (environment !== ViewEnvironment.WIDGET) {
      setRightSide(<HelpLink />);
    } else {
      setRightSide(null);
    }
  }, []);

  return (
    <Page displayFlex>
      <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {transactions.map((tx, index) => {
          const number = String(index + 1);
          if (isMultipleTransactionsPreparedOrder(tx)) {
            return (
              <SingleTransaction
                key={index}
                transaction={{
                  from: {
                    amount: formatBalance(tx.from.amount),
                    tokenSymbol: tx.from.tokenSymbol,
                  },
                  to: {
                    amount: formatBalance(tx.to.amount),
                    tokenSymbol: tx.to.tokenSymbol,
                  },
                  isExecuted: tx.isExecuted,
                  type: "order",
                  account: {
                    label: tx.exchange.label,
                    img: tx.exchange.img,
                  },
                }}
                number={number}
                isLast={index === transactions.length - 1}
              />
            );
          }
          return (
            <SingleTransaction
              key={index}
              transaction={{
                from: {
                  amount: formatBalance(tx.request.amount),
                  tokenSymbol: tx.request.tokenSymbol,
                },
                to: {
                  amount: formatBalance(tx.request.amount),
                  tokenSymbol: tx.request.tokenSymbol,
                  network: tx.network,
                  address: tx.request.address,
                },
                isExecuted: tx.isExecuted,
                type: "withdrawal",
                account: {
                  label: tx.exchange.label,
                  img: tx.exchange.img,
                },
              }}
              number={number}
              isLast={index === transactions.length - 1}
            />
          );
        })}
      </Box>

      <MainFooter>
        <Button height={"tall"} variant={"contained"} fullWidth onClick={onContinue} disabled={isLoading}>
          {txTracker >= transactions.length
            ? userFacingMessages.WIDGETS.TRADE_SEND.FINISH
            : userFacingMessages.WIDGETS.TRADE_SEND.CONFIRM_TX(txTracker + 1)}
        </Button>
      </MainFooter>
    </Page>
  );
};

export const MultipleTransactionsForTrade = () => {
  const { useViewEnvironment, useMultipleTransactions, useNavigate, useTradeForm, useTradePath } = useDependencies();
  const navigate = useNavigate();
  const { environment } = useViewEnvironment();
  const { tradeForm, resetForm } = useTradeForm();
  const { tradePath } = useTradePath({
    enabled: true,
    accountId: tradeForm.selectedAccount?.value || "",
    activeWithdrawal: false,
    fromToken: tradeForm.tokenFrom?.value || "",
    toToken: tradeForm.tokenTo?.value || "",
  });
  const wantsToGoBack = useRef(false);

  const {
    transactions,
    txTracker,
    executeNextTransaction,
    isTxLoading,
    clearState,
    startProcess,
    hasStartedTheProcess,
  } = useMultipleTransactions({
    accountId: tradeForm.selectedAccount?.value || "",
    tradePath,
    fromTokenAmount: tradeForm.amountFrom || "",
    onFinish: () => {
      clearState();
      resetForm();
      navigate(WIDGET_ROUTES.COMPLETED_TRANSACTION_ROUTE);
    },
  });

  const goBack = () => {
    clearState();
    resetForm();
    wantsToGoBack.current = true;
  };

  useEffect(() => {
    startProcess();
  }, []);

  // We need to wait for the state to be cleared before navigating back
  useEffect(() => {
    if (!hasStartedTheProcess && wantsToGoBack.current) {
      return navigate(WIDGET_ROUTES.ACTION_ROUTE);
    }
  }, [hasStartedTheProcess]);

  return (
    <PresentationalMultipleTransactions
      goBack={goBack}
      environment={environment}
      transactions={transactions}
      txTracker={txTracker}
      isLoading={isTxLoading}
      onContinue={executeNextTransaction}
    />
  );
};

export const MultipleTransactionsForSend = () => {
  const { useViewEnvironment, useMultipleTransactions, useNavigate, useSendStore, useTradePath, useLoading } =
    useDependencies();
  const addBigPendingRequest = useLoading((state) => state.addBigPendingRequest);
  const removeBigPendingRequest = useLoading((state) => state.removeBigPendingRequest);
  const navigate = useNavigate();
  const { environment } = useViewEnvironment();

  const form = useSendStore();
  const { tradePath } = useTradePath({
    enabled: true,
    accountId: form.fromAccount?.value || "",
    activeWithdrawal: true,
    fromToken: form.currency?.value || "",
    toToken: form.destinationCurrency?.value || "",
    network: form.network?.value,
  });
  const { transactions, txTracker, executeNextTransaction, isTxLoading, clearState, loadTransactions, startProcess } =
    useMultipleTransactions({
      accountId: form.fromAccount?.value || "",
      tradePath,
      fromTokenAmount: form.assetValue || "",
      network: form.network || undefined,
      activeWithdrawal: true,
      recipientAddress:
        (form.fromAccount?.cexValue === CEDE_CEXS.KRAKEN && form.whitelistedAddress?.key
          ? form.whitelistedAddress?.key
          : form.recipientAddress) || undefined,
      whitelistedAddress: form.whitelistedAddress?.key,
      withdrawalTag: form.tagValue,
      onFinish: () => {
        clearState();
        form.reset();
        navigate(WIDGET_ROUTES.COMPLETED_TRANSACTION_ROUTE);
      },
    });

  const goBack = () => {
    clearState();
    return navigate(WIDGET_ROUTES.ACTION_ROUTE);
  };

  useEffect(() => {
    if (!tradePath) return;
    addBigPendingRequest();
    loadTransactions(transactions.filter((tx) => tx.isExecuted)).finally(() => {
      removeBigPendingRequest();
    });
  }, [tradePath]);

  useEffect(() => {
    startProcess();
  }, []);

  return (
    <PresentationalMultipleTransactions
      goBack={goBack}
      environment={environment}
      transactions={transactions}
      txTracker={txTracker}
      isLoading={isTxLoading}
      onContinue={executeNextTransaction}
    />
  );
};
