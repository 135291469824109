import { useEffect } from "react";
import { Any, HandlerType, ViewEnvironment } from "@cede/types";
import { useDependencies } from "./useDependencies";
import { create } from "zustand";

interface ViewEnvironmentState {
  viewEnvironment: ViewEnvironment | null;
  setViewEnvironment: (environment: ViewEnvironment | null) => void;
}

export const useViewEnvironmentStore = create<ViewEnvironmentState>((set) => ({
  viewEnvironment: null,
  setViewEnvironment: (environment: ViewEnvironment | null) => set({ viewEnvironment: environment }),
}));

async function determineViewEnvironment(backgroundHandler: Any) {
  if (!backgroundHandler) {
    return null;
  }

  if (backgroundHandler.type === HandlerType.UNTRUSTED_PROVIDER_API) {
    return ViewEnvironment.WIDGET;
  }

  const tab = await chrome.tabs?.getCurrent();
  return tab ? ViewEnvironment.TAB : ViewEnvironment.POPUP;
}
/**
 *
 * @returns
 * source: if we were redirected from a source
 * origin: the origin of the current page
 * environment: the current view environment
 */
export const useViewEnvironment = () => {
  const { useBackgroundHandler } = useDependencies();
  const { backgroundHandler } = useBackgroundHandler();
  const setViewEnvironment = useViewEnvironmentStore((state) => state.setViewEnvironment);

  const queryParams = new URLSearchParams(window.location.search);
  const source = queryParams.get("source") ?? undefined;

  const origin = window.location.origin;

  useEffect(() => {
    determineViewEnvironment(backgroundHandler).then(setViewEnvironment);
  }, [backgroundHandler]);

  return {
    source,
    origin,
    environment: useViewEnvironmentStore((state) => state.viewEnvironment),
  };
};
