import { TutorialAffiliationProps } from "./types";
import { Box, Button, Typography, useTheme } from "@mui/material";

export function TutorialAffiliation({ onClick }: TutorialAffiliationProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.background.darkPaper,
        position: "relative",
        display: "flex",
      }}
    >
      <Typography component={"p"} sx={{ display: "inline", flex: "5 1 0", textAlign: "center", margin: "auto" }}>
        Don't have an account yet?
      </Typography>

      <Box
        sx={{
          display: "flex",
          flex: "4 1 0",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onClick}
          sx={{
            "paddingY": theme.custom.smallGutterMargin,
            "paddingX": theme.custom.smallGutterMargin,
            "margin": "auto",
            "backgroundColor": theme.palette.background.default,
            "borderRadius": theme.custom.mediumBoxRadius,
            "color": theme.palette.text.primary,
            "textTransform": "none",
            "gap": theme.custom.smallGutterMargin,
            "&:hover": {
              backgroundColor: theme.palette.background.component,
            },
            "fontSize": "14px",
          }}
        >
          <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 1321316282">
              <path
                id="Subtract"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.5076 7.0301L21.3442 7.51277C22.5401 8.2028 22.9502 9.73166 22.2602 10.9276L21.0223 13.0731L20.3186 15.6883C20.1054 16.4805 19.6687 17.1529 19.0947 17.6557C19.9137 17.3372 20.6338 16.7479 21.1072 15.9275L23.5595 11.6772C24.6635 9.76374 24.0073 7.31756 22.0938 6.21353L12.2537 0.536008C10.3403 -0.568027 7.89408 0.0881602 6.79005 2.00164L6.21716 2.99455C6.7312 2.92383 7.26758 2.95258 7.80012 3.09588L7.87832 3.11693L8.0893 2.75128C8.77932 1.55535 10.3082 1.14523 11.5041 1.83526L18.8377 6.06656C19.4864 6.25309 20.0524 6.59045 20.5076 7.0301Z"
                fill="white"
              />
              <path
                id="Subtract_2"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.1256 7.15567L18.3885 7.49548C19.7217 7.85425 20.5117 9.22593 20.153 10.5592L19.3683 13.4751V15.657C19.3683 16.5512 19.0749 17.3769 18.5791 18.043C19.4089 17.5262 20.0523 16.7059 20.3264 15.6875L21.6014 10.949C22.1755 8.81573 20.9115 6.62104 18.7782 6.04701L7.80791 3.09504C5.67466 2.52101 3.47997 3.78501 2.90594 5.91827L2.61509 6.99913C3.0488 6.83803 3.51802 6.75 4.00781 6.75H4.23548L4.35441 6.30804C4.71318 4.97475 6.08487 4.18475 7.41815 4.54352L15.6558 6.76017C16.1802 6.79741 16.6765 6.9357 17.1256 7.15567Z"
                fill="white"
              />
              <rect
                id="Rectangle 14962"
                x="0.75"
                y="7.5"
                width="17.8605"
                height="11.407"
                rx="3.25"
                stroke="white"
                strokeWidth="1.5"
              />
              <path
                id="Union"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.94441 11.4434H9.17192V13.082H7.62195L7.62195 13.8545H9.17192V15.3058H9.94441V13.8545H11.4844V13.082H9.94441V11.4434Z"
                fill="white"
              />
            </g>
          </svg>
          Create account
        </Button>
      </Box>
    </Box>
  );
}
