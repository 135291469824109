export enum Env {
  DEMO = "DEMO",
  TEST = "TEST",
  PROD = "PROD",
}

export enum EnvMessage {
  DEMO = "You're using the Demo vault.",
  PROD = "You're using the Real vault.",
  PROD_NEW_VAULT = "No real vault found, please create one",
  DEMO_NEW_VAULT_CREATED = "This is a Demo Vault, go to the menu to switch to a real vault",
}

export enum ViewEnvironment {
  POPUP = "popup",
  WIDGET = "widget",
  TAB = "tab",
}
