import { BalancesByToken, BalancesByWalletType } from "@cede/types";

/**
 * @description
 * Converts balances structure indexed by wallet type to the structure indexed by token.
 */
export const balancesByWalletTypeToBalancesByToken = (balancesByWalletType: BalancesByWalletType): BalancesByToken => {
  const balancesByToken = Object.values(balancesByWalletType).reduce((acc, balancesByToken) => {
    Object.entries(balancesByToken).forEach(([tokenSymbol, balance]) => {
      const existingBalance = acc[tokenSymbol];
      if (!existingBalance) {
        acc[tokenSymbol] = {
          totalBalance: balance.totalBalance,
          freeBalance: balance.freeBalance,
          usedBalance: balance.usedBalance,
          refTotalBalance: balance.refTotalBalance,
          refFreeBalance: balance.refFreeBalance,
          refUsedBalance: balance.refUsedBalance,
        };
      } else {
        existingBalance.totalBalance += balance.totalBalance;
        existingBalance.refTotalBalance += balance.refTotalBalance;
        existingBalance.freeBalance += balance.freeBalance;
        existingBalance.refFreeBalance += balance.refFreeBalance;
        existingBalance.usedBalance += balance.usedBalance;
        existingBalance.refUsedBalance += balance.refUsedBalance;
      }
    });

    return acc;
  }, {} as BalancesByToken);
  return balancesByToken;
};
