import {
  CefiInfoTx,
  DefiInfoTx,
  ExchangeToStandarizedChainsMapping,
  StandardizedChain,
  TradeConfirmationLocationState,
  TradeForm,
  Transaction,
  TxStatus,
  WalletType,
  isCefiInfoTx,
} from "@cede/types";
import { QueryKeys } from "@cede/utils";
import { SendForm } from "../../components/Send/types";
import { getQueryClient } from "../../utils";
import { create } from "zustand";

type SelectedTransactionState = {
  reset: () => void;
  setTransaction: (transaction: Transaction | null) => void;
  transaction: null | Transaction;
  networkLabel: string | null;
  error: string | null;
  errorCode: number | null;
  setError: (error: string | null, errorCode: number) => void;
  resetError: VoidFunction;
  total: null | number;
  totalFiatValue: null | number;
  isTrade: boolean;
  isSend: boolean;
  isReceive: boolean;
};

// This store is used to pass the transaction from the Send page to the SendConfirmation page
// and fill the CompletedTransaction and CompletedTransactionDetails pages
export const useSelectedTransaction = create<SelectedTransactionState>((set) => ({
  transaction: null,
  error: null,
  errorCode: null,
  total: null,
  totalFiatValue: null,
  networkLabel: null,
  isTrade: false,
  isSend: false,
  isReceive: false,
  reset: () => {
    set({ transaction: null, error: null }, false);
  },
  setTransaction: (transaction: Transaction | null) => {
    set(() => {
      if (!transaction) {
        return { transaction: null, error: null, networkLabel: null, total: null, totalFiatValue: null };
      }

      const standardizedNetworks = getQueryClient().getQueryData<ExchangeToStandarizedChainsMapping>([
        QueryKeys.STANDARDIZED_NETWORKS,
      ]);

      const totalFiatValue =
        transaction && isCefiInfoTx(transaction.from) ? transaction?.from.transaction.refAmount ?? 0 : 0;

      const total = transaction?.from.transaction.fee.refAmount ?? 0 + totalFiatValue;

      const isTrade = transaction?.type === "order";

      const isSend = transaction?.type === "withdrawal" && isCefiInfoTx(transaction.from);

      const isReceive = transaction?.type === "deposit" && isCefiInfoTx(transaction.to);

      const exchangeId = isCefiInfoTx(transaction.from)
        ? transaction.from.account.exchangeId
        : isCefiInfoTx(transaction.to)
        ? transaction.to.account.exchangeId
        : undefined;
      const infoTx = isCefiInfoTx(transaction.from)
        ? transaction.from
        : isCefiInfoTx(transaction.to)
        ? transaction.to
        : undefined;

      const standardizedNetwork = standardizedNetworks?.[exchangeId ?? ""]?.find(
        (network: StandardizedChain) =>
          network.icid === infoTx?.account.network || network.ecid === infoTx?.account.network,
      );

      const networkLabel = standardizedNetwork?.icn ?? transaction.to.account.network ?? "unknown";

      return { transaction, isTrade, isSend, isReceive, total, totalFiatValue, networkLabel };
    });
  },
  setError: (error: string | null, errorCode: number) => {
    set(() => ({ error, errorCode }));
  },
  resetError: () => {
    set(() => ({
      error: null,
      errorCode: null,
    }));
  },
}));

// todo remove this function and adapt generateFakeTradeTransactionFromStore when we migrate to store from the location state for the trade flow
export function generateFakeTradeTransactionTradeForm(form: TradeForm): Transaction<CefiInfoTx, CefiInfoTx> {
  return {
    type: "order",
    pairSymbol: form.market?.pairSymbol,
    from: {
      account: {
        exchangeId: form.selectedAccount?.cexValue ?? "",
        accountId: form.selectedAccount?.value ?? "",
      },
      wallet: {
        walletType: WalletType.SPOT,
        depositAddress: "",
      },
      transaction: {
        amount: Number(form.amountFrom) ?? 0,
        refAmount: 0,
        tokenSymbol: form.tokenFrom?.value ?? "",
        transactionId: "",
        fee: { amount: 0, tokenSymbol: form.tokenFrom?.value ?? "" },
        status: TxStatus.FAILED,
        timestamp: Date.now(),
      },
    },
    to: {
      account: {
        exchangeId: form.selectedAccount?.cexValue ?? "",
        accountId: form.selectedAccount?.value ?? "",
      },
      wallet: {
        walletType: WalletType.SPOT,
        depositAddress: "",
      },
      transaction: {
        amount: Number(form.amountTo) ?? 0,
        refAmount: 0,
        tokenSymbol: form.tokenTo?.value ?? "",
        transactionId: "",
        fee: { amount: 0, tokenSymbol: form.tokenTo?.value ?? "" },
        status: TxStatus.FAILED,
        timestamp: Date.now(),
      },
    },
  };
}

export function generateFakeTradeTransactionFromStore(
  form: TradeConfirmationLocationState,
): Transaction<CefiInfoTx, CefiInfoTx> {
  return {
    type: "order",
    pairSymbol: form.market?.pairSymbol,
    from: {
      account: {
        exchangeId: form.account?.cexValue ?? "",
        accountId: form.account?.value ?? "",
      },
      wallet: {
        walletType: WalletType.SPOT,
        depositAddress: "",
      },
      transaction: {
        amount: Number(form.amountFrom) ?? 0,
        refAmount: 0,
        tokenSymbol: form.tokenFrom?.value ?? "",
        transactionId: "",
        fee: { amount: 0, tokenSymbol: form.tokenFrom?.value ?? "" },
        status: TxStatus.FAILED,
        timestamp: Date.now(),
      },
    },
    to: {
      account: {
        exchangeId: form.account?.cexValue ?? "",
        accountId: form.account?.value ?? "",
      },
      wallet: {
        walletType: WalletType.SPOT,
        depositAddress: "",
      },
      transaction: {
        amount: Number(form.amountTo) ?? 0,
        refAmount: 0,
        tokenSymbol: form.tokenTo?.value ?? "",
        transactionId: "",
        fee: { amount: 0, tokenSymbol: form.tokenTo?.value ?? "" },
        status: TxStatus.FAILED,
        timestamp: Date.now(),
      },
    },
  };
}

// This function is used to generate a fake transaction from the Send page when the withdraw function fails
export function generateFakeSendTransactionFromStore(form: SendForm, tokenPrice: number) {
  const transaction: Transaction<CefiInfoTx, DefiInfoTx> = {
    from: {
      account: {
        exchangeId: form.fromAccount?.cexValue ?? "",
        accountId: form.fromAccount?.value ?? "",
        network: form.network?.value ?? "",
      },
      wallet: {
        depositAddress: form.recipientAddress ?? "",
        walletType: WalletType.SPOT,
      },
      transaction: {
        amount: Number(form.assetValue) ?? 0,
        refAmount: Number(form.assetValue) * tokenPrice ?? 0,
        tokenSymbol: form.currency?.value ?? "",
        transactionId: "",
        fee: { amount: 0, tokenSymbol: form.currency?.value ?? "" },
        status: TxStatus.FAILED,
        timestamp: Date.now(),
      },
    },
    to: {
      account: {
        address: form.recipientAddress ?? "",
        network: form.network?.value ?? "",
      },
      transaction: {
        transactionHash: "",
        fee: { amount: 0, refAmount: 0, tokenSymbol: "" },
        status: TxStatus.FAILED,
        timestamp: Date.now(),
        tokens: [
          {
            amount: Number(form.assetValue) ?? 0,
            refAmount: Number(form.assetValue) * tokenPrice ?? 0,
            tokenSymbol: form.currency?.value ?? "",
            address: "",
          },
        ],
      },
    },
    type: "withdrawal",
  };

  return transaction;
}
