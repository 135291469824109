import { useEffect, useMemo, useRef, useState } from "react";
import ccxt from "@cede/ccxt";
import { BalancesByToken, DropListWithIconItem, MarketPair, MarketRate, ViewEnvironment } from "@cede/types";
import { balancesByWalletTypeToBalancesByToken, fetchPopularTokens, forgeTokenURL, formatBalance } from "@cede/utils";
import { useDependencies } from "../../../hooks/useDependencies";
import { BALANCE_DEFAULT_TTL } from "../constants";
import { AvailableAssets, UseTradeApiHook } from "../types";
import { useQuery } from "@tanstack/react-query";

const EMPTY_RATE = { bid: "0", ask: "0" };

export const useTradeApi: UseTradeApiHook = (params) => {
  const {
    useVaults,
    backgroundHandler,
    useTokenNamesAndSymbols,
    useSupportedExchanges,
    useViewEnvironment,
    useTradePath,
  } = useDependencies();
  const { selectedAccount, tokenFrom, dispatchForm, form } = params;
  const { activeVault } = useVaults();
  const [markets, setMarkets] = useState<MarketPair[]>([]);
  const [isLoadingUnderlyingMarkets, setIsLoadingUnderlyingMarkets] = useState<boolean>(false); // to use in the assetsTo droplist
  const tokenFromIsSet = useRef(false);
  const { tokenNamesAndSymbols } = useTokenNamesAndSymbols();
  const { supportedExchangesById } = useSupportedExchanges();
  const { environment } = useViewEnvironment();

  // It's used to avoid having an empty assetsTo while the new droplistDestinationTokens are loading
  // which could cause side effects like flickering
  const prevAssetsTo = useRef<AvailableAssets>([]);

  const isTradeSquareSupported =
    selectedAccount?.cexValue &&
    supportedExchangesById[selectedAccount?.cexValue]?.supportedFeatures.includes("trade_square") &&
    environment === ViewEnvironment.WIDGET;

  const shouldUseTradeSquare = Boolean(form?.tokenFrom && isTradeSquareSupported && params.form?.noCommonMarket);

  const {
    droplistDestinationTokens,
    isLoading: droplistDestinationTokensAreLoading,
    error: droplistDestinationTokensError,
    tradePath,
  } = useTradePath({
    accountId: selectedAccount?.value || "",
    fromToken: tokenFrom?.value || "",
    toToken: form?.tokenTo?.value,
    activeWithdrawal: false,
    enabled: !!isTradeSquareSupported,
  });

  const _fetchPortfolioAssetOfSelectedCex = async () => {
    if (selectedAccount) {
      if (!activeVault?.id) return;

      const transferWalletTypes = supportedExchangesById[selectedAccount.cexValue]?.transferWalletTypes;
      // TODO standardise the common methods between the trusted and the untrusted api
      let accountBalances: BalancesByToken = {};
      if (environment === ViewEnvironment.WIDGET) {
        const balances = await backgroundHandler.balances({
          vaultId: activeVault.id,
          accountIds: [selectedAccount.value],
          walletTypes: transferWalletTypes,
          opts: {
            forceRefresh: true,
          },
        });
        accountBalances = balances[selectedAccount.value];
      } else {
        const balances = await backgroundHandler.getBalances({
          vaultId: activeVault.id,
          accountIds: [selectedAccount.value],
          walletTypes: transferWalletTypes,
          opts: {
            forceRefresh: true,
          },
        });

        const balancesByWalletType = balances[selectedAccount.value];
        if (!balancesByWalletType) return;

        accountBalances = balancesByWalletTypeToBalancesByToken(balancesByWalletType);
        if (!accountBalances) return;
      }
      const assetPortfolio: AvailableAssets = Object.entries(accountBalances)
        .map(([tokenSymbol, balance]) => {
          const searchableTerms = [tokenSymbol];

          const tokenName = balance.tokenName;
          if (tokenName) {
            searchableTerms.push(tokenName);
          }

          const asset: AvailableAssets[number] = {
            img: forgeTokenURL(tokenSymbol),
            label: tokenSymbol,
            value: tokenSymbol,
            balance: formatBalance(balance.freeBalance || 0),
            freeBalance: balance.freeBalance || 0,
            amount: balance.refFreeBalance.toFixed(2),
            ...(searchableTerms && {
              searchableTerms,
            }),
          };
          return asset;
        })
        .filter((asset) => Number(asset.amount) > 0)
        .sort((a, b) => Number(b.amount) - Number(a.amount));

      // @TODO: weird because the balance might need to be updated at some point but keeping the tokenFrom
      if (!tokenFromIsSet.current) {
        tokenFromIsSet.current = true;
        dispatchForm({
          field: "tokenFrom",
          value: assetPortfolio[0],
        });
      }
      return assetPortfolio;
    }

    return [];
  };

  const { data: assetsFrom, isLoading: isLoadingBalances } = useQuery({
    queryKey: ["fetchPortfolioAssetPeriodicaly", selectedAccount, activeVault],
    queryFn: _fetchPortfolioAssetOfSelectedCex,
    // staleTime and refetchInterval has to contain the same value because if the validity
    // is > than the refetch interval the refetch will retrieve the data from the cache
    staleTime: BALANCE_DEFAULT_TTL / 2,
    refetchInterval: BALANCE_DEFAULT_TTL / 2,
  });

  /**
   * Fetches all pairs and tickers from selected cex
   */
  const loadMarkets = async () => {
    if (!activeVault || !selectedAccount) return;
    const markets = await backgroundHandler.getMarketPairs({
      accountId: selectedAccount.value,
    });
    setMarkets(markets);
  };

  /**
   * Computes the price for the selected trade path. The amount is in the destination token.
   */
  const computePriceForTradePath = async () => {
    let price = "0";
    if (!tradePath || !selectedAccount) return price;
    for (const path of tradePath) {
      const marketRate = (await backgroundHandler.getMarketRate({
        accountId: selectedAccount.value,
        pairSymbol: path.pairSymbol,
      })) as MarketRate;
      const sellPriceInDestinationToken =
        path.orderSide === "sell" ? marketRate.bid : ccxt.Precise.stringDiv("1", marketRate.ask);
      price = price === "0" ? sellPriceInDestinationToken : ccxt.Precise.stringMul(price, sellPriceInDestinationToken);
    }
    return price;
  };

  /**
   * Fetches the market price for the selected pair or for the trade path.
   * For buy orders, the price is the ask price.
   * For sell orders, the price is the bid price.
   */
  const { data: marketRate } = useQuery({
    queryKey: ["updateMarketRate", tradePath, form?.market?.pairSymbol, selectedAccount, activeVault],
    queryFn: async () => {
      if (!activeVault || !selectedAccount) return EMPTY_RATE;

      if (!form?.market && tradePath) {
        const tradePathPrice = await computePriceForTradePath();
        return {
          bid: tradePathPrice.toString(),
          ask: tradePathPrice.toString(),
        };
      }
      return await backgroundHandler.getMarketRate({
        accountId: selectedAccount.value,
        pairSymbol: form?.market?.pairSymbol,
      });
    },
    staleTime: BALANCE_DEFAULT_TTL / 2,
    refetchInterval: BALANCE_DEFAULT_TTL / 2,
    enabled:
      !!backgroundHandler?.isReady &&
      selectedAccount !== null &&
      activeVault !== null &&
      (form?.market?.pairSymbol !== undefined || tradePath !== undefined),
  });

  const { data: popularTokens } = useQuery({
    queryKey: ["popularTokens"],
    queryFn: fetchPopularTokens,
    staleTime: Infinity,
    refetchInterval: Infinity,
  });

  /**
   * When token from is selected, find all tradable assets
   */
  const { assetsTo, underlyingMarkets } = useMemo<{
    underlyingMarkets: MarketPair[];
    assetsTo: AvailableAssets;
  }>(() => {
    setIsLoadingUnderlyingMarkets(true);

    if (!markets || !tokenFrom) {
      setIsLoadingUnderlyingMarkets(false);
      return {
        assetsTo: [],
        underlyingMarkets: [],
      };
    }
    let tokensAvailable: DropListWithIconItem<{
      balance: string;
      freeBalance: number;
      amount: string;
    }>[] = [];
    const underlyingMarkets: MarketPair[] = markets.filter(
      (market) => market.base === tokenFrom.label || market.quote === tokenFrom.label,
    );

    // If trade square feature supported, we allow the user to select all the available destination tokens
    if (isTradeSquareSupported) {
      // If the destination tokens are loading, we return the previous assetsTo
      // to avoid the flickering of the droplist
      if (droplistDestinationTokensAreLoading && !droplistDestinationTokens?.length) {
        setIsLoadingUnderlyingMarkets(false);
        return {
          assetsTo: prevAssetsTo.current,
          underlyingMarkets,
        };
      }

      tokensAvailable = (droplistDestinationTokens || []).map((token) => {
        // We use assets owned by the user to get the balance
        const assetOwnedByUser = assetsFrom?.find((asset) => asset.value === token.value);

        const asset: AvailableAssets[number] = {
          value: token.value,
          label: token.label,
          img: forgeTokenURL(token.value),
          balance: assetOwnedByUser?.balance || "0",
          freeBalance: assetOwnedByUser?.freeBalance || 0,
          amount: assetOwnedByUser?.amount || "0",
        };
        return asset;
      });
    } else {
      // If trade square feature not supported, we allow the user to select only the destination tokens
      // which have a common market with the source token
      tokensAvailable = underlyingMarkets.map((market) => {
        const tokenSymbol = market.base === tokenFrom.label ? market.quote : market.base;

        const tokenNameSymbol = tokenNamesAndSymbols.find((token) => token.symbol === tokenSymbol);

        const searchableTerms = [tokenSymbol];

        if (tokenNameSymbol?.name) {
          searchableTerms.push(tokenNameSymbol.name);
        }

        // We use assets owned by the user to get the balance
        const assetOwnedByUser = assetsFrom?.find((asset) => asset.value === tokenSymbol);

        const asset: AvailableAssets[number] = {
          value: tokenSymbol,
          label: tokenSymbol,
          img: forgeTokenURL(tokenSymbol),
          ...(searchableTerms && {
            searchableTerms,
          }),
          balance: assetOwnedByUser?.balance || "0",
          freeBalance: assetOwnedByUser?.freeBalance || 0,
          amount: assetOwnedByUser?.amount || "0",
        };
        return asset;
      });
    }

    const newAssetsTo = tokensAvailable.filter(
      (obj, index, self) => index === self.findIndex((t) => t["value"] === obj["value"]),
    );

    setIsLoadingUnderlyingMarkets(false);
    return {
      assetsTo: newAssetsTo,
      underlyingMarkets,
    };
  }, [markets, tokenFrom, assetsFrom, isTradeSquareSupported, droplistDestinationTokens]);

  useEffect(() => {
    prevAssetsTo.current = assetsTo;
  }, [assetsTo]);

  return {
    isLoadingBalances,
    isLoadingUnderlyingMarkets,
    assetsFrom: assetsFrom ?? [],
    assetsTo,
    underlyingMarkets,
    loadMarkets,
    marketRate: marketRate ?? EMPTY_RATE,
    tradePath,
    shouldUseTradeSquare,
    droplistDestinationTokensError,
    droplistDestinationTokensAreLoading,
    popularTokens: popularTokens ?? [],
  };
};
