import { useMemo } from "react";
import { ConnectionStatus, PublicAccount, userFacingMessages } from "@cede/types";
import { getExchangeLogo } from "@cede/utils";
import { useDependencies } from "../useDependencies";
import { UseAccountsHook } from "./types";

export const useAccounts: UseAccountsHook = () => {
  const { useVaults, useSupportedExchanges } = useDependencies();
  const { activeVault } = useVaults();
  const { downExchangesIds } = useSupportedExchanges();

  const { accounts, droplistAccounts, accountsByIds, accountsIds, areAccountsLoading } = useMemo(() => {
    if (!activeVault)
      return {
        accounts: [],
        droplistAccounts: [],
        accountsByIds: {},
        accountsIds: [],
        areAccountsLoading: true,
      };
    return {
      accounts: activeVault.accounts,
      droplistAccounts: activeVault.accounts?.map((account) => {
        return {
          label: account.accountName,
          value: account.id,
          img: getExchangeLogo(account.exchangeId),
          cexValue: account.exchangeId,
          permissions: account.permissions,
          disabledText:
            account.status === ConnectionStatus.RECONNECTION_REQUIRED
              ? userFacingMessages.ACCOUNT.RECONNECTION_REQUIRED
              : undefined,
          isDisabled:
            downExchangesIds.includes(account.exchangeId) ||
            [ConnectionStatus.OFFLINE, ConnectionStatus.RECONNECTION_REQUIRED].includes(account.status),
        };
      }),
      accountsByIds: activeVault.accounts.reduce((acc, account) => {
        acc[account.id] = account;
        return acc;
      }, {} as Record<string, PublicAccount>),
      accountsIds: activeVault.accounts.map((account) => account.id),
      areAccountsLoading: false,
    };
  }, [activeVault, downExchangesIds]);

  return {
    accounts,
    droplistAccounts,
    accountsByIds,
    accountsIds,
    areAccountsLoading,
  };
};
