import { CedeEvents, RequestArguments, UntrustedApi, UntrustedApiMethods, UntrustedApiReturn } from "@cede/types";
import { InpageProvider } from "./InpageProvider";

export enum RequestVersion {
  V1 = 1,
  V2,
  V3,
}

const latestVersion = {
  connect: RequestVersion.V2 as const,
  disconnect: RequestVersion.V1 as const,
  vaults: RequestVersion.V1 as const,
  vaultAccounts: RequestVersion.V1 as const,
  supportedExchanges: RequestVersion.V1 as const,
  getWithdrawalById: RequestVersion.V2 as const,
  transactions: RequestVersion.V2 as const,
  balances: RequestVersion.V3 as const,
  nfts: RequestVersion.V2 as const,
  openOrders: RequestVersion.V2 as const,
  retrieveOrder: RequestVersion.V2 as const,
  editOrder: RequestVersion.V2 as const,
  getMinAmounts: RequestVersion.V2 as const,
  createOrder: RequestVersion.V2 as const,
  prepareOrder: RequestVersion.V2 as const,
  getStandardizedNetworks: RequestVersion.V1 as const,
  getMarketPairs: RequestVersion.V2 as const,
  getMarketRate: RequestVersion.V2 as const,
  tickers: RequestVersion.V1 as const,
  tokens: RequestVersion.V1 as const,
  activeVault: RequestVersion.V1 as const,
  depositableTokens: RequestVersion.V2 as const,
  getNetworks: RequestVersion.V2 as const,
  deposit: RequestVersion.V2 as const,
  cancelOrder: RequestVersion.V2 as const,
  addCex: RequestVersion.V2 as const,
  withdrawToDefi: RequestVersion.V2 as const,
  withdrawableTokens: RequestVersion.V2 as const,
  getPrices: RequestVersion.V1 as const,
  prepareWithdrawal: RequestVersion.V2 as const,
  getSettings: RequestVersion.V1 as const,
  getEnvironment: RequestVersion.V1 as const,
  getAddressBook: RequestVersion.V1 as const,
  checkAddressIsWhitelisted: RequestVersion.V1 as const,
  searchTransaction: RequestVersion.V1 as const,
  getWidgetBundleUrl: RequestVersion.V1 as const,
  getWhitelistedAddresses: RequestVersion.V1 as const,
  getTradePath: RequestVersion.V1 as const,
  getSupportedTokens: RequestVersion.V1 as const,
  isWalletTypeTotupNeeded: RequestVersion.V1 as const,
  trackLink: RequestVersion.V1 as const,
  openPageWithHelper: RequestVersion.V1 as const,
  untrackLink: RequestVersion.V1 as const,
  getConfig: RequestVersion.V1 as const,
  fetchPartners: RequestVersion.V1 as const,
  getCurrentAppTab: RequestVersion.V1 as const,
  focusTab: RequestVersion.V1 as const,
  getUserId: RequestVersion.V1 as const,
  getTradePathBySupportedTokens: RequestVersion.V1 as const,
  verifyKYC: RequestVersion.V1 as const,
  getTokenNamesAndSymbols: RequestVersion.V1 as const,
  getDiscordInvitation: RequestVersion.V1 as const,
  hasJoinedDiscord: RequestVersion.V1 as const,
  getOHLCV: RequestVersion.V1 as const,
  openTab: RequestVersion.V1 as const,
};

export type LatestVersion = typeof latestVersion;

type TypeGuard = {
  [key in keyof UntrustedApi]: LatestVersion[key];
};

export class CedeProvider {
  private inpageProvider: InpageProvider;

  constructor(inpageProvider: InpageProvider) {
    this.inpageProvider = inpageProvider;
  }

  public request = async <T extends UntrustedApiMethods>(args: RequestArguments<T>): Promise<UntrustedApiReturn[T]> => {
    const guardedLatestVersion: TypeGuard = latestVersion;
    const version = guardedLatestVersion[args.method] ?? -1; // -1 if the method is non existent;

    const anyArgs = args as any; // eslint-disable-line @typescript-eslint/no-explicit-any
    // add version to params
    if (anyArgs.params) {
      anyArgs.params = { ...anyArgs.params, version };
    } else {
      anyArgs.params = { version };
    }

    return this.inpageProvider.request(anyArgs) as Promise<UntrustedApiReturn[T]>;
  };

  on = (event: CedeEvents, listener: (...args: unknown[]) => void): this => {
    this.inpageProvider.on(event, listener);
    return this;
  };

  once = (event: CedeEvents, callback: (...args: unknown[]) => void): this => {
    this.inpageProvider.on(event, callback);
    return this;
  };

  off = (event: CedeEvents, callback: (...args: unknown[]) => void): this => {
    this.inpageProvider.off(event, callback);
    return this;
  };

  removeListener = (event: CedeEvents, callback: (...args: unknown[]) => void): this => {
    this.inpageProvider.removeListener(event, callback);
    return this;
  };

  removeAllListeners = (event?: CedeEvents): this => {
    this.inpageProvider.removeAllListeners(event);
    return this;
  };

  emit = (event: CedeEvents, ...args: unknown[]): boolean => {
    return this.inpageProvider.emit(event, ...args);
  };

  getVaultPreviews = () => {
    return this.inpageProvider.getVaultPreviews();
  };

  getIsUnlocked = () => {
    return this.inpageProvider.getIsUnlocked();
  };

  getIsConnected = () => {
    return this.inpageProvider.getIsConnected();
  };

  getActiveVault = () => {
    return this.inpageProvider.getActiveVault();
  };
}
