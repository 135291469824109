import { DISCORD_INVITE_URL, QueryKeys, getErrorMessage } from "@cede/utils";
import { AlertTypes } from "../store";
import { useDependencies } from "./useDependencies";
import { useQuery } from "@tanstack/react-query";

export const useDiscordInvitation = () => {
  const { backgroundHandler, useLoading, useAlert, useLoginStore } = useDependencies();
  const addBigPendingRequest = useLoading((state) => state.addBigPendingRequest);
  const removeBigPendingRequest = useLoading((state) => state.removeBigPendingRequest);
  const appendAlert = useAlert((state) => state.append);
  const isLoggedIn = useLoginStore((state) => state.isLoggedIn);

  const {
    data: hasJoinedDiscord,
    refetch: refetchHasJoinedDiscord,
    isFetching: isLoadingHasJoinedDiscord,
  } = useQuery({
    queryKey: [QueryKeys.HAS_JOINED_DISCORD],
    queryFn: () => backgroundHandler.hasJoinedDiscord(),
    enabled: !!backgroundHandler?.isReady && isLoggedIn,
    gcTime: Infinity,
    staleTime: Infinity,
  });

  const joinDiscord = async () => {
    try {
      addBigPendingRequest();
      const discordInvitation = await backgroundHandler.getDiscordInvitation().catch(() => DISCORD_INVITE_URL);
      window.open(discordInvitation, "_blank");
      await refetchHasJoinedDiscord();
    } catch (error) {
      appendAlert(getErrorMessage(error), AlertTypes.ERROR);
    } finally {
      removeBigPendingRequest();
    }
  };

  return { joinDiscord, hasJoinedDiscord, isLoadingHasJoinedDiscord, refetchHasJoinedDiscord };
};
