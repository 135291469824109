import { DiscordIcon } from "@cede/ds";
import { useDependencies } from "../../hooks";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Link, SvgIcon, Tooltip, Typography, tooltipClasses, useTheme } from "@mui/material";

export const HelpLink = () => {
  const theme = useTheme();
  const { useDiscordInvitation } = useDependencies();
  const { joinDiscord } = useDiscordInvitation();

  // @TODO: put the link inside the feature flag management system so it's hot swappable
  return (
    <Link
      onClick={joinDiscord}
      sx={{
        cursor: "pointer",
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Tooltip
        title={
          <Typography
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            fontSize="14px"
            sx={{
              "minWidth": "164px",
              "transition": ".2s ease-out",
              ":hover": {
                opacity: 0.6,
              },
              "color": theme.palette.text.secondary,
            }}
          >
            Get help on Discord
            <Box ml={theme.custom.smallGutterMargin} display="flex" alignItems="center">
              <SvgIcon fill={theme.palette.text.secondary}>
                <DiscordIcon innerLogoColor={theme.palette.background.default} />
              </SvgIcon>
            </Box>
          </Typography>
        }
        sx={{
          fontSize: "20px",
          color: theme.palette.text.primary,
        }}
        placement="bottom-end"
        arrow
        PopperProps={{
          disablePortal: true,
          sx: {
            [`& .${tooltipClasses.tooltip}`]: {
              marginTop: "6px!important",
              padding: "8px",
              backgroundColor: theme.palette.background.help,
            },

            [`& .${tooltipClasses.arrow}`]: {
              color: theme.palette.background.help,
            },
          },
        }}
      >
        <HelpOutlineIcon color="inherit" fontSize="inherit" />
      </Tooltip>
    </Link>
  );
};
