import { useMemo } from "react";
import { Any, WhitelistedAddresses } from "@cede/types";
import { CEDE_CEXS, USE_WHITELISTED_ADDRESSES_CACHE_TIME, USE_WHITELISTED_ADDRESSES_STALE_TIME } from "@cede/utils";
import { useDependencies } from "../../hooks/useDependencies";
import { DropListWhitelistedAddress, UseWhitelistedAddressesProps } from "./types";
import { useQuery } from "@tanstack/react-query";

export const useWhitelistedAddresses = ({ accountId, tokenSymbol, network }: UseWhitelistedAddressesProps) => {
  const { useVaults, backgroundHandler, useSupportedExchanges, useAccounts } = useDependencies();

  const { activeVault } = useVaults();
  const { supportedExchangesById } = useSupportedExchanges();
  const { accountsByIds } = useAccounts();

  const exchangeSupportGetWhitelistedAddresses = useMemo(() => {
    if (!accountId || !accountsByIds || !supportedExchangesById) return false;
    const account = accountsByIds[accountId];
    if (!account) return false;
    return !!supportedExchangesById[account.exchangeId]?.provideWhitelistedAddresses;
  }, [supportedExchangesById, accountsByIds, accountId]);

  const getWhitelistedAddressQueryKey = () => {
    const account = accountsByIds[accountId];
    const key: Any[] = ["whitelistedAddresses", activeVault, accountId, exchangeSupportGetWhitelistedAddresses];
    if (account && [CEDE_CEXS.GATEIO, CEDE_CEXS.HTX].includes(account.exchangeId)) {
      key.push(tokenSymbol);
    }
    return key;
  };

  const isWhitelistedAddressQueryEnabled = () => {
    const account = accountsByIds[accountId];
    let status = !!activeVault?.id && !!backgroundHandler?.isReady && !!accountId && !!supportedExchangesById;
    if (account && [CEDE_CEXS.GATEIO, CEDE_CEXS.HTX].includes(account.exchangeId)) {
      status = status && !!tokenSymbol;
    }
    return status;
  };

  const getWhitelistedAddressParams = () => {
    const account = accountsByIds[accountId];
    if (!account || ![CEDE_CEXS.GATEIO, CEDE_CEXS.HTX].includes(account.exchangeId)) return { accountId };
    return {
      accountId,
      tokenSymbol,
    };
  };

  const {
    data: whitelistedAddresses,
    isFetching: isWhitelistedAddressesLoading,
    isError: isWhitelistedAddressesError,
    refetch: refetchWhitelistedAddresses,
  } = useQuery<WhitelistedAddresses[]>({
    queryKey: getWhitelistedAddressQueryKey(),
    queryFn: async () => {
      if (!exchangeSupportGetWhitelistedAddresses) return [];
      const addresses: WhitelistedAddresses[] = await backgroundHandler.getWhitelistedAddresses(
        getWhitelistedAddressParams(),
      );
      return addresses;
    },
    enabled: isWhitelistedAddressQueryEnabled(),
    staleTime: USE_WHITELISTED_ADDRESSES_STALE_TIME,
    gcTime: USE_WHITELISTED_ADDRESSES_CACHE_TIME,
  });

  const { filteredAddresses, droplistWhitelistedAddresses } = useMemo(() => {
    const filteredAddresses = whitelistedAddresses?.filter(
      (address) =>
        (!tokenSymbol || !address.tokenSymbol || address.tokenSymbol === tokenSymbol) &&
        (!network || address.network === network),
    );

    const droplistWhitelistedAddresses: DropListWhitelistedAddress[] =
      filteredAddresses?.map((address) => ({
        key: address.key,
        address: address.address,
        label: address.key,
        value: `${address.key}-${address.address}`,
        searchableTerms: [address.key, address.address],
        img: "",
      })) ?? [];

    return { filteredAddresses, droplistWhitelistedAddresses };
  }, [whitelistedAddresses, tokenSymbol, network]);

  return {
    whitelistedAddresses: filteredAddresses,
    isWhitelistedAddressesLoading,
    isWhitelistedAddressesError,
    droplistWhitelistedAddresses,
    refetchWhitelistedAddresses,
  };
};
