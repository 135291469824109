import { WalletType, WalletTypesConfig } from "@cede/types";
import { CEDE_CEXS } from "../../cexs";

export const WALLET_TYPES_COMMON_CONFIG: WalletTypesConfig = {
  send_wallet_types: {
    [CEDE_CEXS.OKX]: WalletType.MAIN,
    [CEDE_CEXS.BINANCE]: WalletType.SPOT,
    [CEDE_CEXS.KUCOIN]: WalletType.MAIN,
    [CEDE_CEXS.BYBIT]: WalletType.MAIN,
    [CEDE_CEXS.BITFINEX]: WalletType.SPOT,
    [CEDE_CEXS.COINBASE]: WalletType.SPOT,
    [CEDE_CEXS.KRAKEN]: WalletType.SPOT,
    [CEDE_CEXS.GATEIO]: WalletType.SPOT,
    [CEDE_CEXS.HTX]: WalletType.SPOT,
    [CEDE_CEXS.BITGET]: WalletType.SPOT,
    [CEDE_CEXS.MEXC]: WalletType.SPOT,
  },
  transfer_wallet_types: {
    [CEDE_CEXS.OKX]: [WalletType.MAIN, WalletType.SPOT],
    [CEDE_CEXS.BINANCE]: [WalletType.SPOT, WalletType.CARD],
    [CEDE_CEXS.KUCOIN]: [WalletType.MAIN, WalletType.SPOT],
    [CEDE_CEXS.BYBIT]: [WalletType.MAIN, WalletType.SPOT],
    [CEDE_CEXS.BITFINEX]: [WalletType.SPOT, WalletType.FUTURES],
    [CEDE_CEXS.COINBASE]: [WalletType.SPOT],
    [CEDE_CEXS.KRAKEN]: [WalletType.SPOT],
    [CEDE_CEXS.GATEIO]: [WalletType.SPOT],
    [CEDE_CEXS.HTX]: [WalletType.SPOT],
    [CEDE_CEXS.BITGET]: [WalletType.SPOT],
    [CEDE_CEXS.MEXC]: [WalletType.SPOT],
  },
  internal_to_external_wallet_types: {
    [CEDE_CEXS.BINANCE]: {
      [WalletType.CARD]: "funding",
      [WalletType.EARN]: "savings",
      [WalletType.SPOT]: "spot",
      [WalletType.OPTIONS]: "option",
    },
    [CEDE_CEXS.BITFINEX]: {
      [WalletType.SPOT]: "exchange",
      [WalletType.MARGIN]: "margin",
      [WalletType.FUTURES]: "funding",
      [WalletType.OTHER]: "derivatives",
    },
    [CEDE_CEXS.BYBIT]: {
      [WalletType.MAIN]: "fund",
      [WalletType.SPOT]: "spot",
    },
    [CEDE_CEXS.KUCOIN]: {
      [WalletType.SPOT]: "trade",
      [WalletType.MARGIN]: "margin",
      [WalletType.MAIN]: "main",
    },
    [CEDE_CEXS.OKX]: {
      [WalletType.MAIN]: "funding",
      [WalletType.SPOT]: "trading",
    },
  },
  external_wallet_types_parameters: {
    [CEDE_CEXS.BITGET]: {
      [WalletType.SPOT]: [
        {
          type: "spot",
        },
      ],
      [WalletType.FUTURES]: [
        { type: "swap", productType: "USDT-FUTURES" },
        { type: "swap", productType: "COIN-FUTURES" },
        { type: "swap", productType: "USDC-FUTURES" },
      ],
      [WalletType.MARGIN]: [
        {
          type: "margin",
          marginMode: "cross",
        },
        {
          type: "margin",
          marginMode: "isolated",
        },
      ],
    },
    [CEDE_CEXS.BITFINEX]: {
      [WalletType.SPOT]: [{ type: "exchange" }],
      [WalletType.MARGIN]: [{ type: "margin" }],
      [WalletType.FUTURES]: [{ type: "funding" }],
      [WalletType.OTHER]: [{ type: "derivatives" }],
    },
    [CEDE_CEXS.COINBASE]: {
      [WalletType.SPOT]: [{}], // coinbase only have 1 wallet type, spot will be used in ccxt
    },
    [CEDE_CEXS.HTX]: {
      [WalletType.SPOT]: [{}],
      [WalletType.MARGIN]: [
        {
          type: "spot",
          marginMode: "cross",
        },
        {
          type: "spot",
          marginMode: "isolated",
        },
      ],
      [WalletType.FUTURES]: [
        { type: "future", marginMode: "isolated", subType: "linear" },
        { type: "future", marginMode: "cross", subType: "linear" },
        { type: "future", subType: "inverse" },
        { type: "swap", subType: "inverse" },
        { type: "future", unified: true },
      ],
    },
    [CEDE_CEXS.KRAKEN]: {
      [WalletType.SPOT]: [{}],
      [WalletType.EARN]: [
        {
          type: "earn",
        },
      ],
    },
    [CEDE_CEXS.BYBIT]: {
      [WalletType.MAIN]: [{ type: "fund" }],
      [WalletType.SPOT]: [{ type: "spot" }],
      [WalletType.OTHER]: [{ accountType: "contract" }],
    },
    [CEDE_CEXS.BINANCE]: {
      [WalletType.CARD]: [
        {
          type: "funding",
        },
      ],
      [WalletType.EARN]: [
        {
          type: "savings",
        },
      ],
      [WalletType.FUTURES]: [
        {
          // USD-M
          type: "future",
        },
        {
          // Coin M
          type: "delivery",
        },
      ],
      [WalletType.MARGIN]: [
        {
          type: "margin",
          marginMode: "cross",
        },
        {
          type: "margin",
          marginMode: "isolated",
        },
      ],
      [WalletType.SPOT]: [{}],
      [WalletType.OPTIONS]: [
        {
          type: "option",
        },
      ],
    },
    [CEDE_CEXS.GATEIO]: {
      [WalletType.SPOT]: [
        {
          type: "spot",
          marginMode: "",
        },
      ],
      [WalletType.MARGIN]: [
        {
          type: "spot",
          marginMode: "cross_margin",
        },
        {
          type: "spot",
          marginMode: "margin",
        },
      ],
      [WalletType.FUTURES]: [
        {
          type: "future",
        },
      ],
      [WalletType.OPTIONS]: [
        {
          type: "option",
        },
      ],
      [WalletType.OTHER]: [
        {
          type: "swap",
        },
      ],
    },
    [CEDE_CEXS.OKX]: {
      [WalletType.MAIN]: [
        {
          type: "funding",
        },
      ],
      [WalletType.SPOT]: [
        {
          type: "trading",
        },
      ],
    },
    [CEDE_CEXS.KUCOIN]: {
      [WalletType.SPOT]: [
        {
          type: "trade",
        },
      ],
      [WalletType.MARGIN]: [
        {
          type: "margin",
        },
      ],
      [WalletType.MAIN]: [
        {
          type: "main",
        },
      ],
      [WalletType.FUTURES]: [
        {
          future: true,
        },
      ],
    },
    [CEDE_CEXS.BITMART]: {
      [WalletType.SPOT]: [
        {
          type: "spot",
        },
      ],
      [WalletType.FUTURES]: [
        {
          type: "swap",
        },
      ],
      [WalletType.MARGIN]: [{ type: "margin" }],
    },
    [CEDE_CEXS.MEXC]: {
      [WalletType.SPOT]: [
        {
          type: "spot",
        },
      ],
      [WalletType.FUTURES]: [
        {
          type: "swap",
        },
      ],
    },
  },
};
