/*
 * Copyright (C) CEDE Labs, SAS - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by CEDE Labs team <team@cedelabs.io>, September 2021
 */
import { Any, ExchangeStatus, VaultItem } from "@cede/types";
import { onElementAvailable } from "@cede/utils";
import { BaseProviderState, InpageProviderApi, InpageProviderApiMethods } from "./types";
import ObjectMultiplex from "@metamask/object-multiplex";
import {
  BaseProviderOptions,
  JsonRpcConnection,
  UnvalidatedJsonRpcRequest,
} from "@metamask/providers/dist/BaseProvider";
import messages from "@metamask/providers/dist/messages";
import { EMITTED_NOTIFICATIONS, getRpcPromiseCallback } from "@metamask/providers/dist/utils";
import SafeEventEmitter from "@metamask/safe-event-emitter";
import { ethErrors } from "eth-rpc-errors";
import { duplex as isDuplex } from "is-stream";
import { JsonRpcEngine, JsonRpcRequest, JsonRpcResponse, createIdRemapMiddleware } from "json-rpc-engine";
import { createStreamMiddleware } from "json-rpc-middleware-stream";
import pump, { Callback } from "pump";
import { Duplex } from "stream";

/* eslint-disable no-console */

export const PROVIDER = "cede-provider";

export class InpageProvider extends SafeEventEmitter {
  protected _state: BaseProviderState;
  protected _rpcEngine: JsonRpcEngine;
  protected _jsonRpcConnection: JsonRpcConnection;
  protected _connectionStream: Duplex;

  protected static _defaultState: BaseProviderState = {
    vaultPreviews: null,
    isConnected: false,
    isUnlocked: false,
  };

  constructor(
    connectionStream: Duplex,
    { jsonRpcStreamName = PROVIDER, maxEventListeners = 100 }: BaseProviderOptions = {},
  ) {
    super();
    this._connectionStream = connectionStream;

    connectionStream.addListener("close", () => {
      console.log("Connection closed !");
    });

    if (!isDuplex(connectionStream)) {
      throw new Error(messages.errors.invalidDuplexStream());
    }

    this.setMaxListeners(maxEventListeners);

    // setup default private state
    this._state = structuredClone({
      ...InpageProvider._defaultState,
    });

    // bind functions (to prevent consumers from making unbound calls)
    this._handleStreamDisconnect = this._handleStreamDisconnect.bind(this);
    this.request = this.request.bind(this);

    // setup connectionStream multiplexing
    const mux = new ObjectMultiplex();
    pump(
      connectionStream,
      mux as unknown as Duplex,
      connectionStream,
      this._handleStreamDisconnect.bind(this, "CEDE.store") as Callback,
    );

    // EIP-1193 connect
    this.on("connect", () => {
      this._state.isConnected = true;
    });

    // setup RPC connection
    this._jsonRpcConnection = createStreamMiddleware() as unknown as JsonRpcConnection;
    pump(
      this._jsonRpcConnection.stream,
      mux.createStream(jsonRpcStreamName) as unknown as Duplex,
      this._jsonRpcConnection.stream,
      this._handleStreamDisconnect.bind(this, "CEDE.store RpcProvider") as Callback,
    );

    // handle RPC requests via dapp-side rpc engine
    const rpcEngine = new JsonRpcEngine();
    rpcEngine.push(createIdRemapMiddleware());
    rpcEngine.push(this._jsonRpcConnection.middleware);
    this._rpcEngine = rpcEngine;

    // handle JSON-RPC notifications
    this._jsonRpcConnection.events.on("notification", (payload) => {
      const { method, params } = payload;
      if (EMITTED_NOTIFICATIONS.includes(method)) {
        this.emit("message", {
          type: method,
          data: params,
        });
      } else if (method === "STREAM_FAILURE") {
        connectionStream.destroy(new Error(messages.errors.permanentlyDisconnected()));
      } else {
        this.executeMethod(method, params);
      }
    });
  }

  /**
   * Called when connection is lost to critical streams.
   *
   * @emits InpageProvider#disconnect
   */
  protected _handleStreamDisconnect(streamName: string, error: Error) {
    console.log(`Critical stream failure for ${streamName}`);
    if (this._state.isConnected) {
      this._state.isConnected = false;
      this._state.vaultPreviews = null;
      this._state.isUnlocked = false;
      this.emit("disconnect", error);
    }
  }

  /**
   * Internal RPC method. Forwards requests to background via the RPC engine.
   * Also remap ids inbound and outbound.
   *
   * @param payload - The RPC request object.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public request = async (args: any) => {
    if (!args || typeof args !== "object" || Array.isArray(args)) {
      throw ethErrors.rpc.invalidRequest({
        message: messages.errors.invalidRequestArgs(),
        data: args,
      });
    }

    const { method, params } = args;

    if (typeof method !== "string" || method.length === 0) {
      throw ethErrors.rpc.invalidRequest({
        message: messages.errors.invalidRequestMethod(),
        data: args,
      });
    }

    if (params !== undefined && !Array.isArray(params) && (typeof params !== "object" || params === null)) {
      throw ethErrors.rpc.invalidRequest({
        message: messages.errors.invalidRequestParams(),
        data: args,
      });
    }

    if (!params?.version) {
      throw new Error("No version provided");
    }

    const executedMethodPromise = new Promise((resolve, reject) => {
      const payload = { method, params } as UnvalidatedJsonRpcRequest;
      const callback = getRpcPromiseCallback(resolve, reject) as (
        error: unknown,
        response: JsonRpcResponse<unknown>,
      ) => void;

      if (!payload.jsonrpc) {
        payload.jsonrpc = "2.0";
      }
      return this._rpcEngine.handle(payload as JsonRpcRequest<unknown>, callback);
    });

    // If the method is connect, in any cases we wan't to update the state if it doesn't throw
    if (method === "connect") {
      executedMethodPromise
        .then((isConnected) => {
          if (isConnected) {
            this._state.isUnlocked = true;
          }
          // We need to handle the error silently because we return the promise so the dApp should handle it itself
        })
        .catch((e) => e);
    }

    return executedMethodPromise;
  };

  /**
   * When the provider becomes connected, updates internal state and emits
   * required events. Idempotent.
   *
   * @emits connect event
   */
  protected _connectionEstablished() {
    console.log("Connection established !");
    if (!this._state.isConnected) {
      this._state.isConnected = true;
      this.emit("connect");
    }
  }

  protected async _updateVaults(vaultPreviews: { vaultsPreview: VaultItem[] }) {
    this._state.vaultPreviews = vaultPreviews.vaultsPreview;

    // Trigger accountsChanged event to automatically refresh the dapp data
    this.emit("accountsChanged", [
      (vaultPreviews as Any).vaultsPreview.find((vault: VaultItem) => vault.isActive)?.name,
    ]);
  }

  protected async onExchangesStatusesUpdate(exchangesStatuses: Record<string, ExchangeStatus>) {
    this.emit("exchangesStatusesUpdate", { exchangesStatuses });
  }

  protected _unlock() {
    this._state.isUnlocked = true;
    this.emit("unlock");
  }

  protected _lock() {
    this._state.isUnlocked = false;
    this._state.vaultPreviews = [];
    this.emit("lock");
  }

  protected _trackLogin({
    tabId,
    redirectLink,
    websiteHomelinkIdentifier,
    notConnectedIdentifier,
  }: {
    tabId: number;
    redirectLink: string;
    websiteHomelinkIdentifier: string;
    notConnectedIdentifier: string;
  }) {
    const handleRedirection = () => {
      const redirectUrl = new URL(redirectLink);
      const isConnected =
        !document.querySelector(notConnectedIdentifier) &&
        document.querySelector<HTMLLinkElement>(websiteHomelinkIdentifier);

      if (isConnected) {
        this.request({ method: "untrackLink", params: { tabId, version: 1 } });

        if (!window.location.pathname.includes(redirectUrl.pathname)) {
          window.location.href = redirectLink;
        }

        return;
      }
    };

    onElementAvailable(
      notConnectedIdentifier,
      () => {
        handleRedirection();

        window.addEventListener("hashchange", handleRedirection);
      },
      websiteHomelinkIdentifier,
    );
  }

  protected getNotificationApi() {
    const api = {
      ["connect"]: this._connectionEstablished.bind(this),
      ["accountsChanged"]: this._updateVaults.bind(this),
      ["exchangesStatusesUpdate"]: this.onExchangesStatusesUpdate.bind(this),
      ["unlock"]: this._unlock.bind(this),
      ["lock"]: this._lock.bind(this),
      ["trackLogin"]: this._trackLogin.bind(this),
      ["sendTxStatusUpdate"]: this.emit.bind(this, "sendTxStatusUpdate"),
    };
    return api;
  }

  protected executeMethod<T extends InpageProviderApiMethods>(
    method: InpageProviderApiMethods,
    ...params: Any[]
  ): ReturnType<InpageProviderApi[T]> {
    const api = this.getNotificationApi();
    const methodFn = api[method] as InpageProviderApi[T];
    if (!methodFn) throw new Error(`Method not found : ${method}`);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return methodFn(...params);
  }

  getIsUnlocked() {
    return this._state.isUnlocked;
  }

  getIsConnected() {
    return this._state.isConnected;
  }

  getVaultPreviews() {
    return this._state.vaultPreviews;
  }

  getActiveVault() {
    return (this.getVaultPreviews() || []).find((vault: VaultItem) => vault.isActive);
  }
}
