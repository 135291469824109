import ccxt from "@cede/ccxt";
import { DropListWithIconItem, MarketRate } from "@cede/types";
import { QueryKeys, getCurrentRate, getErrorMessage } from "@cede/utils";
import { AlertTypes } from "../store";
import { useDependencies } from "./useDependencies";
import { isMultipleTransactionsPreparedOrder } from "./useMultipleTransactions";
import { useQuery } from "@tanstack/react-query";

type UseMultipleTransactionsFeesParams = {
  enabled: boolean;
  accountId: string;
  fromToken: string;
  /**
   * The amount of the fromToken is needed to compute the fees
   */
  fromTokenAmount: string;
  toToken: string;
  activeWithdrawal: boolean;
  network?: DropListWithIconItem<{
    withdrawMin?: string;
    withdrawFee?: string;
  }>;
  isInternalTransfer?: boolean;
};

// We compute the fees in the from currency
// We do the opposite operation regarding the classic order since we are reverting the order side
// If the orderSide is "buy" we need to multiply the fees by ask price of the market
// If the orderSide is "sell" we need to divide the fees by bid price of the market
const reverseComputeAmount = (amount: string, price: string, orderSide: "buy" | "sell") => {
  return orderSide === "buy" ? ccxt.Precise.stringMul(amount, price) : ccxt.Precise.stringDiv(amount, price);
};

/**
 * Hook to compute the fees for multiple transactions
 * !!important: You need to load the multiple transactions before using this hook
 */
export const useMultipleTransactionsFees = ({
  enabled,
  accountId,
  fromToken,
  fromTokenAmount,
  toToken,
  activeWithdrawal,
  network,
  isInternalTransfer = false,
}: UseMultipleTransactionsFeesParams) => {
  const { backgroundHandler, useNetworks, useTradePath, useMultipleTransactions, useAlert } = useDependencies();
  const { tradePath, isLoading: tradePathIsLoading } = useTradePath({
    enabled,
    accountId,
    fromToken,
    toToken,
    activeWithdrawal,
    network: network?.value,
  });
  const { droplistNetworks: networksForDestinationCurrency } = useNetworks({
    toDeposit: false,
    toWithdraw: true,
    accountId,
    tokenSymbol: toToken, // in order to get the withdrawMin and withdrawFee
    isInternalTransfer,
  });
  const appendAlert = useAlert((state) => state.append);

  const { transactions, isTxLoading } = useMultipleTransactions({
    accountId,
    fromTokenAmount,
    tradePath,
  });

  /**
   *
   * @returns The fees in the from currency
   */
  const _computeFees = async () => {
    if (!tradePath) return "0";
    try {
      const networkForDestinationCurrency = networksForDestinationCurrency.find(
        (destinationCurrencyNetwork) => destinationCurrencyNetwork.value === network?.value,
      );
      let _fees = networkForDestinationCurrency?.withdrawFee || "0";

      // We need to reverse the trade path in order to start from the final currency
      // and convert through the iterations to the first one
      for (const tradePathData of [...tradePath].reverse()) {
        const { pairSymbol, orderSide, from, to } = tradePathData;

        const marketRate: MarketRate = await backgroundHandler.getMarketRate({
          accountId,
          pairSymbol: pairSymbol,
        });

        const price = getCurrentRate(marketRate, orderSide);

        _fees = reverseComputeAmount(_fees, price, orderSide);

        const preparedOrder = transactions.find(
          (tx) => isMultipleTransactionsPreparedOrder(tx) && tx.request.pairSymbol === pairSymbol,
        );

        if (preparedOrder && isMultipleTransactionsPreparedOrder(preparedOrder)) {
          const feeAmount = String(preparedOrder.estimatedFee.amount);
          // We compute the fees in the from currency
          const newFees =
            preparedOrder.estimatedFee.tokenSymbol === from
              ? String(preparedOrder.estimatedFee.amount)
              : preparedOrder.estimatedFee.tokenSymbol === to
              ? reverseComputeAmount(feeAmount, price, orderSide)
              : "0";

          _fees = ccxt.Precise.stringAdd(_fees, newFees);
        }
      }
      return _fees;
    } catch (error) {
      appendAlert(getErrorMessage(error), AlertTypes.ERROR);
      return "0";
    }
  };

  const { data: multipleTransactionFeesInFromToken, isFetching } = useQuery({
    queryKey: [QueryKeys.MULTIPLE_TX_FEES, tradePath, accountId, fromTokenAmount, transactions],
    queryFn: _computeFees,
    enabled: !!tradePath && !!accountId && transactions.length > 0 && enabled && !isTxLoading,
  });

  return {
    multipleTransactionFeesInFromToken,
    isLoading: isFetching || isTxLoading || tradePathIsLoading,
  };
};
