import PandaIcon from "../../assets/storeLogoBorderless.svg";
import { StoreLogo } from "@cede/ds";
import { LogoProps, LogoSizeEnum } from "./Logo.types";
import { Box, useTheme } from "@mui/material";

export const Logo = ({ size, isLoading = false }: LogoProps) => {
  const theme = useTheme();

  const imageSize = (() => {
    if (size === LogoSizeEnum.LARGE) {
      return theme.custom.imgSize.large;
    }
    if (size === LogoSizeEnum.MEDIUM) {
      return theme.custom.imgSize.medium;
    }
    if (size === LogoSizeEnum.SMALL) {
      return theme.custom.imgSize.small;
    }
    return theme.custom.imgSize.large;
  })();

  const borderSize = (() => {
    if (size === LogoSizeEnum.LARGE) {
      return "6px";
    }
    if (size === LogoSizeEnum.MEDIUM) {
      return "3px";
    }
    if (size === LogoSizeEnum.SMALL) {
      return "2px";
    }
    return "6px";
  })();

  if (isLoading) {
    return (
      <>
        <Box
          sx={{
            "position": "relative",
            "display": "flex",
            "alignItems": "center",
            "justifyContent": "center",
            "height": imageSize,
            "width": imageSize,
            "padding": borderSize,
            "overflow": "hidden",
            "borderRadius": "25%",
            "boxSizing": "border-box",
            "::before": {
              "content": "''",
              "position": "absolute",
              "top": "-20px",
              "bottom": "-20px",
              "left": "-20px",
              "right": "-20px",
              "background": `linear-gradient(${theme.palette.logoBorderColor}, ${theme.palette.logoBorderColor}, ${theme.palette.logoBorderColor}, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))`,
              "zIndex": -1,
              "borderRadius": "50%",
              "animation": "spinner-spin 2s linear infinite",
              "@keyframes spinner-spin": {
                "100%": {
                  transform: "rotate(360deg)",
                },
              },
            },
          }}
        >
          <Box
            component="img"
            src={PandaIcon}
            alt="Cede Store panda icon"
            sx={{
              height: "100%",
              width: "100%",
            }}
          />
        </Box>
      </>
    );
  }

  return <StoreLogo size={size} alt="Cede Store panda icon" />;
};
