import { Button } from "@cede/ds";
import { ApiPermissions, DropListWithIconItem, ViewEnvironment, userFacingMessages } from "@cede/types";
import { STORE_ROUTES } from "@cede/utils";
import { useDependencies } from "../../hooks";
import { htmlExports } from "../../utils";
import { useTheme } from "@mui/material";

export const DroplistMissingPermission = ({
  option,
}: {
  option: DropListWithIconItem<{ cexValue?: string; permissions?: ApiPermissions[] }>;
}) => {
  const { useViewEnvironment, useSettings, useNavigate, backgroundHandler } = useDependencies();
  const theme = useTheme();
  const { environment } = useViewEnvironment();
  const navigate = useNavigate();
  const { extensionUrl } = useSettings();

  return (
    <Button
      variant="text"
      sx={{
        "padding": 0,
        "color": theme.palette.primary.main,
        "height": "auto",
        "fontSize": "14px",
        "&:hover": {
          background: "inherit",
          opacity: ".6",
        },
      }}
      onClick={() => {
        if (!option.cexValue) return;

        const url = `${STORE_ROUTES.STATE_MACHINE_ROUTER}?accountId=${option.value}&fmState=EditApiKeysPermissions&mode=update`;

        if (environment === ViewEnvironment.WIDGET) {
          return window.open(`${extensionUrl}${htmlExports.popupHtml.replace("/", "")}#${url}`, "_blank");
        }

        if (environment === ViewEnvironment.TAB) {
          return navigate(url);
        }

        backgroundHandler?.openTab({
          route: url,
        });
      }}
    >
      {userFacingMessages.EXCHANGES_STATUSES.ADD_PERMISSION}
    </Button>
  );
};
