// ----------------------------------------------------------------------------

// PLEASE DO NOT EDIT THIS FILE, IT IS GENERATED AND WILL BE OVERWRITTEN:
// https://github.com/ccxt/ccxt/blob/master/CONTRIBUTING.md#how-to-contribute-code
// EDIT THE CORRESPONDENT .ts FILE INSTEAD

import { SdkErrorCodes, SdkErrorMessages } from "./errorsMetadata.js";
import { CedeSDKError } from "./default.js";
/**
 * @description Thrown when there is a network error
 */
export class NetworkError extends CedeSDKError {
    constructor(code = SdkErrorCodes.NETWORK_ERROR, message = SdkErrorMessages.NETWORK_ERROR) {
        super(code, message);
        this.name = "NetworkError";
    }
}
/**
 * @description Thrown when the network is not found
 */
export class NetworkNotFoundError extends NetworkError {
    constructor(network) {
        super(SdkErrorCodes.NETWORK_NOT_FOUND, SdkErrorMessages.NETWORK_NOT_FOUND(network));
        this.name = "NetworkNotFoundError";
    }
}
/**
 * @description Thrown when the network is not found for the token symbol
 */
export class NetworkNotFoundForTokenSymbol extends NetworkError {
    constructor(symbol) {
        super(SdkErrorCodes.NETWORK_NOT_FOUND_FOR_TOKEN_SYMBOL, SdkErrorMessages.NETWORK_NOT_FOUND_FOR_TOKEN_SYMBOL(symbol));
        this.name = "NetworkNotFoundForTokenSymbol";
    }
}
