import { useEffect, useRef } from "react";
import { TutorialListProps } from "./types";
import { useTheme, Box } from "@mui/material";

export function TutorialList(props: TutorialListProps) {
  const theme = useTheme();
  const listRef = useRef<HTMLUListElement>(null);

  const activateStrippable = (li: HTMLLIElement) => {
    li.addEventListener("click", () => {
      li.style.textDecoration = li.style.textDecoration === "line-through" ? "none" : "line-through";
    });
  };

  useEffect(() => {
    if (props.strippable === "true" && listRef.current) {
      const liItems = listRef.current.querySelectorAll("li");
      liItems.forEach((li) => activateStrippable(li));
    }

    return () => {
      if (listRef.current) {
        const liItems = listRef.current.querySelectorAll("li");
        liItems.forEach((li) => li.replaceWith(li.cloneNode(true)));
      }
    };
  }, [props.strippable, listRef.current]);

  return (
    <Box
      ref={listRef}
      component={"ul"}
      sx={{
        "mt": 2,
        "color": theme.palette.text.primary,
        "*": {
          cursor: props.strippable ? "pointer" : "default",
          fontSize: theme.typography.body1.fontSize,
        },
      }}
      alignSelf="flex-start"
    >
      {props.children}
    </Box>
  );
}
