import { POST_ON_TWITTER_URL } from "@cede/utils";
import { XIcon } from "../SharedIcons";
import { ShareOnTwitterProps } from "./types";
import { Box, Link, SvgIcon, useTheme } from "@mui/material";

function mapContent(content: string, mappingTable: Record<string, string | undefined>) {
  return Object.entries(mappingTable).reduce((acc, [key, value]) => {
    if (!value) return acc;
    return acc.replace(`{${key}}`, value);
  }, content);
}

/**
 * @param content - The content to share on Twitter
 * @param mappingTable - If the content contains placeholders like "{key}", this object will be used to replace them with the corresponding value
 * @param props - Basic props for the component (id, testId, sx)
 */
export const ShareOnTwitter = ({ content, mappingTable, ...props }: ShareOnTwitterProps) => {
  const theme = useTheme();

  const mappedContent = mapContent(content, mappingTable);
  const twitterUrl = `${POST_ON_TWITTER_URL}${encodeURIComponent(mappedContent)}`;

  return (
    <Box
      id={props.id}
      data-testId={props.testId}
      sx={{
        padding: theme.custom.smallGutterMargin,
        ...props.sx,
      }}
    >
      <Link
        href={twitterUrl}
        sx={{
          color: theme.palette.text.primary,
          display: "flex",
          columnGap: theme.custom.smallGutterMargin,
          marginLeft: "auto",
          alignItems: "center",
        }}
        target="_blank"
        rel="noreferrer"
      >
        Share on
        <SvgIcon
          component="svg"
          sx={{
            fontSize: "14px",
            fill: theme.palette.text.primary,
          }}
          viewBox="0 0 14 14"
        >
          <XIcon innerLogoColor={theme.palette.text.primary} />
        </SvgIcon>
      </Link>
    </Box>
  );
};
