import TabBackground from "@cede/ds/src/assets/tab.png";
import { Socials } from "@cedelabs/react-utils";
import { CedeSend } from "@cedelabs/widgets/src";
import { Box, useTheme } from "@mui/material";

const queryParams = new URLSearchParams(window.location.search);

const config = {
  tokenSymbol: queryParams.get("tokenSymbol") ?? undefined,
  network: queryParams.get("network") ?? undefined,
  amount: queryParams.get("amount") ?? undefined,
  address: queryParams.get("address") ?? undefined,
};

function App() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${TabBackground})`,
        backgroundSize: "cover",
      }}
    >
      <Box>
        <CedeSend
          config={config}
          theme={{
            width: theme.custom.storeSize.tabWidth,
          }}
        />
      </Box>
      <Box
        sx={{
          alignSelf: "flex-end",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: theme.custom.mediumGutterMargin,
        }}
      >
        <Socials />
      </Box>
    </Box>
  );
}

export default App;
