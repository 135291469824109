import { Icon } from "../Icon/Icon";
import { IconTextSubTextProps } from "./types";
import { Box, Typography, useTheme } from "@mui/material";

export const IconTextSubText = (props: IconTextSubTextProps) => {
  const theme = useTheme();
  return (
    <Box display={"flex"} alignItems="center" gap={theme.custom.smallGutterMargin} width="100%" sx={props.sx}>
      <Icon iconUrl={props.icon} iconChildUrl={props.subIcon} iconName={props.iconName} />
      <Box
        display={"flex"}
        flexDirection="column"
        alignItems={"flex-start"}
        overflow="hidden"
        width="100%"
        sx={props.sxSubGroup}
      >
        <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100%" }}>
          <Typography noWrap typography={"body1"}>
            {props.text}
          </Typography>
        </Box>
        <Typography component={typeof props.subText === "string" ? "p" : "div"} typography={"body2"}>
          {props.subText}
        </Typography>
      </Box>
    </Box>
  );
};
