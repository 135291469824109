import { ConnectParams, userFacingMessages } from "@cede/types";
import { useDependencies } from "./useDependencies";

export const useWidgetLogin = () => {
  const { useLoading, backgroundHandler, useLogin, useOnboardingModal } = useDependencies();
  const addBigPendingRequest = useLoading((state) => state.addBigPendingRequest);
  const removeBigPendingRequest = useLoading((state) => state.removeBigPendingRequest);
  const setDescription = useLoading((state) => state.setDescription);
  const { isLoggedIn, handleLogin: login, isLoading, isError } = useLogin();

  const { isOnboardingModalOpened, setIsOnboardingModalOpened } = useOnboardingModal();

  const openOnboardingModal = () => {
    setIsOnboardingModalOpened(true);
  };

  const closeOnboardingModal = () => {
    setIsOnboardingModalOpened(false);
    removeBigPendingRequest();
  };

  const handleLogin = async (props: ConnectParams) => {
    addBigPendingRequest();
    setDescription(
      <>
        {userFacingMessages.WIDGETS.SEND.LOGIN_LOADING_FIRST_LINE}
        <br />
        {userFacingMessages.WIDGETS.SEND.LOGIN_LOADING_SECOND_LINE}
      </>,
    );
    if (!backgroundHandler && !props.silent) {
      openOnboardingModal();

      return;
    }

    try {
      await login(props);
      // Silently handle cancel of login
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setDescription(null);
      removeBigPendingRequest();
    }
  };

  return {
    handleLogin,
    isLoading,
    isError,
    closeOnboardingModal,
    isOnboardingModalOpened,
    isLoggedIn,
  };
};
