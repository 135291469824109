// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Any = any;
// eslint-disable-next-line @typescript-eslint/ban-types
export type TestSelector = Function;
export type JSONLikeObject =
  | undefined
  | string
  | number
  | boolean
  | null
  | { [x: string]: JSONLikeObject }
  | Array<JSONLikeObject>;

export function checkObject(obj: JSONLikeObject): obj is { [x: string]: JSONLikeObject } {
  return !!obj && Object.keys(obj).length > 0;
}

export function checkArray(obj: JSONLikeObject): obj is Array<JSONLikeObject> {
  return Array.isArray(obj);
}

export function toObject(obj: JSONLikeObject): { [x: string]: JSONLikeObject } {
  if (!checkObject(obj)) return {};
  return obj as unknown as { [x: string]: JSONLikeObject };
}

export function toArray(obj: JSONLikeObject): Array<JSONLikeObject> {
  if (!checkArray(obj)) return [];
  return obj as unknown as Array<JSONLikeObject>;
}
