import { Fragment, useEffect, useRef, useState } from "react";
import { isChildOf } from "@cede/utils";
import { getDomRoot } from "../../utils/getDomRoot";
import { ResizeFieldsWrapperProps } from "./types";
import { Box, SxProps, Theme, useTheme } from "@mui/material";

export const ResizeFieldsWrapper = ({
  fieldElements,
  id,
  middleElement,
  sx,
  focusRef,
  indexesDisabled,
}: ResizeFieldsWrapperProps) => {
  const theme = useTheme();

  const fieldRefs = useRef<HTMLElement[]>([]);
  const componentRef = useRef<HTMLElement>(null);
  const [focusElement, setFocusElement] = useState<number>(-1);

  useEffect(() => {
    const handler = (e: globalThis.Event) => {
      if (!fieldRefs.current.some((field) => isChildOf(e.target, field))) {
        unFocus();
      }
    };

    if (!componentRef.current) return;
    const root = getDomRoot(componentRef.current);

    root.addEventListener("click", handler);
    return () => root.removeEventListener("click", handler);
  }, []);

  const unFocus = () => {
    setFocusElement(-1);
  };

  useEffect(() => {
    if (focusRef) {
      focusRef.current.unFocus = unFocus;
    }
  }, []);

  return (
    <Box
      ref={componentRef}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        ...sx,
      }}
    >
      {fieldElements.map((field, index) => {
        let removeStyle: SxProps<Theme> = {};
        let width = `calc(${100 / fieldElements.length}% - calc(${theme.custom.gutterMargin}) / 2)`;

        if (focusElement !== -1) {
          width = focusElement === index ? "100%" : "0%";

          if (focusElement !== index) {
            removeStyle = { overflowX: "hidden" };
          }
        }

        return (
          <Fragment key={index}>
            {index > 0 && focusElement === -1 && middleElement}
            <Box
              sx={{
                width,
                transition: "width 0.3s",
                opacity: 1,
                ...removeStyle,
              }}
              onClick={() => {
                if (indexesDisabled?.includes(index)) return;
                setFocusElement(index);
              }}
              ref={(element: HTMLElement) => {
                fieldRefs.current[index] = element;
              }}
              data-testid={`${id || ""}-wrapper-${index}`}
            >
              {field}
            </Box>
          </Fragment>
        );
      })}
    </Box>
  );
};
