import { ReactElement } from "react";

export enum EmptyStateTypeEnum {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export interface EmptyStateProps {
  title: string;
  icon?: ReactElement;
  description?: string;
  type?: EmptyStateTypeEnum;
  actionButton?: ReactElement;
}
