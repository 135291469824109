import { CustomButtonIconVariantProps, CustomButtonProps } from "./types";
import { Box, CircularProgress, useTheme } from "@mui/material";
import { default as MuiButton } from "@mui/material/Button";
import { SystemCssProperties } from "@mui/system";

export const Button = ({ rounded, sx = {}, ...props }: CustomButtonProps) => {
  return (
    <MuiButton
      sx={[
        (theme) => ({
          borderRadius: rounded ? theme.custom.buttonRoundedRadius : theme.custom.buttonDefaultRadius,
          height: props.height === "tall" ? "50px" : "36px",
          textTransform: "none",
          fontSize: theme.typography.body1.fontSize,
        }),
        sx as SystemCssProperties,
      ]}
      {...props}
    >
      {props.loading ? <CircularProgress color="inherit" /> : props.children}
    </MuiButton>
  );
};

Button.IconVariant = ({ children, icon, sx, ...props }: CustomButtonIconVariantProps) => {
  const theme = useTheme();
  return (
    <Button
      {...props}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: theme.custom.gutterMargin,
        ...(sx || {}),
      }}
    >
      <Box component={"span"}>{children}</Box>
      {icon}
    </Button>
  );
};
