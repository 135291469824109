import chromeWebStore from "../assets/chromeWebStore.svg";
import storeLogo from "@cede/ds/src/assets/storeLogo.svg";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Fade, IconButton, Modal, ModalProps, Typography, useTheme } from "@mui/material";

export type CedeOnboardingModalProps = {
  open: ModalProps["open"];
  exit: VoidFunction;
  onClose?: ModalProps["onClose"];
  readOnlyOnboarding?: boolean;
  callbackUrl?: string;
};

export const CedeOnboardingModal = (props: CedeOnboardingModalProps) => {
  const theme = useTheme();
  const handleExit = () => {
    localStorage.removeItem("cede.store-onboarding");
    props.exit();
  };

  const onClick = () => {
    // stores a value in the local storage for the extension to know from where the user installed the extension
    localStorage.setItem(
      "cede.store-onboarding",
      JSON.stringify({ readOnlyOnboarding: props.readOnlyOnboarding, callbackUrl: props.callbackUrl }),
    );
    window.open("https://chrome.google.com/webstore/detail/cedestore/dcfphbfmjohnlcpelbhfbjolhmmlcbhd", "_blank");
  };

  return (
    <Modal
      {...props}
      aria-labelledby="cede-modal-onboarding"
      aria-describedby="cede-modal-onboarding"
      id="cede-modal-onboarding"
      closeAfterTransition
      disablePortal={true}
    >
      <Fade in={props.open}>
        <Box
          sx={{
            outline: "none",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "100%",
            width: "600px",
            height: "500px",
            borderRadius: "14px",
            margin: "auto",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            backgroundColor: theme.palette.background.default,
            overflowY: "auto",
            overflowX: "hidden",
            borderColor: "none",
            boxShadow: `0px 4px 70px 50px #5EA6FF26`,
          }}
        >
          <Box
            component="header"
            display={"flex"}
            flexDirection={"row-reverse"}
            width="auto"
            minHeight="50px"
            paddingX="8px"
            paddingY="8px"
          >
            <IconButton onClick={handleExit} disabled={false}>
              <CloseIcon sx={{ color: "#9B9B9B" }} />
            </IconButton>
          </Box>
          <Box
            component="section"
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            paddingTop={"10px"}
            paddingBottom={"25px"}
            sx={{
              height: "100%",
              width: "auto",
              paddingX: "50px",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box
                component="img"
                sx={{
                  height: "110px",
                  width: "110px",
                }}
                alt={"Cede.store logo"}
                src={storeLogo}
              />

              <Typography
                component="h1"
                fontFamily="'Inter', sans-serif"
                fontWeight="600"
                marginTop="16px"
                fontSize="24px"
                color={theme.palette.text.primary}
                mt="28px"
              >
                <Box
                  component="span"
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                >
                  Cede.store
                </Box>{" "}
                is not detected
              </Typography>

              <Typography
                component="p"
                fontFamily="'Inter', sans-serif"
                color={theme.palette.text.secondary}
                fontSize="14px"
                lineHeight="18px"
                fontWeight="400"
                sx={{ marginTop: "20px" }}
              >
                To proceed, you will need to <strong>download</strong> the extension from the{" "}
                <strong>Chrome Web Store</strong>. Let you guide by the <strong>onboarding flow</strong>.
              </Typography>
              <Typography
                component="p"
                fontFamily="'Inter', sans-serif"
                color={theme.palette.text.secondary}
                fontSize="14px"
                lineHeight="18px"
                fontWeight="400"
                sx={{ marginTop: "35px" }}
              >
                Click below to access the Chrome Web Store
              </Typography>
            </Box>

            <Box
              component="img"
              sx={{
                "height": "67px",
                "width": "220px",
                "backgroundColor": "#FFFFFF",
                "borderRadius": "13px",
                "paddingX": "10px",
                "cursor": "pointer",
                "transition": "box-shadow 0.3s ease-in-out",
                "boxShadow": "0px 0px 0px 0px rgba(94, 166, 255, 0.31)",
                "marginTop": "20px",
                "border": `1px solid ${theme.palette.cedeComponentHr}`,
                ":hover": {
                  boxShadow: "0px 0px 20px 14px rgba(94, 166, 255, 0.31)",
                },
              }}
              alt={"Download from Chrome Web Store"}
              src={chromeWebStore}
              onClick={onClick}
            />
            <Box
              marginTop={"30px"}
              component={"a"}
              target="_blank"
              href="https://cede.store"
              color={theme.palette.text.secondary}
            >
              Discover more about cede.store
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
