import ccxt from "@cede/ccxt";
import { MarketRate, TradeForm } from "@cede/types";
import { unformatBalance } from "@cede/utils";
import { getLastOrderTransaction } from "../../../hooks/useMultipleTransactions";
import {
  MultipleTransactionsPreparedOrder,
  MultipleTransactionsPreparedWithdrawal,
} from "../../../hooks/useMultipleTransactionsStore";
import { TradeFormAction } from "../types";

type UseTradeAmountComputation = {
  tradeForm: TradeForm;
  dispatchForm: (value: TradeFormAction<TradeForm>) => void;
  transactions: (MultipleTransactionsPreparedOrder | MultipleTransactionsPreparedWithdrawal)[];
  shouldUseTradeSquare: boolean;
  marketRate: MarketRate;
};

export const useTradeAmountComputation = ({
  tradeForm,
  dispatchForm,
  transactions,
  shouldUseTradeSquare,
  marketRate,
}: UseTradeAmountComputation) => {
  const computeAmountTo = () => {
    if (!tradeForm.tokenTo || !tradeForm.tokenFrom || tradeForm.amountFrom === "0") {
      dispatchForm({
        field: "amountTo",
        value: "0",
      });
      return;
    }

    if (transactions && transactions.length && shouldUseTradeSquare) {
      dispatchForm({
        field: "amountTo",
        value: getLastOrderTransaction(transactions)?.to?.amount || "0",
      });
      return;
    }

    const rate = tradeForm.orderSide === "buy" ? marketRate.ask : marketRate.bid;

    if (!tradeForm.market || !tradeForm.tokenFrom || !tradeForm.tokenTo || !tradeForm.amountFrom || rate === "0")
      return;

    const amountFrom = String(unformatBalance(tradeForm.amountFrom));
    const estimatedAmount =
      tradeForm.orderSide === "sell"
        ? ccxt.Precise.stringMul(amountFrom, rate)
        : ccxt.Precise.stringDiv(amountFrom, rate);
    dispatchForm({
      field: "amountTo",
      value: estimatedAmount.toString(),
    });
  };

  const computeAmountFrom = () => {
    if (!tradeForm.market || !tradeForm.tokenFrom || !tradeForm.tokenTo || !tradeForm.amountTo) return;
    const rate = tradeForm.orderSide === "buy" ? marketRate.ask : marketRate.bid;
    const amountTo = String(unformatBalance(tradeForm.amountTo));
    const estimatedAmount =
      tradeForm.orderSide === "sell" ? ccxt.Precise.stringDiv(amountTo, rate) : ccxt.Precise.stringMul(amountTo, rate);

    dispatchForm({
      field: "amountFrom",
      value: estimatedAmount?.toString(),
    });
  };

  return {
    computeAmountTo,
    computeAmountFrom,
  };
};
