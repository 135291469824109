import { useImperativeHandle } from "react";
import { TextFieldWithIcon } from "../TextFieldWithIcon";
import { ValidationTextFieldWithIconProps } from "./types";
import { useValidation } from "./useValidation";
import { Typography, useTheme } from "@mui/material";

export const ValidationTextFieldWithIcon = ({
  validate,
  validateOnMount = true,
  elementRef,
  subCaption = null,
  ...props
}: ValidationTextFieldWithIconProps) => {
  const { validationStatus, triggerValidate, cancelValidation } = useValidation({
    validate,
    value: props.value,
    validateOnMount,
  });

  const theme = useTheme();

  useImperativeHandle(elementRef, () => ({
    validate: triggerValidate,
    cancelValidation,
  }));

  return (
    <TextFieldWithIcon {...props} sx={{ position: "relative" }} error={validationStatus?.color === "error"}>
      <>
        <Typography
          data-testid={`${props.id || ""}-helper-text`}
          color={"error"}
          sx={{
            position: "absolute",
            top: "110%",
            fontSize: theme.typography.caption.fontSize,
            paddingLeft: theme.custom.gutterMargin,
            ...props.helperSx,
          }}
        >
          {validationStatus && validationStatus.color === "error" ? validationStatus.message : ""}
        </Typography>
        {subCaption}
      </>
    </TextFieldWithIcon>
  );
};
