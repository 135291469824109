import { useMemo, useState } from "react";
import ccxt from "@cede/ccxt";
import {
  DropListWithIconItem,
  GroupedBalancesByAccount,
  Order,
  OrderStatus,
  OrderType,
  PreparedOrder,
  TradePathData,
} from "@cede/types";
import {
  forgeTokenURL,
  getCurrentRate,
  getErrorMessage,
  getExchangeLogo,
  orderToTransactionMapper,
  unformatBalance,
} from "@cede/utils";
import { AlertTypes, useDependencies } from "..";
import {
  MultipleTransactionsPreparedOrder,
  MultipleTransactionsPreparedWithdrawal,
} from "./useMultipleTransactionsStore";

export const isMultipleTransactionsPreparedOrder = (
  transaction: MultipleTransactionsPreparedOrder | MultipleTransactionsPreparedWithdrawal,
): transaction is MultipleTransactionsPreparedOrder => {
  return "from" in transaction;
};

export const getLastOrderTransaction = (
  transactions: (MultipleTransactionsPreparedOrder | MultipleTransactionsPreparedWithdrawal)[],
) => {
  for (const transaction of [...transactions].reverse()) {
    if (isMultipleTransactionsPreparedOrder(transaction)) {
      return transaction;
    }
  }
  return null;
};

export type UseMultipleTransactionsParams = {
  accountId: string;
  fromTokenAmount: string;
  onFinish?: () => void;
  tradePath: TradePathData[] | undefined | null;
  /**
   * Only for withdrawals
   */
  activeWithdrawal?: boolean;
  whitelistedAddress?: string;
  recipientAddress?: string;
  network?: DropListWithIconItem;
  withdrawalTag?: string;
};

export const useMultipleTransactions = ({
  accountId,
  fromTokenAmount,
  tradePath,
  onFinish,
  activeWithdrawal = false,
  recipientAddress,
  network,
  withdrawalTag,
  whitelistedAddress,
}: UseMultipleTransactionsParams) => {
  const {
    backgroundHandler,
    useAlert,
    useSelectedTransaction,
    useVaults,
    useSupportedExchanges,
    useTriggerLoadingConditionally,
    useViewEnvironment,
    useMultipleTransactionsStore,
    useAccounts,
  } = useDependencies();
  const { accountsByIds } = useAccounts();
  const account = accountsByIds[accountId];
  const { source, origin } = useViewEnvironment();
  const { activeVault } = useVaults();
  const appendAlert = useAlert((state) => state.append);
  const {
    transactions,
    setTransactions,
    clear: clearState,
    startProcess,
    hasStartedTheProcess,
  } = useMultipleTransactionsStore();
  const setExecutedTransactionData = useSelectedTransaction((state) => state.setTransaction);
  const [isTxLoading, setIsTxLoading] = useState(false);
  const { supportedExchangesById, isSupportedExchangesLoading } = useSupportedExchanges();

  useTriggerLoadingConditionally({
    isLoading: isSupportedExchangesLoading || Object.keys(supportedExchangesById).length === 0,
  });

  const txTracker = useMemo(() => {
    return transactions.filter((tx) => tx.isExecuted).length;
  }, [transactions]);

  const _fitTxAmountToTokenFreeBalance = (
    amountTo: number,
    tokenSymbol: string,
    balances: GroupedBalancesByAccount,
  ) => {
    const accountBalance = balances[accountId ?? ""];
    if (!accountBalance) return amountTo;
    const tokenFreeBalance = accountBalance[tokenSymbol]?.freeBalance || 0;

    // If the amount is higher than the free balance, we need to update the amount to be sure the next transaction
    // won't fail due to insufficient balance
    return Math.min(amountTo, tokenFreeBalance);
  };

  const _awaitOrderToBeFilled = async (orderData: Order) => {
    const _handler = async (resolve: (value: Order) => unknown) => {
      const order: Order = await backgroundHandler.retrieveOrder({
        accountId: accountId ?? "",
        orderId: orderData.id,
        pairSymbol: orderData.pairSymbol,
      });

      if (order.status === OrderStatus.CLOSED) {
        resolve(order);
        return;
      }

      setTimeout(() => {
        _handler(resolve);
      }, 1000);
    };
    return new Promise(_handler);
  };

  const _getOrdersFromTradePath = async (
    executedTransactions: MultipleTransactionsPreparedOrder[],
  ): Promise<MultipleTransactionsPreparedOrder[]> => {
    if (!tradePath) return [];

    const preparedOrders: MultipleTransactionsPreparedOrder[] = executedTransactions;
    for (const path of tradePath) {
      const { pairSymbol, orderSide, from, to } = path;

      if (executedTransactions.find((tx) => isMultipleTransactionsPreparedOrder(tx) && tx.to.tokenSymbol === to)) {
        continue; // Skip already executed transactions
      }

      const marketRate = await backgroundHandler.getMarketRate({
        accountId,
        pairSymbol: pairSymbol,
      });
      const currentRate = getCurrentRate(marketRate, orderSide);
      const amount =
        preparedOrders.length === 0
          ? String(unformatBalance(fromTokenAmount))
          : preparedOrders[preparedOrders.length - 1]?.to.amount || "0";
      const baseAmount = orderSide === "sell" ? amount : ccxt.Precise.stringDiv(amount, currentRate);
      const orderRequest: PreparedOrder = await backgroundHandler.prepareOrder({
        accountId,
        pairSymbol,
        orderSide,
        orderType: OrderType.MARKET,
        amount: baseAmount,
        price: currentRate,
      });
      orderRequest.createOrderRequest.metadata = {
        tradeAndSend: true,
      };

      // Create the transaction
      preparedOrders.push({
        from: {
          amount,
          tokenSymbol: from,
          img: forgeTokenURL(from),
        },
        to: {
          amount:
            orderSide === "sell"
              ? ccxt.Precise.stringMul(orderRequest.estimatedAmount, orderRequest.createOrderRequest.price)
              : orderRequest.estimatedAmount,
          tokenSymbol: to,
          img: forgeTokenURL(to),
        },
        request: orderRequest.createOrderRequest,
        estimatedFee: orderRequest.estimatedFee,
        isExecuted: false,
        exchange: {
          label: account?.accountName ?? "",
          img: getExchangeLogo(account?.exchangeId ?? ""),
        },
      });
    }

    return preparedOrders;
  };

  const _getWithdrawal = (tokenSymbol: string, amount: string): MultipleTransactionsPreparedWithdrawal => {
    const address = recipientAddress;
    return {
      network: {
        label: network?.label ?? "",
        img: network?.img ?? "",
        value: network?.value ?? "",
      },
      request: {
        accountId,
        amount: amount ?? "0",
        tokenSymbol: tokenSymbol ?? "",
        address: address ?? "",
        network: network?.value ?? "",
        withdrawalTag: withdrawalTag ?? "",
        metadata: {
          tradeAndSend: true,
          referrerSource: source,
          origin,
        },
      },
      isExecuted: false,
      exchange: {
        label: account?.accountName ?? "",
        img: getExchangeLogo(account?.exchangeId ?? ""),
      },
    };
  };

  const _prepareTransactions = async (
    executedTransactions: (MultipleTransactionsPreparedOrder | MultipleTransactionsPreparedWithdrawal)[] = [],
  ) => {
    // If all txs are already executed, we don't need to prepare anything
    if (executedTransactions.length === (tradePath?.length || 0) + 1) {
      return;
    }

    setIsTxLoading(true);
    const preparedOrders = await _getOrdersFromTradePath(
      executedTransactions.filter(isMultipleTransactionsPreparedOrder),
    );

    const lastOrder = preparedOrders[preparedOrders.length - 1];

    if (!lastOrder) {
      // Should never happen
      return;
    }

    if (activeWithdrawal) {
      const preparedWithdrawal = _getWithdrawal(lastOrder.to.tokenSymbol, lastOrder.to.amount);
      setTransactions([...preparedOrders, preparedWithdrawal]);
    } else {
      setTransactions(preparedOrders);
    }
    setIsTxLoading(false);
  };

  const executeNextTransaction = async () => {
    if (isTxLoading || !transactions.length) return;

    if (transactions.every((tx) => tx.isExecuted)) {
      onFinish?.();
      return;
    }

    setIsTxLoading(true);
    try {
      // Execute the transaction
      const transactionToExecute = transactions[txTracker];

      if (!transactionToExecute) return;

      if (isMultipleTransactionsPreparedOrder(transactionToExecute)) {
        // Execute the order
        const createOrderResponse: Order = await backgroundHandler.createOrder({
          ...transactionToExecute.request,
          accountId: account?.id ?? "",
        });
        // Wait for the order to be filled
        const orderResponse = await _awaitOrderToBeFilled(createOrderResponse);

        // Update the portfolio before allowing the user to execute the next transaction
        // to ensure the balance is up to date for the internal transfer
        const transferWalletTypes = supportedExchangesById[account?.exchangeId ?? ""]?.transferWalletTypes;
        const balances = await backgroundHandler.balances({
          vaultId: activeVault?.id,
          accountIds: [account?.id],
          walletTypes: transferWalletTypes,
          opts: { forceRefresh: true },
        });

        const transaction = orderToTransactionMapper({
          ...orderResponse,
          accountId: account?.id ?? "",
          exchangeId: account?.exchangeId ?? "",
        });

        // Update the transaction data for the CompletedTransaction page
        setExecutedTransactionData(transaction);

        // If the fees are in quote currency, we need to update the amount
        let updatedToAmount =
          orderResponse.fee.tokenSymbol === transaction.to.transaction.tokenSymbol
            ? transaction.to.transaction.amount - transaction.to.transaction.fee.amount
            : transaction.to.transaction.amount;

        updatedToAmount = _fitTxAmountToTokenFreeBalance(
          updatedToAmount,
          transaction.to.transaction.tokenSymbol,
          balances,
        );

        // Update transaction state
        const newTransactions = [...transactions];
        const transactionToUpdate = { ...newTransactions[txTracker] } as MultipleTransactionsPreparedOrder;
        transactionToUpdate.isExecuted = true;
        transactionToUpdate.to.amount = String(updatedToAmount);
        transactionToUpdate.from.amount = String(transaction.from.transaction.amount);
        newTransactions[txTracker] = transactionToUpdate;

        await _prepareTransactions(newTransactions.filter((tx) => tx.isExecuted));
        return;
      }

      // Execute the withdrawal
      await backgroundHandler.prepareWithdrawal({
        accountId: account?.id,
        tokenSymbol: transactionToExecute.request.tokenSymbol,
        amount: parseFloat(transactionToExecute.request.amount),
        network: network?.value,
        opts: {
          key: whitelistedAddress,
          tradeAndSend: true,
        },
      });

      const transaction = await backgroundHandler.withdrawToDefi(transactionToExecute.request);

      // Update the transaction data for the CompletedTransaction page
      setExecutedTransactionData(transaction);

      const newTransactions = [...transactions];
      const transactionToUpdate = { ...newTransactions[txTracker] } as MultipleTransactionsPreparedWithdrawal;
      transactionToUpdate.isExecuted = true;
      newTransactions[txTracker] = transactionToUpdate;
      setTransactions(newTransactions);
      return;
    } catch (e) {
      appendAlert(getErrorMessage(e), AlertTypes.ERROR);
    } finally {
      setIsTxLoading(false);
    }
  };

  return {
    /**
     * Is truthy when the transactions are computing or being executed
     */
    isTxLoading,
    transactions,
    executeNextTransaction,
    txTracker,
    clearState,
    loadTransactions: _prepareTransactions,
    /**
     * Store in memory the process has started (to redirect the user to the multiple transactions page
     * if the user refreshes the page)
     */
    startProcess,
    /**
     * Check if the process has started (to redirect the user to the multiple transactions page
     * if the user refreshes the page)
     */
    hasStartedTheProcess,
  };
};
