import { useNavigate } from "react-router-dom";
import { SendWidgetProps } from "@cede/types";
import { useInitialize } from "../../hooks/useInitialize";
import { useWidgetBackgroundHandler } from "../../hooks/useWidgetBackgroundHandler";
import { useWidgetLogin } from "../../hooks/useWidgetLogin";
import { useOnboardingModal } from "../../stores/onboardingModal/onboardingModal";
import { DefaultDependencies } from "@cedelabs/react-utils";

export type WidgetsDependencies = {
  useWidgetLogin: typeof useWidgetLogin;
  useNavigate: typeof useNavigate;
  useInitialize: typeof useInitialize;
  useOnboardingModal: typeof useOnboardingModal;
  widgetProps?: SendWidgetProps;
  useBackgroundHandler: typeof useWidgetBackgroundHandler; // override the react-sdk useBackgroundHandler dependency
};

export type DefaultDependenciesWithFeatures = DefaultDependencies & WidgetsDependencies;

export const defaultDependencies: DefaultDependenciesWithFeatures = {
  useNavigate,
  useInitialize,
  useOnboardingModal,
  useWidgetLogin,
  useBackgroundHandler: useWidgetBackgroundHandler,
} as DefaultDependenciesWithFeatures;
