// ----------------------------------------------------------------------------

// PLEASE DO NOT EDIT THIS FILE, IT IS GENERATED AND WILL BE OVERWRITTEN:
// https://github.com/ccxt/ccxt/blob/master/CONTRIBUTING.md#how-to-contribute-code
// EDIT THE CORRESPONDENT .ts FILE INSTEAD

import { SdkErrorCodes, SdkErrorMessages } from "./errorsMetadata.js";
import { CedeSDKError } from "./default.js";
/**
 * @description Default error for portfolio
 */
export class PortfolioError extends CedeSDKError {
    constructor(code = SdkErrorCodes.PORTFOLIO_ERROR, message = SdkErrorMessages.PORTFOLIO_ERROR) {
        super(code, message);
        this.name = "PortfolioError";
    }
}
/**
 * @description Thrown when there isn't enough balance to perform the operation
 */
export class InsufficientBalanceError extends PortfolioError {
    constructor() {
        super(SdkErrorCodes.NOT_ENOUGH_BALANCE, SdkErrorMessages.NOT_ENOUGH_BALANCE);
        this.name = "InsufficientBalanceError";
    }
}
