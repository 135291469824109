import { ExchangeToStandarizedChainsMapping, StandardizedChain } from "@cede/types";
import { QueryKeys } from "@cede/utils";
import { useDependencies } from "../hooks/useDependencies";
import { useQuery } from "@tanstack/react-query";

export const useStandardizedNetworks = () => {
  const { backgroundHandler } = useDependencies();

  const { data, error, isLoading, refetch } = useQuery<{
    standardizedNetworksByExchange: ExchangeToStandarizedChainsMapping;
    supportedNetworks: StandardizedChain[];
  }>({
    queryKey: [QueryKeys.STANDARDIZED_NETWORKS],
    queryFn: async () => {
      const standardizedNetworksByExchange: ExchangeToStandarizedChainsMapping =
        await backgroundHandler.getStandardizedNetworks();

      return {
        standardizedNetworksByExchange,
        supportedNetworks: Object.values(standardizedNetworksByExchange)
          .flat()
          .filter((network, index, self) => self.findIndex((n) => n.icid === network.icid) === index),
      };
    },
    enabled: !!backgroundHandler?.isReady,
    staleTime: Infinity,
    gcTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const { standardizedNetworksByExchange, supportedNetworks } = data || {};

  const getNetworkName = (internalNetwork: string, exchangeId?: string) => {
    if (!standardizedNetworksByExchange) return undefined;
    const exchangeNetworks = exchangeId ? standardizedNetworksByExchange[exchangeId] : supportedNetworks;
    if (!exchangeNetworks) return undefined;
    const network = exchangeNetworks.find((network) => network.icid === internalNetwork);
    return network?.icn;
  };

  return {
    refetchStandardizedNetworks: refetch,
    standardizedNetworks: standardizedNetworksByExchange,
    isStandardizedNetworksLoading: isLoading,
    standardizedNetworksError: error,
    getNetworkName,
    supportedNetworks,
  };
};
