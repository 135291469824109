import { useMemo } from "react";
import ccxt from "@cede/ccxt";
import { MarketRate, OrderType, PreparedOrder, TradeForm } from "@cede/types";
import { getCurrentRate, unformatBalance } from "@cede/utils";
import { useDependencies } from "../../../hooks";
import { useQuery } from "@tanstack/react-query";

type UseTradeFeeParams = {
  tradeForm: TradeForm;
  marketRate: MarketRate;
  shouldUseTradeSquare: boolean;
  debouncedAmountFrom: string | null;
};

export const useTradeFee = ({
  tradeForm,
  marketRate,
  shouldUseTradeSquare,
  debouncedAmountFrom,
}: UseTradeFeeParams) => {
  const { backgroundHandler, useVaults, useMultipleTransactionsFees, usePrices } = useDependencies();
  const { activeVault } = useVaults();
  const prices = usePrices();

  const { data: preparedOrderWithFeeForCommonMarket, isFetching: preparedOrderWithFeeForCommonMarketIsLoading } =
    useQuery({
      queryKey: ["tradeFee", tradeForm.orderSide, debouncedAmountFrom, tradeForm.market?.pairSymbol, marketRate],
      queryFn: async () => {
        const currentRate = getCurrentRate(marketRate, tradeForm.orderSide!);
        const unformattedAmountFrom = String(unformatBalance(debouncedAmountFrom || "0"));
        const baseAmount =
          tradeForm.orderSide === "sell"
            ? unformattedAmountFrom
            : ccxt.Precise.stringDiv(unformattedAmountFrom, currentRate);
        const preparedOrder: PreparedOrder = await backgroundHandler.prepareOrder({
          accountId: tradeForm.selectedAccount?.value,
          pairSymbol: tradeForm.market?.pairSymbol,
          orderSide: tradeForm.orderSide,
          amount: baseAmount,
          orderType: OrderType.MARKET,
          price: currentRate,
        });

        const fee = {
          tokenSymbol: preparedOrder?.estimatedFee?.tokenSymbol || "",
          amount: preparedOrder?.estimatedFee?.amount || 0,
          refAmount: preparedOrder?.estimatedFee?.refAmount || 0,
        };
        return {
          fee,
          preparedOrder,
        };
      },
      enabled:
        !!backgroundHandler?.isReady &&
        !!activeVault &&
        !!tradeForm.selectedAccount &&
        !!tradeForm.orderSide &&
        !!tradeForm.market,
    });

  const { multipleTransactionFeesInFromToken, isLoading: isMultipleTransactionsFeesLoading } =
    useMultipleTransactionsFees({
      enabled: shouldUseTradeSquare,
      accountId: tradeForm.selectedAccount?.value || "",
      fromToken: tradeForm.tokenFrom?.value || "",
      toToken: tradeForm.tokenTo?.value || "",
      fromTokenAmount: debouncedAmountFrom || "",
      activeWithdrawal: false,
    });

  const multipleTransactionFees = useMemo(() => {
    return {
      tokenSymbol: tradeForm.tokenFrom?.value || "",
      amount: Number(multipleTransactionFeesInFromToken),
      refAmount: (prices[tradeForm.tokenFrom?.value || ""] || 0) * Number(multipleTransactionFeesInFromToken),
    };
  }, [multipleTransactionFeesInFromToken]);

  return {
    fee: shouldUseTradeSquare ? multipleTransactionFees : preparedOrderWithFeeForCommonMarket?.fee,
    preparedOrder: preparedOrderWithFeeForCommonMarket?.preparedOrder,
    isLoading: shouldUseTradeSquare ? isMultipleTransactionsFeesLoading : preparedOrderWithFeeForCommonMarketIsLoading,
  };
};
