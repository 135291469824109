export enum QueryKeys {
  STANDARDIZED_NETWORKS = "standardizedNetworks",
  SUPPORTED_EXCHANGES = "supportedExchanges",
  WITHDRAWABLE_CURRENCIES = "withdrawableCurrencies",
  VAULT_TRANSACTIONS = "getVaultTransactions",
  VAULTS_QUERY_KEY = "vaults",
  VAULTS_EVENTS_QUERY_KEY = "vaults-events",
  PROVIDER_EVENTS_QUERY = "provider-events",
  AVAILABLE_EXCHANGES = "availableExchanges",
  PARTNERS = "partners",
  CONFIG = "config",
  ADDRESS_BOOK = "addressBook",
  USER_REWARDS = "userRewards",
  QUESTS = "quests",
  PASSWORDLESS_LOGIN = "passwordlessLogin",
  ENVIRONMENT = "environment",
  PENDING_APPROVAL_REQUEST = "pendingApprovalRequest",
  DISCORD_STATUS = "DISCORD_STATUS",
  REFERRAL_CODE = "referralCode",
  HAS_JOINED_DISCORD = "hasJoinedDiscord",
  USER_MULTIPLIERS = "userMultipliers",
  MULTIPLE_TX_FEES = "multipleTxFees",
  LEADERBOARD = "leaderboard",
}
