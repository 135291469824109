import { ConnectionStatusProps } from "./types";
import { alpha, Chip, useTheme } from "@mui/material";

export const ConnectionStatus = ({ label, color }: ConnectionStatusProps) => {
  const theme = useTheme();
  return (
    <Chip
      variant="outlined"
      label={label}
      color={color}
      sx={{
        background: alpha(theme.palette[color].main, 0.1),
      }}
    />
  );
};
