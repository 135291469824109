import { CSSProperties } from "@emotion/serialize";
import { TextFieldWithIconProps } from "./types";
import { Box, FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { SystemCssProperties } from "@mui/system";

export const TextFieldWithIcon = ({
  label,
  type = "text",
  value,
  handleChange,
  icon,
  placeholder,
  id,
  color = "primary",
  children,
  sx,
  focused,
  inputSx,
  inputRef,
  error,
  disabled,
  iconPosition = "end",
  iconRotation = 0,
  iconVisibility = true,
}: TextFieldWithIconProps) => {
  const iconPositionProps = {
    [iconPosition === "end" ? "endAdornment" : "startAdornment"]: (
      <InputAdornment position="end">
        <Box
          data-testid={`${id || ""}-icon`}
          id={`${id || ""}-icon`}
          display="flex"
          sx={{
            transform: `rotate(${iconRotation}deg)`,
            visibility: iconVisibility ? "visible" : "hidden",
          }}
        >
          {icon}
        </Box>
      </InputAdornment>
    ),
  };
  return (
    <FormControl
      focused={focused}
      sx={(theme) => ({
        width: "100%",
        ...(typeof sx === "function" ? sx(theme) : (sx as CSSProperties)),
      })}
      variant="outlined"
    >
      {label && (
        <InputLabel
          htmlFor={id}
          color={color}
          data-testid={`${id || ""}-input-label`}
          error={error}
          sx={(theme) => ({ color: theme.palette.cedeTextDisabled })}
        >
          {label}
        </InputLabel>
      )}

      <OutlinedInput
        sx={(theme) => ({
          borderRadius: theme.custom.inputRadius.default,
          ...(typeof inputSx === "function" ? inputSx(theme) : (inputSx as SystemCssProperties)),
        })}
        inputRef={inputRef}
        data-testid={id}
        placeholder={placeholder}
        id={id}
        type={type}
        value={value}
        onChange={handleChange}
        color={color}
        error={error}
        inputProps={{
          "data-testid": `${id || ""}-input-text`,
          "autoComplete": "off",
        }}
        {...iconPositionProps}
        label={label}
        disabled={disabled}
      />
      {children}
    </FormControl>
  );
};
