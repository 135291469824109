// ----------------------------------------------------------------------------

// PLEASE DO NOT EDIT THIS FILE, IT IS GENERATED AND WILL BE OVERWRITTEN:
// https://github.com/ccxt/ccxt/blob/master/CONTRIBUTING.md#how-to-contribute-code
// EDIT THE CORRESPONDENT .ts FILE INSTEAD

import { SdkErrorCodes, SdkErrorMessages } from "./errorsMetadata.js";
import { CedeSDKError } from "./default.js";
/**
 * @description Default error for exchange
 */
export class ExchangeError extends CedeSDKError {
    constructor(code = SdkErrorCodes.EXCHANGE_ERROR, message = SdkErrorMessages.EXCHANGE_ERROR) {
        super(code, message);
        this.name = "ExchangeError";
    }
}
/**
 * @description Thrown when the exchange server status is offline
 */
export class ExchangeServerDownError extends ExchangeError {
    constructor() {
        super(SdkErrorCodes.EXCHANGE_SERVER_DOWN, SdkErrorMessages.EXCHANGE_SERVER_DOWN);
        this.name = "ExchangeServerDownError";
    }
}
/**
 * @description Thrown when the exchange server status is under maintenance
 */
export class ExchangeUnderMaintenanceError extends ExchangeError {
    constructor() {
        super(SdkErrorCodes.EXCHANGE_UNDER_MAINTENANCE, SdkErrorMessages.EXCHANGE_UNDER_MAINTENANCE);
        this.name = "ExchangeUnderMaintenanceError";
    }
}
/**
 * @description Thrown when the request has been expired by the throttling mechanism
 */
export class ExchangeRequestExpiredError extends ExchangeError {
    constructor() {
        super(SdkErrorCodes.EXCHANGE_REQUEST_EXPIRED, SdkErrorMessages.EXCHANGE_REQUEST_EXPIRED);
        this.name = "ExchangeRequestExpiredError";
    }
}
/**
 * @description Thrown when the exchange api can't perform the request due to missing / invalid permissions
 */
export class ExchangeInvalidPermissionsError extends ExchangeError {
    constructor() {
        super(SdkErrorCodes.EXCHANGE_INVALID_PERMISSION, SdkErrorMessages.EXCHANGE_INVALID_PERMISSION);
        this.name = "ExchangeInvalidPermissionsError";
    }
}
// TODO: Replace it by ExchangeFeatureNotAllowedError
export class ExchangeTransferNotAllowedError extends ExchangeError {
    constructor() {
        super(SdkErrorCodes.EXCHANGE_TRANSFER_NOT_ALLOWED, SdkErrorMessages.EXCHANGE_TRANSFER_NOT_ALLOWED);
        this.name = "ExchangeTransferNotAllowedError";
    }
}
export class ExchangeTransferError extends ExchangeError {
    constructor() {
        super(SdkErrorCodes.EXCHANGE_TRANSFER_ERROR, SdkErrorMessages.EXCHANGE_TRANSFER_ERROR);
        this.name = "ExchangeTransferError";
    }
}
export class ExchangeDDosProtectionError extends ExchangeError {
    constructor() {
        super(SdkErrorCodes.EXCHANGE_DDOS_PROTECTION, SdkErrorMessages.EXCHANGE_DDOS_PROTECTION);
        this.name = "ExchangeDDosProtectionError";
    }
}
export class ExchangeRateLimitExceededError extends ExchangeError {
    constructor() {
        super(SdkErrorCodes.EXCHANGE_RATE_LIMIT_EXCEEDED, SdkErrorMessages.EXCHANGE_RATE_LIMIT_EXCEEDED);
        this.name = "ExchangeRateLimitExceededError";
    }
}
export class ExchangeOperationFailedError extends ExchangeError {
    constructor() {
        super(SdkErrorCodes.EXCHANGE_OPERATION_FAILED, SdkErrorMessages.EXCHANGE_OPERATION_FAILED);
        this.name = "ExchangeOperationFailedError";
    }
}
export class ExchangeUnknownAssetError extends ExchangeError {
    constructor(asset) {
        super(SdkErrorCodes.EXCHANGE_UNKNOWN_ASSET, SdkErrorMessages.EXCHANGE_UNKNOWN_ASSET(asset));
        this.name = "ExchangeUnknownAssetError";
    }
}
export class ExchangeAccountSuspendedError extends ExchangeError {
    constructor() {
        super(SdkErrorCodes.EXCHANGE_ACCOUNT_SUSPENDED, SdkErrorMessages.EXCHANGE_ACCOUNT_SUSPENDED);
        this.name = "ExchangeAccountSuspendedError";
    }
}
/**
 * @description Thrown when the method used is not allowed by the exchange
 */
export class ExchangeNotAllowedMethodError extends ExchangeError {
    constructor(methodName) {
        super(SdkErrorCodes.EXCHANGE_NOT_ALLOWED_METHOD, SdkErrorMessages.EXCHANGE_NOT_ALLOWED_METHOD(methodName));
        this.name = "ExchangeNotAllowedMethodError";
    }
}
export class ExchangeNotAvailableError extends ExchangeError {
    constructor() {
        super(SdkErrorCodes.EXCHANGE_NOT_AVAILABLE, SdkErrorMessages.EXCHANGE_NOT_AVAILABLE);
        this.name = "ExchangeNotAvailableError";
    }
}
export class ExchangeInvalidNonceError extends ExchangeError {
    constructor() {
        super(SdkErrorCodes.EXCHANGE_INVALID_NONCE, SdkErrorMessages.EXCHANGE_INVALID_NONCE);
        this.name = "ExchangeInvalidNonceError";
    }
}
export class ExchangeUnavailableInCountry extends ExchangeError {
    constructor() {
        super(SdkErrorCodes.EXCHANGE_UNAVAILABLE_IN_COUNTRY, SdkErrorMessages.EXCHANGE_UNAVAILABLE_IN_COUNTRY);
        this.name = "ExchangeUnavailableInCountry";
    }
}
export class ExchangeWalletTypeNotSupportedError extends ExchangeError {
    constructor(walletType) {
        super(SdkErrorCodes.EXCHANGE_WALLET_TYPE_NOT_SUPPORTED, SdkErrorMessages.EXCHANGE_WALLET_TYPE_NOT_SUPPORTED(walletType));
        this.name = "ExchangeWalletTypeNotSupportedError";
    }
}
