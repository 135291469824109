import { useEffect, useMemo } from "react";
import { Button } from "@cede/ds";
import { ApiPermissions } from "@cede/types";
import { CEDE_CEXS, CEDE_CEX_LABELS } from "@cede/utils";
import { useDependencies } from "../../hooks";
import { TutorialReader } from "../Tutorial";
import { useWhitelistAddressTutorials } from "./Tutorials/useWhitelistAddressTutorials";
import { WhitelistAddressTutorialProps } from "./types";
import { Box, Dialog, useTheme } from "@mui/material";

const EMPTY_PERMISSIONS: ApiPermissions[] = [];

export const WhitelistAddressTutorial = ({
  name,
  address,
  isOpen,
  onClose,
  exchangeId,
  checkWhitelist,
  asset,
  tokenSymbol,
  network,
}: WhitelistAddressTutorialProps) => {
  const theme = useTheme();
  const { useSupportedExchanges } = useDependencies();
  const { supportedExchangesById } = useSupportedExchanges();
  const { currentPage, tutorialType, mdContent, setMaxPage, onNext, maxPage, setCurrentPage, status, checkAddress } =
    useWhitelistAddressTutorials({
      checkWhitelist,
      onClose,
      exchangeId,
    });

  const onCloseModal = () => {
    onClose();
    setCurrentPage(0);
  };

  useEffect(() => {
    if (currentPage > 0 && currentPage === maxPage) {
      checkAddress();
    }
  }, [currentPage, maxPage]);

  const setupButtons = () => {
    const retry = currentPage === maxPage && status === "failed";
    const end = currentPage === maxPage;
    if (retry) {
      return (
        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          gap={"10px"}
        >
          <Button form="defi-form" size="large" type="button" variant={"contained"} fullWidth onClick={checkAddress}>
            Retry
          </Button>
          <Button form="defi-form" size="large" type="button" variant={"text"} fullWidth onClick={onCloseModal}>
            Close
          </Button>
        </Box>
      );
    }

    if (end) {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" gap={"10px"}>
          <Button type="button" form="defi-form" size="large" variant={"contained"} fullWidth onClick={onCloseModal}>
            Finish
          </Button>
        </Box>
      );
    } else {
      return (
        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          gap={"10px"}
        >
          <Button form="defi-form" size="large" type="button" variant={"contained"} fullWidth onClick={onNext}>
            Next
          </Button>
          <Button form="defi-form" size="large" type="button" variant={"text"} fullWidth onClick={onCloseModal}>
            Close
          </Button>
        </Box>
      );
    }
  };

  const getCustomHref = () => {
    let addressManagementUrl = supportedExchangesById[exchangeId]?.addressManagementUrl;
    if (!addressManagementUrl) return "";
    if (exchangeId === CEDE_CEXS.KRAKEN) addressManagementUrl = addressManagementUrl.concat(asset ? asset : "BTC");
    return addressManagementUrl;
  };

  const tutorialReaderData = useMemo(() => {
    return {
      customHref: getCustomHref(),
      name,
      address,
      status,
      ...(tokenSymbol && { tokenSymbol }),
      ...(network && { network }),
    };
  }, [name, address, status, network, tokenSymbol]);

  return (
    <Dialog
      open={isOpen}
      sx={{
        "position": "absolute",
        "maxHeight": "none",
        "zIndex": theme.custom.zIndex.extraLayer,
        "& .MuiDialog-container": {
          alignItems: "flex-start",
          overflow: "hidden",
        },
      }}
      PaperProps={{
        style: {
          position: "relative",
          backgroundColor: "transparent",
          backgroundImage: "none",
          boxShadow: "none",
          height: "100%",
          maxHeight: `calc(100% - ${theme.custom.bigGutterMargin} * 3)`,
        },
      }}
      container={() => document.getElementById("app-body")}
      fullWidth={true}
      disablePortal
      onClose={onCloseModal}
    >
      <Box
        width={"100%"}
        height="100%"
        borderRadius={theme.custom.buttonDefaultRadius}
        position="relative"
        p={theme.custom.gutterMargin}
        sx={{
          background: theme.palette.background.paper,
          overflowX: "auto",
          scrollBehavior: "smooth",
        }}
      >
        <Box
          flex={"1"}
          paddingTop={theme.custom.gutterMargin}
          width="100%"
          mb={theme.custom.gutterMargin}
          sx={{
            overflow: "auto",
          }}
        >
          <TutorialReader
            currentPage={currentPage}
            folder={tutorialType}
            mdContent={mdContent}
            setMaxPage={setMaxPage}
            permissions={EMPTY_PERMISSIONS}
            accountPermissions={EMPTY_PERMISSIONS}
            exchangeId={CEDE_CEX_LABELS[exchangeId] || exchangeId}
            data={tutorialReaderData}
          />
        </Box>

        {setupButtons()}
      </Box>
    </Dialog>
  );
};
