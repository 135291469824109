import { useTabsFactory } from "../../../store";

export enum SendTabsValues {
  DefiAddress = "defi-address",
  BetweenCex = "between-cex",
}

export const sendTabs = [
  { value: SendTabsValues.DefiAddress, label: "To a defi address", disabled: false },
  { value: SendTabsValues.BetweenCex, label: "Between CEX", disabled: false },
];

export const useSendTabs = useTabsFactory(sendTabs);
