import { IconText } from "@cede/ds";
import { isCefiInfoTx } from "@cede/types";
import { firstLetterToUpperCase, getExchangeLogo } from "@cede/utils";
import { useDependencies } from "../../hooks";
import { Typography } from "@mui/material";

export const CexLine = () => {
  const { useSelectedTransaction } = useDependencies();
  const { transaction, isTrade } = useSelectedTransaction(({ transaction, isTrade }) => ({
    transaction,
    isTrade,
  }));
  if (!transaction || !isTrade) return null;
  const from = transaction.from;
  if (!isCefiInfoTx(from)) return null;

  return (
    <>
      <Typography variant={"body2"}>CEX</Typography>
      <IconText
        iconName={from.account.exchangeId}
        iconUrl={getExchangeLogo(from.account.exchangeId)}
        text={firstLetterToUpperCase(from.account.exchangeId)}
        justifySelf={"flex-end"}
        hover={false}
        padding={0}
      />
    </>
  );
};
