import { Any } from "../fakeTypes";

export interface AssetPair {
  pairName: string;
  baseAsset: string;
  quoteAsset: string;
}
export declare function isTicker(obj: Any): obj is AssetPair;

/**
 * A ticker is a report of the current market prices for an individual currency pair
 */
export interface MarketTicker {
  baseAsset: string;
  quoteAsset: string;
  time: number;
  avgPrice: string;
  originExchange: string;
}

export interface AssetPairResponse<AssetPairDtoArray> {
  assetPairs: AssetPairDtoArray[];
}

export interface TickersResponse<TickerDto> {
  tickers: TickerDto[];
}

export enum OrderCategory {
  TAKER = "taker",
  MAKER = "maker",
}

export enum ApiPermissions {
  TRADE = "trade",
  READ = "read",
  WITHDRAW = "withdraw",
}

export type AvailablePermissionType = {
  type: ApiPermissions;
  title: string;
  content: string;
};

export type AddressStructure = {
  currency: string; // currency code
  network?: string; // a list of deposit/withdraw networks, ERC20, TRC20, BSC20 (see below)
  address: string; // address in terms of requested currency
  tag?: string; // tag / memo / paymentId for particular currencies (XRP, XMR, ...)
  info: Any; // raw unparsed data as returned from the exchange
  id?: string; // unique identifier of the address (if any)
};

export * from "./MarketNetwork";
export * from "./CefiServices";
export * from "./ConnectionStatus";
export * from "./Ledger";
