import { ChangeEvent, forwardRef, RefObject, useEffect, useRef, useState } from "react";
import { Any } from "@cede/types";
import { isChildOf, sortItemsByDescendingAmountAndAlphabetical } from "@cede/utils";
import { IconGroup } from "../IconGroup";
import {
  MultiSelectDropListItem,
  MultiSelectDropListOption,
  MultiSelectDropListPaperProps,
  MultiSelectDropListProps,
} from "./types";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  SxProps,
  Theme,
  useTheme,
} from "@mui/material";
import { SystemCssProperties } from "@mui/system";

const paperSx: SxProps<Theme> = (theme) => ({
  background: theme.palette.background.paper,
  borderRadius: "4px",
  marginTop: "8px",
  boxShadow:
    "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
});

const PaperComponent = forwardRef(
  ({ children, header, value, handleChange, ...props }: MultiSelectDropListPaperProps, ref) => {
    return (
      <Box role="listbox" sx={paperSx} {...props} ref={ref}>
        {header && header({ value, handleChange })}
        {children}
      </Box>
    );
  },
);

const defaultOptionRenderSx = { "& > img": { mr: 2, flexShrink: 0 } };
const defaultOptionRender: MultiSelectDropListOption = (props, option, { selected }) => (
  <Box component="li" sx={defaultOptionRenderSx} {...props}>
    <Checkbox style={{ marginRight: 8 }} checked={selected} />
    {option.img && <img loading="lazy" width="20" src={option.img} alt="" />}
    {option.label}
  </Box>
);

export function MultiSelectDropList<T extends MultiSelectDropListItem = MultiSelectDropListItem>({
  id,
  data,
  handleChange,
  label,
  rounded = false,
  width = "100%",
  value,
  placeholder,
  listHeader,
  renderOption,
  maxIcons = 5,
  timeout,
  inputSx,
  listBoxSx = {},
  focusRef,
}: MultiSelectDropListProps<T>) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [sortedItems, setSortedItems] = useState<T[]>([]);

  const autoCompleteRef = useRef();
  const popupRef = useRef<HTMLDivElement>();
  const inputRef = useRef<HTMLInputElement>();
  const timeoutId = useRef<NodeJS.Timeout>();

  const toggleModal = (forceState?: boolean) => {
    clearTimeout(timeoutId.current);
    if (timeout) {
      timeoutId.current = setTimeout(() => {
        setOpen(forceState === undefined ? (prev) => !prev : forceState);
      }, timeout);
      return;
    }

    setOpen(forceState === undefined ? (prev) => !prev : forceState);
  };

  const onInput: React.FormEventHandler<HTMLInputElement> = (e: ChangeEvent<HTMLInputElement>) =>
    setInputValue(e.target.value);

  useEffect(() => {
    if (!inputRef || !inputRef.current) return;

    const closePopupOnClickOutsideOfElement: (this: HTMLElement, ev: globalThis.MouseEvent) => void = (e) => {
      const { target } = e;

      if (!isChildOf(target, popupRef.current) && !isChildOf(target, autoCompleteRef.current)) {
        setOpen(false);
        clearTimeout(timeoutId.current);
        return;
      }

      if (inputRef.current) inputRef.current.focus();
    };

    document.body.addEventListener("click", closePopupOnClickOutsideOfElement);

    return () => document.body.removeEventListener("click", closePopupOnClickOutsideOfElement);
  }, [inputRef]);

  useEffect(() => {
    if (!open) setInputValue("");

    if (!open && focusRef) {
      if (focusRef.current.unFocus != null) {
        focusRef.current.unFocus();
      }
      inputRef.current?.blur();
    }
  }, [open]);

  useEffect(() => {
    setSortedItems(sortItemsByDescendingAmountAndAlphabetical(data) as T[]);
  }, [data]);

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => value.value === option.value}
      disablePortal
      inputValue={inputValue}
      onInput={onInput}
      value={value}
      ref={autoCompleteRef}
      multiple
      id={id}
      data-testid={id}
      open={open}
      options={sortedItems}
      autoHighlight
      disableCloseOnSelect
      PaperComponent={PaperComponent as Any}
      getOptionLabel={(option: MultiSelectDropListItem) => option.label}
      onChange={(_, values: T[]) => {
        handleChange && handleChange(values);
      }}
      ListboxProps={
        {
          "sx": { padding: "0", ...listBoxSx },
          "data-testid": `${id || ""}-list`,
        } as Any
      }
      componentsProps={{
        paper: {
          header: listHeader,
          value,
          handleChange,
          ref: popupRef as RefObject<HTMLDivElement>,
        } as MultiSelectDropListPaperProps<T>,
        popper: {
          modifiers: [
            {
              name: "flip",
              enabled: false,
            },
          ],
        },
      }}
      renderOption={renderOption || defaultOptionRender}
      renderInput={({ InputProps, InputLabelProps, ...params }) => (
        <Box ref={InputProps.ref} width={width}>
          <FormControl focused={open} variant="outlined" fullWidth>
            <InputLabel {...InputLabelProps}>{label}</InputLabel>
            <OutlinedInput
              onInput={() => toggleModal(true)}
              onFocus={() => toggleModal(true)}
              sx={{
                ".MuiAutocomplete-input": { padding: "0!important" },
                "padding": theme.custom.gutterMargin + "!important",
                "borderRadius": rounded ? theme.custom.buttonRoundedRadius : theme.custom.inputRadius.default,
                "paddingRight": theme.custom.gutterMargin + "!important",
                width,
                "justifyContent": "space-between",
                ...(typeof inputSx === "function" ? inputSx(theme) : (inputSx as SystemCssProperties)),
              }}
              value={params.inputProps.value}
              startAdornment={
                <InputAdornment
                  position="end"
                  sx={{
                    marginLeft: "0",
                    ...(value?.length ? { marginRight: "5px" } : {}),
                  }}
                >
                  {
                    <IconGroup
                      maxIcons={maxIcons}
                      icons={value.map((v) => ({
                        name: v.value,
                        src: v.img || "",
                      }))}
                      id={`${id || ""}-icon-group`}
                    />
                  }
                </InputAdornment>
              }
              endAdornment={
                <IconButton
                  onClick={() => toggleModal()}
                  sx={{
                    transition: "transform 0.3s",
                    transformOrigin: "center",
                    transform: open ? "rotate(-180deg)" : "rotate(0)",
                  }}
                >
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.59 0.294922L6 4.87492L1.41 0.294922L0 1.70492L6 7.70492L12 1.70492L10.59 0.294922Z"
                      fill="white"
                      fillOpacity="0.56"
                    />
                  </svg>
                </IconButton>
              }
              label={label}
              placeholder={value.length ? "" : placeholder}
              {...params}
              inputProps={{
                ...params.inputProps,
                "data-testid": `${id || ""}-input`,
              }}
              inputRef={inputRef}
            />
          </FormControl>
        </Box>
      )}
    />
  );
}
