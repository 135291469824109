import { Any } from "@cede/types";
import { getNumberFromAmount } from "../numbers/utils";

// We set "any" because there are multiple types that have "amount" and "label" values
// and if we set the returned value, it will create warnings in other files
const sortItemsByDescendingAmountAndAlphabetical = <T = Any>(
  options: T[],
  shouldDisable?: (option: T) => boolean,
): T[] => {
  if (!options) return [];
  const sortedOptions = [...options].sort((a: Any, b: Any) => {
    if ((a.isDisabled && b.isDisabled) || (shouldDisable?.(a) && shouldDisable?.(b))) return 0;
    if (a.isDisabled || shouldDisable?.(a)) return 1;
    if (b.isDisabled || shouldDisable?.(b)) return -1;

    const hasAmount = a.amount && b.amount;
    const hasValue = a.value && b.value;
    if (hasAmount || hasValue) {
      const amountA = a.amount || a.value;
      const amountB = b.amount || b.value;
      if (typeof amountA === "string" || typeof amountB === "string") {
        // If values are numbers that are passed as strings, eg. '50'
        if (+amountA || +amountB) {
          return +amountB - +amountA;
        }
        if (amountA.trim()[0] === "$" || amountB.trim()[0] === "$") {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const valueA = getNumberFromAmount(amountA);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const valueB = getNumberFromAmount(amountB);

          return valueA > valueB ? -1 : 1;
        }
        return (a.amount || a.value).toLowerCase() > (b.amount || b.value).toLowerCase() ? 1 : -1;
      }
      return amountB - amountA;
    }
    return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return sortedOptions;
};

export { sortItemsByDescendingAmountAndAlphabetical };
