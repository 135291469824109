export const userFacingMessages = {
  LOCKED_FOR_INACTIVITY: "Cede.store has been automatically locked due to a period of inactivity.",
  MESSAGE_SENT_TO_CEDE_TEAM: "Message sent to Cede\u00A0Labs team",
  FEEDBACK_ERROR: "Error when sending feedback",
  API_KEY: {
    ALREADY_EXISTS: "API keys already exist.",
    CREDENTIALS_INCORRECT: "API credentials are incorrect",
    SUCCESSFULLY: (endOfSentence: string) => `API key successfully ${endOfSentence}`,
    REMOVED: `API key successfully removed`,
    VERIFYING_VALIDITY: "Just verifying the validity of your keys…",
  },
  VAULT: {
    CANT_DELETE_SINGLE: "You can't delete your single vault.",
    REQUIRE_NON_EMPTY: "You must have at least one account in your vault to perform this action.",
    CREATED_SUCCESSFULLY: "Vault created successfully",
    NOT_FOUND: "Vault not found",
  },
  TRANSACTION: {
    TOKEN_FROM_NOT_FOUND: "Token from not found for this transaction",
    ACCOUNT_NOT_FOUND: "Account not found for this transaction",
    ORDER_NOT_FOUND: "Order not found for this transaction",
  },
  ACCOUNT: {
    NOT_FOUND: "Account not found",
    NOT_FOUND_WITH_NAME: (accountName: string) => `Account ${accountName} not found`,
    RECONNECT: (accountName: string) => `Please, reconnect your ${accountName} account`,
    DELETED: (accountName: string) => `${accountName} has been deleted.`,
    RECONNECTION_REQUIRED: "Reconnection required",
  },
  MARKET_NOT_FOUND: (symbol?: string | null) => `Market ${symbol} not found`,
  CANCELLATION_FAILED: "The cancellation failed",
  OAUTH: {
    FAILED: "OAuth2 Connection failed, please try again.",
    SUCCESSFULLY_SETUP: `OAuth successfully setup`,
  },
  UNKNOWN_ERROR: "Oups, something went wrong.",
  PRICES: {
    CANT_FETCH: "Sorry, we can't fetch the prices.",
  },
  SEND: {
    YOUR_FUND_SAFE: "Your funds are safe",
    CONFIRM_EMAIL: (
      exchangeId: string,
    ) => `To process, please confirm the email from ${exchangeId} sent to your email address linked
      with your ${exchangeId} account.`,
  },
  WIDGETS: {
    SEND: {
      LOGIN_LOADING_FIRST_LINE: `Select the vault you want to connect to from cede.store extension.`,
      LOGIN_LOADING_SECOND_LINE: `You will be transferring your assets from the CEX accounts connected in the vault.`,
      TRANSACTION_LOADING_FIRST_LINE: `Confirm the transaction on the cede.store popup.`,
      TRANSACTION_LOADING_SECOND_LINE: `Make sure all the parameters are correct before confirming.`,
      TRANSFER_CEX_ASSETS: `Transfer your CEX assets to your blockchain address from any dApp`,
      CONNECT: `Connect`,
      CONNECT_WALLET: `Connect wallet`,
      CANT_FETCH_BUNDLE: `Could not connect to Cede.store. Please make sure you have the latest version of the extension.`,
    },
    TRADE_SEND: {
      ACTIONS_LABEL: "What actions you will validate:",
      WITHDRAW_CURRENCY: (destCurrency: string, recipientAddress: string, network: string) =>
        `Withdraw ${destCurrency} to ${recipientAddress} on ${network}`,
      RECEIVE_CURRENCY: (destCurrency: string) => `In the end, you will receive ${destCurrency} in your wallet`,
      MODAL_CONFIRM: "Continue",
      PERFORM_TRADE_PATH: (tradePathLabel: string, network: string) =>
        `You will need to perform the following ${tradePathLabel} trade(s) in order to withdraw on the ${network} network.`,
      NO_TRADE_PATH: "There is no trade path available for the destination currency. Please select another currency.",
      CONFIRM_TX: (number: number) => `Confirm Transaction ${number}`,
      FINISH: "Finish",
      ERROR: "Error, try again later",
    },
    TRADE_SQUARE: {
      ACTIONS_LABEL: "What actions you will validate:",
      PERFORM_TRADE_PATH: (tradePathLabel: string, destinationTokenSymbol: string) =>
        `You will need to perform the following ${tradePathLabel} trade(s) in order to get ${destinationTokenSymbol} token.`,
      INFO_DETAILS: (cexName: string, tokenFrom: string, tokenTo: string) => [
        `${cexName} `,
        "does not support ",
        `a ${tokenFrom}/${tokenTo} pair. However, cede.store `,
        "has found the best price route. ",
        "You are going to ",
        `automatically perform a series of trades to receive ${tokenTo}`,
      ],
      CLOSE: "Close",
    },
    TRADE: {
      CONNECT: `Connect`,
      TRANSACTION_LOADING_FIRST_LINE: `Confirm the transaction on the cede.store popup.`,
      TRANSACTION_LOADING_SECOND_LINE: `Make sure all the parameters are correct before confirming.`,
      CANT_FETCH_BUNDLE: `Could not connect to Cede.store. Please make sure you have the latest version of the extension.`,
      WELCOME_MESSAGE: `Your non-custodial gateway to your CeFi liquidity from any dApp`,
    },
  },
  EXCHANGES_STATUSES: {
    SINGLE_DOWN: (cexName: string) => `${cexName} server is temporarily down, please try again later.`,
    TWO_DOWN: (firstName: string, secondName: string) =>
      `${firstName} and ${secondName} servers are temporarily down, please try again later.`,
    THREE_DOWN: (firstName: string, secondName: string, thirdName: string) =>
      `${firstName}, ${secondName} and ${thirdName} servers are temporarily down, please try again later.`,

    SINGLE_BACK_ONLINE: (cexName: string) => `${cexName} server is back available.`,
    TWO_BACK_ONLINE: (firstName: string, secondName: string) =>
      `${firstName} and ${secondName} servers are back available.`,
    THREE_BACK_ONLINE: (firstName: string, secondName: string, thirdName: string) =>
      `${firstName}, ${secondName} and ${thirdName} servers are back available.`,
    UNDER_MAINTENANCE: "Under maintenance",
    ADD_PERMISSION: "Add permission +",
    TEMPORARILY_UNAVAILABLE: "Temporarily unavailable",
    TEMPORARILY_UNAVAILABLE_IN_YOUR_COUNTRY: "Temporarily unavailable in your country",
  },
  STATE_MACHINE_ROUTER: {
    ERRORS: {
      MISSING_PARAMETERS: "Missing parameters from the redirection.",
      TWO_KEY_NOT_SUPPORTED: (exchangeId: string) => `The exchange ${exchangeId} doesn't support 2 key system.`,
    },
  },
  READ_ONLY_ONBOARDING: {
    TITLE: "Specific server-side onboarding",
    PERMISSION_HINT: "Trade and withdraw permissions are not available for this specific onboarding",
  },
  AVAILABLE_SOON: "Available soon",
};
