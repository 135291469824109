import { CurrencyAmountFormatter } from "@cede/ds";
import { formatBalance } from "@cede/utils";
import { useDependencies } from "../../hooks";
import { Box, Typography, useTheme } from "@mui/material";

export const FeeLine = () => {
  const theme = useTheme();
  const { useSettings, useSelectedTransaction } = useDependencies();
  const transaction = useSelectedTransaction((state) => state.transaction);
  const fiatCurrency = useSettings((state) => state.currency);

  if (!transaction?.from.transaction.fee) return null;

  return (
    <>
      <Box position={"relative"}>
        <Typography variant={"body2"}>CEX fee</Typography>
        <Typography variant={"caption"} position={"absolute"} left={0} top={"110%"}>
          {"(estimated)"}
        </Typography>
      </Box>
      <Box justifySelf={"right"} display={"inline-flex"} alignItems={"center"} gap={theme.custom.smallGutterMargin}>
        {transaction.from.transaction.fee.refAmount ? (
          <CurrencyAmountFormatter
            amount={transaction.from.transaction.fee.refAmount ?? 0}
            colors={{
              integer: theme.palette.text.primary,
              decimal: theme.palette.text.primary,
            }}
            size={"small"}
            currency={fiatCurrency}
          />
        ) : null}
        <Typography variant={"body1"}>
          {!transaction.from.transaction.fee.amount
            ? "-"
            : `(${formatBalance(transaction.from.transaction.fee.amount)} ${
                transaction.from.transaction.fee.tokenSymbol
              })`}
        </Typography>
      </Box>
    </>
  );
};
