import { useMemo } from "react";
import { defaultDependencies } from "./dependencies";
import { DependenciesProvider as SDKDependenciesProvider } from "@cedelabs/react-sdk/src";

export type DependenciesProviderProps<T> = {
  children: React.ReactNode;
  dependencies?: T;
};

export function DependenciesProvider<T extends Record<string, unknown>>({
  children,
  dependencies,
}: DependenciesProviderProps<T>) {
  const finalDependencies = useMemo(() => {
    return {
      ...defaultDependencies,
      ...dependencies,
    };
  }, [dependencies]);

  return <SDKDependenciesProvider dependencies={finalDependencies}>{children}</SDKDependenciesProvider>;
}
