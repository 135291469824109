import { Partner } from "@cede/types";
import { QueryKeys } from "@cede/utils";
import { useDependencies } from ".";
import { useQuery } from "@tanstack/react-query";

/**
 * Hook to fetch our partners
 * @returns partners, isLoadingPartners, refetchPartners
 */
export const usePartners = () => {
  const { backgroundHandler } = useDependencies();

  const {
    data: partners,
    isFetching: isLoadingPartners,
    refetch,
  } = useQuery<Partner[]>({
    queryKey: [QueryKeys.PARTNERS],
    queryFn: async () => {
      return await backgroundHandler.fetchPartners({
        visible: true,
      });
    },
    enabled: !!backgroundHandler?.isReady,
    gcTime: Infinity,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  return {
    partners,
    isLoadingPartners,
    refetchPartners: refetch,
  };
};
