import { CreateOrderRequest, DropListWithIconItem, EstimatedFee, WidgetType, WithdrawToDefiParams } from "@cede/types";
import { getTimeLimitedPersistStorage } from "../utils/timeLimitedPersistStorage";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export type MultipleTransactionsPreparedTransaction = {
  isExecuted: boolean;
  exchange: {
    label: string;
    img: string;
  };
};

export type MultipleTransactionsPreparedOrder = {
  from: {
    amount: string;
    tokenSymbol: string;
    img: string;
  };
  to: {
    amount: string;
    tokenSymbol: string;
    img: string;
  };
  request: CreateOrderRequest;
  estimatedFee: EstimatedFee;
} & MultipleTransactionsPreparedTransaction;

export type MultipleTransactionsPreparedWithdrawal = {
  network: DropListWithIconItem;
  request: WithdrawToDefiParams;
} & MultipleTransactionsPreparedTransaction;

export type MultipleTransactionsStore = {
  hasStartedTheProcess: boolean;
  transactions: (MultipleTransactionsPreparedOrder | MultipleTransactionsPreparedWithdrawal)[];
  startProcess: VoidFunction;
  setTransactions: (
    transactions: (MultipleTransactionsPreparedOrder | MultipleTransactionsPreparedWithdrawal)[],
  ) => void;
  clear: VoidFunction;
};

const PERSISTANCE_DURATION = 1000 * 60 * 10; // 10 minutes

/**
 * Get the store for multiple transactions
 * (it changes the local storage name based on the widget type to avoid conflicts between the widgets)
 */
export const getUseMultipleTransactionsStore = (type: WidgetType | "STORE") =>
  create<MultipleTransactionsStore>()(
    persist(
      (set) => ({
        transactions: [],
        hasStartedTheProcess: false,
        startProcess: () => set({ hasStartedTheProcess: true }),
        setTransactions: (transactions) => set({ transactions }),
        clear: () => set({ transactions: [], hasStartedTheProcess: false }),
      }),
      {
        name: `MultipleTransactionsStore-${type}`,
        storage: getTimeLimitedPersistStorage({ ttl: PERSISTANCE_DURATION }),
        partialize: (state) => {
          return {
            // Don't need to persist state if the process is finished (all transactions are executed)
            transactions: state.transactions.every((tx) => tx.isExecuted) ? [] : state.transactions,
            hasStartedTheProcess: state.hasStartedTheProcess,
          };
        },
      },
    ),
  );
