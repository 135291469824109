import { Button } from "@cede/ds";
import { SdkErrorCodes, ViewEnvironment } from "@cede/types";
import { MainFooter } from "../../..";
import { useDependencies } from "../../../hooks/useDependencies";

export const DefaultFooter = () => {
  const { useCompletedTransaction, useViewEnvironment } = useDependencies();
  const { transaction, goTo, error, isSupportedExchangesLoading, isRequiringEmailConfirmation, errorCode } =
    useCompletedTransaction();
  const { environment: viewEnvironment } = useViewEnvironment();

  if (!transaction) return null;

  const actionButtonLabel = () => {
    if (errorCode === SdkErrorCodes.EXCHANGE_INVALID_PERMISSION) return "Update permissions";
    if (errorCode === SdkErrorCodes.WHITELIST_CHECK_FAILED) return "Update whitelisted addresses";
    if (viewEnvironment === ViewEnvironment.WIDGET) {
      if (error) return "Back";
      if (isRequiringEmailConfirmation && transaction.type === "withdrawal") return "Confirm via email";
      if (transaction.type === "withdrawal") return "New Transfer";
      if (transaction.type === "order") return "New Trade";
      return "Back";
    }

    if (isSupportedExchangesLoading) return;

    if (isRequiringEmailConfirmation && transaction.type === "withdrawal") {
      return "Confirm via email";
    } else {
      return "Go to vault";
    }
  };

  return (
    <MainFooter>
      <Button fullWidth data-testid="submit-btn" height={"tall"} variant={"contained"} onClick={goTo}>
        {actionButtonLabel()}
      </Button>
    </MainFooter>
  );
};
