import { Button } from "@cede/ds";
import { userFacingMessages } from "@cede/types";
import { cropTextInMiddle } from "@cede/utils";
import { Box, Fade, Modal, Typography, useTheme } from "@mui/material";

export type TradeSendConfirmModalProps = {
  id?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  tradePath: string;
  destinationCurrency: string;
  recipientAddress: string;
  network: string;
  onConfirm: () => void;
};

export function TradeSendConfirmModal({
  id,
  open,
  setOpen,
  tradePath,
  destinationCurrency,
  recipientAddress,
  network,
  onConfirm,
}: TradeSendConfirmModalProps) {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        position: "absolute",
      }}
      closeAfterTransition
      disablePortal={true}
    >
      <Fade in={open} timeout={300}>
        <Box
          data-testid={`${id || ""}-permission-changed-modal`}
          sx={{
            "width": `calc(${theme.custom.storeSize.width} * 0.9)`,
            "height": "max-content",
            "padding": theme.custom.gutterMargin,
            "position": "absolute",
            "top": "50%",
            "left": "50%",
            "transform": "translate(-50%, -50%)",
            "background": theme.palette.background.darkPaper,
            "borderRadius": theme.custom.mediumBoxRadius,
            "display": "flex",
            "flexDirection": "column",
            "alignItems": "center",
            "justifyContent": "center",
            "gap": theme.custom.gutterMargin,
            ":focus": {
              outline: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: theme.custom.smallGutterMargin,
            }}
          >
            <Typography fontSize={theme.typography.h2.fontSize}>Confirmation</Typography>
            <Typography color={theme.palette.text.disabled}>
              {userFacingMessages.WIDGETS.TRADE_SEND.ACTIONS_LABEL}
            </Typography>
          </Box>
          <Typography sx={{ marginTop: theme.custom.smallGutterMargin, textAlign: "left" }} component="ul">
            <li>{`${tradePath} trade`}</li>
            <li>
              {userFacingMessages.WIDGETS.TRADE_SEND.WITHDRAW_CURRENCY(
                destinationCurrency,
                cropTextInMiddle(recipientAddress, 8),
                network,
              )}
            </li>
          </Typography>
          <Typography sx={{ marginTop: theme.custom.smallGutterMargin }}>
            {userFacingMessages.WIDGETS.TRADE_SEND.RECEIVE_CURRENCY(destinationCurrency)}
          </Typography>
          <Button
            variant="contained"
            height="tall"
            fullWidth
            onClick={() => {
              onConfirm();
              setOpen(false);
            }}
          >
            {userFacingMessages.WIDGETS.TRADE_SEND.MODAL_CONFIRM}
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
}
