import { Logo } from "@cede/ds";
import { ViewEnvironment } from "@cede/types";
import { useDependencies } from "../../hooks";
import { useLoading } from "../../store";
import { Box, Fade, Typography, useTheme } from "@mui/material";

export const Loading = () => {
  const { useViewEnvironment } = useDependencies();
  const theme = useTheme();

  const isBlockingLoading = useLoading((state) => state.isBlockingLoading);
  const description = useLoading((state) => state.description);
  const { environment: viewEnvironment } = useViewEnvironment();

  return (
    <Fade appear={false} in={isBlockingLoading} timeout={{ appear: 0, enter: 0, exit: 500 }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          background: theme.palette.background.default,
          zIndex: theme.custom.zIndex.extraLayer + 1,
          borderRadius: viewEnvironment === ViewEnvironment.TAB ? theme.custom.mediumBoxRadius : 0,
        }}
      >
        <Logo isLoading />
        {description && (
          <Typography
            component="h2"
            variant="body2"
            sx={{ marginTop: theme.custom.gutterMargin }}
            textAlign="center"
            px="22px"
          >
            {description}
          </Typography>
        )}
      </Box>
    </Fade>
  );
};
