import ccxt from "@cede/ccxt";
import { AccountId, CefiInfoTx, Order, Transaction, TxStatus, TxType, WalletType } from "@cede/types";
import { DEFAULT_UNDEFINED_STRING } from "../utils";

export const orderStatusToTransactionStatus = (status: Order["status"]): TxStatus => {
  return (() => {
    switch (status) {
      case "open":
        return TxStatus.PENDING;
      case "closed":
        return TxStatus.OK;
      case "canceled":
        return TxStatus.CANCELLED;
      default:
        return TxStatus.CANCELLED; // it can only be 'expired' or 'rejected' according to CCXT
    }
  })();
};

export function orderToTransactionMapper(
  order: Order & { accountId: AccountId; exchangeId: string },
): Transaction<CefiInfoTx, CefiInfoTx> {
  const status = orderStatusToTransactionStatus(order.status);
  const type: TxType = "order";
  // cost is equal to 0 when order is opened so we need to guess it
  const cost = ccxt.Precise.stringMul(String(order.price), String(order.filled));
  const from: CefiInfoTx = {
    account: {
      exchangeId: order.exchangeId,
      accountId: order.accountId,
    },
    wallet: {
      walletType: WalletType.SPOT,
      depositAddress: "unknown",
    },
    transaction: {
      amount: order.side === "sell" ? order.filled : Number(cost),
      tokenSymbol: order.pairSymbol.split("/")[order.side === "sell" ? 0 : 1] || DEFAULT_UNDEFINED_STRING,
      transactionId: order.id,
      fee: {
        amount: order.fee?.amount ?? 0,
        tokenSymbol: order.fee?.tokenSymbol ?? order.pairSymbol.split("/")[0],
      },
      status,
      timestamp: order.timestamp,
    },
  };
  const to: CefiInfoTx = {
    account: {
      exchangeId: order.exchangeId,
      accountId: order.accountId,
    },
    wallet: {
      walletType: WalletType.SPOT,
      depositAddress: "unknown",
    },
    transaction: {
      // cost is equal to 0 when order is opened
      amount: order.side === "sell" ? Number(cost) : order.filled,
      tokenSymbol: order.pairSymbol.split("/")[order.side === "sell" ? 1 : 0] || DEFAULT_UNDEFINED_STRING,
      transactionId: order.id,
      fee: {
        amount: order.fee?.amount ?? 0,
        tokenSymbol: order.fee?.tokenSymbol ?? order.pairSymbol.split("/")[1],
      },
      status,
      timestamp: order.timestamp,
    },
  };

  return {
    from,
    to,
    type,
    pairSymbol: order.pairSymbol,
  };
}
