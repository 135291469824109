import { useMemo } from "react";
import { DropListWithIconItem, MarketNetwork } from "@cede/types";
import { USE_NETWORKS_CACHE_TIME } from "@cede/utils";
import { UseSendState } from "../../components/Send/types";
import { useDependencies } from "../../hooks/useDependencies";
import { UseNetworksProps } from "./types";
import { useQuery } from "@tanstack/react-query";

export const useNetworks = ({
  toDeposit = false,
  toWithdraw = false,
  accountId,
  tokenSymbol,
  isInternalTransfer,
}: UseNetworksProps) => {
  const { useVaults, backgroundHandler } = useDependencies();
  const { activeVault } = useVaults();

  const { data: networks, isFetching: networksAreLoading } = useQuery<MarketNetwork[]>({
    queryKey: ["networks", activeVault, accountId, tokenSymbol, isInternalTransfer, { toDeposit, toWithdraw }],
    queryFn: async () => {
      const networks = await backgroundHandler.getNetworks({
        accountId,
        tokenSymbol,
        isInternalTransfer,
        opts: {
          toDeposit,
          toWithdraw,
          isInternalTransfer,
        },
      });
      return networks;
    },
    enabled: !!activeVault?.id && !!backgroundHandler?.isReady && !!accountId,
    staleTime: USE_NETWORKS_CACHE_TIME,
    gcTime: USE_NETWORKS_CACHE_TIME,
  });

  const droplistNetworks: UseSendState["networks"] = useMemo(() => {
    if (networks) {
      let allNetworks: MarketNetwork[] = [];
      allNetworks = networks.map((network) => {
        return {
          ...network,
          label: network.name,
          value: network.network,
          img: network.iconUrl,
          isDisabled: (toDeposit && !network.depositEnabled) || (toWithdraw && !network.withdrawalEnabled),
        };
      });
      return allNetworks.map(
        (network) =>
          ({
            ...network,
            label: network.name,
          } as DropListWithIconItem<MarketNetwork>),
      );
    } else {
      return [];
    }
  }, [tokenSymbol, networks]);

  const networksByValue = useMemo(() => {
    return networks?.reduce((acc, network) => {
      acc[network.network] = network;
      return acc;
    }, {} as Record<string, MarketNetwork>);
  }, [networks]);

  return {
    networksAreLoading,
    networks,
    droplistNetworks,
    networksByValue,
  };
};
