import { TutorialVideoCardProps } from "./types";
import { Box, CardActionArea, CardContent, Typography, useTheme } from "@mui/material";

export function TutorialVideoCard({ title, onClick }: TutorialVideoCardProps) {
  const theme = useTheme();
  return (
    <Box flex={"1"} display="flex" flexDirection={"column"} justifyContent="flex-end">
      <CardActionArea
        sx={{
          background: theme.palette.background.darkPaper,
          borderRadius: theme.custom.mediumBoxRadius,
        }}
        onClick={onClick}
      >
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.custom.gutterMargin,
          }}
        >
          <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 17.2389V2.59235C0 1.0249 1.72118 0.0666484 3.05353 0.892331L14.3713 7.90617C15.6048 8.67058 15.6392 10.453 14.436 11.2643L3.11826 18.897C1.78989 19.7929 0 18.8411 0 17.2389Z"
              fill="white"
            />
          </svg>

          <Typography
            sx={{
              marginBottom: "0",
              color: theme.palette.text.primary,
            }}
            component={"p"}
            typography="h2"
          >
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Box>
  );
}
