import { useRef, useState } from "react";
import { IconButtonWithPopperProps } from "./types";
import { Box, Fade, IconButton, Popper, Slide, useTheme } from "@mui/material";

export const IconButtonWithPopper = ({
  children,
  popperPlacement = "bottom-end",
  icon,
  id,
  disabled = false,
  onClick,
  onPopperClick,
  isAnimated = false,
  persistent = false,
  animationTimeout = 1000,
  popperSx = {},
}: IconButtonWithPopperProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(isAnimated);
  // UI improvement for the top right border radius from animation start to finish
  const [hasBorderRadius, setHasBorderRadius] = useState(false);
  const anchorEl = useRef();

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    // If the popper is animated, don't close it
    setOpen(isAnimated);
  };

  // Set top-right border radius during the transition
  const animationStart = async () => {
    setTimeout(() => {
      setHasBorderRadius(true);
    }, animationTimeout / 2);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const popperId = canBeOpen ? "transition-popper" : undefined;

  return (
    <Box ref={anchorEl} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} data-testid={id}>
      <IconButton disabled={disabled} onClick={onClick} data-testid={`${id || ""}-btn`} aria-describedby={popperId}>
        {icon}
      </IconButton>
      <Popper
        id={popperId}
        open={open && !disabled}
        anchorEl={anchorEl.current}
        transition
        placement={popperPlacement}
        onClick={onPopperClick}
        sx={{
          padding: theme.custom.smallGutterMargin,
          zIndex: theme.custom.zIndex.baseLayer,
          cursor: onPopperClick ? "pointer" : "default",
          ...popperSx,
        }}
      >
        {({ TransitionProps }) =>
          isAnimated ? (
            <Box
              sx={{
                marginRight: theme.custom.gutterMargin,
                borderTopRightRadius: hasBorderRadius ? theme.custom.mediumBoxRadius : 0,
                borderRight: `1px solid ${theme.palette.cedePrimaryColor}`,
                transform: "translateY(16px)",
                overflow: "hidden",
              }}
            >
              <Slide
                {...TransitionProps}
                timeout={animationTimeout}
                direction="left"
                in={persistent}
                addEndListener={(node) => {
                  node.addEventListener("transitionstart", () => {
                    animationStart();
                  });
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    p: 1,
                    borderTopLeftRadius: theme.custom.mediumBoxRadius,
                    borderTopRightRadius: theme.custom.mediumBoxRadius,
                    borderBottomLeftRadius: theme.custom.mediumBoxRadius,
                    background: theme.palette.cedePrimaryColor,
                  }}
                >
                  {children}
                </Box>
              </Slide>
            </Box>
          ) : (
            <Fade {...TransitionProps} timeout={350}>
              <Box
                sx={{
                  display: "flex",
                  p: 1,
                  borderRadius: theme.custom.defaultBoxRadius,
                  background: theme.palette.background.paper,
                  boxShadow:
                    "0px 5px 5px -3px rgba(0, 0, 0, 0.8), 0px 8px 10px 1px rgba(0, 0, 0, 0.8), 0px 3px 14px 2px rgba(0, 0, 0, 0.8)",
                }}
              >
                {children}
              </Box>
            </Fade>
          )
        }
      </Popper>
    </Box>
  );
};
