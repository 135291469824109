import { useLocation, useNavigate } from "react-router-dom";
import { SendWidgetProps, WidgetType } from "@cede/types";
import { useTrade, useTradeApi, useTradeForm, useTradeTabs } from "../../components";
import { useSend } from "../../components/Send/hooks/useSend";
import { useSendMinAmount } from "../../components/Send/hooks/useSendMinAmount";
import { useTradeAmountComputation } from "../../components/Trade/hooks/useTradeAmountComputation";
import { useTradeEventDispatcher } from "../../components/Trade/hooks/useTradeDispatcher";
import { useTradeFee } from "../../components/Trade/hooks/useTradeFee";
import { useTradeMinAmount } from "../../components/Trade/hooks/useTradeMinAmount";
import {
  // WidgetType,
  useAddressBook,
  useAddressInputValue,
  useConfig,
  useExtensionAsset,
  useNetworks,
  usePartners,
  useStandardizedNetworks,
  useTokenNamesAndSymbols,
  useTriggerUpdateTransaction,
  useWhitelistedAddresses,
  useWithdrawableBalances,
} from "../../hooks";
import { useSupportedTokens } from "../../hooks/supportedTokens/useSupportedTokens";
import { useTradePathBySupportedTokens } from "../../hooks/tradePathBySupportedTokens/useTradePathBySupportedTokens";
import { useDiscordInvitation } from "../../hooks/useDiscordInvitation";
import { useExchangesStatusAlerts } from "../../hooks/useExchangesStatusAlerts";
import { useMultipleTransactionsFees } from "../../hooks/useMultipleTransactionFees";
import { useMultipleTransactions } from "../../hooks/useMultipleTransactions";
import { useMultipleTransactionsMinAmount } from "../../hooks/useMultipleTransactionsMinAmount";
import { getUseMultipleTransactionsStore } from "../../hooks/useMultipleTransactionsStore";
import { useShareOnTwitter } from "../../hooks/useShareOnTwitter";
import { useTradePath } from "../../hooks/useTradePath";
import { useTriggerLoadingConditionally } from "../../hooks/useTriggerLoadingConditionally";
import { useCompletedTransaction } from "../../pages/CompletedTransaction/useCompletedTransaction";
import {
  UseLoadingHook,
  useAlert,
  useGlobalMenu,
  useLoading,
  useSelectedTransaction,
  useSendStore,
  useSettings,
  useSetupSettings,
} from "../../store";
import { CedeReactDependencies } from "@cedelabs/react-sdk/src";

export type DefaultDependencies = {
  [key: string]: unknown;
  useLoading: UseLoadingHook;
  useSendStore: typeof useSendStore;
  useSelectedTransaction: typeof useSelectedTransaction;
  useSend: typeof useSend;
  useWithdrawableBalances: typeof useWithdrawableBalances;
  useAddressBook: typeof useAddressBook;
  useGlobalMenu: typeof useGlobalMenu;
  useSettings: typeof useSettings;
  useSetupSettings: typeof useSetupSettings;
  useAlert: typeof useAlert;
  useNavigate: typeof useNavigate;
  useNetworks: typeof useNetworks;
  useLocation: typeof useLocation;
  useCompletedTransaction: typeof useCompletedTransaction;
  useTriggerUpdateTransaction: typeof useTriggerUpdateTransaction;
  useExtensionAsset: typeof useExtensionAsset;
  useExchangesStatusAlerts: typeof useExchangesStatusAlerts;
  useStandardizedNetworks: typeof useStandardizedNetworks;
  useAddressInputValue: typeof useAddressInputValue;
  useWhitelistedAddresses: typeof useWhitelistedAddresses;
  useSupportedTokens: typeof useSupportedTokens;
  useSendMinAmount: typeof useSendMinAmount;
  useTriggerLoadingConditionally: typeof useTriggerLoadingConditionally;
  usePartners: typeof usePartners;
  useConfig: typeof useConfig;
  useShareOnTwitter: typeof useShareOnTwitter;
  useTradePathBySupportedTokens: typeof useTradePathBySupportedTokens;
  useTokenNamesAndSymbols: typeof useTokenNamesAndSymbols;
  useTradeForm: typeof useTradeForm;
  useTradeApi: typeof useTradeApi;
  useTradeMinAmount: typeof useTradeMinAmount;
  useTrade: typeof useTrade;
  useTradeTabs: typeof useTradeTabs;
  useDiscordInvitation: typeof useDiscordInvitation;
  useTradePath: typeof useTradePath;
  useMultipleTransactionsMinAmount: typeof useMultipleTransactionsMinAmount;
  useMultipleTransactions: typeof useMultipleTransactions;
  useMultipleTransactionsStore: ReturnType<typeof getUseMultipleTransactionsStore>;
  useMultipleTransactionsFees: typeof useMultipleTransactionsFees;
  useTradeFee: typeof useTradeFee;
  useTradeAmountComputation: typeof useTradeAmountComputation;
  useTradeEventDispatcher: typeof useTradeEventDispatcher;
};

export type DefaultDependenciesWithFeatures = CedeReactDependencies &
  DefaultDependencies & { widgetProps: SendWidgetProps };

export const defaultDependencies: DefaultDependenciesWithFeatures = {
  useLoading,
  useSendStore,
  useSelectedTransaction,
  useSend,
  useWithdrawableBalances,
  useAddressBook,
  useGlobalMenu,
  useSettings,
  useSetupSettings,
  useAlert,
  useNavigate,
  useNetworks,
  useLocation,
  useCompletedTransaction,
  useTriggerUpdateTransaction,
  useExtensionAsset,
  useExchangesStatusAlerts,
  useStandardizedNetworks,
  useAddressInputValue,
  useWhitelistedAddresses,
  useSupportedTokens,
  useSendMinAmount,
  useTriggerLoadingConditionally,
  usePartners,
  useConfig,
  useShareOnTwitter,
  useTradePathBySupportedTokens,
  useTokenNamesAndSymbols,
  useTradeForm,
  useTradeApi,
  useTrade,
  useTradeMinAmount,
  useTradeTabs,
  useDiscordInvitation,
  useTradePath,
  useMultipleTransactionsMinAmount,
  useMultipleTransactions,
  useMultipleTransactionsFees,
  useTradeFee,
  useTradeAmountComputation,
  useMultipleTransactionsStore: getUseMultipleTransactionsStore(WidgetType.SEND),
  useTradeEventDispatcher,
} as DefaultDependenciesWithFeatures;
