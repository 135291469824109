import { CustomVariables, PaletteOptions, colors, themeConfig } from "@cede/utils";
import { Components, createTheme, Theme } from "@mui/material";

export const palette: PaletteOptions = themeConfig.palette(colors);

export const typography = themeConfig.typography;

export const components: Components<Theme> = {
  MuiCssBaseline: {
    styleOverrides: {},
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        margin: "none",
      },
    },
  },
  MuiChip: {
    defaultProps: {
      variant: "outlined",
      color: "primary",
    },
    styleOverrides: {
      root: {
        "borderRadius": 5,
        "height": "fit-content",
        ".MuiChip-label": {
          lineHeight: "20px",
          padding: "0 6px",
          fontSize: "12px",
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        ".MuiCardActions-root .MuiCardActions-spacing": {
          padding: 0,
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        legend: {
          width: "auto",
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        legend: {
          width: "auto",
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          backgroundColor: "#424242",
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        "&.MuiTab-labelIcon": {
          minHeight: "48px",
          height: "48px",
          padding: "12px 16px",
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      paper: ({ theme }: { theme: Omit<Theme, "components"> }) => ({
        background: theme.palette.background.paper,
      }),
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: ({ theme }: { theme: Omit<Theme, "components"> }) => ({
        "&.MuiSkeleton-root": {
          backgroundColor: theme.palette.cedeSkeleton,
        },
      }),
    },
  },
};

export const custom: CustomVariables = themeConfig.custom;

export const breakpoints = themeConfig.breakpoints;

export const theme = createTheme({
  palette,
  custom,
  typography,
  components: {
    ...components,
  },
  breakpoints,
});
