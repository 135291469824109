import { ExchangeStatus } from "../api";
import { ApiPermissions, MarketNetwork } from "../cefi";
import { InternalChainId } from "../chains";
import { Any } from "../fakeTypes";
import { Timeframe } from "../trade";
import { AccountId } from "../vault";

export type VaultId = string;

export type Pong = "pong";

export type Transaction<
  F extends CefiInfoTx | DefiInfoTx = CefiInfoTx | DefiInfoTx,
  T extends CefiInfoTx | DefiInfoTx = CefiInfoTx | DefiInfoTx,
> = {
  from: F;
  to: T;
  type: TxType;
  pairSymbol?: string; // Only for trades
  partnerId?: number;
};

export type TemporaryTransaction = Transaction & {
  isTemp: boolean;
};

export type StoreTransaction = Transaction & {
  id: string; // Unique identifier for the transaction whatever the type is or the exchange is
  vaultId: VaultId;
  timestamp: number;
};

export enum TxStatus {
  PENDING = "pending",
  OK = "ok",
  CANCELLED = "cancelled",
  FAILED = "failed",
}

export type TxType = "transfer" | "swap" | "deposit" | "withdrawal" | "order";

export type PrepareWithdrawalParams = {
  accountId: AccountId;
  tokenSymbol: TokenSymbol;
  amount: number;
  address?: string;
  network?: InternalChainId;
  opts?: {
    tradeAndSend?: boolean;
    isInternalTransfer?: boolean;
  };
};

export type TotupWalletTypeParams = {
  accountId: AccountId;
  amount: number;
  tokenSymbol: TokenSymbol;
  action: AssetMovementAction;
};

export function isWalletType(value: Any): value is WalletType {
  return Object.values(WalletType).includes(value);
}

export enum WalletType {
  MAIN = "main",
  FUNDING = "funding",
  SPOT = "spot",
  FUTURES = "futures",
  MARGIN = "margin",
  OPTIONS = "options",
  EARN = "earn",
  BOT = "bot",
  CARD = "card",
  OTHER = "other",
}

export type ExchangeInfo = {
  /**
   * @description The exchange label
   */
  name: string;
  /**
   * @description The exchange identifier
   */
  id: string;
  /**
   * @description The exchange logo
   */
  logo: string;
  /**
   * @description The exchange API's status
   * @example "ok", "shutdown", "error", "maintenance", "unknown
   */
  status: ExchangeStatus;
  /**
   * @description The exchange's internal wallet types supported
   * @example ["spot", "margin", "futures"]
   */
  supportedWalletTypes: string[];
  /**
   * @description The exchange's supported features
   * @example ["send", "trade", "trade_and_send", "receive"]
   */
  supportedFeatures: string[];
  /**
   * @description The exchange's authentication method
   */
  auhenticationMethod: "oauth" | "apiKeys";
  /**
   * @description True if the exchange requires email confirmation
   */
  isRequiringEmailConfirmation: boolean;
  minimumWithdrawalUrl: string | undefined;
  minimumDepositlUrl: string | undefined;
  /**
   * @description True if the exchange requires address whitelisting for withdrawals
   */
  isRequiringAddressWhitelisting: boolean;
  /**
   * @description True if the exchange allows the check if an address is whitelisted
   */
  shouldCheckWhitelist: boolean;
  /**
   * @description True if the exchange provides whitelisted addresses
   */
  provideWhitelistedAddresses: boolean;
  /**
   * @description List of IP addresses that should be whitelisted for a set of credentials
   */
  ipWhitelistAddresses: string[];
  /**
   * @description List of scopes of API permissions that require the use of whitelisted ip addresses
   */
  whitelistScopes: ApiPermissions[] | undefined;
  /**
   * @description The set of features that are under maintenance
   * @example { send: false, trade: false, receive: false, trade_and_send: false }
   */
  featuresUnderMaintenance: {
    send: boolean;
    trade: boolean;
    receive: boolean;
    trade_and_send: boolean;
  };
  /**
   * @description Link to the exchange's API management page
   */
  apiManagementLink: string | undefined;
  /**
   * @description Link to the exchange's address whitelisting and management page
   */
  addressManagementUrl: string | undefined;
  /**
   * @description Cede Labs affiliation link
   */
  affiliationLink: string | undefined;
  /**
   * @description For hybrid exchanges accepting both OAuth and API keys, the permissions allowed for each authentication method
   * @example { oauth: ["read", "trade"], api_keys: ["read", "trade", "withdraw"] }
   */
  hybridAuthentication:
    | {
        oauth: ApiPermissions[];
        api_keys: ApiPermissions[];
      }
    | undefined;
  /**
   * @description Exchange OAuth authentication information
   */
  oauthAuthentication: {
    isOauthLoginSupported: boolean;
    hasPkceFlow: boolean;
    isUsingFastApi: boolean;
  };
  /**
   * @description True if the exchange requires an UID for authentication
   */
  requiresUid: boolean;
  /**
   * @description True if the exchange requires a password for authentication
   */
  requiresPassword: boolean;
  /**
   * @description True if the exchange requires two different keys, one for reading/trading and one for withdrawals
   */
  requiresTwoKeys: boolean;
  /**
   * @description Set of permittions that are induced by other permissions
   * @example { "trade": ["withdraw"], "withdraw": ["trade"] } This example means that the user cannot trade without the withdraw permission and vice versa
   */
  inducedPermissions: Partial<Record<ApiPermissions, ApiPermissions[]>> | undefined;
  /**
   * @description List of internal wallet types that support transfers between them
   * @example ["spot", "margin"]
   */
  transferWalletTypes: WalletType[] | undefined;
  notConnectedIdentifier: string | undefined;
  websiteHomelinkIdentifier: string | undefined;
  /**
   * @description True if the exchange supports KYC verification
   */
  supportVerifyKYC: boolean;
};

export type ExchangeInfoById = { [key: string]: ExchangeInfo };

export type Fee = {
  amount: number; // amount (not percentage) of crypto taken as fee
  refAmount?: number;
  tokenSymbol: TokenSymbol; // crypto token involved in the taken fee
};

export type CefiTx = {
  amount: number;
  refAmount?: number;
  tokenSymbol: TokenSymbol;
  transactionId: string;
  fee: Fee;
  status: TxStatus;
  timestamp: number;
};

export type FeeWithAmounts = {
  feeAmount: number;
  feeTokenSymbol: TokenSymbol;
  baseAmount: number; // amount of crypto after charging the fee
  quoteAmount: number; // amount of crypto after charging the fee
};

export type CefiInfoTx = {
  account: {
    exchangeId: string;
    accountId: AccountId;
    network?: string;
  };
  wallet: {
    depositAddress: string;
    walletType: WalletType;
  };
  transaction: CefiTx;
};

export function isCefiInfoTx(tx: CefiInfoTx | DefiInfoTx): tx is CefiInfoTx {
  return "wallet" in tx;
}

export type DefiInfoTx = {
  account: {
    address: string;
    network: InternalChainId;
  };
  transaction: {
    transactionHash: string;
    fee: Fee;
    status: TxStatus;
    timestamp: number;
    tokens: {
      amount: number;
      refAmount?: number;
      tokenSymbol: TokenSymbol;
      address: string; //  why do we need an address here? I think we should remove it
    }[];
  };
};

export function isDefiInfoTx(tx: CefiInfoTx | DefiInfoTx): tx is DefiInfoTx {
  return !("wallet" in tx);
}

export function hasRefAmount(tx: CefiInfoTx | DefiInfoTx): boolean {
  if (isDefiInfoTx(tx)) {
    return tx.transaction.tokens.every((token) => token.refAmount !== undefined);
  }
  return tx.transaction.refAmount !== undefined;
}

// TODO merge with TokenBalance type
export type TokenBalanceTypes = {
  tokenName?: string;
  freeBalance: number;
  refFreeBalance: number;
  usedBalance: number;
  refUsedBalance: number;
  totalBalance: number;
  refTotalBalance: number;
};

export type GroupedBalancesByWalletType = {
  [tokenSymbol: TokenSymbol]: TokenBalanceTypes & {
    [type in WalletType]?: TokenBalanceTypes;
  };
};

export type BalancesByAccountByWalletType = {
  [accountId: AccountId]: BalancesByWalletType;
};

export type BalancesByWalletType = {
  [walletType in WalletType]: {
    [tokenSymbol: TokenSymbol]: TokenBalanceTypes;
  };
};

export type BalancesByToken = {
  [tokenSymbol: TokenSymbol]: TokenBalanceTypes;
};

export type GroupedBalancesByAccount = {
  [accountId: AccountId]: GroupedBalancesByWalletType;
};

export type BalanceDetailsByType = {
  [type: string]: TokenBalanceTypes;
};

export type MarketPair = {
  pairSymbol: PairSymbol;
  base: TokenSymbol;
  quote: TokenSymbol;
  type: WalletType;
  taker: number;
  maker: number;
  active: boolean;
  precision: {
    amount: number;
    price: number;
    base: number;
    quote: number;
  };
};

export type Ticker = {
  timestamp: number;
  open: number | undefined;
  high: number;
  close: number | undefined;
  low: number;
  last: number | undefined;
  average: number | undefined;
  baseVolume: number | undefined;
  quoteVolume: number | undefined;
};

export type Tickers = {
  [pairSymbol: PairSymbol]: Ticker;
};

export type Nft = {
  data?: {
    name: string;
    description: string;
    imageUrl: string;
    animationUrl?: string;
    attributes: Record<string, string | number>;
    collection: {
      name: string;
      imageUrl: string;
      creator: string;
    };
  };
  amount?: number;
  tokenId: string;
  tokenAddress: string;
  owner?: string;
  network: InternalChainId;
};

export type DepositableToken = {
  tokenSymbol: TokenSymbol;
  networks?: MarketNetwork[];
};

export type TokenWithMarketNetwork = {
  tokenSymbol: TokenSymbol;
  isDepositable: boolean;
  isWithdrawable: boolean;
  networks: MarketNetwork[];
  precision?: number;
};

export type TokenWithMarketNetworkAndTokenName = {
  name?: string;
} & TokenWithMarketNetwork;

export type Network = {
  name: string;
  network: InternalChainId;
  chainId: number; // chain id in decimal format, if possible
};

export type ProviderConfig = {
  [key: string]: string;
};

export type DepositAddress = {
  tokenSymbol: TokenSymbol;
  address: string;
  network: InternalChainId;
  /**
   * @description
   * A tag or memo is a unique identifier assigned to each account
   * for identifying a deposit and crediting the appropriate account.
   *
   * Some exchanges require a tag or memo to be included with the deposit
   * for some tokens, such as BNB or ATOM
   */
  tag?: string;
};

export type TokenSymbol = string;
export type PairSymbol = string;

export type FuturePosition = {
  pairSymbol: PairSymbol;
  marginTokenSymbol: TokenSymbol;
  marginMode: MarginMode;
  positionSide: PositionSide;
  notional: string | undefined;
  timestamp: number | undefined;
  unrealizedPnl: string | undefined;
  leverage: string | undefined;
  entryPrice: string | undefined;
  percentage: string | undefined;
  initialMargin: string | undefined;
  initialMarginPercentage: string | undefined;
};

export type MarketRate = {
  bid: string;
  ask: string;
};

export type Order = {
  id: OrderId;
  timestamp: number;
  status: OrderStatus;
  pairSymbol: PairSymbol;
  type: OrderType;
  side: OrderSide;
  price: number;
  amount: number;
  filled: number;
  fee: Fee;
};

export type OrderId = string;

export type OrderSide = "buy" | "sell";
export type PositionSide = "long" | "short";
export enum MarginMode {
  CROSS = "cross",
  ISOLATED = "isolated",
  UNKNOWN = "unknown",
}

export enum OrderType {
  LIMIT = "limit",
  MARKET = "market",
  UNKNOWN = "unknown",
}

export enum OrderStatus {
  OPEN = "open",
  CLOSED = "closed",
  CANCELED = "canceled",
  UNKNOWN = "unknown",
}

export enum CancelOrderStatus {
  CANCELED = "canceled",
  ALREADY_FILLED = "already_filled",
}

export type Result = "success" | "error";

export type WithdrawToDefiResult = {
  id: string;
  timestamp: number;
};

export type EstimatedFee = Fee;

export type EstimatedFeeAndAmount = {
  estimatedFee: EstimatedFee;
  estimatedAmount: string; // in base currency
};

export type PreparedOrder = EstimatedFeeAndAmount & {
  createOrderRequest: CreateOrderRequest;
};

export type CreateOrderRequest = {
  pairSymbol: PairSymbol;
  orderSide: OrderSide;
  orderType: OrderType;
  price: string; // price from the ticker (base to quote).
  amount: string; // the amount in base currency
  metadata?: {
    tradeAndSend?: boolean;
    defiAddress?: string;
  };
};

export type ConnectParams = {
  onboard?: boolean;
  readOnlyOnboarding?: boolean;
  silent?: boolean;
  callbackUrl?: string;
};

export type MinAmounts = {
  minBaseAmount: string;
  minQuoteAmount: string;
  minPrice: string;
};

export type WithdrawToDefiParams = {
  accountId: AccountId;
  tokenSymbol: TokenSymbol;
  network: string | number;
  amount: string;
  address: string;
  code?: string;
  withdrawalTag?: string;
  isInternalTransfer?: boolean;
  metadata?: {
    tradeAndSend?: boolean;
    referrerSource?: string;
    origin?: string;
    clientTxId?: string;
    callbackUrl?: string;
  };
};

export type GetWhitelistedAddressesParams = {
  accountId: AccountId;
  tokenSymbol?: TokenSymbol;
  network?: InternalChainId;
};
export enum AssetMovementAction {
  TRADE = "trade",
  SEND = "send",
}

export type TotupPath = {
  fromWalletType: WalletType;
  fromWalletTypeExternalName: string;
  toWalletType: WalletType;
  toWalletTypeExternalName: string;
};

export type WithdrawableTokensParams = {
  accountId: AccountId;
  network?: InternalChainId;
};

export type VaultAccountsParams = {
  vaultId: VaultId;
};
export type TransactionsParams = {
  vaultId: VaultId;
  accountIds?: AccountId[];
  tokenSymbol?: TokenSymbol;
  txType?: string;
  startTime?: number;
  pageCount?: number;
  pageIndex?: number;
};

export type BalancesParams = {
  vaultId: VaultId;
  accountId?: AccountId[];
  walletTypes?: WalletType[];
  opts?: {
    forceRefresh?: boolean;
  };
};

export type DepositableTokensParams = {
  accountId: AccountId;
};

export type GetNetworksParams = {
  accountId: AccountId;
  tokenSymbol?: TokenSymbol;
  opts: {
    toDeposit?: boolean;
    toWithdraw?: boolean;
    isInternalTransfer?: boolean;
  };
};

export type GetNetworksBetweenCexParams = {
  accountId: AccountId;
  toAccountId: AccountId;
  tokenSymbol: TokenSymbol;
};

export type GetSupportedTokensParams = {
  accountId: AccountId;
  network?: string;
};

export type DepositParams = {
  accountId: AccountId;
  tokenSymbol: TokenSymbol;
  network: InternalChainId;
};

export type OpenOrdersParams = {
  accountId: AccountId;
  pairSymbol: PairSymbol;
  since?: number;
  limit?: number;
};

export type CreateOrderParams = {
  accountId: AccountId;
  pairSymbol: PairSymbol;
  orderSide: OrderSide;
  orderType: OrderType;
  amount: string;
  price: string;
  metadata?: {
    tradeAndSend?: boolean;
    defiAddress?: string;
  };
};

export type NftsParams = {
  accountId: AccountId;
};

export type RetrieveOrderParams = {
  accountId: AccountId;
  orderId: string;
  pairSymbol: PairSymbol;
};

export type EditOrderParams = {
  accountId: AccountId;
  orderId: OrderId;
  pairSymbol: PairSymbol;
  orderSide?: OrderSide;
  orderType?: OrderType;
  price?: string;
  amount?: string;
};

export type GetMinAmountsParams = {
  accountId: AccountId;
  pairSymbol: PairSymbol;
  orderSide: OrderSide;
  price: string;
};

export type MarketPairsParams = {
  accountId: AccountId;
};

export type MarketRateParams = {
  accountId: AccountId;
  pairSymbol: PairSymbol;
};

export type CancelOrderParams = {
  accountId: AccountId;
  orderId: OrderId;
  pairSymbol: PairSymbol;
};

export type AddCexParams = {
  exchangeId?: string;
  readOnlyOnboarding?: boolean;
  callbackUrl?: string;
};

export type GetWithdrawalByIdParams = {
  accountId: string;
  withdrawalId: string;
  tokenSymbol: string;
  timestamp: number;
};

/**
 * @param timestamp Only for withdrawal transaction
 * @param tokenOrPairSymbol For trade transaction, it's the pair symbol (ex: BTC/USDT) and for withdrawal transaction, it's the token symbol (ex: BTC)
 */
export type SearchTransactionParams = {
  accountId: string;
  transactionId: string;
  transactionType: "trade" | "withdrawal";
  tokenOrPairSymbol: string;
  timestamp?: number;
};

export type GetWidgetBundleUrlParams = {
  name: string;
};

export type WhitelistedAddresses = {
  address: string;
  key: string;
  network: InternalChainId;
  tokenSymbol?: TokenSymbol; // It's not defined for universal addresses
  verified: boolean;
};

export type GetTradePathParams = {
  accountId: string;
  to: string;
  from: string;
};

export type GetTradePathBySupportedTokensParams = {
  accountId: string;
  from: string;
  network: InternalChainId;
};

export type GetOHLCVParams = {
  accountId: string;
  pairSymbol: string;
  timeframe: Timeframe;
  from: number;
  to: number;
  limit: number;
  opts?: {
    forceRefresh?: boolean;
  };
};

export type VerifyKYCParams = {
  accountId: string;
  tokenSymbol?: TokenSymbol;
  network?: InternalChainId;
  callbackUrl: string;
};

export type VerifyKYCReturn = {
  balances: BalancesByWalletType;
  depositAddress: DepositAddress;
  exchangeId: string;
};

export enum UrlRoot {
  POPUP = "popup",
  REQUEST_ONBOARDING = "requestOnboarding",
  REQUEST = "request",
}

export type OpenTabParams = {
  route?: string;
  root?: UrlRoot;
};
