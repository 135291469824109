import { useEffect } from "react";
import { useNavBar } from "@cede/ds";
import { CedeOnboardingModal } from "../../features/CedeOnboardingModal";
import { useDependencies } from "../../hooks/useDependencies";
import { DefaultLogin } from "./DefaultLogin";

export const Login = () => {
  const { useWidgetLogin } = useDependencies();
  const { isOnboardingModalOpened, closeOnboardingModal } = useWidgetLogin();
  const { setCenter, setLeftSide, setIsDisplayed } = useNavBar();

  useEffect(() => {
    setIsDisplayed(false);
    setLeftSide(null);
    setCenter(null);
  }, []);

  return (
    <>
      <CedeOnboardingModal open={isOnboardingModalOpened} exit={closeOnboardingModal} />
      <DefaultLogin />
    </>
  );
};
