import { useDependencies } from "./useDependencies";
import { CedeReactDependencies, useBackgroundHandler, UseProviderProps } from "@cedelabs/react-sdk/src";

/**
 * Wrapper of useBackgroundHandler hook to handle the case when the provider is not found and
 * a mocked background handler needs to be retrieved from the context.
 */
export const useWidgetBackgroundHandler = ({ onProviderNotFound, onProviderFound }: UseProviderProps = {}): ReturnType<
  CedeReactDependencies["useBackgroundHandler"]
> => {
  const { backgroundHandler } = useDependencies();
  const { refetchProvider, backgroundHandler: nativeBackgroundHandler } = useBackgroundHandler({
    onProviderNotFound,
    onProviderFound,
  });

  /**
   * If the native background handler isn't present, we return the background handler from the context
   * which is possibly the mocked one
   */
  return { backgroundHandler: nativeBackgroundHandler || backgroundHandler, refetchProvider };
};
