import { useState, useEffect } from "react";
import { PricesByTokenSymbol, TokenPrices } from "@cede/types";
import { fakePrices, convertToDictionary } from "@cede/utils";
import { useDependencies } from "../useDependencies";
import { useQuery } from "@tanstack/react-query";

const ENVIRONMENT = process.env.CEDE_NODE_ENV;

export const usePrices = () => {
  const { useBackgroundHandler } = useDependencies();
  const { backgroundHandler } = useBackgroundHandler();

  const { data } = useQuery<PricesByTokenSymbol>({
    queryKey: ["pricesData"],
    queryFn: async () => {
      if (ENVIRONMENT === "test") {
        return fakePrices;
      }
      const data = await backgroundHandler?.getPrices();
      return data;
    },
    enabled: !!backgroundHandler?.isReady,
  });

  const [prices, setPrices] = useState<PricesByTokenSymbol>({});

  useEffect(() => {
    if (data) {
      setPrices(ENVIRONMENT === "test" ? fakePrices : convertToDictionary(data));
    }
  }, [data]);
  return prices;
};

/**
 * Provides a price for a given symbol of a token.
 * @param symbol
 */
export const usePrice = (symbol: string) => {
  const data = usePrices();

  if (data) {
    return data[symbol] ?? 0;
  }

  return 0;
};

/**
 * React hook returning a function to get prices.
 * Once the function is called, it will continue to poll with a given interval.
 * Once the prices are fetched, the callback is called with the prices as argument.
 * @param callbacks
 */
export const useSetupPrices = (...callbacks: (({ prices }?: { prices: TokenPrices }) => void)[]) => {
  const { useBackgroundHandler } = useDependencies();
  const { backgroundHandler } = useBackgroundHandler();

  const { refetch: getPrices, data } = useQuery({
    queryKey: ["pricesData"],
    queryFn: async () => {
      if (ENVIRONMENT === "test") {
        return fakePrices;
      }
      const data = await backgroundHandler?.getPrices();
      return data;
    },
    refetchOnWindowFocus: false,
    enabled: !!backgroundHandler?.isReady,
    refetchInterval: 1000 * 60 * 5, // 5 minutes
  });

  useEffect(() => {
    if (ENVIRONMENT === "test") {
      callbacks.forEach((callback) => callback());
      return;
    }

    if (data) {
      const prices = convertToDictionary(data);
      callbacks.forEach((callback) => callback({ prices }));
    }
  }, [data]);

  return getPrices;
};
