import { userFacingMessages } from "@cede/types";
import { useDependencies } from "../../hooks";
import { Typography } from "@mui/material";

export const CedeStoreMessage = () => {
  const { useSelectedTransaction } = useDependencies();
  const { transaction, isSend } = useSelectedTransaction(({ transaction, isSend }) => ({ transaction, isSend }));
  if (!transaction || !isSend) return null;

  return (
    <>
      <Typography variant={"body2"}>Cede.store message</Typography>
      <Typography variant={"body1"} fontWeight={"bold"} justifySelf={"flex-end"}>
        {userFacingMessages.SEND.YOUR_FUND_SAFE}
      </Typography>
    </>
  );
};
