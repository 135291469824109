import { useMemo } from "react";
import { useDependencies } from "../../../hooks";

type UseSendMinAmountProps = {
  shouldUseTradeAndSend?: boolean;
  supportedTokensAreLoading?: boolean;
};

export const useSendMinAmount = ({ shouldUseTradeAndSend = false }: UseSendMinAmountProps = {}) => {
  const { useSendStore, useNetworks, useMultipleTransactionsMinAmount } = useDependencies();
  const form = useSendStore();
  const { droplistNetworks, networksAreLoading } = useNetworks({
    toDeposit: false,
    toWithdraw: true,
    accountId: form.fromAccount?.value ?? "",
    tokenSymbol: form.currency?.value,
    isInternalTransfer: !!form.initialProps.isInternalTransfer,
  });
  const { minAmount: multipleTransactionsMinAmount, isLoading: multiWithdrawalMinIsLoading } =
    useMultipleTransactionsMinAmount({
      enabled: shouldUseTradeAndSend,
      accountId: form.fromAccount?.value ?? "",
      fromToken: form.currency?.value ?? "",
      toToken: form.destinationCurrency?.value ?? "",
      network: form.network || undefined,
      activeWithdrawal: true,
      isInternalTransfer: !!form.initialProps.isInternalTransfer,
    });

  const classicWithdrawalMinAmount = useMemo(() => {
    if (!form.currency) return undefined;
    const network = droplistNetworks.find((network) => network.network === form.network?.value);
    if (network) {
      // min amount must take into account the fee if defined
      const minAmount = Number(network.withdrawMin);
      const fee = Number(network.withdrawFee);
      return String(fee ? minAmount + fee : minAmount);
    }
    return undefined;
  }, [form.network, form.currency, droplistNetworks]);

  return {
    minAmount: shouldUseTradeAndSend ? multipleTransactionsMinAmount : classicWithdrawalMinAmount,
    minAmountIsLoading:
      (shouldUseTradeAndSend && (multiWithdrawalMinIsLoading || multipleTransactionsMinAmount === undefined)) ||
      networksAreLoading,
  };
};
