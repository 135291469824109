import { safeJsonParse } from "@cede/utils";
import { PersistStorage } from "zustand/middleware/persist";

type GetTimeLimitedPersistStorageParams<T> = {
  onHydration?: (state: T) => T;
  ttl: number;
};

export const getTimeLimitedPersistStorage = <T = unknown>({
  ttl,
  onHydration,
}: GetTimeLimitedPersistStorageParams<T>): PersistStorage<T> => {
  return {
    getItem(key: string) {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
        return null;
      }

      const item = safeJsonParse(itemStr);
      const now = new Date();

      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }

      const value = item.value;

      if (onHydration) {
        return { state: onHydration(value) };
      }

      return { state: value };
    },

    setItem(key: string, value) {
      const now = new Date();
      const item = {
        value: value.state,
        expiry: now.getTime() + ttl,
      };
      localStorage.setItem(key, JSON.stringify(item));
    },

    removeItem(key: string) {
      window.localStorage.removeItem(key);
    },
  };
};
