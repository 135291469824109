import { ApiPermissions } from "@cede/types";
import { checkRequiredPermissionStatement } from "./checkRequiredPermissionStatement";

export function buildStringWithRequirements(value: string, permissions: ApiPermissions[]) {
  const regex = /{(.*?)}/g;
  const matches = value.match(regex);
  matches?.forEach((match) => {
    try {
      const require = match.match(/{require(.*?)}/g);
      if (!require?.length) throw new Error();
      const requireStatement = require[0]?.match(/\[(.*?)\]/g);
      if (!requireStatement?.length) throw new Error();
      const requiredPermissions = requireStatement[0]?.replace("[", "")?.replace("]", "");
      if (!checkRequiredPermissionStatement(requiredPermissions, permissions)) throw new Error();
      const conditionalValue = match.match(/=(.*?)\}/g);
      if (!conditionalValue?.length) throw new Error();
      value = value.replace(match, conditionalValue[0]?.replace("=", "")?.replace("}", "") || "");
    } catch (error) {
      value = value.replace(match, "");
    }
  });
  return value;
}
