// ----------------------------------------------------------------------------

// PLEASE DO NOT EDIT THIS FILE, IT IS GENERATED AND WILL BE OVERWRITTEN:
// https://github.com/ccxt/ccxt/blob/master/CONTRIBUTING.md#how-to-contribute-code
// EDIT THE CORRESPONDENT .ts FILE INSTEAD

//  ---------------------------------------------------------------------------
import Exchange from './abstract/kraken.js';
import { Precise } from './base/Precise.js';
import { TICK_SIZE, TRUNCATE } from './base/functions/number.js';
import { AuthenticationError, ExchangeAccountSuspendedError, ExchangeDDosProtectionError, ExchangeError, ExchangeInvalidNonceError, ExchangeInvalidPermissionsError, ExchangeNotAvailableError, ExchangeTransferError, ExchangeUnderMaintenanceError, ExchangeUnknownAssetError, GetDepositAddressError, InsufficientBalanceError, InvalidAddressError, InvalidParamsError, MarketNotFoundError, MarketNotLoadedError, MarketTypeNotSupportedError, OrderNotFoundError, TradeError, UnderWithdrawMinimumError } from './sdk/errors.js';
import { SdkErrorMessages } from './sdk/errorsMetadata.js';
import { sha256 } from './static_dependencies/noble-hashes/sha256.js';
import { sha512 } from './static_dependencies/noble-hashes/sha512.js';
//  ---------------------------------------------------------------------------
/**
 * @class kraken
 * @augments Exchange
 * @description Set rateLimit to 1000 if fully verified
 */
export default class kraken extends Exchange {
    describe() {
        return this.deepExtend(super.describe(), {
            'id': 'kraken',
            'name': 'Kraken',
            'countries': ['US'],
            'version': '0',
            'rateLimit': 3000,
            'certified': false,
            'pro': true,
            'has': {
                'CORS': undefined,
                'spot': true,
                'margin': true,
                'swap': false,
                'future': false,
                'option': false,
                'cancelAllOrders': true,
                'cancelOrder': true,
                'cancelOrders': true,
                'createDepositAddress': true,
                'createOrder': true,
                'createTrailingAmountOrder': true,
                'editOrder': true,
                'fetchBalance': true,
                'fetchClosedOrders': true,
                'fetchCurrencies': true,
                'fetchDepositAddress': true,
                'fetchDeposits': true,
                'fetchLedger': true,
                'fetchLedgerEntry': true,
                'fetchMarkets': true,
                'fetchMyTrades': true,
                'fetchOHLCV': true,
                'fetchOpenOrders': true,
                'fetchOrder': true,
                'fetchOrderBook': true,
                'fetchOrderTrades': 'emulated',
                'fetchPositions': true,
                'fetchTicker': true,
                'fetchTickers': true,
                'fetchTime': true,
                'fetchTrades': true,
                'fetchTradingFee': true,
                'fetchTradingFees': false,
                'fetchWithdrawals': true,
                'transfer': true,
                'withdraw': true,
            },
            'timeframes': {
                '1m': 1,
                '5m': 5,
                '15m': 15,
                '30m': 30,
                '1h': 60,
                '4h': 240,
                '1d': 1440,
                '1w': 10080,
                '2w': 21600,
            },
            'urls': {
                'logo': 'https://user-images.githubusercontent.com/51840849/76173629-fc67fb00-61b1-11ea-84fe-f2de582f58a3.jpg',
                'api': {
                    'public': 'https://api.kraken.com',
                    'private': 'https://api.kraken.com',
                    'zendesk': 'https://kraken.zendesk.com/api/v2/help_center/en-us/articles', // use the public zendesk api to receive article bodies and bypass new anti-spam protections
                },
                'www': 'https://www.kraken.com',
                'doc': 'https://docs.kraken.com/rest/',
                'fees': 'https://www.kraken.com/en-us/features/fee-schedule',
            },
            'fees': {
                'trading': {
                    'tierBased': true,
                    'percentage': true,
                    'taker': this.parseNumber('0.0026'),
                    'maker': this.parseNumber('0.0016'),
                    'tiers': {
                        'taker': [
                            [this.parseNumber('0'), this.parseNumber('0.0026')],
                            [this.parseNumber('50000'), this.parseNumber('0.0024')],
                            [this.parseNumber('100000'), this.parseNumber('0.0022')],
                            [this.parseNumber('250000'), this.parseNumber('0.0020')],
                            [this.parseNumber('500000'), this.parseNumber('0.0018')],
                            [this.parseNumber('1000000'), this.parseNumber('0.0016')],
                            [this.parseNumber('2500000'), this.parseNumber('0.0014')],
                            [this.parseNumber('5000000'), this.parseNumber('0.0012')],
                            [this.parseNumber('10000000'), this.parseNumber('0.0001')],
                        ],
                        'maker': [
                            [this.parseNumber('0'), this.parseNumber('0.0016')],
                            [this.parseNumber('50000'), this.parseNumber('0.0014')],
                            [this.parseNumber('100000'), this.parseNumber('0.0012')],
                            [this.parseNumber('250000'), this.parseNumber('0.0010')],
                            [this.parseNumber('500000'), this.parseNumber('0.0008')],
                            [this.parseNumber('1000000'), this.parseNumber('0.0006')],
                            [this.parseNumber('2500000'), this.parseNumber('0.0004')],
                            [this.parseNumber('5000000'), this.parseNumber('0.0002')],
                            [this.parseNumber('10000000'), this.parseNumber('0.0')],
                        ],
                    },
                },
            },
            'handleContentTypeApplicationZip': true,
            'api': {
                'zendesk': {
                    'get': [
                        // we should really refrain from putting fixed fee numbers and stop hardcoding
                        // we will be using their web APIs to scrape all numbers from these articles
                        '360000292886',
                        '201893608', // -What-are-the-withdrawal-fees-
                    ],
                },
                'public': {
                    'get': {
                        // public endpoint rate-limits are described in article: https://support.kraken.com/hc/en-us/articles/206548367-What-are-the-API-rate-limits-#1
                        'Assets': 1,
                        'AssetPairs': 1,
                        'Depth': 1,
                        'OHLC': 1,
                        'Spread': 1,
                        'SystemStatus': 1,
                        'Ticker': 1,
                        'Time': 1,
                        'Trades': 1,
                    },
                },
                'private': {
                    'post': {
                        'AddOrder': 0,
                        'AddOrderBatch': 0,
                        'AddExport': 1,
                        'Balance': 1,
                        'CancelAll': 1,
                        'CancelAllOrdersAfter': 1,
                        'CancelOrder': 0,
                        'CancelOrderBatch': 0,
                        'ClosedOrders': 1,
                        'DepositAddresses': 1,
                        'DepositMethods': 1,
                        'DepositStatus': 1,
                        'EditOrder': 0,
                        'ExportStatus': 1,
                        'GetWebSocketsToken': 1,
                        'Ledgers': 2,
                        'OpenOrders': 1,
                        'OpenPositions': 1,
                        'QueryLedgers': 1,
                        'QueryOrders': 1,
                        'QueryTrades': 1,
                        'RetrieveExport': 1,
                        'RemoveExport': 1,
                        'BalanceEx': 1,
                        'TradeBalance': 1,
                        'TradesHistory': 2,
                        'TradeVolume': 1,
                        'Withdraw': 1,
                        'WithdrawCancel': 1,
                        'WithdrawInfo': 1,
                        'WithdrawMethods': 1,
                        'WithdrawAddresses': 1,
                        'WithdrawStatus': 1,
                        'WalletTransfer': 1,
                        // sub accounts
                        'CreateSubaccount': 1,
                        'AccountTransfer': 1,
                        // earn
                        'Earn/Allocate': 1,
                        'Earn/Deallocate': 1,
                        'Earn/AllocateStatus': 1,
                        'Earn/DeallocateStatus': 1,
                        'Earn/Strategies': 1,
                        'Earn/Allocations': 1,
                    },
                },
            },
            'commonCurrencies': {
                'LUNA': 'LUNC',
                'LUNA2': 'LUNA',
                'REPV2': 'REP',
                'REP': 'REPV1',
                'UST': 'USTC',
                'XBT': 'BTC',
                'XBT.M': 'BTC.M',
                'XDG': 'DOGE',
            },
            'options': {
                'marketsByAltname': {},
                'timeDifference': 0,
                'delistedMarketsById': {},
                // cannot withdraw/deposit these
                'inactiveCurrencies': ['CAD', 'USD', 'JPY', 'GBP'],
                'networks': {
                    'ETH': 'ERC20',
                    'TRX': 'TRC20',
                },
                // used for deposit methods since they are different from withdrawal methods sometimes
                'hardCodedMethodsToEcid': {
                    'Ethereum (ERC20)': 'Ethereum',
                    '1INCH - Ethereum (Unified)': 'Ethereum',
                    'AAVE - Ethereum (Unified)': 'Ethereum',
                    'ACH - Ethereum (Unified)': 'Ethereum',
                    'ADX - Ethereum (Unified)': 'Ethereum',
                    'AGLD - Ethereum (Unified)': 'Ethereum',
                    'ALCX - Ethereum (Unified)': 'Ethereum',
                    'ALICE - Ethereum (Unified)': 'Ethereum',
                    'ALPHA - Ethereum (Unified)': 'Ethereum',
                    'ANKR - Ethereum (Unified)': 'Ethereum',
                    'Link - Ethereum (Unified)': 'Ethereum',
                    'Marinade SOL (mSOL)': 'Solana',
                    'ETHW  - Ethereum PoW (Unified)': 'Ethereum PoW',
                    'REPv2 - Ethereum (Unified)': 'Ethereum',
                    'TRC20 (USDC)': 'Tron',
                },
                'withdrawalNetworks': [],
                'currenciesNetworks': {},
            },
            'precisionMode': TICK_SIZE,
            'exceptions': {
                'exact': {
                    'EQuery:Invalid asset pair': MarketNotFoundError,
                    'EAPI:Invalid key': AuthenticationError,
                    'EFunding:Unknown withdraw key': InvalidAddressError,
                    'EFunding:Invalid amount': InsufficientBalanceError,
                    'EFunding:Fee amount is greater than asset amount': UnderWithdrawMinimumError,
                    'EService:Unavailable': ExchangeNotAvailableError,
                    'EDatabase:Internal error': ExchangeNotAvailableError,
                    'EService:Busy': ExchangeNotAvailableError,
                    'EQuery:Unknown asset': ExchangeUnknownAssetError,
                    'EAPI:Rate limit exceeded': ExchangeDDosProtectionError,
                    'EOrder:Rate limit exceeded': ExchangeDDosProtectionError,
                    'EGeneral:Internal error': ExchangeNotAvailableError,
                    'EGeneral:Temporary lockout': ExchangeDDosProtectionError,
                    'EGeneral:Permission denied': ExchangeInvalidPermissionsError,
                    'EOrder:Unknown order': TradeError,
                    'EOrder:Order minimum not met': InvalidParamsError,
                    'EGeneral:Invalid arguments': InvalidParamsError,
                    'ESession:Invalid session': AuthenticationError,
                    'EAPI:Invalid nonce': ExchangeInvalidNonceError,
                    'EFunding:No funding method': ExchangeError,
                    'EFunding:Unknown asset': ExchangeUnknownAssetError,
                    'EService:Market in post_only mode': ExchangeUnderMaintenanceError,
                    'EGeneral:Too many requests': ExchangeDDosProtectionError,
                    'ETrade:User Locked': ExchangeAccountSuspendedError, // {"error":["ETrade:User Locked"]}
                },
                'broad': {
                    'Invalid order': InvalidParamsError,
                    'Invalid nonce': ExchangeInvalidNonceError,
                    'Insufficient funds': InsufficientBalanceError,
                    'Cancel pending': ExchangeError,
                    'Invalid arguments:volume': InvalidParamsError,
                    'Rate limit exceeded': ExchangeDDosProtectionError,
                },
                'messages': {
                    'EGeneral:Invalid arguments:volume': SdkErrorMessages.ORDER_UNDER_MINIMUM,
                },
            },
        });
    }
    feeToPrecision(symbol, fee) {
        return this.decimalToPrecision(fee, TRUNCATE, this.markets[symbol]['precision']['amount'], this.precisionMode);
    }
    async fetchMarkets(params = {}) {
        /**
         * @method
         * @name kraken#fetchMarkets
         * @description retrieves data on all markets for kraken
         * @see https://docs.kraken.com/rest/#tag/Market-Data/operation/getTradableAssetPairs
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object[]} an array of objects representing market data
         */
        const response = await this.publicGetAssetPairs(params);
        //
        //     {
        //         "error": [],
        //         "result": {
        //             "ADAETH": {
        //                 "altname": "ADAETH",
        //                 "wsname": "ADA\/ETH",
        //                 "aclass_base": "currency",
        //                 "base": "ADA",
        //                 "aclass_quote": "currency",
        //                 "quote": "XETH",
        //                 "lot": "unit",
        //                 "pair_decimals": 7,
        //                 "lot_decimals": 8,
        //                 "lot_multiplier": 1,
        //                 "leverage_buy": [],
        //                 "leverage_sell": [],
        //                 "fees": [
        //                     [0, 0.26],
        //                     [50000, 0.24],
        //                     [100000, 0.22],
        //                     [250000, 0.2],
        //                     [500000, 0.18],
        //                     [1000000, 0.16],
        //                     [2500000, 0.14],
        //                     [5000000, 0.12],
        //                     [10000000, 0.1]
        //                 ],
        //                 "fees_maker": [
        //                     [0, 0.16],
        //                     [50000, 0.14],
        //                     [100000, 0.12],
        //                     [250000, 0.1],
        //                     [500000, 0.08],
        //                     [1000000, 0.06],
        //                     [2500000, 0.04],
        //                     [5000000, 0.02],
        //                     [10000000, 0]
        //                 ],
        //                 "fee_volume_currency": "ZUSD",
        //                 "margin_call": 80,
        //                 "margin_stop": 40,
        //                 "ordermin": "1"
        //             },
        //         }
        //     }
        //
        const markets = this.safeValue(response, 'result', {});
        const keys = Object.keys(markets);
        let result = [];
        for (let i = 0; i < keys.length; i++) {
            const id = keys[i];
            const market = markets[id];
            const baseId = this.safeString(market, 'base');
            const quoteId = this.safeString(market, 'quote');
            const base = this.safeCurrencyCode(baseId);
            const quote = this.safeCurrencyCode(quoteId);
            const darkpool = id.indexOf('.d') >= 0;
            const altname = this.safeString(market, 'altname');
            const makerFees = this.safeValue(market, 'fees_maker', []);
            const firstMakerFee = this.safeValue(makerFees, 0, []);
            const firstMakerFeeRate = this.safeString(firstMakerFee, 1);
            let maker = undefined;
            if (firstMakerFeeRate !== undefined) {
                maker = this.parseNumber(Precise.stringDiv(firstMakerFeeRate, '100'));
            }
            const takerFees = this.safeValue(market, 'fees', []);
            const firstTakerFee = this.safeValue(takerFees, 0, []);
            const firstTakerFeeRate = this.safeString(firstTakerFee, 1);
            let taker = undefined;
            if (firstTakerFeeRate !== undefined) {
                taker = this.parseNumber(Precise.stringDiv(firstTakerFeeRate, '100'));
            }
            const leverageBuy = this.safeValue(market, 'leverage_buy', []);
            const leverageBuyLength = leverageBuy.length;
            const precisionPrice = this.parseNumber(this.parsePrecision(this.safeString(market, 'pair_decimals')));
            result.push({
                'id': id,
                'wsId': this.safeString(market, 'wsname'),
                'symbol': darkpool ? altname : (base + '/' + quote),
                'base': base,
                'quote': quote,
                'settle': undefined,
                'baseId': baseId,
                'quoteId': quoteId,
                'settleId': undefined,
                'darkpool': darkpool,
                'altname': market['altname'],
                'type': 'spot',
                'spot': true,
                'margin': (leverageBuyLength > 0),
                'swap': false,
                'future': false,
                'option': false,
                'active': true,
                'contract': false,
                'linear': undefined,
                'inverse': undefined,
                'taker': taker,
                'maker': maker,
                'contractSize': undefined,
                'expiry': undefined,
                'expiryDatetime': undefined,
                'strike': undefined,
                'optionType': undefined,
                'precision': {
                    'amount': this.parseNumber(this.parsePrecision(this.safeString(market, 'lot_decimals'))),
                    'price': precisionPrice,
                },
                'limits': {
                    'leverage': {
                        'min': this.parseNumber('1'),
                        'max': this.safeNumber(leverageBuy, leverageBuyLength - 1, 1),
                    },
                    'amount': {
                        'min': this.safeNumber(market, 'ordermin'),
                        'max': undefined,
                    },
                    'price': {
                        'min': precisionPrice,
                        'max': undefined,
                    },
                    'cost': {
                        'min': this.safeNumber(market, 'costmin'),
                        'max': undefined,
                    },
                },
                'created': undefined,
                'info': market,
            });
        }
        result = this.appendInactiveMarkets(result);
        this.options['marketsByAltname'] = this.indexBy(result, 'altname');
        return result;
    }
    safeCurrency(currencyId, currency = undefined) {
        if (currencyId !== undefined) {
            if (currencyId.length > 3) {
                if ((currencyId.indexOf('X') === 0) || (currencyId.indexOf('Z') === 0)) {
                    if (currencyId.indexOf('.') > 0) {
                        return super.safeCurrency(currencyId, currency);
                    }
                    else {
                        currencyId = currencyId.slice(1);
                    }
                }
            }
        }
        return super.safeCurrency(currencyId, currency);
    }
    appendInactiveMarkets(result) {
        // result should be an array to append to
        const precision = {
            'amount': this.parseNumber('1e-8'),
            'price': this.parseNumber('1e-8'),
        };
        const costLimits = { 'min': undefined, 'max': undefined };
        const priceLimits = { 'min': precision['price'], 'max': undefined };
        const amountLimits = { 'min': precision['amount'], 'max': undefined };
        const limits = { 'amount': amountLimits, 'price': priceLimits, 'cost': costLimits };
        const defaults = {
            'darkpool': false,
            'info': undefined,
            'maker': undefined,
            'taker': undefined,
            'active': false,
            'precision': precision,
            'limits': limits,
        };
        const markets = [
        // { 'id': 'XXLMZEUR', 'symbol': 'XLM/EUR', 'base': 'XLM', 'quote': 'EUR', 'altname': 'XLMEUR' },
        ];
        for (let i = 0; i < markets.length; i++) {
            result.push(this.extend(defaults, markets[i]));
        }
        return result;
    }
    async fetchStatus(params = {}) {
        /**
         * @method
         * @name kraken#fetchStatus
         * @description the latest known information on the availability of the exchange API
         * @see https://docs.kraken.com/rest/#tag/Spot-Market-Data/operation/getSystemStatus
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a [status structure]{@link https://docs.ccxt.com/#/?id=exchange-status-structure}
         */
        const response = await this.publicGetSystemStatus(params);
        // {
        //   "error": [],
        //   "result": {
        //     "status": "online",
        //     "timestamp": "2023-07-06T18:52:00Z"
        //   }
        // }
        const result = this.safeValue(response, 'result', {});
        const status = this.safeString(result, 'status');
        const parsedStatus = this.safeString({ 'online': 'ok', 'maintenance': 'maintenance', 'cancel_only': 'maintenance', 'post_only': 'maintenance' }, status, 'maintenance');
        const timestamp = this.parse8601(this.safeString(result, 'timestamp'));
        return {
            'status': parsedStatus,
            'updated': timestamp,
            'eta': undefined,
            'url': undefined,
            'info': response,
        };
    }
    async fetchWithdrawalMethods() {
        let withdrawalNetworks = this.safeValue(this.options, 'withdrawalNetworks', []);
        if (withdrawalNetworks.length === 0 && this.checkRequiredCredentials(false)) {
            try {
                const withdrawNetworkResponse = await this.privatePostWithdrawMethods();
                // Withdrawal Methods
                // {
                //     "error": [],
                //     "result": [
                //       {
                //         "asset": "XXBT",
                //         "method": "Bitcoin",
                //         "network": "Bitcoin",
                //         "minimum": "0.0004"
                //       },
                //       {
                //         "asset": "XXBT",
                //         "method": "Bitcoin Lightning",
                //         "network": "Lightning",
                //         "minimum": "0.00001"
                //       }
                //     ]
                //  }
                this.handleErrors(null, null, null, null, null, {}, withdrawNetworkResponse, null, null);
                withdrawalNetworks = this.safeValue(withdrawNetworkResponse, 'result', []);
                this.options['withdrawalNetworks'] = withdrawalNetworks;
            }
            catch (e) {
                // The user does not have access to the endpoint, not a big deal we just return an empty array
                if (e instanceof ExchangeInvalidPermissionsError) {
                    return [];
                }
                throw e;
            }
        }
        this.options['methodsByEcids'] = {};
        this.options['ecidsByMethods'] = {};
        const networksPerCurrency = {};
        for (let i = 0; i < withdrawalNetworks.length; i++) {
            const network = this.safeValue(withdrawalNetworks, i);
            const asset = this.safeString(network, 'asset');
            const code = this.safeCurrencyCode(asset);
            const networkInformation = {
                'info': network,
                'network': this.safeString(network, 'network'),
                'withdraw': {
                    'min': this.parseNumber(this.safeString(network, 'minimum')),
                    'max': undefined,
                },
                'withdraw_enabled': true,
            };
            this.options['methodsByEcids'][code] = this.safeValue(this.options['methodsByEcids'], code, {});
            this.options['ecidsByMethods'][code] = this.safeValue(this.options['ecidsByMethods'], code, {});
            const method = this.safeString(networkInformation['info'], 'method');
            this.options['methodsByEcids'][code][networkInformation['network']] = method;
            this.options['ecidsByMethods'][code][method] = networkInformation['network'];
            const currencyNetworks = this.safeValue(networksPerCurrency, code, []);
            currencyNetworks.push(networkInformation);
            networksPerCurrency[code] = currencyNetworks;
        }
        this.options['currenciesNetworks'] = networksPerCurrency;
        return withdrawalNetworks;
    }
    async fetchCurrencies(params = {}) {
        /**
         * @method
         * @name kraken#fetchCurrencies
         * @description fetches all available currencies on an exchange
         * @see https://docs.kraken.com/rest/#tag/Market-Data/operation/getAssetInfo
         * @see https://docs.kraken.com/rest/#tag/Funding/operation/getWithdrawalMethods
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} an associative dictionary of currencies
         */
        const assetsResponse = await this.publicGetAssets(params);
        await this.fetchWithdrawalMethods();
        // Assets
        //     {
        //         "error": [],
        //         "result": {
        //             "ADA": { "aclass": "currency", "altname": "ADA", "decimals": 8, "display_decimals": 6, "collateral_value": 1, "status": "enabled" },
        //             "BCH": { "aclass": "currency", "altname": "BCH", "decimals": 10, "display_decimals": 5, "collateral_value": 1, "status": "deposit_only" },
        //             ...
        //         },
        //     }
        const currencies = this.safeValue(assetsResponse, 'result', {});
        const ids = Object.keys(currencies);
        const result = {};
        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            const currency = currencies[id];
            // todo: will need to rethink the fees
            // see: https://support.kraken.com/hc/en-us/articles/201893608-What-are-the-withdrawal-fees-
            // to add support for multiple withdrawal/deposit methods and
            // differentiated fees for each particular method
            const code = this.safeCurrencyCode(this.safeString(currency, 'altname'));
            const precision = this.parseNumber(this.parsePrecision(this.safeString(currency, 'decimals')));
            const status = this.safeString(currency, 'status');
            const isTokenDepositable = (status === 'enabled' || status === 'deposit_only');
            const isWithdrawalEnabled = (status === 'enabled' || status === 'withdrawal_only');
            // Load the networks if they exist
            const currenciesNetworks = this.safeValue(this.options, 'currenciesNetworks', {});
            const networkInformations = this.safeValue(currenciesNetworks, code, []);
            result[code] = {
                'id': id,
                'code': code,
                'info': currency,
                'name': code,
                'active': isTokenDepositable && isWithdrawalEnabled,
                'deposit': isTokenDepositable,
                'withdraw': isWithdrawalEnabled,
                'fee': undefined,
                'precision': precision,
                'limits': {
                    'amount': {
                        'min': precision,
                        'max': undefined,
                    },
                    'withdraw': {
                        'min': undefined,
                        'max': undefined,
                    },
                },
                'networks': networkInformations,
            };
        }
        return result;
    }
    async fetchTradingFee(symbol, params = {}) {
        /**
         * @method
         * @name kraken#fetchTradingFee
         * @description fetch the trading fees for a market
         * @see https://docs.kraken.com/rest/#tag/Account-Data/operation/getTradeVolume
         * @param {string} symbol unified market symbol
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a [fee structure]{@link https://docs.ccxt.com/#/?id=fee-structure}
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        const request = {
            'pair': market['id'],
            'fee-info': true,
        };
        const response = await this.privatePostTradeVolume(this.extend(request, params));
        //
        //     {
        //        "error": [],
        //        "result": {
        //          "currency": 'ZUSD',
        //          "volume": '0.0000',
        //          "fees": {
        //            "XXBTZUSD": {
        //              "fee": '0.2600',
        //              "minfee": '0.1000',
        //              "maxfee": '0.2600',
        //              "nextfee": '0.2400',
        //              "tiervolume": '0.0000',
        //              "nextvolume": '50000.0000'
        //            }
        //          },
        //          "fees_maker": {
        //            "XXBTZUSD": {
        //              "fee": '0.1600',
        //              "minfee": '0.0000',
        //              "maxfee": '0.1600',
        //              "nextfee": '0.1400',
        //              "tiervolume": '0.0000',
        //              "nextvolume": '50000.0000'
        //            }
        //          }
        //        }
        //     }
        //
        const result = this.safeValue(response, 'result', {});
        return this.parseTradingFee(result, market);
    }
    async getWhitelistedAddresses(params) {
        /**
         * @see https://docs.kraken.com/rest/#tag/Funding/operation/getWithdrawalAddresses
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         */
        await this.loadMarkets();
        const result = await this.privatePostWithdrawAddresses(params);
        // {
        //     "error": [],
        //     "result": [
        //       {
        //         "address": "bc1qxdsh4sdd29h6ldehz0se5c61asq8cgwyjf2y3z",
        //         "asset": "XBT",
        //         "method": "Bitcoin",
        //         "key": "btc-wallet-1",
        //         "verified": true
        //       }
        //     ]
        // }
        const whitelistedAddresses = [];
        const addresses = this.safeValue(result, 'result', []);
        addresses.forEach((address) => {
            const asset = this.safeString(address, 'asset');
            const method = this.safeString(address, 'method');
            const code = this.safeCurrencyCode(asset);
            const ecid = this.methodToEcid(method, code);
            whitelistedAddresses.push({
                'address': this.safeString(address, 'address'),
                'asset': code,
                'method': method,
                'key': this.safeString(address, 'key'),
                'verified': this.safeValue(address, 'verified'),
                'network': ecid,
            });
        });
        return whitelistedAddresses;
    }
    methodToEcid(method, currencyCode) {
        /**
         * @description converts a method to an ecid
         */
        const currencyCodes = Object.keys(this.options['ecidsByMethods']);
        const currencyCodesLength = currencyCodes.length;
        if (currencyCodesLength === 0) {
            throw new MarketNotLoadedError()
                .addMessage('methodToEcid() - markets need to be loaded at first')
                .exchange(this.id);
        }
        const methods = this.safeValue(this.options['ecidsByMethods'], currencyCode, {});
        let ecid = this.safeValue(methods, method);
        if (!ecid) {
            const greedyMethod = method.replace(/\s*\((Unified|Hex)\)/g, '').trim();
            ecid = this.safeValue(methods, greedyMethod);
        }
        if (!ecid) {
            const greedyMethod = method.replace(/\s*\((Unified|Hex)\)/g, '').replace(`${currencyCode} -`, '').trim();
            ecid = this.safeValue(methods, greedyMethod);
        }
        if (!ecid) {
            // we take asset - network from withdrawal methods and try to match. If matches, we get the network field from withdrawal methods
            const withdrawalNetworks = this.options['withdrawalNetworks'];
            for (let i = 0; i < withdrawalNetworks.length; i++) {
                const withdrawalNetwork = this.safeValue(withdrawalNetworks, i);
                const asset = this.safeString(withdrawalNetwork, 'asset');
                const code = this.safeCurrencyCode(asset);
                const network = this.safeString(withdrawalNetwork, 'network');
                const greedyMethod = method.replace(/\s*\((Unified|Hex)\)/g, '').trim();
                if (`${code} - ${network}` === greedyMethod) {
                    ecid = network;
                    break;
                }
            }
        }
        if (!ecid) {
            ecid = this.safeValue(this.options['hardCodedMethodsToEcid'], method);
        }
        if (!ecid) {
            const greedyHardcodedMethod = method.replace(`${currencyCode} -`, '').trim();
            ecid = this.safeValue(this.options['hardCodedMethodsToEcid'], greedyHardcodedMethod);
        }
        return ecid;
    }
    parseTradingFee(response, market) {
        const makerFees = this.safeValue(response, 'fees_maker', {});
        const takerFees = this.safeValue(response, 'fees', {});
        const symbolMakerFee = this.safeValue(makerFees, market['id'], {});
        const symbolTakerFee = this.safeValue(takerFees, market['id'], {});
        return {
            'info': response,
            'symbol': market['symbol'],
            'maker': this.safeNumber(symbolMakerFee, 'fee'),
            'taker': this.safeNumber(symbolTakerFee, 'fee'),
            'percentage': true,
            'tierBased': true,
        };
    }
    parseBidAsk(bidask, priceKey = 0, amountKey = 1, countOrIdKey = 2) {
        const price = this.safeNumber(bidask, priceKey);
        const amount = this.safeNumber(bidask, amountKey);
        const timestamp = this.safeInteger(bidask, 2);
        return [price, amount, timestamp];
    }
    async fetchOrderBook(symbol, limit = undefined, params = {}) {
        /**
         * @method
         * @name kraken#fetchOrderBook
         * @description fetches information on open orders with bid (buy) and ask (sell) prices, volumes and other data
         * @see https://docs.kraken.com/rest/#tag/Market-Data/operation/getOrderBook
         * @param {string} symbol unified symbol of the market to fetch the order book for
         * @param {int} [limit] the maximum amount of order book entries to return
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} A dictionary of [order book structures]{@link https://docs.ccxt.com/#/?id=order-book-structure} indexed by market symbols
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        if (market['darkpool']) {
            throw new MarketTypeNotSupportedError('darkpool').addMessage(' fetchOrderBook() does not provide an order book for darkpool symbol ' + symbol).exchange(this.id);
        }
        const request = {
            'pair': market['id'],
        };
        if (limit !== undefined) {
            request['count'] = limit; // 100
        }
        const response = await this.publicGetDepth(this.extend(request, params));
        //
        //     {
        //         "error":[],
        //         "result":{
        //             "XETHXXBT":{
        //                 "asks":[
        //                     ["0.023480","4.000",1586321307],
        //                     ["0.023490","50.095",1586321306],
        //                     ["0.023500","28.535",1586321302],
        //                 ],
        //                 "bids":[
        //                     ["0.023470","59.580",1586321307],
        //                     ["0.023460","20.000",1586321301],
        //                     ["0.023440","67.832",1586321306],
        //                 ]
        //             }
        //         }
        //     }
        //
        const result = this.safeValue(response, 'result', {});
        let orderbook = this.safeValue(result, market['id']);
        // sometimes kraken returns wsname instead of market id
        // https://github.com/ccxt/ccxt/issues/8662
        const marketInfo = this.safeValue(market, 'info', {});
        const wsName = this.safeValue(marketInfo, 'wsname');
        if (wsName !== undefined) {
            orderbook = this.safeValue(result, wsName, orderbook);
        }
        return this.parseOrderBook(orderbook, symbol);
    }
    parseTicker(ticker, market = undefined) {
        //
        //     {
        //         "a":["2432.77000","1","1.000"],
        //         "b":["2431.37000","2","2.000"],
        //         "c":["2430.58000","0.04408910"],
        //         "v":["4147.94474901","8896.96086304"],
        //         "p":["2456.22239","2568.63032"],
        //         "t":[3907,10056],
        //         "l":["2302.18000","2302.18000"],
        //         "h":["2621.14000","2860.01000"],
        //         "o":"2571.56000"
        //     }
        //
        const symbol = this.safeSymbol(undefined, market);
        const v = this.safeValue(ticker, 'v', []);
        const baseVolume = this.safeString(v, 1);
        const p = this.safeValue(ticker, 'p', []);
        const vwap = this.safeString(p, 1);
        const quoteVolume = Precise.stringMul(baseVolume, vwap);
        const c = this.safeValue(ticker, 'c', []);
        const last = this.safeString(c, 0);
        const high = this.safeValue(ticker, 'h', []);
        const low = this.safeValue(ticker, 'l', []);
        const bid = this.safeValue(ticker, 'b', []);
        const ask = this.safeValue(ticker, 'a', []);
        return this.safeTicker({
            'symbol': symbol,
            'timestamp': undefined,
            'datetime': undefined,
            'high': this.safeString(high, 1),
            'low': this.safeString(low, 1),
            'bid': this.safeString(bid, 0),
            'bidVolume': undefined,
            'ask': this.safeString(ask, 0),
            'askVolume': undefined,
            'vwap': vwap,
            'open': this.safeString(ticker, 'o'),
            'close': last,
            'last': last,
            'previousClose': undefined,
            'change': undefined,
            'percentage': undefined,
            'average': undefined,
            'baseVolume': baseVolume,
            'quoteVolume': quoteVolume,
            'info': ticker,
        }, market);
    }
    async fetchTickers(symbols = undefined, params = {}) {
        /**
         * @method
         * @name kraken#fetchTickers
         * @description fetches price tickers for multiple markets, statistical information calculated over the past 24 hours for each market
         * @see https://docs.kraken.com/rest/#tag/Market-Data/operation/getTickerInformation
         * @param {string[]|undefined} symbols unified symbols of the markets to fetch the ticker for, all market tickers are returned if not assigned
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a dictionary of [ticker structures]{@link https://docs.ccxt.com/#/?id=ticker-structure}
         */
        await this.loadMarkets();
        const request = {};
        if (symbols !== undefined) {
            symbols = this.marketSymbols(symbols);
            const marketIds = [];
            for (let i = 0; i < symbols.length; i++) {
                const symbol = symbols[i];
                const market = this.markets[symbol];
                if (market['active'] && !market['darkpool']) {
                    marketIds.push(market['id']);
                }
            }
            request['pair'] = marketIds.join(',');
        }
        const response = await this.publicGetTicker(this.extend(request, params));
        const tickers = response['result'];
        const ids = Object.keys(tickers);
        const result = {};
        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            const market = this.safeMarket(id);
            const symbol = market['symbol'];
            const ticker = tickers[id];
            result[symbol] = this.parseTicker(ticker, market);
        }
        return this.filterByArrayTickers(result, 'symbol', symbols);
    }
    async fetchTicker(symbol, params = {}) {
        /**
         * @method
         * @name kraken#fetchTicker
         * @description fetches a price ticker, a statistical calculation with the information calculated over the past 24 hours for a specific market
         * @see https://docs.kraken.com/rest/#tag/Market-Data/operation/getTickerInformation
         * @param {string} symbol unified symbol of the market to fetch the ticker for
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a [ticker structure]{@link https://docs.ccxt.com/#/?id=ticker-structure}
         */
        await this.loadMarkets();
        const darkpool = symbol.indexOf('.d') >= 0;
        if (darkpool) {
            throw new TradeError()
                .addMessage('fetchTicker() does not provide a ticker for darkpool symbol ' + symbol)
                .exchange(this.id);
        }
        const market = this.market(symbol);
        const request = {
            'pair': market['id'],
        };
        const response = await this.publicGetTicker(this.extend(request, params));
        const ticker = response['result'][market['id']];
        return this.parseTicker(ticker, market);
    }
    parseOHLCV(ohlcv, market = undefined) {
        //
        //     [
        //         1591475640,
        //         "0.02500",
        //         "0.02500",
        //         "0.02500",
        //         "0.02500",
        //         "0.02500",
        //         "9.12201000",
        //         5
        //     ]
        //
        return [
            this.safeTimestamp(ohlcv, 0),
            this.safeNumber(ohlcv, 1),
            this.safeNumber(ohlcv, 2),
            this.safeNumber(ohlcv, 3),
            this.safeNumber(ohlcv, 4),
            this.safeNumber(ohlcv, 6),
        ];
    }
    async fetchOHLCV(symbol, timeframe = '1m', since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name kraken#fetchOHLCV
         * @description fetches historical candlestick data containing the open, high, low, and close price, and the volume of a market
         * @see https://docs.kraken.com/rest/#tag/Market-Data/operation/getOHLCData
         * @param {string} symbol unified symbol of the market to fetch OHLCV data for
         * @param {string} timeframe the length of time each candle represents
         * @param {int} [since] timestamp in ms of the earliest candle to fetch
         * @param {int} [limit] the maximum amount of candles to fetch
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [availble parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @returns {int[][]} A list of candles ordered as timestamp, open, high, low, close, volume
         */
        await this.loadMarkets();
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchOHLCV', 'paginate');
        if (paginate) {
            return await this.fetchPaginatedCallDeterministic('fetchOHLCV', symbol, since, limit, timeframe, params, 720);
        }
        const market = this.market(symbol);
        const parsedTimeframe = this.safeInteger(this.timeframes, timeframe);
        const request = {
            'pair': market['id'],
        };
        if (parsedTimeframe !== undefined) {
            request['interval'] = parsedTimeframe;
        }
        else {
            request['interval'] = timeframe;
        }
        if (since !== undefined) {
            // contrary to kraken's api documentation, the since parameter must be passed in nanoseconds
            // the adding of '000000' is copied from the fetchTrades function
            request['since'] = this.numberToString(since) + '000000'; // expected to be in nanoseconds
        }
        const response = await this.publicGetOHLC(this.extend(request, params));
        //
        //     {
        //         "error":[],
        //         "result":{
        //             "XETHXXBT":[
        //                 [1591475580,"0.02499","0.02499","0.02499","0.02499","0.00000","0.00000000",0],
        //                 [1591475640,"0.02500","0.02500","0.02500","0.02500","0.02500","9.12201000",5],
        //                 [1591475700,"0.02499","0.02499","0.02499","0.02499","0.02499","1.28681415",2],
        //                 [1591475760,"0.02499","0.02499","0.02499","0.02499","0.02499","0.08800000",1],
        //             ],
        //             "last":1591517580
        //         }
        //     }
        const result = this.safeValue(response, 'result', {});
        const ohlcvs = this.safeValue(result, market['id'], []);
        return this.parseOHLCVs(ohlcvs, market, timeframe, since, limit);
    }
    parseLedgerEntryType(type) {
        const types = {
            'trade': 'trade',
            'withdrawal': 'transaction',
            'deposit': 'transaction',
            'transfer': 'transfer',
            'margin': 'margin',
        };
        return this.safeString(types, type, type);
    }
    parseLedgerEntry(item, currency = undefined) {
        //
        //     {
        //         'LTFK7F-N2CUX-PNY4SX': {
        //             "refid": "TSJTGT-DT7WN-GPPQMJ",
        //             "time":  1520102320.555,
        //             "type": "trade",
        //             "aclass": "currency",
        //             "asset": "XETH",
        //             "amount": "0.1087194600",
        //             "fee": "0.0000000000",
        //             "balance": "0.2855851000"
        //         },
        //         ...
        //     }
        //
        const id = this.safeString(item, 'id');
        let direction = undefined;
        const account = undefined;
        const referenceId = this.safeString(item, 'refid');
        const referenceAccount = undefined;
        const type = this.parseLedgerEntryType(this.safeString(item, 'type'));
        const code = this.safeCurrencyCode(this.safeString(item, 'asset'), currency);
        let amount = this.safeString(item, 'amount');
        if (Precise.stringLt(amount, '0')) {
            direction = 'out';
            amount = Precise.stringAbs(amount);
        }
        else {
            direction = 'in';
        }
        const timestamp = this.safeTimestamp(item, 'time');
        return {
            'info': item,
            'id': id,
            'direction': direction,
            'account': account,
            'referenceId': referenceId,
            'referenceAccount': referenceAccount,
            'type': type,
            'currency': code,
            'amount': this.parseNumber(amount),
            'before': undefined,
            'after': this.safeNumber(item, 'balance'),
            'status': 'ok',
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'fee': {
                'cost': this.safeNumber(item, 'fee'),
                'currency': code,
            },
        };
    }
    async fetchLedger(code = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name kraken#fetchLedger
         * @description fetch the history of changes, actions done by the user or operations that altered balance of the user
         * @see https://docs.kraken.com/rest/#tag/Account-Data/operation/getLedgers
         * @param {string} code unified currency code, default is undefined
         * @param {int} [since] timestamp in ms of the earliest ledger entry, default is undefined
         * @param {int} [limit] max number of ledger entrys to return, default is undefined
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {int} [params.until] timestamp in ms of the latest ledger entry
         * @returns {object} a [ledger structure]{@link https://docs.ccxt.com/#/?id=ledger-structure}
         */
        // https://www.kraken.com/features/api#get-ledgers-info
        await this.loadMarkets();
        let request = {};
        let currency = undefined;
        if (code !== undefined) {
            currency = this.currency(code);
            request['asset'] = currency['id'];
        }
        if (since !== undefined) {
            request['start'] = this.parseToInt(since / 1000);
        }
        [request, params] = this.handleUntilOption('end', request, params);
        const response = await this.privatePostLedgers(this.extend(request, params));
        // {  error: [],
        //   "result": { ledger: { 'LPUAIB-TS774-UKHP7X': {   refid: "A2B4HBV-L4MDIE-JU4N3N",
        //                                                   "time":  1520103488.314,
        //                                                   "type": "withdrawal",
        //                                                 "aclass": "currency",
        //                                                  "asset": "XETH",
        //                                                 "amount": "-0.2805800000",
        //                                                    "fee": "0.0050000000",
        //                                                "balance": "0.0000051000"           },
        const result = this.safeValue(response, 'result', {});
        const ledger = this.safeValue(result, 'ledger', {});
        const keys = Object.keys(ledger);
        const items = [];
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = ledger[key];
            value['id'] = key;
            items.push(value);
        }
        return this.parseLedger(items, currency, since, limit);
    }
    async fetchLedgerEntriesByIds(ids, code = undefined, params = {}) {
        // https://www.kraken.com/features/api#query-ledgers
        await this.loadMarkets();
        ids = ids.join(',');
        const request = this.extend({
            'id': ids,
        }, params);
        const response = await this.privatePostQueryLedgers(request);
        // {  error: [],
        //   "result": { 'LPUAIB-TS774-UKHP7X': {   refid: "A2B4HBV-L4MDIE-JU4N3N",
        //                                         "time":  1520103488.314,
        //                                         "type": "withdrawal",
        //                                       "aclass": "currency",
        //                                        "asset": "XETH",
        //                                       "amount": "-0.2805800000",
        //                                          "fee": "0.0050000000",
        //                                      "balance": "0.0000051000"           } } }
        const result = response['result'];
        const keys = Object.keys(result);
        const items = [];
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = result[key];
            value['id'] = key;
            items.push(value);
        }
        return this.parseLedger(items);
    }
    async fetchLedgerEntry(id, code = undefined, params = {}) {
        const items = await this.fetchLedgerEntriesByIds([id], code, params);
        return items[0];
    }
    parseTrade(trade, market = undefined) {
        //
        // fetchTrades (public)
        //
        //     [
        //         "0.032310", // price
        //         "4.28169434", // amount
        //         1541390792.763, // timestamp
        //         "s", // sell or buy
        //         "l", // limit or market
        //         ""
        //     ]
        //
        // fetchOrderTrades (private)
        //
        //     {
        //         "id": 'TIMIRG-WUNNE-RRJ6GT', // injected from outside
        //         "ordertxid": 'OQRPN2-LRHFY-HIFA7D',
        //         "postxid": 'TKH2SE-M7IF5-CFI7LT',
        //         "pair": 'USDCUSDT',
        //         "time": 1586340086.457,
        //         "type": 'sell',
        //         "ordertype": 'market',
        //         "price": '0.99860000',
        //         "cost": '22.16892001',
        //         "fee": '0.04433784',
        //         "vol": '22.20000000',
        //         "margin": '0.00000000',
        //         "misc": ''
        //     }
        //
        let timestamp = undefined;
        let side = undefined;
        let type = undefined;
        let price = undefined;
        let amount = undefined;
        let id = undefined;
        let orderId = undefined;
        let fee = undefined;
        let symbol = undefined;
        if (Array.isArray(trade)) {
            timestamp = this.safeTimestamp(trade, 2);
            side = (trade[3] === 's') ? 'sell' : 'buy';
            type = (trade[4] === 'l') ? 'limit' : 'market';
            price = this.safeString(trade, 0);
            amount = this.safeString(trade, 1);
            const tradeLength = trade.length;
            if (tradeLength > 6) {
                id = this.safeString(trade, 6); // artificially added as per #1794
            }
        }
        else if (typeof trade === 'string') {
            id = trade;
        }
        else if ('ordertxid' in trade) {
            const marketId = this.safeString(trade, 'pair');
            const foundMarket = this.findMarketByAltnameOrId(marketId);
            if (foundMarket !== undefined) {
                market = foundMarket;
            }
            else if (marketId !== undefined) {
                // delisted market ids go here
                market = this.getDelistedMarketById(marketId);
            }
            orderId = this.safeString(trade, 'ordertxid');
            id = this.safeString2(trade, 'id', 'postxid');
            timestamp = this.safeTimestamp(trade, 'time');
            side = this.safeString(trade, 'type');
            type = this.safeString(trade, 'ordertype');
            price = this.safeString(trade, 'price');
            amount = this.safeString(trade, 'vol');
            if ('fee' in trade) {
                let currency = undefined;
                if (market !== undefined) {
                    currency = market['quote'];
                }
                fee = {
                    'cost': this.safeString(trade, 'fee'),
                    'currency': currency,
                };
            }
        }
        if (market !== undefined) {
            symbol = market['symbol'];
        }
        const cost = this.safeString(trade, 'cost');
        return this.safeTrade({
            'id': id,
            'order': orderId,
            'info': trade,
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'symbol': symbol,
            'type': type,
            'side': side,
            'takerOrMaker': undefined,
            'price': price,
            'amount': amount,
            'cost': cost,
            'fee': fee,
        }, market);
    }
    async fetchTrades(symbol, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name kraken#fetchTrades
         * @description get the list of most recent trades for a particular symbol
         * @see https://docs.kraken.com/rest/#tag/Market-Data/operation/getRecentTrades
         * @param {string} symbol unified symbol of the market to fetch trades for
         * @param {int} [since] timestamp in ms of the earliest trade to fetch
         * @param {int} [limit] the maximum amount of trades to fetch
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {Trade[]} a list of [trade structures]{@link https://docs.ccxt.com/#/?id=public-trades}
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        const id = market['id'];
        const request = {
            'pair': id,
        };
        // https://support.kraken.com/hc/en-us/articles/218198197-How-to-pull-all-trade-data-using-the-Kraken-REST-API
        // https://github.com/ccxt/ccxt/issues/5677
        if (since !== undefined) {
            // php does not format it properly
            // therefore we use string concatenation here
            request['since'] = since * 1e6;
            request['since'] = since.toString() + '000000'; // expected to be in nanoseconds
        }
        if (limit !== undefined) {
            request['count'] = limit;
        }
        const response = await this.publicGetTrades(this.extend(request, params));
        //
        //     {
        //         "error": [],
        //         "result": {
        //             "XETHXXBT": [
        //                 ["0.032310","4.28169434",1541390792.763,"s","l",""]
        //             ],
        //             "last": "1541439421200678657"
        //         }
        //     }
        //
        const result = response['result'];
        const trades = result[id];
        // trades is a sorted array: last (most recent trade) goes last
        const length = trades.length;
        if (length <= 0) {
            return [];
        }
        const lastTrade = trades[length - 1];
        const lastTradeId = this.safeString(result, 'last');
        lastTrade.push(lastTradeId);
        return this.parseTrades(trades, market, since, limit);
    }
    parseBalanceCustom(response, type) {
        const result = {
            'info': response,
            'timestamp': undefined,
            'datetime': undefined,
        };
        const balances = this.safeValue(response, 'result', {});
        if (type === 'earn') {
            const items = this.safeValue(balances, 'items', []);
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                const currencyCode = this.safeValue(item, 'native_asset');
                const amountAllocated = this.safeValue(item, 'amount_allocated');
                const totalRewarded = this.safeValue(item, 'total_rewarded');
                const totalAmountAllocated = this.safeValue(amountAllocated, 'total');
                const totalAllocatedAmount = this.safeNumber(totalAmountAllocated, 'native');
                const totalRewardedAmount = this.safeNumber(totalRewarded, 'native');
                const totalAmount = totalAllocatedAmount + totalRewardedAmount;
                const code = this.safeCurrencyCode(currencyCode);
                result[code] = {
                    'free': 0,
                    'used': totalAmount,
                    'total': totalAmount,
                };
            }
        }
        else {
            const currencyIds = Object.keys(balances);
            for (let i = 0; i < currencyIds.length; i++) {
                const currencyId = currencyIds[i];
                const code = this.safeCurrencyCode(currencyId);
                const balance = balances[currencyId];
                const totalBalance = this.safeNumber(balance, 'balance', 0);
                const credit = this.safeNumber(balance, 'credit', 0);
                const creditUsed = this.safeNumber(balance, 'credit_used', 0);
                const holdTrade = this.safeNumber(balance, 'hold_trade', 0);
                const debt = this.safeNumber(balance, 'debt', 0);
                const free = totalBalance + credit - creditUsed - holdTrade;
                const used = holdTrade + creditUsed;
                const total = free + used;
                result[code] = {
                    'free': free,
                    'used': used,
                    'total': total,
                    'debt': debt,
                };
            }
        }
        return this.safeBalance(result);
    }
    async fetchBalance(params = {}) {
        /**
         * @method
         * @name kraken#fetchBalance
         * @description query for balance and get the amount of funds available for trading or funds locked in orders
         * @see https://docs.kraken.com/rest/#tag/Account-Data/operation/getExtendedBalance
         * @see https://docs.kraken.com/rest/#tag/Earn/operation/listAllocations
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.type] 'earn', or 'spot'
         * @returns {object} a [balance structure]{@link https://docs.ccxt.com/#/?id=balance-structure}
         */
        await this.loadMarkets();
        const type = this.safeString(params, 'type', 'spot');
        let response;
        if (type === 'earn') {
            // {
            //     error: [],
            //     result: {
            //       converted_asset: "USD",
            //       total_allocated: "49.2398",
            //       total_rewarded: "0.0675",
            //       next_cursor: "2",
            //       items: [
            //         {
            //           strategy_id: "ESDQCOL-WTZEU-NU55QF",
            //           native_asset: "ETH",
            //           amount_allocated: {},
            //           total_rewarded: {},
            //         },
            //       ],
            //     },
            // };
            response = await this.privatePostEarnAllocations(params);
        }
        else {
            //
            //     {
            //         "error": [],
            //         "result": {
            //             "ZUSD": {
            //                 "balance": 25435.21,
            //                 "hold_trade": 8249.76
            //             },
            //             "XXBT": {
            //                 "balance": 1.2435,
            //                 "hold_trade": 0.8423
            //             }
            //         }
            //     }
            //
            response = await this.privatePostBalanceEx(params);
        }
        return this.parseBalanceCustom(response, type);
    }
    async createOrder(symbol, type, side, amount, price = undefined, params = {}) {
        /**
         * @method
         * @name kraken#createOrder
         * @see https://docs.kraken.com/rest/#tag/Trading/operation/addOrder
         * @description create a trade order
         * @param {string} symbol unified symbol of the market to create an order in
         * @param {string} type 'market' or 'limit'
         * @param {string} side 'buy' or 'sell'
         * @param {float} amount how much of currency you want to trade in units of base currency
         * @param {float} [price] the price at which the order is to be fullfilled, in units of the quote currency, ignored in market orders
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {bool} [params.postOnly] if true, the order will only be posted to the order book and not executed immediately
         * @param {bool} [params.reduceOnly] *margin only* indicates if this order is to reduce the size of a position
         * @param {float} [params.stopLossPrice] *margin only* the price that a stop loss order is triggered at
         * @param {float} [params.takeProfitPrice] *margin only* the price that a take profit order is triggered at
         * @param {string} [params.trailingAmount] *margin only* the quote amount to trail away from the current market price
         * @param {string} [params.trailingLimitAmount] *margin only* the quote amount away from the trailingAmount
         * @param {string} [params.offset] *margin only* '+' or '-' whether you want the trailingLimitAmount value to be positive or negative, default is negative '-'
         * @param {string} [params.trigger] *margin only* the activation price type, 'last' or 'index', default is 'last'
         * @returns {object} an [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        const request = {
            'pair': market['id'],
            'type': side,
            'ordertype': type,
            'volume': this.amountToPrecision(symbol, amount),
        };
        const orderRequest = this.orderRequest('createOrder()', symbol, type, request, price, params);
        const response = await this.privatePostAddOrder(this.extend(orderRequest[0], orderRequest[1]));
        //
        //     {
        //         "error": [],
        //         "result": {
        //             "descr": { order: 'buy 0.02100000 ETHUSDT @ limit 330.00' },
        //             "txid": [ 'OEKVV2-IH52O-TPL6GZ' ]
        //         }
        //     }
        //
        const result = this.safeValue(response, 'result');
        return this.parseOrder(result);
    }
    findMarketByAltnameOrId(id) {
        const marketsByAltname = this.safeValue(this.options, 'marketsByAltname', {});
        if (id in marketsByAltname) {
            return marketsByAltname[id];
        }
        else {
            return this.safeMarket(id);
        }
    }
    getDelistedMarketById(id) {
        if (id === undefined) {
            return id;
        }
        let market = this.safeValue(this.options['delistedMarketsById'], id);
        if (market !== undefined) {
            return market;
        }
        const baseIdStart = 0;
        let baseIdEnd = 3;
        let quoteIdStart = 3;
        let quoteIdEnd = 6;
        if (id.length === 8) {
            baseIdEnd = 4;
            quoteIdStart = 4;
            quoteIdEnd = 8;
        }
        else if (id.length === 7) {
            baseIdEnd = 4;
            quoteIdStart = 4;
            quoteIdEnd = 7;
        }
        const baseId = id.slice(baseIdStart, baseIdEnd);
        const quoteId = id.slice(quoteIdStart, quoteIdEnd);
        const base = this.safeCurrencyCode(baseId);
        const quote = this.safeCurrencyCode(quoteId);
        const symbol = base + '/' + quote;
        market = {
            'symbol': symbol,
            'base': base,
            'quote': quote,
            'baseId': baseId,
            'quoteId': quoteId,
        };
        this.options['delistedMarketsById'][id] = market;
        return market;
    }
    parseOrderStatus(status) {
        const statuses = {
            'pending': 'open',
            'open': 'open',
            'closed': 'closed',
            'canceled': 'canceled',
            'expired': 'expired',
        };
        return this.safeString(statuses, status, status);
    }
    parseOrderType(status) {
        const statuses = {
            'take-profit': 'market',
            'stop-loss-limit': 'limit',
            'stop-loss': 'market',
            'take-profit-limit': 'limit',
            'trailing-stop-limit': 'limit',
        };
        return this.safeString(statuses, status, status);
    }
    parseOrder(order, market = undefined) {
        //
        // createOrder for regular orders
        //
        //     {
        //         "descr": { order: 'buy 0.02100000 ETHUSDT @ limit 330.00' },
        //         "txid": [ 'OEKVV2-IH52O-TPL6GZ' ]
        //     }
        //     {
        //         "txid": [ "TX_ID_HERE" ],
        //         "descr": { "order":"buy 0.12345678 ETHEUR @ market" },
        //     }
        //
        //
        // createOrder for stop orders
        //
        //     {
        //         "txid":["OSILNC-VQI5Q-775ZDQ"],
        //         "descr":{"order":"sell 167.28002676 ADAXBT @ stop loss 0.00003280 -> limit 0.00003212"}
        //     }
        //
        //
        //     {
        //         "txid":["OVHMJV-BZW2V-6NZFWF"],
        //         "descr":{"order":"sell 0.00100000 ETHUSD @ stop loss 2677.00 -> limit 2577.00 with 5:1 leverage"}
        //     }
        //
        // editOrder
        //
        //     {
        //         "status": "ok",
        //         "txid": "OAW2BO-7RWEK-PZY5UO",
        //         "originaltxid": "OXL6SS-UPNMC-26WBE7",
        //         "volume": "0.00075000",
        //         "price": "13500.0",
        //         "orders_cancelled": 1,
        //         "descr": {
        //             "order": "buy 0.00075000 XBTUSDT @ limit 13500.0"
        //         }
        //     }
        //  ws - createOrder
        //    {
        //        "descr": 'sell 0.00010000 XBTUSDT @ market',
        //        "event": 'addOrderStatus',
        //        "reqid": 1,
        //        "status": 'ok',
        //        "txid": 'OAVXZH-XIE54-JCYYDG'
        //    }
        //  ws - editOrder
        //    {
        //        "descr": "order edited price = 9000.00000000",
        //        "event": "editOrderStatus",
        //        "originaltxid": "O65KZW-J4AW3-VFS74A",
        //        "reqid": 3,
        //        "status": "ok",
        //        "txid": "OTI672-HJFAO-XOIPPK"
        //    }
        //
        //  {
        //      "error": [],
        //      "result": {
        //          "open": {
        //              "OXVPSU-Q726F-L3SDEP": {
        //                  "refid": null,
        //                  "userref": 0,
        //                  "status": "open",
        //                  "opentm": 1706893367.4656649,
        //                  "starttm": 0,
        //                  "expiretm": 0,
        //                  "descr": {
        //                      "pair": "XRPEUR",
        //                      "type": "sell",
        //                      "ordertype": "trailing-stop",
        //                      "price": "+50.0000%",
        //                      "price2": "0",
        //                      "leverage": "none",
        //                      "order": "sell 10.00000000 XRPEUR @ trailing stop +50.0000%",
        //                      "close": ""
        //                  },
        //                  "vol": "10.00000000",
        //                  "vol_exec": "0.00000000",
        //                  "cost": "0.00000000",
        //                  "fee": "0.00000000",
        //                  "price": "0.00000000",
        //                  "stopprice": "0.23424000",
        //                  "limitprice": "0.46847000",
        //                  "misc": "",
        //                  "oflags": "fciq",
        //                  "trigger": "index"
        //              }
        //      }
        //  }
        //
        const description = this.safeValue(order, 'descr', {});
        const orderDescription = this.safeString(description, 'order', description);
        let side = undefined;
        let type = undefined;
        let marketId = undefined;
        let price = undefined;
        let amount = undefined;
        let stopPrice = undefined;
        if (orderDescription !== undefined) {
            const parts = orderDescription.split(' ');
            side = this.safeString(parts, 0);
            amount = this.safeString(parts, 1);
            marketId = this.safeString(parts, 2);
            type = this.safeString(parts, 4);
            if (type === 'stop') {
                stopPrice = this.safeString(parts, 6);
                price = this.safeString(parts, 9);
            }
            else if (type === 'limit') {
                price = this.safeString(parts, 5);
            }
        }
        side = this.safeString(description, 'type', side);
        type = this.safeString(description, 'ordertype', type);
        marketId = this.safeString(description, 'pair', marketId);
        const foundMarket = this.findMarketByAltnameOrId(marketId);
        let symbol = undefined;
        if (foundMarket !== undefined) {
            market = foundMarket;
        }
        else if (marketId !== undefined) {
            // delisted market ids go here
            market = this.getDelistedMarketById(marketId);
        }
        const timestamp = this.safeTimestamp(order, 'opentm');
        amount = this.safeString(order, 'vol', amount);
        const filled = this.safeString(order, 'vol_exec');
        let fee = undefined;
        // kraken truncates the cost in the api response so we will ignore it and calculate it from average & filled
        // const cost = this.safeString (order, 'cost');
        price = this.safeString(description, 'price', price);
        // when type = trailling stop returns price = '+50.0000%'
        if ((price !== undefined) && price.endsWith('%')) {
            price = undefined; // this is not the price we want
        }
        if ((price === undefined) || Precise.stringEquals(price, '0')) {
            price = this.safeString(description, 'price2');
        }
        if ((price === undefined) || Precise.stringEquals(price, '0')) {
            price = this.safeString(order, 'price', price);
        }
        const flags = this.safeString(order, 'oflags', '');
        const isPostOnly = flags.indexOf('post') > -1;
        const average = this.safeNumber(order, 'price');
        if (market !== undefined) {
            symbol = market['symbol'];
            if ('fee' in order) {
                const feeCost = this.safeString(order, 'fee');
                fee = {
                    'cost': feeCost,
                    'rate': undefined,
                };
                if (flags.indexOf('fciq') >= 0) {
                    fee['currency'] = market['quote'];
                }
                else if (flags.indexOf('fcib') >= 0) {
                    fee['currency'] = market['base'];
                }
            }
        }
        const status = this.parseOrderStatus(this.safeString(order, 'status'));
        let id = this.safeString2(order, 'id', 'txid');
        if ((id === undefined) || (id.slice(0, 1) === '[')) {
            const txid = this.safeValue(order, 'txid');
            id = this.safeString(txid, 0);
        }
        const clientOrderId = this.safeString(order, 'userref');
        const rawTrades = this.safeValue(order, 'trades', []);
        const trades = [];
        for (let i = 0; i < rawTrades.length; i++) {
            const rawTrade = rawTrades[i];
            if (typeof rawTrade === 'string') {
                trades.push(this.safeTrade({ 'id': rawTrade, 'orderId': id, 'symbol': symbol, 'info': {} }));
            }
            else {
                trades.push(rawTrade);
            }
        }
        stopPrice = this.omitZero(this.safeString(order, 'stopprice', stopPrice));
        let stopLossPrice = undefined;
        let takeProfitPrice = undefined;
        if (type.startsWith('take-profit')) {
            takeProfitPrice = this.safeString(description, 'price');
            price = this.omitZero(this.safeString(description, 'price2'));
        }
        else if (type.startsWith('stop-loss')) {
            stopLossPrice = this.safeString(description, 'price');
            price = this.omitZero(this.safeString(description, 'price2'));
        }
        return this.safeOrder({
            'id': id,
            'clientOrderId': clientOrderId,
            'info': order,
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'lastTradeTimestamp': undefined,
            'status': status,
            'symbol': symbol,
            'type': this.parseOrderType(type),
            'timeInForce': undefined,
            'postOnly': isPostOnly,
            'side': side,
            'price': price,
            'stopPrice': stopPrice,
            'triggerPrice': stopPrice,
            'takeProfitPrice': takeProfitPrice,
            'stopLossPrice': stopLossPrice,
            'cost': undefined,
            'amount': amount,
            'filled': filled,
            'average': average,
            'remaining': undefined,
            'fee': fee,
            'trades': trades,
        }, market);
    }
    orderRequest(method, symbol, type, request, price = undefined, params = {}) {
        const clientOrderId = this.safeString2(params, 'userref', 'clientOrderId');
        params = this.omit(params, ['userref', 'clientOrderId']);
        if (clientOrderId !== undefined) {
            request['userref'] = clientOrderId;
        }
        const stopLossTriggerPrice = this.safeString(params, 'stopLossPrice');
        const takeProfitTriggerPrice = this.safeString(params, 'takeProfitPrice');
        const isStopLossTriggerOrder = stopLossTriggerPrice !== undefined;
        const isTakeProfitTriggerOrder = takeProfitTriggerPrice !== undefined;
        const isStopLossOrTakeProfitTrigger = isStopLossTriggerOrder || isTakeProfitTriggerOrder;
        const trailingAmount = this.safeString(params, 'trailingAmount');
        const trailingLimitAmount = this.safeString(params, 'trailingLimitAmount');
        const isTrailingAmountOrder = trailingAmount !== undefined;
        const isLimitOrder = type.endsWith('limit'); // supporting limit, stop-loss-limit, take-profit-limit, etc
        if (isLimitOrder && !isTrailingAmountOrder) {
            request['price'] = this.priceToPrecision(symbol, price);
        }
        const reduceOnly = this.safeValue2(params, 'reduceOnly', 'reduce_only');
        if (isStopLossOrTakeProfitTrigger) {
            if (isStopLossTriggerOrder) {
                request['price'] = this.priceToPrecision(symbol, stopLossTriggerPrice);
                if (isLimitOrder) {
                    request['ordertype'] = 'stop-loss-limit';
                }
                else {
                    request['ordertype'] = 'stop-loss';
                }
            }
            else if (isTakeProfitTriggerOrder) {
                request['price'] = this.priceToPrecision(symbol, takeProfitTriggerPrice);
                if (isLimitOrder) {
                    request['ordertype'] = 'take-profit-limit';
                }
                else {
                    request['ordertype'] = 'take-profit';
                }
            }
            if (isLimitOrder) {
                request['price2'] = this.priceToPrecision(symbol, price);
            }
        }
        else if (isTrailingAmountOrder) {
            const trailingActivationPriceType = this.safeString(params, 'trigger', 'last');
            const trailingAmountString = '+' + trailingAmount;
            request['trigger'] = trailingActivationPriceType;
            if (isLimitOrder || (trailingLimitAmount !== undefined)) {
                const offset = this.safeString(params, 'offset', '-');
                const trailingLimitAmountString = offset + this.numberToString(trailingLimitAmount);
                request['price'] = trailingAmountString;
                request['price2'] = trailingLimitAmountString;
                request['ordertype'] = 'trailing-stop-limit';
            }
            else {
                request['price'] = trailingAmountString;
                request['ordertype'] = 'trailing-stop';
            }
        }
        if (reduceOnly) {
            request['reduce_only'] = 'true'; // not using boolean in this case, because the urlencodedNested transforms it into 'True' string
        }
        let close = this.safeValue(params, 'close');
        if (close !== undefined) {
            close = this.extend({}, close);
            const closePrice = this.safeValue(close, 'price');
            if (closePrice !== undefined) {
                close['price'] = this.priceToPrecision(symbol, closePrice);
            }
            const closePrice2 = this.safeValue(close, 'price2'); // stopPrice
            if (closePrice2 !== undefined) {
                close['price2'] = this.priceToPrecision(symbol, closePrice2);
            }
            request['close'] = close;
        }
        const timeInForce = this.safeString2(params, 'timeInForce', 'timeinforce');
        if (timeInForce !== undefined) {
            request['timeinforce'] = timeInForce;
        }
        const isMarket = (type === 'market');
        let postOnly = undefined;
        [postOnly, params] = this.handlePostOnly(isMarket, false, params);
        if (postOnly) {
            request['oflags'] = 'post';
        }
        params = this.omit(params, ['timeInForce', 'reduceOnly', 'stopLossPrice', 'takeProfitPrice', 'trailingAmount', 'trailingLimitAmount', 'offset']);
        return [request, params];
    }
    async editOrder(id, symbol, type, side, amount = undefined, price = undefined, params = {}) {
        /**
         * @method
         * @name kraken#editOrder
         * @description edit a trade order
         * @see https://docs.kraken.com/rest/#tag/Trading/operation/editOrder
         * @param {string} id order id
         * @param {string} symbol unified symbol of the market to create an order in
         * @param {string} type 'market' or 'limit'
         * @param {string} side 'buy' or 'sell'
         * @param {float} amount how much of the currency you want to trade in units of the base currency
         * @param {float} [price] the price at which the order is to be fullfilled, in units of the quote currency, ignored in market orders
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {float} [params.stopLossPrice] *margin only* the price that a stop loss order is triggered at
         * @param {float} [params.takeProfitPrice] *margin only* the price that a take profit order is triggered at
         * @param {string} [params.trailingAmount] *margin only* the quote price away from the current market price
         * @param {string} [params.trailingLimitAmount] *margin only* the quote amount away from the trailingAmount
         * @param {string} [params.offset] *margin only* '+' or '-' whether you want the trailingLimitAmount value to be positive or negative, default is negative '-'
         * @param {string} [params.trigger] *margin only* the activation price type, 'last' or 'index', default is 'last'
         * @returns {object} an [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        if (!market['spot']) {
            throw new MarketTypeNotSupportedError(market['type'])
                .addMessage('Only spot orders are accepted')
                .exchange(this.id);
        }
        const request = {
            'txid': id,
            'pair': market['id'],
        };
        if (amount !== undefined) {
            request['volume'] = this.amountToPrecision(symbol, amount);
        }
        const orderRequest = this.orderRequest('editOrder()', symbol, type, request, price, params);
        const response = await this.privatePostEditOrder(this.extend(orderRequest[0], orderRequest[1]));
        //
        //     {
        //         "error": [],
        //         "result": {
        //             "status": "ok",
        //             "txid": "OAW2BO-7RWEK-PZY5UO",
        //             "originaltxid": "OXL6SS-UPNMC-26WBE7",
        //             "volume": "0.00075000",
        //             "price": "13500.0",
        //             "orders_cancelled": 1,
        //             "descr": {
        //                 "order": "buy 0.00075000 XBTUSDT @ limit 13500.0"
        //             }
        //         }
        //     }
        //
        const data = this.safeValue(response, 'result', {});
        return this.parseOrder(data, market);
    }
    async fetchOrder(id, symbol = undefined, params = {}) {
        /**
         * @method
         * @name kraken#fetchOrder
         * @description fetches information on an order made by the user
         * @see https://docs.kraken.com/rest/#tag/Account-Data/operation/getOrdersInfo
         * @param {string} symbol not used by kraken fetchOrder
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} An [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const clientOrderId = this.safeValue2(params, 'userref', 'clientOrderId');
        const request = {
            'trades': true, // whether or not to include trades in output (optional, default false)
            // 'txid': id, // do not comma separate a list of ids - use fetchOrdersByIds instead
            // 'userref': 'optional', // restrict results to given user reference id (optional)
        };
        let query = params;
        if (clientOrderId !== undefined) {
            request['userref'] = clientOrderId;
            query = this.omit(params, ['userref', 'clientOrderId']);
        }
        else {
            request['txid'] = id;
        }
        const response = await this.privatePostQueryOrders(this.extend(request, query));
        //
        //     {
        //         "error":[],
        //         "result":{
        //             "OTLAS3-RRHUF-NDWH5A":{
        //                 "refid":null,
        //                 "userref":null,
        //                 "status":"closed",
        //                 "reason":null,
        //                 "opentm":1586822919.3342,
        //                 "closetm":1586822919.365,
        //                 "starttm":0,
        //                 "expiretm":0,
        //                 "descr":{
        //                     "pair":"XBTUSDT",
        //                     "type":"sell",
        //                     "ordertype":"market",
        //                     "price":"0",
        //                     "price2":"0",
        //                     "leverage":"none",
        //                     "order":"sell 0.21804000 XBTUSDT @ market",
        //                     "close":""
        //                 },
        //                 "vol":"0.21804000",
        //                 "vol_exec":"0.21804000",
        //                 "cost":"1493.9",
        //                 "fee":"3.8",
        //                 "price":"6851.5",
        //                 "stopprice":"0.00000",
        //                 "limitprice":"0.00000",
        //                 "misc":"",
        //                 "oflags":"fciq",
        //                 "trades":["TT5UC3-GOIRW-6AZZ6R"]
        //             }
        //         }
        //     }
        //
        const result = this.safeValue(response, 'result', []);
        if (!(id in result)) {
            throw new OrderNotFoundError()
                .addMessage('fetchOrder() could not find order id ' + id)
                .exchange(this.id);
        }
        return this.parseOrder(this.extend({ 'id': id }, result[id]));
    }
    async fetchOrderTrades(id, symbol = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name kraken#fetchOrderTrades
         * @description fetch all the trades made from a single order
         * @see https://docs.kraken.com/rest/#tag/Account-Data/operation/getTradesInfo
         * @param {string} id order id
         * @param {string} symbol unified market symbol
         * @param {int} [since] the earliest time in ms to fetch trades for
         * @param {int} [limit] the maximum number of trades to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object[]} a list of [trade structures]{@link https://docs.ccxt.com/#/?id=trade-structure}
         */
        const orderTrades = this.safeValue(params, 'trades');
        const tradeIds = [];
        if (orderTrades === undefined) {
            throw new InvalidParamsError("fetchOrderTrades() requires a unified order structure in the params argument or a 'trades' param (an array of trade id strings)")
                .exchange(this.id);
        }
        else {
            for (let i = 0; i < orderTrades.length; i++) {
                const orderTrade = orderTrades[i];
                if (typeof orderTrade === 'string') {
                    tradeIds.push(orderTrade);
                }
                else {
                    tradeIds.push(orderTrade['id']);
                }
            }
        }
        await this.loadMarkets();
        if (symbol !== undefined) {
            symbol = this.symbol(symbol);
        }
        const options = this.safeValue(this.options, 'fetchOrderTrades', {});
        const batchSize = this.safeInteger(options, 'batchSize', 20);
        const numTradeIds = tradeIds.length;
        let numBatches = this.parseToInt(numTradeIds / batchSize);
        numBatches = this.sum(numBatches, 1);
        let result = [];
        for (let j = 0; j < numBatches; j++) {
            const requestIds = [];
            for (let k = 0; k < batchSize; k++) {
                const index = this.sum(j * batchSize, k);
                if (index < numTradeIds) {
                    requestIds.push(tradeIds[index]);
                }
            }
            const request = {
                'txid': requestIds.join(','),
            };
            const response = await this.privatePostQueryTrades(request);
            //
            //     {
            //         "error": [],
            //         "result": {
            //             'TIMIRG-WUNNE-RRJ6GT': {
            //                 "ordertxid": 'OQRPN2-LRHFY-HIFA7D',
            //                 "postxid": 'TKH2SE-M7IF5-CFI7LT',
            //                 "pair": 'USDCUSDT',
            //                 "time": 1586340086.457,
            //                 "type": 'sell',
            //                 "ordertype": 'market',
            //                 "price": '0.99860000',
            //                 "cost": '22.16892001',
            //                 "fee": '0.04433784',
            //                 "vol": '22.20000000',
            //                 "margin": '0.00000000',
            //                 "misc": ''
            //             }
            //         }
            //     }
            //
            const rawTrades = this.safeValue(response, 'result');
            const ids = Object.keys(rawTrades);
            for (let i = 0; i < ids.length; i++) {
                rawTrades[ids[i]]['id'] = ids[i];
            }
            const trades = this.parseTrades(rawTrades, undefined, since, limit);
            const tradesFilteredBySymbol = this.filterBySymbol(trades, symbol);
            result = this.arrayConcat(result, tradesFilteredBySymbol);
        }
        return result;
    }
    async fetchOrdersByIds(ids, symbol = undefined, params = {}) {
        /**
         * @method
         * @name kraken#fetchOrdersByIds
         * @description fetch orders by the list of order id
         * @see https://docs.kraken.com/rest/#tag/Account-Data/operation/getClosedOrders
         * @param {string[]|undefined} ids list of order id
         * @param {object} [params] extra parameters specific to the kraken api endpoint
         * @returns {object[]} a list of [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const response = await this.privatePostQueryOrders(this.extend({
            'trades': true,
            'txid': ids.join(','), // comma delimited list of transaction ids to query info about (20 maximum)
        }, params));
        const result = this.safeValue(response, 'result', {});
        const orders = [];
        const orderIds = Object.keys(result);
        for (let i = 0; i < orderIds.length; i++) {
            const id = orderIds[i];
            const item = result[id];
            const order = this.parseOrder(this.extend({ 'id': id }, item));
            orders.push(order);
        }
        return orders;
    }
    async fetchMyTrades(symbol = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name kraken#fetchMyTrades
         * @description fetch all trades made by the user
         * @see https://docs.kraken.com/rest/#tag/Account-Data/operation/getTradeHistory
         * @param {string} symbol unified market symbol
         * @param {int} [since] the earliest time in ms to fetch trades for
         * @param {int} [limit] the maximum number of trades structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {Trade[]} a list of [trade structures]{@link https://docs.ccxt.com/#/?id=trade-structure}
         */
        await this.loadMarkets();
        const request = {
        // 'type': 'all', // any position, closed position, closing position, no position
        // 'trades': false, // whether or not to include trades related to position in output
        // 'start': 1234567890, // starting unix timestamp or trade tx id of results (exclusive)
        // 'end': 1234567890, // ending unix timestamp or trade tx id of results (inclusive)
        // 'ofs' = result offset
        };
        if (since !== undefined) {
            request['start'] = this.parseToInt(since / 1000);
        }
        const response = await this.privatePostTradesHistory(this.extend(request, params));
        //
        //     {
        //         "error": [],
        //         "result": {
        //             "trades": {
        //                 "GJ3NYQ-XJRTF-THZABF": {
        //                     "ordertxid": "TKH2SE-ZIF5E-CFI7LT",
        //                     "postxid": "OEN3VX-M7IF5-JNBJAM",
        //                     "pair": "XICNXETH",
        //                     "time": 1527213229.4491,
        //                     "type": "sell",
        //                     "ordertype": "limit",
        //                     "price": "0.001612",
        //                     "cost": "0.025792",
        //                     "fee": "0.000026",
        //                     "vol": "16.00000000",
        //                     "margin": "0.000000",
        //                     "misc": ""
        //                 },
        //                 ...
        //             },
        //             "count": 9760,
        //         },
        //     }
        //
        const trades = response['result']['trades'];
        const ids = Object.keys(trades);
        for (let i = 0; i < ids.length; i++) {
            trades[ids[i]]['id'] = ids[i];
        }
        let market = undefined;
        if (symbol !== undefined) {
            market = this.market(symbol);
        }
        return this.parseTrades(trades, market, since, limit);
    }
    async cancelOrder(id, symbol = undefined, params = {}) {
        /**
         * @method
         * @name kraken#cancelOrder
         * @description cancels an open order
         * @see https://docs.kraken.com/rest/#tag/Trading/operation/cancelOrder
         * @param {string} id order id
         * @param {string} symbol unified symbol of the market the order was made in
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} An [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        let response = undefined;
        const clientOrderId = this.safeValue2(params, 'userref', 'clientOrderId', id);
        const request = {
            'txid': clientOrderId, // order id or userref
        };
        params = this.omit(params, ['userref', 'clientOrderId']);
        try {
            response = await this.privatePostCancelOrder(this.extend(request, params));
        }
        catch (e) {
            if (this.last_http_response) {
                if (this.last_http_response.indexOf('EOrder:Unknown order') >= 0) {
                    throw new OrderNotFoundError().addMessage(this.last_http_response).exchange(this.id);
                }
            }
            throw e;
        }
        return response;
    }
    async cancelOrders(ids, symbol = undefined, params = {}) {
        /**
         * @method
         * @name kraken#cancelOrders
         * @description cancel multiple orders
         * @see https://docs.kraken.com/rest/#tag/Trading/operation/cancelOrderBatch
         * @param {string[]} ids open orders transaction ID (txid) or user reference (userref)
         * @param {string} symbol unified market symbol
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} an list of [order structures]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        const request = {
            'orders': ids,
        };
        const response = await this.privatePostCancelOrderBatch(this.extend(request, params));
        //
        //     {
        //         "error": [],
        //         "result": {
        //           "count": 2
        //         }
        //     }
        //
        return response;
    }
    async cancelAllOrders(symbol = undefined, params = {}) {
        /**
         * @method
         * @name kraken#cancelAllOrders
         * @description cancel all open orders
         * @see https://docs.kraken.com/rest/#tag/Trading/operation/cancelAllOrders
         * @param {string} symbol unified market symbol, only orders in the market of this symbol are cancelled when symbol is not undefined
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object[]} a list of [order structures]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        return await this.privatePostCancelAll(params);
    }
    async fetchOpenOrders(symbol = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name kraken#fetchOpenOrders
         * @description fetch all unfilled currently open orders
         * @see https://docs.kraken.com/rest/#tag/Account-Data/operation/getOpenOrders
         * @param {string} symbol unified market symbol
         * @param {int} [since] the earliest time in ms to fetch open orders for
         * @param {int} [limit] the maximum number of  open orders structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {Order[]} a list of [order structures]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const request = {};
        if (since !== undefined) {
            request['start'] = this.parseToInt(since / 1000);
        }
        let query = params;
        const clientOrderId = this.safeValue2(params, 'userref', 'clientOrderId');
        if (clientOrderId !== undefined) {
            request['userref'] = clientOrderId;
            query = this.omit(params, ['userref', 'clientOrderId']);
        }
        const response = await this.privatePostOpenOrders(this.extend(request, query));
        let market = undefined;
        if (symbol !== undefined) {
            market = this.market(symbol);
        }
        const result = this.safeValue(response, 'result', {});
        const orders = this.safeValue(result, 'open', []);
        return this.parseOrders(orders, market, since, limit);
    }
    async fetchClosedOrders(symbol = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name kraken#fetchClosedOrders
         * @description fetches information on multiple closed orders made by the user
         * @see https://docs.kraken.com/rest/#tag/Account-Data/operation/getClosedOrders
         * @param {string} symbol unified market symbol of the market orders were made in
         * @param {int} [since] the earliest time in ms to fetch orders for
         * @param {int} [limit] the maximum number of order structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {int} [params.until] timestamp in ms of the latest entry
         * @returns {Order[]} a list of [order structures]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        let request = {};
        if (since !== undefined) {
            request['start'] = this.parseToInt(since / 1000);
        }
        let query = params;
        const clientOrderId = this.safeValue2(params, 'userref', 'clientOrderId');
        if (clientOrderId !== undefined) {
            request['userref'] = clientOrderId;
            query = this.omit(params, ['userref', 'clientOrderId']);
        }
        [request, params] = this.handleUntilOption('end', request, params);
        const response = await this.privatePostClosedOrders(this.extend(request, query));
        //
        //     {
        //         "error":[],
        //         "result":{
        //             "closed":{
        //                 "OETZYO-UL524-QJMXCT":{
        //                     "refid":null,
        //                     "userref":null,
        //                     "status":"canceled",
        //                     "reason":"User requested",
        //                     "opentm":1601489313.3898,
        //                     "closetm":1601489346.5507,
        //                     "starttm":0,
        //                     "expiretm":0,
        //                     "descr":{
        //                         "pair":"ETHUSDT",
        //                         "type":"buy",
        //                         "ordertype":"limit",
        //                         "price":"330.00",
        //                         "price2":"0",
        //                         "leverage":"none",
        //                         "order":"buy 0.02100000 ETHUSDT @ limit 330.00",
        //                         "close":""
        //                     },
        //                     "vol":"0.02100000",
        //                     "vol_exec":"0.00000000",
        //                     "cost":"0.00000",
        //                     "fee":"0.00000",
        //                     "price":"0.00000",
        //                     "stopprice":"0.00000",
        //                     "limitprice":"0.00000",
        //                     "misc":"",
        //                     "oflags":"fciq"
        //                 },
        //             },
        //             "count":16
        //         }
        //     }
        //
        let market = undefined;
        if (symbol !== undefined) {
            market = this.market(symbol);
        }
        const result = this.safeValue(response, 'result', {});
        const orders = this.safeValue(result, 'closed', []);
        return this.parseOrders(orders, market, since, limit);
    }
    parseTransactionStatus(status) {
        // IFEX transaction states
        const statuses = {
            'Initial': 'pending',
            'Pending': 'pending',
            'Success': 'ok',
            'Settled': 'pending',
            'Failure': 'failed',
            'Partial': 'ok',
        };
        return this.safeString(statuses, status, status);
    }
    parseTransaction(transaction, currency = undefined) {
        //
        // fetchDeposits
        //
        //     {
        //         "method": "Ether (Hex)",
        //         "aclass": "currency",
        //         "asset": "XETH",
        //         "refid": "Q2CANKL-LBFVEE-U4Y2WQ",
        //         "txid": "0x57fd704dab1a73c20e24c8696099b695d596924b401b261513cfdab23…",
        //         "info": "0x615f9ba7a9575b0ab4d571b2b36b1b324bd83290",
        //         "amount": "7.9999257900",
        //         "fee": "0.0000000000",
        //         "time":  1529223212,
        //         "status": "Success"
        //     }
        //
        // there can be an additional 'status-prop' field present
        // deposit pending review by exchange => 'on-hold'
        // the deposit is initiated by the exchange => 'return'
        //
        //      {
        //          "type": 'deposit',
        //          "method": 'Fidor Bank AG (Wire Transfer)',
        //          "aclass": 'currency',
        //          "asset": 'ZEUR',
        //          "refid": 'xxx-xxx-xxx',
        //          "txid": '12341234',
        //          "info": 'BANKCODEXXX',
        //          "amount": '38769.08',
        //          "fee": '0.0000',
        //          "time": 1644306552,
        //          "status": 'Success',
        //          status-prop: 'on-hold'
        //      }
        //
        //
        // fetchWithdrawals
        //
        //     {
        //         "method": "Ether",
        //         "aclass": "currency",
        //         "asset": "XETH",
        //         "refid": "A2BF34S-O7LBNQ-UE4Y4O",
        //         "txid": "0x288b83c6b0904d8400ef44e1c9e2187b5c8f7ea3d838222d53f701a15b5c274d",
        //         "info": "0x7cb275a5e07ba943fee972e165d80daa67cb2dd0",
        //         "amount": "9.9950000000",
        //         "fee": "0.0050000000",
        //         "time":  1530481750,
        //         "status": "Success"
        //         "key":"Huobi wallet",
        //         "network":"Tron"
        //         status-prop: 'on-hold' // this field might not be present in some cases
        //     }
        //
        // withdraw
        //
        //     {
        //         "refid": "AGBSO6T-UFMTTQ-I7KGS6"
        //     }
        //
        const id = this.safeString(transaction, 'refid');
        const txid = this.safeString(transaction, 'txid');
        const timestamp = this.safeTimestamp(transaction, 'time');
        const currencyId = this.safeString(transaction, 'asset');
        const code = this.safeCurrencyCode(currencyId, currency);
        const address = this.safeString(transaction, 'info');
        const amount = this.safeNumber(transaction, 'amount');
        const network = this.safeString(transaction, 'network');
        let status = this.parseTransactionStatus(this.safeString(transaction, 'status'));
        const statusProp = this.safeString(transaction, 'status-prop');
        const isOnHoldDeposit = statusProp === 'on-hold';
        const isCancellationRequest = statusProp === 'cancel-pending';
        const isOnHoldWithdrawal = statusProp === 'onhold';
        if (isOnHoldDeposit || isCancellationRequest || isOnHoldWithdrawal) {
            status = 'pending';
        }
        const type = this.safeString(transaction, 'type'); // injected from the outside
        let feeCost = this.safeNumber(transaction, 'fee');
        if (feeCost === undefined) {
            if (type === 'deposit') {
                feeCost = 0;
            }
        }
        return {
            'info': transaction,
            'id': id,
            'currency': code,
            'amount': amount,
            'network': network,
            'address': address,
            'addressTo': undefined,
            'addressFrom': undefined,
            'tag': undefined,
            'tagTo': undefined,
            'tagFrom': undefined,
            'status': status,
            'type': type,
            'updated': undefined,
            'txid': txid,
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'comment': undefined,
            'internal': undefined,
            'fee': {
                'currency': code,
                'cost': feeCost,
            },
        };
    }
    parseTransactionsByType(type, transactions, code = undefined, since = undefined, limit = undefined) {
        const result = [];
        for (let i = 0; i < transactions.length; i++) {
            const transaction = this.parseTransaction(this.extend({
                'type': type,
            }, transactions[i]));
            result.push(transaction);
        }
        return this.filterByCurrencySinceLimit(result, code, since, limit);
    }
    async fetchDeposits(code = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name kraken#fetchDeposits
         * @description fetch all deposits made to an account
         * @see https://docs.kraken.com/rest/#tag/Funding/operation/getStatusRecentDeposits
         * @param {string} code unified currency code
         * @param {int} [since] the earliest time in ms to fetch deposits for
         * @param {int} [limit] the maximum number of deposits structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object[]} a list of [transaction structures]{@link https://docs.ccxt.com/#/?id=transaction-structure}
         */
        // https://www.kraken.com/en-us/help/api#deposit-status
        if (code === undefined) {
            throw new InvalidParamsError('fetchDeposits() requires a currency code argument')
                .exchange(this.id);
        }
        await this.loadMarkets();
        const currency = this.currency(code);
        const request = {
            'asset': currency['id'],
        };
        if (since !== undefined) {
            request['start'] = since;
        }
        const response = await this.privatePostDepositStatus(this.extend(request, params));
        //
        //     {  error: [],
        //       "result": [ { "method": "Ether (Hex)",
        //                     "aclass": "currency",
        //                      "asset": "XETH",
        //                      "refid": "Q2CANKL-LBFVEE-U4Y2WQ",
        //                       "txid": "0x57fd704dab1a73c20e24c8696099b695d596924b401b261513cfdab23…",
        //                       "info": "0x615f9ba7a9575b0ab4d571b2b36b1b324bd83290",
        //                     "amount": "7.9999257900",
        //                        "fee": "0.0000000000",
        //                       "time":  1529223212,
        //                     "status": "Success"                                                       } ] }
        //
        return this.parseTransactionsByType('deposit', response['result'], code, since, limit);
    }
    async fetchTime(params = {}) {
        /**
         * @method
         * @name kraken#fetchTime
         * @description fetches the current integer timestamp in milliseconds from the exchange server
         * @see https://docs.kraken.com/rest/#tag/Market-Data/operation/getServerTime
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {int} the current integer timestamp in milliseconds from the exchange server
         */
        // https://www.kraken.com/en-us/features/api#get-server-time
        const response = await this.publicGetTime(params);
        //
        //    {
        //        "error": [],
        //        "result": {
        //            "unixtime": 1591502873,
        //            "rfc1123": "Sun,  7 Jun 20 04:07:53 +0000"
        //        }
        //    }
        //
        const result = this.safeValue(response, 'result', {});
        return this.safeTimestamp(result, 'unixtime');
    }
    async fetchWithdrawals(code = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name kraken#fetchWithdrawals
         * @description fetch all withdrawals made from an account
         * @see https://docs.kraken.com/rest/#tag/Funding/operation/getStatusRecentWithdrawals
         * @param {string} code unified currency code
         * @param {int} [since] the earliest time in ms to fetch withdrawals for
         * @param {int} [limit] the maximum number of withdrawals structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {object} [params.end] End timestamp, withdrawals created strictly after will be not be included in the response
         * @param {boolean} [params.paginate]  default false, when true will automatically paginate by calling this endpoint multiple times
         * @returns {object[]} a list of [transaction structures]{@link https://docs.ccxt.com/#/?id=transaction-structure}
         */
        await this.loadMarkets();
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchWithdrawals', 'paginate');
        if (paginate) {
            params['cursor'] = true;
            return await this.fetchPaginatedCallCursor('fetchWithdrawals', code, since, limit, params, 'next_cursor', 'cursor');
        }
        const request = {};
        if (code !== undefined) {
            const currency = this.currency(code);
            request['asset'] = currency['id'];
        }
        if (since !== undefined) {
            request['since'] = since.toString();
        }
        const response = await this.privatePostWithdrawStatus(this.extend(request, params));
        //
        // with no pagination
        //     {  error: [],
        //       "result": [ { "method": "Ether",
        //                     "aclass": "currency",
        //                      "asset": "XETH",
        //                      "refid": "A2BF34S-O7LBNQ-UE4Y4O",
        //                       "txid": "0x298c83c7b0904d8400ef43e1c9e2287b518f7ea3d838822d53f704a1565c274d",
        //                       "info": "0x7cb275a5e07ba943fee972e165d80daa67cb2dd0",
        //                     "amount": "9.9950000000",
        //                        "fee": "0.0050000000",
        //                       "time":  1530481750,
        //                     "status": "Success"                                                             } ] }
        // with pagination
        //    {
        //        "error":[],
        //        "result":{
        //           "withdrawals":[
        //              {
        //                 "method":"Tether USD (TRC20)",
        //                 "aclass":"currency",
        //                 "asset":"USDT",
        //                 "refid":"BSNFZU2-MEFN4G-J3NEZV",
        //                 "txid":"1c7a642fb7387bbc2c6a2c509fd1ae146937f4cf793b4079a4f0715e3a02615a",
        //                 "info":"TQmdxSuC16EhFg8FZWtYgrfFRosoRF7bCp",
        //                 "amount":"1996.50000000",
        //                 "fee":"2.50000000",
        //                 "time":1669126657,
        //                 "status":"Success",
        //                 "key":"poloniex",
        //                 "network":"Tron"
        //              },
        //             ...
        //           ],
        //           "next_cursor":"HgAAAAAAAABGVFRSd3k1LVF4Y0JQY05Gd0xRY0NxenFndHpybkwBAQH2AwEBAAAAAQAAAAAAAAABAAAAAAAZAAAAAAAAAA=="
        //        }
        //     }
        //
        let rawWithdrawals = undefined;
        const result = this.safeValue(response, 'result');
        if (!Array.isArray(result)) {
            rawWithdrawals = this.addPaginationCursorToResult(result);
        }
        else {
            rawWithdrawals = result;
        }
        return this.parseTransactionsByType('withdrawal', rawWithdrawals, code, since, limit);
    }
    addPaginationCursorToResult(result) {
        const cursor = this.safeString(result, 'next_cursor');
        const data = this.safeValue(result, 'withdrawals');
        const dataLength = data.length;
        if (cursor !== undefined && dataLength > 0) {
            const last = data[dataLength - 1];
            last['next_cursor'] = cursor;
            data[dataLength - 1] = last;
        }
        return data;
    }
    async createDepositAddress(code, params = {}) {
        /**
         * @method
         * @name kraken#createDepositAddress
         * @description create a currency deposit address
         * @see https://docs.kraken.com/rest/#tag/Funding/operation/getDepositAddresses
         * @param {string} code unified currency code of the currency for the deposit address
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} an [address structure]{@link https://docs.ccxt.com/#/?id=address-structure}
         */
        const request = {
            'new': 'true',
        };
        return await this.fetchDepositAddress(code, this.extend(request, params));
    }
    async fetchDepositMethods(code, params = {}) {
        /**
         * @method
         * @name kraken#fetchDepositMethods
         * @description fetch deposit methods for a currency associated with this account
         * @see https://docs.kraken.com/rest/#tag/Funding/operation/getDepositMethods
         * @param {string} code unified currency code
         * @param {object} [params] extra parameters specific to the kraken api endpoint
         * @returns {object} of deposit methods
         */
        await this.loadMarkets();
        const currency = this.currency(code);
        const request = {
            'asset': currency['id'],
        };
        const response = await this.privatePostDepositMethods(this.extend(request, params));
        //
        //     {
        //         "error":[],
        //         "result":[
        //             {"method":"Ether (Hex)","limit":false,"gen-address":true}
        //         ]
        //     }
        //
        //     {
        //         "error":[],
        //         "result":[
        //             {"method":"Tether USD (ERC20)","limit":false,"address-setup-fee":"0.00000000","gen-address":true},
        //             {"method":"Tether USD (TRC20)","limit":false,"address-setup-fee":"0.00000000","gen-address":true}
        //         ]
        //     }
        //
        //     {
        //         "error":[],
        //         "result":[
        //             {"method":"Bitcoin","limit":false,"fee":"0.0000000000","gen-address":true}
        //         ]
        //     }
        //
        const result = this.safeValue(response, 'result', []);
        const depositMethods = [];
        for (let i = 0; i < result.length; i++) {
            const element = this.safeValue(result, i, {});
            const method = this.safeString(element, 'method');
            const fee = this.safeString2(element, 'address-setup-fee', 'fee');
            const limit = this.safeValue(element, 'limit');
            const network = this.methodToEcid(method, code);
            if (network === undefined) {
                this.options?.report('unmapped_network', {
                    method,
                    'tokenSymbol': code,
                });
            }
            depositMethods.push({
                'method': method,
                'network': network,
                'fee': fee,
                'limit': limit,
                'info': element,
            });
        }
        return depositMethods;
    }
    async fetchDepositAddress(code, params = {}) {
        /**
         * @method
         * @name kraken#fetchDepositAddress
         * @description fetch the deposit address for a currency associated with this account
         * @see https://docs.kraken.com/rest/#tag/Funding/operation/getDepositAddresses
         * @param {string} code unified currency code
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {object} [params.network] the network name for the deposit address (required)
         * @returns {object} an [address structure]{@link https://docs.ccxt.com/#/?id=address-structure}
         */
        await this.loadMarkets();
        const currency = this.currency(code);
        const network = this.safeValue(params, 'network');
        if (!network) {
            throw new InvalidParamsError('network parameter is required').exchange(this.id);
        }
        const depositMethods = await this.fetchDepositMethods(code);
        const targetedDepositMethods = depositMethods.filter((depoMethod) => this.safeString(depoMethod, 'network') === network);
        const targetedDepositMethod = this.safeValue(targetedDepositMethods, 0, undefined);
        if (!targetedDepositMethod) {
            throw new GetDepositAddressError(code, network).exchange(this.id);
        }
        const depositMethod = this.safeString(targetedDepositMethod, 'method');
        const request = {
            'asset': currency['id'],
            'method': depositMethod,
        };
        const response = await this.privatePostDepositAddresses(this.extend(request, params));
        //
        //     {
        //         "error":[],
        //         "result":[
        //             {"address":"0x77b5051f97efa9cc52c9ad5b023a53fc15c200d3","expiretm":"0"}
        //         ]
        //     }
        //
        const result = this.safeValue(response, 'result', []);
        const firstResult = this.safeValue(result, 0, {});
        if (firstResult === undefined) {
            throw new GetDepositAddressError(code, network).exchange(this.id);
        }
        return this.parseDepositAddress(firstResult, currency);
    }
    parseDepositAddress(depositAddress, currency = undefined) {
        //
        //     {
        //         "address":"0x77b5051f97efa9cc52c9ad5b023a53fc15c200d3",
        //         "expiretm":"0"
        //     }
        //
        const address = this.safeString(depositAddress, 'address');
        const tag = this.safeString(depositAddress, 'tag');
        currency = this.safeCurrency(undefined, currency);
        const code = currency['code'];
        this.checkAddress(address);
        return {
            'currency': code,
            'address': address,
            'tag': tag,
            'network': undefined,
            'info': depositAddress,
        };
    }
    async withdraw(code, amount, address, tag = undefined, params = {}) {
        /**
         * @method
         * @name kraken#withdraw
         * @description make a withdrawal
         * @see https://docs.kraken.com/rest/#tag/Funding/operation/withdrawFunds
         * @param {string} code unified currency code
         * @param {float} amount the amount to withdraw
         * @param {string} address the address to withdraw to
         * @param {string} tag
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a [transaction structure]{@link https://docs.ccxt.com/#/?id=transaction-structure}
         */
        [tag, params] = this.handleWithdrawTagAndParams(tag, params);
        // address is optional
        if (address !== undefined) {
            this.checkAddress(address);
        }
        if ('key' in params) {
            await this.loadMarkets();
            const currency = this.currency(code);
            const amountPrecision = this.currencyToPrecision(code, amount);
            const request = {
                'asset': currency['id'],
                'amount': amountPrecision,
            };
            if (address) {
                request['address'] = address;
            }
            const response = await this.privatePostWithdraw(this.extend(request, params));
            //
            //     {
            //         "error": [],
            //         "result": {
            //             "refid": "AGBSO6T-UFMTTQ-I7KGS6"
            //         }
            //     }
            //
            const result = this.safeValue(response, 'result', {});
            return this.parseTransaction(result, currency);
        }
        throw new InvalidParamsError("withdraw() requires a 'key' parameter (withdrawal key name, as set up on your account)").exchange(this.id);
    }
    async fetchPositions(symbols = undefined, params = {}) {
        /**
         * @method
         * @name kraken#fetchPositions
         * @description fetch all open positions
         * @see https://docs.kraken.com/rest/#tag/Account-Data/operation/getOpenPositions
         * @param {string[]|undefined} symbols not used by kraken fetchPositions ()
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object[]} a list of [position structure]{@link https://docs.ccxt.com/#/?id=position-structure}
         */
        await this.loadMarkets();
        const request = {
        // 'txid': 'comma delimited list of transaction ids to restrict output to',
        // 'docalcs': false, // whether or not to include profit/loss calculations
        // 'consolidation': 'market', // what to consolidate the positions data around, market will consolidate positions based on market pair
        };
        const response = await this.privatePostOpenPositions(this.extend(request, params));
        //
        // no consolidation
        //
        //     {
        //         "error": [],
        //         "result": {
        //             'TGUFMY-FLESJ-VYIX3J': {
        //                 "ordertxid": "O3LRNU-ZKDG5-XNCDFR",
        //                 "posstatus": "open",
        //                 "pair": "ETHUSDT",
        //                 "time":  1611557231.4584,
        //                 "type": "buy",
        //                 "ordertype": "market",
        //                 "cost": "28.49800",
        //                 "fee": "0.07979",
        //                 "vol": "0.02000000",
        //                 "vol_closed": "0.00000000",
        //                 "margin": "14.24900",
        //                 "terms": "0.0200% per 4 hours",
        //                 "rollovertm": "1611571631",
        //                 "misc": "",
        //                 "oflags": ""
        //             }
        //         }
        //     }
        //
        // consolidation by market
        //
        //     {
        //         "error": [],
        //         "result": [
        //             {
        //                 "pair": "ETHUSDT",
        //                 "positions": "1",
        //                 "type": "buy",
        //                 "leverage": "2.00000",
        //                 "cost": "28.49800",
        //                 "fee": "0.07979",
        //                 "vol": "0.02000000",
        //                 "vol_closed": "0.00000000",
        //                 "margin": "14.24900"
        //             }
        //         ]
        //     }
        //
        const result = this.safeValue(response, 'result');
        // todo unify parsePosition/parsePositions
        return result;
    }
    parseAccount(account) {
        const accountByType = {
            'spot': 'Spot Wallet',
            'swap': 'Futures Wallet',
            'future': 'Futures Wallet',
        };
        return this.safeString(accountByType, account, account);
    }
    async transfer(code, amount, fromAccount, toAccount, params = {}) {
        /**
         * @method
         * @name kraken#transfer
         * @see https://docs.kraken.com/rest/#tag/User-Funding/operation/walletTransfer
         * @description transfers currencies between sub-accounts (only spot->swap direction is supported)
         * @param {string} code Unified currency code
         * @param {float} amount Size of the transfer
         * @param {string} fromAccount 'spot' or 'Spot Wallet'
         * @param {string} toAccount 'swap' or 'Futures Wallet'
         * @param {object} [params] Exchange specific parameters
         * @returns a [transfer structure]{@link https://docs.ccxt.com/#/?id=transfer-structure}
         */
        await this.loadMarkets();
        const currency = this.currency(code);
        fromAccount = this.parseAccount(fromAccount);
        toAccount = this.parseAccount(toAccount);
        const request = {
            'amount': this.currencyToPrecision(code, amount),
            'from': fromAccount,
            'to': toAccount,
            'asset': currency['id'],
        };
        if (fromAccount !== 'Spot Wallet') {
            throw new ExchangeTransferError()
                .addMessage('Transfer cannot transfer from ' + fromAccount + ' to ' + toAccount + '. Use krakenfutures instead to transfer from the futures account.')
                .exchange(this.id);
        }
        const response = await this.privatePostWalletTransfer(this.extend(request, params));
        //
        //   {
        //       "error":[
        //       ],
        //       "result":{
        //          "refid":"BOIUSIF-M7DLMN-UXZ3P5"
        //       }
        //   }
        //
        const transfer = this.parseTransfer(response, currency);
        return this.extend(transfer, {
            'amount': amount,
            'fromAccount': fromAccount,
            'toAccount': toAccount,
        });
    }
    parseTransfer(transfer, currency = undefined) {
        //
        // transfer
        //
        //    {
        //        "error":[
        //        ],
        //        "result":{
        //           "refid":"BOIUSIF-M7DLMN-UXZ3P5"
        //        }
        //    }
        //
        const result = this.safeValue(transfer, 'result', {});
        const refid = this.safeString(result, 'refid');
        return {
            'info': transfer,
            'id': refid,
            'timestamp': undefined,
            'datetime': undefined,
            'currency': this.safeString(currency, 'code'),
            'amount': undefined,
            'fromAccount': undefined,
            'toAccount': undefined,
            'status': 'sucess',
        };
    }
    sign(path, api = 'public', method = 'GET', params = {}, headers = undefined, body = undefined) {
        let url = '/' + this.version + '/' + api + '/' + path;
        if (api === 'public') {
            if (Object.keys(params).length) {
                // urlencodeNested is used to address https://github.com/ccxt/ccxt/issues/12872
                url += '?' + this.urlencodeNested(params);
            }
        }
        else if (api === 'private') {
            const isCancelOrderBatch = (path === 'CancelOrderBatch');
            this.checkRequiredCredentials();
            const nonce = this.nonce().toString();
            // urlencodeNested is used to address https://github.com/ccxt/ccxt/issues/12872
            if (isCancelOrderBatch) {
                body = this.json(this.extend({ 'nonce': nonce }, params));
            }
            else {
                body = this.urlencodeNested(this.extend({ 'nonce': nonce }, params));
            }
            const auth = this.encode(nonce + body);
            const hash = this.hash(auth, sha256, 'binary');
            const binary = this.encode(url);
            const binhash = this.binaryConcat(binary, hash);
            const secret = this.base64ToBinary(this.secret);
            const signature = this.hmac(binhash, secret, sha512, 'base64');
            headers = {
                'API-Key': this.apiKey,
                'API-Sign': signature,
                // 'Content-Type': 'application/x-www-form-urlencoded',
            };
            if (isCancelOrderBatch) {
                headers['Content-Type'] = 'application/json';
            }
            else {
                headers['Content-Type'] = 'application/x-www-form-urlencoded';
            }
        }
        else {
            url = '/' + path;
        }
        url = this.urls['api'][api] + url;
        return { 'url': url, 'method': method, 'body': body, 'headers': headers };
    }
    nonce() {
        return this.milliseconds() - this.options['timeDifference'];
    }
    handleErrors(code, reason, url, method, headers, body, response, requestHeaders, requestBody) {
        // {
        //   "error": [
        //       "EGeneral:Invalid arguments:ordertype"
        //   ]
        // }
        if (body) {
            if (code === 520) {
                throw new ExchangeNotAvailableError().addMessage(code.toString() + ': ' + reason).exchange(this.id);
            }
            const errors = this.safeValue(response, 'error', []);
            if (errors.length > 0) {
                for (let i = 0; i < errors.length; i++) {
                    const error = errors[i];
                    const errorMessage = error;
                    this.throwExactlyMatchedException(this.exceptions['exact'], error, errorMessage);
                    this.throwBroadlyMatchedException(this.exceptions['broad'], error, errorMessage);
                }
                throw new ExchangeError().addMessage(errors.join(', ')).exchange(this.id);
            }
        }
        return undefined;
    }
}
