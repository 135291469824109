import { useRef, useState } from "react";
import { Bubble } from "@cede/ds";
import { useDependencies } from "../../hooks";
import { Box, Typography, useTheme } from "@mui/material";

type Props = {
  label?: string;
};

export const ErrorLine = ({ label = "Error" }: Props) => {
  const theme = useTheme();
  const { useSelectedTransaction } = useDependencies();
  const error = useSelectedTransaction((state) => state.error);
  const popperRef = useRef(null);
  const popperAnchor = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const onMouseIn = () => {
    setIsOpen(true);
  };

  const onMouseOut = () => {
    setIsOpen(false);
  };

  if (!error) return null;

  return (
    <>
      <Typography variant={"body2"}>{label}</Typography>
      <Box ref={popperAnchor} onMouseEnter={onMouseIn} onMouseOut={onMouseOut} justifySelf={"right"}>
        <Typography textAlign={"right"} variant={"body1"} color={theme.palette.error.main}>
          {error.length > 16 ? `${error.slice(0, 16)}...` : error}
        </Typography>
        <Bubble
          hideArrow={true}
          ref={popperRef}
          isOpen={isOpen}
          anchor={popperAnchor.current}
          placement={"top-end"}
          disablePortal={true}
        >
          <Typography variant={"body1"} fontSize={theme.custom.textSize.medium}>
            {error}
          </Typography>
        </Bubble>
      </Box>
    </>
  );
};
