const PAGE_SEPARATOR = "--/";

function removeGarbage(mdContent: string): string {
  return mdContent
    .replace(/\n\s+\n/g, "") // remove breaking line with white spaces
    .replace(/^(\s+)?(\n+)?/g, "") // remove breaking line and white spaces at the beginning
    .replace(/(\s+)?(\n+)?$/g, "") // remove breaking line and white spaces at the end
    .replace(/--\//g, ""); // remove pagination separator if it's disabled
}

function removeComments(mdContent: string): string {
  return mdContent.replace(/<!--[\s\S]*?-->/g, "");
}

export function extractPages(mdContent: string, disablePagination?: boolean) {
  if (!disablePagination) {
    return removeComments(mdContent)
      .split(PAGE_SEPARATOR)
      .map(removeGarbage)
      .filter((pageContent) => pageContent.trim().length > 0);
  }

  return removeGarbage(removeComments(mdContent));
}
