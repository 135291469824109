import { useMemo } from "react";
import { htmlExports } from "../utils/htmlExports";

export const useIsOnboardingRequest = () => {
  const isOnboardingRequest = useMemo(() => {
    const url = new URL(window.location.href);
    return url.pathname === htmlExports.requestOnboardingHtml;
  }, []);

  return {
    isOnboardingRequest,
  };
};
