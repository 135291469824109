import { timestampToDate } from "@cede/utils";
import { useDependencies } from "../../hooks";
import { Typography } from "@mui/material";

export const DateLine = () => {
  const { useSelectedTransaction } = useDependencies();
  const transaction = useSelectedTransaction((state) => state.transaction);

  if (!transaction) return null;

  return (
    <>
      <Typography variant={"body2"}>Date</Typography>
      <Typography variant={"body1"} justifySelf={"right"}>
        {timestampToDate(transaction.from.transaction.timestamp)}
      </Typography>
    </>
  );
};
