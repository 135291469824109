import { formatBalance } from "../balance";
import { CURRENCY_CODE_MAP } from "../currencies";

export const DEFAULT_CURRENCY = "USD";

export const formatCurrencyAmount = (
  amount: number,
  local = "en-US",
  currency = DEFAULT_CURRENCY,
  hideBalance = false,
) => {
  const amountNumber = formatBalance(amount, {
    roundForFiat: currency === DEFAULT_CURRENCY,
    hideBalance,
  });
  const currencySymbol = getCurrencySymbol(local, currency);

  if (amountNumber[0] === "-") {
    return `-${currencySymbol}${amountNumber.slice(1)}`;
  } else {
    return `${currencySymbol}${amountNumber}`;
  }
};

export const getCurrencySymbol = (locale: string, currency: string) =>
  CURRENCY_CODE_MAP.has(currency)
    ? CURRENCY_CODE_MAP.get(currency)!
    : (0)
        .toLocaleString(locale, {
          style: "currency",
          currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, "")
        .trim();
