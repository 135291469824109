export enum DateFormat {
  DEFAULT = "default",
  METRICS = "metrics",
}

const metricsDateOptions = {
  hour12: true,
  hour: "numeric" as const,
  minute: "2-digit" as const,
  second: "2-digit" as const,
  fractionalSecondDigits: 3 as const,
  timeZoneName: "short" as const,
  weekday: "short" as const,
  month: "short" as const,
  day: "numeric" as const,
  year: "numeric" as const,
};

export function getRelativeDate(date: number) {
  const relativeDate = Date.now() - date;

  let seconds = Math.floor(relativeDate / 1000);

  let minutes = Math.floor(seconds / 60);

  const hours = Math.floor(minutes / 60);

  const days = Math.floor(hours / 24);

  seconds = seconds % 60;

  minutes = minutes % 60;

  return days ? `${days} d` : hours ? `${hours} h` : minutes ? `${minutes} min` : `${seconds} sec`;
}

export function timestampToDate(timestamp: number, format: DateFormat = DateFormat.DEFAULT) {
  const date = new Date(timestamp);

  const dayStr = date.getDate().toString().padStart(2, "0");
  const monthStr = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  const hoursStr = date.getHours().toString().padStart(2, "0");
  const minutesStr = date.getMinutes().toString().padStart(2, "0");

  switch (format) {
    case DateFormat.METRICS:
      // Format: Tue, Jan 20, 1970, 4:55:50.293 PM GMT+1
      return date.toLocaleString("en-US", metricsDateOptions);
    default:
      // Format: 20/01/1970 16:55
      return `${dayStr}/${monthStr}/${year} ${hoursStr}:${minutesStr}`;
  }
}

export function formatDateYYYYMMDD(date: Date) {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}
