// ----------------------------------------------------------------------------

// PLEASE DO NOT EDIT THIS FILE, IT IS GENERATED AND WILL BE OVERWRITTEN:
// https://github.com/ccxt/ccxt/blob/master/CONTRIBUTING.md#how-to-contribute-code
// EDIT THE CORRESPONDENT .ts FILE INSTEAD

import { SdkErrorCodes, SdkErrorMessages } from "./errorsMetadata.js";
import { CedeSDKError } from "./default.js";
/**
 * @description Default error for authentication
 */
export class AuthenticationError extends CedeSDKError {
    constructor(code = SdkErrorCodes.AUTHENTICATION_ERROR, message = SdkErrorMessages.AUTHENTICATION_ERROR) {
        super(code, message);
        this.name = "AuthenticationError";
    }
}
/**
 * @description Thrown when the credentials are invalid
 */
export class InvalidCredentialsError extends AuthenticationError {
    constructor() {
        super(SdkErrorCodes.INVALID_CREDENTIALS, SdkErrorMessages.INVALID_CREDENTIALS);
        this.name = "InvalidCredentialsError";
        this.notReportable = true;
    }
}
/**
 * @description Thrown when the authentication flow is not supported
 */
export class AuthenticationFlowNotSupportedError extends AuthenticationError {
    constructor() {
        super(SdkErrorCodes.AUTHENTICATION_FLOW_NOT_SUPPORTED, SdkErrorMessages.AUTHENTICATION_FLOW_NOT_SUPPORTED);
        this.name = "AuthenticationFlowNotSupportedError";
    }
}
/**
 * @descrion Thrown when the oauth credentials are revoked
 */
export class OAuthAccessRevokedError extends AuthenticationError {
    constructor() {
        super(SdkErrorCodes.OAUTH_ACCESS_REVOKED, SdkErrorMessages.OAUTH_ACCESS_REVOKED);
        this.name = "OAuthAccessRevokedError";
        this.notReportable = true;
    }
}
