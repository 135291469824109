/**
 * Parse limit amount in for the Market order, from quote (cost) to base minimum amount
 * @param minCost
 * @param tickerPrice
 * @param toPrecision
 * @param safetyMargin - safety margin to take into account the price fluctuation. 0.15 = 15%
 */
export const parseMinAmountFromMinCost = (
  minCost: number,
  tickerPrice: string,
  toPrecision: number,
  safetyMargin = 0.15,
) => {
  const limitAmount = minCost / parseFloat(tickerPrice);
  const limitAmountWithMargin = limitAmount * (1 + safetyMargin); // add x% safety margin if the price fluctuates
  return parseFloat(limitAmountWithMargin.toPrecision(toPrecision));
};

/**
 * Parse limit amount in for the Market order, from base minimum amount required to quote (cost)
 * @param minAmount
 * @param tickerPrice
 * @param toPrecision
 * @param safetyMargin
 */
export const parseMinCostFromMinAmount = (
  minAmount: number,
  tickerPrice: string,
  toPrecision: number,
  safetyMargin = 0.15,
) => {
  const limitCost = minAmount * parseFloat(tickerPrice);
  const limitCostWithMargin = limitCost * (1 + safetyMargin); // add x% safety margin if the price fluctuates
  return parseFloat(limitCostWithMargin.toPrecision(toPrecision));
};

/**
 * Parse trade minimum safe amount by adding a safety margin (15%)
 * @param minLimit
 * @param toPrecision
 * @param safetyMargin - safety margin to take into account the price fluctuation. 0.15 = 15%
 */
export const parseLimit = (minLimit: number, toPrecision: number, safetyMargin = 0.15) => {
  const limitCostWithMargin = minLimit * (1 + safetyMargin); // add x% safety margin if the price fluctuates
  return parseFloat(limitCostWithMargin.toPrecision(toPrecision));
};

export const DEFAULT_UNDEFINED_STRING = "unknown";
export const DEFAULT_UNDEFINED_NUMBER = 0;
