import { useMemo } from "react";
import {
  ApiPermissions,
  DropListWithIconItem,
  ExchangeInfo,
  ExchangeInfoById,
  ExchangeStatus,
  FeaturesKeys,
  featureKeyToPermission,
  userFacingMessages,
} from "@cede/types";
import { AVAILABLE_EXCHANGES_CACHE_TIME, QueryKeys, SUPPORTED_EXCHANGES_CACHE_TIME } from "@cede/utils";
import { useDependencies } from "./useDependencies";
import { useQuery } from "@tanstack/react-query";

type ExchangeAvailibility = {
  [exchangeId: string]: boolean;
};

export const useSupportedExchanges = () => {
  const { useVaults, useBackgroundHandler, useLoginStore } = useDependencies();
  const { vaults } = useVaults();

  const { backgroundHandler } = useBackgroundHandler();
  const { isLoggedIn } = useLoginStore();

  const {
    data: supportedExchanges,
    error,
    isLoading,
    refetch,
  } = useQuery<ExchangeInfo[] | undefined, Error>({
    queryKey: [QueryKeys.SUPPORTED_EXCHANGES, vaults],
    queryFn: async () => {
      if (!backgroundHandler?.isReady || !isLoggedIn) return;
      return await backgroundHandler.supportedExchanges();
    },
    enabled: !!backgroundHandler?.isReady && isLoggedIn,
    staleTime: SUPPORTED_EXCHANGES_CACHE_TIME,
    gcTime: SUPPORTED_EXCHANGES_CACHE_TIME,
  });

  const {
    data: availableExchanges,
    isFetching: isUnavailableExchangesLoading,
    refetch: refetchUnavailableExchanges,
  } = useQuery<ExchangeAvailibility | undefined>({
    queryKey: [QueryKeys.AVAILABLE_EXCHANGES, vaults],
    queryFn: async () => {
      if (!supportedExchanges || !backgroundHandler?.isReady || !isLoggedIn) return;
      const availableExchanges: ExchangeAvailibility = {};
      const promises = supportedExchanges.map((exchange) =>
        // TODO fix the type of backgroundHanler. `useBackgroundHandler` is overrided in cede.store by the hook for trusted connection that has a method `verifyApiCountryAvailability`.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        backgroundHandler.verifyApiCountryAvailability({
          exchangeId: exchange.id,
        }),
      );

      await Promise.all(promises);

      for (const exchange of supportedExchanges) {
        availableExchanges[exchange.id] = await promises[supportedExchanges.indexOf(exchange)];
      }
      return availableExchanges;
    },
    enabled: !!backgroundHandler?.isReady && isLoggedIn && !!supportedExchanges,
    staleTime: AVAILABLE_EXCHANGES_CACHE_TIME,
    gcTime: AVAILABLE_EXCHANGES_CACHE_TIME,
  });

  const supportedExchangesById = useMemo(() => {
    if (!supportedExchanges) return {};

    return supportedExchanges.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {} as ExchangeInfoById);
  }, [supportedExchanges]);

  const downExchanges = useMemo(() => {
    const groups: ExchangeInfo[] = [];
    if (!supportedExchanges) return groups;

    return supportedExchanges.filter((exchange) =>
      [ExchangeStatus.Shutdown, ExchangeStatus.Error, ExchangeStatus.Maintenance].includes(exchange.status),
    );
  }, [supportedExchanges]);

  const downExchangesIds = useMemo(() => {
    if (!downExchanges) return [];

    return downExchanges.map((exchange) => exchange.id);
  }, [downExchanges]);

  const unavailableExchangesIds = useMemo(() => {
    if (!availableExchanges) return [];

    return Object.entries(availableExchanges)
      .filter(([, isAvailable]) => !isAvailable)
      .map(([exchangeId]) => exchangeId);
  }, [availableExchanges]);

  const isRequiringWhitelist = (permissions: { type: ApiPermissions }[], exchangeId: string) => {
    if (!supportedExchangesById[exchangeId]) return false;
    const exchange = supportedExchangesById[exchangeId];
    return permissions.some((permission) => exchange?.whitelistScopes?.includes(permission.type));
  };

  const shouldDisableCex =
    (feature: FeaturesKeys) =>
    (option: DropListWithIconItem<{ cexValue?: string; permissions?: ApiPermissions[] }>) => {
      if (!option.cexValue) return false;
      if (supportedExchangesById[option.cexValue]?.featuresUnderMaintenance?.[feature]) return true;
      if (!option.permissions?.includes(featureKeyToPermission[feature])) return true;

      return false;
    };

  const disabledCexText =
    (
      feature: FeaturesKeys,
      MissingPermissionElement: React.FC<{
        option: DropListWithIconItem<{ cexValue?: string; permissions?: ApiPermissions[] }>;
      }>,
    ) =>
    (option: DropListWithIconItem<{ cexValue?: string; permissions?: ApiPermissions[] }>) => {
      if (!option.cexValue) return userFacingMessages.EXCHANGES_STATUSES.TEMPORARILY_UNAVAILABLE;
      if (supportedExchangesById[option.cexValue]?.featuresUnderMaintenance?.[feature])
        return userFacingMessages.EXCHANGES_STATUSES.UNDER_MAINTENANCE;
      if (!option.permissions?.includes(featureKeyToPermission[feature]))
        return <MissingPermissionElement option={option} />;

      return userFacingMessages.EXCHANGES_STATUSES.TEMPORARILY_UNAVAILABLE;
    };

  const isFeatureSupportedByCex = (exchangeId: string, feature: FeaturesKeys) => {
    return supportedExchangesById[exchangeId]?.supportedFeatures?.includes(feature);
  };

  const hasAtLeastOneCexSupported = (feature: FeaturesKeys) => {
    return !!supportedExchanges?.find((exchange) => exchange.supportedFeatures.includes(feature));
  };

  return {
    refetchSupportedExchanges: refetch,
    supportedExchanges,
    downExchanges,
    downExchangesIds,
    supportedExchangesById,
    isSupportedExchangesLoading: isLoading,
    supportedExchangesError: error,
    isRequiringWhitelist,
    shouldDisableCex,
    disabledCexText,
    isFeatureSupportedByCex,
    hasAtLeastOneCexSupported,
    unavailableExchangesIds,
    isUnavailableExchangesLoading,
    refetchUnavailableExchanges,
  };
};
