import { useAccounts } from "../../hooks/accounts/useAccounts";
import { usePrice, usePrices, useSetupPrices } from "../../hooks/prices";
import { useBackgroundHandler } from "../../hooks/useBackgroundHandler";
import { useLogin } from "../../hooks/useLogin";
import { useProviderEvents } from "../../hooks/useProviderEvents";
import { useSupportedExchanges } from "../../hooks/useSupportedExchanges";
import { useVaults } from "../../hooks/useVaults";
import { useViewEnvironment } from "../../hooks/useViewEnvironment";
import { useLoginStore } from "../../store/login/login";

export type CedeReactDependencies = {
  useLogin: typeof useLogin;
  useLoginStore: typeof useLoginStore;
  useBackgroundHandler: typeof useBackgroundHandler;
  useVaults: typeof useVaults;
  useAccounts: typeof useAccounts;
  useSupportedExchanges: typeof useSupportedExchanges;
  usePrice: typeof usePrice;
  usePrices: typeof usePrices;
  useSetupPrices: typeof useSetupPrices;
  useProviderEvents: typeof useProviderEvents;
  useViewEnvironment: typeof useViewEnvironment;
};

export const reactSdkDefaultDependencies: CedeReactDependencies = {
  useBackgroundHandler,
  useLogin,
  useLoginStore,
  useVaults,
  useSupportedExchanges,
  useAccounts,
  usePrice,
  usePrices,
  useSetupPrices,
  useProviderEvents,
  useViewEnvironment,
} as CedeReactDependencies;
