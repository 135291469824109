// ----------------------------------------------------------------------------

// PLEASE DO NOT EDIT THIS FILE, IT IS GENERATED AND WILL BE OVERWRITTEN:
// https://github.com/ccxt/ccxt/blob/master/CONTRIBUTING.md#how-to-contribute-code
// EDIT THE CORRESPONDENT .ts FILE INSTEAD

//  ---------------------------------------------------------------------------
import Exchange from './abstract/bitget.js';
import { Precise } from './base/Precise.js';
import { TICK_SIZE } from './base/functions/number.js';
import { AuthenticationError, ExchangeAccountSuspendedError, ExchangeDDosProtectionError, ExchangeError, ExchangeInvalidNonceError, ExchangeInvalidPermissionsError, ExchangeNotAllowedMethodError, ExchangeNotAvailableError, ExchangeRateLimitExceededError, ExchangeRequestExpiredError, ExchangeTransferError, ExchangeUnderMaintenanceError, ExchangeUnknownAssetError, InsufficientBalanceError, InternalError, InvalidAddressError, InvalidCredentialsError, InvalidParamsError, MarketError, MarketNotFoundError, MarketTypeNotSupportedError, OrderNotFoundError, OverWithdrawMaximumError, TradeError, UnderWithdrawMinimumError, WhitelistCheckFailedError, WithdrawalError } from './sdk/errors.js';
import { SdkErrorMessages } from './sdk/errorsMetadata.js';
import { sha256 } from './static_dependencies/noble-hashes/sha256.js';
//  ---------------------------------------------------------------------------
/**
 * @class bitget
 * @augments Exchange
 */
export default class bitget extends Exchange {
    constructor() {
        super(...arguments);
        this.USDT_FUTURES = 'USDT-FUTURES';
        this.USDT_SETTLES = ['USDT'];
        this.USDC_FUTURES = 'USDC-FUTURES';
        this.USDC_SETTLES = ['USDC'];
        this.SUSDT_FUTURES = 'SUSDT-FUTURES';
        this.SUSDT_SETTLES = ['SUSDT'];
        this.SUSDC_FUTURES = 'SUSDC-FUTURES';
        this.SUSDC_SETTLES = ['SUSDC'];
        this.COIN_FUTURES = 'COIN-FUTURES';
        this.SCOIN_FUTURES = 'SCOIN-FUTURES';
        this.SCOIN_SETTLES = ['SETH', 'SBTC', 'SEOS'];
    }
    describe() {
        return this.deepExtend(super.describe(), {
            'id': 'bitget',
            'name': 'Bitget',
            'countries': ['SG'],
            'version': 'v2',
            'rateLimit': 50,
            'certified': true,
            'pro': true,
            'has': {
                'CORS': undefined,
                'spot': true,
                'margin': true,
                'swap': true,
                'future': true,
                'option': false,
                'cancelAllOrders': true,
                'cancelOrder': true,
                'cancelOrders': true,
                'createDepositAddress': false,
                'createMarketBuyOrderWithCost': true,
                'createMarketOrderWithCost': false,
                'createMarketSellOrderWithCost': false,
                'createOrder': true,
                'createOrders': true,
                'createOrderWithTakeProfitAndStopLoss': true,
                'createTakeProfitOrder': true,
                'createTrailingAmountOrder': false,
                'createTrailingPercentOrder': true,
                'createTriggerOrder': true,
                'editOrder': true,
                'fetchAccounts': false,
                'fetchBalance': true,
                'fetchCanceledAndClosedOrders': true,
                'fetchCanceledOrders': true,
                'fetchClosedOrders': true,
                'fetchCurrencies': true,
                'fetchDeposit': false,
                'fetchDepositAddress': true,
                'fetchDepositAddresses': false,
                'fetchDeposits': true,
                'fetchDepositsWithdrawals': false,
                'fetchDepositWithdrawFee': 'emulated',
                'fetchDepositWithdrawFees': true,
                'fetchLedger': true,
                'fetchMarkets': true,
                'fetchMyTrades': true,
                'fetchOHLCV': true,
                'fetchOpenOrders': true,
                'fetchOrder': true,
                'fetchOrderBook': true,
                'fetchOrderBooks': false,
                'fetchOrders': false,
                'fetchOrderTrades': false,
                'fetchPosition': true,
                'fetchPositions': true,
                'fetchPositionsRisk': false,
                'fetchStatus': false,
                'fetchTicker': true,
                'fetchTickers': true,
                'fetchTime': true,
                'fetchTrades': true,
                'fetchTradingFee': true,
                'fetchTradingFees': true,
                'fetchTransactions': false,
                'fetchTransfer': false,
                'fetchTransfers': true,
                'fetchWithdrawAddresses': false,
                'fetchWithdrawal': false,
                'fetchWithdrawals': true,
                'transfer': true,
                'withdraw': true,
            },
            'timeframes': {
                '1m': '1m',
                '3m': '3m',
                '5m': '5m',
                '15m': '15m',
                '30m': '30m',
                '1h': '1h',
                '2h': '2h',
                '4h': '4h',
                '6h': '6h',
                '12h': '12h',
                '1d': '1d',
                '3d': '3d',
                '1w': '1w',
                '1M': '1m',
            },
            'hostname': 'bitget.com',
            'urls': {
                'logo': 'https://user-images.githubusercontent.com/1294454/195989417-4253ddb0-afbe-4a1c-9dea-9dbcd121fa5d.jpg',
                'api': {
                    'spot': 'https://api.{hostname}',
                    'mix': 'https://api.{hostname}',
                    'user': 'https://api.{hostname}',
                    'p2p': 'https://api.{hostname}',
                    'broker': 'https://api.{hostname}',
                    'margin': 'https://api.{hostname}',
                    'common': 'https://api.{hostname}',
                    'tax': 'https://api.{hostname}',
                    'convert': 'https://api.{hostname}',
                    'copy': 'https://api.{hostname}',
                    'earn': 'https://api.{hostname}',
                },
                'www': 'https://www.bitget.com',
                'doc': [
                    'https://www.bitget.com/api-doc/common/intro',
                    'https://www.bitget.com/api-doc/spot/intro',
                    'https://www.bitget.com/api-doc/contract/intro',
                    'https://www.bitget.com/api-doc/broker/intro',
                    'https://www.bitget.com/api-doc/margin/intro',
                    'https://www.bitget.com/api-doc/copytrading/intro',
                    'https://www.bitget.com/api-doc/earn/intro',
                    'https://bitgetlimited.github.io/apidoc/en/mix',
                    'https://bitgetlimited.github.io/apidoc/en/spot',
                    'https://bitgetlimited.github.io/apidoc/en/broker',
                    'https://bitgetlimited.github.io/apidoc/en/margin',
                ],
                'fees': 'https://www.bitget.cc/zh-CN/rate?tab=1',
                // https://www.bitget.com/expressly?languageType=0&channelCode=ccxt&vipCode=tg9j - I removed the code for now, because CWS is blocking until we explain our affiliate program to our users
                'referral': 'https://www.bitget.com',
            },
            'api': {
                'public': {
                    'common': {
                        'get': {
                            'v2/public/annoucements': 1,
                            'v2/public/time': 1,
                        },
                    },
                    'spot': {
                        'get': {
                            'spot/v1/notice/queryAllNotices': 1,
                            'spot/v1/public/time': 1,
                            'spot/v1/public/currencies': 6.6667,
                            'spot/v1/public/products': 1,
                            'spot/v1/public/product': 1,
                            'spot/v1/market/ticker': 1,
                            'spot/v1/market/tickers': 1,
                            'spot/v1/market/fills': 2,
                            'spot/v1/market/fills-history': 2,
                            'spot/v1/market/candles': 1,
                            'spot/v1/market/depth': 1,
                            'spot/v1/market/spot-vip-level': 2,
                            'spot/v1/market/merge-depth': 1,
                            'spot/v1/market/history-candles': 1,
                            'spot/v1/public/loan/coinInfos': 2,
                            'spot/v1/public/loan/hour-interest': 2,
                            'v2/spot/public/coins': 6.6667,
                            'v2/spot/public/symbols': 1,
                            'v2/spot/market/vip-fee-rate': 2,
                            'v2/spot/market/tickers': 1,
                            'v2/spot/market/merge-depth': 1,
                            'v2/spot/market/orderbook': 1,
                            'v2/spot/market/candles': 1,
                            'v2/spot/market/history-candles': 1,
                            'v2/spot/market/fills': 2,
                            'v2/spot/market/fills-history': 2,
                        },
                    },
                    'mix': {
                        'get': {
                            'mix/v1/market/contracts': 1,
                            'mix/v1/market/depth': 1,
                            'mix/v1/market/ticker': 1,
                            'mix/v1/market/tickers': 1,
                            'mix/v1/market/contract-vip-level': 2,
                            'mix/v1/market/fills': 1,
                            'mix/v1/market/fills-history': 2,
                            'mix/v1/market/candles': 1,
                            'mix/v1/market/index': 1,
                            'mix/v1/market/funding-time': 1,
                            'mix/v1/market/history-fundRate': 1,
                            'mix/v1/market/current-fundRate': 1,
                            'mix/v1/market/open-interest': 1,
                            'mix/v1/market/mark-price': 1,
                            'mix/v1/market/symbol-leverage': 1,
                            'mix/v1/market/queryPositionLever': 1,
                            'mix/v1/market/open-limit': 1,
                            'mix/v1/market/history-candles': 1,
                            'mix/v1/market/history-index-candles': 1,
                            'mix/v1/market/history-mark-candles': 1,
                            'mix/v1/market/merge-depth': 1,
                            'v2/mix/market/vip-fee-rate': 2,
                            'v2/mix/market/merge-depth': 1,
                            'v2/mix/market/ticker': 1,
                            'v2/mix/market/tickers': 1,
                            'v2/mix/market/fills': 1,
                            'v2/mix/market/fills-history': 2,
                            'v2/mix/market/candles': 1,
                            'v2/mix/market/history-candles': 1,
                            'v2/mix/market/history-index-candles': 1,
                            'v2/mix/market/history-mark-candles': 1,
                            'v2/mix/market/open-interest': 1,
                            'v2/mix/market/funding-time': 1,
                            'v2/mix/market/symbol-price': 1,
                            'v2/mix/market/history-fund-rate': 1,
                            'v2/mix/market/current-fund-rate': 1,
                            'v2/mix/market/contracts': 1,
                            'v2/mix/market/query-position-lever': 2,
                        },
                    },
                    'margin': {
                        'get': {
                            'margin/v1/cross/public/interestRateAndLimit': 2,
                            'margin/v1/isolated/public/interestRateAndLimit': 2,
                            'margin/v1/cross/public/tierData': 2,
                            'margin/v1/isolated/public/tierData': 2,
                            'margin/v1/public/currencies': 1,
                            'v2/margin/currencies': 2,
                        },
                    },
                    'earn': {
                        'get': {
                            'v2/earn/loan/public/coinInfos': 2,
                            'v2/earn/loan/public/hour-interest': 2,
                        },
                    },
                },
                'private': {
                    'spot': {
                        'get': {
                            'spot/v1/wallet/deposit-address': 4,
                            'spot/v1/wallet/withdrawal-list': 1,
                            'spot/v1/wallet/deposit-list': 1,
                            'spot/v1/account/getInfo': 20,
                            'spot/v1/account/assets': 2,
                            'spot/v1/account/assets-lite': 2,
                            'spot/v1/account/transferRecords': 1,
                            'spot/v1/convert/currencies': 2,
                            'spot/v1/convert/convert-record': 2,
                            'spot/v1/loan/ongoing-orders': 2,
                            'spot/v1/loan/repay-history': 2,
                            'spot/v1/loan/revise-history': 2,
                            'spot/v1/loan/borrow-history': 2,
                            'spot/v1/loan/debts': 2,
                            'v2/spot/trade/orderInfo': 1,
                            'v2/spot/trade/unfilled-orders': 1,
                            'v2/spot/trade/history-orders': 1,
                            'v2/spot/trade/fills': 2,
                            'v2/spot/trade/current-plan-order': 1,
                            'v2/spot/trade/history-plan-order': 1,
                            'v2/spot/account/info': 20,
                            'v2/spot/account/assets': 2,
                            'v2/spot/account/subaccount-assets': 2,
                            'v2/spot/account/bills': 2,
                            'v2/spot/account/transferRecords': 1,
                            'v2/spot/wallet/deposit-address': 2,
                            'v2/spot/wallet/deposit-records': 2,
                            'v2/spot/wallet/withdrawal-records': 2,
                        },
                        'post': {
                            'spot/v1/wallet/transfer': 4,
                            'spot/v1/wallet/transfer-v2': 4,
                            'spot/v1/wallet/subTransfer': 10,
                            'spot/v1/wallet/withdrawal': 4,
                            'spot/v1/wallet/withdrawal-v2': 4,
                            'spot/v1/wallet/withdrawal-inner': 4,
                            'spot/v1/wallet/withdrawal-inner-v2': 4,
                            'spot/v1/account/sub-account-spot-assets': 200,
                            'spot/v1/account/bills': 2,
                            'spot/v1/trade/orders': 2,
                            'spot/v1/trade/batch-orders': 4,
                            'spot/v1/trade/cancel-order': 2,
                            'spot/v1/trade/cancel-order-v2': 2,
                            'spot/v1/trade/cancel-symbol-order': 2,
                            'spot/v1/trade/cancel-batch-orders': 4,
                            'spot/v1/trade/cancel-batch-orders-v2': 4,
                            'spot/v1/trade/orderInfo': 1,
                            'spot/v1/trade/open-orders': 1,
                            'spot/v1/trade/history': 1,
                            'spot/v1/trade/fills': 1,
                            'spot/v1/plan/placePlan': 1,
                            'spot/v1/plan/modifyPlan': 1,
                            'spot/v1/plan/cancelPlan': 1,
                            'spot/v1/plan/currentPlan': 1,
                            'spot/v1/plan/historyPlan': 1,
                            'spot/v1/plan/batchCancelPlan': 2,
                            'spot/v1/convert/quoted-price': 4,
                            'spot/v1/convert/trade': 4,
                            'spot/v1/loan/borrow': 2,
                            'spot/v1/loan/repay': 2,
                            'spot/v1/loan/revise-pledge': 2,
                            'spot/v1/trace/order/orderCurrentList': 2,
                            'spot/v1/trace/order/orderHistoryList': 2,
                            'spot/v1/trace/order/closeTrackingOrder': 2,
                            'spot/v1/trace/order/updateTpsl': 2,
                            'spot/v1/trace/order/followerEndOrder': 2,
                            'spot/v1/trace/order/spotInfoList': 2,
                            'spot/v1/trace/config/getTraderSettings': 2,
                            'spot/v1/trace/config/getFollowerSettings': 2,
                            'spot/v1/trace/user/myTraders': 2,
                            'spot/v1/trace/config/setFollowerConfig': 2,
                            'spot/v1/trace/user/myFollowers': 2,
                            'spot/v1/trace/config/setProductCode': 2,
                            'spot/v1/trace/user/removeTrader': 2,
                            'spot/v1/trace/getRemovableFollower': 2,
                            'spot/v1/trace/user/removeFollower': 2,
                            'spot/v1/trace/profit/totalProfitInfo': 2,
                            'spot/v1/trace/profit/totalProfitList': 2,
                            'spot/v1/trace/profit/profitHisList': 2,
                            'spot/v1/trace/profit/profitHisDetailList': 2,
                            'spot/v1/trace/profit/waitProfitDetailList': 2,
                            'spot/v1/trace/user/getTraderInfo': 2,
                            'v2/spot/trade/place-order': 2,
                            'v2/spot/trade/cancel-order': 2,
                            'v2/spot/trade/batch-orders': 20,
                            'v2/spot/trade/batch-cancel-order': 2,
                            'v2/spot/trade/cancel-symbol-order': 4,
                            'v2/spot/trade/place-plan-order': 1,
                            'v2/spot/trade/modify-plan-order': 1,
                            'v2/spot/trade/cancel-plan-order': 1,
                            'v2/spot/trade/batch-cancel-plan-order': 2,
                            'v2/spot/wallet/transfer': 2,
                            'v2/spot/wallet/subaccount-transfer': 2,
                            'v2/spot/wallet/withdrawal': 2,
                        },
                    },
                    'mix': {
                        'get': {
                            'mix/v1/account/account': 2,
                            'mix/v1/account/accounts': 2,
                            'mix/v1/position/singlePosition': 2,
                            'mix/v1/position/singlePosition-v2': 2,
                            'mix/v1/position/allPosition': 4,
                            'mix/v1/position/allPosition-v2': 4,
                            'mix/v1/position/history-position': 1,
                            'mix/v1/account/accountBill': 2,
                            'mix/v1/account/accountBusinessBill': 4,
                            'mix/v1/order/current': 1,
                            'mix/v1/order/marginCoinCurrent': 1,
                            'mix/v1/order/history': 2,
                            'mix/v1/order/historyProductType': 4,
                            'mix/v1/order/detail': 2,
                            'mix/v1/order/fills': 2,
                            'mix/v1/order/allFills': 2,
                            'mix/v1/plan/currentPlan': 1,
                            'mix/v1/plan/historyPlan': 2,
                            'mix/v1/trace/currentTrack': 2,
                            'mix/v1/trace/followerOrder': 2,
                            'mix/v1/trace/followerHistoryOrders': 2,
                            'mix/v1/trace/historyTrack': 2,
                            'mix/v1/trace/summary': 1,
                            'mix/v1/trace/profitSettleTokenIdGroup': 1,
                            'mix/v1/trace/profitDateGroupList': 1,
                            'mix/v1/trade/profitDateList': 2,
                            'mix/v1/trace/waitProfitDateList': 1,
                            'mix/v1/trace/traderSymbols': 1,
                            'mix/v1/trace/traderList': 2,
                            'mix/v1/trace/traderDetail': 2,
                            'mix/v1/trace/queryTraceConfig': 2,
                            'v2/mix/account/account': 2,
                            'v2/mix/account/accounts': 2,
                            'v2/mix/account/sub-account-assets': 200,
                            'v2/mix/account/open-count': 2,
                            'v2/mix/account/bill': 2,
                            'v2/mix/market/query-position-lever': 2,
                            'v2/mix/position/single-position': 2,
                            'v2/mix/position/all-position': 4,
                            'v2/mix/position/history-position': 1,
                            'v2/mix/order/detail': 2,
                            'v2/mix/order/fills': 2,
                            'v2/mix/order/fill-history': 2,
                            'v2/mix/order/orders-pending': 2,
                            'v2/mix/order/orders-history': 2,
                            'v2/mix/order/orders-plan-pending': 2,
                            'v2/mix/order/orders-plan-history': 2,
                        },
                        'post': {
                            'mix/v1/account/sub-account-contract-assets': 200,
                            'mix/v1/account/open-count': 1,
                            'mix/v1/account/setLeverage': 4,
                            'mix/v1/account/setMargin': 4,
                            'mix/v1/account/setMarginMode': 4,
                            'mix/v1/account/setPositionMode': 4,
                            'mix/v1/order/placeOrder': 2,
                            'mix/v1/order/batch-orders': 2,
                            'mix/v1/order/cancel-order': 2,
                            'mix/v1/order/cancel-batch-orders': 2,
                            'mix/v1/order/modifyOrder': 2,
                            'mix/v1/order/cancel-symbol-orders': 2,
                            'mix/v1/order/cancel-all-orders': 2,
                            'mix/v1/order/close-all-positions': 20,
                            'mix/v1/plan/placePlan': 2,
                            'mix/v1/plan/modifyPlan': 2,
                            'mix/v1/plan/modifyPlanPreset': 2,
                            'mix/v1/plan/placeTPSL': 2,
                            'mix/v1/plan/placeTrailStop': 2,
                            'mix/v1/plan/placePositionsTPSL': 2,
                            'mix/v1/plan/modifyTPSLPlan': 2,
                            'mix/v1/plan/cancelPlan': 2,
                            'mix/v1/plan/cancelSymbolPlan': 2,
                            'mix/v1/plan/cancelAllPlan': 2,
                            'mix/v1/trace/closeTrackOrder': 2,
                            'mix/v1/trace/modifyTPSL': 2,
                            'mix/v1/trace/closeTrackOrderBySymbol': 2,
                            'mix/v1/trace/setUpCopySymbols': 2,
                            'mix/v1/trace/followerSetBatchTraceConfig': 2,
                            'mix/v1/trace/followerCloseByTrackingNo': 2,
                            'mix/v1/trace/followerCloseByAll': 2,
                            'mix/v1/trace/followerSetTpsl': 2,
                            'mix/v1/trace/cancelCopyTrader': 4,
                            'mix/v1/trace/traderUpdateConfig': 2,
                            'mix/v1/trace/myTraderList': 2,
                            'mix/v1/trace/myFollowerList': 2,
                            'mix/v1/trace/removeFollower': 2,
                            'mix/v1/trace/public/getFollowerConfig': 2,
                            'mix/v1/trace/report/order/historyList': 2,
                            'mix/v1/trace/report/order/currentList': 2,
                            'mix/v1/trace/queryTraderTpslRatioConfig': 2,
                            'mix/v1/trace/traderUpdateTpslRatioConfig': 2,
                            'v2/mix/account/set-leverage': 4,
                            'v2/mix/account/set-margin': 4,
                            'v2/mix/account/set-margin-mode': 4,
                            'v2/mix/account/set-position-mode': 4,
                            'v2/mix/order/place-order': 20,
                            'v2/mix/order/click-backhand': 20,
                            'v2/mix/order/batch-place-order': 20,
                            'v2/mix/order/modify-order': 2,
                            'v2/mix/order/cancel-order': 2,
                            'v2/mix/order/batch-cancel-orders': 2,
                            'v2/mix/order/close-positions': 20,
                            'v2/mix/order/place-tpsl-order': 2,
                            'v2/mix/order/place-plan-order': 2,
                            'v2/mix/order/modify-tpsl-order': 2,
                            'v2/mix/order/modify-plan-order': 2,
                            'v2/mix/order/cancel-plan-order': 2,
                        },
                    },
                    'user': {
                        'get': {
                            'user/v1/fee/query': 2,
                            'user/v1/sub/virtual-list': 2,
                            'user/v1/sub/virtual-api-list': 2,
                            'user/v1/tax/spot-record': 1,
                            'user/v1/tax/future-record': 1,
                            'user/v1/tax/margin-record': 1,
                            'user/v1/tax/p2p-record': 1,
                            'v2/user/virtual-subaccount-list': 2,
                            'v2/user/virtual-subaccount-apikey-list': 2,
                        },
                        'post': {
                            'user/v1/sub/virtual-create': 4,
                            'user/v1/sub/virtual-modify': 4,
                            'user/v1/sub/virtual-api-batch-create': 20,
                            'user/v1/sub/virtual-api-create': 4,
                            'user/v1/sub/virtual-api-modify': 4,
                            'v2/user/create-virtual-subaccount': 4,
                            'v2/user/modify-virtual-subaccount': 4,
                            'v2/user/batch-create-subaccount-and-apikey': 20,
                            'v2/user/create-virtual-subaccount-apikey': 4,
                            'v2/user/modify-virtual-subaccount-apikey': 4,
                        },
                    },
                    'p2p': {
                        'get': {
                            'p2p/v1/merchant/merchantList': 2,
                            'p2p/v1/merchant/merchantInfo': 2,
                            'p2p/v1/merchant/advList': 2,
                            'p2p/v1/merchant/orderList': 2,
                            'v2/p2p/merchantList': 2,
                            'v2/p2p/merchantInfo': 2,
                            'v2/p2p/orderList': 2,
                            'v2/p2p/advList': 2,
                        },
                    },
                    'broker': {
                        'get': {
                            'broker/v1/account/info': 2,
                            'broker/v1/account/sub-list': 20,
                            'broker/v1/account/sub-email': 20,
                            'broker/v1/account/sub-spot-assets': 2,
                            'broker/v1/account/sub-future-assets': 2,
                            'broker/v1/account/subaccount-transfer': 1,
                            'broker/v1/account/subaccount-deposit': 1,
                            'broker/v1/account/subaccount-withdrawal': 1,
                            'broker/v1/account/sub-api-list': 2,
                            'v2/broker/account/info': 2,
                            'v2/broker/account/subaccount-list': 20,
                            'v2/broker/account/subaccount-email': 2,
                            'v2/broker/account/subaccount-spot-assets': 2,
                            'v2/broker/account/subaccount-future-assets': 2,
                            'v2/broker/manage/subaccount-apikey-list': 2,
                        },
                        'post': {
                            'broker/v1/account/sub-create': 20,
                            'broker/v1/account/sub-modify': 20,
                            'broker/v1/account/sub-modify-email': 20,
                            'broker/v1/account/sub-address': 2,
                            'broker/v1/account/sub-withdrawal': 2,
                            'broker/v1/account/sub-auto-transfer': 4,
                            'broker/v1/account/sub-api-create': 2,
                            'broker/v1/account/sub-api-modify': 2,
                            'v2/broker/account/modify-subaccount-email': 2,
                            'v2/broker/account/create-subaccount': 20,
                            'v2/broker/account/modify-subaccount': 20,
                            'v2/broker/account/subaccount-address': 2,
                            'v2/broker/account/subaccount-withdrawal': 2,
                            'v2/broker/account/set-subaccount-autotransfer': 2,
                            'v2/broker/manage/create-subaccount-apikey': 2,
                            'v2/broker/manage/modify-subaccount-apikey': 2,
                        },
                    },
                    'margin': {
                        'get': {
                            'margin/v1/cross/account/riskRate': 2,
                            'margin/v1/cross/account/maxTransferOutAmount': 2,
                            'margin/v1/isolated/account/maxTransferOutAmount': 2,
                            'margin/v1/isolated/order/openOrders': 2,
                            'margin/v1/isolated/order/history': 2,
                            'margin/v1/isolated/order/fills': 2,
                            'margin/v1/isolated/loan/list': 2,
                            'margin/v1/isolated/repay/list': 2,
                            'margin/v1/isolated/interest/list': 2,
                            'margin/v1/isolated/liquidation/list': 2,
                            'margin/v1/isolated/fin/list': 2,
                            'margin/v1/cross/order/openOrders': 2,
                            'margin/v1/cross/order/history': 2,
                            'margin/v1/cross/order/fills': 2,
                            'margin/v1/cross/loan/list': 2,
                            'margin/v1/cross/repay/list': 2,
                            'margin/v1/cross/interest/list': 2,
                            'margin/v1/cross/liquidation/list': 2,
                            'margin/v1/cross/fin/list': 2,
                            'margin/v1/cross/account/assets': 2,
                            'margin/v1/isolated/account/assets': 2,
                            'v2/margin/crossed/borrow-history': 2,
                            'v2/margin/crossed/repay-history': 2,
                            'v2/margin/crossed/interest-history': 2,
                            'v2/margin/crossed/liquidation-history': 2,
                            'v2/margin/crossed/financial-records': 2,
                            'v2/margin/crossed/account/assets': 2,
                            'v2/margin/crossed/account/risk-rate': 2,
                            'v2/margin/crossed/account/max-borrowable-amount': 2,
                            'v2/margin/crossed/account/max-transfer-out-amount': 2,
                            'v2/margin/crossed/interest-rate-and-limit': 2,
                            'v2/margin/crossed/tier-data': 2,
                            'v2/margin/crossed/open-orders': 2,
                            'v2/margin/crossed/history-orders': 2,
                            'v2/margin/crossed/fills': 2,
                            'v2/margin/isolated/borrow-history': 2,
                            'v2/margin/isolated/repay-history': 2,
                            'v2/margin/isolated/interest-history': 2,
                            'v2/margin/isolated/liquidation-history': 2,
                            'v2/margin/isolated/financial-records': 2,
                            'v2/margin/isolated/account/assets': 2,
                            'v2/margin/isolated/account/risk-rate': 2,
                            'v2/margin/isolated/account/max-borrowable-amount': 2,
                            'v2/margin/isolated/account/max-transfer-out-amount': 2,
                            'v2/margin/isolated/interest-rate-and-limit': 2,
                            'v2/margin/isolated/tier-data': 2,
                            'v2/margin/isolated/open-orders': 2,
                            'v2/margin/isolated/history-orders': 2,
                            'v2/margin/isolated/fills': 2,
                        },
                        'post': {
                            'margin/v1/cross/account/borrow': 2,
                            'margin/v1/isolated/account/borrow': 2,
                            'margin/v1/cross/account/repay': 2,
                            'margin/v1/isolated/account/repay': 2,
                            'margin/v1/isolated/account/riskRate': 2,
                            'margin/v1/cross/account/maxBorrowableAmount': 2,
                            'margin/v1/isolated/account/maxBorrowableAmount': 2,
                            'margin/v1/isolated/account/flashRepay': 2,
                            'margin/v1/isolated/account/queryFlashRepayStatus': 2,
                            'margin/v1/cross/account/flashRepay': 2,
                            'margin/v1/cross/account/queryFlashRepayStatus': 2,
                            'margin/v1/isolated/order/placeOrder': 4,
                            'margin/v1/isolated/order/batchPlaceOrder': 4,
                            'margin/v1/isolated/order/cancelOrder': 2,
                            'margin/v1/isolated/order/batchCancelOrder': 2,
                            'margin/v1/cross/order/placeOrder': 2,
                            'margin/v1/cross/order/batchPlaceOrder': 2,
                            'margin/v1/cross/order/cancelOrder': 2,
                            'margin/v1/cross/order/batchCancelOrder': 2,
                            'v2/margin/crossed/account/borrow': 2,
                            'v2/margin/crossed/account/repay': 2,
                            'v2/margin/crossed/account/flash-repay': 2,
                            'v2/margin/crossed/account/query-flash-repay-status': 2,
                            'v2/margin/crossed/place-order': 2,
                            'v2/margin/crossed/batch-place-order': 2,
                            'v2/margin/crossed/cancel-order': 2,
                            'v2/margin/crossed/batch-cancel-order': 2,
                            'v2/margin/isolated/account/borrow': 2,
                            'v2/margin/isolated/account/repay': 2,
                            'v2/margin/isolated/account/flash-repay': 2,
                            'v2/margin/isolated/account/query-flash-repay-status': 2,
                            'v2/margin/isolated/place-order': 2,
                            'v2/margin/isolated/batch-place-order': 2,
                            'v2/margin/isolated/cancel-order': 2,
                            'v2/margin/isolated/batch-cancel-order': 2,
                        },
                    },
                    'copy': {
                        'get': {
                            'v2/copy/mix-trader/order-current-track': 2,
                            'v2/copy/mix-trader/order-history-track': 2,
                            'v2/copy/mix-trader/order-total-detail': 2,
                            'v2/copy/mix-trader/profit-history-summarys': 1,
                            'v2/copy/mix-trader/profit-history-details': 1,
                            'v2/copy/mix-trader/profit-details': 1,
                            'v2/copy/mix-trader/profits-group-coin-date': 1,
                            'v2/copy/mix-trader/config-query-symbols': 1,
                            'v2/copy/mix-trader/config-query-followers': 2,
                            'v2/copy/mix-follower/query-current-orders': 2,
                            'v2/copy/mix-follower/query-history-orders': 1,
                            'v2/copy/mix-follower/query-settings': 2,
                            'v2/copy/mix-follower/query-traders': 2,
                            'v2/copy/mix-follower/query-quantity-limit': 2,
                            'v2/copy/mix-broker/query-traders': 2,
                            'v2/copy/mix-broker/query-history-traces': 2,
                            'v2/copy/mix-broker/query-current-traces': 2,
                            'v2/copy/spot-trader/profit-summarys': 2,
                            'v2/copy/spot-trader/profit-history-details': 2,
                            'v2/copy/spot-trader/profit-details': 2,
                            'v2/copy/spot-trader/order-total-detail': 2,
                            'v2/copy/spot-trader/order-history-track': 2,
                            'v2/copy/spot-trader/order-current-track': 2,
                            'v2/copy/spot-trader/config-query-settings': 2,
                            'v2/copy/spot-trader/config-query-followers': 2,
                            'v2/copy/spot-follower/query-traders': 2,
                            'v2/copy/spot-follower/query-trader-symbols': 2,
                            'v2/copy/spot-follower/query-settings': 2,
                            'v2/copy/spot-follower/query-history-orders': 2,
                            'v2/copy/spot-follower/query-current-orders': 2,
                        },
                        'post': {
                            'v2/copy/mix-trader/order-modify-tpsl': 2,
                            'v2/copy/mix-trader/order-close-positions': 2,
                            'v2/copy/mix-trader/config-setting-symbols': 2,
                            'v2/copy/mix-trader/config-setting-base': 2,
                            'v2/copy/mix-trader/config-remove-follower': 2,
                            'v2/copy/mix-follower/setting-tpsl': 1,
                            'v2/copy/mix-follower/settings': 2,
                            'v2/copy/mix-follower/close-positions': 2,
                            'v2/copy/mix-follower/cancel-trader': 4,
                            'v2/copy/spot-trader/order-modify-tpsl': 2,
                            'v2/copy/spot-trader/order-close-tracking': 2,
                            'v2/copy/spot-trader/config-setting-symbols': 2,
                            'v2/copy/spot-trader/config-remove-follower': 2,
                            'v2/copy/spot-follower/stop-order': 2,
                            'v2/copy/spot-follower/settings': 2,
                            'v2/copy/spot-follower/setting-tpsl': 2,
                            'v2/copy/spot-follower/order-close-tracking': 2,
                            'v2/copy/spot-follower/cancel-trader': 2,
                        },
                    },
                    'tax': {
                        'get': {
                            'v2/tax/spot-record': 20,
                            'v2/tax/future-record': 20,
                            'v2/tax/margin-record': 20,
                            'v2/tax/p2p-record': 20,
                        },
                    },
                    'convert': {
                        'get': {
                            'v2/convert/currencies': 2,
                            'v2/convert/quoted-price': 2,
                            'v2/convert/convert-record': 2,
                        },
                        'post': {
                            'v2/convert/trade': 2,
                        },
                    },
                    'earn': {
                        'get': {
                            'v2/earn/savings/product': 2,
                            'v2/earn/savings/account': 2,
                            'v2/earn/savings/assets': 2,
                            'v2/earn/savings/records': 2,
                            'v2/earn/savings/subscribe-info': 2,
                            'v2/earn/savings/subscribe-result': 2,
                            'v2/earn/savings/redeem-result': 2,
                            'v2/earn/sharkfin/product': 2,
                            'v2/earn/sharkfin/account': 2,
                            'v2/earn/sharkfin/assets': 2,
                            'v2/earn/sharkfin/records': 2,
                            'v2/earn/sharkfin/subscribe-info': 2,
                            'v2/earn/sharkfin/subscribe-result': 4,
                            'v2/earn/loan/ongoing-orders': 2,
                            'v2/earn/loan/repay-history': 2,
                            'v2/earn/loan/revise-history': 2,
                            'v2/earn/loan/borrow-history': 2,
                            'v2/earn/loan/debts': 2,
                            'v2/earn/loan/reduces': 2,
                        },
                        'post': {
                            'v2/earn/savings/subscribe': 2,
                            'v2/earn/savings/redeem': 2,
                            'v2/earn/sharkfin/subscribe': 2,
                            'v2/earn/loan/borrow': 2,
                            'v2/earn/loan/repay': 2,
                            'v2/earn/loan/revise-pledge': 2,
                        },
                    },
                    'common': {
                        'get': {
                            'v2/common/trade-rate': 2,
                        },
                    },
                },
            },
            'fees': {
                'spot': {
                    'taker': this.parseNumber('0.002'),
                    'maker': this.parseNumber('0.002'),
                },
                'swap': {
                    'taker': this.parseNumber('0.0006'),
                    'maker': this.parseNumber('0.0004'),
                },
            },
            'requiredCredentials': {
                'apiKey': true,
                'secret': true,
                'password': true,
            },
            'exceptions': {
                // http error codes
                // 400 Bad Request — Invalid request format
                // 401 Unauthorized — Invalid API Key
                // 403 Forbidden — You do not have access to the requested resource
                // 404 Not Found
                // 500 Internal Server Error — We had a problem with our server
                'exact': {
                    '1': InternalError,
                    // undocumented
                    'failure to get a peer from the ring-balancer': ExchangeNotAvailableError,
                    '4010': ExchangeInvalidPermissionsError,
                    // common
                    // '0': ExchangeError, // 200 successful,when the order placement / cancellation / operation is successful
                    '4001': InternalError,
                    '4002': InternalError,
                    // --------------------------------------------------------
                    '30001': AuthenticationError,
                    '30002': AuthenticationError,
                    '30003': AuthenticationError,
                    '30004': AuthenticationError,
                    '30005': ExchangeInvalidNonceError,
                    '30006': AuthenticationError,
                    '30007': InternalError,
                    '30008': ExchangeRequestExpiredError,
                    '30009': InternalError,
                    '30010': AuthenticationError,
                    '30011': ExchangeInvalidPermissionsError,
                    '30012': AuthenticationError,
                    '30013': AuthenticationError,
                    '30014': ExchangeDDosProtectionError,
                    '30015': AuthenticationError,
                    '30016': ExchangeInvalidPermissionsError,
                    '30017': AuthenticationError,
                    '30018': AuthenticationError,
                    '30019': ExchangeNotAvailableError,
                    '30020': InternalError,
                    '30021': InternalError,
                    '30022': ExchangeInvalidPermissionsError,
                    '30023': InvalidParamsError,
                    '30024': InvalidParamsError,
                    '30025': InvalidParamsError,
                    '30026': ExchangeDDosProtectionError,
                    '30027': AuthenticationError,
                    '30028': ExchangeInvalidPermissionsError,
                    '30029': ExchangeAccountSuspendedError,
                    '30030': InternalError,
                    '30031': ExchangeUnknownAssetError,
                    '30032': MarketNotFoundError,
                    '30033': InternalError,
                    '30034': InternalError,
                    '30035': InternalError,
                    '30036': InternalError,
                    '30037': ExchangeNotAvailableError,
                    // '30038': AuthenticationError, // { "code": 30038, "message": "user does not exist" }
                    '30038': ExchangeUnderMaintenanceError,
                    // futures
                    '32001': ExchangeAccountSuspendedError,
                    '32002': ExchangeInvalidPermissionsError,
                    '32003': TradeError,
                    '32004': TradeError,
                    '32005': InvalidParamsError,
                    '32006': InvalidParamsError,
                    '32007': InvalidParamsError,
                    '32008': TradeError,
                    '32009': TradeError,
                    '32010': TradeError,
                    '32011': TradeError,
                    '32012': TradeError,
                    '32013': TradeError,
                    '32014': TradeError,
                    '32015': TradeError,
                    '32016': TradeError,
                    '32017': TradeError,
                    '32018': InvalidParamsError,
                    '32019': InvalidParamsError,
                    '32020': InvalidParamsError,
                    '32021': TradeError,
                    '32022': ExchangeNotAllowedMethodError,
                    '32023': InternalError,
                    '32024': TradeError,
                    '32025': TradeError,
                    '32026': ExchangeInvalidPermissionsError,
                    '32027': TradeError,
                    '32028': ExchangeAccountSuspendedError,
                    '32029': TradeError,
                    '32030': TradeError,
                    '32031': InvalidParamsError,
                    '32038': AuthenticationError,
                    '32040': ExchangeError,
                    '32044': TradeError,
                    '32045': ExchangeError,
                    '32046': TradeError,
                    '32047': InternalError,
                    '32048': TradeError,
                    '32049': ExchangeError,
                    '32050': TradeError,
                    '32051': TradeError,
                    '32052': ExchangeError,
                    '32053': ExchangeError,
                    '32057': ExchangeError,
                    '32054': ExchangeError,
                    '32055': TradeError,
                    '32056': ExchangeError,
                    '32058': ExchangeError,
                    '32059': TradeError,
                    '32060': InvalidParamsError,
                    '32061': InvalidParamsError,
                    '32062': InvalidParamsError,
                    '32063': InvalidParamsError,
                    '32064': ExchangeError,
                    '32065': ExchangeError,
                    '32066': ExchangeError,
                    '32067': ExchangeError,
                    '32068': ExchangeError,
                    '32069': ExchangeError,
                    '32070': ExchangeError,
                    '32071': ExchangeError,
                    '32072': ExchangeError,
                    '32073': ExchangeError,
                    '32074': ExchangeError,
                    '32075': ExchangeError,
                    '32076': ExchangeError,
                    '32077': ExchangeError,
                    '32078': ExchangeError,
                    '32079': ExchangeError,
                    '32080': ExchangeError,
                    '32083': ExchangeError,
                    // token and margin trading
                    '33001': ExchangeInvalidPermissionsError,
                    '33002': ExchangeAccountSuspendedError,
                    '33003': InsufficientBalanceError,
                    '33004': ExchangeError,
                    '33005': ExchangeError,
                    '33006': ExchangeError,
                    '33007': ExchangeError,
                    '33008': InsufficientBalanceError,
                    '33009': ExchangeError,
                    '33010': ExchangeError,
                    '33011': ExchangeError,
                    '33012': ExchangeError,
                    '33013': TradeError,
                    '33014': OrderNotFoundError,
                    '33015': TradeError,
                    '33016': ExchangeError,
                    '33017': InsufficientBalanceError,
                    '33018': ExchangeError,
                    '33020': ExchangeError,
                    '33021': TradeError,
                    '33022': TradeError,
                    '33023': ExchangeError,
                    '33024': InvalidParamsError,
                    '33025': InvalidParamsError,
                    '33026': ExchangeError,
                    '33027': TradeError,
                    '33028': InvalidParamsError,
                    '33029': InvalidParamsError,
                    '33034': ExchangeError,
                    '33035': ExchangeError,
                    '33036': ExchangeError,
                    '33037': ExchangeError,
                    '33038': ExchangeError,
                    '33039': ExchangeError,
                    '33040': ExchangeError,
                    '33041': ExchangeError,
                    '33042': ExchangeError,
                    '33043': ExchangeError,
                    '33044': ExchangeError,
                    '33045': ExchangeError,
                    '33046': ExchangeError,
                    '33047': ExchangeError,
                    '33048': ExchangeError,
                    '33049': ExchangeError,
                    '33050': ExchangeError,
                    '33051': ExchangeError,
                    '33059': InvalidParamsError,
                    '33060': InvalidParamsError,
                    '33061': ExchangeError,
                    '33062': ExchangeError,
                    '33063': ExchangeError,
                    '33064': ExchangeError,
                    '33065': ExchangeError,
                    // account
                    '21009': ExchangeError,
                    '34001': ExchangeInvalidPermissionsError,
                    '34002': InvalidAddressError,
                    '34003': WithdrawalError,
                    '34004': WithdrawalError,
                    '34005': WithdrawalError,
                    '34006': UnderWithdrawMinimumError,
                    '34007': OverWithdrawMaximumError,
                    '34008': InsufficientBalanceError,
                    '34009': WithdrawalError,
                    '34010': ExchangeTransferError,
                    '34011': ExchangeError,
                    '34012': WithdrawalError,
                    '34013': ExchangeTransferError,
                    '34014': ExchangeTransferError,
                    '34015': ExchangeError,
                    '34016': ExchangeInvalidPermissionsError,
                    '34017': ExchangeAccountSuspendedError,
                    '34018': AuthenticationError,
                    '34019': ExchangeInvalidPermissionsError,
                    '34020': ExchangeInvalidPermissionsError,
                    '34021': InvalidAddressError,
                    '34022': ExchangeError,
                    '34023': ExchangeInvalidPermissionsError,
                    '34026': ExchangeError,
                    '34036': ExchangeError,
                    '34037': ExchangeError,
                    '34038': ExchangeError,
                    '34039': ExchangeError,
                    // swap
                    '35001': ExchangeError,
                    '35002': ExchangeError,
                    '35003': ExchangeError,
                    '35004': ExchangeError,
                    '35005': AuthenticationError,
                    '35008': InvalidParamsError,
                    '35010': InvalidParamsError,
                    '35012': InvalidParamsError,
                    '35014': InvalidParamsError,
                    '35015': InvalidParamsError,
                    '35017': ExchangeError,
                    '35019': InvalidParamsError,
                    '35020': InvalidParamsError,
                    '35021': InvalidParamsError,
                    '35022': ExchangeError,
                    '35024': ExchangeError,
                    '35025': InsufficientBalanceError,
                    '35026': ExchangeError,
                    '35029': OrderNotFoundError,
                    '35030': InvalidParamsError,
                    '35031': InvalidParamsError,
                    '35032': ExchangeError,
                    '35037': ExchangeError,
                    '35039': ExchangeError,
                    '35040': InvalidParamsError,
                    '35044': ExchangeError,
                    '35046': InsufficientBalanceError,
                    '35047': InsufficientBalanceError,
                    '35048': ExchangeError,
                    '35049': InvalidParamsError,
                    '35050': InvalidParamsError,
                    '35052': InsufficientBalanceError,
                    '35053': ExchangeError,
                    '35055': InsufficientBalanceError,
                    '35057': ExchangeError,
                    '35058': ExchangeError,
                    '35059': InvalidParamsError,
                    '35060': InvalidParamsError,
                    '35061': InvalidParamsError,
                    '35062': InvalidParamsError,
                    '35063': InvalidParamsError,
                    '35064': InvalidParamsError,
                    '35066': TradeError,
                    '35067': TradeError,
                    '35068': TradeError,
                    '35069': TradeError,
                    '35070': TradeError,
                    '35071': InvalidParamsError,
                    '35072': TradeError,
                    '35073': TradeError,
                    '35074': TradeError,
                    '35075': TradeError,
                    '35076': TradeError,
                    '35077': TradeError,
                    '35078': InvalidParamsError,
                    '35079': TradeError,
                    '35080': TradeError,
                    '35081': TradeError,
                    '35082': TradeError,
                    '35083': TradeError,
                    '35084': InvalidParamsError,
                    '35085': InvalidParamsError,
                    '35086': InvalidParamsError,
                    '35087': InvalidParamsError,
                    '35088': InvalidParamsError,
                    '35089': InvalidParamsError,
                    '35090': ExchangeError,
                    '35091': ExchangeError,
                    '35092': ExchangeError,
                    '35093': ExchangeError,
                    '35094': ExchangeError,
                    '35095': ExchangeError,
                    '35096': ExchangeError,
                    '35097': ExchangeError,
                    '35098': ExchangeError,
                    '35099': ExchangeError,
                    // option
                    '36001': ExchangeError,
                    '36002': ExchangeError,
                    '36005': ExchangeError,
                    '36101': AuthenticationError,
                    '36102': ExchangeInvalidPermissionsError,
                    '36103': ExchangeAccountSuspendedError,
                    '36104': ExchangeInvalidPermissionsError,
                    '36105': ExchangeInvalidPermissionsError,
                    '36106': ExchangeAccountSuspendedError,
                    '36107': ExchangeInvalidPermissionsError,
                    '36108': InsufficientBalanceError,
                    '36109': ExchangeInvalidPermissionsError,
                    '36201': ExchangeInvalidPermissionsError,
                    '36202': ExchangeInvalidPermissionsError,
                    '36203': InvalidParamsError,
                    '36204': ExchangeError,
                    '36205': ExchangeError,
                    '36206': InvalidParamsError,
                    '36207': InvalidParamsError,
                    '36208': InvalidParamsError,
                    '36209': InvalidParamsError,
                    '36210': InvalidParamsError,
                    '36211': TradeError,
                    '36212': TradeError,
                    '36213': TradeError,
                    '36214': ExchangeError,
                    '36216': OrderNotFoundError,
                    '36217': TradeError,
                    '36218': TradeError,
                    '36219': TradeError,
                    '36220': TradeError,
                    '36221': InvalidParamsError,
                    '36222': InvalidParamsError,
                    '36223': InvalidParamsError,
                    '36224': InvalidParamsError,
                    '36225': TradeError,
                    '36226': TradeError,
                    '36227': TradeError,
                    '36228': TradeError,
                    '36229': TradeError,
                    '36230': TradeError,
                    // --------------------------------------------------------
                    // swap
                    '400': ExchangeError,
                    '401': AuthenticationError,
                    '403': ExchangeInvalidPermissionsError,
                    '404': InternalError,
                    '405': InternalError,
                    '415': InternalError,
                    '429': ExchangeDDosProtectionError,
                    '500': ExchangeNotAvailableError,
                    '1001': ExchangeRateLimitExceededError,
                    '1002': ExchangeError,
                    '1003': ExchangeError,
                    // '00000': ExchangeError, // success
                    '40001': AuthenticationError,
                    '40002': AuthenticationError,
                    '40003': AuthenticationError,
                    '40004': ExchangeInvalidNonceError,
                    '40005': ExchangeInvalidNonceError,
                    '40006': AuthenticationError,
                    '40007': InternalError,
                    '40008': ExchangeInvalidNonceError,
                    '40009': AuthenticationError,
                    '40010': AuthenticationError,
                    '40011': AuthenticationError,
                    '40012': AuthenticationError,
                    '40013': ExchangeError,
                    '40014': ExchangeInvalidPermissionsError,
                    '40015': ExchangeError,
                    '40016': ExchangeInvalidPermissionsError,
                    '40017': ExchangeError,
                    '40018': ExchangeInvalidPermissionsError,
                    '40019': ExchangeError,
                    '40031': ExchangeAccountSuspendedError,
                    '40037': InvalidCredentialsError,
                    '40102': ExchangeError,
                    '40103': ExchangeError,
                    '40104': ExchangeError,
                    '40105': ExchangeError,
                    '40106': ExchangeError,
                    '40107': ExchangeError,
                    '40108': InvalidParamsError,
                    '40109': OrderNotFoundError,
                    '40200': ExchangeUnderMaintenanceError,
                    '40201': InvalidParamsError,
                    '40202': ExchangeError,
                    '40203': InvalidParamsError,
                    '40204': InvalidParamsError,
                    '40205': InvalidParamsError,
                    '40206': InvalidParamsError,
                    '40207': InvalidParamsError,
                    '40208': InvalidParamsError,
                    '40209': InvalidParamsError,
                    '40300': ExchangeError,
                    '40301': ExchangeInvalidPermissionsError,
                    '40302': InvalidParamsError,
                    '40303': ExchangeError,
                    '40304': InvalidParamsError,
                    '40305': InvalidParamsError,
                    '40306': ExchangeError,
                    '40308': ExchangeUnderMaintenanceError,
                    '40309': ExchangeError,
                    '40400': ExchangeError,
                    '40401': ExchangeError,
                    '40402': InvalidParamsError,
                    '40403': InvalidParamsError,
                    '40404': InvalidParamsError,
                    '40405': InvalidParamsError,
                    '40406': InvalidParamsError,
                    '40407': ExchangeError,
                    '40408': ExchangeError,
                    '40409': ExchangeError,
                    '40500': InvalidParamsError,
                    '40501': ExchangeError,
                    '40502': ExchangeError,
                    '40503': ExchangeError,
                    '40504': ExchangeError,
                    '40505': ExchangeError,
                    '40506': AuthenticationError,
                    '40507': AuthenticationError,
                    '40508': ExchangeError,
                    '40509': ExchangeError,
                    '40600': ExchangeError,
                    '40601': ExchangeError,
                    '40602': ExchangeError,
                    '40603': ExchangeError,
                    '40604': ExchangeNotAvailableError,
                    '40605': ExchangeError,
                    '40606': ExchangeError,
                    '40607': ExchangeError,
                    '40608': ExchangeError,
                    '40609': ExchangeError,
                    '40700': InvalidParamsError,
                    '40701': ExchangeError,
                    '40702': ExchangeError,
                    '40703': ExchangeError,
                    '40704': ExchangeError,
                    '40705': InvalidParamsError,
                    '40706': InvalidParamsError,
                    '40707': InvalidParamsError,
                    '40708': InvalidParamsError,
                    '40709': ExchangeError,
                    '40710': ExchangeError,
                    '40711': InsufficientBalanceError,
                    '40712': InsufficientBalanceError,
                    '40713': ExchangeError,
                    '40714': ExchangeError,
                    '40768': OrderNotFoundError,
                    '41114': ExchangeUnderMaintenanceError,
                    '43011': InvalidParamsError,
                    '43025': TradeError,
                    '43115': ExchangeUnderMaintenanceError,
                    '45110': InvalidParamsError,
                    // spot
                    'invalid sign': AuthenticationError,
                    'invalid currency': InvalidParamsError,
                    'invalid symbol': InvalidParamsError,
                    'invalid period': InvalidParamsError,
                    'invalid user': ExchangeError,
                    'invalid amount': InvalidParamsError,
                    'invalid type': InvalidParamsError,
                    'invalid orderId': InvalidParamsError,
                    'invalid record': ExchangeError,
                    'invalid accountId': InvalidParamsError,
                    'invalid address': InvalidAddressError,
                    'accesskey not null': AuthenticationError,
                    'illegal accesskey': AuthenticationError,
                    'sign not null': AuthenticationError,
                    'req_time is too much difference from server time': ExchangeInvalidNonceError,
                    'permissions not right': ExchangeInvalidPermissionsError,
                    'illegal sign invalid': AuthenticationError,
                    'user locked': ExchangeAccountSuspendedError,
                    'Request Frequency Is Too High': ExchangeRateLimitExceededError,
                    'more than a daily rate of cash': ExchangeError,
                    'more than the maximum daily withdrawal amount': WithdrawalError,
                    'need to bind email or mobile': ExchangeError,
                    'user forbid': ExchangeInvalidPermissionsError,
                    'User Prohibited Cash Withdrawal': ExchangeInvalidPermissionsError,
                    'Cash Withdrawal Is Less Than The Minimum Value': UnderWithdrawMinimumError,
                    'Cash Withdrawal Is More Than The Maximum Value': OverWithdrawMaximumError,
                    'the account with in 24 hours ban coin': ExchangeInvalidPermissionsError,
                    'order cancel fail': TradeError,
                    'base symbol error': InvalidParamsError,
                    'base date error': ExchangeError,
                    'api signature not valid': AuthenticationError,
                    'gateway internal error': ExchangeError,
                    'audit failed': ExchangeError,
                    'order queryorder invalid': TradeError,
                    'market no need price': InvalidParamsError,
                    'limit need price': InvalidParamsError,
                    'userid not equal to account_id': ExchangeError,
                    'your balance is low': InsufficientBalanceError,
                    'address invalid cointype': ExchangeError,
                    'system exception': ExchangeError,
                    '50003': ExchangeError,
                    '50004': MarketError,
                    '50006': ExchangeInvalidPermissionsError,
                    '50007': ExchangeInvalidPermissionsError,
                    '50008': ExchangeRequestExpiredError,
                    '50009': ExchangeRateLimitExceededError,
                    '50010': ExchangeError,
                    '50014': InvalidParamsError,
                    '50015': InvalidParamsError,
                    '50016': InvalidParamsError,
                    '50017': InvalidParamsError,
                    '50018': InvalidParamsError,
                    '50019': InvalidParamsError,
                    '50020': InsufficientBalanceError,
                    '50021': InvalidParamsError,
                    '50026': InvalidParamsError,
                    'invalid order query time': ExchangeError,
                    'invalid start time': InvalidParamsError,
                    'invalid end time': InvalidParamsError,
                    '20003': ExchangeError,
                    '01001': ExchangeError,
                    '43111': WhitelistCheckFailedError,
                    '43114': UnderWithdrawMinimumError, //  43114: Withdrawal is less than the minimum withdrawal count less than min withdraw amount
                },
                'broad': {
                    'invalid size, valid range': ExchangeError,
                },
                'messages': {
                    'less than the minimum amount': SdkErrorMessages.ORDER_UNDER_MINIMUM,
                },
            },
            'precisionMode': TICK_SIZE,
            'commonCurrencies': {
                'JADE': 'Jade Protocol',
            },
            'options': {
                'timeDifference': 0,
                'timeframes': {
                    'spot': {
                        '1m': '1min',
                        '5m': '5min',
                        '15m': '15min',
                        '30m': '30min',
                        '1h': '1h',
                        '4h': '4h',
                        '6h': '6Hutc',
                        '12h': '12Hutc',
                        '1d': '1Dutc',
                        '3d': '3Dutc',
                        '1w': '1Wutc',
                        '1M': '1Mutc',
                    },
                    'swap': {
                        '1m': '1m',
                        '3m': '3m',
                        '5m': '5m',
                        '15m': '15m',
                        '30m': '30m',
                        '1h': '1H',
                        '2h': '2H',
                        '4h': '4H',
                        '6h': '6Hutc',
                        '12h': '12Hutc',
                        '1d': '1Dutc',
                        '3d': '3Dutc',
                        '1w': '1Wutc',
                        '1M': '1Mutc',
                    },
                },
                'fetchMarkets': [
                    'spot',
                    'swap', // there is future markets but they use the same endpoints as swap
                ],
                'defaultType': 'spot',
                'defaultSubType': 'linear',
                'createMarketBuyOrderRequiresPrice': true,
                'broker': 'p4sve',
                'withdraw': {
                    'fillResponseFromRequest': true,
                },
                'fetchOHLCV': {
                    'spot': {
                        'method': 'publicSpotGetV2SpotMarketCandles', // or publicSpotGetV2SpotMarketHistoryCandles
                    },
                    'swap': {
                        'method': 'publicMixGetV2MixMarketCandles', // or publicMixGetV2MixMarketHistoryCandles or publicMixGetV2MixMarketHistoryIndexCandles or publicMixGetV2MixMarketHistoryMarkCandles
                    },
                },
                'fetchTrades': {
                    'spot': {
                        'method': 'publicSpotGetV2SpotMarketFillsHistory', // or publicSpotGetV2SpotMarketFills
                    },
                    'swap': {
                        'method': 'publicMixGetV2MixMarketFillsHistory', // or publicMixGetV2MixMarketFills
                    },
                },
                'accountsByType': {
                    'spot': 'spot',
                    'cross': 'crossed_margin',
                    'isolated': 'isolated_margin',
                    'swap': 'usdt_futures',
                    'usdc_swap': 'usdc_futures',
                    'future': 'coin_futures',
                    'p2p': 'p2p',
                },
                'accountsById': {
                    'spot': 'spot',
                    'crossed_margin': 'cross',
                    'isolated_margin': 'isolated',
                    'usdt_futures': 'swap',
                    'usdc_futures': 'usdc_swap',
                    'coin_futures': 'future',
                    'p2p': 'p2p',
                },
                'sandboxMode': false,
                // network codes indexed by network IDs
                'networksById': {
                    'TRC20': 'TRX',
                    'TRC10': 'TRX',
                    'ERC20': 'ETH',
                    'BRC20': 'BTC',
                    'BEP20': 'BSC',
                    'POLYGON': 'Polygon',
                },
                'fetchPositions': {
                    'method': 'privateMixGetV2MixPositionAllPosition', // or privateMixGetV2MixPositionHistoryPosition
                },
                'defaultTimeInForce': 'GTC', // 'GTC' = Good To Cancel (default), 'IOC' = Immediate Or Cancel
            },
        });
    }
    isUsingForcedProxy(params = {}, api = 'public') {
        if (api[0] === 'private') {
            return true;
        }
        return false;
    }
    nonce() {
        return this.milliseconds() - this.options['timeDifference'];
    }
    convertSymbolForSandbox(symbol) {
        if (symbol.startsWith('S')) {
            // handle using the exchange specified sandbox symbols
            return symbol;
        }
        let convertedSymbol = undefined;
        if (symbol.indexOf('/') > -1) {
            if (symbol.indexOf(':') === -1) {
                throw new MarketTypeNotSupportedError('unkwnown').overwriteMessage('Sandbox supports swap and future markets only').exchange(this.id);
            }
            const splitBase = symbol.split('/');
            const previousBase = this.safeString(splitBase, 0);
            const previousQuoteSettleExpiry = this.safeString(splitBase, 1);
            const splitQuote = previousQuoteSettleExpiry.split(':');
            const previousQuote = this.safeString(splitQuote, 0);
            const previousSettleExpiry = this.safeString(splitQuote, 1);
            const splitSettle = previousSettleExpiry.split('-');
            const previousSettle = this.safeString(splitSettle, 0);
            const expiry = this.safeString(splitSettle, 1);
            convertedSymbol = 'S' + previousBase + '/S' + previousQuote + ':S' + previousSettle;
            if (expiry !== undefined) {
                convertedSymbol = convertedSymbol + '-' + expiry;
            }
        }
        else {
            // handle using a market id instead of a unified symbol
            const base = symbol.slice(0, 3);
            const remaining = symbol.slice(3);
            convertedSymbol = 'S' + base + 'S' + remaining;
        }
        return convertedSymbol;
    }
    async fetchStatus(params = {}) {
        /**
         * @method
         * @name bitget#fetchStatus
         * @description the latest known information on the availability of the exchange API - bitget does not have such an endpoint, so we fetch the server time to see if the exchange is able to respond
         * @see https://www.bitget.com/api-doc/common/public/Get-Server-Time
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a [status structure]{@link https://docs.ccxt.com/#/?id=exchange-status-structure}
         */
        const response = await this.publicCommonGetV2PublicTime(params);
        // {
        //     "code": "00000",
        //     "msg": "success",
        //     "requestTime": 1688008631614,
        //     "data": {
        //         "serverTime": "1688008631614"
        //     }
        // }
        const data = this.safeValue(response, 'data', {});
        const code = this.safeString(response, 'code');
        const status = (code === '00000') ? 'ok' : 'maintenance';
        const serverTime = this.safeInteger(data, 'serverTime');
        return {
            'status': status,
            'updated': serverTime,
            'eta': undefined,
            'url': undefined,
            'info': response,
        };
    }
    handleProductTypeAndParams(market = undefined, params = {}) {
        let subType = undefined;
        [subType, params] = this.handleSubTypeAndParams('handleProductTypeAndParams', undefined, params);
        let defaultProductType = undefined;
        if ((subType !== undefined) && (market === undefined)) {
            // set default only if subType is defined and market is not defined, since there is also USDC productTypes which are also linear
            const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
            if (sandboxMode) {
                defaultProductType = (subType === 'linear') ? this.SUSDT_FUTURES : this.SCOIN_FUTURES;
            }
            else {
                defaultProductType = (subType === 'linear') ? this.USDT_FUTURES : this.COIN_FUTURES;
            }
        }
        let productType = this.safeString(params, 'productType', defaultProductType);
        if ((productType === undefined) && (market !== undefined)) {
            const settle = market['settle'];
            if (this.USDT_SETTLES.includes(settle)) {
                productType = this.USDT_FUTURES;
            }
            else if (this.USDC_SETTLES.includes(settle)) {
                productType = this.USDC_FUTURES;
            }
            else if (this.SUSDT_SETTLES.includes(settle)) {
                productType = this.SUSDT_FUTURES;
            }
            else if (this.SUSDC_SETTLES.includes(settle)) {
                productType = this.SUSDC_FUTURES;
            }
            else if (this.SCOIN_FUTURES.includes(settle)) {
                productType = this.SCOIN_FUTURES;
            }
            else {
                productType = this.COIN_FUTURES;
            }
        }
        if (productType === undefined) {
            throw new InvalidParamsError('Requires a productType param, one of "USDT-FUTURES", "USDC-FUTURES", "COIN-FUTURES", "SUSDT-FUTURES", "SUSDC-FUTURES" or "SCOIN-FUTURES"')
                .exchange(this.id);
        }
        params = this.omit(params, 'productType');
        return [productType, params];
    }
    async fetchTime(params = {}) {
        /**
         * @method
         * @name bitget#fetchTime
         * @description fetches the current integer timestamp in milliseconds from the exchange server
         * @see https://www.bitget.com/api-doc/common/public/Get-Server-Time
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {int} the current integer timestamp in milliseconds from the exchange server
         */
        const response = await this.publicCommonGetV2PublicTime(params);
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700111073740,
        //         "data": {
        //             "serverTime": "1700111073740"
        //         }
        //     }
        //
        const data = this.safeValue(response, 'data', {});
        return this.safeInteger(data, 'serverTime');
    }
    getFutureMarginCoin(productType) {
        if (productType === this.COIN_FUTURES) {
            const otherProductTypes = [...this.SCOIN_SETTLES, ...this.USDT_SETTLES, ...this.USDC_SETTLES, ...this.SUSDT_SETTLES, ...this.SUSDC_SETTLES];
            return [...new Set(Object.values(this.markets).map((market) => market['settle']).filter((settle) => settle !== undefined && !otherProductTypes.includes(settle)))];
        }
        else if (productType === this.SCOIN_FUTURES) {
            return this.SCOIN_SETTLES;
        }
        else if (productType === this.USDC_FUTURES) {
            return this.USDC_SETTLES;
        }
        else if (productType === this.USDT_FUTURES) {
            return this.USDT_SETTLES;
        }
        else if (productType === this.SUSDT_FUTURES) {
            return this.SUSDT_SETTLES;
        }
        else if (productType === this.SUSDC_FUTURES) {
            return this.SUSDC_SETTLES;
        }
        throw new InvalidParamsError('Invalid productType, one of "USDT-FUTURES", "USDC-FUTURES", "COIN-FUTURES", "SUSDT-FUTURES", "SUSDC-FUTURES" or "SCOIN-FUTURES"');
    }
    async fetchMarkets(params = {}) {
        /**
         * @method
         * @name bitget#fetchMarkets
         * @description retrieves data on all markets for bitget
         * @see https://www.bitget.com/api-doc/spot/market/Get-Symbols
         * @see https://www.bitget.com/api-doc/contract/market/Get-All-Symbols-Contracts
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object[]} an array of objects representing market data
         */
        const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        let types = this.safeValue(this.options, 'fetchMarkets', ['spot', 'swap']);
        if (sandboxMode) {
            types = ['swap'];
        }
        let promises = [];
        for (let i = 0; i < types.length; i++) {
            const type = types[i];
            if (type === 'swap') {
                let subTypes = undefined;
                if (sandboxMode) {
                    // the following are simulated trading markets [ 'SUSDT-FUTURES', 'SCOIN-FUTURES', 'SUSDC-FUTURES' ];
                    subTypes = [this.SUSDT_FUTURES, this.SCOIN_FUTURES, this.SUSDC_FUTURES];
                }
                else {
                    subTypes = [this.USDT_FUTURES, this.COIN_FUTURES, this.USDC_FUTURES];
                }
                for (let j = 0; j < subTypes.length; j++) {
                    promises.push(this.fetchMarketsByType(type, this.extend(params, {
                        'productType': subTypes[j],
                    })));
                }
            }
            else {
                promises.push(this.fetchMarketsByType(types[i], params));
            }
        }
        promises = await Promise.all(promises);
        let result = promises[0];
        for (let i = 1; i < promises.length; i++) {
            result = this.arrayConcat(result, promises[i]);
        }
        return result;
    }
    parseMarket(market) {
        //
        // spot
        //
        //     {
        //         "symbol": "TRXUSDT",
        //         "baseCoin": "TRX",
        //         "quoteCoin": "USDT",
        //         "minTradeAmount": "0",
        //         "maxTradeAmount": "10000000000",
        //         "takerFeeRate": "0.002",
        //         "makerFeeRate": "0.002",
        //         "pricePrecision": "6",
        //         "quantityPrecision": "4",
        //         "quotePrecision": "6",
        //         "status": "online",
        //         "minTradeUSDT": "5",
        //         "buyLimitPriceRatio": "0.05",
        //         "sellLimitPriceRatio": "0.05"
        //     }
        //
        // swap and future
        //
        //     {
        //         "symbol": "BTCUSDT",
        //         "baseCoin": "BTC",
        //         "quoteCoin": "USDT",
        //         "buyLimitPriceRatio": "0.01",
        //         "sellLimitPriceRatio": "0.01",
        //         "feeRateUpRatio": "0.005",
        //         "makerFeeRate": "0.0002",
        //         "takerFeeRate": "0.0006",
        //         "openCostUpRatio": "0.01",
        //         "supportMarginCoins": ["USDT"],
        //         "minTradeNum": "0.001",
        //         "priceEndStep": "1",
        //         "volumePlace": "3",
        //         "pricePlace": "1",
        //         "sizeMultiplier": "0.001",
        //         "symbolType": "perpetual",
        //         "minTradeUSDT": "5",
        //         "maxSymbolOrderNum": "200",
        //         "maxProductOrderNum": "400",
        //         "maxPositionNum": "150",
        //         "symbolStatus": "normal",
        //         "offTime": "-1",
        //         "limitOpenTime": "-1",
        //         "deliveryTime": "",
        //         "deliveryStartTime": "",
        //         "deliveryPeriod": "",
        //         "launchTime": "",
        //         "fundInterval": "8",
        //         "minLever": "1",
        //         "maxLever": "125",
        //         "posLimit": "0.05",
        //         "maintainTime": ""
        //     }
        //
        const marketId = this.safeString(market, 'symbol');
        const quoteId = this.safeString(market, 'quoteCoin');
        const baseId = this.safeString(market, 'baseCoin');
        const quote = this.safeCurrencyCode(quoteId);
        const base = this.safeCurrencyCode(baseId);
        const supportMarginCoins = this.safeValue(market, 'supportMarginCoins', []);
        let settleId = undefined;
        if (this.inArray(baseId, supportMarginCoins)) {
            settleId = baseId;
        }
        else if (this.inArray(quoteId, supportMarginCoins)) {
            settleId = quoteId;
        }
        else {
            settleId = this.safeString(supportMarginCoins, 0);
        }
        const settle = this.safeCurrencyCode(settleId);
        let symbol = base + '/' + quote;
        let type = undefined;
        let swap = false;
        let spot = false;
        let future = false;
        let contract = false;
        let pricePrecision = undefined;
        let amountPrecision = undefined;
        let linear = undefined;
        let inverse = undefined;
        let expiry = undefined;
        let expiryDatetime = undefined;
        const symbolType = this.safeString(market, 'symbolType');
        if (symbolType === undefined) {
            type = 'spot';
            spot = true;
            pricePrecision = this.parseNumber(this.parsePrecision(this.safeString(market, 'pricePrecision')));
            amountPrecision = this.parseNumber(this.parsePrecision(this.safeString(market, 'quantityPrecision')));
        }
        else {
            if (symbolType === 'perpetual') {
                type = 'swap';
                swap = true;
                symbol = symbol + ':' + settle;
            }
            else if (symbolType === 'delivery') {
                expiry = this.safeInteger(market, 'deliveryTime');
                expiryDatetime = this.iso8601(expiry);
                const expiryParts = expiryDatetime.split('-');
                const yearPart = this.safeString(expiryParts, 0);
                const dayPart = this.safeString(expiryParts, 2);
                const year = yearPart.slice(2, 4);
                const month = this.safeString(expiryParts, 1);
                const day = dayPart.slice(0, 2);
                const expiryString = year + month + day;
                type = 'future';
                future = true;
                symbol = symbol + ':' + settle + '-' + expiryString;
            }
            contract = true;
            inverse = (base === settle);
            linear = !inverse;
            const priceDecimals = this.safeInteger(market, 'pricePlace');
            const amountDecimals = this.safeInteger(market, 'volumePlace');
            const priceStep = this.safeString(market, 'priceEndStep');
            const amountStep = this.safeString(market, 'minTradeNum');
            const precisePrice = new Precise(priceStep);
            precisePrice.decimals = Math.max(precisePrice.decimals, priceDecimals);
            precisePrice.reduce();
            const priceString = precisePrice.toString();
            pricePrecision = this.parseNumber(priceString);
            const preciseAmount = new Precise(amountStep);
            preciseAmount.decimals = Math.max(preciseAmount.decimals, amountDecimals);
            preciseAmount.reduce();
            const amountString = preciseAmount.toString();
            amountPrecision = this.parseNumber(amountString);
        }
        const status = this.safeString2(market, 'status', 'symbolStatus');
        let active = undefined;
        if (status !== undefined) {
            active = ((status === 'online') || (status === 'normal'));
        }
        let minCost = undefined;
        if (quote === 'USDT') {
            minCost = this.safeNumber(market, 'minTradeUSDT');
        }
        const contractSize = contract ? 1 : undefined;
        return {
            'id': marketId,
            'symbol': symbol,
            'base': base,
            'quote': quote,
            'settle': settle,
            'baseId': baseId,
            'quoteId': quoteId,
            'settleId': settleId,
            'type': type,
            'spot': spot,
            'margin': undefined,
            'swap': swap,
            'future': future,
            'option': false,
            'active': active,
            'contract': contract,
            'linear': linear,
            'inverse': inverse,
            'taker': this.safeNumber(market, 'takerFeeRate'),
            'maker': this.safeNumber(market, 'makerFeeRate'),
            'contractSize': contractSize,
            'expiry': expiry,
            'expiryDatetime': expiryDatetime,
            'strike': undefined,
            'optionType': undefined,
            'precision': {
                'amount': amountPrecision,
                'price': pricePrecision,
            },
            'limits': {
                'leverage': {
                    'min': this.safeNumber(market, 'minLever'),
                    'max': this.safeNumber(market, 'maxLever'),
                },
                'amount': {
                    'min': this.safeNumber2(market, 'minTradeNum', 'minTradeAmount'),
                    'max': this.safeNumber(market, 'maxTradeAmount'),
                },
                'price': {
                    'min': undefined,
                    'max': undefined,
                },
                'cost': {
                    'min': minCost,
                    'max': undefined,
                },
            },
            'created': this.safeInteger(market, 'launchTime'),
            'info': market,
        };
    }
    async fetchMarketsByType(type, params = {}) {
        let response = undefined;
        if (type === 'spot') {
            response = await this.publicSpotGetV2SpotPublicSymbols(params);
        }
        else if ((type === 'swap') || (type === 'future')) {
            response = await this.publicMixGetV2MixMarketContracts(params);
        }
        else {
            throw new MarketTypeNotSupportedError(type).exchange(this.id);
        }
        //
        // spot
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700102364653,
        //         "data": [
        //             {
        //                 "symbol": "TRXUSDT",
        //                 "baseCoin": "TRX",
        //                 "quoteCoin": "USDT",
        //                 "minTradeAmount": "0",
        //                 "maxTradeAmount": "10000000000",
        //                 "takerFeeRate": "0.002",
        //                 "makerFeeRate": "0.002",
        //                 "pricePrecision": "6",
        //                 "quantityPrecision": "4",
        //                 "quotePrecision": "6",
        //                 "status": "online",
        //                 "minTradeUSDT": "5",
        //                 "buyLimitPriceRatio": "0.05",
        //                 "sellLimitPriceRatio": "0.05"
        //             },
        //         ]
        //     }
        //
        // swap and future
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700102364709,
        //         "data": [
        //             {
        //                 "symbol": "BTCUSDT",
        //                 "baseCoin": "BTC",
        //                 "quoteCoin": "USDT",
        //                 "buyLimitPriceRatio": "0.01",
        //                 "sellLimitPriceRatio": "0.01",
        //                 "feeRateUpRatio": "0.005",
        //                 "makerFeeRate": "0.0002",
        //                 "takerFeeRate": "0.0006",
        //                 "openCostUpRatio": "0.01",
        //                 "supportMarginCoins": ["USDT"],
        //                 "minTradeNum": "0.001",
        //                 "priceEndStep": "1",
        //                 "volumePlace": "3",
        //                 "pricePlace": "1",
        //                 "sizeMultiplier": "0.001",
        //                 "symbolType": "perpetual",
        //                 "minTradeUSDT": "5",
        //                 "maxSymbolOrderNum": "200",
        //                 "maxProductOrderNum": "400",
        //                 "maxPositionNum": "150",
        //                 "symbolStatus": "normal",
        //                 "offTime": "-1",
        //                 "limitOpenTime": "-1",
        //                 "deliveryTime": "",
        //                 "deliveryStartTime": "",
        //                 "deliveryPeriod": "",
        //                 "launchTime": "",
        //                 "fundInterval": "8",
        //                 "minLever": "1",
        //                 "maxLever": "125",
        //                 "posLimit": "0.05",
        //                 "maintainTime": ""
        //             },
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        return this.parseMarkets(data);
    }
    networkCodeToId(networkCode, currencyCode) {
        const networkIdsByCurrencyAndNetworkCodes = this.safeValue(this.options, 'networkIdsByCurrencyAndNetworkCodes', {});
        const networkIdsByCodes = this.safeValue(networkIdsByCurrencyAndNetworkCodes, currencyCode, {});
        return this.safeString(networkIdsByCodes, networkCode);
    }
    async fetchCurrencies(params = {}) {
        /**
         * @method
         * @name bitget#fetchCurrencies
         * @description fetches all available currencies on an exchange
         * @see https://www.bitget.com/api-doc/spot/market/Get-Coin-List
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} an associative dictionary of currencies
         */
        const response = await this.publicSpotGetV2SpotPublicCoins(params);
        //
        //     {
        //         "code": "00000",
        //         "data": [
        //             {
        //                 "chains": [
        //                     {
        //                         "browserUrl": "https://blockchair.com/bitcoin/transaction/",
        //                         "chain": "BTC",
        //                         "depositConfirm": "1",
        //                         "extraWithdrawFee": "0",
        //                         "minDepositAmount": "0.0001",
        //                         "minWithdrawAmount": "0.005",
        //                         "needTag": "false",
        //                         "rechargeable": "true",
        //                         "withdrawConfirm": "1",
        //                         "withdrawFee": "0.0004",
        //                         "withdrawable": "true"
        //                     },
        //                 ],
        //                 "coin": "BTC",
        //                 "coinId": "1",
        //                 "transfer": "true""
        //             }
        //         ],
        //         "msg": "success",
        //         "requestTime": "1700120731773"
        //     }
        //
        const result = {};
        const data = this.safeValue(response, 'data', []);
        this.options['networkIdsByCurrencyAndNetworkCodes'] = {};
        for (let i = 0; i < data.length; i++) {
            const entry = data[i];
            const id = this.safeString(entry, 'coinId');
            const code = this.safeCurrencyCode(this.safeString(entry, 'coin'));
            this.options['networkIdsByCurrencyAndNetworkCodes'][code] = {};
            const chains = this.safeValue(entry, 'chains', []);
            const networks = {};
            let isTokenDepositable = false;
            let isTokenWithdrawable = false;
            let minWithdrawString = undefined;
            let minDepositString = undefined;
            let minWithdrawFeeString = undefined;
            for (let j = 0; j < chains.length; j++) {
                const chain = chains[j];
                const networkId = this.safeString(chain, 'chain');
                const networkCode = this.networkIdToCode(networkId, code);
                const withdrawEnabled = this.safeString(chain, 'withdrawable');
                const isWithdrawalEnabled = withdrawEnabled === 'true';
                const depositEnabled = this.safeString(chain, 'rechargeable');
                const isDepositEnabled = depositEnabled === 'true';
                isTokenDepositable = isDepositEnabled || isTokenDepositable;
                isTokenWithdrawable = isWithdrawalEnabled || isTokenWithdrawable;
                const networkWithdrawFeeString = this.safeString(chain, 'withdrawFee');
                if (networkWithdrawFeeString !== undefined) {
                    minWithdrawFeeString = (minWithdrawFeeString === undefined) ? networkWithdrawFeeString : Precise.stringMin(networkWithdrawFeeString, minWithdrawFeeString);
                }
                const networkMinWithdrawString = this.safeString(chain, 'minWithdrawAmount');
                if (networkMinWithdrawString !== undefined) {
                    minWithdrawString = (minWithdrawString === undefined) ? networkMinWithdrawString : Precise.stringMin(networkMinWithdrawString, minWithdrawString);
                }
                const networkMinDepositString = this.safeString(chain, 'minDepositAmount');
                if (networkMinDepositString !== undefined) {
                    minDepositString = (minDepositString === undefined) ? networkMinDepositString : Precise.stringMin(networkMinDepositString, minDepositString);
                }
                this.options['networkIdsByCurrencyAndNetworkCodes'][code][networkCode] = networkId; // we assume all networkCodes are unique by currency (e.g. no ERC20 and ETH for the same currency)
                networks[networkCode] = {
                    'info': chain,
                    'id': networkId,
                    'network': networkCode,
                    'limits': {
                        'withdraw': {
                            'min': this.parseNumber(networkMinWithdrawString),
                            'max': undefined,
                        },
                        'deposit': {
                            'min': this.parseNumber(networkMinDepositString),
                            'max': undefined,
                        },
                    },
                    'active': isWithdrawalEnabled && isDepositEnabled,
                    'withdraw': isWithdrawalEnabled,
                    'deposit': isDepositEnabled,
                    'fee': this.parseNumber(networkWithdrawFeeString),
                    'precision': undefined,
                };
            }
            result[code] = {
                'info': entry,
                'id': id,
                'code': code,
                'networks': networks,
                'type': undefined,
                'name': undefined,
                'active': isTokenDepositable && isTokenWithdrawable,
                'deposit': isTokenDepositable,
                'withdraw': isTokenWithdrawable,
                'fee': this.parseNumber(minWithdrawFeeString),
                'precision': undefined,
                'limits': {
                    'amount': {
                        'min': undefined,
                        'max': undefined,
                    },
                    'withdraw': {
                        'min': this.parseNumber(minWithdrawString),
                        'max': undefined,
                    },
                    'deposit': {
                        'min': this.parseNumber(minDepositString),
                        'max': undefined,
                    },
                },
                'created': undefined,
            };
        }
        return result;
    }
    async fetchDeposits(code = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name bitget#fetchDeposits
         * @description fetch all deposits made to an account
         * @see https://www.bitget.com/api-doc/spot/account/Get-Deposit-Record
         * @param {string} code unified currency code
         * @param {int} [since] the earliest time in ms to fetch deposits for
         * @param {int} [limit] the maximum number of deposits structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {int} [params.until] end time in milliseconds
         * @param {string} [params.idLessThan] return records with id less than the provided value
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [available parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @returns {object[]} a list of [transaction structures]{@link https://docs.ccxt.com/#/?id=transaction-structure}
         */
        await this.loadMarkets();
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchDeposits', 'paginate');
        if (paginate) {
            return await this.fetchPaginatedCallCursor('fetchDeposits', undefined, since, limit, params, 'idLessThan', 'idLessThan', undefined, 100);
        }
        if (code === undefined) {
            throw new InvalidParamsError('fetchDeposits() requires a `code` argument')
                .exchange(this.id);
        }
        const currency = this.currency(code);
        if (since === undefined) {
            since = this.milliseconds() - 7776000000; // 90 days
        }
        let request = {
            'coin': currency['code'],
            'startTime': since,
            'endTime': this.milliseconds(),
        };
        if (limit !== undefined) {
            request['limit'] = limit;
        }
        [request, params] = this.handleUntilOption('endTime', request, params);
        const response = await this.privateSpotGetV2SpotWalletDepositRecords(this.extend(request, params));
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700528340608,
        //         "data": [
        //             {
        //                 "orderId": "1083832260799930368",
        //                 "tradeId": "35bf0e588a42b25c71a9d45abe7308cabdeec6b7b423910b9bd4743d3a9a9efa",
        //                 "coin": "BTC",
        //                 "type": "deposit",
        //                 "size": "0.00030000",
        //                 "status": "success",
        //                 "toAddress": "1BfZh7JESJGBUszCGeZnzxbVVvBycbJSbA",
        //                 "dest": "on_chain",
        //                 "chain": "BTC",
        //                 "fromAddress": null,
        //                 "cTime": "1694131668281",
        //                 "uTime": "1694131680247"
        //             }
        //         ]
        //     }
        //
        const rawTransactions = this.safeValue(response, 'data', []);
        return this.parseTransactions(rawTransactions, currency, since, limit);
    }
    async withdraw(code, amount, address, tag = undefined, params = {}) {
        /**
         * @method
         * @name bitget#withdraw
         * @description make a withdrawal
         * @see https://www.bitget.com/api-doc/spot/account/Wallet-Withdrawal
         * @param {string} code unified currency code
         * @param {float} amount the amount to withdraw
         * @param {string} address the address to withdraw to
         * @param {string} tag
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.chain] the blockchain network the withdrawal is taking place on
         * @returns {object} a [transaction structure]{@link https://docs.ccxt.com/#/?id=transaction-structure}
         */
        this.checkAddress(address);
        const chain = this.safeString2(params, 'chain', 'network');
        params = this.omit(params, 'network');
        if (chain === undefined) {
            throw new InvalidParamsError('withdraw() requires a chain parameter or a network parameter')
                .exchange(this.id);
        }
        await this.loadMarkets();
        const currency = this.currency(code);
        const networkId = this.networkCodeToId(chain, code);
        const precisionAmount = this.currencyToPrecision(code, amount, chain);
        const request = {
            'coin': currency['code'],
            'address': address,
            'chain': networkId,
            'amount': precisionAmount,
        };
        if (tag !== undefined) {
            request['tag'] = tag;
        }
        const response = await this.privateSpotPostSpotV1WalletWithdrawalV2(this.extend(request, params));
        //
        //     {
        //          "code":"00000",
        //          "msg":"success",
        //          "requestTime":1696784219602,
        //          "data": {
        //              "orderId":"1094957867615789056",
        //              "clientOrderId":"64f1e4ce842041d296b4517df1b5c2d7"
        //          }
        //      }
        //
        const data = this.safeValue(response, 'data');
        let id = undefined;
        if (typeof data === 'string') {
            id = data;
        }
        else if (data !== undefined) {
            id = this.safeString(data, 'orderId');
        }
        const result = {
            'id': id,
            'info': response,
            'txid': undefined,
            'timestamp': undefined,
            'datetime': undefined,
            'network': undefined,
            'addressFrom': undefined,
            'address': undefined,
            'addressTo': undefined,
            'amount': undefined,
            'type': 'withdrawal',
            'currency': undefined,
            'status': undefined,
            'updated': undefined,
            'tagFrom': undefined,
            'tag': undefined,
            'tagTo': undefined,
            'comment': undefined,
            'fee': undefined,
        };
        const withdrawOptions = this.safeValue(this.options, 'withdraw', {});
        const fillResponseFromRequest = this.safeBool(withdrawOptions, 'fillResponseFromRequest', true);
        if (fillResponseFromRequest) {
            result['currency'] = code;
            result['timestamp'] = this.milliseconds();
            result['datetime'] = this.iso8601(this.milliseconds());
            result['amount'] = amount;
            result['tag'] = tag;
            result['address'] = address;
            result['addressTo'] = address;
            result['network'] = chain;
        }
        return result;
    }
    async fetchWithdrawals(code = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name bitget#fetchWithdrawals
         * @description fetch all withdrawals made from an account
         * @see https://www.bitget.com/api-doc/spot/account/Get-Withdraw-Record
         * @param {string} code unified currency code
         * @param {int} [since] the earliest time in ms to fetch withdrawals for
         * @param {int} [limit] the maximum number of withdrawals structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {int} [params.until] end time in milliseconds
         * @param {string} [params.idLessThan] return records with id less than the provided value
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [available parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @returns {object[]} a list of [transaction structures]{@link https://docs.ccxt.com/#/?id=transaction-structure}
         */
        await this.loadMarkets();
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchWithdrawals', 'paginate');
        if (paginate) {
            return await this.fetchPaginatedCallCursor('fetchWithdrawals', undefined, since, limit, params, 'idLessThan', 'idLessThan', undefined, 100);
        }
        if (code === undefined) {
            throw new InvalidParamsError('fetchWithdrawals() requires a `code` argument')
                .exchange(this.id);
        }
        const currency = this.currency(code);
        if (since === undefined) {
            since = this.milliseconds() - 7776000000; // 90 days
        }
        let request = {
            'coin': currency['code'],
            'startTime': since,
            'endTime': this.milliseconds(),
        };
        [request, params] = this.handleUntilOption('endTime', request, params);
        if (limit !== undefined) {
            request['limit'] = limit;
        }
        const response = await this.privateSpotGetV2SpotWalletWithdrawalRecords(this.extend(request, params));
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700528340608,
        //         "data": [
        //             {
        //                 "orderId": "1083832260799930368",
        //                 "tradeId": "35bf0e588a42b25c71a9d45abe7308cabdeec6b7b423910b9bd4743d3a9a9efa",
        //                 "clientOid": "123",
        //                 "coin": "BTC",
        //                 "type": "withdraw",
        //                 "size": "0.00030000",
        //                 "fee": "-1.0000000",
        //                 "status": "success",
        //                 "toAddress": "1BfZh7JESJGBUszCGeZnzxbVVvBycbJSbA",
        //                 "dest": "on_chain",
        //                 "chain": "BTC",
        //                 "confirm": "100",
        //                 "fromAddress": null,
        //                 "cTime": "1694131668281",
        //                 "uTime": "1694131680247"
        //             }
        //         ]
        //     }
        //
        const rawTransactions = this.safeValue(response, 'data', []);
        return this.parseTransactions(rawTransactions, currency, since, limit);
    }
    parseTransaction(transaction, currency = undefined) {
        //
        // fetchDeposits
        //
        //     {
        //         "orderId": "1083832260799930368",
        //         "tradeId": "35bf0e588a42b25c71a9d45abe7308cabdeec6b7b423910b9bd4743d3a9a9efa",
        //         "coin": "BTC",
        //         "type": "deposit",
        //         "size": "0.00030000",
        //         "status": "success",
        //         "toAddress": "1BfZh7JESJGBUszCGeZnzxbVVvBycbJSbA",
        //         "dest": "on_chain",
        //         "chain": "BTC",
        //         "fromAddress": null,
        //         "cTime": "1694131668281",
        //         "uTime": "1694131680247"
        //     }
        //
        // fetchWithdrawals
        //
        //     {
        //         "orderId": "1083832260799930368",
        //         "tradeId": "35bf0e588a42b25c71a9d45abe7308cabdeec6b7b423910b9bd4743d3a9a9efa",
        //         "clientOid": "123",
        //         "coin": "BTC",
        //         "type": "withdraw",
        //         "size": "0.00030000",
        //         "fee": "-1.0000000",
        //         "status": "success",
        //         "toAddress": "1BfZh7JESJGBUszCGeZnzxbVVvBycbJSbA",
        //         "dest": "on_chain",
        //         "chain": "BTC",
        //         "confirm": "100",
        //         "fromAddress": null,
        //         "cTime": "1694131668281",
        //         "uTime": "1694131680247"
        //     }
        //
        const currencyId = this.safeString(transaction, 'coin');
        const code = this.safeCurrencyCode(currencyId, currency);
        const timestamp = this.safeInteger(transaction, 'cTime');
        const networkId = this.safeString(transaction, 'chain');
        const status = this.safeString(transaction, 'status');
        const tag = this.safeString(transaction, 'tag');
        const feeCostString = this.safeString(transaction, 'fee');
        const feeCostAbsString = Precise.stringAbs(feeCostString);
        let fee = undefined;
        let amountString = this.safeString(transaction, 'size');
        if (feeCostAbsString !== undefined) {
            fee = { 'currency': code, 'cost': this.parseNumber(feeCostAbsString) };
            amountString = Precise.stringSub(amountString, feeCostAbsString);
        }
        return {
            'id': this.safeString(transaction, 'orderId'),
            'info': transaction,
            'txid': this.safeString(transaction, 'tradeId'),
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'network': this.networkIdToCode(networkId),
            'addressFrom': this.safeString(transaction, 'fromAddress'),
            'address': this.safeString(transaction, 'toAddress'),
            'addressTo': this.safeString(transaction, 'toAddress'),
            'amount': this.parseNumber(amountString),
            'type': this.safeString(transaction, 'type'),
            'currency': code,
            'status': this.parseTransactionStatus(status),
            'updated': this.safeInteger(transaction, 'uTime'),
            'tagFrom': undefined,
            'tag': tag,
            'tagTo': tag,
            'comment': undefined,
            'internal': undefined,
            'fee': fee,
        };
    }
    parseTransactionStatus(status) {
        const statuses = {
            'success': 'ok',
            'Pending': 'pending',
            'pending_review': 'pending',
            'pending_review_fail': 'failed',
            'reject': 'failed',
        };
        return this.safeString(statuses, status, status);
    }
    async fetchDepositAddress(code, params = {}) {
        /**
         * @method
         * @name bitget#fetchDepositAddress
         * @description fetch the deposit address for a currency associated with this account
         * @see https://www.bitget.com/api-doc/spot/account/Get-Deposit-Address
         * @param {string} code unified currency code
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} an [address structure]{@link https://docs.ccxt.com/#/?id=address-structure}
         */
        await this.loadMarkets();
        const networkCode = this.safeString2(params, 'chain', 'network');
        params = this.omit(params, 'network');
        let networkId = undefined;
        if (networkCode !== undefined) {
            networkId = this.networkCodeToId(networkCode, code);
        }
        const currency = this.currency(code);
        const request = {
            'coin': currency['code'],
        };
        if (networkId !== undefined) {
            request['chain'] = networkId;
        }
        const response = await this.privateSpotGetV2SpotWalletDepositAddress(this.extend(request, params));
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700532244807,
        //         "data": {
        //             "coin": "BTC",
        //             "address": "1BfZh7JESJGBUszCGeZnzxbVVvBycbJSbA",
        //             "chain": "",
        //             "tag": null,
        //             "url": "https://blockchair.com/bitcoin/transaction/"
        //         }
        //     }
        //
        const data = this.safeValue(response, 'data', {});
        return this.parseDepositAddress(data, currency);
    }
    parseDepositAddress(depositAddress, currency = undefined) {
        //
        //     {
        //         "coin": "BTC",
        //         "address": "1BfZh7JESJGBUszCGeZnzxbVVvBycbJSbA",
        //         "chain": "",
        //         "tag": null,
        //         "url": "https://blockchair.com/bitcoin/transaction/"
        //     }
        //
        const currencyId = this.safeString(depositAddress, 'coin');
        const networkId = this.safeString(depositAddress, 'chain');
        const parsedCurrency = this.safeCurrencyCode(currencyId, currency);
        let network = undefined;
        if (networkId !== undefined) {
            network = this.networkIdToCode(networkId, parsedCurrency);
        }
        return {
            'currency': parsedCurrency,
            'address': this.safeString(depositAddress, 'address'),
            'tag': this.safeString(depositAddress, 'tag'),
            'network': network,
            'info': depositAddress,
        };
    }
    async fetchOrderBook(symbol, limit = undefined, params = {}) {
        /**
         * @method
         * @name bitget#fetchOrderBook
         * @description fetches information on open orders with bid (buy) and ask (sell) prices, volumes and other data
         * @see https://www.bitget.com/api-doc/spot/market/Get-Orderbook
         * @see https://www.bitget.com/api-doc/contract/market/Get-Merge-Depth
         * @param {string} symbol unified symbol of the market to fetch the order book for
         * @param {int} [limit] the maximum amount of order book entries to return
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} A dictionary of [order book structures]{@link https://docs.ccxt.com/#/?id=order-book-structure} indexed by market symbols
         */
        await this.loadMarkets();
        const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        let market = undefined;
        if (sandboxMode) {
            const sandboxSymbol = this.convertSymbolForSandbox(symbol);
            market = this.market(sandboxSymbol);
        }
        else {
            market = this.market(symbol);
        }
        const request = {
            'symbol': market['id'],
        };
        if (limit !== undefined) {
            request['limit'] = limit;
        }
        let response = undefined;
        if (market['spot']) {
            response = await this.publicSpotGetV2SpotMarketOrderbook(this.extend(request, params));
        }
        else {
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(market, params);
            request['productType'] = productType;
            response = await this.publicMixGetV2MixMarketMergeDepth(this.extend(request, params));
        }
        //
        //     {
        //       "code": "00000",
        //       "msg": "success",
        //       "requestTime": 1645854610294,
        //       "data": {
        //         "asks": [ [ "39102", "11.026" ] ],
        //         "bids": [ [ '39100.5', "1.773" ] ],
        //         "ts": "1645854610294"
        //       }
        //     }
        //
        const data = this.safeValue(response, 'data', {});
        const timestamp = this.safeInteger(data, 'ts');
        return this.parseOrderBook(data, market['symbol'], timestamp);
    }
    parseTicker(ticker, market = undefined) {
        //
        // spot: fetchTicker, fetchTickers
        //
        //     {
        //         "open": "37202.46",
        //         "symbol": "BTCUSDT",
        //         "high24h": "37744.75",
        //         "low24h": "36666",
        //         "lastPr": "37583.69",
        //         "quoteVolume": "519127705.303",
        //         "baseVolume": "13907.0386",
        //         "usdtVolume": "519127705.302908",
        //         "ts": "1700532903261",
        //         "bidPr": "37583.68",
        //         "askPr": "37583.69",
        //         "bidSz": "0.0007",
        //         "askSz": "0.0829",
        //         "openUtc": "37449.4",
        //         "changeUtc24h": "0.00359",
        //         "change24h": "0.00321"
        //     }
        //
        // swap and future: fetchTicker
        //
        //     {
        //         "symbol": "BTCUSDT",
        //         "lastPr": "37577.2",
        //         "askPr": "37577.3",
        //         "bidPr": "37577.2",
        //         "bidSz": "3.679",
        //         "askSz": "0.02",
        //         "high24h": "37765",
        //         "low24h": "36628.9",
        //         "ts": "1700533070359",
        //         "change24h": "0.00288",
        //         "baseVolume": "108606.181",
        //         "quoteVolume": "4051316303.9608",
        //         "usdtVolume": "4051316303.9608",
        //         "openUtc": "37451.5",
        //         "changeUtc24h": "0.00336",
        //         "indexPrice": "37574.489253",
        //         "fundingRate": "0.0001",
        //         "holdingAmount": "53464.529",
        //         "deliveryStartTime": null,
        //         "deliveryTime": null,
        //         "deliveryStatus": "",
        //         "open24h": "37235.7"
        //     }
        //
        // swap and future: fetchTickers
        //
        //     {
        //         "open": "14.9776",
        //         "symbol": "LINKUSDT",
        //         "high24h": "15.3942",
        //         "low24h": "14.3457",
        //         "lastPr": "14.3748",
        //         "quoteVolume": "7008612.4299",
        //         "baseVolume": "469908.8523",
        //         "usdtVolume": "7008612.42986561",
        //         "ts": "1700533772309",
        //         "bidPr": "14.375",
        //         "askPr": "14.3769",
        //         "bidSz": "50.004",
        //         "askSz": "0.7647",
        //         "openUtc": "14.478",
        //         "changeUtc24h": "-0.00713",
        //         "change24h": "-0.04978"
        //     }
        //
        const marketId = this.safeString(ticker, 'symbol');
        const close = this.safeString(ticker, 'lastPr');
        const timestamp = this.safeInteger(ticker, 'ts');
        const change = this.safeString(ticker, 'change24h');
        const open24 = this.safeString(ticker, 'open24');
        const open = this.safeString(ticker, 'open');
        let symbol;
        let openValue;
        if (open === undefined) {
            symbol = this.safeSymbol(marketId, market, undefined, 'contract');
            openValue = open24;
        }
        else {
            symbol = this.safeSymbol(marketId, market, undefined, 'spot');
            openValue = open;
        }
        return this.safeTicker({
            'symbol': symbol,
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'high': this.safeString(ticker, 'high24h'),
            'low': this.safeString(ticker, 'low24h'),
            'bid': this.safeString(ticker, 'bidPr'),
            'bidVolume': this.safeString(ticker, 'bidSz'),
            'ask': this.safeString(ticker, 'askPr'),
            'askVolume': this.safeString(ticker, 'askSz'),
            'vwap': undefined,
            'open': openValue,
            'close': close,
            'last': close,
            'previousClose': undefined,
            'change': change,
            'percentage': Precise.stringMul(change, '100'),
            'average': undefined,
            'baseVolume': this.safeString(ticker, 'baseVolume'),
            'quoteVolume': this.safeString(ticker, 'quoteVolume'),
            'info': ticker,
        }, market);
    }
    async fetchTicker(symbol, params = {}) {
        /**
         * @method
         * @name bitget#fetchTicker
         * @description fetches a price ticker, a statistical calculation with the information calculated over the past 24 hours for a specific market
         * @see https://www.bitget.com/api-doc/spot/market/Get-Tickers
         * @see https://www.bitget.com/api-doc/contract/market/Get-Ticker
         * @param {string} symbol unified symbol of the market to fetch the ticker for
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a [ticker structure]{@link https://docs.ccxt.com/#/?id=ticker-structure}
         */
        await this.loadMarkets();
        const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        let market = undefined;
        if (sandboxMode) {
            const sandboxSymbol = this.convertSymbolForSandbox(symbol);
            market = this.market(sandboxSymbol);
        }
        else {
            market = this.market(symbol);
        }
        const request = {
            'symbol': market['id'],
        };
        let response = undefined;
        if (market['spot']) {
            response = await this.publicSpotGetV2SpotMarketTickers(this.extend(request, params));
        }
        else {
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(market, params);
            request['productType'] = productType;
            response = await this.publicMixGetV2MixMarketTicker(this.extend(request, params));
        }
        //
        // spot
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700532903782,
        //         "data": [
        //             {
        //                 "open": "37202.46",
        //                 "symbol": "BTCUSDT",
        //                 "high24h": "37744.75",
        //                 "low24h": "36666",
        //                 "lastPr": "37583.69",
        //                 "quoteVolume": "519127705.303",
        //                 "baseVolume": "13907.0386",
        //                 "usdtVolume": "519127705.302908",
        //                 "ts": "1700532903261",
        //                 "bidPr": "37583.68",
        //                 "askPr": "37583.69",
        //                 "bidSz": "0.0007",
        //                 "askSz": "0.0829",
        //                 "openUtc": "37449.4",
        //                 "changeUtc24h": "0.00359",
        //                 "change24h": "0.00321"
        //             }
        //         ]
        //     }
        //
        // swap and future
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700533070357,
        //         "data": [
        //             {
        //                 "symbol": "BTCUSDT",
        //                 "lastPr": "37577.2",
        //                 "askPr": "37577.3",
        //                 "bidPr": "37577.2",
        //                 "bidSz": "3.679",
        //                 "askSz": "0.02",
        //                 "high24h": "37765",
        //                 "low24h": "36628.9",
        //                 "ts": "1700533070359",
        //                 "change24h": "0.00288",
        //                 "baseVolume": "108606.181",
        //                 "quoteVolume": "4051316303.9608",
        //                 "usdtVolume": "4051316303.9608",
        //                 "openUtc": "37451.5",
        //                 "changeUtc24h": "0.00336",
        //                 "indexPrice": "37574.489253",
        //                 "fundingRate": "0.0001",
        //                 "holdingAmount": "53464.529",
        //                 "deliveryStartTime": null,
        //                 "deliveryTime": null,
        //                 "deliveryStatus": "",
        //                 "open24h": "37235.7"
        //             }
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        return this.parseTicker(data[0], market);
    }
    async fetchTickers(symbols = undefined, params = {}) {
        /**
         * @method
         * @name bitget#fetchTickers
         * @description fetches price tickers for multiple markets, statistical information calculated over the past 24 hours for each market
         * @see https://www.bitget.com/api-doc/spot/market/Get-Tickers
         * @see https://www.bitget.com/api-doc/contract/market/Get-All-Symbol-Ticker
         * @param {string[]|undefined} symbols unified symbols of the markets to fetch the ticker for, all market tickers are returned if not assigned
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.productType] *contract only* 'USDT-FUTURES', 'USDC-FUTURES', 'COIN-FUTURES', 'SUSDT-FUTURES', 'SUSDC-FUTURES' or 'SCOIN-FUTURES'
         * @returns {object} a dictionary of [ticker structures]{@link https://docs.ccxt.com/#/?id=ticker-structure}
         */
        await this.loadMarkets();
        let market = undefined;
        if (symbols !== undefined) {
            const symbol = this.safeValue(symbols, 0);
            const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
            if (sandboxMode) {
                const sandboxSymbol = this.convertSymbolForSandbox(symbol);
                market = this.market(sandboxSymbol);
            }
            else {
                market = this.market(symbol);
            }
        }
        const request = {};
        let type = undefined;
        [type, params] = this.handleMarketTypeAndParams('fetchTickers', market, params);
        let response = undefined;
        if (type === 'spot') {
            response = await this.publicSpotGetV2SpotMarketTickers(this.extend(request, params));
        }
        else {
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(market, params);
            request['productType'] = productType;
            response = await this.publicMixGetV2MixMarketTickers(this.extend(request, params));
        }
        //
        // spot
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700532903782,
        //         "data": [
        //             {
        //                 "open": "37202.46",
        //                 "symbol": "BTCUSDT",
        //                 "high24h": "37744.75",
        //                 "low24h": "36666",
        //                 "lastPr": "37583.69",
        //                 "quoteVolume": "519127705.303",
        //                 "baseVolume": "13907.0386",
        //                 "usdtVolume": "519127705.302908",
        //                 "ts": "1700532903261",
        //                 "bidPr": "37583.68",
        //                 "askPr": "37583.69",
        //                 "bidSz": "0.0007",
        //                 "askSz": "0.0829",
        //                 "openUtc": "37449.4",
        //                 "changeUtc24h": "0.00359",
        //                 "change24h": "0.00321"
        //             }
        //         ]
        //     }
        //
        // swap and future
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700533773477,
        //         "data": [
        //             {
        //                 "open": "14.9776",
        //                 "symbol": "LINKUSDT",
        //                 "high24h": "15.3942",
        //                 "low24h": "14.3457",
        //                 "lastPr": "14.3748",
        //                 "quoteVolume": "7008612.4299",
        //                 "baseVolume": "469908.8523",
        //                 "usdtVolume": "7008612.42986561",
        //                 "ts": "1700533772309",
        //                 "bidPr": "14.375",
        //                 "askPr": "14.3769",
        //                 "bidSz": "50.004",
        //                 "askSz": "0.7647",
        //                 "openUtc": "14.478",
        //                 "changeUtc24h": "-0.00713",
        //                 "change24h": "-0.04978"
        //             },
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        return this.parseTickers(data, symbols);
    }
    parseTrade(trade, market = undefined) {
        //
        // spot, swap and future: fetchTrades
        //
        //     {
        //         "tradeId": "1075199767891652609",
        //         "price": "29376.5",
        //         "size": "6.035",
        //         "side": "Buy",
        //         "ts": "1692073521000",
        //         "symbol": "BTCUSDT"
        //     }
        //
        // spot: fetchMyTrades
        //
        //     {
        //         "userId": "7264631750",
        //         "symbol": "BTCUSDT",
        //         "orderId": "1098394344925597696",
        //         "tradeId": "1098394344974925824",
        //         "orderType": "market",
        //         "side": "sell",
        //         "priceAvg": "28467.68",
        //         "size": "0.0002",
        //         "amount": "5.693536",
        //         "feeDetail": {
        //             "deduction": "no",
        //             "feeCoin": "USDT",
        //             "totalDeductionFee": "",
        //             "totalFee": "-0.005693536"
        //         },
        //         "tradeScope": "taker",
        //         "cTime": "1697603539699",
        //         "uTime": "1697603539754"
        //     }
        //
        // spot margin: fetchMyTrades
        //
        //     {
        //         "orderId": "1099353730455318528",
        //         "tradeId": "1099353730627092481",
        //         "orderType": "market",
        //         "side": "sell",
        //         "priceAvg": "29543.7",
        //         "size": "0.0001",
        //         "amount": "2.95437",
        //         "tradeScope": "taker",
        //         "feeDetail": {
        //             "deduction": "no",
        //             "feeCoin": "USDT",
        //             "totalDeductionFee": "0",
        //             "totalFee": "-0.00295437"
        //         },
        //         "cTime": "1697832275063",
        //         "uTime": "1697832275150"
        //     }
        //
        // swap and future: fetchMyTrades
        //
        //     {
        //         "tradeId": "1111468664328269825",
        //         "symbol": "BTCUSDT",
        //         "orderId": "1111468664264753162",
        //         "price": "37271.4",
        //         "baseVolume": "0.001",
        //         "feeDetail": [
        //             {
        //                 "deduction": "no",
        //                 "feeCoin": "USDT",
        //                 "totalDeductionFee": null,
        //                 "totalFee": "-0.02236284"
        //             }
        //         ],
        //         "side": "buy",
        //         "quoteVolume": "37.2714",
        //         "profit": "-0.0007",
        //         "enterPointSource": "web",
        //         "tradeSide": "close",
        //         "posMode": "hedge_mode",
        //         "tradeScope": "taker",
        //         "cTime": "1700720700342"
        //     }
        //
        const marketId = this.safeString(trade, 'symbol');
        const symbol = this.safeSymbol(marketId, market);
        const timestamp = this.safeInteger2(trade, 'cTime', 'ts');
        let fee = undefined;
        const feeDetail = this.safeValue(trade, 'feeDetail');
        const posMode = this.safeString(trade, 'posMode');
        const feeStructure = (posMode !== undefined) ? feeDetail[0] : feeDetail;
        if (feeStructure !== undefined) {
            const currencyCode = this.safeCurrencyCode(this.safeString(feeStructure, 'feeCoin'));
            fee = {
                'currency': currencyCode,
                'cost': Precise.stringAbs(this.safeString(feeStructure, 'totalFee')),
            };
        }
        return this.safeTrade({
            'info': trade,
            'id': this.safeString(trade, 'tradeId'),
            'order': this.safeString(trade, 'orderId'),
            'symbol': symbol,
            'side': this.safeStringLower(trade, 'side'),
            'type': this.safeString(trade, 'orderType'),
            'takerOrMaker': this.safeString(trade, 'tradeScope'),
            'price': this.safeString2(trade, 'priceAvg', 'price'),
            'amount': this.safeString2(trade, 'baseVolume', 'size'),
            'cost': this.safeString2(trade, 'quoteVolume', 'amount'),
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'fee': fee,
        }, market);
    }
    async fetchTrades(symbol, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name bitget#fetchTrades
         * @description get the list of most recent trades for a particular symbol
         * @see https://www.bitget.com/api-doc/spot/market/Get-Recent-Trades
         * @see https://www.bitget.com/api-doc/spot/market/Get-Market-Trades
         * @see https://www.bitget.com/api-doc/contract/market/Get-Recent-Fills
         * @see https://www.bitget.com/api-doc/contract/market/Get-Fills-History
         * @param {string} symbol unified symbol of the market to fetch trades for
         * @param {int} [since] timestamp in ms of the earliest trade to fetch
         * @param {int} [limit] the maximum amount of trades to fetch
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {int} [params.until] *only applies to publicSpotGetV2SpotMarketFillsHistory and publicMixGetV2MixMarketFillsHistory* the latest time in ms to fetch trades for
         * @param {boolean} [params.paginate] *only applies to publicSpotGetV2SpotMarketFillsHistory and publicMixGetV2MixMarketFillsHistory* default false, when true will automatically paginate by calling this endpoint multiple times
         * @returns {Trade[]} a list of [trade structures]{@link https://docs.ccxt.com/#/?id=public-trades}
         */
        await this.loadMarkets();
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchTrades', 'paginate');
        if (paginate) {
            return await this.fetchPaginatedCallCursor('fetchTrades', symbol, since, limit, params, 'idLessThan', 'idLessThan');
        }
        const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        let market = undefined;
        if (sandboxMode) {
            const sandboxSymbol = this.convertSymbolForSandbox(symbol);
            market = this.market(sandboxSymbol);
        }
        else {
            market = this.market(symbol);
        }
        let request = {
            'symbol': market['id'],
        };
        if (limit !== undefined) {
            if (market['contract']) {
                request['limit'] = Math.min(limit, 1000);
            }
            else {
                request['limit'] = limit;
            }
        }
        const options = this.safeValue(this.options, 'fetchTrades', {});
        let response = undefined;
        if (market['spot']) {
            const spotOptions = this.safeValue(options, 'spot', {});
            const defaultSpotMethod = this.safeString(spotOptions, 'method', 'publicSpotGetV2SpotMarketFillsHistory');
            const spotMethod = this.safeString(params, 'method', defaultSpotMethod);
            params = this.omit(params, 'method');
            if (spotMethod === 'publicSpotGetV2SpotMarketFillsHistory') {
                [request, params] = this.handleUntilOption('endTime', request, params);
                if (since !== undefined) {
                    request['startTime'] = since;
                }
                response = await this.publicSpotGetV2SpotMarketFillsHistory(this.extend(request, params));
            }
            else if (spotMethod === 'publicSpotGetV2SpotMarketFills') {
                response = await this.publicSpotGetV2SpotMarketFills(this.extend(request, params));
            }
        }
        else {
            const swapOptions = this.safeValue(options, 'swap', {});
            const defaultSwapMethod = this.safeString(swapOptions, 'method', 'publicMixGetV2MixMarketFillsHistory');
            const swapMethod = this.safeString(params, 'method', defaultSwapMethod);
            params = this.omit(params, 'method');
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(market, params);
            request['productType'] = productType;
            if (swapMethod === 'publicMixGetV2MixMarketFillsHistory') {
                [request, params] = this.handleUntilOption('endTime', request, params);
                if (since !== undefined) {
                    request['startTime'] = since;
                }
                response = await this.publicMixGetV2MixMarketFillsHistory(this.extend(request, params));
            }
            else if (swapMethod === 'publicMixGetV2MixMarketFills') {
                response = await this.publicMixGetV2MixMarketFills(this.extend(request, params));
            }
        }
        //
        // spot
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1692073693562,
        //         "data": [
        //             {
        //                 "symbol": "BTCUSDT_SPBL",
        //                 "tradeId": "1075200479040323585",
        //                 "side": "Sell",
        //                 "price": "29381.54",
        //                 "size": "0.0056",
        //                 "ts": "1692073691000"
        //             },
        //         ]
        //     }
        //
        // swap
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1692073522689,
        //         "data": [
        //             {
        //                 "tradeId": "1075199767891652609",
        //                 "price": "29376.5",
        //                 "size": "6.035",
        //                 "side": "Buy",
        //                 "ts": "1692073521000",
        //                 "symbol": "BTCUSDT_UMCBL"
        //             },
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        return this.parseTrades(data, market, since, limit);
    }
    async fetchTradingFee(symbol, params = {}) {
        /**
         * @method
         * @name bitget#fetchTradingFee
         * @description fetch the trading fees for a market
         * @see https://www.bitget.com/api-doc/common/public/Get-Trade-Rate
         * @param {string} symbol unified market symbol
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.marginMode] 'isolated' or 'cross', for finding the fee rate of spot margin trading pairs
         * @returns {object} a [fee structure]{@link https://docs.ccxt.com/#/?id=fee-structure}
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        const request = {
            'symbol': market['id'],
        };
        let marginMode = undefined;
        [marginMode, params] = this.handleMarginModeAndParams('fetchTradingFee', params);
        if (market['spot']) {
            if (marginMode !== undefined) {
                request['businessType'] = 'margin';
            }
            else {
                request['businessType'] = 'spot';
            }
        }
        else {
            request['businessType'] = 'contract';
        }
        const response = await this.privateCommonGetV2CommonTradeRate(this.extend(request, params));
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700549524887,
        //         "data": {
        //             "makerFeeRate": "0.001",
        //             "takerFeeRate": "0.001"
        //         }
        //     }
        //
        const data = this.safeValue(response, 'data', {});
        return this.parseTradingFee(data, market);
    }
    async fetchTradingFees(params = {}) {
        /**
         * @method
         * @name bitget#fetchTradingFees
         * @description fetch the trading fees for multiple markets
         * @see https://www.bitget.com/api-doc/spot/market/Get-Symbols
         * @see https://www.bitget.com/api-doc/contract/market/Get-All-Symbols-Contracts
         * @see https://www.bitget.com/api-doc/margin/common/support-currencies
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.productType] *contract only* 'USDT-FUTURES', 'USDC-FUTURES', 'COIN-FUTURES', 'SUSDT-FUTURES', 'SUSDC-FUTURES' or 'SCOIN-FUTURES'
         * @param {boolean} [params.margin] set to true for spot margin
         * @returns {object} a dictionary of [fee structures]{@link https://docs.ccxt.com/#/?id=fee-structure} indexed by market symbols
         */
        await this.loadMarkets();
        let response = undefined;
        let marginMode = undefined;
        let marketType = undefined;
        [marginMode, params] = this.handleMarginModeAndParams('fetchTradingFees', params);
        [marketType, params] = this.handleMarketTypeAndParams('fetchTradingFees', undefined, params);
        if (marketType === 'spot') {
            const margin = this.safeBool(params, 'margin', false);
            params = this.omit(params, 'margin');
            if ((marginMode !== undefined) || margin) {
                response = await this.publicMarginGetV2MarginCurrencies(params);
            }
            else {
                response = await this.publicSpotGetV2SpotPublicSymbols(params);
            }
        }
        else if ((marketType === 'swap') || (marketType === 'future')) {
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(undefined, params);
            params['productType'] = productType;
            response = await this.publicMixGetV2MixMarketContracts(params);
        }
        else {
            throw new MarketTypeNotSupportedError(marketType).exchange(this.id);
        }
        //
        // spot and margin
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700102364653,
        //         "data": [
        //             {
        //                 "symbol": "TRXUSDT",
        //                 "baseCoin": "TRX",
        //                 "quoteCoin": "USDT",
        //                 "minTradeAmount": "0",
        //                 "maxTradeAmount": "10000000000",
        //                 "takerFeeRate": "0.002",
        //                 "makerFeeRate": "0.002",
        //                 "pricePrecision": "6",
        //                 "quantityPrecision": "4",
        //                 "quotePrecision": "6",
        //                 "status": "online",
        //                 "minTradeUSDT": "5",
        //                 "buyLimitPriceRatio": "0.05",
        //                 "sellLimitPriceRatio": "0.05"
        //             },
        //         ]
        //     }
        //
        // swap and future
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700102364709,
        //         "data": [
        //             {
        //                 "symbol": "BTCUSDT",
        //                 "baseCoin": "BTC",
        //                 "quoteCoin": "USDT",
        //                 "buyLimitPriceRatio": "0.01",
        //                 "sellLimitPriceRatio": "0.01",
        //                 "feeRateUpRatio": "0.005",
        //                 "makerFeeRate": "0.0002",
        //                 "takerFeeRate": "0.0006",
        //                 "openCostUpRatio": "0.01",
        //                 "supportMarginCoins": ["USDT"],
        //                 "minTradeNum": "0.001",
        //                 "priceEndStep": "1",
        //                 "volumePlace": "3",
        //                 "pricePlace": "1",
        //                 "sizeMultiplier": "0.001",
        //                 "symbolType": "perpetual",
        //                 "minTradeUSDT": "5",
        //                 "maxSymbolOrderNum": "200",
        //                 "maxProductOrderNum": "400",
        //                 "maxPositionNum": "150",
        //                 "symbolStatus": "normal",
        //                 "offTime": "-1",
        //                 "limitOpenTime": "-1",
        //                 "deliveryTime": "",
        //                 "deliveryStartTime": "",
        //                 "deliveryPeriod": "",
        //                 "launchTime": "",
        //                 "fundInterval": "8",
        //                 "minLever": "1",
        //                 "maxLever": "125",
        //                 "posLimit": "0.05",
        //                 "maintainTime": ""
        //             },
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        const result = {};
        for (let i = 0; i < data.length; i++) {
            const entry = data[i];
            const marketId = this.safeString(entry, 'symbol');
            const symbol = this.safeSymbol(marketId, undefined, undefined, marketType);
            const market = this.market(symbol);
            const fee = this.parseTradingFee(entry, market);
            result[symbol] = fee;
        }
        return result;
    }
    parseTradingFee(data, market = undefined) {
        const marketId = this.safeString(data, 'symbol');
        return {
            'info': data,
            'symbol': this.safeSymbol(marketId, market),
            'maker': this.safeNumber(data, 'makerFeeRate'),
            'taker': this.safeNumber(data, 'takerFeeRate'),
        };
    }
    parseOHLCV(ohlcv, market = undefined) {
        //
        //     [
        //         "1645911960000",
        //         "39406",
        //         "39407",
        //         "39374.5",
        //         "39379",
        //         "35.526",
        //         "1399132.341"
        //     ]
        //
        return [
            this.safeInteger(ohlcv, 0),
            this.safeNumber(ohlcv, 1),
            this.safeNumber(ohlcv, 2),
            this.safeNumber(ohlcv, 3),
            this.safeNumber(ohlcv, 4),
            this.safeNumber(ohlcv, 5),
        ];
    }
    async fetchOHLCV(symbol, timeframe = '1m', since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name bitget#fetchOHLCV
         * @description fetches historical candlestick data containing the open, high, low, and close price, and the volume of a market
         * @see https://www.bitget.com/api-doc/spot/market/Get-Candle-Data
         * @see https://www.bitget.com/api-doc/spot/market/Get-History-Candle-Data
         * @see https://www.bitget.com/api-doc/contract/market/Get-Candle-Data
         * @see https://www.bitget.com/api-doc/contract/market/Get-History-Candle-Data
         * @see https://www.bitget.com/api-doc/contract/market/Get-History-Index-Candle-Data
         * @see https://www.bitget.com/api-doc/contract/market/Get-History-Mark-Candle-Data
         * @param {string} symbol unified symbol of the market to fetch OHLCV data for
         * @param {string} timeframe the length of time each candle represents
         * @param {int} [since] timestamp in ms of the earliest candle to fetch
         * @param {int} [limit] the maximum amount of candles to fetch
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {int} [params.until] timestamp in ms of the latest candle to fetch
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [available parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @param {string} [params.price] *swap only* "mark" (to fetch mark price candles) or "index" (to fetch index price candles)
         * @returns {int[][]} A list of candles ordered as timestamp, open, high, low, close, volume
         */
        await this.loadMarkets();
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchOHLCV', 'paginate');
        if (paginate) {
            return await this.fetchPaginatedCallDeterministic('fetchOHLCV', symbol, since, limit, timeframe, params, 1000);
        }
        const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        let market = undefined;
        if (sandboxMode) {
            const sandboxSymbol = this.convertSymbolForSandbox(symbol);
            market = this.market(sandboxSymbol);
        }
        else {
            market = this.market(symbol);
        }
        const marketType = market['spot'] ? 'spot' : 'swap';
        const timeframes = this.options['timeframes'][marketType];
        const selectedTimeframe = this.safeString(timeframes, timeframe, timeframe);
        const request = {
            'symbol': market['id'],
            'granularity': selectedTimeframe,
        };
        const until = this.safeInteger2(params, 'until', 'till');
        params = this.omit(params, ['until', 'till']);
        if (limit !== undefined) {
            request['limit'] = limit;
        }
        if (since !== undefined) {
            request['startTime'] = since;
        }
        if (since !== undefined) {
            if (limit === undefined) {
                limit = 100; // exchange default
            }
            const duration = this.parseTimeframe(timeframe) * 1000;
            request['endTime'] = this.sum(since, duration * (limit + 1)) - 1; // limit + 1)) - 1 is needed for when since is not the exact timestamp of a candle
        }
        else if (until !== undefined) {
            request['endTime'] = until;
        }
        else {
            request['endTime'] = this.milliseconds();
        }
        let response = undefined;
        const thirtyOneDaysAgo = this.milliseconds() - 2678400000;
        if (market['spot']) {
            if ((since !== undefined) && (since < thirtyOneDaysAgo)) {
                response = await this.publicSpotGetV2SpotMarketHistoryCandles(this.extend(request, params));
            }
            else {
                response = await this.publicSpotGetV2SpotMarketCandles(this.extend(request, params));
            }
        }
        else {
            const priceType = this.safeString(params, 'price');
            params = this.omit(params, ['price']);
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(market, params);
            request['productType'] = productType;
            if (priceType === 'mark') {
                response = await this.publicMixGetV2MixMarketHistoryMarkCandles(this.extend(request, params));
            }
            else if (priceType === 'index') {
                response = await this.publicMixGetV2MixMarketHistoryIndexCandles(this.extend(request, params));
            }
            else if ((since !== undefined) && (since < thirtyOneDaysAgo)) {
                response = await this.publicMixGetV2MixMarketHistoryCandles(this.extend(request, params));
            }
            else {
                response = await this.publicMixGetV2MixMarketCandles(this.extend(request, params));
            }
        }
        if (response === '') {
            return []; // happens when a new token is listed
        }
        //  [ ["1645911960000","39406","39407","39374.5","39379","35.526","1399132.341"] ]
        const data = this.safeList(response, 'data', response);
        return this.parseOHLCVs(data, market, timeframe, since, limit);
    }
    async fetchBalance(params = {}) {
        /**
         * @method
         * @name bitget#fetchBalance
         * @description query for balance and get the amount of funds available for trading or funds locked in orders
         * @see https://www.bitget.com/api-doc/spot/account/Get-Account-Assets
         * @see https://www.bitget.com/api-doc/contract/account/Get-Account-List
         * @see https://www.bitget.com/api-doc/margin/cross/account/Get-Cross-Assets
         * @see https://www.bitget.com/api-doc/margin/isolated/account/Get-Isolated-Assets
         * @see https://bitgetlimited.github.io/apidoc/en/margin/#get-cross-assets
         * @see https://bitgetlimited.github.io/apidoc/en/margin/#get-isolated-assets
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.productType] *contract only* 'USDT-FUTURES', 'USDC-FUTURES', 'COIN-FUTURES', 'SUSDT-FUTURES', 'SUSDC-FUTURES' or 'SCOIN-FUTURES'
         * @returns {object} a [balance structure]{@link https://docs.ccxt.com/#/?id=balance-structure}
         */
        await this.loadMarkets();
        const request = {};
        let marketType = undefined;
        let marginMode = undefined;
        let response = undefined;
        [marketType, params] = this.handleMarketTypeAndParams('fetchBalance', undefined, params);
        [marginMode, params] = this.handleMarginModeAndParams('fetchBalance', params);
        let method = this.getSupportedMapping(marketType, {
            'spot': 'privateSpotGetAccountAssets',
            'swap': 'privateMixGetAccountAccounts',
            'margin': 'privateMarginGetCrossAccountAssets',
        });
        if ((marketType === 'swap') || (marketType === 'future')) {
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(undefined, params);
            request['productType'] = productType;
            response = await this.privateMixGetV2MixAccountAccounts(this.extend(request, params));
        }
        else if (marginMode === 'isolated') {
            response = await this.privateMarginGetMarginV1IsolatedAccountAssets(this.extend(request, params));
        }
        else if (marginMode === 'cross') {
            response = await this.privateMarginGetMarginV1CrossAccountAssets(this.extend(request, params));
        }
        else if (marketType === 'spot') {
            response = await this.privateSpotGetV2SpotAccountAssets(this.extend(request, params));
        }
        else if (marketType === 'margin') {
            if (marginMode === 'isolated') {
                method = 'privateMarginGetIsolatedAccountAssets';
            }
            else {
                method = 'privateMarginGetCrossedAccountAssets';
            }
            response = await this[method](this.extend(request, params));
        }
        else {
            throw new MarketTypeNotSupportedError(marketType)
                .addMessage('fetchBalance() does not support ' + marketType + ' accounts')
                .exchange(this.id);
        }
        // spot
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700623852854,
        //         "data": [
        //             {
        //                 "coin": "USDT",
        //                 "available": "0.00000000",
        //                 "limitAvailable": "0",
        //                 "frozen": "0.00000000",
        //                 "locked": "0.00000000",
        //                 "uTime": "1699937566000"
        //             }
        //         ]
        //     }
        //
        // swap
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700625127294,
        //         "data": [
        //             {
        //                 "marginCoin": "USDT",
        //                 "locked": "0",
        //                 "available": "0",
        //                 "crossedMaxAvailable": "0",
        //                 "isolatedMaxAvailable": "0",
        //                 "maxTransferOut": "0",
        //                 "accountEquity": "0",
        //                 "usdtEquity": "0.000000005166",
        //                 "btcEquity": "0",
        //                 "crossedRiskRate": "0",
        //                 "unrealizedPL": "0",
        //                 "coupon": "0",
        //                 "crossedUnrealizedPL": null,
        //                 "isolatedUnrealizedPL": null
        //             }
        //         ]
        //     }
        //
        // isolated margin
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1697501436571,
        //         "data": [
        //             {
        //                 "symbol": "BTCUSDT",
        //                 "coin": "BTC",
        //                 "totalAmount": "0.00021654",
        //                 "available": "0.00021654",
        //                 "transferable": "0.00021654",
        //                 "frozen": "0",
        //                 "borrow": "0",
        //                 "interest": "0",
        //                 "net": "0.00021654",
        //                 "ctime": "1697248128071"
        //             },
        //         ]
        //     }
        //
        // cross margin
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1697515463804,
        //         "data": [
        //             {
        //                 "coin": "BTC",
        //                 "totalAmount": "0.00024996",
        //                 "available": "0.00024996",
        //                 "transferable": "0.00004994",
        //                 "frozen": "0",
        //                 "borrow": "0.0001",
        //                 "interest": "0.00000001",
        //                 "net": "0.00014995",
        //                 "ctime": "1697251265504"
        //             },
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        return this.parseBalance(data);
    }
    parseBalance(balance) {
        const result = { 'info': balance };
        //
        // spot
        //
        //     {
        //         "coin": "USDT",
        //         "available": "0.00000000",
        //         "limitAvailable": "0",
        //         "frozen": "0.00000000",
        //         "locked": "0.00000000",
        //         "uTime": "1699937566000"
        //     }
        //
        // swap
        //
        //     {
        //         "marginCoin": "USDT",
        //         "locked": "0",
        //         "available": "0",
        //         "crossedMaxAvailable": "0",
        //         "isolatedMaxAvailable": "0",
        //         "maxTransferOut": "0",
        //         "accountEquity": "0",
        //         "usdtEquity": "0.000000005166",
        //         "btcEquity": "0",
        //         "crossedRiskRate": "0",
        //         "unrealizedPL": "0",
        //         "coupon": "0",
        //         "crossedUnrealizedPL": null,
        //         "isolatedUnrealizedPL": null
        //     }
        //
        // isolated margin
        //
        //     {
        //         "symbol": "BTCUSDT",
        //         "coin": "BTC",
        //         "totalAmount": "0.00021654",
        //         "available": "0.00021654",
        //         "transferable": "0.00021654",
        //         "frozen": "0",
        //         "borrow": "0",
        //         "interest": "0",
        //         "net": "0.00021654",
        //         "ctime": "1697248128071"
        //     }
        //
        // cross margin
        //
        //     {
        //         "coin": "BTC",
        //         "totalAmount": "0.00024995",
        //         "available": "0.00024995",
        //         "transferable": "0.00004993",
        //         "frozen": "0",
        //         "borrow": "0.0001",
        //         "interest": "0.00000001",
        //         "net": "0.00014994",
        //         "ctime": "1697251265504"
        //     }
        //
        for (let i = 0; i < balance.length; i++) {
            const entry = balance[i];
            const account = this.account();
            const currencyId = this.safeString2(entry, 'marginCoin', 'coin');
            const code = this.safeCurrencyCode(currencyId);
            const borrow = this.safeString(entry, 'borrow');
            if (borrow !== undefined) {
                const interest = this.safeString(entry, 'interest');
                account['free'] = this.safeString(entry, 'transferable');
                account['total'] = this.safeString(entry, 'totalAmount');
                account['debt'] = Precise.stringAdd(borrow, interest);
            }
            else {
                // Use transferable instead of available for swap and margin https://github.com/ccxt/ccxt/pull/19127
                const spotAccountFree = this.safeString(entry, 'available');
                const contractAccountFree = this.safeString(entry, 'maxTransferOut');
                if (contractAccountFree !== undefined) {
                    account['free'] = contractAccountFree;
                    account['total'] = this.safeString(entry, 'accountEquity');
                }
                else {
                    account['free'] = spotAccountFree;
                    const frozen = this.safeString(entry, 'frozen');
                    const locked = this.safeString(entry, 'locked');
                    account['used'] = Precise.stringAdd(frozen, locked);
                }
            }
            result[code] = account;
        }
        return this.safeBalance(result);
    }
    parseOrderStatus(status) {
        const statuses = {
            'new': 'open',
            'init': 'open',
            'not_trigger': 'open',
            'partial_fill': 'open',
            'partially_fill': 'open',
            'triggered': 'closed',
            'full_fill': 'closed',
            'filled': 'closed',
            'fail_trigger': 'rejected',
            'cancel': 'canceled',
            'cancelled': 'canceled',
            'canceled': 'canceled',
            'live': 'open',
            'fail_execute': 'rejected',
            'executed': 'closed',
        };
        return this.safeString(statuses, status, status);
    }
    parseOrder(order, market = undefined) {
        //
        // createOrder, editOrder, closePosition
        //
        //     {
        //         "clientOid": "abe95dbe-6081-4a6f-a2d3-ae49601cd479",
        //         "orderId": null
        //     }
        //
        // createOrders
        //
        //     [
        //         {
        //             "orderId": "1111397214281175046",
        //             "clientOid": "766d3fc3-7321-4406-a689-15c9987a2e75"
        //         },
        //         {
        //             "orderId": "",
        //             "clientOid": "d1b75cb3-cc15-4ede-ad4c-3937396f75ab",
        //             "errorMsg": "less than the minimum amount 5 USDT",
        //             "errorCode": "45110"
        //         },
        //     ]
        //
        // spot, swap, future and spot margin: cancelOrder, cancelOrders
        //
        //     {
        //         "orderId": "1098758604547850241",
        //         "clientOid": "1098758604585598977"
        //     }
        //
        // spot trigger: cancelOrder
        //
        //     {
        //         "result": "success"
        //     }
        //
        // spot: fetchOrder
        //
        //     {
        //         "userId": "7264631750",
        //         "symbol": "BTCUSDT",
        //         "orderId": "1111461743123927040",
        //         "clientOid": "63f95110-93b5-4309-8f77-46339f1bcf3c",
        //         "price": "25000.0000000000000000",
        //         "size": "0.0002000000000000",
        //         "orderType": "limit",
        //         "side": "buy",
        //         "status": "live",
        //         "priceAvg": "0",
        //         "baseVolume": "0.0000000000000000",
        //         "quoteVolume": "0.0000000000000000",
        //         "enterPointSource": "API",
        //         "feeDetail": "",
        //         "orderSource": "normal",
        //         "cTime": "1700719050198",
        //         "uTime": "1700719050198"
        //     }
        //
        // swap and future: fetchOrder
        //
        //     {
        //         "symbol": "BTCUSDT",
        //         "size": "0.001",
        //         "orderId": "1111465253393825792",
        //         "clientOid": "1111465253431574529",
        //         "baseVolume": "0",
        //         "fee": "0",
        //         "price": "27000",
        //         "priceAvg": "",
        //         "state": "live",
        //         "side": "buy",
        //         "force": "gtc",
        //         "totalProfits": "0",
        //         "posSide": "long",
        //         "marginCoin": "USDT",
        //         "presetStopSurplusPrice": "",
        //         "presetStopLossPrice": "",
        //         "quoteVolume": "0",
        //         "orderType": "limit",
        //         "leverage": "20",
        //         "marginMode": "crossed",
        //         "reduceOnly": "NO",
        //         "enterPointSource": "API",
        //         "tradeSide": "open",
        //         "posMode": "hedge_mode",
        //         "orderSource": "normal",
        //         "cTime": "1700719887120",
        //         "uTime": "1700719887120"
        //     }
        //
        // spot: fetchOpenOrders
        //
        //     {
        //         "userId": "7264631750",
        //         "symbol": "BTCUSDT",
        //         "orderId": "1111499608327360513",
        //         "clientOid": "d0d4dad5-18d0-4869-a074-ec40bb47cba6",
        //         "priceAvg": "25000.0000000000000000",
        //         "size": "0.0002000000000000",
        //         "orderType": "limit",
        //         "side": "buy",
        //         "status": "live",
        //         "basePrice": "0",
        //         "baseVolume": "0.0000000000000000",
        //         "quoteVolume": "0.0000000000000000",
        //         "enterPointSource": "WEB",
        //         "orderSource": "normal",
        //         "cTime": "1700728077966",
        //         "uTime": "1700728077966"
        //     }
        //
        // spot stop: fetchOpenOrders, fetchCanceledAndClosedOrders
        //
        //     {
        //         "orderId": "1111503385931620352",
        //         "clientOid": "1111503385910648832",
        //         "symbol": "BTCUSDT",
        //         "size": "0.0002",
        //         "planType": "AMOUNT",
        //         "executePrice": "25000",
        //         "triggerPrice": "26000",
        //         "status": "live",
        //         "orderType": "limit",
        //         "side": "buy",
        //         "triggerType": "fill_price",
        //         "enterPointSource": "API",
        //         "cTime": "1700728978617",
        //         "uTime": "1700728978617"
        //     }
        //
        // spot margin: fetchOpenOrders, fetchCanceledAndClosedOrders
        //
        //     {
        //         "symbol": "BTCUSDT",
        //         "orderType": "limit",
        //         "enterPointSource": "WEB",
        //         "orderId": "1111506377509580801",
        //         "clientOid": "2043a3b59a60445f9d9f7365bf3e960c",
        //         "loanType": "autoLoanAndRepay",
        //         "price": "25000",
        //         "side": "buy",
        //         "status": "live",
        //         "baseSize": "0.0002",
        //         "quoteSize": "5",
        //         "priceAvg": "0",
        //         "size": "0",
        //         "amount": "0",
        //         "force": "gtc",
        //         "cTime": "1700729691866",
        //         "uTime": "1700729691866"
        //     }
        //
        // swap: fetchOpenOrders, fetchCanceledAndClosedOrders
        //
        //     {
        //         "symbol": "BTCUSDT",
        //         "size": "0.002",
        //         "orderId": "1111488897767604224",
        //         "clientOid": "1111488897805352960",
        //         "baseVolume": "0",
        //         "fee": "0",
        //         "price": "25000",
        //         "priceAvg": "",
        //         "status": "live",
        //         "side": "buy",
        //         "force": "gtc",
        //         "totalProfits": "0",
        //         "posSide": "long",
        //         "marginCoin": "USDT",
        //         "quoteVolume": "0",
        //         "leverage": "20",
        //         "marginMode": "crossed",
        //         "enterPointSource": "web",
        //         "tradeSide": "open",
        //         "posMode": "hedge_mode",
        //         "orderType": "limit",
        //         "orderSource": "normal",
        //         "presetStopSurplusPrice": "",
        //         "presetStopLossPrice": "",
        //         "reduceOnly": "NO",
        //         "cTime": "1700725524378",
        //         "uTime": "1700725524378"
        //     }
        //
        // swap stop: fetchOpenOrders
        //
        //     {
        //         "planType": "normal_plan",
        //         "symbol": "BTCUSDT",
        //         "size": "0.001",
        //         "orderId": "1111491399869075457",
        //         "clientOid": "1111491399869075456",
        //         "price": "27000",
        //         "callbackRatio": "",
        //         "triggerPrice": "24000",
        //         "triggerType": "mark_price",
        //         "planStatus": "live",
        //         "side": "buy",
        //         "posSide": "long",
        //         "marginCoin": "USDT",
        //         "marginMode": "crossed",
        //         "enterPointSource": "API",
        //         "tradeSide": "open",
        //         "posMode": "hedge_mode",
        //         "orderType": "limit",
        //         "stopSurplusTriggerPrice": "",
        //         "stopSurplusExecutePrice": "",
        //         "stopSurplusTriggerType": "fill_price",
        //         "stopLossTriggerPrice": "",
        //         "stopLossExecutePrice": "",
        //         "stopLossTriggerType": "fill_price",
        //         "cTime": "1700726120917",
        //         "uTime": "1700726120917"
        //     }
        //
        // spot: fetchCanceledAndClosedOrders
        //
        //     {
        //         "userId": "7264631750",
        //         "symbol": "BTCUSDT",
        //         "orderId": "1111499608327360513",
        //         "clientOid": "d0d4dad5-18d0-4869-a074-ec40bb47cba6",
        //         "price": "25000.0000000000000000",
        //         "size": "0.0002000000000000",
        //         "orderType": "limit",
        //         "side": "buy",
        //         "status": "cancelled",
        //         "priceAvg": "0",
        //         "baseVolume": "0.0000000000000000",
        //         "quoteVolume": "0.0000000000000000",
        //         "enterPointSource": "WEB",
        //         "feeDetail": "",
        //         "orderSource": "normal",
        //         "cTime": "1700728077966",
        //         "uTime": "1700728911471"
        //     }
        //
        // swap stop: fetchCanceledAndClosedOrders
        //
        //     {
        //         "planType": "normal_plan",
        //         "symbol": "BTCUSDT",
        //         "size": "0.001",
        //         "orderId": "1111491399869075457",
        //         "clientOid": "1111491399869075456",
        //         "planStatus": "cancelled",
        //         "price": "27000",
        //         "feeDetail": null,
        //         "baseVolume": "0",
        //         "callbackRatio": "",
        //         "triggerPrice": "24000",
        //         "triggerType": "mark_price",
        //         "side": "buy",
        //         "posSide": "long",
        //         "marginCoin": "USDT",
        //         "marginMode": "crossed",
        //         "enterPointSource": "API",
        //         "tradeSide": "open",
        //         "posMode": "hedge_mode",
        //         "orderType": "limit",
        //         "stopSurplusTriggerPrice": "",
        //         "stopSurplusExecutePrice": "",
        //         "stopSurplusTriggerType": "fill_price",
        //         "stopLossTriggerPrice": "",
        //         "stopLossExecutePrice": "",
        //         "stopLossTriggerType": "fill_price",
        //         "cTime": "1700726120917",
        //         "uTime": "1700727879652"
        //     }
        //
        const errorMessage = this.safeString(order, 'errorMsg');
        if (errorMessage !== undefined) {
            return this.safeOrder({
                'info': order,
                'id': this.safeString(order, 'orderId'),
                'clientOrderId': this.safeString2(order, 'clientOrderId', 'clientOid'),
                'status': 'rejected',
            }, market);
        }
        const isContractOrder = ('posSide' in order);
        let marketType = isContractOrder ? 'contract' : 'spot';
        if (market !== undefined) {
            marketType = market['type'];
        }
        const marketId = this.safeString(order, 'symbol');
        market = this.safeMarket(marketId, market, undefined, marketType);
        const timestamp = this.safeInteger2(order, 'cTime', 'ctime');
        const updateTimestamp = this.safeInteger(order, 'uTime');
        const rawStatus = this.safeString2(order, 'status', 'state');
        let fee = undefined;
        const feeCostString = this.safeString(order, 'fee');
        if (feeCostString !== undefined) {
            // swap
            fee = {
                'cost': this.parseNumber(Precise.stringAbs(feeCostString)),
                'currency': market['settle'],
            };
        }
        const feeDetail = this.safeValue(order, 'feeDetail');
        if (feeDetail !== undefined) {
            const parsedFeeDetail = JSON.parse(feeDetail);
            const feeValues = Object.values(parsedFeeDetail);
            let feeObject = undefined;
            for (let i = 0; i < feeValues.length; i++) {
                const feeValue = feeValues[i];
                if (this.safeValue(feeValue, 'feeCoinCode') !== undefined) {
                    feeObject = feeValue;
                    break;
                }
            }
            fee = {
                'cost': this.parseNumber(Precise.stringAbs(this.safeString(feeObject, 'totalFee'))),
                'currency': this.safeCurrencyCode(this.safeString(feeObject, 'feeCoinCode')),
            };
        }
        let postOnly = undefined;
        let timeInForce = this.safeStringUpper(order, 'force');
        if (timeInForce === 'POST_ONLY') {
            postOnly = true;
            timeInForce = 'PO';
        }
        let reduceOnly = undefined;
        const reduceOnlyRaw = this.safeString(order, 'reduceOnly');
        if (reduceOnlyRaw !== undefined) {
            reduceOnly = (reduceOnlyRaw === 'NO') ? false : true;
        }
        let price = undefined;
        let average = undefined;
        const basePrice = this.safeString(order, 'basePrice');
        if (basePrice !== undefined) {
            // for spot fetchOpenOrders, the price is priceAvg and the filled price is basePrice
            price = this.safeString(order, 'priceAvg');
            average = this.safeString(order, 'basePrice');
        }
        else {
            price = this.safeString2(order, 'price', 'executePrice');
            average = this.safeString(order, 'priceAvg');
        }
        let size = undefined;
        let filled = undefined;
        const baseSize = this.safeString(order, 'baseSize');
        if (baseSize !== undefined) {
            // for spot margin fetchOpenOrders, the order size is baseSize and the filled amount is size
            size = baseSize;
            filled = this.safeString(order, 'size');
        }
        else {
            size = this.safeString(order, 'size');
            filled = this.safeString(order, 'baseVolume');
        }
        let side = this.safeString(order, 'side');
        const posMode = this.safeString(order, 'posMode');
        if (posMode === 'hedge_mode' && reduceOnly) {
            side = (side === 'buy') ? 'sell' : 'buy';
            // on bitget hedge mode if the position is long the side is always buy, and if the position is short the side is always sell
            // so the side of the reduceOnly order is inversed
        }
        return this.safeOrder({
            'info': order,
            'id': this.safeString2(order, 'orderId', 'data'),
            'clientOrderId': this.safeString2(order, 'clientOrderId', 'clientOid'),
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'lastTradeTimestamp': updateTimestamp,
            'lastUpdateTimestamp': updateTimestamp,
            'symbol': market['symbol'],
            'type': this.safeString(order, 'orderType'),
            'side': side,
            'price': price,
            'amount': size,
            'cost': this.safeString2(order, 'quoteVolume', 'quoteSize'),
            'average': average,
            'filled': filled,
            'remaining': undefined,
            'timeInForce': timeInForce,
            'postOnly': postOnly,
            'reduceOnly': reduceOnly,
            'stopPrice': this.safeNumber(order, 'triggerPrice'),
            'triggerPrice': this.safeNumber(order, 'triggerPrice'),
            'takeProfitPrice': this.safeNumber2(order, 'presetStopSurplusPrice', 'stopSurplusTriggerPrice'),
            'stopLossPrice': this.safeNumber2(order, 'presetStopLossPrice', 'stopLossTriggerPrice'),
            'status': this.parseOrderStatus(rawStatus),
            'fee': fee,
            'trades': undefined,
        }, market);
    }
    async createMarketBuyOrderWithCost(symbol, cost, params = {}) {
        /**
         * @method
         * @name bitget#createMarketBuyOrderWithCost
         * @description create a market buy order by providing the symbol and cost
         * @see https://www.bitget.com/api-doc/spot/trade/Place-Order
         * @see https://www.bitget.com/api-doc/margin/cross/trade/Cross-Place-Order
         * @see https://www.bitget.com/api-doc/margin/isolated/trade/Isolated-Place-Order
         * @param {string} symbol unified symbol of the market to create an order in
         * @param {float} cost how much you want to trade in units of the quote currency
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} an [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        if (!market['spot']) {
            throw new MarketTypeNotSupportedError(market['type']).addMessage('It supports spot orders only').exchange(this.id);
        }
        params['createMarketBuyOrderRequiresPrice'] = false;
        return await this.createOrder(symbol, 'market', 'buy', cost, undefined, params);
    }
    async createOrder(symbol, type, side, amount, price = undefined, params = {}) {
        /**
         * @method
         * @name bitget#createOrder
         * @description create a trade order
         * @see https://www.bitget.com/api-doc/spot/trade/Place-Order
         * @see https://www.bitget.com/api-doc/spot/plan/Place-Plan-Order
         * @see https://www.bitget.com/api-doc/contract/trade/Place-Order
         * @see https://www.bitget.com/api-doc/contract/plan/Place-Tpsl-Order
         * @see https://www.bitget.com/api-doc/contract/plan/Place-Plan-Order
         * @see https://www.bitget.com/api-doc/margin/cross/trade/Cross-Place-Order
         * @see https://www.bitget.com/api-doc/margin/isolated/trade/Isolated-Place-Order
         * @param {string} symbol unified symbol of the market to create an order in
         * @param {string} type 'market' or 'limit'
         * @param {string} side 'buy' or 'sell' or 'open_long' or 'open_short' or 'close_long' or 'close_short'
         * @param {float} amount how much you want to trade in units of the base currency
         * @param {float} [price] the price at which the order is to be fullfilled, in units of the quote currency, ignored in market orders
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {float} [params.cost] *spot only* how much you want to trade in units of the quote currency, for market buy orders only
         * @param {float} [params.triggerPrice] *swap only* The price at which a trigger order is triggered at
         * @param {float} [params.stopLossPrice] *swap only* The price at which a stop loss order is triggered at
         * @param {float} [params.takeProfitPrice] *swap only* The price at which a take profit order is triggered at
         * @param {object} [params.takeProfit] *takeProfit object in params* containing the triggerPrice at which the attached take profit order will be triggered (perpetual swap markets only)
         * @param {float} [params.takeProfit.triggerPrice] *swap only* take profit trigger price
         * @param {object} [params.stopLoss] *stopLoss object in params* containing the triggerPrice at which the attached stop loss order will be triggered (perpetual swap markets only)
         * @param {float} [params.stopLoss.triggerPrice] *swap only* stop loss trigger price
         * @param {string} [params.timeInForce] "GTC", "IOC", "FOK", or "PO"
         * @param {string} [params.marginMode] 'isolated' or 'cross' for spot margin trading
         * @param {string} [params.loanType] *spot margin only* 'normal', 'autoLoan', 'autoRepay', or 'autoLoanAndRepay' default is 'normal'
         * @param {string} [params.holdSide] *contract stopLossPrice, takeProfitPrice only* Two-way position: ('long' or 'short'), one-way position: ('buy' or 'sell')
         * @param {float} [params.stopLoss.price] *swap only* the execution price for a stop loss attached to a trigger order
         * @param {float} [params.takeProfit.price] *swap only* the execution price for a take profit attached to a trigger order
         * @param {string} [params.stopLoss.type] *swap only* the type for a stop loss attached to a trigger order, 'fill_price', 'index_price' or 'mark_price', default is 'mark_price'
         * @param {string} [params.takeProfit.type] *swap only* the type for a take profit attached to a trigger order, 'fill_price', 'index_price' or 'mark_price', default is 'mark_price'
         * @param {string} [params.trailingPercent] *swap and future only* the percent to trail away from the current market price, rate can not be greater than 10
         * @param {string} [params.trailingTriggerPrice] *swap and future only* the price to trigger a trailing stop order, default uses the price argument
         * @param {string} [params.triggerType] *swap and future only* 'fill_price', 'mark_price' or 'index_price'
         * @param {boolean} [params.oneWayMode] *swap and future only* required to set this to true in one_way_mode and you can leave this as undefined in hedge_mode, can adjust the mode using the setPositionMode() method
         * @returns {object} an [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const market = this.market(symbol);
        const marginParams = this.handleMarginModeAndParams('createOrder', params);
        const marginMode = marginParams[0];
        const triggerPrice = this.safeValue2(params, 'stopPrice', 'triggerPrice');
        const stopLossTriggerPrice = this.safeValue(params, 'stopLossPrice');
        const takeProfitTriggerPrice = this.safeValue(params, 'takeProfitPrice');
        const trailingPercent = this.safeString2(params, 'trailingPercent', 'callbackRatio');
        const isTrailingPercentOrder = trailingPercent !== undefined;
        const isTriggerOrder = triggerPrice !== undefined;
        const isStopLossTriggerOrder = stopLossTriggerPrice !== undefined;
        const isTakeProfitTriggerOrder = takeProfitTriggerPrice !== undefined;
        const isStopLossOrTakeProfitTrigger = isStopLossTriggerOrder || isTakeProfitTriggerOrder;
        const request = this.createOrderRequest(symbol, type, side, amount, price, params);
        let response = undefined;
        if (market['spot']) {
            if (isTriggerOrder) {
                response = await this.privateSpotPostV2SpotTradePlacePlanOrder(request);
            }
            else if (marginMode === 'isolated') {
                response = await this.privateMarginPostV2MarginIsolatedPlaceOrder(request);
            }
            else if (marginMode === 'cross') {
                response = await this.privateMarginPostV2MarginCrossedPlaceOrder(request);
            }
            else {
                response = await this.privateSpotPostV2SpotTradePlaceOrder(request);
            }
        }
        else {
            if (isTriggerOrder || isTrailingPercentOrder) {
                response = await this.privateMixPostV2MixOrderPlacePlanOrder(request);
            }
            else if (isStopLossOrTakeProfitTrigger) {
                response = await this.privateMixPostV2MixOrderPlaceTpslOrder(request);
            }
            else {
                response = await this.privateMixPostV2MixOrderPlaceOrder(request);
            }
        }
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1645932209602,
        //         "data": {
        //             "orderId": "881669078313766912",
        //             "clientOid": "iauIBf#a45b595f96474d888d0ada"
        //         }
        //     }
        //
        const data = this.safeValue(response, 'data', {});
        return this.parseOrder(data, market);
    }
    createOrderRequest(symbol, type, side, amount, price = undefined, params = {}) {
        const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        let market = undefined;
        if (sandboxMode) {
            const sandboxSymbol = this.convertSymbolForSandbox(symbol);
            market = this.market(sandboxSymbol);
        }
        else {
            market = this.market(symbol);
        }
        let marketType = undefined;
        let marginMode = undefined;
        [marketType, params] = this.handleMarketTypeAndParams('createOrder', market, params);
        [marginMode, params] = this.handleMarginModeAndParams('createOrder', params);
        const request = {
            'symbol': market['id'],
            'orderType': type,
        };
        const isMarketOrder = type === 'market';
        const triggerPrice = this.safeValue2(params, 'stopPrice', 'triggerPrice');
        const stopLossTriggerPrice = this.safeValue(params, 'stopLossPrice');
        const takeProfitTriggerPrice = this.safeValue(params, 'takeProfitPrice');
        const stopLoss = this.safeValue(params, 'stopLoss');
        const takeProfit = this.safeValue(params, 'takeProfit');
        const isTriggerOrder = triggerPrice !== undefined;
        const isStopLossTriggerOrder = stopLossTriggerPrice !== undefined;
        const isTakeProfitTriggerOrder = takeProfitTriggerPrice !== undefined;
        const isStopLoss = stopLoss !== undefined;
        const isTakeProfit = takeProfit !== undefined;
        const isStopLossOrTakeProfitTrigger = isStopLossTriggerOrder || isTakeProfitTriggerOrder;
        const isStopLossOrTakeProfit = isStopLoss || isTakeProfit;
        const trailingTriggerPrice = this.safeString(params, 'trailingTriggerPrice', this.numberToString(price));
        const trailingPercent = this.safeString2(params, 'trailingPercent', 'callbackRatio');
        const isTrailingPercentOrder = trailingPercent !== undefined;
        if (this.sum(isTriggerOrder, isStopLossTriggerOrder, isTakeProfitTriggerOrder, isTrailingPercentOrder) > 1) {
            throw new InvalidParamsError('createOrder() params can only contain one of triggerPrice, stopLossPrice, takeProfitPrice, trailingPercent')
                .exchange(this.id);
        }
        if (type === 'limit') {
            request['price'] = this.priceToPrecision(symbol, price);
        }
        const triggerType = this.safeString(params, 'triggerType', 'mark_price');
        const reduceOnly = this.safeBool(params, 'reduceOnly', false);
        const clientOrderId = this.safeString2(params, 'clientOid', 'clientOrderId');
        const exchangeSpecificTifParam = this.safeString2(params, 'force', 'timeInForce');
        let postOnly = undefined;
        [postOnly, params] = this.handlePostOnly(isMarketOrder, exchangeSpecificTifParam === 'post_only', params);
        const defaultTimeInForce = this.safeStringUpper(this.options, 'defaultTimeInForce');
        const timeInForce = this.safeStringUpper(params, 'timeInForce', defaultTimeInForce);
        if (postOnly) {
            request['force'] = 'post_only';
        }
        else if (timeInForce === 'GTC') {
            request['force'] = 'GTC';
        }
        else if (timeInForce === 'FOK') {
            request['force'] = 'FOK';
        }
        else if (timeInForce === 'IOC') {
            request['force'] = 'IOC';
        }
        params = this.omit(params, ['stopPrice', 'triggerType', 'stopLossPrice', 'takeProfitPrice', 'stopLoss', 'takeProfit', 'postOnly', 'reduceOnly', 'clientOrderId', 'trailingPercent', 'trailingTriggerPrice']);
        if ((marketType === 'swap') || (marketType === 'future')) {
            request['marginCoin'] = market['settleId'];
            request['size'] = this.amountToPrecision(symbol, amount);
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(market, params);
            request['productType'] = productType;
            if (clientOrderId !== undefined) {
                request['clientOid'] = clientOrderId;
            }
            if (isTriggerOrder || isStopLossOrTakeProfitTrigger || isTrailingPercentOrder) {
                request['triggerType'] = triggerType;
            }
            if (isTrailingPercentOrder) {
                if (!isMarketOrder) {
                    throw new MarketTypeNotSupportedError(type)
                        .addMessage('createOrder() bitget trailing orders must be market orders')
                        .exchange(this.id);
                }
                if (trailingTriggerPrice === undefined) {
                    throw new InvalidParamsError('createOrder() bitget trailing orders must have a trailingTriggerPrice param')
                        .exchange(this.id);
                }
                request['planType'] = 'track_plan';
                request['triggerPrice'] = this.priceToPrecision(symbol, trailingTriggerPrice);
                request['callbackRatio'] = trailingPercent;
            }
            else if (isTriggerOrder) {
                request['planType'] = 'normal_plan';
                request['triggerPrice'] = this.priceToPrecision(symbol, triggerPrice);
                if (price !== undefined) {
                    request['executePrice'] = this.priceToPrecision(symbol, price);
                }
                if (isStopLoss) {
                    const slTriggerPrice = this.safeNumber2(stopLoss, 'triggerPrice', 'stopPrice');
                    request['stopLossTriggerPrice'] = this.priceToPrecision(symbol, slTriggerPrice);
                    const slPrice = this.safeNumber(stopLoss, 'price');
                    request['stopLossExecutePrice'] = this.priceToPrecision(symbol, slPrice);
                    const slType = this.safeString(stopLoss, 'type', 'mark_price');
                    request['stopLossTriggerType'] = slType;
                }
                if (isTakeProfit) {
                    const tpTriggerPrice = this.safeNumber2(takeProfit, 'triggerPrice', 'stopPrice');
                    request['stopSurplusTriggerPrice'] = this.priceToPrecision(symbol, tpTriggerPrice);
                    const tpPrice = this.safeNumber(takeProfit, 'price');
                    request['stopSurplusExecutePrice'] = this.priceToPrecision(symbol, tpPrice);
                    const tpType = this.safeString(takeProfit, 'type', 'mark_price');
                    request['stopSurplusTriggerType'] = tpType;
                }
            }
            else if (isStopLossOrTakeProfitTrigger) {
                if (!isMarketOrder) {
                    throw new MarketTypeNotSupportedError(type)
                        .addMessage('createOrder() bitget stopLoss or takeProfit orders must be market orders')
                        .exchange(this.id);
                }
                request['holdSide'] = (side === 'buy') ? 'long' : 'short';
                if (isStopLossTriggerOrder) {
                    request['triggerPrice'] = this.priceToPrecision(symbol, stopLossTriggerPrice);
                    request['planType'] = 'pos_loss';
                }
                else if (isTakeProfitTriggerOrder) {
                    request['triggerPrice'] = this.priceToPrecision(symbol, takeProfitTriggerPrice);
                    request['planType'] = 'pos_profit';
                }
            }
            else {
                if (isStopLoss) {
                    const slTriggerPrice = this.safeValue2(stopLoss, 'triggerPrice', 'stopPrice');
                    request['presetStopLossPrice'] = this.priceToPrecision(symbol, slTriggerPrice);
                }
                if (isTakeProfit) {
                    const tpTriggerPrice = this.safeValue2(takeProfit, 'triggerPrice', 'stopPrice');
                    request['presetStopSurplusPrice'] = this.priceToPrecision(symbol, tpTriggerPrice);
                }
            }
            if (!isStopLossOrTakeProfitTrigger) {
                if (marginMode === undefined) {
                    marginMode = 'cross';
                }
                const marginModeRequest = (marginMode === 'cross') ? 'crossed' : 'isolated';
                request['marginMode'] = marginModeRequest;
                const oneWayMode = this.safeBool(params, 'oneWayMode', false);
                params = this.omit(params, 'oneWayMode');
                let requestSide = side;
                if (reduceOnly) {
                    if (oneWayMode) {
                        request['reduceOnly'] = 'YES';
                    }
                    else {
                        // on bitget hedge mode if the position is long the side is always buy, and if the position is short the side is always sell
                        requestSide = (side === 'buy') ? 'sell' : 'buy';
                        request['tradeSide'] = 'Close';
                    }
                }
                else {
                    if (!oneWayMode) {
                        request['tradeSide'] = 'Open';
                    }
                }
                request['side'] = requestSide;
            }
        }
        else if (marketType === 'spot') {
            if (isStopLossOrTakeProfitTrigger || isStopLossOrTakeProfit) {
                throw new TradeError()
                    .addMessage('createOrder() does not support stop loss/take profit orders on spot markets, only swap markets')
                    .exchange(this.id);
            }
            request['side'] = side;
            let quantity = undefined;
            let planType = undefined;
            let createMarketBuyOrderRequiresPrice = true;
            [createMarketBuyOrderRequiresPrice, params] = this.handleOptionAndParams(params, 'createOrder', 'createMarketBuyOrderRequiresPrice', true);
            if (isMarketOrder && (side === 'buy')) {
                planType = 'total';
                const cost = this.safeNumber(params, 'cost');
                params = this.omit(params, 'cost');
                if (cost !== undefined) {
                    quantity = this.costToPrecision(symbol, cost);
                }
                else if (createMarketBuyOrderRequiresPrice) {
                    if (price === undefined) {
                        throw new InvalidParamsError('createOrder() requires the price argument for market buy orders to calculate the total cost to spend (amount * price), alternatively set the createMarketBuyOrderRequiresPrice option or param to false and pass the cost to spend in the amount argument')
                            .exchange(this.id);
                    }
                    else {
                        const amountString = this.numberToString(amount);
                        const priceString = this.numberToString(price);
                        const quoteAmount = Precise.stringMul(amountString, priceString);
                        quantity = this.costToPrecision(symbol, quoteAmount);
                    }
                }
                else {
                    quantity = this.costToPrecision(symbol, amount);
                }
            }
            else {
                planType = 'amount';
                quantity = this.amountToPrecision(symbol, amount);
            }
            if (clientOrderId !== undefined) {
                request['clientOid'] = clientOrderId;
            }
            if (marginMode !== undefined) {
                request['loanType'] = 'normal';
                if (createMarketBuyOrderRequiresPrice && isMarketOrder && (side === 'buy')) {
                    request['quoteSize'] = quantity;
                }
                else {
                    request['baseSize'] = quantity;
                }
            }
            else {
                if (quantity !== undefined) {
                    request['size'] = quantity;
                }
                if (triggerPrice !== undefined) {
                    request['planType'] = planType;
                    request['triggerType'] = triggerType;
                    request['triggerPrice'] = this.priceToPrecision(symbol, triggerPrice);
                    if (price !== undefined) {
                        request['executePrice'] = this.priceToPrecision(symbol, price);
                    }
                }
            }
        }
        else {
            throw new MarketTypeNotSupportedError(marketType).exchange(this.id);
        }
        return this.extend(request, params);
    }
    async createOrders(orders, params = {}) {
        /**
         * @method
         * @name bitget#createOrders
         * @description create a list of trade orders (all orders should be of the same symbol)
         * @see https://www.bitget.com/api-doc/spot/trade/Batch-Place-Orders
         * @see https://www.bitget.com/api-doc/contract/trade/Batch-Order
         * @see https://www.bitget.com/api-doc/margin/isolated/trade/Isolated-Batch-Order
         * @see https://www.bitget.com/api-doc/margin/cross/trade/Cross-Batch-Order
         * @param {Array} orders list of orders to create, each object should contain the parameters required by createOrder, namely symbol, type, side, amount, price and params
         * @param {object} [params] extra parameters specific to the api endpoint
         * @returns {object} an [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const ordersRequests = [];
        let symbol = undefined;
        let marginMode = undefined;
        for (let i = 0; i < orders.length; i++) {
            const rawOrder = orders[i];
            const marketId = this.safeString(rawOrder, 'symbol');
            if (symbol === undefined) {
                symbol = marketId;
            }
            else {
                if (symbol !== marketId) {
                    throw new InvalidParamsError('createOrders() requires all orders to have the same symbol')
                        .exchange(this.id);
                }
            }
            const type = this.safeString(rawOrder, 'type');
            const side = this.safeString(rawOrder, 'side');
            const amount = this.safeValue(rawOrder, 'amount');
            const price = this.safeValue(rawOrder, 'price');
            const orderParams = this.safeValue(rawOrder, 'params', {});
            const marginResult = this.handleMarginModeAndParams('createOrders', orderParams);
            const currentMarginMode = marginResult[0];
            if (currentMarginMode !== undefined) {
                if (marginMode === undefined) {
                    marginMode = currentMarginMode;
                }
                else {
                    if (marginMode !== currentMarginMode) {
                        throw new InvalidParamsError('createOrders() requires all orders to have the same margin mode (isolated or cross)')
                            .exchange(this.id);
                    }
                }
            }
            const orderRequest = this.createOrderRequest(marketId, type, side, amount, price, orderParams);
            ordersRequests.push(orderRequest);
        }
        const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        let market = undefined;
        if (sandboxMode) {
            const sandboxSymbol = this.convertSymbolForSandbox(symbol);
            market = this.market(sandboxSymbol);
        }
        else {
            market = this.market(symbol);
        }
        const request = {
            'symbol': market['id'],
            'orderList': ordersRequests,
        };
        let response = undefined;
        if ((market['swap']) || (market['future'])) {
            if (marginMode === undefined) {
                marginMode = 'cross';
            }
            const marginModeRequest = (marginMode === 'cross') ? 'crossed' : 'isolated';
            request['marginMode'] = marginModeRequest;
            request['marginCoin'] = market['settleId'];
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(market, params);
            request['productType'] = productType;
            response = await this.privateMixPostV2MixOrderBatchPlaceOrder(request);
        }
        else if (marginMode === 'isolated') {
            response = await this.privateMarginPostV2MarginIsolatedBatchPlaceOrder(request);
        }
        else if (marginMode === 'cross') {
            response = await this.privateMarginPostV2MarginCrossedBatchPlaceOrder(request);
        }
        else {
            response = await this.privateSpotPostV2SpotTradeBatchOrders(request);
        }
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700703539416,
        //         "data": {
        //             "successList": [
        //                 {
        //                     "orderId": "1111397214281175046",
        //                     "clientOid": "766d3fc3-7321-4406-a689-15c9987a2e75"
        //                 },
        //             ],
        //             "failureList": [
        //                 {
        //                     "orderId": "",
        //                     "clientOid": "d1b75cb3-cc15-4ede-ad4c-3937396f75ab",
        //                     "errorMsg": "less than the minimum amount 5 USDT",
        //                     "errorCode": "45110"
        //                 },
        //             ]
        //         }
        //     }
        //
        const data = this.safeValue(response, 'data', {});
        const failure = this.safeValue(data, 'failureList', []);
        const orderInfo = this.safeValue(data, 'successList', []);
        const both = this.arrayConcat(orderInfo, failure);
        return this.parseOrders(both, market);
    }
    async editOrder(id, symbol, type, side, amount = undefined, price = undefined, params = {}) {
        /**
         * @method
         * @name bitget#editOrder
         * @description edit a trade order
         * @see https://www.bitget.com/api-doc/spot/plan/Modify-Plan-Order
         * @see https://www.bitget.com/api-doc/contract/trade/Modify-Order
         * @see https://www.bitget.com/api-doc/contract/plan/Modify-Tpsl-Order
         * @see https://www.bitget.com/api-doc/contract/plan/Modify-Plan-Order
         * @param {string} id cancel order id
         * @param {string} symbol unified symbol of the market to create an order in
         * @param {string} type 'market' or 'limit'
         * @param {string} side 'buy' or 'sell'
         * @param {float} amount how much you want to trade in units of the base currency
         * @param {float} [price] the price at which the order is to be fullfilled, in units of the base currency, ignored in market orders
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {float} [params.triggerPrice] the price that a trigger order is triggered at
         * @param {float} [params.stopLossPrice] *swap only* The price at which a stop loss order is triggered at
         * @param {float} [params.takeProfitPrice] *swap only* The price at which a take profit order is triggered at
         * @param {object} [params.takeProfit] *takeProfit object in params* containing the triggerPrice at which the attached take profit order will be triggered (perpetual swap markets only)
         * @param {float} [params.takeProfit.triggerPrice] *swap only* take profit trigger price
         * @param {object} [params.stopLoss] *stopLoss object in params* containing the triggerPrice at which the attached stop loss order will be triggered (perpetual swap markets only)
         * @param {float} [params.stopLoss.triggerPrice] *swap only* stop loss trigger price
         * @param {float} [params.stopLoss.price] *swap only* the execution price for a stop loss attached to a trigger order
         * @param {float} [params.takeProfit.price] *swap only* the execution price for a take profit attached to a trigger order
         * @param {string} [params.stopLoss.type] *swap only* the type for a stop loss attached to a trigger order, 'fill_price', 'index_price' or 'mark_price', default is 'mark_price'
         * @param {string} [params.takeProfit.type] *swap only* the type for a take profit attached to a trigger order, 'fill_price', 'index_price' or 'mark_price', default is 'mark_price'
         * @param {string} [params.trailingPercent] *swap and future only* the percent to trail away from the current market price, rate can not be greater than 10
         * @param {string} [params.trailingTriggerPrice] *swap and future only* the price to trigger a trailing stop order, default uses the price argument
         * @param {string} [params.newTriggerType] *swap and future only* 'fill_price', 'mark_price' or 'index_price'
         * @returns {object} an [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        let market = undefined;
        if (sandboxMode) {
            const sandboxSymbol = this.convertSymbolForSandbox(symbol);
            market = this.market(sandboxSymbol);
        }
        else {
            market = this.market(symbol);
        }
        const request = {
            'orderId': id,
        };
        const isMarketOrder = type === 'market';
        const triggerPrice = this.safeValue2(params, 'stopPrice', 'triggerPrice');
        const isTriggerOrder = triggerPrice !== undefined;
        const stopLossPrice = this.safeValue(params, 'stopLossPrice');
        const isStopLossOrder = stopLossPrice !== undefined;
        const takeProfitPrice = this.safeValue(params, 'takeProfitPrice');
        const isTakeProfitOrder = takeProfitPrice !== undefined;
        const stopLoss = this.safeValue(params, 'stopLoss');
        const takeProfit = this.safeValue(params, 'takeProfit');
        const isStopLoss = stopLoss !== undefined;
        const isTakeProfit = takeProfit !== undefined;
        const trailingTriggerPrice = this.safeString(params, 'trailingTriggerPrice', this.numberToString(price));
        const trailingPercent = this.safeString2(params, 'trailingPercent', 'newCallbackRatio');
        const isTrailingPercentOrder = trailingPercent !== undefined;
        if (this.sum(isTriggerOrder, isStopLossOrder, isTakeProfitOrder, isTrailingPercentOrder) > 1) {
            throw new InvalidParamsError('editOrder() params can only contain one of triggerPrice, stopLossPrice, takeProfitPrice, trailingPercent')
                .exchange(this.id);
        }
        const clientOrderId = this.safeString2(params, 'clientOid', 'clientOrderId');
        if (clientOrderId !== undefined) {
            request['clientOid'] = clientOrderId;
        }
        params = this.omit(params, ['stopPrice', 'triggerType', 'stopLossPrice', 'takeProfitPrice', 'stopLoss', 'takeProfit', 'clientOrderId', 'trailingTriggerPrice', 'trailingPercent']);
        let response = undefined;
        if (market['spot']) {
            const editMarketBuyOrderRequiresPrice = this.safeValue(this.options, 'editMarketBuyOrderRequiresPrice', true);
            if (editMarketBuyOrderRequiresPrice && isMarketOrder && (side === 'buy')) {
                if (price === undefined) {
                    throw new InvalidParamsError('editOrder() requires price argument for market buy orders on spot markets to calculate the total amount to spend (amount * price), alternatively set the editMarketBuyOrderRequiresPrice option to false and pass in the cost to spend into the amount parameter')
                        .exchange(this.id);
                }
                else {
                    const amountString = this.numberToString(amount);
                    const priceString = this.numberToString(price);
                    const cost = this.parseNumber(Precise.stringMul(amountString, priceString));
                    request['size'] = this.priceToPrecision(symbol, cost);
                }
            }
            else {
                request['size'] = this.amountToPrecision(symbol, amount);
            }
            request['orderType'] = type;
            request['triggerPrice'] = this.priceToPrecision(symbol, triggerPrice);
            request['executePrice'] = this.priceToPrecision(symbol, price);
            response = await this.privateSpotPostV2SpotTradeModifyPlanOrder(this.extend(request, params));
        }
        else {
            if ((!market['swap']) && (!market['future'])) {
                throw new MarketTypeNotSupportedError(market['type'])
                    .addMessage('editOrder() does not support ' + market['type'] + ' orders')
                    .exchange(this.id);
            }
            request['symbol'] = market['id'];
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(market, params);
            request['productType'] = productType;
            if (!isTakeProfitOrder && !isStopLossOrder) {
                request['newSize'] = this.amountToPrecision(symbol, amount);
                if ((price !== undefined) && !isTrailingPercentOrder) {
                    request['newPrice'] = this.priceToPrecision(symbol, price);
                }
            }
            if (isTrailingPercentOrder) {
                if (!isMarketOrder) {
                    throw new MarketTypeNotSupportedError(type)
                        .addMessage('editOrder() bitget trailing orders must be market orders')
                        .exchange(this.id);
                }
                if (trailingTriggerPrice !== undefined) {
                    request['newTriggerPrice'] = this.priceToPrecision(symbol, trailingTriggerPrice);
                }
                request['newCallbackRatio'] = trailingPercent;
                response = await this.privateMixPostV2MixOrderModifyPlanOrder(this.extend(request, params));
            }
            else if (isTakeProfitOrder || isStopLossOrder) {
                request['marginCoin'] = market['settleId'];
                request['size'] = this.amountToPrecision(symbol, amount);
                request['executePrice'] = this.priceToPrecision(symbol, price);
                if (isStopLossOrder) {
                    request['triggerPrice'] = this.priceToPrecision(symbol, stopLossPrice);
                }
                else if (isTakeProfitOrder) {
                    request['triggerPrice'] = this.priceToPrecision(symbol, takeProfitPrice);
                }
                response = await this.privateMixPostV2MixOrderModifyTpslOrder(this.extend(request, params));
            }
            else if (isTriggerOrder) {
                request['newTriggerPrice'] = this.priceToPrecision(symbol, triggerPrice);
                if (isStopLoss) {
                    const slTriggerPrice = this.safeNumber2(stopLoss, 'triggerPrice', 'stopPrice');
                    request['newStopLossTriggerPrice'] = this.priceToPrecision(symbol, slTriggerPrice);
                    const slPrice = this.safeNumber(stopLoss, 'price');
                    request['newStopLossExecutePrice'] = this.priceToPrecision(symbol, slPrice);
                    const slType = this.safeString(stopLoss, 'type', 'mark_price');
                    request['newStopLossTriggerType'] = slType;
                }
                if (isTakeProfit) {
                    const tpTriggerPrice = this.safeNumber2(takeProfit, 'triggerPrice', 'stopPrice');
                    request['newSurplusTriggerPrice'] = this.priceToPrecision(symbol, tpTriggerPrice);
                    const tpPrice = this.safeNumber(takeProfit, 'price');
                    request['newStopSurplusExecutePrice'] = this.priceToPrecision(symbol, tpPrice);
                    const tpType = this.safeString(takeProfit, 'type', 'mark_price');
                    request['newStopSurplusTriggerType'] = tpType;
                }
                response = await this.privateMixPostV2MixOrderModifyPlanOrder(this.extend(request, params));
            }
            else {
                const defaultNewClientOrderId = this.uuid();
                const newClientOrderId = this.safeString2(params, 'newClientOid', 'newClientOrderId', defaultNewClientOrderId);
                params = this.omit(params, 'newClientOrderId');
                request['newClientOid'] = newClientOrderId;
                if (isStopLoss) {
                    const slTriggerPrice = this.safeValue2(stopLoss, 'triggerPrice', 'stopPrice');
                    request['newPresetStopLossPrice'] = this.priceToPrecision(symbol, slTriggerPrice);
                }
                if (isTakeProfit) {
                    const tpTriggerPrice = this.safeValue2(takeProfit, 'triggerPrice', 'stopPrice');
                    request['newPresetStopSurplusPrice'] = this.priceToPrecision(symbol, tpTriggerPrice);
                }
                response = await this.privateMixPostV2MixOrderModifyOrder(this.extend(request, params));
            }
        }
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700708275737,
        //         "data": {
        //             "clientOid": "abe95dbe-6081-4a6f-a2d3-ae49601cd459",
        //             "orderId": null
        //         }
        //     }
        //
        const data = this.safeValue(response, 'data', {});
        return this.parseOrder(data, market);
    }
    async cancelOrder(id, symbol = undefined, params = {}) {
        /**
         * @method
         * @name bitget#cancelOrder
         * @description cancels an open order
         * @see https://www.bitget.com/api-doc/spot/trade/Cancel-Order
         * @see https://www.bitget.com/api-doc/spot/plan/Cancel-Plan-Order
         * @see https://www.bitget.com/api-doc/contract/trade/Cancel-Order
         * @see https://www.bitget.com/api-doc/contract/plan/Cancel-Plan-Order
         * @see https://www.bitget.com/api-doc/margin/cross/trade/Cross-Cancel-Order
         * @see https://www.bitget.com/api-doc/margin/isolated/trade/Isolated-Cancel-Order
         * @param {string} id order id
         * @param {string} symbol unified symbol of the market the order was made in
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.marginMode] 'isolated' or 'cross' for spot margin trading
         * @param {boolean} [params.stop] set to true for canceling trigger orders
         * @param {string} [params.planType] *swap only* either profit_plan, loss_plan, normal_plan, pos_profit, pos_loss, moving_plan or track_plan
         * @param {boolean} [params.trailing] set to true if you want to cancel a trailing order
         * @returns {object} An [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        if (symbol === undefined) {
            throw new InvalidParamsError('cancelOrder() requires a symbol argument')
                .exchange(this.id);
        }
        await this.loadMarkets();
        const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        let market = undefined;
        if (sandboxMode) {
            const sandboxSymbol = this.convertSymbolForSandbox(symbol);
            market = this.market(sandboxSymbol);
        }
        else {
            market = this.market(symbol);
        }
        let marginMode = undefined;
        let response = undefined;
        [marginMode, params] = this.handleMarginModeAndParams('cancelOrder', params);
        const request = {};
        const trailing = this.safeValue(params, 'trailing');
        const stop = this.safeValue2(params, 'stop', 'trigger');
        params = this.omit(params, ['stop', 'trigger', 'trailing']);
        if (!(market['spot'] && stop)) {
            request['symbol'] = market['id'];
        }
        if (!((market['swap'] || market['future']) && stop)) {
            request['orderId'] = id;
        }
        if ((market['swap']) || (market['future'])) {
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(market, params);
            request['productType'] = productType;
            if (stop || trailing) {
                const orderIdList = [];
                const orderId = {
                    'orderId': id,
                };
                orderIdList.push(orderId);
                request['orderIdList'] = orderIdList;
            }
            if (trailing) {
                const planType = this.safeString(params, 'planType', 'track_plan');
                request['planType'] = planType;
                response = await this.privateMixPostV2MixOrderCancelPlanOrder(this.extend(request, params));
            }
            else if (stop) {
                response = await this.privateMixPostV2MixOrderCancelPlanOrder(this.extend(request, params));
            }
            else {
                response = await this.privateMixPostV2MixOrderCancelOrder(this.extend(request, params));
            }
        }
        else if (market['spot']) {
            if (marginMode !== undefined) {
                if (marginMode === 'isolated') {
                    response = await this.privateMarginPostV2MarginIsolatedCancelOrder(this.extend(request, params));
                }
                else if (marginMode === 'cross') {
                    response = await this.privateMarginPostV2MarginCrossedCancelOrder(this.extend(request, params));
                }
            }
            else {
                if (stop) {
                    response = await this.privateSpotPostV2SpotTradeCancelPlanOrder(this.extend(request, params));
                }
                else {
                    response = await this.privateSpotPostV2SpotTradeCancelOrder(this.extend(request, params));
                }
            }
        }
        else {
            throw new MarketTypeNotSupportedError(market['type'])
                .addMessage('cancelOrder() does not support ' + market['type'] + ' orders')
                .exchange(this.id);
        }
        //
        // spot, swap, future and spot margin
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1697690413177,
        //         "data": {
        //             "orderId": "1098758604547850241",
        //             "clientOid": "1098758604585598977"
        //         }
        //     }
        //
        // swap trigger
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700711311791,
        //         "data": {
        //             "successList": [
        //                 {
        //                     "clientOid": "1111428059067125760",
        //                     "orderId": "1111428059067125761"
        //                 }
        //             ],
        //             "failureList": []
        //         }
        //     }
        //
        // spot trigger
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700711728063,
        //         "data": {
        //             "result": "success"
        //         }
        //     }
        //
        const data = this.safeValue(response, 'data', {});
        let order = undefined;
        if ((market['swap'] || market['future']) && stop) {
            const orderInfo = this.safeValue(data, 'successList', []);
            order = orderInfo[0];
        }
        else {
            order = data;
        }
        return this.parseOrder(order, market);
    }
    async cancelOrders(ids, symbol = undefined, params = {}) {
        /**
         * @method
         * @name bitget#cancelOrders
         * @description cancel multiple orders
         * @see https://www.bitget.com/api-doc/spot/trade/Batch-Cancel-Orders
         * @see https://www.bitget.com/api-doc/contract/trade/Batch-Cancel-Orders
         * @see https://www.bitget.com/api-doc/contract/plan/Cancel-Plan-Order
         * @see https://www.bitget.com/api-doc/margin/cross/trade/Cross-Batch-Cancel-Order
         * @see https://www.bitget.com/api-doc/margin/isolated/trade/Isolated-Batch-Cancel-Orders
         * @param {string[]} ids order ids
         * @param {string} symbol unified market symbol, default is undefined
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.marginMode] 'isolated' or 'cross' for spot margin trading
         * @param {boolean} [params.stop] *contract only* set to true for canceling trigger orders
         * @returns {object} an array of [order structures]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        if (symbol === undefined) {
            throw new InvalidParamsError('cancelOrders() requires a symbol argument')
                .exchange(this.id);
        }
        await this.loadMarkets();
        const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        let market = undefined;
        if (sandboxMode) {
            const sandboxSymbol = this.convertSymbolForSandbox(symbol);
            market = this.market(sandboxSymbol);
        }
        else {
            market = this.market(symbol);
        }
        let marginMode = undefined;
        [marginMode, params] = this.handleMarginModeAndParams('cancelOrders', params);
        const stop = this.safeValue2(params, 'stop', 'trigger');
        params = this.omit(params, ['stop', 'trigger']);
        const orderIdList = [];
        for (let i = 0; i < ids.length; i++) {
            const individualId = ids[i];
            const orderId = {
                'orderId': individualId,
            };
            orderIdList.push(orderId);
        }
        const request = {
            'symbol': market['id'],
        };
        if (market['spot'] && (marginMode === undefined)) {
            request['orderList'] = orderIdList;
        }
        else {
            request['orderIdList'] = orderIdList;
        }
        let response = undefined;
        if (market['spot']) {
            if (marginMode !== undefined) {
                if (marginMode === 'cross') {
                    response = await this.privateMarginPostV2MarginCrossedBatchCancelOrder(this.extend(request, params));
                }
                else {
                    response = await this.privateMarginPostV2MarginIsolatedBatchCancelOrder(this.extend(request, params));
                }
            }
            else {
                response = await this.privateSpotPostV2SpotTradeBatchCancelOrder(this.extend(request, params));
            }
        }
        else {
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(market, params);
            request['productType'] = productType;
            if (stop) {
                response = await this.privateMixPostV2MixOrderCancelPlanOrder(this.extend(request, params));
            }
            else {
                response = await this.privateMixPostV2MixOrderBatchCancelOrders(this.extend(request, params));
            }
        }
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": "1680008815965",
        //         "data": {
        //             "successList": [
        //                 {
        //                     "orderId": "1024598257429823488",
        //                     "clientOid": "876493ce-c287-4bfc-9f4a-8b1905881313"
        //                 },
        //             ],
        //             "failureList": []
        //         }
        //     }
        //
        const data = this.safeValue(response, 'data', {});
        const orders = this.safeValue(data, 'successList', []);
        return this.parseOrders(orders, market);
    }
    async cancelAllOrders(symbol = undefined, params = {}) {
        /**
         * @method
         * @name bitget#cancelAllOrders
         * @description cancel all open orders
         * @see https://www.bitget.com/api-doc/spot/trade/Cancel-Symbol-Orders
         * @see https://www.bitget.com/api-doc/contract/trade/Batch-Cancel-Orders
         * @see https://bitgetlimited.github.io/apidoc/en/margin/#isolated-batch-cancel-orders
         * @see https://bitgetlimited.github.io/apidoc/en/margin/#cross-batch-cancel-order
         * @param {string} symbol unified market symbol
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.marginMode] 'isolated' or 'cross' for spot margin trading
         * @param {boolean} [params.stop] *contract only* set to true for canceling trigger orders
         * @returns {object[]} a list of [order structures]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        if (symbol === undefined) {
            throw new InvalidParamsError('cancelAllOrders() requires a symbol argument')
                .exchange(this.id);
        }
        await this.loadMarkets();
        const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        let market = undefined;
        if (sandboxMode) {
            const sandboxSymbol = this.convertSymbolForSandbox(symbol);
            market = this.market(sandboxSymbol);
        }
        else {
            market = this.market(symbol);
        }
        let marginMode = undefined;
        [marginMode, params] = this.handleMarginModeAndParams('cancelAllOrders', params);
        const request = {
            'symbol': market['id'],
        };
        const stop = this.safeValue2(params, 'stop', 'trigger');
        params = this.omit(params, ['stop', 'trigger']);
        let response = undefined;
        if (market['spot']) {
            if (marginMode !== undefined) {
                if (marginMode === 'cross') {
                    response = await this.privateMarginPostMarginV1CrossOrderBatchCancelOrder(this.extend(request, params));
                }
                else {
                    response = await this.privateMarginPostMarginV1IsolatedOrderBatchCancelOrder(this.extend(request, params));
                }
            }
            else {
                response = await this.privateSpotPostV2SpotTradeCancelSymbolOrder(this.extend(request, params));
            }
        }
        else {
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(market, params);
            request['productType'] = productType;
            if (stop) {
                response = await this.privateMixPostV2MixOrderCancelPlanOrder(this.extend(request, params));
            }
            else {
                response = await this.privateMixPostV2MixOrderBatchCancelOrders(this.extend(request, params));
            }
        }
        //
        // spot
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700716953996,
        //         "data": {
        //             "symbol": "BTCUSDT"
        //         }
        //     }
        //
        // swap
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": "1680008815965",
        //         "data": {
        //             "successList": [
        //                 {
        //                     "orderId": "1024598257429823488",
        //                     "clientOid": "876493ce-c287-4bfc-9f4a-8b1905881313"
        //                 },
        //             ],
        //             "failureList": []
        //         }
        //     }
        //
        // spot margin
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700717155622,
        //         "data": {
        //             "resultList": [
        //                 {
        //                     "orderId": "1111453253721796609",
        //                     "clientOid": "2ae7fc8a4ff949b6b60d770ca3950e2d"
        //                 },
        //             ],
        //             "failure": []
        //         }
        //     }
        //
        return response;
    }
    async fetchOrder(id, symbol = undefined, params = {}) {
        /**
         * @method
         * @name bitget#fetchOrder
         * @description fetches information on an order made by the user
         * @see https://www.bitget.com/api-doc/spot/trade/Get-Order-Info
         * @see https://www.bitget.com/api-doc/contract/trade/Get-Order-Details
         * @param {string} symbol unified symbol of the market the order was made in
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} An [order structure]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        if (symbol === undefined) {
            throw new InvalidParamsError('fetchOrder() requires a symbol argument')
                .exchange(this.id);
        }
        await this.loadMarkets();
        const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        let market = undefined;
        if (sandboxMode) {
            const sandboxSymbol = this.convertSymbolForSandbox(symbol);
            market = this.market(sandboxSymbol);
        }
        else {
            market = this.market(symbol);
        }
        const request = {
            'orderId': id,
        };
        let response = undefined;
        if (market['spot']) {
            response = await this.privateSpotGetV2SpotTradeOrderInfo(this.extend(request, params));
        }
        else if (market['swap'] || market['future']) {
            request['symbol'] = market['id'];
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(market, params);
            request['productType'] = productType;
            response = await this.privateMixGetV2MixOrderDetail(this.extend(request, params));
        }
        else {
            throw new MarketTypeNotSupportedError(market['type'])
                .addMessage('fetchOrder() does not support ' + market['type'] + ' orders')
                .exchange(this.id);
        }
        //
        // spot
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700719076263,
        //         "data": [
        //             {
        //                 "userId": "7264631750",
        //                 "symbol": "BTCUSDT",
        //                 "orderId": "1111461743123927040",
        //                 "clientOid": "63f95110-93b5-4309-8f77-46339f1bcf3c",
        //                 "price": "25000.0000000000000000",
        //                 "size": "0.0002000000000000",
        //                 "orderType": "limit",
        //                 "side": "buy",
        //                 "status": "live",
        //                 "priceAvg": "0",
        //                 "baseVolume": "0.0000000000000000",
        //                 "quoteVolume": "0.0000000000000000",
        //                 "enterPointSource": "API",
        //                 "feeDetail": "",
        //                 "orderSource": "normal",
        //                 "cTime": "1700719050198",
        //                 "uTime": "1700719050198"
        //             }
        //         ]
        //     }
        //
        // swap and future
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700719918781,
        //         "data": {
        //             "symbol": "BTCUSDT",
        //             "size": "0.001",
        //             "orderId": "1111465253393825792",
        //             "clientOid": "1111465253431574529",
        //             "baseVolume": "0",
        //             "fee": "0",
        //             "price": "27000",
        //             "priceAvg": "",
        //             "state": "live",
        //             "side": "buy",
        //             "force": "gtc",
        //             "totalProfits": "0",
        //             "posSide": "long",
        //             "marginCoin": "USDT",
        //             "presetStopSurplusPrice": "",
        //             "presetStopLossPrice": "",
        //             "quoteVolume": "0",
        //             "orderType": "limit",
        //             "leverage": "20",
        //             "marginMode": "crossed",
        //             "reduceOnly": "NO",
        //             "enterPointSource": "API",
        //             "tradeSide": "open",
        //             "posMode": "hedge_mode",
        //             "orderSource": "normal",
        //             "cTime": "1700719887120",
        //             "uTime": "1700719887120"
        //         }
        //     }
        //
        if (typeof response === 'string') {
            response = JSON.parse(response);
        }
        const data = this.safeValue(response, 'data');
        const first = this.safeValue(data, 0, data);
        return this.parseOrder(first, market);
    }
    async fetchOpenOrders(symbol = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name bitget#fetchOpenOrders
         * @description fetch all unfilled currently open orders
         * @see https://www.bitget.com/api-doc/spot/trade/Get-Unfilled-Orders
         * @see https://www.bitget.com/api-doc/spot/plan/Get-Current-Plan-Order
         * @see https://www.bitget.com/api-doc/contract/trade/Get-Orders-Pending
         * @see https://www.bitget.com/api-doc/contract/plan/get-orders-plan-pending
         * @see https://www.bitget.com/api-doc/margin/cross/trade/Get-Cross-Open-Orders
         * @see https://www.bitget.com/api-doc/margin/isolated/trade/Isolated-Open-Orders
         * @param {string} symbol unified market symbol
         * @param {int} [since] the earliest time in ms to fetch open orders for
         * @param {int} [limit] the maximum number of open order structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {int} [params.until] the latest time in ms to fetch orders for
         * @param {string} [params.planType] *contract stop only* 'normal_plan': average trigger order, 'track_plan': trailing stop order, default is 'normal_plan'
         * @param {boolean} [params.stop] set to true for fetching trigger orders
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [available parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @param {string} [params.isPlan] *swap only* 'plan' for stop orders and 'profit_loss' for tp/sl orders, default is 'plan'
         * @param {boolean} [params.trailing] set to true if you want to fetch trailing orders
         * @returns {Order[]} a list of [order structures]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        let market = undefined;
        let type = undefined;
        let request = {};
        let marginMode = undefined;
        [marginMode, params] = this.handleMarginModeAndParams('fetchOpenOrders', params);
        if (symbol !== undefined) {
            if (sandboxMode) {
                const sandboxSymbol = this.convertSymbolForSandbox(symbol);
                market = this.market(sandboxSymbol);
            }
            else {
                market = this.market(symbol);
            }
            request['symbol'] = market['id'];
            const defaultType = this.safeString2(this.options, 'fetchOpenOrders', 'defaultType', 'spot');
            const marketType = ('type' in market) ? market['type'] : defaultType;
            type = this.safeString(params, 'type', marketType);
        }
        else {
            const defaultType = this.safeString2(this.options, 'fetchOpenOrders', 'defaultType', 'spot');
            type = this.safeString(params, 'type', defaultType);
        }
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchOpenOrders', 'paginate');
        if (paginate) {
            let cursorReceived = undefined;
            if (type === 'spot') {
                if (marginMode !== undefined) {
                    cursorReceived = 'minId';
                }
            }
            else {
                cursorReceived = 'endId';
            }
            return await this.fetchPaginatedCallCursor('fetchOpenOrders', symbol, since, limit, params, cursorReceived, 'idLessThan');
        }
        let response = undefined;
        const trailing = this.safeValue(params, 'trailing');
        const stop = this.safeValue2(params, 'stop', 'trigger');
        params = this.omit(params, ['stop', 'trigger', 'trailing']);
        [request, params] = this.handleUntilOption('endTime', request, params);
        if (since !== undefined) {
            request['startTime'] = since;
        }
        if (limit !== undefined) {
            request['limit'] = limit;
        }
        if ((type === 'swap') || (type === 'future') || (marginMode !== undefined)) {
            const clientOrderId = this.safeString2(params, 'clientOid', 'clientOrderId');
            params = this.omit(params, 'clientOrderId');
            if (clientOrderId !== undefined) {
                request['clientOid'] = clientOrderId;
            }
        }
        let query = undefined;
        query = this.omit(params, ['type']);
        if (type === 'spot') {
            if (marginMode !== undefined) {
                if (since === undefined) {
                    since = this.milliseconds() - 7776000000;
                    request['startTime'] = since;
                }
                if (marginMode === 'isolated') {
                    response = await this.privateMarginGetV2MarginIsolatedOpenOrders(this.extend(request, query));
                }
                else if (marginMode === 'cross') {
                    response = await this.privateMarginGetV2MarginCrossedOpenOrders(this.extend(request, query));
                }
            }
            else {
                if (stop) {
                    response = await this.privateSpotGetV2SpotTradeCurrentPlanOrder(this.extend(request, query));
                }
                else {
                    response = await this.privateSpotGetV2SpotTradeUnfilledOrders(this.extend(request, query));
                }
            }
        }
        else {
            let productType = undefined;
            [productType, query] = this.handleProductTypeAndParams(market, query);
            request['productType'] = productType;
            if (trailing) {
                const planType = this.safeString(params, 'planType', 'track_plan');
                request['planType'] = planType;
                response = await this.privateMixGetV2MixOrderOrdersPlanPending(this.extend(request, query));
            }
            else if (stop) {
                const planType = this.safeString(query, 'planType', 'normal_plan');
                request['planType'] = planType;
                response = await this.privateMixGetV2MixOrderOrdersPlanPending(this.extend(request, query));
            }
            else {
                response = await this.privateMixGetV2MixOrderOrdersPending(this.extend(request, query));
            }
        }
        //
        // spot
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700728123994,
        //         "data": [
        //             {
        //                 "userId": "7264631750",
        //                 "symbol": "BTCUSDT",
        //                 "orderId": "1111499608327360513",
        //                 "clientOid": "d0d4dad5-18d0-4869-a074-ec40bb47cba6",
        //                 "priceAvg": "25000.0000000000000000",
        //                 "size": "0.0002000000000000",
        //                 "orderType": "limit",
        //                 "side": "buy",
        //                 "status": "live",
        //                 "basePrice": "0",
        //                 "baseVolume": "0.0000000000000000",
        //                 "quoteVolume": "0.0000000000000000",
        //                 "enterPointSource": "WEB",
        //                 "orderSource": "normal",
        //                 "cTime": "1700728077966",
        //                 "uTime": "1700728077966"
        //             }
        //         ]
        //     }
        //
        // spot stop
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700729361609,
        //         "data": {
        //             "nextFlag": false,
        //             "idLessThan": "1111503385931620352",
        //             "orderList": [
        //                 {
        //                     "orderId": "1111503385931620352",
        //                     "clientOid": "1111503385910648832",
        //                     "symbol": "BTCUSDT",
        //                     "size": "0.0002",
        //                     "planType": "AMOUNT",
        //                     "executePrice": "25000",
        //                     "triggerPrice": "26000",
        //                     "status": "live",
        //                     "orderType": "limit",
        //                     "side": "buy",
        //                     "triggerType": "fill_price",
        //                     "enterPointSource": "API",
        //                     "cTime": "1700728978617",
        //                     "uTime": "1700728978617"
        //                 }
        //             ]
        //         }
        //     }
        //
        // spot margin
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700729887686,
        //         "data": {
        //             "orderList": [
        //                 {
        //                     "symbol": "BTCUSDT",
        //                     "orderType": "limit",
        //                     "enterPointSource": "WEB",
        //                     "orderId": "1111506377509580801",
        //                     "clientOid": "2043a3b59a60445f9d9f7365bf3e960c",
        //                     "loanType": "autoLoanAndRepay",
        //                     "price": "25000",
        //                     "side": "buy",
        //                     "status": "live",
        //                     "baseSize": "0.0002",
        //                     "quoteSize": "5",
        //                     "priceAvg": "0",
        //                     "size": "0",
        //                     "amount": "0",
        //                     "force": "gtc",
        //                     "cTime": "1700729691866",
        //                     "uTime": "1700729691866"
        //                 }
        //             ],
        //             "maxId": "1111506377509580801",
        //             "minId": "1111506377509580801"
        //         }
        //     }
        //
        // swap and future
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700725609065,
        //         "data": {
        //             "entrustedList": [
        //                 {
        //                     "symbol": "BTCUSDT",
        //                     "size": "0.002",
        //                     "orderId": "1111488897767604224",
        //                     "clientOid": "1111488897805352960",
        //                     "baseVolume": "0",
        //                     "fee": "0",
        //                     "price": "25000",
        //                     "priceAvg": "",
        //                     "status": "live",
        //                     "side": "buy",
        //                     "force": "gtc",
        //                     "totalProfits": "0",
        //                     "posSide": "long",
        //                     "marginCoin": "USDT",
        //                     "quoteVolume": "0",
        //                     "leverage": "20",
        //                     "marginMode": "crossed",
        //                     "enterPointSource": "web",
        //                     "tradeSide": "open",
        //                     "posMode": "hedge_mode",
        //                     "orderType": "limit",
        //                     "orderSource": "normal",
        //                     "presetStopSurplusPrice": "",
        //                     "presetStopLossPrice": "",
        //                     "reduceOnly": "NO",
        //                     "cTime": "1700725524378",
        //                     "uTime": "1700725524378"
        //                 }
        //             ],
        //             "endId": "1111488897767604224"
        //         }
        //     }
        //
        // swap and future stop
        //
        //     {
        //         "code": "00000",\
        //         "msg": "success",
        //         "requestTime": 1700726417495,
        //         "data": {
        //             "entrustedList": [
        //                 {
        //                     "planType": "normal_plan",
        //                     "symbol": "BTCUSDT",
        //                     "size": "0.001",
        //                     "orderId": "1111491399869075457",
        //                     "clientOid": "1111491399869075456",
        //                     "price": "27000",
        //                     "callbackRatio": "",
        //                     "triggerPrice": "24000",
        //                     "triggerType": "mark_price",
        //                     "planStatus": "live",
        //                     "side": "buy",
        //                     "posSide": "long",
        //                     "marginCoin": "USDT",
        //                     "marginMode": "crossed",
        //                     "enterPointSource": "API",
        //                     "tradeSide": "open",
        //                     "posMode": "hedge_mode",
        //                     "orderType": "limit",
        //                     "stopSurplusTriggerPrice": "",
        //                     "stopSurplusExecutePrice": "",
        //                     "stopSurplusTriggerType": "fill_price",
        //                     "stopLossTriggerPrice": "",
        //                     "stopLossExecutePrice": "",
        //                     "stopLossTriggerType": "fill_price",
        //                     "cTime": "1700726120917",
        //                     "uTime": "1700726120917"
        //                 }
        //             ],
        //             "endId": "1111491399869075457"
        //         }
        //     }
        //
        const data = this.safeValue(response, 'data');
        if (type === 'spot') {
            if ((marginMode !== undefined) || stop) {
                const resultList = this.safeValue(data, 'orderList', []);
                return this.parseOrders(resultList, market, since, limit);
            }
        }
        else {
            const result = this.safeValue(data, 'entrustedList', []);
            return this.parseOrders(result, market, since, limit);
        }
        return this.parseOrders(data, market, since, limit);
    }
    async fetchClosedOrders(symbol = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name bitget#fetchClosedOrders
         * @description fetches information on multiple closed orders made by the user
         * @see https://www.bitget.com/api-doc/spot/trade/Get-History-Orders
         * @see https://www.bitget.com/api-doc/spot/plan/Get-History-Plan-Order
         * @see https://www.bitget.com/api-doc/contract/trade/Get-Orders-History
         * @see https://www.bitget.com/api-doc/contract/plan/orders-plan-history
         * @see https://www.bitget.com/api-doc/margin/cross/trade/Get-Cross-Order-History
         * @see https://www.bitget.com/api-doc/margin/isolated/trade/Get-Isolated-Order-History
         * @param {string} symbol unified market symbol of the closed orders
         * @param {int} [since] timestamp in ms of the earliest order
         * @param {int} [limit] the max number of closed orders to return
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {int} [params.until] the latest time in ms to fetch entries for
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [available parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @param {string} [params.isPlan] *swap only* 'plan' for stop orders and 'profit_loss' for tp/sl orders, default is 'plan'
         * @param {string} [params.productType] *contract only* 'USDT-FUTURES', 'USDC-FUTURES', 'COIN-FUTURES', 'SUSDT-FUTURES', 'SUSDC-FUTURES' or 'SCOIN-FUTURES'
         * @param {boolean} [params.trailing] set to true if you want to fetch trailing orders
         * @returns {Order[]} a list of [order structures]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const orders = await this.fetchCanceledAndClosedOrders(symbol, since, limit, params);
        return this.filterBy(orders, 'status', 'closed');
    }
    async fetchCanceledOrders(symbol = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name bitget#fetchCanceledOrders
         * @description fetches information on multiple canceled orders made by the user
         * @see https://www.bitget.com/api-doc/spot/trade/Get-History-Orders
         * @see https://www.bitget.com/api-doc/spot/plan/Get-History-Plan-Order
         * @see https://www.bitget.com/api-doc/contract/trade/Get-Orders-History
         * @see https://www.bitget.com/api-doc/contract/plan/orders-plan-history
         * @see https://www.bitget.com/api-doc/margin/cross/trade/Get-Cross-Order-History
         * @see https://www.bitget.com/api-doc/margin/isolated/trade/Get-Isolated-Order-History
         * @param {string} symbol unified market symbol of the canceled orders
         * @param {int} [since] timestamp in ms of the earliest order
         * @param {int} [limit] the max number of canceled orders to return
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {int} [params.until] the latest time in ms to fetch entries for
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [available parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @param {string} [params.isPlan] *swap only* 'plan' for stop orders and 'profit_loss' for tp/sl orders, default is 'plan'
         * @param {string} [params.productType] *contract only* 'USDT-FUTURES', 'USDC-FUTURES', 'COIN-FUTURES', 'SUSDT-FUTURES', 'SUSDC-FUTURES' or 'SCOIN-FUTURES'
         * @param {boolean} [params.trailing] set to true if you want to fetch trailing orders
         * @returns {object} a list of [order structures]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const orders = await this.fetchCanceledAndClosedOrders(symbol, since, limit, params);
        return this.filterBy(orders, 'status', 'canceled');
    }
    async fetchCanceledAndClosedOrders(symbol = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name bitget#fetchCanceledAndClosedOrders
         * @see https://www.bitget.com/api-doc/spot/trade/Get-History-Orders
         * @see https://www.bitget.com/api-doc/spot/plan/Get-History-Plan-Order
         * @see https://www.bitget.com/api-doc/contract/trade/Get-Orders-History
         * @see https://www.bitget.com/api-doc/contract/plan/orders-plan-history
         * @see https://www.bitget.com/api-doc/margin/cross/trade/Get-Cross-Order-History
         * @see https://www.bitget.com/api-doc/margin/isolated/trade/Get-Isolated-Order-History
         * @description fetches information on multiple canceled and closed orders made by the user
         * @param {string} symbol unified market symbol of the market orders were made in
         * @param {int} [since] the earliest time in ms to fetch orders for
         * @param {int} [limit] the maximum number of order structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {Order[]} a list of [order structures]{@link https://docs.ccxt.com/#/?id=order-structure}
         */
        await this.loadMarkets();
        const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        let market = undefined;
        if (sandboxMode) {
            if (symbol !== undefined) {
                const sandboxSymbol = this.convertSymbolForSandbox(symbol);
                symbol = sandboxSymbol;
            }
        }
        let request = {};
        if (symbol !== undefined) {
            market = this.market(symbol);
            request['symbol'] = market['id'];
        }
        let marketType = undefined;
        [marketType, params] = this.handleMarketTypeAndParams('fetchCanceledAndClosedOrders', market, params);
        let marginMode = undefined;
        [marginMode, params] = this.handleMarginModeAndParams('fetchCanceledAndClosedOrders', params);
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchCanceledAndClosedOrders', 'paginate');
        if (paginate) {
            let cursorReceived = undefined;
            if (marketType === 'spot') {
                if (marginMode !== undefined) {
                    cursorReceived = 'minId';
                }
            }
            else {
                cursorReceived = 'endId';
            }
            return await this.fetchPaginatedCallCursor('fetchCanceledAndClosedOrders', symbol, since, limit, params, cursorReceived, 'idLessThan');
        }
        let response = undefined;
        const trailing = this.safeValue(params, 'trailing');
        const stop = this.safeValue2(params, 'stop', 'trigger');
        params = this.omit(params, ['stop', 'trigger', 'trailing']);
        [request, params] = this.handleUntilOption('endTime', request, params);
        if (since !== undefined) {
            request['startTime'] = since;
        }
        if (limit !== undefined) {
            request['limit'] = limit;
        }
        if ((marketType === 'swap') || (marketType === 'future') || (marginMode !== undefined)) {
            const clientOrderId = this.safeString2(params, 'clientOid', 'clientOrderId');
            params = this.omit(params, 'clientOrderId');
            if (clientOrderId !== undefined) {
                request['clientOid'] = clientOrderId;
            }
        }
        const now = this.milliseconds();
        if (marketType === 'spot') {
            if (marginMode !== undefined) {
                if (since === undefined) {
                    since = now - 7776000000;
                    request['startTime'] = since;
                }
                if (marginMode === 'isolated') {
                    response = await this.privateMarginGetV2MarginIsolatedHistoryOrders(this.extend(request, params));
                }
                else if (marginMode === 'cross') {
                    response = await this.privateMarginGetV2MarginCrossedHistoryOrders(this.extend(request, params));
                }
            }
            else {
                if (stop) {
                    if (symbol === undefined) {
                        throw new InvalidParamsError('fetchCanceledAndClosedOrders() requires a symbol argument')
                            .exchange(this.id);
                    }
                    const endTime = this.safeIntegerN(params, ['endTime', 'until', 'till']);
                    params = this.omit(params, ['until', 'till']);
                    if (since === undefined) {
                        since = now - 7776000000;
                        request['startTime'] = since;
                    }
                    if (endTime === undefined) {
                        request['endTime'] = now;
                    }
                    response = await this.privateSpotGetV2SpotTradeHistoryPlanOrder(this.extend(request, params));
                }
                else {
                    response = await this.privateSpotGetV2SpotTradeHistoryOrders(this.extend(request, params));
                }
            }
        }
        else {
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(market, params);
            request['productType'] = productType;
            if (trailing) {
                const planType = this.safeString(params, 'planType', 'track_plan');
                request['planType'] = planType;
                response = await this.privateMixGetV2MixOrderOrdersPlanHistory(this.extend(request, params));
            }
            else if (stop) {
                const planType = this.safeString(params, 'planType', 'normal_plan');
                request['planType'] = planType;
                response = await this.privateMixGetV2MixOrderOrdersPlanHistory(this.extend(request, params));
            }
            else {
                response = await this.privateMixGetV2MixOrderOrdersHistory(this.extend(request, params));
            }
        }
        //
        // spot
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700791085380,
        //         "data": [
        //             {
        //                 "userId": "7264631750",
        //                 "symbol": "BTCUSDT",
        //                 "orderId": "1111499608327360513",
        //                 "clientOid": "d0d4dad5-18d0-4869-a074-ec40bb47cba6",
        //                 "price": "25000.0000000000000000",
        //                 "size": "0.0002000000000000",
        //                 "orderType": "limit",
        //                 "side": "buy",
        //                 "status": "cancelled",
        //                 "priceAvg": "0",
        //                 "baseVolume": "0.0000000000000000",
        //                 "quoteVolume": "0.0000000000000000",
        //                 "enterPointSource": "WEB",
        //                 "feeDetail": "",
        //                 "orderSource": "normal",
        //                 "cTime": "1700728077966",
        //                 "uTime": "1700728911471"
        //             },
        //         ]
        //     }
        //
        // spot stop
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700792099146,
        //         "data": {
        //             "nextFlag": false,
        //             "idLessThan": "1098757597417775104",
        //             "orderList": [
        //                 {
        //                     "orderId": "1111503385931620352",
        //                     "clientOid": "1111503385910648832",
        //                     "symbol": "BTCUSDT",
        //                     "size": "0.0002",
        //                     "planType": "AMOUNT",
        //                     "executePrice": "25000",
        //                     "triggerPrice": "26000",
        //                     "status": "cancelled",
        //                     "orderType": "limit",
        //                     "side": "buy",
        //                     "triggerType": "fill_price",
        //                     "enterPointSource": "API",
        //                     "cTime": "1700728978617",
        //                     "uTime": "1700729666868"
        //                 },
        //             ]
        //         }
        //     }
        //
        // spot margin
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700792381435,
        //         "data": {
        //             "orderList": [
        //                 {
        //                     "symbol": "BTCUSDT",
        //                     "orderType": "limit",
        //                     "enterPointSource": "WEB",
        //                     "orderId": "1111456274707001345",
        //                     "clientOid": "41e428dd305a4f668671b7f1ed00dc50",
        //                     "loanType": "autoLoanAndRepay",
        //                     "price": "27000",
        //                     "side": "buy",
        //                     "status": "cancelled",
        //                     "baseSize": "0.0002",
        //                     "quoteSize": "5.4",
        //                     "priceAvg": "0",
        //                     "size": "0",
        //                     "amount": "0",
        //                     "force": "gtc",
        //                     "cTime": "1700717746427",
        //                     "uTime": "1700717780636"
        //                 },
        //             ],
        //             "maxId": "1111456274707001345",
        //             "minId": "1098396464990269440"
        //         }
        //     }
        //
        // swap and future
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700792674673,
        //         "data": {
        //             "entrustedList": [
        //                 {
        //                     "symbol": "BTCUSDT",
        //                     "size": "0.002",
        //                     "orderId": "1111498800817143808",
        //                     "clientOid": "1111498800850698240",
        //                     "baseVolume": "0",
        //                     "fee": "0",
        //                     "price": "25000",
        //                     "priceAvg": "",
        //                     "status": "canceled",
        //                     "side": "buy",
        //                     "force": "gtc",
        //                     "totalProfits": "0",
        //                     "posSide": "long",
        //                     "marginCoin": "USDT",
        //                     "quoteVolume": "0",
        //                     "leverage": "20",
        //                     "marginMode": "crossed",
        //                     "enterPointSource": "web",
        //                     "tradeSide": "open",
        //                     "posMode": "hedge_mode",
        //                     "orderType": "limit",
        //                     "orderSource": "normal",
        //                     "presetStopSurplusPrice": "",
        //                     "presetStopLossPrice": "",
        //                     "reduceOnly": "NO",
        //                     "cTime": "1700727885449",
        //                     "uTime": "1700727944563"
        //                 },
        //             ],
        //             "endId": "1098397008323575809"
        //         }
        //     }
        //
        // swap and future stop
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700792938359,
        //         "data": {
        //             "entrustedList": [
        //                 {
        //                     "planType": "normal_plan",
        //                     "symbol": "BTCUSDT",
        //                     "size": "0.001",
        //                     "orderId": "1111491399869075457",
        //                     "clientOid": "1111491399869075456",
        //                     "planStatus": "cancelled",
        //                     "price": "27000",
        //                     "feeDetail": null,
        //                     "baseVolume": "0",
        //                     "callbackRatio": "",
        //                     "triggerPrice": "24000",
        //                     "triggerType": "mark_price",
        //                     "side": "buy",
        //                     "posSide": "long",
        //                     "marginCoin": "USDT",
        //                     "marginMode": "crossed",
        //                     "enterPointSource": "API",
        //                     "tradeSide": "open",
        //                     "posMode": "hedge_mode",
        //                     "orderType": "limit",
        //                     "stopSurplusTriggerPrice": "",
        //                     "stopSurplusExecutePrice": "",
        //                     "stopSurplusTriggerType": "fill_price",
        //                     "stopLossTriggerPrice": "",
        //                     "stopLossExecutePrice": "",
        //                     "stopLossTriggerType": "fill_price",
        //                     "cTime": "1700726120917",
        //                     "uTime": "1700727879652"
        //                 },
        //             ],
        //             "endId": "1098760007867502593"
        //         }
        //     }
        //
        const data = this.safeValue(response, 'data', {});
        if (marketType === 'spot') {
            if ((marginMode !== undefined) || stop) {
                return this.parseOrders(this.safeValue(data, 'orderList', []), market, since, limit);
            }
        }
        else {
            return this.parseOrders(this.safeValue(data, 'entrustedList', []), market, since, limit);
        }
        if (typeof response === 'string') {
            response = JSON.parse(response);
        }
        const orders = this.safeValue(response, 'data', []);
        return this.parseOrders(orders, market, since, limit);
    }
    async fetchLedger(code = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name bitget#fetchLedger
         * @see https://www.bitget.com/api-doc/spot/account/Get-Account-Bills
         * @see https://www.bitget.com/api-doc/contract/account/Get-Account-Bill
         * @description fetch the history of changes, actions done by the user or operations that altered balance of the user
         * @param {string} code unified currency code, default is undefined
         * @param {int} [since] timestamp in ms of the earliest ledger entry, default is undefined
         * @param {int} [limit] max number of ledger entrys to return, default is undefined
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {int} [params.until] end time in ms
         * @param {string} [params.symbol] *contract only* unified market symbol
         * @param {string} [params.productType] *contract only* 'USDT-FUTURES', 'USDC-FUTURES', 'COIN-FUTURES', 'SUSDT-FUTURES', 'SUSDC-FUTURES' or 'SCOIN-FUTURES'
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [available parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @returns {object} a [ledger structure]{@link https://docs.ccxt.com/#/?id=ledger-structure}
         */
        await this.loadMarkets();
        const symbol = this.safeString(params, 'symbol');
        params = this.omit(params, 'symbol');
        let market = undefined;
        if (symbol !== undefined) {
            const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
            if (sandboxMode) {
                const sandboxSymbol = this.convertSymbolForSandbox(symbol);
                market = this.market(sandboxSymbol);
            }
            else {
                market = this.market(symbol);
            }
        }
        let marketType = undefined;
        [marketType, params] = this.handleMarketTypeAndParams('fetchLedger', market, params);
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchLedger', 'paginate');
        if (paginate) {
            let cursorReceived = undefined;
            if (marketType !== 'spot') {
                cursorReceived = 'endId';
            }
            return await this.fetchPaginatedCallCursor('fetchLedger', symbol, since, limit, params, cursorReceived, 'idLessThan');
        }
        let currency = undefined;
        let request = {};
        if (code !== undefined) {
            currency = this.currency(code);
            request['coin'] = currency['code'];
        }
        [request, params] = this.handleUntilOption('endTime', request, params);
        if (since !== undefined) {
            request['startTime'] = since;
        }
        if (limit !== undefined) {
            request['limit'] = limit;
        }
        let response = undefined;
        if (marketType === 'spot') {
            response = await this.privateSpotGetV2SpotAccountBills(this.extend(request, params));
        }
        else {
            if (symbol !== undefined) {
                request['symbol'] = market['id'];
            }
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(market, params);
            request['productType'] = productType;
            response = await this.privateMixGetV2MixAccountBill(this.extend(request, params));
        }
        //
        // spot
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700795836415,
        //         "data": [
        //             {
        //                 "billId": "1111506298997215233",
        //                 "coin": "USDT",
        //                 "groupType": "transfer",
        //                 "businessType": "transfer_out",
        //                 "size": "-11.64958799",
        //                 "balance": "0.00000000",
        //                 "fees": "0.00000000",
        //                 "cTime": "1700729673028"
        //             },
        //         ]
        //     }
        //
        // swap and future
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700795977890,
        //         "data": {
        //             "bills": [
        //                 {
        //                     "billId": "1111499428100472833",
        //                     "symbol": "",
        //                     "amount": "-11.64958799",
        //                     "fee": "0",
        //                     "feeByCoupon": "",
        //                     "businessType": "trans_to_exchange",
        //                     "coin": "USDT",
        //                     "cTime": "1700728034996"
        //                 },
        //             ],
        //             "endId": "1098396773329305606"
        //         }
        //     }
        //
        const data = this.safeValue(response, 'data');
        if ((marketType === 'swap') || (marketType === 'future')) {
            const bills = this.safeValue(data, 'bills', []);
            return this.parseLedger(bills, currency, since, limit);
        }
        return this.parseLedger(data, currency, since, limit);
    }
    parseLedgerEntry(item, currency = undefined) {
        //
        // spot
        //
        //     {
        //         "billId": "1111506298997215233",
        //         "coin": "USDT",
        //         "groupType": "transfer",
        //         "businessType": "transfer_out",
        //         "size": "-11.64958799",
        //         "balance": "0.00000000",
        //         "fees": "0.00000000",
        //         "cTime": "1700729673028"
        //     }
        //
        // swap and future
        //
        //     {
        //         "billId": "1111499428100472833",
        //         "symbol": "",
        //         "amount": "-11.64958799",
        //         "fee": "0",
        //         "feeByCoupon": "",
        //         "businessType": "trans_to_exchange",
        //         "coin": "USDT",
        //         "cTime": "1700728034996"
        //     }
        //
        const currencyId = this.safeString(item, 'coin');
        const code = this.safeCurrencyCode(currencyId, currency);
        const timestamp = this.safeInteger(item, 'cTime');
        const after = this.safeNumber(item, 'balance');
        const fee = this.safeNumber2(item, 'fees', 'fee');
        const amountRaw = this.safeString2(item, 'size', 'amount');
        const amount = this.parseNumber(Precise.stringAbs(amountRaw));
        let direction = 'in';
        if (amountRaw.indexOf('-') >= 0) {
            direction = 'out';
        }
        return {
            'info': item,
            'id': this.safeString(item, 'billId'),
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'direction': direction,
            'account': undefined,
            'referenceId': undefined,
            'referenceAccount': undefined,
            'type': this.parseLedgerType(this.safeString(item, 'businessType')),
            'currency': code,
            'amount': amount,
            'before': undefined,
            'after': after,
            'status': undefined,
            'fee': fee,
        };
    }
    parseLedgerType(type) {
        const types = {
            'trans_to_cross': 'transfer',
            'trans_from_cross': 'transfer',
            'trans_to_exchange': 'transfer',
            'trans_from_exchange': 'transfer',
            'trans_to_isolated': 'transfer',
            'trans_from_isolated': 'transfer',
            'trans_to_contract': 'transfer',
            'trans_from_contract': 'transfer',
            'trans_to_otc': 'transfer',
            'trans_from_otc': 'transfer',
            'open_long': 'trade',
            'close_long': 'trade',
            'open_short': 'trade',
            'close_short': 'trade',
            'force_close_long': 'trade',
            'force_close_short': 'trade',
            'burst_long_loss_query': 'trade',
            'burst_short_loss_query': 'trade',
            'force_buy': 'trade',
            'force_sell': 'trade',
            'burst_buy': 'trade',
            'burst_sell': 'trade',
            'delivery_long': 'settlement',
            'delivery_short': 'settlement',
            'contract_settle_fee': 'fee',
            'append_margin': 'transaction',
            'adjust_down_lever_append_margin': 'transaction',
            'reduce_margin': 'transaction',
            'auto_append_margin': 'transaction',
            'cash_gift_issue': 'cashback',
            'cash_gift_recycle': 'cashback',
            'bonus_issue': 'rebate',
            'bonus_recycle': 'rebate',
            'bonus_expired': 'rebate',
            'transfer_in': 'transfer',
            'transfer_out': 'transfer',
            'deposit': 'deposit',
            'withdraw': 'withdrawal',
            'buy': 'trade',
            'sell': 'trade',
        };
        return this.safeString(types, type, type);
    }
    async fetchMyTrades(symbol = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name bitget#fetchMyTrades
         * @description fetch all trades made by the user
         * @see https://www.bitget.com/api-doc/spot/trade/Get-Fills
         * @see https://www.bitget.com/api-doc/contract/trade/Get-Order-Fills
         * @see https://www.bitget.com/api-doc/margin/cross/trade/Get-Cross-Order-Fills
         * @see https://www.bitget.com/api-doc/margin/isolated/trade/Get-Isolated-Transaction-Details
         * @param {string} symbol unified market symbol
         * @param {int} [since] the earliest time in ms to fetch trades for
         * @param {int} [limit] the maximum number of trades structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {int} [params.until] the latest time in ms to fetch trades for
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [available parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @returns {Trade[]} a list of [trade structures]{@link https://docs.ccxt.com/#/?id=trade-structure}
         */
        if (symbol === undefined) {
            throw new InvalidParamsError('fetchMyTrades() requires a symbol argument').exchange(this.id);
        }
        await this.loadMarkets();
        const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        let market = undefined;
        if (sandboxMode) {
            const sandboxSymbol = this.convertSymbolForSandbox(symbol);
            market = this.market(sandboxSymbol);
        }
        else {
            market = this.market(symbol);
        }
        let marginMode = undefined;
        [marginMode, params] = this.handleMarginModeAndParams('fetchMyTrades', params);
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchMyTrades', 'paginate');
        if (paginate) {
            let cursorReceived = undefined;
            if (market['spot']) {
                if (marginMode !== undefined) {
                    cursorReceived = 'minId';
                }
            }
            else {
                cursorReceived = 'endId';
            }
            return await this.fetchPaginatedCallCursor('fetchMyTrades', symbol, since, limit, params, cursorReceived, 'idLessThan');
        }
        let response = undefined;
        let request = {
            'symbol': market['id'],
        };
        [request, params] = this.handleUntilOption('endTime', request, params);
        if (since !== undefined) {
            request['startTime'] = since;
        }
        if (limit !== undefined) {
            request['limit'] = limit;
        }
        if (market['spot']) {
            if (marginMode !== undefined) {
                if (since === undefined) {
                    request['startTime'] = this.milliseconds() - 7776000000;
                }
                if (marginMode === 'isolated') {
                    response = await this.privateMarginGetV2MarginIsolatedFills(this.extend(request, params));
                }
                else if (marginMode === 'cross') {
                    response = await this.privateMarginGetV2MarginCrossedFills(this.extend(request, params));
                }
            }
            else {
                response = await this.privateSpotGetV2SpotTradeFills(this.extend(request, params));
            }
        }
        else {
            let productType = undefined;
            [productType, params] = this.handleProductTypeAndParams(market, params);
            request['productType'] = productType;
            response = await this.privateMixGetV2MixOrderFills(this.extend(request, params));
        }
        //
        // spot
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700802995406,
        //         "data": [
        //             {
        //                 "userId": "7264631750",
        //                 "symbol": "BTCUSDT",
        //                 "orderId": "1098394344925597696",
        //                 "tradeId": "1098394344974925824",
        //                 "orderType": "market",
        //                 "side": "sell",
        //                 "priceAvg": "28467.68",
        //                 "size": "0.0002",
        //                 "amount": "5.693536",
        //                 "feeDetail": {
        //                     "deduction": "no",
        //                     "feeCoin": "USDT",
        //                     "totalDeductionFee": "",
        //                     "totalFee": "-0.005693536"
        //                 },
        //                 "tradeScope": "taker",
        //                 "cTime": "1697603539699",
        //                 "uTime": "1697603539754"
        //             }
        //         ]
        //     }
        //
        // spot margin
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700803176399,
        //         "data": {
        //             "fills": [
        //                 {
        //                     "orderId": "1099353730455318528",
        //                     "tradeId": "1099353730627092481",
        //                     "orderType": "market",
        //                     "side": "sell",
        //                     "priceAvg": "29543.7",
        //                     "size": "0.0001",
        //                     "amount": "2.95437",
        //                     "tradeScope": "taker",
        //                     "feeDetail": {
        //                         "deduction": "no",
        //                         "feeCoin": "USDT",
        //                         "totalDeductionFee": "0",
        //                         "totalFee": "-0.00295437"
        //                     },
        //                     "cTime": "1697832275063",
        //                     "uTime": "1697832275150"
        //                 },
        //             ],
        //             "minId": "1099353591699161118",
        //             "maxId": "1099353730627092481"
        //         }
        //     }
        //
        // swap and future
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700803357487,
        //         "data": {
        //             "fillList": [
        //                 {
        //                     "tradeId": "1111468664328269825",
        //                     "symbol": "BTCUSDT",
        //                     "orderId": "1111468664264753162",
        //                     "price": "37271.4",
        //                     "baseVolume": "0.001",
        //                     "feeDetail": [
        //                         {
        //                             "deduction": "no",
        //                             "feeCoin": "USDT",
        //                             "totalDeductionFee": null,
        //                             "totalFee": "-0.02236284"
        //                         }
        //                     ],
        //                     "side": "buy",
        //                     "quoteVolume": "37.2714",
        //                     "profit": "-0.0007",
        //                     "enterPointSource": "web",
        //                     "tradeSide": "close",
        //                     "posMode": "hedge_mode",
        //                     "tradeScope": "taker",
        //                     "cTime": "1700720700342"
        //                 },
        //             ],
        //             "endId": "1099351587643699201"
        //         }
        //     }
        //
        const data = this.safeValue(response, 'data');
        if ((market['swap']) || (market['future'])) {
            const fillList = this.safeValue(data, 'fillList', []);
            return this.parseTrades(fillList, market, since, limit);
        }
        else if (marginMode !== undefined) {
            const fills = this.safeValue(data, 'fills', []);
            return this.parseTrades(fills, market, since, limit);
        }
        return this.parseTrades(data, market, since, limit);
    }
    async fetchPosition(symbol, params = {}) {
        /**
         * @method
         * @name bitget#fetchPosition
         * @description fetch data on a single open contract trade position
         * @see https://www.bitget.com/api-doc/contract/position/get-single-position
         * @param {string} symbol unified market symbol of the market the position is held in
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a [position structure]{@link https://docs.ccxt.com/#/?id=position-structure}
         */
        await this.loadMarkets();
        const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
        let market = undefined;
        if (sandboxMode) {
            const sandboxSymbol = this.convertSymbolForSandbox(symbol);
            market = this.market(sandboxSymbol);
        }
        else {
            market = this.market(symbol);
        }
        let productType = undefined;
        [productType, params] = this.handleProductTypeAndParams(market, params);
        const request = {
            'symbol': market['id'],
            'marginCoin': market['settleId'],
            'productType': productType,
        };
        const response = await this.privateMixGetV2MixPositionSinglePosition(this.extend(request, params));
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700807531673,
        //         "data": [
        //             {
        //                 "marginCoin": "USDT",
        //                 "symbol": "BTCUSDT",
        //                 "holdSide": "long",
        //                 "openDelegateSize": "0",
        //                 "marginSize": "3.73555",
        //                 "available": "0.002",
        //                 "locked": "0",
        //                 "total": "0.002",
        //                 "leverage": "20",
        //                 "achievedProfits": "0",
        //                 "openPriceAvg": "37355.5",
        //                 "marginMode": "crossed",
        //                 "posMode": "hedge_mode",
        //                 "unrealizedPL": "0.007",
        //                 "liquidationPrice": "31724.970702417",
        //                 "keepMarginRate": "0.004",
        //                 "markPrice": "37359",
        //                 "marginRatio": "0.029599540355",
        //                 "cTime": "1700807507275"
        //             }
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        const first = this.safeValue(data, 0, {});
        return this.parsePosition(first, market);
    }
    async fetchPositions(symbols = undefined, params = {}) {
        /**
         * @method
         * @name bitget#fetchPositions
         * @description fetch all open positions
         * @see https://www.bitget.com/api-doc/contract/position/get-all-position
         * @see https://www.bitget.com/api-doc/contract/position/Get-History-Position
         * @param {string[]|undefined} symbols list of unified market symbols
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.marginCoin] the settle currency of the positions, needs to match the productType
         * @param {string} [params.productType] 'USDT-FUTURES', 'USDC-FUTURES', 'COIN-FUTURES', 'SUSDT-FUTURES', 'SUSDC-FUTURES' or 'SCOIN-FUTURES'
         * @param {boolean} [params.paginate] default false, when true will automatically paginate by calling this endpoint multiple times. See in the docs all the [available parameters](https://github.com/ccxt/ccxt/wiki/Manual#pagination-params)
         * @returns {object[]} a list of [position structure]{@link https://docs.ccxt.com/#/?id=position-structure}
         */
        await this.loadMarkets();
        let paginate = false;
        [paginate, params] = this.handleOptionAndParams(params, 'fetchPositions', 'paginate');
        if (paginate) {
            return await this.fetchPaginatedCallCursor('fetchPositions', undefined, undefined, undefined, params, 'endId', 'idLessThan');
        }
        const fetchPositionsOptions = this.safeValue(this.options, 'fetchPositions', {});
        const method = this.safeString(fetchPositionsOptions, 'method', 'privateMixGetV2MixPositionAllPosition');
        let market = undefined;
        if (symbols !== undefined) {
            const first = this.safeString(symbols, 0);
            const sandboxMode = this.safeValue(this.options, 'sandboxMode', false);
            if (sandboxMode) {
                const sandboxSymbol = this.convertSymbolForSandbox(first);
                market = this.market(sandboxSymbol);
            }
            else {
                market = this.market(first);
            }
        }
        let productType = undefined;
        [productType, params] = this.handleProductTypeAndParams(market, params);
        const request = {
            'productType': productType,
        };
        let response = undefined;
        let isHistory = false;
        if (method === 'privateMixGetV2MixPositionAllPosition') {
            let marginCoin = this.safeString(params, 'marginCoin', 'USDT');
            if (symbols !== undefined) {
                marginCoin = market['settleId'];
            }
            else if (productType === this.USDT_FUTURES) {
                marginCoin = 'USDT';
            }
            else if (productType === this.USDC_FUTURES) {
                marginCoin = 'USDC';
            }
            else if (productType === this.SUSDT_FUTURES) {
                marginCoin = 'SUSDT';
            }
            else if (productType === this.SUSDC_FUTURES) {
                marginCoin = 'SUSDC';
            }
            else if ((productType === this.SCOIN_FUTURES) || (productType === this.COIN_FUTURES)) {
                if (marginCoin === undefined) {
                    throw new InvalidParamsError('fetchPositions() requires a marginCoin parameter that matches the productType')
                        .exchange(this.id);
                }
            }
            request['marginCoin'] = marginCoin;
            response = await this.privateMixGetV2MixPositionAllPosition(this.extend(request, params));
        }
        else {
            isHistory = true;
            if (market !== undefined) {
                request['symbol'] = market['id'];
            }
            response = await this.privateMixGetV2MixPositionHistoryPosition(this.extend(request, params));
        }
        //
        // privateMixGetV2MixPositionAllPosition
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700807810221,
        //         "data": [
        //             {
        //                 "marginCoin": "USDT",
        //                 "symbol": "BTCUSDT",
        //                 "holdSide": "long",
        //                 "openDelegateSize": "0",
        //                 "marginSize": "3.73555",
        //                 "available": "0.002",
        //                 "locked": "0",
        //                 "total": "0.002",
        //                 "leverage": "20",
        //                 "achievedProfits": "0",
        //                 "openPriceAvg": "37355.5",
        //                 "marginMode": "crossed",
        //                 "posMode": "hedge_mode",
        //                 "unrealizedPL": "0.03",
        //                 "liquidationPrice": "31725.023602417",
        //                 "keepMarginRate": "0.004",
        //                 "markPrice": "37370.5",
        //                 "marginRatio": "0.029550120396",
        //                 "cTime": "1700807507275"
        //             }
        //         ]
        //     }
        //
        // privateMixGetV2MixPositionHistoryPosition
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700808051002,
        //         "data": {
        //             "list": [
        //                 {
        //                     "symbol": "BTCUSDT",
        //                     "marginCoin": "USDT",
        //                     "holdSide": "long",
        //                     "openAvgPrice": "37272.1",
        //                     "closeAvgPrice": "37271.4",
        //                     "marginMode": "crossed",
        //                     "openTotalPos": "0.001",
        //                     "closeTotalPos": "0.001",
        //                     "pnl": "-0.0007",
        //                     "netProfit": "-0.0454261",
        //                     "totalFunding": "0",
        //                     "openFee": "-0.02236326",
        //                     "closeFee": "-0.02236284",
        //                     "utime": "1700720700400",
        //                     "ctime": "1700720651684"
        //                 },
        //             ],
        //             "endId": "1099351653866962944"
        //         }
        //     }
        //
        let position = [];
        if (!isHistory) {
            position = this.safeValue(response, 'data', []);
        }
        else {
            const data = this.safeValue(response, 'data', {});
            position = this.safeValue(data, 'list', []);
        }
        const result = [];
        for (let i = 0; i < position.length; i++) {
            result.push(this.parsePosition(position[i], market));
        }
        symbols = this.marketSymbols(symbols);
        return this.filterByArrayPositions(result, 'symbol', symbols, false);
    }
    parsePosition(position, market = undefined) {
        //
        // fetchPosition
        //
        //     {
        //         "marginCoin": "USDT",
        //         "symbol": "BTCUSDT",
        //         "holdSide": "long",
        //         "openDelegateSize": "0",
        //         "marginSize": "3.73555",
        //         "available": "0.002",
        //         "locked": "0",
        //         "total": "0.002",
        //         "leverage": "20",
        //         "achievedProfits": "0",
        //         "openPriceAvg": "37355.5",
        //         "marginMode": "crossed",
        //         "posMode": "hedge_mode",
        //         "unrealizedPL": "0.007",
        //         "liquidationPrice": "31724.970702417",
        //         "keepMarginRate": "0.004",
        //         "markPrice": "37359",
        //         "marginRatio": "0.029599540355",
        //         "cTime": "1700807507275"
        //     }
        //
        // fetchPositions: privateMixGetV2MixPositionAllPosition
        //
        //     {
        //         "marginCoin": "USDT",
        //         "symbol": "BTCUSDT",
        //         "holdSide": "long",
        //         "openDelegateSize": "0",
        //         "marginSize": "3.73555",
        //         "available": "0.002",
        //         "locked": "0",
        //         "total": "0.002",
        //         "leverage": "20",
        //         "achievedProfits": "0",
        //         "openPriceAvg": "37355.5",
        //         "marginMode": "crossed",
        //         "posMode": "hedge_mode",
        //         "unrealizedPL": "0.03",
        //         "liquidationPrice": "31725.023602417",
        //         "keepMarginRate": "0.004",
        //         "markPrice": "37370.5",
        //         "marginRatio": "0.029550120396",
        //         "cTime": "1700807507275"
        //     }
        //
        // fetchPositions: privateMixGetV2MixPositionHistoryPosition
        //
        //     {
        //         "symbol": "BTCUSDT",
        //         "marginCoin": "USDT",
        //         "holdSide": "long",
        //         "openAvgPrice": "37272.1",
        //         "closeAvgPrice": "37271.4",
        //         "marginMode": "crossed",
        //         "openTotalPos": "0.001",
        //         "closeTotalPos": "0.001",
        //         "pnl": "-0.0007",
        //         "netProfit": "-0.0454261",
        //         "totalFunding": "0",
        //         "openFee": "-0.02236326",
        //         "closeFee": "-0.02236284",
        //         "utime": "1700720700400",
        //         "ctime": "1700720651684"
        //     }
        //
        // closeAllPositions
        //
        //     {
        //         "orderId": "1120923953904893955",
        //         "clientOid": "1120923953904893956"
        //     }
        //
        const marketId = this.safeString(position, 'symbol');
        market = this.safeMarket(marketId, market, undefined, 'contract');
        const symbol = market['symbol'];
        const timestamp = this.safeInteger2(position, 'cTime', 'ctime');
        let marginMode = this.safeString(position, 'marginMode');
        let collateral = undefined;
        let initialMargin = undefined;
        const unrealizedPnl = this.safeString(position, 'unrealizedPL');
        const rawCollateral = this.safeString(position, 'marginSize');
        if (marginMode === 'isolated') {
            collateral = Precise.stringAdd(rawCollateral, unrealizedPnl);
        }
        else if (marginMode === 'crossed') {
            marginMode = 'cross';
            initialMargin = rawCollateral;
        }
        const holdMode = this.safeString(position, 'posMode');
        let hedged = undefined;
        if (holdMode === 'hedge_mode') {
            hedged = true;
        }
        else if (holdMode === 'one_way_mode') {
            hedged = false;
        }
        const side = this.safeString(position, 'holdSide');
        const leverage = this.safeString(position, 'leverage');
        const contractSizeNumber = this.safeValue(market, 'contractSize');
        const contractSize = this.numberToString(contractSizeNumber);
        const baseAmount = this.safeString(position, 'total');
        const entryPrice = this.safeString2(position, 'openPriceAvg', 'openAvgPrice');
        const maintenanceMarginPercentage = this.safeString(position, 'keepMarginRate');
        const openNotional = Precise.stringMul(entryPrice, baseAmount);
        if (initialMargin === undefined) {
            initialMargin = Precise.stringDiv(openNotional, leverage);
        }
        let contracts = this.parseNumber(Precise.stringDiv(baseAmount, contractSize));
        if (contracts === undefined) {
            contracts = this.safeNumber(position, 'closeTotalPos');
        }
        const markPrice = this.safeString(position, 'markPrice');
        const notional = Precise.stringMul(baseAmount, markPrice);
        const initialMarginPercentage = Precise.stringDiv(initialMargin, notional);
        let liquidationPrice = this.parseNumber(this.omitZero(this.safeString(position, 'liquidationPrice')));
        const calcTakerFeeRate = '0.0006';
        const calcTakerFeeMult = '0.9994';
        if ((liquidationPrice === undefined) && (marginMode === 'isolated') && Precise.stringGt(baseAmount, '0')) {
            let signedMargin = Precise.stringDiv(rawCollateral, baseAmount);
            let signedMmp = maintenanceMarginPercentage;
            if (side === 'short') {
                signedMargin = Precise.stringNeg(signedMargin);
                signedMmp = Precise.stringNeg(signedMmp);
            }
            let mmrMinusOne = Precise.stringSub('1', signedMmp);
            let numerator = Precise.stringSub(entryPrice, signedMargin);
            if (side === 'long') {
                mmrMinusOne = Precise.stringMul(mmrMinusOne, calcTakerFeeMult);
            }
            else {
                numerator = Precise.stringMul(numerator, calcTakerFeeMult);
            }
            liquidationPrice = this.parseNumber(Precise.stringDiv(numerator, mmrMinusOne));
        }
        const feeToClose = Precise.stringMul(notional, calcTakerFeeRate);
        const maintenanceMargin = Precise.stringAdd(Precise.stringMul(maintenanceMarginPercentage, notional), feeToClose);
        const percentage = Precise.stringMul(Precise.stringDiv(unrealizedPnl, initialMargin, 4), '100');
        return this.safePosition({
            'info': position,
            'id': this.safeString(position, 'orderId'),
            'symbol': symbol,
            'notional': this.parseNumber(notional),
            'marginMode': marginMode,
            'liquidationPrice': liquidationPrice,
            'entryPrice': this.parseNumber(entryPrice),
            'unrealizedPnl': this.parseNumber(unrealizedPnl),
            'realizedPnl': this.safeNumber(position, 'pnl'),
            'percentage': this.parseNumber(percentage),
            'contracts': contracts,
            'contractSize': contractSizeNumber,
            'markPrice': this.parseNumber(markPrice),
            'lastPrice': this.safeNumber(position, 'closeAvgPrice'),
            'side': side,
            'hedged': hedged,
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'lastUpdateTimestamp': this.safeInteger(position, 'utime'),
            'maintenanceMargin': this.parseNumber(maintenanceMargin),
            'maintenanceMarginPercentage': this.parseNumber(maintenanceMarginPercentage),
            'collateral': this.parseNumber(collateral),
            'initialMargin': this.parseNumber(initialMargin),
            'initialMarginPercentage': this.parseNumber(initialMarginPercentage),
            'leverage': this.parseNumber(leverage),
            'marginRatio': this.safeNumber(position, 'marginRatio'),
            'stopLossPrice': undefined,
            'takeProfitPrice': undefined,
        });
    }
    async fetchTransfers(code = undefined, since = undefined, limit = undefined, params = {}) {
        /**
         * @method
         * @name bitget#fetchTransfers
         * @description fetch a history of internal transfers made on an account
         * @see https://www.bitget.com/api-doc/spot/account/Get-Account-TransferRecords
         * @param {string} code unified currency code of the currency transferred
         * @param {int} [since] the earliest time in ms to fetch transfers for
         * @param {int} [limit] the maximum number of transfers structures to retrieve
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {int} [params.until] the latest time in ms to fetch entries for
         * @returns {object[]} a list of [transfer structures]{@link https://docs.ccxt.com/#/?id=transfer-structure}
         */
        if (code === undefined) {
            throw new InvalidParamsError('fetchTransfers() requires a code argument')
                .exchange(this.id);
        }
        await this.loadMarkets();
        let type = undefined;
        [type, params] = this.handleMarketTypeAndParams('fetchTransfers', undefined, params);
        const fromAccount = this.safeString(params, 'fromAccount', type);
        params = this.omit(params, 'fromAccount');
        const accountsByType = this.safeValue(this.options, 'accountsByType', {});
        type = this.safeString(accountsByType, fromAccount);
        const currency = this.currency(code);
        let request = {
            'coin': currency['code'],
            'fromType': type,
        };
        if (since !== undefined) {
            request['startTime'] = since;
        }
        if (limit !== undefined) {
            request['limit'] = limit;
        }
        [request, params] = this.handleUntilOption('endTime', request, params);
        const response = await this.privateSpotGetV2SpotAccountTransferRecords(this.extend(request, params));
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700873854651,
        //         "data": [
        //             {
        //                 "coin": "USDT",
        //                 "status": "Successful",
        //                 "toType": "crossed_margin",
        //                 "toSymbol": "",
        //                 "fromType": "spot",
        //                 "fromSymbol": "",
        //                 "size": "11.64958799",
        //                 "ts": "1700729673028",
        //                 "clientOid": "1111506298504744960",
        //                 "transferId": "24930940"
        //             },
        //         ]
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        return this.parseTransfers(data, currency, since, limit);
    }
    async transfer(code, amount, fromAccount, toAccount, params = {}) {
        /**
         * @method
         * @name bitget#transfer
         * @description transfer currency internally between wallets on the same account
         * @see https://www.bitget.com/api-doc/spot/account/Wallet-Transfer
         * @param {string} code unified currency code
         * @param {float} amount amount to transfer
         * @param {string} fromAccount account to transfer from
         * @param {string} toAccount account to transfer to
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @param {string} [params.symbol] unified CCXT market symbol, required when transferring to or from an account type that is a leveraged position-by-position account
         * @param {string} [params.clientOid] custom id
         * @returns {object} a [transfer structure]{@link https://docs.ccxt.com/#/?id=transfer-structure}
         */
        await this.loadMarkets();
        const currency = this.currency(code);
        const accountsByType = this.safeValue(this.options, 'accountsByType', {});
        const fromType = this.safeString(accountsByType, fromAccount);
        const toType = this.safeString(accountsByType, toAccount);
        const precisionAmount = this.currencyToPrecision(code, amount);
        const request = {
            'fromType': fromType,
            'toType': toType,
            'amount': precisionAmount,
            'coin': currency['code'],
        };
        const symbol = this.safeString(params, 'symbol');
        params = this.omit(params, 'symbol');
        let market = undefined;
        if (symbol !== undefined) {
            market = this.market(symbol);
            request['symbol'] = market['id'];
        }
        const response = await this.privateSpotPostV2SpotWalletTransfer(this.extend(request, params));
        //
        //     {
        //         "code": "00000",
        //         "msg": "success",
        //         "requestTime": 1700874302021,
        //         "data": {
        //             "transferId": "1112112916581847040",
        //             "clientOrderId": null
        //         }
        //     }
        //
        const data = this.safeValue(response, 'data', {});
        data['ts'] = this.safeInteger(response, 'requestTime');
        return this.parseTransfer(data, currency);
    }
    parseTransfer(transfer, currency = undefined) {
        //
        // transfer
        //
        //     {
        //         "transferId": "1112112916581847040",
        //         "clientOrderId": null,
        //         "ts": 1700874302021
        //     }
        //
        // fetchTransfers
        //
        //     {
        //         "coin": "USDT",
        //         "status": "Successful",
        //         "toType": "crossed_margin",
        //         "toSymbol": "",
        //         "fromType": "spot",
        //         "fromSymbol": "",
        //         "size": "11.64958799",
        //         "ts": "1700729673028",
        //         "clientOid": "1111506298504744960",
        //         "transferId": "24930940"
        //     }
        //
        const timestamp = this.safeInteger(transfer, 'ts');
        const status = this.safeStringLower(transfer, 'status');
        const currencyId = this.safeString(transfer, 'coin');
        const fromAccountRaw = this.safeString(transfer, 'fromType');
        const accountsById = this.safeValue(this.options, 'accountsById', {});
        const fromAccount = this.safeString(accountsById, fromAccountRaw, fromAccountRaw);
        const toAccountRaw = this.safeString(transfer, 'toType');
        const toAccount = this.safeString(accountsById, toAccountRaw, toAccountRaw);
        return {
            'info': transfer,
            'id': this.safeString(transfer, 'transferId'),
            'timestamp': timestamp,
            'datetime': this.iso8601(timestamp),
            'currency': this.safeCurrencyCode(currencyId, currency),
            'amount': this.safeNumber(transfer, 'size'),
            'fromAccount': fromAccount,
            'toAccount': toAccount,
            'status': this.parseTransferStatus(status),
        };
    }
    parseTransferStatus(status) {
        const statuses = {
            'successful': 'ok',
        };
        return this.safeString(statuses, status, status);
    }
    parseDepositWithdrawFee(fee, currency = undefined) {
        //
        //     {
        //         "chains": [
        //             {
        //                 "browserUrl": "https://blockchair.com/bitcoin/transaction/",
        //                 "chain": "BTC",
        //                 "depositConfirm": "1",
        //                 "extraWithdrawFee": "0",
        //                 "minDepositAmount": "0.0001",
        //                 "minWithdrawAmount": "0.005",
        //                 "needTag": "false",
        //                 "rechargeable": "true",
        //                 "withdrawConfirm": "1",
        //                 "withdrawFee": "0.0004",
        //                 "withdrawable": "true"
        //             },
        //         ],
        //         "coin": "BTC",
        //         "coinId": "1",
        //         "transfer": "true""
        //     }
        //
        const chains = this.safeValue(fee, 'chains', []);
        const chainsLength = chains.length;
        const result = {
            'info': fee,
            'withdraw': {
                'fee': undefined,
                'percentage': undefined,
            },
            'deposit': {
                'fee': undefined,
                'percentage': undefined,
            },
            'networks': {},
        };
        for (let i = 0; i < chainsLength; i++) {
            const chain = chains[i];
            const networkId = this.safeString(chain, 'chain');
            const currencyCode = this.safeString(currency, 'code');
            const networkCode = this.networkIdToCode(networkId, currencyCode);
            result['networks'][networkCode] = {
                'deposit': { 'fee': undefined, 'percentage': undefined },
                'withdraw': { 'fee': this.safeNumber(chain, 'withdrawFee'), 'percentage': false },
            };
            if (chainsLength === 1) {
                result['withdraw']['fee'] = this.safeNumber(chain, 'withdrawFee');
                result['withdraw']['percentage'] = false;
            }
        }
        return result;
    }
    async fetchDepositWithdrawFees(codes = undefined, params = {}) {
        /**
         * @method
         * @name bitget#fetchDepositWithdrawFees
         * @description fetch deposit and withdraw fees
         * @see https://www.bitget.com/api-doc/spot/market/Get-Coin-List
         * @param {string[]|undefined} codes list of unified currency codes
         * @param {object} [params] extra parameters specific to the exchange API endpoint
         * @returns {object} a list of [fee structures]{@link https://docs.ccxt.com/#/?id=fee-structure}
         */
        await this.loadMarkets();
        const response = await this.publicSpotGetV2SpotPublicCoins(params);
        //
        //     {
        //         "code": "00000",
        //         "data": [
        //             {
        //                 "chains": [
        //                     {
        //                         "browserUrl": "https://blockchair.com/bitcoin/transaction/",
        //                         "chain": "BTC",
        //                         "depositConfirm": "1",
        //                         "extraWithdrawFee": "0",
        //                         "minDepositAmount": "0.0001",
        //                         "minWithdrawAmount": "0.005",
        //                         "needTag": "false",
        //                         "rechargeable": "true",
        //                         "withdrawConfirm": "1",
        //                         "withdrawFee": "0.0004",
        //                         "withdrawable": "true"
        //                     },
        //                 ],
        //                 "coin": "BTC",
        //                 "coinId": "1",
        //                 "transfer": "true""
        //             }
        //         ],
        //         "msg": "success",
        //         "requestTime": "1700120731773"
        //     }
        //
        const data = this.safeValue(response, 'data', []);
        return this.parseDepositWithdrawFees(data, codes, 'coin');
    }
    handleErrors(code, reason, url, method, headers, body, response, requestHeaders, requestBody) {
        if (!response) {
            return undefined; // fallback to default error handler
        }
        //
        // spot
        //
        //     {"status":"fail","err_code":"01001","err_msg":"系统异常，请稍后重试"}
        //     {"status":"error","ts":1595594160149,"err_code":"invalid-parameter","err_msg":"invalid size, valid range: [1,2000]"}
        //     {"status":"error","ts":1595684716042,"err_code":"invalid-parameter","err_msg":"illegal sign invalid"}
        //     {"status":"error","ts":1595700216275,"err_code":"bad-request","err_msg":"your balance is low!"}
        //     {"status":"error","ts":1595700344504,"err_code":"invalid-parameter","err_msg":"invalid type"}
        //     {"status":"error","ts":1595703343035,"err_code":"bad-request","err_msg":"order cancel fail"}
        //     {"status":"error","ts":1595704360508,"err_code":"invalid-parameter","err_msg":"accesskey not null"}
        //     {"status":"error","ts":1595704490084,"err_code":"invalid-parameter","err_msg":"permissions not right"}
        //     {"status":"error","ts":1595711862763,"err_code":"system exception","err_msg":"system exception"}
        //     {"status":"error","ts":1595730308979,"err_code":"bad-request","err_msg":"20003"}
        //
        // swap
        //
        //     {"code":"40015","msg":"","requestTime":1595698564931,"data":null}
        //     {"code":"40017","msg":"Order id must not be blank","requestTime":1595702477835,"data":null}
        //     {"code":"40017","msg":"Order Type must not be blank","requestTime":1595698516162,"data":null}
        //     {"code":"40301","msg":"","requestTime":1595667662503,"data":null}
        //     {"code":"40017","msg":"Contract code must not be blank","requestTime":1595703151651,"data":null}
        //     {"code":"40108","msg":"","requestTime":1595885064600,"data":null}
        //     {"order_id":"513468410013679613","client_oid":null,"symbol":"ethusd","result":false,"err_code":"order_no_exist_error","err_msg":"订单不存在！"}
        //
        const message = this.safeString2(response, 'err_msg', 'msg');
        const errorCode = this.safeString2(response, 'code', 'err_code');
        const userMessage = errorCode + ': ' + message;
        const nonEmptyMessage = ((message !== undefined) && (message !== ''));
        if (nonEmptyMessage) {
            this.throwExactlyMatchedException(this.exceptions['exact'], message, userMessage);
            this.throwBroadlyMatchedException(this.exceptions['broad'], message, userMessage);
        }
        const nonZeroErrorCode = (errorCode !== undefined) && (errorCode !== '00000');
        if (nonZeroErrorCode) {
            this.throwExactlyMatchedException(this.exceptions['exact'], errorCode, userMessage);
        }
        if (nonZeroErrorCode || (nonEmptyMessage && errorCode !== '00000')) {
            throw new ExchangeError().addMessage(userMessage).exchange(this.id); // unknown message
        }
        return undefined;
    }
    sign(path, api = [], method = 'GET', params = {}, headers = undefined, body = undefined) {
        const signed = api[0] === 'private';
        const endpoint = api[1];
        const pathPart = '/api';
        const request = '/' + this.implodeParams(path, params);
        const payload = pathPart + request;
        let url = this.implodeHostname(this.urls['api'][endpoint]) + payload;
        const query = this.omit(params, this.extractParams(path));
        if (!signed && (method === 'GET')) {
            const keys = Object.keys(query);
            const keysLength = keys.length;
            if (keysLength > 0) {
                url = url + '?' + this.urlencode(query);
            }
        }
        if (signed) {
            this.checkRequiredCredentials();
            const timestamp = this.nonce().toString();
            let auth = timestamp + method + payload;
            if (method === 'POST') {
                body = this.json(params);
                auth += body;
            }
            else {
                if (Object.keys(params).length) {
                    let queryInner = '?' + this.urlencode(this.keysort(params));
                    // check #21169 pr
                    if (queryInner.indexOf('%24') > -1) {
                        queryInner = queryInner.replace('%24', '$');
                    }
                    url += queryInner;
                    auth += queryInner;
                }
            }
            const signature = this.hmac(this.encode(auth), this.encode(this.secret), sha256, 'base64');
            const broker = this.safeString(this.options, 'broker');
            headers = {
                'ACCESS-KEY': this.apiKey,
                'ACCESS-SIGN': signature,
                'ACCESS-TIMESTAMP': timestamp,
                'ACCESS-PASSPHRASE': this.password,
                'X-CHANNEL-API-CODE': broker,
            };
            if (method === 'POST') {
                headers['Content-Type'] = 'application/json';
            }
        }
        return { 'url': url, 'method': method, 'body': body, 'headers': headers };
    }
}
