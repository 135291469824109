import { IconTextSubText } from "@cede/ds";
import { isCefiInfoTx } from "@cede/types";
import { firstLetterToUpperCase, forgeTokenURL, formatBalance, getExchangeLogo } from "@cede/utils";
import { useDependencies } from "../../hooks";

export const SendHeader = () => {
  const { useSelectedTransaction } = useDependencies();
  const { transaction, isSend } = useSelectedTransaction(({ transaction, isSend }) => ({
    transaction,
    isSend,
  }));
  if (!transaction || !isSend) return null;
  const from = transaction.from;
  if (!isCefiInfoTx(from)) return null;

  return (
    <>
      <IconTextSubText
        iconName={from.account.exchangeId}
        icon={getExchangeLogo(from.account.exchangeId)}
        text={firstLetterToUpperCase(from.account.exchangeId)}
        subText={"CEX"}
      />
      <IconTextSubText
        sx={{ width: "auto" }}
        iconName={from.transaction.tokenSymbol}
        icon={forgeTokenURL(from.transaction.tokenSymbol)}
        text={`${formatBalance(from.transaction.amount)} ${from.transaction.tokenSymbol}`}
        subText={"Amount"}
        sxSubGroup={{
          alignItems: "flex-end",
        }}
      />
    </>
  );
};
