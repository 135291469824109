import { ReactElement } from "react";
import { ApiPermissions } from "../cefi";
import { TokenSymbol } from "../untrustedApi";

export type WidgetEvents = "sendTxStatusUpdate" | "widgetClosed";

export type SendTxStatusUpdate = "submitted" | "txIdRetrieved" | "txIdNotRetrieved" | "failed";

export type SendWidgetConfig = {
  tokenSymbol?: TokenSymbol;
  network?: string | number;
  address?: string;
  amount?: string;
  lockNetwork?: boolean;
  exchangeIds?: string[];
  clientTxId?: string;
  callbackUrl?: string;
  isInternalTransfer?: boolean;
};

export type SendWidgetTheme = {
  mode?: "dark" | "light";
  logoTheme?: "dark" | "light";
  fontFamily?: string;
  borderRadius?: number;
  width?: string;
  primaryColor?: string;
  primaryTextColor?: string;
  secondaryTextColor?: string;
  inputBorderColor?: string;
  logoBorderColor?: string;
  borderColor?: string;
  errorColor?: string;
  warningColor?: string;
  secondaryColor?: string;
  accentColor?: string;
  buttonTextColor?: string;
  deactivateButtonColor?: string;
};

export enum WidgetType {
  TRADE = "trade",
  SEND = "send",
}

export type SendWidgetProps = {
  title?: string;
  description?: string;
  config?: SendWidgetConfig;
  logo?: ReactElement | null;
  navbarLogo?: ReactElement | null;
  theme?: SendWidgetTheme;
};

export type TradeWidgetConfig = {
  tokenSymbols?: TokenSymbol[];
};

export type TradeWidgetProps = {
  title?: string;
  description?: string;
  config?: TradeWidgetConfig;
  logo?: ReactElement | null;
  navbarLogo?: ReactElement | null;
  theme?: SendWidgetTheme;
};

export type SendUniversalWidgetConfig = SendWidgetProps & {
  selector: string;
  logo?: string;
  navbarLogo?: string;
};

export type TradeUniversalWidgetConfig = TradeWidgetProps & {
  selector: string;
  logo?: string;
  navbarLogo?: string;
};

export type InpageHelperConfig = {
  exchangeId: string;
  permissions: ApiPermissions[];
};
