import { ApiPermissions } from "@cede/types";

export const PERMISSIONS_ICONS = {
  [ApiPermissions.READ]: (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M7 3.02801C5.90107 2.04224 4.47626 1.49797 3 1.50001C2.29867 1.50001 1.62533 1.62001 1 1.84134V11.3413C1.64241 11.1147 2.31878 10.9993 3 11C4.53667 11 5.93867 11.578 7 12.528M7 3.02801C8.09889 2.04219 9.52372 1.4979 11 1.50001C11.7013 1.50001 12.3747 1.62001 13 1.84134V11.3413C12.3576 11.1147 11.6812 10.9993 11 11C9.52374 10.998 8.09893 11.5422 7 12.528M7 3.02801V12.528"
        stroke="white"
        strokeOpacity="0.56"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  [ApiPermissions.TRADE]: (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.63115 0.846559C8.38381 0.997567 8.3812 1.39331 8.62854 1.52609C8.67801 1.55473 8.83423 1.61722 8.97743 1.66929C10.1386 2.08847 11.1879 2.93464 11.9507 4.0698C12.0523 4.22081 12.1356 4.35099 12.1304 4.35359C12.1252 4.3588 12.0471 4.34318 11.9533 4.31714C11.7919 4.27548 11.7789 4.27548 11.6617 4.31974C11.3753 4.4291 11.3154 4.81963 11.555 5.0097C11.594 5.03833 11.9117 5.1581 12.2658 5.27526C12.987 5.51479 13.0338 5.52 13.1978 5.36899C13.2759 5.29609 13.315 5.21017 13.4947 4.67904C13.7212 4.01512 13.7472 3.86672 13.6535 3.72613C13.5728 3.60636 13.4712 3.55429 13.315 3.55429C13.0911 3.55429 12.987 3.64542 12.8828 3.93181C12.8646 3.98128 12.8646 3.98128 12.8359 3.93441C12.8177 3.90838 12.7136 3.74956 12.6042 3.58293C11.7685 2.32279 10.5318 1.34384 9.19092 0.883009C8.89671 0.781469 8.75352 0.771055 8.63115 0.846559Z"
        fill="white"
        fillOpacity="0.56"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33569 2.44514C2.97096 1.81247 3.726 1.43495 4.60341 1.31779C4.89762 1.27613 5.60319 1.30477 5.9026 1.36726C6.41811 1.47661 6.86853 1.66667 7.31114 1.95827C7.62357 2.16916 8.10784 2.63781 8.31873 2.94243C8.7301 3.53344 8.96702 4.19475 9.02951 4.92376L9.04773 5.13725L9.26123 5.15548C10.1803 5.23359 10.9978 5.59288 11.6487 6.20212C12.258 6.77751 12.6407 7.45965 12.8229 8.30322C12.9063 8.68334 12.9063 9.46442 12.8229 9.84454C12.6485 10.6569 12.3126 11.2739 11.7398 11.8493C11.016 12.5731 10.1491 12.9558 9.13365 12.9975C8.23542 13.0365 7.34238 12.7684 6.63421 12.245C6.37906 12.0576 5.93124 11.5994 5.7568 11.352C5.34543 10.7636 5.10851 10.1023 5.04602 9.37069L5.0278 9.15719L4.8143 9.13897C4.27796 9.09471 3.78849 8.95672 3.33286 8.725C2.93972 8.52452 2.66634 8.32404 2.32527 7.98297C1.7629 7.4206 1.42443 6.79313 1.25259 5.99123C1.16928 5.6059 1.16928 4.83263 1.2552 4.43949C1.42703 3.63759 1.7629 3.02054 2.33569 2.44514ZM8.18595 4.6608C7.92819 3.3538 6.89457 2.34621 5.60579 2.13532C4.61903 1.9765 3.62707 2.30195 2.90848 3.01793C1.79674 4.12966 1.67698 5.84022 2.62468 7.09775C3.0022 7.60025 3.55156 7.99599 4.14258 8.18605C4.50708 8.30322 4.69714 8.33185 5.13715 8.32925C5.56934 8.32925 5.83751 8.28239 6.19941 8.1444C7.02995 7.83457 7.72511 7.13941 8.03494 6.30887C8.17293 5.94697 8.21979 5.6788 8.21979 5.2466C8.2224 4.99926 8.20938 4.77275 8.18595 4.6608ZM12.0497 9.56856C12.3126 7.93351 11.2217 6.35834 9.58928 6.01466C9.48774 5.99383 9.30549 5.9678 9.18312 5.95999C8.97483 5.94176 8.96442 5.94436 8.96442 5.99383C8.96442 6.07715 8.84205 6.48591 8.74572 6.71763L8.6624 6.92592C8.10747 8.13984 7.18146 8.67276 6.78782 8.78748C6.78522 8.78748 6.6967 8.82133 6.58995 8.86299C6.36864 8.95411 5.96248 9.07388 5.88438 9.07388C5.83491 9.07388 5.8323 9.08429 5.85053 9.29258C5.93384 10.3939 6.57693 11.3546 7.56369 11.8597C9.43046 12.81 11.7112 11.6462 12.0497 9.56856Z"
        fill="white"
        fillOpacity="0.56"
      />
      <path
        d="M1.01045 8.84218C0.849024 8.88644 0.799555 8.97757 0.580854 9.61805C0.354342 10.2768 0.328306 10.4252 0.422035 10.5684C0.502746 10.6881 0.604286 10.7402 0.760502 10.7402C0.98441 10.7402 1.08855 10.6491 1.1927 10.3627C1.21092 10.3132 1.21092 10.3132 1.23956 10.3601C1.58324 10.9198 1.9191 11.352 2.36431 11.7973C2.85899 12.2945 3.34847 12.6669 3.91084 12.9793C4.38209 13.2396 5.05121 13.5 5.24909 13.5C5.54329 13.5 5.72815 13.1693 5.58495 12.9038C5.52767 12.7996 5.44175 12.7476 5.09808 12.6252C3.93688 12.2086 2.87201 11.3468 2.11176 10.2091C2.01543 10.0659 1.93993 9.9435 1.94513 9.9409C1.95034 9.93569 2.02845 9.95131 2.12218 9.97735C2.2836 10.019 2.29662 10.019 2.41378 9.97474C2.57781 9.91226 2.66372 9.78468 2.66372 9.60764C2.66372 9.46444 2.60124 9.33686 2.4997 9.26917C2.41638 9.2171 1.21092 8.81354 1.14063 8.81615C1.11199 8.81615 1.0521 8.82916 1.01045 8.84218Z"
        fill="white"
        fillOpacity="0.56"
      />
    </svg>
  ),
  [ApiPermissions.WITHDRAW]: (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none">
      <path
        d="M2.66699 2.00033C2.8438 2.00033 3.01337 1.93009 3.1384 1.80506C3.26342 1.68004 3.33366 1.51047 3.33366 1.33366C3.33366 1.15685 3.26342 0.987279 3.1384 0.862254C3.01337 0.73723 2.8438 0.666992 2.66699 0.666992H1.33366C1.15685 0.666992 0.987279 0.73723 0.862254 0.862254C0.73723 0.987279 0.666992 1.15685 0.666992 1.33366V10.667C0.666992 10.8438 0.73723 11.0134 0.862254 11.1384C0.987279 11.2634 1.15685 11.3337 1.33366 11.3337H2.66699C2.8438 11.3337 3.01337 11.2634 3.1384 11.1384C3.26342 11.0134 3.33366 10.8438 3.33366 10.667C3.33366 10.4902 3.26342 10.3206 3.1384 10.1956C3.01337 10.0706 2.8438 10.0003 2.66699 10.0003H2.00033V2.00033H2.66699ZM11.8803 5.61366L10.0003 2.94699C9.89837 2.80327 9.74366 2.70575 9.57001 2.67577C9.39636 2.64579 9.21791 2.68578 9.07366 2.78699C9.00156 2.83752 8.94018 2.90182 8.89306 2.9762C8.84595 3.05057 8.81403 3.13354 8.79916 3.22032C8.78428 3.30709 8.78674 3.39595 8.80639 3.48177C8.82605 3.56759 8.8625 3.64867 8.91366 3.72033L10.0603 5.33366H4.66699C4.49018 5.33366 4.32061 5.4039 4.19559 5.52892C4.07056 5.65394 4.00033 5.82351 4.00033 6.00033C4.00033 6.17714 4.07056 6.34671 4.19559 6.47173C4.32061 6.59675 4.49018 6.66699 4.66699 6.66699H10.0003L8.80033 8.26699C8.7478 8.33703 8.70958 8.41673 8.68785 8.50154C8.66612 8.58635 8.66131 8.67461 8.67369 8.76127C8.68607 8.84794 8.7154 8.93132 8.76001 9.00665C8.80462 9.08199 8.86362 9.1478 8.93366 9.20032C9.04906 9.28687 9.18941 9.33366 9.33366 9.33366C9.43716 9.33366 9.53923 9.30956 9.6318 9.26328C9.72437 9.21699 9.80489 9.14979 9.86699 9.06699L11.867 6.40033C11.9524 6.28757 11.9997 6.15058 12.0021 6.00917C12.0045 5.86775 11.9618 5.72924 11.8803 5.61366Z"
        fill="white"
        fillOpacity="0.56"
      />
    </svg>
  ),
};

function getPermissionIcon(permission: ApiPermissions) {
  return PERMISSIONS_ICONS[permission];
}

type Props = {
  permission: ApiPermissions;
};

export function PermissionIcon({ permission }: Props) {
  return getPermissionIcon(permission);
}
