import { HoveredIconProps } from "./types";
import { Box, SvgIcon, useTheme } from "@mui/material";

export const HoveredIcon = ({ redirect, children, fill, size = "24px" }: HoveredIconProps) => {
  const theme = useTheme();
  fill = fill || theme.palette.text.secondary;
  return (
    <Box
      sx={{
        ":hover": {
          cursor: "pointer",
        },
      }}
    >
      <SvgIcon
        onClick={redirect}
        data-testid="hovered-icon"
        component="svg"
        sx={{
          "fontSize": size,
          ":hover": {
            fill: theme.palette.primary.main,
          },
          "fill": fill,
        }}
      >
        {children}
      </SvgIcon>
    </Box>
  );
};
