import { ConnectionStatus, ConnectionStatusProps, PendingTransactionLoader } from "@cede/ds";
import { TxStatus, isCefiInfoTx } from "@cede/types";
import { useDependencies } from "../../hooks";
import { Box, Typography } from "@mui/material";

export const statusStates = {
  [TxStatus.OK]: "Completed",
  [TxStatus.FAILED]: "Rejected",
  [TxStatus.PENDING]: "In progress",
  [TxStatus.CANCELLED]: "Canceled",
};

export const connectionStatusColors: Record<TxStatus, ConnectionStatusProps["color"]> = {
  [TxStatus.OK]: "success",
  [TxStatus.FAILED]: "error",
  [TxStatus.PENDING]: "warning",
  [TxStatus.CANCELLED]: "error",
};

export const StatusLine = () => {
  const { useSelectedTransaction } = useDependencies();
  const { transaction, isReceive } = useSelectedTransaction(({ transaction, isReceive }) => ({
    transaction,
    isReceive,
  }));

  if (!transaction) return null;

  const infoTx =
    isCefiInfoTx(transaction.to) && isReceive
      ? transaction.to
      : isCefiInfoTx(transaction.from)
      ? transaction.from
      : null;

  if (!infoTx) return null;

  return (
    <>
      <Typography variant={"body2"}>Status</Typography>
      <Box justifySelf={"right"} display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <ConnectionStatus
          label={statusStates[infoTx.transaction.status]}
          color={connectionStatusColors[infoTx.transaction.status]}
        />
        {infoTx.transaction.status === TxStatus.PENDING && <PendingTransactionLoader />}
      </Box>
    </>
  );
};
