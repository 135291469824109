import React, { ChangeEventHandler, Ref, useMemo } from "react";
import { DropListWithIconItem } from "@cede/types";
import { formatBalance, sortItemsByDescendingAmountAndAlphabetical } from "@cede/utils";
import { CurrencyAmountFormatter } from "../CurrencyAmountFormatter";
import { DropListWithIconModal } from "../DropListWithIconModal";
import { AmountFieldInputComponentProps, AmountFieldWithTokensProps } from "./types";
import { Box, FormControl, InputBase, InputLabel, OutlinedInput, Typography, useTheme } from "@mui/material";

export const InputComponent = React.forwardRef(
  (
    { currency, estimatedValue, local, displayDefaultEstimatedValue = true, ...props }: AmountFieldInputComponentProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const theme = useTheme();
    return (
      <Box width={"100%"} paddingRight={theme.custom.gutterMargin}>
        <InputBase
          {...props}
          ref={ref}
          sx={{
            padding: "10px 0px!important",
            width: "95%",
          }}
          inputProps={{ "id": props.id, "data-testid": props["data-testid"] + "-input" }}
        />
        {displayDefaultEstimatedValue || estimatedValue > 0 ? (
          <Box display="flex" alignItems="center" sx={{ gap: "3px", color: theme.palette.action.disabled }}>
            <Typography component="span">≈</Typography>
            <CurrencyAmountFormatter
              amount={estimatedValue}
              local={local}
              currency={currency}
              size="small"
              id={String(props.id) + "-currency-amount-formatter"}
              colors={{
                integer: theme.palette.action.disabled,
                decimal: theme.palette.action.disabled,
              }}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    );
  },
);

export const AmountFieldWithTokens = ({
  assets,
  label,
  amount,
  setAmount,
  local,
  currency,
  estimatedValue,
  selectedAsset,
  setSelectedAsset,
  loading,
  id,
  error,
  endAdornment,
  displayDefaultEstimatedValue,
  onFocus,
  availableBalance,
  subListTitles,
}: AmountFieldWithTokensProps) => {
  const theme = useTheme();

  const handleInput: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    // \s is used to match any whitespace character
    const value = e.target.value.replace(/\s/g, "");

    // If the value is an empty string, set the amount to "0"
    if (value === "") {
      setAmount("0");
      return;
    }

    const hasMoreDecimalPoints = (value.match(/\./gi) ?? "").length > 1;
    if (hasMoreDecimalPoints && value.endsWith(".")) {
      // amount without the decimal point at the end
      const newAmount = value.substring(0, value.length - 1);
      setAmount(newAmount);
      return;
    }

    // if value contains "." and value.endsWith("0") => 1.0; 1.10; 1.100
    const decimalZero = value.includes(".") && value.endsWith("0"); // 1.00...; 1.10...
    const decimalPlaces = value.includes(".") ? (value.split(".")[1] || "").length : 0;

    // Prevent 10e-7 error
    if (decimalPlaces > 6) {
      const newAmount = value.split(".");
      newAmount[1] = (newAmount[1] || "").slice(0, 6);
      setAmount(newAmount.join("."));
    }

    // If the value ends with ".", append "0" to form a valid floating point number
    const inputNumber = decimalZero ? value : parseFloat(value.endsWith(".") ? value + "0" : value);
    if (!decimalZero && isNaN(inputNumber as number)) return;

    // If the number is too large, return to avoid setting amount to "Infinity"
    if (inputNumber === Infinity) return; // TODO: we should use BigNumber instead of float everywhere (to refactor)

    // If the original value ends with ".", add it back after formatting the balance
    setAmount(decimalZero ? value : formatBalance(inputNumber as number) + (value.endsWith(".") ? "." : ""));
  };

  const sortedItems = useMemo(() => {
    if (Array.isArray(assets)) {
      return sortItemsByDescendingAmountAndAlphabetical(assets);
    }
    return Object.entries(assets).reduce((acc, [key, value]) => {
      acc[key] = sortItemsByDescendingAmountAndAlphabetical(value);
      return acc;
    }, {} as Record<string, DropListWithIconItem[]>);
  }, [assets]);

  return (
    <FormControl variant="outlined" data-testid={id} fullWidth>
      <InputLabel shrink>{label}</InputLabel>
      <OutlinedInput
        autoComplete="off"
        disabled={loading}
        sx={(theme) => ({
          padding: theme.custom.gutterMargin,
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5),
          borderRadius: theme.custom.inputRadius.default,
          justifyContent: "space-between",
        })}
        notched
        value={amount}
        onChange={handleInput}
        label={label}
        inputComponent={InputComponent}
        error={error}
        inputProps={{
          theme,
          estimatedValue,
          displayDefaultEstimatedValue,
          currency,
          local,
          id,
          "data-testid": String(id),
        }}
        endAdornment={
          endAdornment || (
            <Box width={"120%"} display={"inline-flex"} flexDirection={"column"} alignItems={"flex-end"}>
              <Box width={"110px"}>
                <DropListWithIconModal
                  title="Select Currency"
                  inputProps={{
                    id: `${id || ""}-asset-drop-list`,
                  }}
                  loading={loading}
                  value={selectedAsset}
                  onChange={setSelectedAsset}
                  items={sortedItems}
                  disableModalPortal={true}
                  rounded
                  padding={theme.spacing(0.5)}
                  sublistTitles={subListTitles}
                />
              </Box>
              {availableBalance}
            </Box>
          )
        }
        onFocus={onFocus}
      />
    </FormControl>
  );
};
