/*
 * Copyright (C) CEDE Labs, SAS - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by CEDE Labs team <team@cedelabs.io>, September 2021
 */
import { ReactElement } from "react";
import { Logger } from "@cede/utils";
import { create, StoreApi, UseBoundStore } from "zustand";

export type LoadingState = {
  nbBigRequests: number;
  nbSmallRequests: number;
  isBlockingLoading: boolean;
  isLoading: boolean;
  description: string | null | ReactElement;
  setDescription: (description: string | null | ReactElement) => void;
  addBigPendingRequest: () => void;
  removeBigPendingRequest: () => void;
  addSmallPendingRequests: (reqsToAdd?: number) => void;
  removeSmallPendingRequests: (reqsToRemove?: number) => void;
};

export type UseLoadingHook = UseBoundStore<StoreApi<LoadingState>>;

export const useLoading: UseLoadingHook = create<LoadingState>((set, get) => ({
  nbBigRequests: 0,
  nbSmallRequests: 0,
  isBlockingLoading: false,
  isLoading: false,
  description: null,
  setDescription: (description: string | null | ReactElement) => {
    set(() => ({
      description,
    }));
  },
  addBigPendingRequest: () => {
    set((state) => ({
      nbBigRequests: state.nbBigRequests + 1,
      isBlockingLoading: true,
    }));
  },
  removeBigPendingRequest: () => {
    const nbBigRequests = get().nbBigRequests;
    if (nbBigRequests - 1 < 0) {
      Logger.error("We try to remove big a pending request but the counter is already at 0");
      return;
    }
    set((state) => ({
      nbBigRequests: state.nbBigRequests - 1,
      isBlockingLoading: state.nbBigRequests > 1,
    }));
  },
  addSmallPendingRequests: (reqsToAdd = 1) => {
    set((state) => ({
      isLoading: true,
      nbSmallRequests: state.nbSmallRequests + reqsToAdd,
    }));
  },
  removeSmallPendingRequests: (reqsToRemove = 1) => {
    const nbSmallRequests = get().nbSmallRequests;
    if (nbSmallRequests - reqsToRemove < 0) {
      Logger.error("We try to remove small pending requests but the counter is already at 0");
      return;
    }
    set((state) => ({
      isLoading: state.nbSmallRequests > reqsToRemove,
      nbSmallRequests: state.nbSmallRequests - reqsToRemove,
    }));
  },
}));
