import { TokenSymbolName } from "@cede/types";
import { TOKENS_NAMES_AND_SYMBOLS_CACHE_TIME } from "@cede/utils";
import { useDependencies } from "../../hooks/useDependencies";
import { useQuery } from "@tanstack/react-query";

export const useTokenNamesAndSymbols = () => {
  const { useBackgroundHandler } = useDependencies();
  const { backgroundHandler } = useBackgroundHandler();

  const {
    data: tokenNamesAndSymbols,
    error,
    isLoading,
    refetch,
  } = useQuery<TokenSymbolName[], Error>({
    queryKey: ["tokenNamesAndSymbol"],
    queryFn: backgroundHandler?.getTokenNamesAndSymbols,
    enabled: false,
    staleTime: TOKENS_NAMES_AND_SYMBOLS_CACHE_TIME,
    gcTime: TOKENS_NAMES_AND_SYMBOLS_CACHE_TIME,
  });

  return {
    getTokensNameAndSymbols: refetch,
    tokenNamesAndSymbols: tokenNamesAndSymbols?.length ? tokenNamesAndSymbols : [],
    isLoading,
    error,
  };
};
