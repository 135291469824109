import { Any, SendWidgetProps, WidgetType } from "@cede/types";
import type { DefaultDependenciesWithFeatures } from "../contexts";
import { useDependencies as useDependenciesSdk } from "@cedelabs/react-sdk/src";

export function useDependencies<
  T extends {
    // @TODO: Make this working with both store backgroundHandler and provider types, BackgroundHandlerType is private
    backgroundHandler: Any;
    widgetProps?: SendWidgetProps;
    widgetType?: WidgetType;
  },
>() {
  return useDependenciesSdk<T & DefaultDependenciesWithFeatures>();
}
