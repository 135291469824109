import { useMemo } from "react";
import { components } from "@cede/ds";
import { SendWidgetProps } from "@cede/types";
import { inferTheme } from "@cede/utils";
import { createTheme } from "@mui/material";

export const useCustomTheme = <T extends Record<string, unknown> & { widgetProps: SendWidgetProps }>(
  dependencies: T,
) => {
  const theme = useMemo(() => {
    const { theme } = dependencies.widgetProps;
    const inferredTheme = inferTheme(theme);
    return createTheme({
      ...inferredTheme,
      components: {
        ...components,
        MuiButton: {
          styleOverrides: theme?.buttonTextColor
            ? {
                root: {
                  "color": theme?.buttonTextColor,
                  "&.Mui-disabled": {
                    backgroundColor: theme?.deactivateButtonColor ?? undefined,
                  },
                },
              }
            : undefined,
        },
        MuiOutlinedInput: {
          ...components.MuiOutlinedInput,
          styleOverrides: {
            notchedOutline: {
              borderColor: theme?.inputBorderColor,
            },
            root: {
              "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                borderColor: theme?.inputBorderColor,
                color: theme?.primaryTextColor,
              },
              ".MuiOutlinedInput-input.Mui-disabled": {
                "color": theme?.primaryTextColor,
                "-webkit-text-fill-color": theme?.primaryTextColor,
              },
            },
          },
        },
      },
    });
  }, [dependencies.widgetProps]);
  return theme;
};
