/*
 * Copyright (C) CEDE Labs, SAS - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by CEDE Labs team <team@cedelabs.io>, September 2021
 */
import { useEffect } from "react";
import { FiatCurrency, JSONLikeObject, toObject } from "@cede/types";
import { DEFAULT_CURRENCY, DEFAULT_LANGUAGE } from "@cede/utils";
import { useDependencies } from "../../hooks";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export type SettingsState = {
  language: string;
  currency: FiatCurrency;
  hiddenBalance: number;
  extensionUrl: string;
  setLanguage: (language: string) => void;
  setCurrency: (currency: string) => void;
  setHiddenBalance: (hiddenBalance: number) => void;
  setExtensionUrl: (extensionUrl: string) => void;
};

export type UseSettingsHook = typeof useSettings;

export const useSettings = create(
  persist(
    (set) => ({
      language: DEFAULT_LANGUAGE,
      currency: { symbol: DEFAULT_CURRENCY },
      hiddenBalance: 0,
      isMetricsActive: true,
      extensionUrl: "",
      setLanguage: (language: string) => {
        set(() => ({
          language,
        }));
      },
      setCurrency: async (symbol: string) => {
        // @TODO: get rate from API when we will need it
        set(() => ({
          currency: { symbol },
        }));
      },
      setHiddenBalance: (hiddenBalance: number) => {
        set(() => ({
          hiddenBalance,
        }));
      },
      setExtensionUrl: (extensionUrl: string) => {
        set(() => ({
          extensionUrl,
        }));
      },
    }),
    {
      name: "settings",
      partialize: (state) => ({
        language: state.language,
        currency: state.currency,
        hiddenBalance: state.hiddenBalance,
      }),
      version: 1,
      migrate: (persistedState: unknown, version: number | undefined) => {
        if (!version || version === 0) {
          return {
            ...toObject(persistedState as JSONLikeObject),
            currency: { symbol: DEFAULT_CURRENCY },
          } as unknown as SettingsState;
        } else {
          return persistedState as SettingsState;
        }
      },
    },
  ),
);

export const useSetupSettings = () => {
  const {
    currency,
    language,
    hiddenBalance,
    extensionUrl,
    setLanguage,
    setCurrency,
    setHiddenBalance,
    setExtensionUrl,
  } = useSettings((state) => ({
    currency: state.currency,
    language: state.language,
    hiddenBalance: state.hiddenBalance,
    extensionUrl: state.extensionUrl,
    setLanguage: state.setLanguage,
    setCurrency: state.setCurrency,
    setHiddenBalance: state.setHiddenBalance,
    setExtensionUrl: state.setExtensionUrl,
  }));
  const { useViewEnvironment, useBackgroundHandler } = useDependencies();
  const { environment } = useViewEnvironment();
  const { backgroundHandler } = useBackgroundHandler();

  const getSettings = async () => {
    if (!backgroundHandler) return;

    const { currency, language, balanceBelow, extensionUrl } = await backgroundHandler.getSettings();
    setCurrency(currency.symbol);
    setLanguage(language);
    setHiddenBalance(balanceBelow);
    setExtensionUrl(extensionUrl);
  };

  useEffect(() => {
    if (!backgroundHandler || !environment) return;

    getSettings();
  }, [backgroundHandler, environment]);

  return {
    currency,
    language,
    hiddenBalance,
    extensionUrl,
  };
};
