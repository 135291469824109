import { CurrencyAmountFormatter, Icon } from "@cede/ds";
import { formatBalance, forgeTokenURL } from "@cede/utils";
import { TransactionStatusCardLiteProps } from "./types";
import { Box, Typography, useTheme } from "@mui/material";

export const TransactionStatusCardLite = ({
  amountValue,
  currency,
  content,
  cryptoCurrency,
  cryptoCurrencyTo,
  transactionType,
  assetTokenSymbol,
}: TransactionStatusCardLiteProps) => {
  const theme = useTheme();

  if (transactionType === "trade") {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          paddingY: `calc(${theme.custom.gutterMargin} - 2px)`,
          paddingX: theme.custom.smallGutterMargin,
          margin: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <Typography typography={"body2"}>From</Typography>
          {cryptoCurrency && (
            <Box display={"flex"} alignItems="center" gap={theme.custom.smallGutterMargin}>
              <Typography typography={"body1"}>{`${formatBalance(amountValue, {
                maxDigits: 5,
              })}`}</Typography>
              <Box display={"flex"} alignItems="center">
                <Icon iconUrl={forgeTokenURL(cryptoCurrency)} iconName={cryptoCurrency} />
                <Typography typography={"body1"} ml="3px">{`${cryptoCurrency.toUpperCase()}`}</Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box height="100%" display="flex" alignItems="flex-end">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" fill="white" fillOpacity="1" />
          </svg>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Typography typography={"body2"}>To</Typography>
            {cryptoCurrencyTo && (
              <Box display={"flex"} alignItems="center" gap={theme.custom.smallGutterMargin}>
                <Typography typography={"body1"}>{content}</Typography>
                <Box display={"flex"} alignItems="center">
                  <Icon iconUrl={forgeTokenURL(cryptoCurrencyTo)} iconName={"asset-icon"} />
                  <Typography typography={"body1"} ml="3px">
                    {cryptoCurrencyTo}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        backgroundColor: theme.palette.background.component,
        borderRadius: theme.custom.largeBoxRadius,
        width: "100%",
        paddingY: `calc(${theme.custom.gutterMargin} - 2px)`,
        margin: "auto",
        border: `1px solid ${theme.palette.cedeComponentHr}`,
      }}
    >
      {currency && (
        <CurrencyAmountFormatter
          amount={amountValue}
          colors={{
            integer: theme.palette.text.secondary,
            decimal: theme.palette.text.secondary,
          }}
          size={"small"}
          currency={currency}
        />
      )}
      {cryptoCurrency && (
        <Box display={"flex"} alignItems="center" gap={theme.custom.smallGutterMargin}>
          <Icon iconUrl={forgeTokenURL(cryptoCurrency)} iconName={cryptoCurrency} />
          <Typography typography={"body2"}>{`${formatBalance(
            amountValue,
          )} ${cryptoCurrency.toUpperCase()}`}</Typography>
        </Box>
      )}

      <Box display="flex" alignItems="center" marginTop="6px">
        <Icon iconUrl={forgeTokenURL(assetTokenSymbol)} iconName={"asset-icon"} />
        <Typography
          component="p"
          color={theme.palette.text.primary}
          ml="12px"
          fontSize="20px"
          fontWeight="600"
          lineHeight="32px"
        >
          {content}
        </Typography>
      </Box>
    </Box>
  );
};
