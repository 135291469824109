// ----------------------------------------------------------------------------

// PLEASE DO NOT EDIT THIS FILE, IT IS GENERATED AND WILL BE OVERWRITTEN:
// https://github.com/ccxt/ccxt/blob/master/CONTRIBUTING.md#how-to-contribute-code
// EDIT THE CORRESPONDENT .ts FILE INSTEAD

import { SdkErrorCodes, SdkErrorMessages } from "./errorsMetadata.js";
import { CedeSDKError } from "./default.js";
/**
 * @description Default error for deposit
 */
export class DepositError extends CedeSDKError {
    constructor(code = SdkErrorCodes.DEPOSIT_ERROR, message = SdkErrorMessages.DEPOSIT_ERROR) {
        super(code, message);
        this.name = "DepositError";
    }
}
/**
 * @description Thrown when the deposit address is not found from the exchange api
 */
export class GetDepositAddressError extends DepositError {
    constructor(tokenSymbol, network) {
        super(SdkErrorCodes.GET_DEPOSIT_ADDRESS, SdkErrorMessages.GET_DEPOSIT_ADDRESS(tokenSymbol, network));
        this.name = "GetDepositAddressError";
    }
}
export class CreateDepositAddressError extends DepositError {
    constructor(tokenSymbol, network) {
        super(SdkErrorCodes.CREATE_DEPOSIT_ADDRESS, SdkErrorMessages.CREATE_DEPOSIT_ADDRESS(tokenSymbol, network));
        this.name = "CreateDepositAddressError";
    }
}
