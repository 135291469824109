import { Button } from "@cede/ds";
import { ArrowBack } from "@mui/icons-material";
import { Typography, Box, useTheme } from "@mui/material";

export const BackButton = ({ onClick = () => null }: { onClick?: () => void }) => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Button onClick={onClick} disableRipple>
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
          <Box mr={1} sx={{ display: "inline-flex" }}>
            <ArrowBack sx={{ color: theme.palette.text.secondary }} fontSize="small" />
          </Box>
          <Box>
            <Typography sx={{ color: theme.palette.text.secondary, mb: 0 }} component="p">
              Back
            </Typography>
          </Box>
        </Box>
      </Button>
    </Box>
  );
};
