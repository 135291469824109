import { MouseEventHandler, useRef, useState } from "react";
import { ComponentUnderlay } from "../ComponentUnderlay";
import { Icon } from "../Icon";
import { Bubble } from "../Popups";
import {
  IconGroupButtonLabelProps,
  IconGroupExtraLabelProps,
  IconGroupProps,
  IconGroupsHideButtonVariantProps,
} from "./types";
import { KeyboardBackspace } from "@mui/icons-material";
import { Box, IconButton, Theme, Typography, useTheme } from "@mui/material";

const COMPACT_TRANSLATION_PERCENT = 15;

export const getCompactWidth = (size: IconGroupProps["size"] = "small", theme: Theme, maxIcons: number) => {
  const iconSize = Number(theme.custom.iconSize[size].replace(/\D/g, "")) + theme.custom.iconUnderlayMargin;
  const width = iconSize * (1 - COMPACT_TRANSLATION_PERCENT / 100) * maxIcons + iconSize;
  return `${width}px`;
};

const DefautExtraLabel = ({
  id,
  icons,
  maxIcons,
  size,
  filteredIcons,
  compactStyle,
  withBubble = false,
  itemTranslation,
}: IconGroupExtraLabelProps) => {
  const theme = useTheme();
  const [bubbleIsOpen, setBubbleIsOpen] = useState(false);
  const anchorRef = useRef<HTMLElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const hiddenIcons = icons.slice(maxIcons);

  const onMouseIn = () => {
    withBubble && setBubbleIsOpen(true);
  };

  const onMouseOut: MouseEventHandler = () => {
    setBubbleIsOpen(false);
  };

  return compactStyle ? (
    <>
      <Box
        onMouseEnter={onMouseIn}
        onMouseLeave={onMouseOut}
        ref={anchorRef}
        sx={{
          transform: `translateX(-${filteredIcons.length * itemTranslation}%)`,
        }}
        data-testid={(id || "") + "-extra-label"}
      >
        <ComponentUnderlay size={size}>
          <Typography fontSize={(theme) => theme.custom.textSize[size]}>+{icons.length - maxIcons}</Typography>
        </ComponentUnderlay>
      </Box>
      <Bubble
        testId="bubble-popup"
        background={theme.palette.background.paper}
        ref={popperRef}
        isOpen={bubbleIsOpen}
        placement="top"
        anchor={anchorRef.current}
        sx={{
          zIndex: theme.custom.zIndex.topLayer,
        }}
      >
        <IconGroup icons={hiddenIcons} maxIcons={hiddenIcons.length} />
      </Bubble>
    </>
  ) : null;
};

export const IconGroup = ({
  icons,
  size = "small",
  maxIcons,
  id,
  forceCollapse = false,
  renderLabelElement = DefautExtraLabel,
  withBubble,
  iconSx = {},
}: IconGroupProps) => {
  const compactStyle = icons.length > maxIcons;
  const filteredIcons = icons.slice(0, maxIcons);
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      data-testid={id}
      sx={{ transition: "0.3s" }}
      style={
        compactStyle || forceCollapse
          ? {
              width: getCompactWidth(size, theme, forceCollapse && !compactStyle ? maxIcons - 1 : maxIcons),
            }
          : {}
      }
    >
      {filteredIcons.map((icon, index) => (
        <Box
          sx={{
            transition: "0.3s",
            transform: forceCollapse ? `translateX(-${index * COMPACT_TRANSLATION_PERCENT}%)` : "translateX(0%)",
            ...iconSx,
          }}
          data-testid={(id || "") + "-item"}
          key={index}
          onClick={icon.onClick && icon.onClick}
        >
          <ComponentUnderlay size={size}>
            <Icon iconName={icon.name} iconUrl={icon.src} key={index} disabled={icon.disabled} iconSize={size} />
          </ComponentUnderlay>
        </Box>
      ))}
      {renderLabelElement({
        filteredIcons,
        icons,
        maxIcons,
        size,
        compactStyle,
        forceCollapse,
        id,
        withBubble,
        itemTranslation: forceCollapse ? COMPACT_TRANSLATION_PERCENT : 0,
      })}
    </Box>
  );
};

const ButtonExtraLabel = ({ id, size, filteredIcons, onClick, isSelected }: IconGroupButtonLabelProps) => (
  <Box
    sx={{
      transform: `translateX(-${filteredIcons.length * COMPACT_TRANSLATION_PERCENT}%)`,
    }}
    data-testid={(id || "") + "-extra-label"}
  >
    <ComponentUnderlay size={size}>
      <IconButton
        data-testid={`${id || ""}-icon-button`}
        onClick={onClick}
        sx={{ width: "100%", height: "100%", padding: "0" }}
      >
        <KeyboardBackspace
          sx={{
            width: "60%",
            transform: !isSelected ? "rotate(180deg)" : "rotate(0)",
            transition: "0.2s",
          }}
        />
      </IconButton>
    </ComponentUnderlay>
  </Box>
);

IconGroup.HideButton = ({ onClick, isSelected, ...props }: IconGroupsHideButtonVariantProps) => {
  return (
    <IconGroup
      {...props}
      forceCollapse={true}
      iconSx={{ opacity: isSelected ? "0" : "1" }}
      renderLabelElement={({ ...props }) => <ButtonExtraLabel {...props} onClick={onClick} isSelected={isSelected} />}
    />
  );
};
