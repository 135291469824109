import { ComponentUnderlay, Icon } from "@cede/ds";
import { isCefiInfoTx } from "@cede/types";
import { CEDE_CEX_LABELS, getExchangeLogo } from "@cede/utils";
import { useDependencies } from "../../hooks";
import { Box, Typography, useTheme } from "@mui/material";

export const AccountLine = () => {
  const theme = useTheme();
  const { useSelectedTransaction } = useDependencies();
  const { transaction, isReceive } = useSelectedTransaction(({ transaction, isReceive }) => ({
    transaction,
    isReceive,
  }));

  if (!transaction) return null;

  const infoTx =
    isCefiInfoTx(transaction.to) && isReceive
      ? transaction.to
      : isCefiInfoTx(transaction.from)
      ? transaction.from
      : null;

  if (!infoTx) return null;

  return (
    <>
      <Typography variant={"body2"}>Account</Typography>
      <Box justifySelf={"right"} display={"inline-flex"} alignItems={"center"} gap={theme.custom.gutterMargin}>
        <ComponentUnderlay size={"small"}>
          <Icon
            iconUrl={getExchangeLogo(infoTx.account.exchangeId)}
            iconName={infoTx.account.exchangeId}
            iconSize={"small"}
          />
        </ComponentUnderlay>
        <Typography variant={"body1"}>{CEDE_CEX_LABELS[infoTx.account.exchangeId]}</Typography>
      </Box>
    </>
  );
};
