import { themeConfig } from "@cede/utils";
import { create } from "zustand";

export type UseLayoutState = {
  tabContainerWidth: string;
  setTabContainerWidth: (tabContainerSize: string) => void;
  tabContainerHeight: string;
  setTabContainerHeight: (tabContainerSize: string) => void;
  resetTabContainerSize: () => Promise<void>;
};

export type UseLayoutHook = () => UseLayoutState;

export const useLayout = create<UseLayoutState>((set) => ({
  tabContainerWidth: themeConfig.custom.storeSize.tabWidth,
  tabContainerHeight: themeConfig.custom.storeSize.tabHeight,
  setTabContainerWidth: (tabContainerWidth) => set({ tabContainerWidth }),
  setTabContainerHeight: (tabContainerHeight) => set({ tabContainerHeight }),
  resetTabContainerSize: async () => {
    set({
      tabContainerWidth: themeConfig.custom.storeSize.tabWidth,
      tabContainerHeight: themeConfig.custom.storeSize.tabHeight,
    });
    await new Promise((resolve) => setTimeout(resolve, 300));
  },
}));
