import { isOnrampMoney } from "../utils";
import { DefaultFooter } from "./DefaultFooter";
import { OnrampMoneyCustomFooter } from "./OnrampMoneyCustomFooter";

export const getFooter = (clientTxId: string | undefined) => {
  if (isOnrampMoney && clientTxId) {
    return <OnrampMoneyCustomFooter />;
  }
  return <DefaultFooter />;
};
