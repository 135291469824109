import { Dispatch, FormEventHandler, MutableRefObject } from "react";
import { DropListWithIconItem, ValidateFunction, ValidateFunctionReturnValue, ValidationTextFieldRef } from "@cede/ds";
import {
  Any,
  ApiPermissions,
  CurrencyDropListWithIconItem,
  FiatCurrency,
  MarketNetwork,
  SendWidgetConfig,
  SupportedTokensDropListWithIconItem,
  TradePathData,
} from "@cede/types";
import { DropListWhitelistedAddress } from "../../hooks";
import { QueryObserverResult } from "@tanstack/react-query";

export enum AddressInputType {
  WHITELIST = "whitelist",
  ADDRESS_BOOK = "addressBook",
}

export type PresentationalSendProps = {
  controller: UseSendState<SendForm>;
  disableAddressBook?: boolean;
};

export type WithdrawMinAmountProps = {
  controller: UseSendState<SendForm>;
};

export type SendFormAccount = DropListWithIconItem<{
  amount: string;
  cexValue: string;
  permissions: ApiPermissions[];
  isOauth: boolean;
}>;

export type ShouldPrefillObject = {
  tokenSymbol: boolean;
  network: boolean;
  address: boolean;
  amount: boolean;
  account: boolean;
};

export type SendForm = {
  shouldPrefill?: ShouldPrefillObject;
  fromAccount: SendFormAccount | null;
  initialProps: SendWidgetConfig;
  currency: CurrencyDropListWithIconItem | null;
  assetValue: string;
  whitelistedAddress: DropListWhitelistedAddress | undefined;
  refValue: string;
  network: DropListWithIconItem<{
    withdrawMin?: string;
    withdrawFee?: string;
    [key: string]: Any;
  }> | null;
  destinationCurrency: SupportedTokensDropListWithIconItem | null;
  tagValue: string;
  recipientAddress: string | null; // Equivalent to Address Key for Kraken
  toAccount: SendFormAccount | null;
  tutorialIsOpen: boolean;
  isDemo: boolean;
};

export type AmountInputRefValue = { validate: () => void };

export type CheckWhitelistFunction = () => Promise<QueryObserverResult<boolean, unknown>>;

export type UseSendState<T = SendForm> = {
  form: T;
  dispatchInput: Dispatch<FormAction<T>>;
  validateAddress: ValidateFunction<string>;
  droplistAddressBook: DropListWithIconItem[];
  droplistWhitelistedAddresses: DropListWhitelistedAddress[];
  isValid: boolean;
  setMaxAssetValue: VoidFunction;
  validateAssetValue: ValidateFunction<string | number>;
  submit: FormEventHandler<HTMLFormElement>;
  fromAccounts: DropListWithIconItem<{
    amount: string;
    permissions: ApiPermissions[];
    cexValue: string;
    isOauth: boolean;
    isLoading?: boolean;
  }>[];
  toAccounts: DropListWithIconItem<{ amount: string }>[];
  currencies: CurrencyDropListWithIconItem[];
  droplistDestinationTokens: SupportedTokensDropListWithIconItem[];
  droplistDestinationTokensAreLoading: boolean;
  droplistDestinationTokensError: Error | null;
  minAmountIsLoading: boolean;
  networks: DropListWithIconItem<Partial<MarketNetwork>>[];
  allNetworks: DropListWithIconItem<Partial<MarketNetwork>>[];
  switchSelectedCexs: VoidFunction;
  warningMessage: string;
  networksAreLoading: boolean;
  fiatCurrency: FiatCurrency;
  currenciesAreLoading: boolean;
  areAccountsLoading?: boolean;
  isOauth?: boolean;
  addressInputType: AddressInputType;
  isWhitelistedAddressesLoading: boolean;
  needWhitelist: boolean;
  displayWhitelistTooltip: boolean;
  tutorialIsOpen: boolean;
  recipientAddress: string;
  setIsRecipientInputFocused: (isFocused: boolean) => void;
  setTutorialIsOpen: (isOpen: boolean) => void;
  handleAddAddressManually: () => void;
  addCexRedirect?: VoidFunction;
  isAddressWhitelisted?: boolean;
  isDefiTab: boolean;
  isBetweenCexTab: boolean;
  checkWhitelist: CheckWhitelistFunction;
  disabledCexText: (
    v: DropListWithIconItem<{ cexValue?: string; permissions?: ApiPermissions[] }>,
  ) => string | JSX.Element;
  shouldDisableCex: (v: DropListWithIconItem<{ cexValue?: string; permissions?: ApiPermissions[] }>) => boolean;
  validateAssetValueRef: MutableRefObject<ValidationTextFieldRef | undefined>;
  shouldUseTradeAndSend: boolean;
  isTradeSendConfirmModalOpened: boolean;
  setTradeSendConfirmModalOpened: (isOpen: boolean) => void;
  onTradeSendModalContinue: () => void;
  isTutorialCheckboxChecked: boolean;
  setIsTutorialCheckboxChecked: (isChecked: boolean) => void;
  minAmount: string | undefined;
  tradePath: TradePathData[] | null | undefined;
  isDemo: boolean;
  refValue: string;
};

export type FilteredAccounts = {
  fromAccounts: UseSendState["fromAccounts"];
  toAccounts: UseSendState["fromAccounts"];
};

export type FormAction<T> = {
  field: keyof T | "all" | "switch-accounts";
  value?: T[keyof T] | T;
};

export type ValidateAssetFunction = () => ValidateFunctionReturnValue;
