import { WIDGET_ROUTES } from "@cede/utils";
import { DefaultPage } from "../components/DefaultPage";
import { Login } from "../components/Login";
import { Send } from "../components/Send";
import { authenticationMiddleware } from "./authenticationMiddleware";
import { chromiumMiddleware } from "./chromiumMiddleware";
import { initMiddleware } from "./initMiddleware";
import {
  AppRoute,
  CompletedTransaction,
  CompletedTransactionDetails,
  FixInvalidPermissionsTutorial,
  MultipleTransactionsForSend,
} from "@cedelabs/react-utils";

export const SEND_ROUTES: AppRoute[] = [
  {
    wrapper: chromiumMiddleware,
    routes: [
      {
        wrapper: initMiddleware,
        routes: [
          {
            path: WIDGET_ROUTES.DEFAULT_ROUTE,
            element: DefaultPage,
          },
          { path: WIDGET_ROUTES.LOGIN, element: Login },
          {
            wrapper: authenticationMiddleware,
            routes: [
              {
                path: WIDGET_ROUTES.ACTION_ROUTE,
                element: Send,
              },
              { path: WIDGET_ROUTES.COMPLETED_TRANSACTION_ROUTE, element: CompletedTransaction },
              { path: WIDGET_ROUTES.COMPLETED_TRANSACTION_DETAILS_ROUTE, element: CompletedTransactionDetails },
              { path: WIDGET_ROUTES.FIX_INVALID_PERMISSIONS_TUTORIAL, element: FixInvalidPermissionsTutorial },
              { path: WIDGET_ROUTES.MULTIPLE_TRANSACTIONS, element: MultipleTransactionsForSend },
            ],
          },
        ],
      },
    ],
  },
];
