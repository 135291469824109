import { useEffect } from "react";
import {
  TradeForm,
  TradePathData,
  ViewEnvironment,
  WidgetEventMessage,
  WidgetEventUpdateExchangePayload,
  WidgetEventUpdatePairPayload,
  WidgetWindowEvents,
} from "@cede/types";
import { useDependencies } from "../../../hooks";

type TradeDispatcherParams = {
  enabled: boolean;
  tradeForm: TradeForm;
  tradePath: TradePathData[] | null | undefined;
};

/**
 * Dispatch events through the window object (only available for Widgets)
 */
export const useTradeEventDispatcher = ({ enabled, tradeForm, tradePath }: TradeDispatcherParams) => {
  const { useViewEnvironment } = useDependencies();
  const { environment } = useViewEnvironment();

  const onChangeSelectedAccount = () => {
    const message: WidgetEventMessage<WidgetEventUpdateExchangePayload> = {
      type: WidgetWindowEvents.UPDATE_EXCHANGE,
      payload: tradeForm.selectedAccount
        ? { exchangeId: tradeForm.selectedAccount?.cexValue, accountId: tradeForm.selectedAccount?.value }
        : null,
    };
    window.postMessage(message);
  };

  const onChangePairSymbol = async () => {
    let pairSymbol = "";
    let precision = 0;
    if (tradeForm.market) {
      pairSymbol = tradeForm.market.pairSymbol;
      precision = tradeForm.market.precision?.price ?? 0;
    } else if (tradePath?.length) {
      const lastTradePath = tradePath[tradePath.length - 1];
      if (!lastTradePath) return;
      // we want to use the precision of the destination token
      // eg. BTC/USDT: price precision is 0.01 but if the destination token is BTC,
      // we want to use the BTC (base amount) precision which is 0.00000001
      precision = lastTradePath.orderSide === "sell" ? lastTradePath.precision.price : lastTradePath.precision.amount;
      pairSymbol = `${tradeForm.tokenFrom?.value}/${tradeForm.tokenTo?.value}`;
    }

    const message: WidgetEventMessage<WidgetEventUpdatePairPayload> = {
      type: WidgetWindowEvents.UPDATE_PAIR,
      payload: pairSymbol ? { pairSymbol, precision } : null,
    };
    window.postMessage(message);
  };

  useEffect(() => {
    if (environment !== ViewEnvironment.WIDGET || !enabled) return;
    onChangeSelectedAccount();
  }, [tradeForm.selectedAccount, enabled]);

  useEffect(() => {
    if (environment !== ViewEnvironment.WIDGET || !enabled) return;
    onChangePairSymbol();
  }, [tradeForm.market, tradePath, enabled]);
};
