// values mapped to CCXT exchanges' ids
export const CEDE_CEXS = {
  BINANCE: "binance",
  COINBASE: "coinbase",
  KRAKEN: "kraken",
  GATEIO: "gateio",
  OKX: "okx",
  BITFINEX: "bitfinex",
  HTX: "huobi",
  KUCOIN: "kucoin",
  BYBIT: "bybit",
  BITGET: "bitget",
  BITSTAMP: "bitstamp",
  BITMART: "bitmart",
  MEXC: "mexc",
};

export const getCCXTExchangeId = (internalCexId: string) => {
  switch (internalCexId) {
    case CEDE_CEXS.BITFINEX: {
      return "bitfinex2";
    }
    case CEDE_CEXS.HTX: {
      return "htx";
    }
    default: {
      return internalCexId;
    }
  }
};

export const getInternalExchangeId = (ccxtExchangeId: string) => {
  switch (ccxtExchangeId) {
    case "bitfinex2": {
      return CEDE_CEXS.BITFINEX;
    }
    case "htx": {
      return CEDE_CEXS.HTX;
    }
    default: {
      return ccxtExchangeId;
    }
  }
};

export const CEDE_CEX_LABELS = {
  [CEDE_CEXS.BINANCE]: "Binance",
  [CEDE_CEXS.COINBASE]: "Coinbase",
  [CEDE_CEXS.KRAKEN]: "Kraken",
  [CEDE_CEXS.GATEIO]: "Gate.io",
  [CEDE_CEXS.OKX]: "OKX",
  [CEDE_CEXS.BITFINEX]: "Bitfinex",
  [CEDE_CEXS.HTX]: "HTX",
  [CEDE_CEXS.KUCOIN]: "Kucoin",
  [CEDE_CEXS.BYBIT]: "Bybit",
  [CEDE_CEXS.BITGET]: "Bitget",
  [CEDE_CEXS.BITSTAMP]: "Bitstamp",
  [CEDE_CEXS.BITMART]: "Bitmart",
  [CEDE_CEXS.MEXC]: "MEXC",
};
