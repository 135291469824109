/* eslint-disable no-console */
import { Any } from "@cede/types";
import { LoggerMessageType, LoggerType } from "./logger.type";

export class Logger implements LoggerType {
  static singleton: Logger;

  static readonly types = {
    ERROR: { color: "red", type: "ERROR" },
    WARNING: { color: "orange", type: "WARNING" },
    INFO: { color: "#3399ff", type: "INFO" },
    SUCCESS: { color: "green", type: "SUCCESS" },
  };

  log(message: string, type: LoggerMessageType) {
    if (process.env.CEDE_NODE_ENV === "development") {
      console.log(`%c[${type.type}] ${message}`, `color: ${type.color}`);
    }
  }

  error(e: Any) {
    if (process.env.CEDE_NODE_ENV === "development" && e instanceof Error) {
      console.error(e);
    }
  }

  static getInstance() {
    if (!Logger.singleton) {
      Logger.singleton = new Logger();
    }
    return Logger.singleton;
  }

  static log(message: string, type: LoggerMessageType) {
    Logger.getInstance().log(message, type);
  }

  static error(e: Any) {
    Logger.getInstance().error(e);
  }
}
