import { isCefiInfoTx } from "@cede/types";
import { formatBalance } from "@cede/utils";
import { useDependencies } from "../../hooks";
import { Box, Typography, useTheme } from "@mui/material";

export const PriceLine = () => {
  const theme = useTheme();
  const { useSettings, useSelectedTransaction, usePrice } = useDependencies();
  const transaction = useSelectedTransaction((state) => state.transaction);
  const fiatCurrency = useSettings((state) => state.currency);

  if (!transaction || !isCefiInfoTx(transaction.from) || !transaction.from.transaction.amount) return null;
  const price = usePrice(transaction.from.transaction.tokenSymbol);
  return (
    <>
      <Typography variant={"body2"}>Price</Typography>
      <Box justifySelf={"right"} display={"inline-flex"} alignItems={"center"} gap={theme.custom.smallGutterMargin}>
        <Typography variant={"body1"} justifySelf={"right"}>
          {`${formatBalance(transaction.from.transaction.amount)} ${
            transaction.from.transaction.tokenSymbol
          } = ${formatBalance(transaction.from.transaction.amount * price, { roundForFiat: true })} ${
            fiatCurrency.symbol
          }`}
        </Typography>
      </Box>
    </>
  );
};
