import {
  Any,
  CedeEvents,
  GroupedBalances,
  HandlerType,
  MarketNetwork,
  PricesByTokenSymbol,
  ProviderHandler,
  TokenPrice,
} from "@cede/types";
import { API_ENDPOINT, getFromApi } from "../api-client";
import {
  fakeActiveVaultForDemo,
  fakeSettings,
  mockedNetworks,
  mockedNetworksByToken,
  mockedWithdrawableBalancesByAccountId,
  mockSupportedExchanges,
} from "../mocks";
import { CedeProvider } from "@cedelabs/providers/src";
import { Mutex } from "async-mutex";

const eventEmitter = new EventTarget();

const pricesMutex = new Mutex();

let cachedPrices: TokenPrice[] = [];

const mockedApi: Partial<ProviderHandler> & {
  getIsConnected: CedeProvider["getIsConnected"];
  getIsUnlocked: CedeProvider["getIsUnlocked"];
  getVaultPreviews: CedeProvider["getVaultPreviews"];
  getActiveVault: CedeProvider["getActiveVault"];
  on: CedeProvider["on"];
  once: CedeProvider["once"];
  emit: CedeProvider["emit"];
  off: CedeProvider["off"];
} = {
  isReady: true,
  isReal: false,
  getIsConnected: () => {
    return true;
  },
  getIsUnlocked: () => {
    return true;
  },
  emit: (event: string, ...args: Any[]) => {
    eventEmitter.dispatchEvent(new CustomEvent(event, { detail: args }));
    return true;
  },
  getVaultPreviews() {
    return [fakeActiveVaultForDemo];
  },
  getActiveVault() {
    return fakeActiveVaultForDemo;
  },
  on: (event: CedeEvents, callback: (...args: unknown[]) => void) => {
    eventEmitter.addEventListener(event, callback);

    return mockedApi as CedeProvider;
  },
  once: (event: CedeEvents, callback: (...args: unknown[]) => void) => {
    eventEmitter.addEventListener(event, callback, { once: true });
    return mockedApi as CedeProvider;
  },
  off: (event: CedeEvents, callback: VoidFunction) => {
    eventEmitter.removeEventListener(event, callback);
    return mockedApi as CedeProvider;
  },
  connect: async () => {
    eventEmitter.dispatchEvent(new Event("unlock"));
    return [fakeActiveVaultForDemo];
  },
  getSettings: async () => {
    return fakeSettings;
  },
  supportedExchanges: async () => {
    return mockSupportedExchanges;
  },
  vaults: async () => {
    return [fakeActiveVaultForDemo];
  },
  withdrawableTokens: async (params) => {
    const { accountId } = params;
    const balances = mockedWithdrawableBalancesByAccountId[accountId] as GroupedBalances;

    await mockedApi?.getPrices?.();

    return Object.entries(balances).reduce((acc, [token, balance]) => {
      const tokenPrice = cachedPrices.find((price) => price.tokenSymbol === token)?.average || 0;
      return {
        ...acc,
        [token]: {
          ...balance,
          refTotalBalance: balance.totalBalance * tokenPrice,
          refFreeBalance: balance.freeBalance * tokenPrice,
        },
      };
    }, {} as GroupedBalances);
  },
  getNetworks: async ({ tokenSymbol }) => {
    if (tokenSymbol && mockedNetworksByToken[tokenSymbol]) {
      return mockedNetworksByToken[tokenSymbol] as MarketNetwork[];
    }
    return mockedNetworks;
  },
  getPrices: async () => {
    const release = await pricesMutex.acquire();
    if (!cachedPrices.length) {
      const { data } = await getFromApi(API_ENDPOINT.PRICES, undefined).catch(() => ({
        data: {
          tokens: [],
        },
      }));
      cachedPrices = data ? data.tokens : [];
    }
    release();
    return cachedPrices.reduce((acc, { tokenSymbol, average }) => {
      acc[tokenSymbol] = average;
      return acc;
    }, {} as PricesByTokenSymbol);
  },
};

export function mockedHandlerFactory() {
  return new Proxy(eventEmitter, {
    get: (object: Any, property: string) => {
      if (property === "type") return HandlerType.UNTRUSTED_PROVIDER_API;
      if (property === "isReady") return true;
      if (property === "isReal") return false;

      if (object[property]) return object[property];
      return async (...parameters: [Any, Any]) => {
        const method = mockedApi[property as keyof typeof mockedApi];
        if (!method || typeof method !== "function") {
          throw new Error(`Method ${property} not found`);
        }
        const response = method(...parameters);
        return response;
      };
    },
  }) as ProviderHandler & CedeProvider;
}
