import { create } from "zustand";

type TabItem = {
  value: string;
  label: string;
  disabled?: boolean;
};

type UseTabsFactoryState = {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
};

export const useTabsFactory = (tabs: TabItem[]) =>
  create<UseTabsFactoryState>((set) => ({
    selectedTab: tabs[0]?.value || "",
    setSelectedTab: (tab) => {
      if (tabs.some((item) => item.value === tab)) {
        set({ selectedTab: tab });
      }
    },
  }));
