import { useEffect } from "react";
import { readAssetFromExtension } from "@cede/utils";
import { useDependencies } from ".";
import { useQuery } from "@tanstack/react-query";

type UseExtensionAssetProps = {
  path?: string;
  disable?: boolean;
  onAssetNotFound?: () => void;
};

export const useExtensionAsset = ({ path, onAssetNotFound, disable = false }: UseExtensionAssetProps) => {
  const { useSettings } = useDependencies();
  const { extensionUrl } = useSettings();

  const {
    data: asset,
    isLoading,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: [`extensionAsset`, path, extensionUrl],
    queryFn: () => (path ? readAssetFromExtension(path, extensionUrl) : undefined),
    enabled: !!path && !disable,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  useEffect(() => {
    if (isLoading) return;

    if (isError || !isSuccess) {
      onAssetNotFound?.();
    }
  }, [asset, isLoading, isError, isSuccess]);

  return {
    asset,
    fullPath: `${extensionUrl}${path}`,
    isAssetLoading: isLoading,
    isAssetError: isError,
    isAssetSuccess: isSuccess,
  };
};
