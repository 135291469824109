import { useState, useRef } from "react";
import { CopyIconButtonProps } from "./types";
import { Box, Fade, IconButton, Popper, useTheme } from "@mui/material";

export const CopyIconButton = ({ testId = "", opacity = 1, size, text }: CopyIconButtonProps) => {
  const theme = useTheme();
  const [popupOpened, setPopupOpened] = useState(false);

  const ref = useRef();

  function onCopy() {
    navigator.clipboard.writeText(text).catch((err) => {
      // eslint-disable-next-line no-console
      console.error("Failed to copy: ", err);
    });
  }

  let width;
  let height;
  if (size === "large") {
    width = 20;
    height = 20 * 1.2;
  } else if (size === "medium") {
    width = 15;
    height = 15 * 1.2;
  } else {
    width = 10;
    height = 12;
  }

  return (
    <>
      <Box ref={ref}>
        <IconButton
          data-testid={testId}
          onClick={() => {
            if (popupOpened) return;
            onCopy();
            setPopupOpened(true);
            setTimeout(() => {
              setPopupOpened(false);
            }, 1000);
          }}
          sx={{
            color: theme.palette.text.primary,
          }}
        >
          <svg width={width} height={height} viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.21051 0.666687H1.31577C0.775418 0.666687 0.333313 1.10305 0.333313 1.63638V8.42426H1.31577V1.63638H7.21051V0.666687ZM8.68419 2.60608H3.28068C2.74033 2.60608 2.29823 3.04244 2.29823 3.57578V10.3637C2.29823 10.897 2.74033 11.3334 3.28068 11.3334H8.68419C9.22454 11.3334 9.66665 10.897 9.66665 10.3637V3.57578C9.66665 3.04244 9.22454 2.60608 8.68419 2.60608ZM8.68419 10.3637H3.28068V3.57578H8.68419V10.3637Z"
              fill="currentColor"
              fillOpacity={opacity}
            />
          </svg>
        </IconButton>
      </Box>
      <Popper
        open={popupOpened}
        anchorEl={ref.current}
        data-testid={`${testId}Popup`}
        transition
        sx={{
          padding: theme.custom.smallGutterMargin,
          zIndex: theme.custom.zIndex.topLayer + 1,
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              sx={{
                display: "flex",
                p: 1,
                color: "text.primary",
                borderRadius: theme.custom.defaultBoxRadius,
                background: theme.palette.background.paper,
                boxShadow:
                  "0px 5px 5px -3px rgba(0, 0, 0, 0.8), 0px 8px 10px 1px rgba(0, 0, 0, 0.8), 0px 3px 14px 2px rgba(0, 0, 0, 0.8)",
              }}
            >
              Copied!
            </Box>
          </Fade>
        )}
      </Popper>
    </>
  );
};
