import { Navigate, Outlet } from "react-router-dom";
import { WIDGET_ROUTES } from "@cede/utils";
import { useDependencies } from "../hooks/useDependencies";

export const authenticationMiddleware = () => {
  const { backgroundHandler, useWidgetLogin } = useDependencies();
  const { isLoggedIn } = useWidgetLogin();
  if (backgroundHandler?.isReady && !isLoggedIn) {
    return <Navigate to={WIDGET_ROUTES.LOGIN} replace />;
  }

  return <Outlet />;
};
