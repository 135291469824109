import { GroupedBalances } from "@cede/types";
import { fakeActiveVaultForDemo } from "./vaults";

export const mockedWithdrawableBalances = {
  USDT: {
    freeBalance: 5780,
    totalBalance: 5780,
    refFreeBalance: 5777.780479999999,
    refTotalBalance: 5777.780479999999,
  },
  LINK: {
    freeBalance: 569,
    totalBalance: 569,
    refFreeBalance: 4384.30611804,
    refTotalBalance: 4384.30611804,
  },
  ETH: {
    freeBalance: 5.305,
    totalBalance: 5.305,
    refFreeBalance: 8694.85261305,
    refTotalBalance: 8694.85261305,
  },
  DOT: {
    freeBalance: 533.5,
    totalBalance: 533.5,
    refFreeBalance: 2167.9150218,
    refTotalBalance: 2167.9150218,
  },
};

const generateGroupedBalances = (tokens: string[], balances: number[]): GroupedBalances => {
  return tokens.reduce((acc, token, index) => {
    acc[token] = {
      freeBalance: balances[index] || 0,
      totalBalance: balances[index] || 0,
      refFreeBalance: 0,
      refTotalBalance: 0,
    };
    return acc;
  }, {} as GroupedBalances);
};

export const mockedWithdrawableBalancesByAccountId: Record<string, GroupedBalances> =
  fakeActiveVaultForDemo.accounts.reduce((acc, account) => {
    return {
      ...acc,
      [account.id]: generateGroupedBalances(["USDT", "MATIC", "ETH", "WBTC"], [0, 0, 0, 0]),
    };
  }, {});
