import { Any } from "@cede/types";

export function isChildOf(child: Any, parent: Any) {
  let childRef = child;

  while (childRef && childRef.nodeName !== "BODY" && !childRef.isEqualNode(parent)) {
    childRef = childRef.parentNode;
  }

  if (!childRef) return false;

  return childRef.isEqualNode(parent) as boolean;
}

export function onElementAvailable(selector: string, callback: () => void, fallback: string) {
  const observer = new MutationObserver(() => {
    // For some exchanges, we always see the register element even if we are logged in a fraction of second before everything builds up
    const timeout = setTimeout(() => {
      if (document.querySelector(fallback)) {
        observer.disconnect();
        callback();
      }
    }, 2000);

    if (!document.querySelector(selector) && document.querySelector(fallback)) {
      clearTimeout(timeout);
      observer.disconnect();
      callback();
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });
}
