import chromeIcon from "../../assets/chrome.svg";
import { Button, StoreLogo } from "@cede/ds";
import { isMobile } from "@cede/utils";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Typography, useTheme } from "@mui/material";

export const NotSupported = () => {
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={{
        backgroundColor: theme.palette.background.default,
        height: "100%",
      }}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"stretch"}
      justifyContent={"space-between"}
      paddingY={theme.custom.smallGutterMargin}
    >
      <Box
        component="div"
        sx={{
          marginTop: "110px",
          textAlign: "center",
        }}
      >
        <StoreLogo size="large" />
        <Typography component="h1" variant="h1" fontSize="20px">
          <Typography
            component={"span"}
            variant="h1"
            fontSize="20px"
            sx={{ display: "inline", color: theme.palette.primary.main }}
          >
            {"Cede.store "}
          </Typography>
          send portal is not available on {isMobile() ? "mobile" : "your current browser"}.
        </Typography>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={theme.custom.smallGutterMargin}
          paddingTop={theme.custom.gutterMargin}
        >
          <Typography component="h2" variant="body2">
            Retry on a {isMobile() ? "desktop" : ""} chromium browser
          </Typography>
          <Box component={"img"} src={chromeIcon} width={20} alt="Google Chrome Icon" />
        </Box>

        <Box
          component="a"
          href={"https://en.wikipedia.org/wiki/Chromium_(web_browser)#Active"}
          target={"_blank"}
          rel="noopener noreferrer"
          data-testid="anchor"
          sx={{
            color: theme.palette.text.secondary,
            marginTop: theme.custom.gutterMargin,
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          List of compatible browser
          <OpenInNewIcon
            sx={{
              height: "16px",
              width: "16px",
              ml: "5px",
            }}
          />
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          gap: theme.custom.gutterMargin,
          paddingTop: theme.custom.bigGutterMargin,
          marginTop: "40px",
        }}
      >
        <Button onClick={() => (window.location.href = "/")} variant={"contained"} color={"primary"} height={"tall"}>
          Return to the menu
        </Button>
      </Box>
    </Box>
  );
};
