/*
 * Copyright (C) CEDE Labs, SAS - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by CEDE Labs team <team@cedelabs.io>, September 2021
 */
import Loader from "react-loader-spinner";

type Props = {
  width?: number;
  height?: number;
};

export const SpinnerLoader = ({ width = 100, height = 100 }: Props) => (
  <Loader
    type="Rings"
    color="#11A49A"
    height={height}
    width={width}
    timeout={60000} //1 min
  />
);
