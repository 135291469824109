import { useEffect, useState } from "react";

export type AddressInputValueProps = {
  address?: string;
  isFocused?: boolean;
};

const lengthToShow = 4;
// We start splitting the address after 13 chars
const minLength = lengthToShow * 2 + 7;

export const useAddressInputValue = ({ address, isFocused }: AddressInputValueProps = {}) => {
  const [value, setValue] = useState("");

  const splitAddress = (address?: string) => {
    if (address && address.length > minLength) {
      const prefix = address.slice(0, lengthToShow + 2);
      const suffix = address.slice(-lengthToShow);

      return `${prefix}.....${suffix}`;
    }

    return address ?? "";
  };

  useEffect(() => {
    if (isFocused) {
      setValue(address ?? "");
    } else {
      setValue(splitAddress(address ?? ""));
    }
  }, [address, isFocused]);

  return { value, splitAddress };
};
