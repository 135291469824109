import { TextFieldWithIcon } from "../TextFieldWithIcon";
import { SearchBarProps } from "./types";
import { Search } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";

export const SearchBar = ({ value, onChange, onSubmit, placeholder = "Search", inputRef, sx }: SearchBarProps) => {
  const theme = useTheme();
  return (
    <Box sx={{ ...sx }}>
      <TextFieldWithIcon
        sx={{
          ".MuiOutlinedInput-root": {
            "borderRadius": theme.custom.buttonSmallRadius,
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "background": theme.palette.background?.paper,
          },
          ".MuiInputBase-input": {
            padding: theme.custom.smallGutterMargin,
          },
          ".MuiInputBase-root": {
            paddingLeft: theme.custom.smallGutterMargin,
          },
        }}
        inputRef={inputRef}
        placeholder={placeholder}
        value={value}
        handleChange={onChange}
        icon={
          <Box
            display={"flex"}
            onClick={onSubmit}
            sx={{
              "cursor": "pointer",
              ":hover .MuiSvgIcon-root": {
                opacity: 0.9,
              },
            }}
          >
            <Search sx={{ opacity: 0.56, transition: "0.3s", color: "text.primary" }} />
          </Box>
        }
        iconPosition="start"
      />
    </Box>
  );
};
