import { useMemo } from "react";
import { ApiPermissions, ViewEnvironment } from "@cede/types";
import { useDependencies } from "../../../hooks/useDependencies";
import { DroplistMissingPermission } from "../../DroplistMissingPermission/DroplistMissingPermission";
import { AddressInputType, FilteredAccounts, SendFormAccount, UseSendState } from "../types";
import { SendTabsValues, useSendTabs } from "./useSendTabs";

export const useSendApi = () => {
  const {
    useSendStore,
    useSupportedExchanges,
    useVaults,
    useWithdrawableBalances,
    useAddressBook,
    useAccounts,
    useNetworks,
    useWhitelistedAddresses,
    useViewEnvironment,
    useTriggerLoadingConditionally,
    useTradePath,
    useBackgroundHandler,
  } = useDependencies();
  const { selectedTab } = useSendTabs();
  const { activeVault } = useVaults();
  const form = useSendStore();
  const { supportedExchangesById, shouldDisableCex, disabledCexText, isSupportedExchangesLoading } =
    useSupportedExchanges();
  const { backgroundHandler } = useBackgroundHandler();
  const { environment } = useViewEnvironment();

  useTriggerLoadingConditionally({
    isLoading:
      !!backgroundHandler?.isReady && (isSupportedExchangesLoading || Object.keys(supportedExchangesById).length === 0),
  });

  const isSendSupported =
    !form.fromAccount?.cexValue ||
    supportedExchangesById[form.fromAccount.cexValue]?.supportedFeatures.includes("send");

  const isTradeAndSendSupported =
    form.fromAccount?.cexValue &&
    supportedExchangesById[form.fromAccount.cexValue]?.supportedFeatures.includes("trade_and_send") &&
    environment === ViewEnvironment.WIDGET;

  const {
    droplistDestinationTokens,
    isLoading: droplistDestinationTokensAreLoading,
    error: droplistDestinationTokensError,
    tradePath,
  } = useTradePath({
    enabled: !!isTradeAndSendSupported,
    accountId: form.fromAccount?.value ?? "",
    fromToken: form.currency?.value ?? "",
    network: form.network?.value ?? "",
    toToken: form.destinationCurrency?.value ?? "",
    activeWithdrawal: true,
  });

  const {
    droplistNetworks: tokenDroplistNetworks,
    networksAreLoading,
    networksByValue,
  } = useNetworks({
    toDeposit: false,
    toWithdraw: true,
    accountId: form.fromAccount?.value ?? "",
    tokenSymbol: form.currency?.value,
    isInternalTransfer: !!form.initialProps.isInternalTransfer,
  });

  const isNetworkDisabledForSelectedCurrency = useMemo(() => {
    return droplistDestinationTokens
      .find((token) => form.currency?.value === token.value)
      ?.networks.some((n) => n.network === form.network?.value && !n.withdrawalEnabled);
  }, [droplistDestinationTokens, form.currency, form.network]);

  const shouldUseTradeAndSend = Boolean(
    networksByValue &&
      form.network &&
      (!networksByValue[form.network?.network] || isNetworkDisabledForSelectedCurrency) &&
      form.currency &&
      droplistDestinationTokens &&
      isTradeAndSendSupported,
  );

  const { droplistNetworks: droplistAllNetworks } = useNetworks({
    toDeposit: false,
    toWithdraw: true,
    accountId: form.fromAccount?.value ?? "",
    isInternalTransfer: !!form.initialProps.isInternalTransfer,
  });

  const { totalBalancesByAccountId } = useWithdrawableBalances(
    !isTradeAndSendSupported ? form.network?.value : undefined,
  );

  const { droplistWhitelistedAddresses, isWhitelistedAddressesLoading, refetchWhitelistedAddresses } =
    useWhitelistedAddresses({
      accountId: form.fromAccount?.value ?? "",
      tokenSymbol: shouldUseTradeAndSend ? form.destinationCurrency?.value : form.currency?.value,
      network: form.network?.value,
    });
  const { droplistAddressBook } = useAddressBook();
  const { droplistAccounts, accountsByIds, areAccountsLoading } = useAccounts();

  const addressInputType = useMemo(() => {
    if (supportedExchangesById[form.fromAccount?.cexValue ?? ""]?.provideWhitelistedAddresses) {
      return AddressInputType.WHITELIST;
    }
    return AddressInputType.ADDRESS_BOOK;
  }, [form.fromAccount, supportedExchangesById]);

  // we must update the droplist networks after selecting the token to get fees and withdrawal limits
  const droplistNetworks = !isTradeAndSendSupported && form.currency ? tokenDroplistNetworks : droplistAllNetworks;

  const isReceiveSupported =
    !form.toAccount?.cexValue || supportedExchangesById[form.toAccount.cexValue]?.supportedFeatures.includes("receive");

  const isSendAllowed = form.fromAccount && form.fromAccount.permissions.includes(ApiPermissions.WITHDRAW);

  const accounts: UseSendState["fromAccounts"] = useMemo(() => {
    return droplistAccounts.map((account) => ({
      ...account,
      isLoading:
        (totalBalancesByAccountId.totalBalances[account.value]?.isLoading === undefined ??
          !!totalBalancesByAccountId.totalBalances[account.value]?.isLoading) &&
        !account.isDisabled,
      amount: totalBalancesByAccountId.totalBalances[account.value]?.total.toString() ?? "0",
      isOauth: !!accountsByIds[account.value]?.isOauth,
    }));
  }, [activeVault?.accounts, totalBalancesByAccountId, droplistAccounts]);

  const { fromAccounts, toAccounts } = useMemo(() => {
    const filteredAccounts: FilteredAccounts = { fromAccounts: [], toAccounts: [] };
    if (!supportedExchangesById) return filteredAccounts;

    const filterAccounts = (selectedAccount?: SendFormAccount | null) => {
      return accounts.filter(
        (account) =>
          supportedExchangesById[account.cexValue]?.supportedFeatures.includes("send") &&
          selectedAccount?.value !== account.value,
      );
    };

    if (form.toAccount) {
      filteredAccounts.fromAccounts = filterAccounts(form.toAccount);
    }

    filteredAccounts.fromAccounts = accounts.filter((account) =>
      supportedExchangesById[account.cexValue]?.supportedFeatures.includes("send"),
    );
    filteredAccounts.toAccounts = filterAccounts(form.fromAccount);

    return filteredAccounts;
  }, [supportedExchangesById, accounts, form.toAccount, form.fromAccount]);

  const isDefiTab = selectedTab === SendTabsValues.DefiAddress;
  const isBetweenCexTab = selectedTab === SendTabsValues.BetweenCex;

  return {
    isSendAllowed,
    isSendSupported,
    isReceiveSupported,
    droplistCurrencies: totalBalancesByAccountId.droplistCurrenciesByAccountId[form.fromAccount?.value ?? ""],
    areCurrenciesLoading: !totalBalancesByAccountId.totalBalances[form.fromAccount?.value ?? ""] ?? false,
    fromAccounts,
    toAccounts,
    droplistNetworks,
    droplistAllNetworks,
    networksAreLoading,
    droplistAddressBook,
    isWhitelistedAddressesLoading,
    refetchWhitelistedAddresses,
    droplistWhitelistedAddresses,
    droplistDestinationTokens,
    droplistDestinationTokensError,
    droplistDestinationTokensAreLoading,
    shouldUseTradeAndSend,
    areAccountsLoading: areAccountsLoading || isSupportedExchangesLoading,
    isDefiTab,
    isBetweenCexTab,
    shouldDisableCex: shouldDisableCex("send"),
    disabledCexText: disabledCexText("send", DroplistMissingPermission),
    addressInputType,
    tradePath,
  };
};
