import { useMemo } from "react";
import { Partner, Transaction, isCefiInfoTx, isDefiInfoTx } from "@cede/types";
import { NETWORK_TWITTER_HANDLE_CONFIG_IDENTIFIER, POST_ON_TWITTER_URL, replacePlaceholdersByValue } from "@cede/utils";
import { useDependencies } from ".";

/**
 * Hook to get the twitter handle of a partner and a network from a transaction
 * @param transaction contains the partnerId and the network
 * @returns partnerName, networkName
 */
export const useShareOnTwitter = (transaction: Transaction | null) => {
  const { usePartners, useStandardizedNetworks, useConfig } = useDependencies();

  const { getNetworkName } = useStandardizedNetworks();
  const { partners } = usePartners();
  const { config } = useConfig();

  // Returns the twitter handle if it exists in the config (growthbook) and the internal network name otherwise
  const networkName = useMemo(() => {
    if (!transaction) return undefined;
    if (transaction.type === "withdrawal" && isCefiInfoTx(transaction.from) && isDefiInfoTx(transaction.to)) {
      const internalNetwork = transaction.to.account.network;
      const key = `${NETWORK_TWITTER_HANDLE_CONFIG_IDENTIFIER}${internalNetwork}`;
      const twitterNetworkName = config?.[key];
      if (twitterNetworkName) return `@${twitterNetworkName}`;
      // If no twitter handle is found, we return the internal network name
      return getNetworkName(internalNetwork, transaction.from.account.exchangeId);
    }
    return undefined;
  }, [config, getNetworkName]);

  const partnerName = useMemo(() => {
    if (!transaction || !partners) return undefined;
    const partnerId = transaction.partnerId;
    if (partnerId !== undefined) {
      const partner = partners.find((partner: Partner) => partner.id === partnerId);
      if (partner?.twitterHandle) return `@${partner.twitterHandle}`;
    }
    return undefined;
  }, [partners, transaction]);

  const share = (content: string, placeholderValues: Record<string, string>) => {
    const mappedContent = replacePlaceholdersByValue(content, placeholderValues);
    const twitterUrl = `${POST_ON_TWITTER_URL}${encodeURIComponent(mappedContent)}`;
    window.open(twitterUrl, "_blank");
  };

  return {
    partnerName,
    networkName,
    share,
  };
};
