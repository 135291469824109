import { ChangeEvent, useEffect, useMemo, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import { Any } from "@cede/types";
import { sortItemsByDescendingAmountAndAlphabetical } from "@cede/utils";
import { BackButton } from "../BackButton";
import { AmountDropListWithIconItemElement } from "../DropListWithIcon/items/AmountDropListWithIconItem";
import { Icon } from "../Icon";
import { SearchBar } from "../SearchBar";
import {
  DropListItemTitle,
  DropListWithIconItemModal,
  DropListWithIconModalInputProps,
  DropListWithIconModalProps,
} from "./types";
import {
  Box,
  CircularProgress,
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";

const DropListWithIconInput = ({
  onClick,
  id,
  label,
  rounded,
  value,
  isActive,
  placeholder,
  disabled,
  onChange,
  onClear,
  loading,
  padding,
}: DropListWithIconModalInputProps) => {
  const theme = useTheme();
  return (
    <Box>
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor={id + "-input"}>{label}</InputLabel>
        <OutlinedInput
          onClick={onClick}
          sx={(theme) => ({
            "borderRadius": rounded ? theme.custom.buttonRoundedRadius : theme.custom.inputRadius.default,
            "paddingRight": (padding || theme.custom.gutterMargin) + "!important",
            "paddingLeft": padding ? padding + "!important" : undefined,
            "& .input__clear-icon": {
              pointerEvents: "none",
              opacity: 0,
            },
            "&:hover .input__clear-icon": {
              pointerEvents: "all",
              opacity: 1,
            },
          })}
          startAdornment={
            <InputAdornment
              position="end"
              sx={{
                marginLeft: value && value.img !== "" ? 0 : undefined,
              }}
            >
              {value && value.img !== "" && (
                <Icon
                  iconUrl={value.img}
                  iconSize="medium"
                  iconName={value.label}
                  testId={`${id || ""}-selected-icon`}
                  sx={{
                    marginRight: theme.custom.smallGutterMargin,
                  }}
                  defaultIcon={value.defaultImg}
                />
              )}
            </InputAdornment>
          }
          endAdornment={
            <>
              {onClear && value && (
                <IconButton
                  className="input__clear-icon"
                  onClick={(e) => {
                    onClear();
                    e.stopPropagation();
                  }}
                >
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.8337 1.34163L10.6587 0.166626L6.00033 4.82496L1.34199 0.166626L0.166992 1.34163L4.82533 5.99996L0.166992 10.6583L1.34199 11.8333L6.00033 7.17496L10.6587 11.8333L11.8337 10.6583L7.17533 5.99996L11.8337 1.34163Z"
                      fill={theme.palette.text.secondary}
                      fillOpacity="0.56"
                    />
                  </svg>
                </IconButton>
              )}
              <IconButton
                onClick={onClick}
                sx={{
                  transition: "transform 0.3s",
                  transformOrigin: "center",
                  transform: isActive ? "rotate(-180deg)" : "rotate(0)",
                }}
              >
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.59 0.294922L6 4.87492L1.41 0.294922L0 1.70492L6 7.70492L12 1.70492L10.59 0.294922Z"
                    fill={theme.palette.text.secondary}
                    fillOpacity="0.56"
                  />
                </svg>
              </IconButton>
            </>
          }
          label={label}
          placeholder={placeholder}
          disabled={disabled}
          inputProps={{
            "id": id + "-input",
            "data-testid": `${id || ""}-input`,
            "value": loading ? "Loading..." : value?.label || "",
            "onChange": (event: ChangeEvent<HTMLInputElement>) => {
              onClick();
              onChange(event);
            },
            "autoComplete": "off",
            "style": {
              paddingTop: padding,
              paddingBottom: padding,
            },
          }}
        />
      </FormControl>
    </Box>
  );
};

const isDropListItemTitle = (item: DropListWithIconItemModal | DropListItemTitle): item is DropListItemTitle =>
  (item as DropListItemTitle).title != undefined;

export function DropListWithIconModal<T extends DropListWithIconItemModal<{ [k: string]: Any }>>({
  title,
  items,
  rounded,
  value,
  emptyMessage,
  description,
  onChange,
  inputProps,
  loading,
  disableModalPortal,
  actionButton,
  shouldDisableItem,
  disabledTextItem,
  onClear,
  baseElement: BaseElement,
  renderItem,
  itemSize,
  padding,
  sublistTitles,
}: DropListWithIconModalProps<T>) {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const selectedItemValue = value?.value;

  const filterItemList = (items: DropListWithIconItemModal[]): DropListWithIconItemModal[] => {
    const sortedItems = sortItemsByDescendingAmountAndAlphabetical<DropListWithIconItemModal>(items, shouldDisableItem);
    const filteredAndSortedItems = !searchValue
      ? sortedItems
      : sortedItems.filter((item) => {
          const isLabelSearched = item.label.toLowerCase().includes(searchValue.toLowerCase());
          const isSearchTermSearched = !!item.searchableTerms?.find((searchableTerm) =>
            searchableTerm.toLowerCase().includes(searchValue.toLowerCase()),
          );

          return isLabelSearched || isSearchTermSearched;
        });

    // Move the selected item to the beginning, if it exists
    if (selectedItemValue != null) {
      const selectedItemIndex = filteredAndSortedItems.findIndex((item) => item.value === selectedItemValue);
      if (selectedItemIndex > -1) {
        const selectedItem = filteredAndSortedItems.splice(selectedItemIndex, 1)[0];
        if (selectedItem) filteredAndSortedItems.unshift(selectedItem);
      }
    }

    return filteredAndSortedItems;
  };

  const filteredItems = useMemo(() => {
    if (Array.isArray(items)) {
      return filterItemList(items);
    }
    const sortedLists = Object.entries(items).reduce((acc, [key, value]) => {
      acc[key] = filterItemList(value);
      return acc;
    }, {} as Record<string, DropListWithIconItemModal[]>);

    return Object.entries(sortedLists).reduce((acc, [key, values]) => {
      if (values.length === 0) return acc;
      const itemTitle: DropListItemTitle = {
        title: sublistTitles?.[key] || key,
        value: key,
      };
      acc.push(itemTitle);
      acc.push(...values);
      return acc;
    }, [] as (DropListWithIconItemModal | DropListItemTitle)[]);
  }, [items, searchValue, selectedItemValue]);

  const [searchInputRef, setSearchInputRef] = useState<HTMLInputElement | null>(null);

  const openModal = () => {
    if (inputProps?.disabled) return;
    setIsOpen(true);
  };

  useEffect(() => {
    if (isOpen && searchInputRef) {
      searchInputRef.focus();
    }
  }, [isOpen, searchInputRef]);

  const Row = ({ index, style, ...rest }: Any) => {
    const item = rest.data.filteredItems[index];

    const render = () => {
      if (!isDropListItemTitle(item)) {
        return renderItem
          ? renderItem(item)
          : AmountDropListWithIconItemElement(
              {
                setOpen: setIsOpen,
                theme,
                shouldDisableItem,
                disabledTextItem,
              },
              { ...item, amount: item.amount ? Number(item.amount) : undefined },
            );
      }
      return (
        <Box component={"li"} paddingY={theme.spacing()}>
          <Typography fontWeight={"bold"} paddingLeft={theme.spacing()}>
            {item.title}
          </Typography>
        </Box>
      );
    };

    return (
      <div style={{ ...style, pointerEvents: "auto" }}>
        <Box
          data-testid={`${inputProps?.id || ""}-option-${index}`}
          onClick={() => {
            if (item.isDisabled || shouldDisableItem?.(item) || isDropListItemTitle(item)) return;

            onChange(item);
            setIsOpen(false);
          }}
          key={item.value}
          sx={{
            "transition": "0.3s",
            "cursor": isDropListItemTitle(item) ? undefined : "pointer",
            ":hover": { background: isDropListItemTitle(item) ? undefined : alpha("#75B3FF", 0.08) }, // Should we move this to the theme?
            "background": item.value === selectedItemValue ? alpha("#75B3FF", 0.15) : "transparent",
          }}
        >
          {render()}
        </Box>
      </div>
    );
  };

  return (
    <>
      {/* INPUT */}
      {BaseElement ? (
        <BaseElement value={value} onClick={openModal} />
      ) : (
        <DropListWithIconInput
          onClick={openModal}
          id={inputProps?.id}
          label={inputProps?.label}
          placeholder={inputProps?.placeholder}
          disabled={inputProps?.disabled}
          loading={loading}
          rounded={rounded}
          isActive={isOpen}
          onChange={(event) => setSearchValue(event.target.value)}
          value={value}
          onClear={onClear}
          padding={padding}
        />
      )}
      {/* MODAL */}
      <Modal open={isOpen} onClose={() => setIsOpen(false)} closeAfterTransition disablePortal={disableModalPortal}>
        <Fade in={isOpen} timeout={300}>
          <Box
            sx={{
              "width": `calc(${theme.custom.storeSize.width} * 0.9)`,
              "height": `calc(${theme.custom.storeSize.height} * 0.9)`,
              "position": "absolute",
              "top": "50%",
              "left": "50%",
              "transform": "translate(-50%, -50%)",
              "backgroundColor": theme.palette.background.darkPaper,
              "display": "flex",
              "flexDirection": "column",
              "borderRadius": theme.custom.mediumBoxRadius,
              ":focus": {
                outline: "none",
              },
            }}
          >
            <Box
              sx={{
                padding: theme.custom.gutterMargin,
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Box position={"absolute"} left={theme.custom.gutterMargin}>
                <BackButton
                  color={theme.palette.text.secondary}
                  handleReturn={() => {
                    setIsOpen(false);
                  }}
                />
              </Box>

              <Typography fontSize={theme.typography.h3.fontSize}>{title}</Typography>
              {onClear && value && (
                <Box position={"absolute"} right={theme.custom.gutterMargin}>
                  <Typography
                    data-testid="clear-button"
                    sx={{ cursor: "pointer", color: theme.palette.primary.main }}
                    onClick={() => {
                      onClear();
                      setIsOpen(false);
                    }}
                  >
                    Clear
                  </Typography>
                </Box>
              )}
            </Box>
            <Box sx={{ padding: theme.custom.gutterMargin }}>
              {description && (
                <Typography fontSize="12px" mt="-8px" color={theme.palette.text.secondary} mb="4px">
                  {description}
                </Typography>
              )}
              <SearchBar
                sx={{
                  ".MuiOutlinedInput-root.MuiInputBase-formControl": {
                    borderRadius: 1.5,
                  },
                }}
                inputRef={(ref) => setSearchInputRef(ref)}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />
            </Box>
            <Box
              flex={1}
              sx={{
                listStyle: "none",
                padding: 0,
              }}
              component="ul"
              data-testid={`${inputProps?.id || ""}-list`}
            >
              {loading && (
                <CircularProgress
                  sx={{
                    position: "absolute",
                    right: "0",
                    top: "0",
                    bottom: "0",
                    left: "0",
                    margin: "auto",
                  }}
                />
              )}
              {filteredItems.length === 0 && !loading && (
                <Typography
                  data-testid="empty-message"
                  paddingX={theme.custom.gutterMargin}
                  variant="body1"
                  color={theme.palette.text.primary}
                >
                  {emptyMessage ?? "No results found"}
                </Typography>
              )}
              {!loading && filteredItems?.length !== 0 && (
                <AutoSizer style={{ width: "auto", height: "auto" }} data-testid="windowing-list">
                  {({ width, height }) => {
                    return (
                      <List
                        height={actionButton ? 360 : height || 0}
                        width={width || 0}
                        style={{
                          willChange: "auto",
                        }}
                        itemSize={
                          itemSize
                            ? itemSize
                            : (item: number) => ((filteredItems[item] as DropListWithIconItemModal)?.balance ? 50 : 36)
                        }
                        itemCount={filteredItems.length}
                        itemData={{
                          filteredItems,
                        }}
                      >
                        {Row}
                      </List>
                    );
                  }}
                </AutoSizer>
              )}
              {actionButton ? <Box onClick={() => setIsOpen(false)}>{actionButton}</Box> : <></>}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
