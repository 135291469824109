/*
 * Copyright (C) CEDE Labs, SAS - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by CEDE Labs team <team@cedelabs.io>, September 2021
 */
import { Any, HandlerType, ProviderHandler, UntrustedApi, UntrustedApiMethods, UntrustedApiParams } from "@cede/types";
import { CedeProvider } from "@cedelabs/providers/src";

export default function providerHandlerFactory(provider: CedeProvider) {
  return new Proxy(provider, {
    get: (object: Any, property: string) => {
      if (property === "type") return HandlerType.UNTRUSTED_PROVIDER_API;
      if (property === "then") return undefined; // we don't want to make the provider thenable
      if (property === "isReal") return true;
      if (property === "isReady") {
        // we can execute methods on the provider object when the connection with the background is ready
        return object.inpageProvider.getIsConnected();
      }
      if (object[property]) return object[property];
      return async (parameters: UntrustedApiParams[UntrustedApiMethods]) => {
        const response = await provider.request({ method: property as keyof UntrustedApi, params: parameters });
        return response;
      };
    },
  }) as ProviderHandler & CedeProvider;
}
