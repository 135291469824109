import { Any, CurrenciesResponse } from "@cede/types";
import { advancedRandomId } from "../random";
import { API_MOCK_DATA } from "./mocks";
import { APIResponseMap, API_ENDPOINT } from "./types";
import { Scope } from "@sentry/types";
import axios from "axios";

export * from "./readAssetFromExtension";
export * from "./types";

const CEDE_API_URL = process.env.CEDE_API_URL || "";
const CEDE_API_TOKEN = process.env.CEDE_API_TOKEN || "";
const ENVIRONMENT = process.env.CEDE_NODE_ENV;

if (ENVIRONMENT === undefined) throw new Error("CEDE_NODE_ENV is not defined");

type ApiParams = {
  pathParams?: string[];
  queryParams?: Record<string, string>;
};

type PostApiParams = ApiParams & {
  bodyParams?: Record<string, Any>;
};

export const fakeGetFromApi = async <E extends keyof APIResponseMap>(endpoint: E): Promise<APIResponseMap[E]> => {
  return Promise.resolve(API_MOCK_DATA[endpoint] as APIResponseMap[E]);
};

/**
 * @description This function is used to call the Cede API.
 * It handles the authentication and the error handling.
 * It also handles the mock data for the tests.
 */
export const getFromApi = async <E extends keyof APIResponseMap>(
  endpoint: E,
  params?: ApiParams,
): Promise<APIResponseMap[E]> => {
  if (ENVIRONMENT === "test") return await fakeGetFromApi(endpoint);
  const requestId = advancedRandomId(3);
  try {
    const pathParamsString = params?.pathParams ? `/${params?.pathParams?.join("/")}` : "";
    const queryParamsString = params?.queryParams ? `?${new URLSearchParams(params.queryParams)}` : "";
    const response = await fetch(`${CEDE_API_URL}/${endpoint}${pathParamsString}${queryParamsString}`, {
      method: "GET",
      headers: {
        "X-TOKEN": `${CEDE_API_TOKEN}`,
        "X-REQUEST-ID": requestId,
        "X-ENDPOINT": endpoint,
      },
    });

    const unwrappedResponse = await response.json();
    return unwrappedResponse;
  } catch (error: Any) {
    throw new Error(`Couldn't reach Cede API - ${endpoint}: ${error.message}, requestId:${requestId}`);
  }
};

export const postToApi = async <E extends API_ENDPOINT>(
  endpoint: E,
  params?: PostApiParams,
  sentryInstance?: Scope,
): Promise<Any> => {
  const requestId = advancedRandomId(3);
  try {
    const pathParamsString = params?.pathParams ? `/${params?.pathParams?.join("/")}` : "";
    const queryParamsString = params?.queryParams ? `?${new URLSearchParams(params.queryParams)}` : "";
    const res = await fetch(`${CEDE_API_URL}/${endpoint}${pathParamsString}${queryParamsString}`, {
      method: "POST",
      headers: {
        "X-TOKEN": `${CEDE_API_TOKEN}`,
        "X-REQUEST-ID": requestId,
        "X-ENDPOINT": endpoint,
        ...(params?.bodyParams ? { "Content-Type": "application/json" } : {}), // Only add Content-Type if there are bodyParams
      },
      body: params?.bodyParams ? JSON.stringify(params.bodyParams) : undefined,
    });
    return await res.json();
  } catch (error: Any) {
    if (sentryInstance) {
      sentryInstance.captureException(new Error(`Couldn't POST to Cede API`), {
        captureContext: {
          extra: {
            url: `${CEDE_API_URL}/${endpoint}`,
            message: `Error when calling ${CEDE_API_URL}/${endpoint}: ${error.message}, ${error.stack}`,
            requestId,
          },
        },
      });
    }
    throw error;
  }
};

export const deleteToApi = async <E extends API_ENDPOINT, T extends Record<string, Any>>(
  endpoint: E,
  params?: T,
  sentryInstance?: Scope,
): Promise<void> => {
  const requestId = advancedRandomId(3);
  try {
    const queryParamsString = params ? `?${new URLSearchParams(params)}` : "";
    await fetch(`${CEDE_API_URL}/${endpoint}${queryParamsString}`, {
      method: "DELETE",
      headers: {
        "X-TOKEN": `${CEDE_API_TOKEN}`,
        "X-REQUEST-ID": requestId,
        "X-ENDPOINT": endpoint,
      },
      body: params ? JSON.stringify(params) : undefined,
    });
  } catch (error: Any) {
    if (sentryInstance) {
      sentryInstance.captureException(new Error(`Couldn't DELETE to Cede API`), {
        captureContext: {
          extra: {
            url: `${CEDE_API_URL}/${endpoint}`,
            message: `Error when calling ${CEDE_API_URL}/${endpoint}: ${error.message}, ${error.stack}`,
            requestId,
          },
        },
      });
    }
    throw error;
  }
};

const axiosInstance = axios.create({
  headers: {
    "X-TOKEN": CEDE_API_TOKEN,
  },
});

export const fetchAvailableCurrencies = async () => {
  if (ENVIRONMENT === "test") {
    const res: CurrenciesResponse = {
      data: {
        updatedAt: Date.now().toString(),
        currencies: {
          fiat: ["EUR", "USD"],
          crypto: ["BTC"],
        },
      },
    };
    return res;
  }
  const { data } = await axiosInstance.get<CurrenciesResponse>(`${CEDE_API_URL}/v2/price/available_vs_currencies`);
  return data;
};
