import { useDependencies } from "../../hooks";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Button, useTheme } from "@mui/material";

export const HelpButton = () => {
  const theme = useTheme();
  const { useDiscordInvitation } = useDependencies();
  const { joinDiscord } = useDiscordInvitation();

  // @TODO: put the link inside the feature flag management system so it's hot swappable
  return (
    <Button
      variant="contained"
      endIcon={<HelpOutlineIcon color="inherit" fontSize="inherit" />}
      onClick={joinDiscord}
      sx={{
        "position": "relative",
        "zIndex": "9999",
        "backgroundColor": theme.palette.background.darkPaper,
        "color": "white",
        "transition": ".25s ease-out",
        "textTransform": "none",
        "&:hover": {
          opacity: "0.7",
          backgroundColor: theme.palette.background.darkPaper,
        },
      }}
    >
      Contact us for help
    </Button>
  );
};
