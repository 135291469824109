import { ReactNode, useEffect } from "react";
import { DiscordIcon, StoreLogo, useNavBar } from "@cede/ds";
import { TxStatus, TxType, ViewEnvironment } from "@cede/types";
import { DISCORD_INVITE_URL } from "@cede/utils";
import { HelpLink, Page } from "../..";
import { CedeStoreMessage } from "../../components/Transaction/CedeStoreMessage";
import { CexLine } from "../../components/Transaction/CexLine";
import { ConfirmEmail } from "../../components/Transaction/ConfirmEmail";
import { ErrorLine } from "../../components/Transaction/ErrorLine";
import { NetworkLine } from "../../components/Transaction/NetworkLine";
import { RecipientAddressLine } from "../../components/Transaction/RecipientAddressLine";
import { SendHeader } from "../../components/Transaction/SendHeader";
import { StatusLine } from "../../components/Transaction/StatusLine";
import { TotalFees } from "../../components/Transaction/TotalFees";
import { TradeHeader } from "../../components/Transaction/TradeHeader";
import { useDependencies } from "../../hooks/useDependencies";
import { getFooter } from "./footers/getFooter";
import { isOnrampMoney } from "./utils";
import { WidgetsRounded, X } from "@mui/icons-material";
import { Box, CardActionArea, Fade, SvgIcon, Typography, useTheme } from "@mui/material";

const titles = {
  [TxStatus.OK]: (type: TxType) => (type === "order" ? "Successfully traded!" : "Successfully sent!"),
  [TxStatus.CANCELLED]: (type: TxType) => (type === "order" ? "Trade attempt failed!" : "Send attempt failed!"),
  [TxStatus.FAILED]: (type: TxType) => (type === "order" ? "Trade attempt failed!" : "Send attempt failed!"),
  [TxStatus.PENDING]: (type: TxType) => (type === "order" ? "Trade submitted!" : "Send submitted!"),
};

const operationNames: Record<TxType, string> = {
  order: "Trade",
  withdrawal: "Send",
  deposit: "Deposit",
  transfer: "Transfer",
  swap: "Swap",
};

const transactionStatuses = {
  [TxStatus.OK]: "success",
  [TxStatus.FAILED]: "error",
  [TxStatus.PENDING]: "pending",
  [TxStatus.CANCELLED]: "error",
};

const HeaderButton = ({ children, onClick }: { children: ReactNode[]; onClick: VoidFunction }) => {
  const theme = useTheme();
  return (
    <CardActionArea
      onClick={onClick}
      sx={{
        display: "inline-flex",
        gap: theme.spacing(1),
        alignItems: "center",
        flex: 1,
        background: theme.palette.background.darkPaper,
        color: theme.palette.text.primary,
        borderRadius: theme.custom.buttonSmallRadius,
        paddingY: theme.spacing(1),
      }}
    >
      {children}
    </CardActionArea>
  );
};

export const CompletedTransaction = () => {
  const { useCompletedTransaction, widgetProps, useViewEnvironment, useSendStore } = useDependencies();
  const { transaction, onTransactionDetails, shareOnTwitter, isRequiringEmailConfirmation, error } =
    useCompletedTransaction();
  const theme = useTheme();
  const { setIsDisplayed } = useNavBar();
  const { environment: viewEnvironment } = useViewEnvironment();
  const { initialProps } = useSendStore();

  useEffect(() => {
    setIsDisplayed(false);
  }, []);

  if (!transaction) return null;

  const transactionStatus = transaction.from.transaction.status;
  const transactionResult = transactionStatuses[transactionStatus];

  const logoType = transactionResult === "success" ? "happy" : transactionResult === "error" ? "sad" : "normal";

  const titleColor = () => {
    if (transactionResult === "success") return theme.palette.success.main;
    if (transactionResult === "error") return theme.palette.error.main;
    if (transactionResult === "pending") return theme.palette.warning.main;
    return theme.palette.text.primary;
  };

  const onSupportNeeded = () => {
    window.open(DISCORD_INVITE_URL, "_blank");
  };

  const logoSize = viewEnvironment === ViewEnvironment.WIDGET ? "75px" : theme.custom.imgSize.large;

  const shouldDisplayConfirmEmail = isRequiringEmailConfirmation && transaction.type === "withdrawal" && !error;

  return (
    <Fade in={true}>
      <Page displayFlex alignItems="center">
        <Box
          position={"absolute"}
          top={viewEnvironment === ViewEnvironment.WIDGET ? "30px" : theme.custom.smallGutterMargin}
          right={theme.custom.smallGutterMargin}
        >
          {viewEnvironment !== ViewEnvironment.WIDGET && <HelpLink />}
        </Box>
        <Box paddingTop={viewEnvironment === ViewEnvironment.WIDGET ? "15px" : "30px"}>
          {widgetProps?.logo !== undefined ? (
            widgetProps.logo
          ) : (
            <StoreLogo size="medium" type={logoType} width={logoSize} height={logoSize} />
          )}
        </Box>
        <Typography variant={"h1"} marginTop={0} paddingY={theme.spacing()} color={titleColor()}>
          {titles[transactionStatus](transaction.type)}
        </Typography>
        <Box display={"flex"} alignItems={"center"} gap={theme.spacing(3)} width={"100%"}>
          <HeaderButton onClick={onTransactionDetails}>
            <Typography>{operationNames[transaction.type]} details</Typography>
            <WidgetsRounded fontSize="small" />
          </HeaderButton>
          {!isOnrampMoney && !error && (
            <HeaderButton onClick={shareOnTwitter}>
              <Typography>Share on</Typography>
              <X fontSize="small" />
            </HeaderButton>
          )}
          {error && (
            <HeaderButton onClick={onSupportNeeded}>
              <Typography>Contact support </Typography>
              <Box ml={theme.custom.smallGutterMargin} display="flex" alignItems="center">
                <SvgIcon fill={theme.palette.text.secondary}>
                  <DiscordIcon innerLogoColor={theme.palette.background.default} />
                </SvgIcon>
              </Box>
            </HeaderButton>
          )}
        </Box>
        <Box
          sx={{
            background: theme.palette.background.darkPaper,
            borderRadius: theme.custom.buttonSmallRadius,
            width: "100%",
          }}
        >
          <Box
            display={"flex"}
            padding={theme.spacing(2)}
            alignItems={"center"}
            justifyContent={"space-between"}
            borderBottom={`1px solid ${theme.palette.cedeComponentHr}`}
          >
            <TradeHeader />
            <SendHeader />
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"repeat(2, auto)"}
            gap={theme.spacing(2)}
            alignItems={"center"}
            justifyItems={"left"}
            padding={theme.spacing(2)}
            paddingBottom={shouldDisplayConfirmEmail ? theme.spacing(0.5) : theme.spacing(2)}
          >
            <CexLine />
            <StatusLine />
            {!error && <NetworkLine />}
            {!error && <RecipientAddressLine />}
            {!shouldDisplayConfirmEmail && !error && <TotalFees />}
            <ErrorLine label="CEX error message" />
            {error && <CedeStoreMessage />}
          </Box>
          {shouldDisplayConfirmEmail && (
            <Box padding={theme.spacing(2)} paddingTop={0}>
              <ConfirmEmail />
            </Box>
          )}
        </Box>
        {getFooter(initialProps.clientTxId)}
      </Page>
    </Fade>
  );
};
