import { Button } from "@cede/ds";
import { TutorialModalProps } from "./types";
import { Box, Fade, Modal, Theme, Typography, useTheme } from "@mui/material";

const style = (theme: Theme) => ({
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: `calc(${theme.custom.storeSize.tabWidth} * 0.9)`,
  maxHeight: "476px",
  overflowY: "auto",
  background: theme.palette.background.paper,
  boxShadow: 24,
  padding: theme.custom.gutterMargin,
  borderRadius: theme.custom.largeBoxRadius,
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
});

export function TutorialModal({ onClose, open, children, subtitle, title }: TutorialModalProps) {
  const theme = useTheme();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      sx={{
        zIndex: "99999999999",
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography variant="h1" component="h2" fontWeight={"500"} fontSize={theme.typography.h2.fontSize}>
            {title}
          </Typography>
          {typeof subtitle === "string" ? (
            <Typography variant="body2" component={"h3"} fontWeight="normal" sx={{ mt: 2 }}>
              {subtitle}
            </Typography>
          ) : (
            subtitle
          )}

          <Box sx={{ mt: 2 }} alignSelf="flex-start">
            {children}
          </Box>
          <Button variant="text" fullWidth onClick={onClose} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
}
