import { ExchangeInfo, ExchangeStatus } from "@cede/types";
import { CEDE_CEXS } from "../cexs";

function generateExchangeInfo(exchangeId: string): ExchangeInfo {
  return {
    name: exchangeId,
    id: exchangeId,
    logo: "#",
    status: ExchangeStatus.Ok,
    supportedWalletTypes: ["spot"],
    supportedFeatures: ["send", "trade", "receive"],
    auhenticationMethod: "apiKeys",
    isRequiringEmailConfirmation: false,
    minimumWithdrawalUrl: "",
    isRequiringAddressWhitelisting: false,
    shouldCheckWhitelist: false,
    ipWhitelistAddresses: [],
    provideWhitelistedAddresses: false,
    minimumDepositlUrl: "",
    addressManagementUrl: "",
    affiliationLink: "",
    websiteHomelinkIdentifier: "",
    whitelistScopes: [],
    inducedPermissions: {},
    featuresUnderMaintenance: {
      send: false,
      trade: false,
      receive: false,
      trade_and_send: false,
    },
    requiresPassword: false,
    requiresTwoKeys: false,
    requiresUid: false,
    apiManagementLink: "",
    notConnectedIdentifier: "",
    supportVerifyKYC: false,
    hybridAuthentication: undefined,
    oauthAuthentication: {
      isOauthLoginSupported: false,
      isUsingFastApi: false,
      hasPkceFlow: false,
    },
    transferWalletTypes: [],
  };
}

export const mockSupportedExchanges: ExchangeInfo[] = Object.values(CEDE_CEXS).map(generateExchangeInfo);
