import { CedeProvider } from "./providers/CedeProvider";
import { InpageProvider } from "./providers/InpageProvider";

interface Window {
  cede?: CedeProvider;
}

export function detectCedeProvider(timeout = 1500): Promise<CedeProvider | null> {
  let handled = false;

  return new Promise((resolve) => {
    if ((window as Window).cede) {
      handleConnection();
    } else {
      window.addEventListener("cede#initialized", handleConnection, {
        once: true,
      });

      setTimeout(() => {
        handleConnection();
      }, timeout);
    }

    function handleConnection() {
      if (handled) {
        const inpageProvider = (window as Window).cede as unknown as InpageProvider;
        const cedeProvider = new CedeProvider(inpageProvider);
        resolve(cedeProvider);
        return;
      }
      handled = true;

      window.removeEventListener("cede#initialized", handleConnection);

      const { cede } = window as Window;

      if (cede) {
        const inpageProvider = (window as Window).cede as unknown as InpageProvider;
        const cedeProvider = new CedeProvider(inpageProvider);
        resolve(cedeProvider);
      } else {
        resolve(null);
      }
    }
  });
}
