import { useEffect } from "react";
import { Button, useNavBar } from "@cede/ds";
import { ApiPermissions, ViewEnvironment } from "@cede/types";
import { CEDE_CEX_LABELS, TWO_KEYS_PERMISSIONS_WITHOUT_PROXY } from "@cede/utils";
import { HelpLink, MainFooter, Page, TutorialReader } from "../../components";
import { useDependencies } from "../../hooks";
import { useFixInvalidPermissionsTutorial } from "./useFixInvalidPermissionsTutorial";
import { Box, Fade, Typography } from "@mui/material";

export const FixInvalidPermissionsTutorial = () => {
  const { useCompletedTransaction, useNavigate } = useDependencies();
  const { transaction } = useCompletedTransaction();
  const navigate = useNavigate();
  const { setIsDisplayed, setGoBack, setCenter, setRightSide, setLeftSide } = useNavBar();

  const { mdContent, tutorialType, onUpdated, publicApiKey, environment, account } = useFixInvalidPermissionsTutorial({
    transaction,
  });

  const goBack = () => navigate(-1);

  useEffect(() => {
    setIsDisplayed(true);
    if (goBack) setGoBack(goBack);
    else setLeftSide(null);
    setCenter(
      <Typography component="h1" variant="h3" margin={0}>
        Fix missing permissions
      </Typography>,
    );

    if (environment !== ViewEnvironment.WIDGET) {
      setRightSide(<HelpLink />);
    } else {
      setRightSide(null);
    }
  }, []);

  const filterPermissions = (permissions: ApiPermissions[]) => {
    if (!account) return permissions;
    if (TWO_KEYS_PERMISSIONS_WITHOUT_PROXY[account.exchangeId])
      return permissions.filter((p) => TWO_KEYS_PERMISSIONS_WITHOUT_PROXY[account.exchangeId]?.includes(p));
    else return permissions;
  };

  return (
    <Fade in={true}>
      <Page displayFlex overflow="auto">
        <Box>
          {account && (
            <TutorialReader
              folder={tutorialType}
              mdContent={mdContent}
              permissions={filterPermissions(account.permissions)}
              accountPermissions={account.permissions || []}
              exchangeId={CEDE_CEX_LABELS[account.exchangeId] || account.exchangeId}
              data={{
                publicAPIKey: publicApiKey,
              }}
            />
          )}
        </Box>
        <MainFooter>
          <Button
            data-testid="choose-cex-button"
            height="tall"
            variant="contained"
            size="large"
            type="submit"
            fullWidth
            onClick={onUpdated}
          >
            Done
          </Button>
        </MainFooter>
      </Page>
    </Fade>
  );
};
