import { useImperativeHandle } from "react";
import { ValidationTextFieldProps } from "./types";
import { useValidation } from "./useValidation";
import { TextField } from "@mui/material";

export const ValidationTextField = ({
  validate,
  validateOnMount = true,
  elementRef,
  ...props
}: ValidationTextFieldProps) => {
  const { validationStatus, triggerValidate, cancelValidation } = useValidation({
    validate,
    value: props.value,
    validateOnMount,
  });

  useImperativeHandle(elementRef, () => ({
    validate: triggerValidate,
    cancelValidation,
  }));

  return (
    <TextField
      {...props}
      error={validationStatus ? validationStatus.color === "error" : false}
      helperText={validationStatus && validationStatus.color === "error" ? validationStatus.message : ""}
      InputLabelProps={{ id: `${props.id || ""}-label` }}
      sx={(theme) => ({
        ".MuiFormLabel-root": {
          color: validationStatus?.color === "error" ? theme.palette.error.main : theme.palette.action.disabled,
        },
        ".Mui-focused": {
          color: validationStatus?.color === "error" ? theme.palette.error.main : theme.palette.primary.main,
        },
        ".Mui-focused .MuiOutlinedInput-input": {
          color: theme.palette.text.primary,
        },
        ".MuiInputBase-root": {
          borderRadius: theme.custom.inputRadius.default,
          marginBottom: "0",
          position: "relative",
        },
        ".MuiFormHelperText-root": {
          position: "absolute",
          top: "100%",
        },
      })}
    />
  );
};
