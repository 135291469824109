import { useMemo } from "react";
import { ApiPermissions, Transaction, ViewEnvironment, isCefiInfoTx } from "@cede/types";
import { STORE_ROUTES, TWO_KEYS_PERMISSIONS_WITH_PROXY, WIDGET_ROUTES } from "@cede/utils";
import { useDependencies } from "../../hooks";
import { useQuery } from "@tanstack/react-query";

export const useFixInvalidPermissionsTutorial = ({ transaction }: { transaction: Transaction | null }) => {
  const { useExtensionAsset, useViewEnvironment, useNavigate, backgroundHandler, useAccounts, useSelectedTransaction } =
    useDependencies();
  const { environment } = useViewEnvironment();
  const { resetError } = useSelectedTransaction((state) => ({
    resetError: state.resetError,
  }));

  const { accountsByIds } = useAccounts();
  const account = useMemo(() => {
    if (transaction && !isCefiInfoTx(transaction.from)) {
      if (environment === ViewEnvironment.WIDGET) navigate(WIDGET_ROUTES.ACTION_ROUTE);
      else navigate(STORE_ROUTES.SEND_ROUTE);
    }
    if (transaction && isCefiInfoTx(transaction.from)) return accountsByIds[transaction.from.account.accountId];
    return;
  }, [transaction]);

  const navigate = useNavigate();
  const { asset: mdContent } = useExtensionAsset({
    path: account?.exchangeId
      ? `tutorials/fix-invalid-permissions/${account.exchangeId}/${account.exchangeId}.md`
      : undefined,
  });

  const { data: publicApiKey, isFetching: isFetchingPublicApiKey } = useQuery({
    queryKey: ["publicApiKey", account?.exchangeId],
    queryFn: async () => {
      if (!account) return;
      const publicKeys = await backgroundHandler.getAccountPublicKeys({
        accountId: account.id,
      });

      let permission = ApiPermissions.READ;
      if (TWO_KEYS_PERMISSIONS_WITH_PROXY[account?.exchangeId]) {
        const proxiedPermission = TWO_KEYS_PERMISSIONS_WITH_PROXY[account.exchangeId]?.find((key) => publicKeys[key]);
        if (proxiedPermission) permission = proxiedPermission; // for 2 keys system, we use proxied permission for withdrawals. The user will need to update the proxied key.
      }
      return publicKeys[permission]?.apiPublic;
    },
    refetchOnWindowFocus: false,
    enabled: !!backgroundHandler?.isReady && !!account,
    gcTime: Infinity,
    staleTime: Infinity,
  });

  const onUpdated = () => {
    resetError();
    if (environment === ViewEnvironment.WIDGET) navigate(WIDGET_ROUTES.ACTION_ROUTE);
    else navigate(STORE_ROUTES.SEND_ROUTE);
  };

  return {
    tutorialType: "fix-invalid-permissions/" + account?.exchangeId,
    mdContent: mdContent ?? "",
    onUpdated,
    publicApiKey,
    isFetchingPublicApiKey,
    environment,
    account,
  };
};
