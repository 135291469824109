import { TransitionGroup } from "react-transition-group";
import { InformationCard } from "@cede/ds";
import { useDependencies } from "../../hooks/useDependencies";
import { Box, Grow, useTheme } from "@mui/material";

export const AlertQueue = () => {
  const { useVaults, useAlert } = useDependencies();
  const theme = useTheme();
  const { activeVault } = useVaults();
  const alertQueue = useAlert((state) => state.alertQueue);
  const removeAlert = useAlert((state) => state.remove);

  return (
    <Box
      position={"absolute"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      gap={theme.custom.gutterMargin}
      left={"50%"}
      zIndex={theme.custom.zIndex.extraLayer + 100}
      width={"70%"}
      paddingTop={theme.custom.gutterMargin}
      sx={{
        transform: "TranslateX(-50%)",
      }}
    >
      <TransitionGroup>
        {alertQueue
          .filter((alert) => (alert.vault?.id ? alert.vault.id === activeVault?.id : true))
          .map((alert) => (
            <Grow key={alert.id} in={true}>
              <Box bgcolor={theme.palette.background.default} marginBottom={theme.custom.smallGutterMargin}>
                <InformationCard
                  color={alert.type}
                  content={alert.message}
                  onClose={() => removeAlert(alert.id)}
                  onClick={alert.onClick}
                />
              </Box>
            </Grow>
          ))}
      </TransitionGroup>
    </Box>
  );
};
