import { Button } from "@cede/ds";
import { TxStatus } from "@cede/types";
import { useDependencies } from "../../../hooks";
import { ArrowForward } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";

export const TxExplorerLink = () => {
  const theme = useTheme();
  const { useSelectedTransaction } = useDependencies();
  const transaction = useSelectedTransaction((state) => state.transaction);

  // Not implemented yet
  return (
    <Box
      flex={"1"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"flex-end"}
      width={"100%"}
      alignItems={"center"}
      paddingBottom={theme.custom.bigGutterMargin}
    >
      {transaction?.from.transaction.status === TxStatus.OK && false && (
        <Box>
          <Button variant={"outlined"}>
            <Box display={"flex"} alignItems={"center"} gap={theme.custom.smallGutterMargin}>
              <Box component={"span"}>View on Tx Explorer </Box>
              <ArrowForward
                sx={{
                  width: theme.custom.iconSize.small,
                  height: theme.custom.iconSize.small,
                }}
              />
            </Box>
          </Button>
        </Box>
      )}
    </Box>
  );
};
