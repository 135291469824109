import { useMemo } from "react";
import { SupportedTokensDropListWithIconItem, TradePathBySupportedTokens } from "@cede/types";
import { USE_SUPPORTED_TOKENS_CACHE_TIME, forgeTokenURL } from "@cede/utils";
import { useDependencies } from "../useDependencies";
import { useQuery } from "@tanstack/react-query";

type Params = {
  enabled: boolean;
  accountId?: string;
  fromToken?: string;
  network?: string;
  activeWithdrawal: boolean; // true for trade&send feature, false for trade square feature
};

export const useTradePathBySupportedTokens = ({ enabled, accountId, fromToken, network, activeWithdrawal }: Params) => {
  const { backgroundHandler } = useDependencies();

  const {
    data: tradePathBySupportedTokens,
    isFetching,
    error,
  } = useQuery({
    queryKey: ["tradePathBySupportedTokens", accountId, fromToken, network],
    queryFn: async () => {
      return backgroundHandler.getTradePathBySupportedTokens({
        accountId,
        from: fromToken,
        network,
      }) as Promise<TradePathBySupportedTokens>;
    },
    enabled: enabled && !!accountId && !!fromToken && (!activeWithdrawal || !!network) && !!backgroundHandler?.isReady,
    staleTime: USE_SUPPORTED_TOKENS_CACHE_TIME,
    gcTime: USE_SUPPORTED_TOKENS_CACHE_TIME,
  });

  const droplistSupportedTokens = useMemo<SupportedTokensDropListWithIconItem[]>(() => {
    if (!tradePathBySupportedTokens) return [];
    return Object.keys(tradePathBySupportedTokens).map<SupportedTokensDropListWithIconItem>((token) => ({
      label: token,
      value: token,
      searchableTerms: [token],
      img: forgeTokenURL(token),
      isDisabled: false,
      networks: [], // useless for the moment, since we assume that getTradePathBySupportedTokens returns the supported tokens for the given network
    }));
  }, [tradePathBySupportedTokens]);

  return {
    tradePathBySupportedTokens,
    supportedTokens: tradePathBySupportedTokens,
    droplistSupportedTokens,
    isLoading: isFetching,
    error,
  };
};
