import { useEffect } from "react";
import { ConnectParams, ProviderHandler } from "@cede/types";
import { useLoginStore } from "../store/login/login";
import { useDependencies } from "./useDependencies";
import { CedeProvider } from "@cedelabs/providers/src";
import { useMutation } from "@tanstack/react-query";

export type UseLoginProps = {
  onSuccess?: () => void;
};

export const useLogin = ({ onSuccess }: UseLoginProps = {}) => {
  const { useBackgroundHandler, useProviderEvents } = useDependencies();
  const { backgroundHandler } = useBackgroundHandler();
  const isLoggedIn = useLoginStore((state) => state.isLoggedIn);
  const setIsLoggedIn = useLoginStore((state) => state.setIsLoggedIn);

  const checkAutoLogin = async (bgHandler: ProviderHandler & CedeProvider) => {
    try {
      await bgHandler.connect({ silent: true, onboard: false });
      onSuccess?.();
      return true;
    } catch (e) {
      return false;
    }
  };

  useProviderEvents({
    onUnlock: () => setIsLoggedIn(true),
    onLock: () => setIsLoggedIn(false),
  });

  const connect = async (props: ConnectParams) => {
    const response = await backgroundHandler?.connect(props);

    return response;
  };

  const {
    mutateAsync: login,
    isPending,
    isError,
  } = useMutation({
    mutationFn: connect,
  });

  const handleLogin = async (props: ConnectParams) => {
    if (!backgroundHandler && !props.silent) {
      return;
    }

    const vaults = await login(props);
    onSuccess?.();

    return vaults;
  };

  useEffect(() => {
    (async () => {
      if (backgroundHandler && !isLoggedIn) {
        await checkAutoLogin(backgroundHandler);
      }
    })();
  }, [backgroundHandler, isLoggedIn]);

  return {
    handleLogin,
    isLoading: isPending,
    isError,
    checkAutoLogin,
    isLoggedIn,
  };
};
