/*
 * Copyright (C) CEDE Labs, SAS - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by CEDE Labs team <team@cedelabs.io>, September 2021
 */
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export type UseLoginStoreState = {
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
};

export type UseLoginStore = typeof useLoginStore;

export const useLoginStore = create(
  subscribeWithSelector<UseLoginStoreState>((set) => ({
    isLoggedIn: false,
    setIsLoggedIn: (isLoggedIn: boolean) => {
      set(() => ({ isLoggedIn }));
    },
  })),
);
