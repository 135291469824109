import { PandaBasic } from "../../assets/pandaBasic";
import { PandaHappy } from "../../assets/pandaHappy";
import { PandaSad } from "../../assets/pandaSad";
import { StoreLogoProps } from "./types";
import { Box, useTheme } from "@mui/material";

export const StoreLogo = ({ size, type, width, height }: StoreLogoProps) => {
  const theme = useTheme();
  const imageSize = (() => {
    if (size === "large") {
      return theme.custom.imgSize.large;
    }
    if (size === "medium") {
      return theme.custom.imgSize.medium;
    }
    if (size === "small") {
      return theme.custom.imgSize.small;
    }
    return theme.custom.imgSize.large;
  })();

  const Logo = type === "happy" ? PandaHappy : type === "sad" ? PandaSad : PandaBasic;

  return (
    <Box
      data-testid="store-logo"
      sx={{
        height: height || imageSize,
        width: width || imageSize,
        color: theme.palette.logoBorderColor,
        display: "inline-block",
      }}
    >
      <Logo />
    </Box>
  );
};
