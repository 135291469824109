import { forwardRef } from "react";
import { useNavBar } from "@cede/ds";
import { Any, ViewEnvironment } from "@cede/types";
import { useDependencies, useIsOnboardingRequest } from "../../hooks";
import { Box, BoxProps, useTheme } from "@mui/material";

export type PageProps = {
  displayFlex?: boolean;
  onSubmit?: React.FormEventHandler<Any>; // we have to redefine onSubmit here because ts doesn't infer the type correctly
} & Omit<BoxProps, "onSubmit">;

export const Page = forwardRef(({ children, displayFlex, sx, ...boxProps }: PageProps, ref: Any) => {
  const { useViewEnvironment } = useDependencies();
  const theme = useTheme();
  const { isDisplayed } = useNavBar();
  const { isOnboardingRequest } = useIsOnboardingRequest();
  const { environment } = useViewEnvironment();

  const getHeight = () => {
    if (environment === ViewEnvironment.WIDGET) return "100%";
    if (isDisplayed) return `calc(${theme.custom.storeSize.height} - ${theme.custom.navBarSize.height})`;
    else return theme.custom.storeSize.height;
  };

  return (
    <Box
      ref={ref}
      display={displayFlex ? "flex" : "block"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      width={"100%"}
      {...boxProps}
      sx={{
        "height": getHeight(),
        "paddingX": isOnboardingRequest ? "50px" : theme.custom.gutterMargin,
        "paddingBottom": 0,
        "paddingTop": theme.custom.smallGutterMargin, // many pages have a droplist at the top of the page. The label might have an overflow if we don't put the padding
        "::-webkit-scrollbar": {
          width: "4px",
        },
        ":hover::-webkit-scrollbar-thumb": {
          backgroundColor: "white",
        },
        "::-webkit-scrollbar-thumb": {
          borderRadius: "4px",
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
});
