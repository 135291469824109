import React, { useEffect, useState } from "react";
import { Box, Theme, useTheme } from "@mui/material";

export interface Props {
  children: React.ReactElement;
  size: number | string;
  underlayColor?: string;
}

export const ComponentUnderlay = ({ children, underlayColor, size }: Props) => {
  const theme = useTheme();
  const [newSize, setNewSize] = useState(() => {
    return changeSize(size);
  });

  useEffect(() => {
    setNewSize(changeSize(size));
  }, [size]);

  function changeSize(size: number | string) {
    if (typeof size === "string") {
      const sizeNumber = theme.custom.iconSize[size as keyof Theme["custom"]["iconSize"]].replace(/\D/g, "");
      return Number(sizeNumber) + theme.custom.iconUnderlayMargin;
    }
    if (typeof size === "number") {
      return size;
    }
    return 50;
  }

  return (
    <Box
      sx={{
        borderRadius: "50%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: newSize,
        height: newSize,
        backgroundColor: underlayColor ? underlayColor : theme.palette.background.paper,
      }}
      data-testid="component-underlay"
    >
      <Box sx={{ position: "absolute", display: "flex" }}>{children}</Box>
    </Box>
  );
};
