import { useState } from "react";
import { InformationCardProps } from "./types";
import { Alert, Typography, Box, alpha, Collapse } from "@mui/material";

export const InformationCard = ({
  title,
  content,
  onClose,
  actionElement,
  onClick,
  color,
  id,
  sx,
}: InformationCardProps) => {
  const [open, setOpen] = useState(true);
  return (
    <Collapse in={open} sx={sx}>
      <Alert
        variant="outlined"
        icon={false}
        onClose={(e) => {
          e.stopPropagation();
          onClose && onClose();
          setOpen(false);
        }}
        onClick={onClick}
        color={color}
        sx={(theme) => ({
          "transition": "0.2s",
          "width": "100%",
          ":hover": {
            backgroundColor: alpha(theme.palette[color].main, theme.custom.opacity.alert),
            cursor: "pointer",
          },
          ".MuiAlert-message": {
            flex: 1,
          },
        })}
        id={id}
        data-testid={id}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={(theme) => ({
            marginBottom: title && content ? theme.custom.gutterMargin : 0,
            width: "100%",
            flex: 1,
          })}
        >
          <>
            <Typography
              variant={title ? "h4" : "body1"}
              sx={(theme) => ({
                fontSize: theme.typography.body1,
                marginBottom: 0,
                fontWeight: title ? theme.typography.fontWeightBold : theme.typography.fontWeightMedium,
              })}
              data-testid={`${id || ""}-title`}
            >
              {title || content}
            </Typography>
            {typeof actionElement === "string" && actionElement && (
              <Typography
                sx={(theme) => ({
                  marginBottom: "0",
                  fontWeight: theme.typography.fontWeightMedium,
                })}
                data-testid={`${id || ""}-action`}
              >
                {actionElement}
              </Typography>
            )}
            {typeof actionElement === "object" && { actionElement }}
          </>
        </Box>

        {title && content && (
          <Typography sx={{ marginBottom: "0" }} variant="body1" data-testid={`${id || ""}-content`}>
            {content}
          </Typography>
        )}
      </Alert>
    </Collapse>
  );
};
