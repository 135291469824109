import { useRef, MutableRefObject, useEffect, useState, FocusEvent } from "react";
import React from "react";
import { DropListWithIconItem } from "@cede/types";
import { ValidationDropListProps } from "./types";
import {
  useTheme,
  Autocomplete,
  autocompleteClasses,
  TextField,
  Typography,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  Box,
  Skeleton,
} from "@mui/material";

export const ValidationDropList = (props: ValidationDropListProps) => {
  const theme = useTheme();
  const {
    selectTitle,
    items,
    renderItem,
    placeholder,
    validate,
    value,
    setValue,
    id,
    noOptionsText,
    sx,
    disableOptions,
    onBlur,
    onFocus,
    isLoading,
    timeout = 0,
  } = props;

  const SKELETON_IDENTIFIER = "skeleton";
  const [validationStatus, setValidationStatus] = React.useState<"error" | "success" | "warning">("success");
  const [helperText, setHelperText] = useState<string>("");
  // We start checking the input validity once the user start typing or lost focus
  const [firstLostFocusOrType, setFirstLostFocusOrType] = useState(false);
  const timeoutId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!firstLostFocusOrType) return;
    const validation = validate(value);
    if (!validation) return;
    setValidationStatus(validation.color as "error" | "success" | "warning");
    setHelperText(validation.message ?? "");
  }, [value, firstLostFocusOrType, items]);

  const inputRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;

  const [open, setOpen] = React.useState(false);

  const toggleModal = (forceState: boolean) => {
    const currentModalState = open;
    clearTimeout(timeoutId.current);

    const manageModalState = () => {
      setOpen(forceState === undefined ? (prev) => !prev : forceState);
    };

    if (currentModalState === forceState) return;
    if (timeout && !currentModalState) {
      timeoutId.current = setTimeout(manageModalState, timeout);
      return;
    }

    manageModalState();
  };

  const handleInputChange = (_event: unknown, newInputValue: string) => {
    setValue(newInputValue);
    if (newInputValue) setFirstLostFocusOrType(true);
  };

  // All options
  const options = items
    .map((item, i) => {
      return {
        label: `${item.label}`,
        img: "",
        type: "item",
        value: item.value,
        name: item.name,
        disabled: validate(item.value)?.color === "error",
        testId: `droplist-option-${i}`,
      };
    })
    .sort((a, b) => {
      if (a.disabled === b.disabled) return -(b.label[0] || "").localeCompare(a.label[0] || "");
      else if (a.disabled) return 1;
      else return -1;
    });

  // Loader
  if (isLoading) {
    options.push({
      label: "",
      img: "",
      type: SKELETON_IDENTIFIER,
      value: "",
      name: "",
      disabled: true,
      testId: `option-${SKELETON_IDENTIFIER}`,
    });
  }

  // Render the groups
  const renderGroup = (params: AutocompleteRenderGroupParams) => {
    return (
      <>
        <Typography
          variant="body2"
          sx={{
            paddingX: theme.custom.gutterMargin,
            paddingY: theme.custom.smallGutterMargin,
            backgroundColor: theme.palette.cedePaperDarkBackgroundColor,
            color: theme.palette.text.primary,
            textAlign: "left",
          }}
        >
          {params.group}
        </Typography>
        {params.children}
        <Box
          width={"100%"}
          sx={{
            backgroundColor: theme.palette.cedePaperDarkBackgroundColor,
          }}
        >
          {props.bottomElement}
        </Box>
      </>
    );
  };
  // Popup icon
  const popupIcon = (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={"popup-icon"}
    >
      <path
        d="M16.5 0.25H3.75C2.95435 0.25 2.19129 0.56607 1.62868 1.12868C1.06607 1.69129 0.75 2.45435 0.75 3.25V19C0.75 19.1989 0.829018 19.3897 0.96967 19.5303C1.11032 19.671 1.30109 19.75 1.5 19.75H15C15.1989 19.75 15.3897 19.671 15.5303 19.5303C15.671 19.3897 15.75 19.1989 15.75 19C15.75 18.8011 15.671 18.6103 15.5303 18.4697C15.3897 18.329 15.1989 18.25 15 18.25H2.25C2.25 17.8522 2.40804 17.4706 2.68934 17.1893C2.97064 16.908 3.35218 16.75 3.75 16.75H16.5C16.6989 16.75 16.8897 16.671 17.0303 16.5303C17.171 16.3897 17.25 16.1989 17.25 16V1C17.25 0.801088 17.171 0.610322 17.0303 0.46967C16.8897 0.329018 16.6989 0.25 16.5 0.25ZM8.25 1.75H12.75V8.5L10.9491 7.15C10.8192 7.05263 10.6613 7 10.4991 7C10.3368 7 10.1789 7.05263 10.0491 7.15L8.25 8.5V1.75ZM15.75 15.25H3.75C3.22326 15.2493 2.70572 15.388 2.25 15.6522V3.25C2.25 2.85218 2.40804 2.47064 2.68934 2.18934C2.97064 1.90804 3.35218 1.75 3.75 1.75H6.75V10C6.75 10.1393 6.78879 10.2758 6.86201 10.3943C6.93524 10.5128 7.04001 10.6085 7.16459 10.6708C7.28917 10.7331 7.42863 10.7595 7.56735 10.747C7.70608 10.7345 7.83857 10.6836 7.95 10.6L10.5 8.6875L13.0509 10.6C13.1805 10.6972 13.338 10.7498 13.5 10.75C13.6989 10.75 13.8897 10.671 14.0303 10.5303C14.171 10.3897 14.25 10.1989 14.25 10V1.75H15.75V15.25Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  );
  // Render the input
  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      error={validationStatus && validationStatus === "error"}
      helperText={validationStatus && validationStatus === "error" ? helperText : ""}
      data-testid={`${id ?? ""}-select-textfield`}
      {...params}
      inputRef={inputRef}
      placeholder={placeholder}
      InputProps={{
        ...params.InputProps,
      }}
    />
  );

  const renderOption = (_props: unknown, option: DropListWithIconItem) => {
    return option.type === SKELETON_IDENTIFIER ? (
      <Skeleton variant="text" sx={{ flex: 1, marginX: theme.custom.smallGutterMargin, height: "50px" }} />
    ) : (
      renderItem(option, () => {
        // On item click
        toggleModal(false);
        inputRef.current?.blur();
      })
    );
  };

  return (
    <Autocomplete
      id={id}
      data-testid={id}
      sx={{
        "flex": 1,
        // Disable popup icon rotation
        [`& .${autocompleteClasses.popupIndicator}`]: {
          transform: "none",
          paddingX: theme.custom.smallGutterMargin,
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderRadius: theme.custom.inputRadius.default,
        },
        ...sx,
      }}
      options={options}
      groupBy={() => selectTitle}
      noOptionsText={noOptionsText}
      // Disable the popup if there are no options
      freeSolo={!!disableOptions}
      renderGroup={renderGroup}
      getOptionDisabled={(option) => validate(option.value).color === "error"}
      popupIcon={popupIcon}
      disablePortal={true}
      renderOption={renderOption}
      ListboxProps={{
        style: {
          padding: 0,
        },
      }}
      open={open}
      clearOnBlur={false}
      inputValue={value}
      onInputChange={handleInputChange}
      renderInput={renderInput}
      onBlur={(event) => {
        setFirstLostFocusOrType(true);
        toggleModal(false);
        onBlur?.(event as FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>);
      }}
      onInput={() => toggleModal(true)}
      onFocus={(event) => {
        toggleModal(true);
        onFocus?.(event as FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>);
      }}
    />
  );
};
