import { CedeErrors } from "./types";

export enum ErrorPlaceholder {
  VAULT_AND_ACCOUNT = "{vault_and_account}",
  VAULT = "{vault}",
  ACCOUNT = "{account}",
}

export const cedeErrors: CedeErrors = {
  // transport related errors
  rpc: {
    parse: {
      code: -32700,
      message: "JSON parsing error",
      description: "{data.description}",
      documentation: "An error occurred on the server while parsing the JSON text",
    },
    invalidRequest: {
      code: -32600,
      message: "Invalid request object",
      description: "{data.description}",
      documentation: "The JSON sent is not a valid Request object",
    },
    methodNotFound: {
      code: -32601,
      message: "Method not found",
      description: "{data.description}",
      documentation: "The method does not exist / is not available",
    },
    invalidParams: {
      code: -32602,
      message: "Invalid method parameter(s)",
      description: "{data.description}",
      documentation: "Provided parameters do not match the method signature",
    },
    internal: {
      code: -32603,
      message: "Internal JSON-RPC error",
      description: "{data.description}",
      documentation: "Something went wrong in the communication engine of cede.store",
    },
  },

  defaultError: {
    code: 1000,
    message: "cede.store default error",
    description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error occurred`,
    documentation: "Something went wrong in cede.store",
  },

  // ccxt library specific errors
  ccxt: {
    baseError: {
      code: 3000,
      message: "CEX error",
      description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
      documentation: "The exchange has a system abnormality. The original exchange error is provided.",
    },
    requestExpired: {
      code: 3001,
      message: "Request expired",
      description: `${ErrorPlaceholder.ACCOUNT}: request expired. This happens when ccxt throttler drops pending requests`,
      documentation: "The request has expired",
    },
    // general: {
    //   cexError: {
    //     code: 3001,
    //     message: "CEX error",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "The exchange has a system abnormality. The original exchange error is provided.",
    //   },
    //   cexApiAuthError: {
    //     code: 3002,
    //     message: "CEX API authentication error",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "Invalid API key or permission denied",
    //   },
    //   cexNotAvailable: {
    //     code: 3003,
    //     message: "CEX is not available",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "Exchange is not available, please try again later",
    //   },
    //   cexOnMaintenance: {
    //     code: 3004,
    //     message: "CEX on maintenance",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "Exchange is on maintenance, please try again later",
    //   },
    //   invalidNonce: {
    //     code: 3005,
    //     message: "Invalid nonce",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation:
    //       "The API keys seem to be used by another app. The user should create new API keys and use them only for cede.store.",
    //   },
    //   notSupported: {
    //     code: 3006,
    //     message: "Not supported",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "This error is raised if the endpoint is not offered/not supported by the exchange API",
    //   },
    // },
    // order: {
    //   orderNotFound: {
    //     code: 3100,
    //     message: "Could not find the order",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "Raised when you are trying to fetch or cancel a non-existent order",
    //   },
    //   orderImmediatelyFillable: {
    //     code: 3101,
    //     message: "The order is immediately fillable",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation:
    //       "Raised when the order you're trying to place would immediately be triggered and filled, and not be placed on the order book",
    //   },
    //   orderNotFillable: {
    //     code: 3102,
    //     message: "The order is not fillable",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "Unable to fill this order",
    //   },
    //   invalidOrder: {
    //     code: 3103,
    //     message: "The order is invalid",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "Raised when the order you're trying to place is not valid",
    //   },
    //   duplicateOrderId: {
    //     code: 3104,
    //     message: "Two or more orders have identical ids",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "Raised when two or more orders have identical ids",
    //   },
    // },
    // request: {
    //   badRequest: {
    //     code: 3200,
    //     message: "Bad request",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "The request is malformed, the content of the request is not valid",
    //   },
    //   requestTimeout: {
    //     code: 3201,
    //     message: "Request timeout",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "The request timed out",
    //   },
    //   badResponse: {
    //     code: 3202,
    //     message: "Bad response",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "The response from the exchange is malformed",
    //   },
    //   rateLimitExceeded: {
    //     code: 3203,
    //     message: "Rate limit exceeded",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "The request rate limit has been exceeded",
    //   },
    //   argumentsRequired: {
    //     code: 3204,
    //     message: "Method requires some arguments",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "The method requires some arguments",
    //   },
    //   nullResponse: {
    //     code: 3205,
    //     message: "Null response",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "The response from the exchange is null",
    //   },
    //   ddosProtection: {
    //     code: 3206,
    //     message: "DDOS protection",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "The request has been rejected by the exchange due to DDOS protection",
    //   },
    //   badSymbol: {
    //     code: 3207,
    //     message: "Bad symbol",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "The symbol is not valid",
    //   },
    // },
    // account: {
    //   accountSuspended: {
    //     code: 3300,
    //     message: "Account suspended",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "The user account is suspended",
    //   },
    //   permissionDenied: {
    //     code: 3301,
    //     message: "Permission denied",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "The user doesn't have enough permission to perform the action",
    //   },
    //   insufficientFunds: {
    //     code: 3302,
    //     message: "Insufficient funds",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "The user doesn't have enough funds to perform the action",
    //   },
    //   invalidAddress: {
    //     code: 3303,
    //     message: "Invalid address",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "The address is not valid",
    //   },
    //   addressPending: {
    //     code: 3304,
    //     message: "Address pending",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "The address is pending",
    //   },
    //   cancelPending: {
    //     code: 3305,
    //     message: "Cancel pending",
    //     description: `${ErrorPlaceholder.ACCOUNT}: {error.message}`,
    //     documentation: "The cancel is pending",
    //   },
    // },
  },

  internal: {
    general: {
      badPassword: {
        code: 4000,
        message: "Bad password",
        description: "Bad password",
        documentation: "The password provided is incorrect",
      },
    },
    misc: {
      jobs: {
        fetchTransactionHistoryError: {
          code: 4100,
          message: "Transaction history cannot be fetched through the job",
          description: "An error happened while fetching the transaction history",
          documentation: "An error happened while fetching the transaction history",
        },
        getLastTxUpdateError: {
          code: 4101,
          message: "Last transaction update cannot be fetched through the job",
          description: "An error happened while getting the last transaction update",
          documentation: "An error happened while getting the last transaction update",
        },
      },
      cancelTrackingError: {
        code: 4102,
        message: "Tracking cannot be cancelled",
        description: "An error happened while cancelling the tracking",
        documentation: "An error happened while cancelling the tracking",
      },
    },
    vault: {
      accountAlreadyExists: {
        code: 4200,
        message: "Vault account already exists",
        description: "The exchange account has already been added to the vault",
        documentation: "The exchange account has already been added to the vault",
      },
      nameExists: {
        code: 4201,
        message: "Vault name already exists",
        description: `The vault ${ErrorPlaceholder.VAULT} already exists`,
        documentation: "Raised when trying to create the vault with same name as the existent vault",
      },
      nameCannotBeGenerated: {
        code: 4202,
        message: "Vault name cannot be generated",
        description: "The vault name cannot be generated",
        documentation: "Raised when there is an error in the vault name generation",
      },
      accountKeysInconsistent: {
        code: 4203,
        message: "Account keys inconsistent. They may not relate to the same account",
        description: "The Api Keys are not related to the same CEX account",
        documentation:
          "Raised when the user is trying to a second pair of API keys for the 2 key CEX which are not related to the same account",
      },
      cannotUnlockStorage: {
        code: 4204,
        message: "The storage cannot be unlocked",
        description: "Cede Store cannot unlock the storage",
        documentation: "Raised when the storage cannot be unlocked",
      },
      cannotPersisted: {
        code: 4205,
        message: "Vaults cannot persisted",
        description: "The vaults cannot be persisted",
        documentation: "Raised when the vaults cannot be persisted",
      },
      creationError: {
        code: 4206,
        message: "Vault creation error",
        description: `${ErrorPlaceholder.VAULT}: An error happened while creating the vault`,
        documentation: "Raised when an error happened while creating the vault",
      },
      deletionError: {
        code: 4207,
        message: "Vault deletion error",
        description: `${ErrorPlaceholder.VAULT}: An error happened while deleting the vault`,
        documentation: "Raised when an error happened while deleting the vault",
      },
      checkApiCredentialsError: {
        code: 4208,
        message: "API check credentials failure",
        description: `${ErrorPlaceholder.VAULT}: An error happened while checking the API credentials`,
        documentation: "Raised when an error happened while checking the API credentials when adding a new CEX",
      },
      updateApiCredentialsError: {
        code: 4209,
        message: "API update credentials failure",
        description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error happened while updating the API credentials`,
        documentation: "Raised when an error happened while updating the API credentials when updating a CEX",
      },
      updateCexSettingsError: {
        code: 4210,
        message: "Vault CEX update settings error",
        description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error happened while updating the CEX settings`,
        documentation: "Raised when an error happened while updating the CEX settings when updating a CEX",
      },
      accountCreationError: {
        code: 4211,
        message: "Vault account creation error",
        description: `${ErrorPlaceholder.VAULT}: An error happened while creating the account`,
        documentation: "Raised when an error happened while creating the account",
      },
      accountUpdateError: {
        code: 4212,
        message: "Vault account update error",
        description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error happened while updating the account`,
        documentation: "Raised when an error happened while updating the account",
      },
      globalUnlockError: {
        code: 4213,
        message: "Vault cannot be unlocked",
        description: "An error happened while unlocking the vaults",
        documentation: "Raised when an error happened while unlocking globally the vaults",
      },
      notWhitelisted: {
        code: 4214,
        message: "IP not whitelisted for API keys",
        description: "IP addresses weren't whitelisted. Please, whitelist them first.",
        documentation: "Raised when cede.store proxy IP was not whitelisted on the CEX side",
      },
      accountKeyAlreadyExists: {
        code: 4215,
        message: "API key already exists",
        description: "The API Key already exists",
        documentation:
          "Raised when the user is trying to update a key using one that already exists in the vault for the same CEX",
      },
      whitelistCheckFail: {
        code: 4216,
        message: "Whitelist check failed",
        description: "Whitelist check failed. Please try again later.",
        documentation: "Raised when the whitelist check failed",
      },
    },
  },

  // cede.store background errors
  cede: {
    general: {
      dispatcherDefaultError: {
        code: 5000,
        message: "Dispatcher default error",
        description: "An error occured while {data.humanizedAction}",
        documentation: "An error occured while executing the method.",
      },
      approvalNotFound: {
        code: 5001,
        message: "Approval not found",
        description: "The approval {data.approvalId} isn't found",
        documentation: "The approval isn't found. This can happen if the approval is not in pending approvals array",
      },
      vaultNotAllowedToAccess: {
        code: 5002,
        message: "Vault is not allowed to access",
        description: "You aren't allowed to access to this vault",
        documentation: "You aren't allowed to access to this vault",
      },
      accountNotPermission: {
        code: 5003,
        message: "Account does not have sufficient permissions to perform this action",
        description: `The account ${ErrorPlaceholder.ACCOUNT} doesn't have the permission to {data.permission} : ${ErrorPlaceholder.VAULT}`,
        documentation:
          "The account doesn't have the permission to perform this action. If the account is read-only, you can't perform trades/withdrawals",
      },
      notConnected: {
        code: 5005,
        message: "Not connected",
        description: "Connect with cede.store to perfom this request",
        documentation: "Connect with cede.store to perfom this request",
      },
      notInitialized: {
        code: 5006,
        message: "Not initialized",
        description: "The user must set up at least one vault in the cede.store first",
        documentation:
          "Raised when cede.store has been downloaded but not initialized. The user must set up at least one vault in the cede.store first",
      },
      notSupportedFeature: {
        code: 5008,
        message: `This feature is not supported by the ${ErrorPlaceholder.ACCOUNT}`,
        description: `This feature is not supported by the ${ErrorPlaceholder.ACCOUNT}`,
        documentation: "Verify if the feature is supported by the CEX",
      },
      userDeniedApproval: {
        code: 5009,
        message: "User denied approval",
        description: "User denied approval",
        documentation: "User denied approval",
      },
      accountInvalidSetOfPermissions: {
        code: 5010,
        message: "Invalid set of permissions",
        description: "The account has an invalid set of permissions",
        documentation: "The account has an invalid set of permissions",
      },
      methodNotAvailableForThisExchange: {
        code: 5011,
        message: "Method not available",
        description: "This method is not available on this exchange",
        documentation:
          "The provider was requested to perform an action on an exchange that does not support the action",
      },
      invalidPermissionsSetup: {
        code: 5012,
        message: "Invalid key permissions",
        description: "Missing API permissions, please reimport your API key with the valid permissions",
        documentation: "API key permissions aren't valid, you should add missing permissions to the key and try again",
      },
    },
    vault: {
      notFound: {
        code: 5100,
        message: "Vault not found",
        description: "The vault was not found",
        documentation: "The vault was not found. Verify the vauld id",
      },
      accountNotFound: {
        code: 5101,
        message: "Vault account not found",
        // TODO make sure the error handler puts accountId in the context
        description: `${ErrorPlaceholder.VAULT}: The account ${ErrorPlaceholder.ACCOUNT} was not found`,
        documentation: "The account was not found. Verify the account name",
      },
      fetchVaultsError: {
        code: 5102,
        message: "Vaults cannot be retrieved",
        description: "An error happened while retrieveing the vaults",
        documentation: "Raised when the vaults cannot be retrieved from cede.store",
      },
      generateAccountNameError: {
        code: 5103,
        message: "An error occured while generating the account name",
        description: "An error occured while generating the account name",
        documentation: "The generate account name method shouldn't fail",
      },
      validateAccountNameError: {
        code: 5104,
        message: "An error occured while validating the account name",
        description: "An error occured while validating the account name",
        documentation: "The validate account name method shouldn't fail",
      },
      updateAccountNameError: {
        code: 5105,
        message: "An error occured while updating the account name",
        description: "An error occured while updating the account name",
        documentation: "Verify if the old account name is valid because we are using it to update the account",
      },
      getAccountError: {
        code: 5106,
        message: "An error occured while getting the account",
        description: "An error occured while getting the account",
        documentation: "Verify if the account id is valid",
      },
      getAccountsError: {
        code: 5107,
        message: "An error occured while getting the accounts",
        description: "An error occured while getting the accounts",
        documentation: "Verify if the vault id is valid",
      },
      notOAuthAccount: {
        code: 5108,
        message: "The account is not an OAuth account",
        description: "The account is not an OAuth account",
        documentation:
          "This error is raised when we try to perform an action on an OAuth account and the account is not using OAuth",
      },
      accountAlreadyContainsApiKeys: {
        code: 5109,
        message: "The account already contains these API keys",
        description: "The account already contains these API keys",
        documentation:
          "The account already contains the API keys added for different permissions. The CEX requires different keys for different permissions.",
      },
    },
    exchange: {
      // public data, such as available tickers, tokens, etc.
      public: {
        fetchTickerFromTokenError: {
          code: 5201,
          message: "Cannot fetch ticker from token",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error happened while fetching tickers`,
          documentation: "Raised when the ticker for a given token symbol cannot be retrieved from the CEX",
        },
        serverDown: {
          code: 5204,
          message: "Exchange's server is down",
          description: `The exchange's server is down`,
          documentation: "Raised when the exchange server status is offline.",
        },
        underMaintenance: {
          code: 5205,
          message: "Exchange is under maintenance",
          description: `The exchange is under maintenance`,
          documentation: "Raised when the exchange server status is under maintenance.",
        },
        supportedTokensError: {
          code: 5206,
          message: "Supported tokens not found",
          description: `${ErrorPlaceholder.ACCOUNT}: The supported tokens were not found`,
          documentation: "Raised when getSupportedTokens returns an error.",
        },
        unavailableInThisCountry: {
          code: 5207,
          message: "The exchange is not available in this country",
          description: `${ErrorPlaceholder.ACCOUNT}: The exchange is not available in this country`,
          documentation: "Raised when the exchange is not available in this country",
        },
      },
      // fungible and non-fungible balances (tokens, nfts, fiat)
      balances: {
        getBalancesError: {
          code: 5210,
          message: "Cannot retrieve vault portfolio from CEX",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error happened while fetching the portfolio from the CEX`,
          documentation: "Raised when the portfolio cannot be retrieved from the CEX",
        },
        getNftFromCexError: {
          code: 5211,
          message: "NFTs cannot be fetched from CEX",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error happened while fetching the NFTs`,
          documentation: "Raised when the NFTs cannot be retrieved from the CEX",
        },
      },
      transactions: {
        getVaultTransactionsError: {
          code: 5220,
          message: "Cannot retrieve vault transactions",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error happened while fetching the vault transactions`,
          documentation: "Raised when the transactions cannot be retrieved from the CEX",
        },
      },
      deposit: {
        getDepositableTokensError: {
          code: 5230,
          message: "Cannot get depositable tokens",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error happened while fetching the depositable tokens`,
          documentation: "Raised when the depositable tokens cannot be retrieved from the CEX",
        },
        getDepositAddressError: {
          code: 5231,
          message: "Cannot get deposit address",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error happened while fetching the deposit address`,
          documentation: "Raised when the deposit address cannot be retrieved from the CEX",
        },
      },
      trade: {
        postOrderError: {
          code: 5240,
          message: "Error encountered while posting order",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error occured with the market order`,
          documentation: "Raised when the order cannot be posted to the CEX",
        },
        fetchMarketPairsError: {
          code: 5241,
          message: "Market pairs cannot be fetched",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error happened while fetching the market pairs`,
          documentation: "Raised when the market pairs cannot be retrieved from the CEX",
        },
        fetchAllTickersFromSymbolsError: {
          code: 5242,
          message: "Tickers cannot be fetched from symbols",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error happened while fetching the tickers`,
          documentation: "Raised when the tickers cannot be retrieved from the CEX",
        },
        orderNotFound: {
          code: 5243,
          message: "Order not found",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: The order {data.orderId} was not found`,
          documentation: "Raised when the order cannot be found on the CEX",
        },
        minAmountError: {
          code: 5244,
          message: "Minimum amount error",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: The amount should be greater than {data.minAmount}`,
          documentation: "Raised when the amount is too low to execute the trade",
        },
        tickerNotFound: {
          code: 5245,
          message: "Ticker not found",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: The ticker for {data.pairSymbol} was not found`,
          documentation: "Raised when the ticker cannot be found on the CEX",
        },
        invalidOrderRequest: {
          code: 5246,
          message: "Invalid order request",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: The order request is invalid: {data.error}`,
          documentation:
            "This error is triggered by invalid order requests. It could arise due to inappropriate data inputs, such as a price set to zero. To streamline error handling, we avoid generating overly specific errors for each scenario. This approach assumes that proper data validation is performed within the dApp, ensuring user inputs are correct.",
        },
        fetchMarketPriceError: {
          code: 5247,
          message: "Market rates cannot be fetched",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error happened while fetching market rates`,
          documentation: "Raised when market rates cannot be retrieved from the CEX",
        },
        getTradePathError: {
          code: 5248,
          message: "Trade path cannot be fetched",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error happened while fetching trade path`,
          documentation: "Raised when trade path cannot be retrieved from the CEX",
        },
      },
      network: {
        networkNotFound: {
          code: 5250,
          message: "Network not found",
          description: "The network {data.network} isn't found",
          documentation: "Raised when the network isn't found",
        },
        networkNotProvided: {
          code: 5251,
          message: "Network not provided",
          description: "The network is not provided",
          documentation: "Raised when the network is not provided, but required",
        },
        networksNotStandardized: {
          code: 5252,
          message: "Networks are not standardized",
          description: "The networks are not standardized",
          documentation: "Raised when the networks are not standardized",
        },
      },
      withdrawal: {
        tokenToDefiAddressError: {
          code: 5260,
          message: "Error encountered while withdrawing token to DeFi address",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error happened while withdrawing to a DeFi address`,
          documentation: "Raised when the withdrawal cannot be posted to DeFi address",
        },
        tokenToAnotherCexError: {
          code: 5261,
          message: "Error encountered while withdrawing token to another CEX",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: An error happened while withdrawing to another CEX`,
          documentation: "Raised when the withdrawal cannot be posted to the CEX",
        },
        notFound: {
          code: 5262,
          message: "Withdrawal not found",
          description: "The withdrawal with id {data.withdrawalId} isn't found",
          documentation: "Raised when the withdrawal isn't found",
        },
        infoError: {
          code: 5263,
          message: "Withdrawal info error",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: Withdrawal info not found`,
          documentation: "Raised when the withdrawal info cannot be retrieved from the CEX",
        },
        invalidAddress: {
          code: 5268,
          message: "Invalid address",
          description: `${ErrorPlaceholder.VAULT_AND_ACCOUNT}: Invalid address`,
          documentation: "Raised when the address is invalid",
        },
      },
      coinbase: {
        accountNotFound: {
          code: 5270,
          message: "Coinbase account not found",
          description: `${ErrorPlaceholder.ACCOUNT}: The account for currency {data.currency} was not found`,
          documentation: "Raised when the account for currency cannot be found on the CEX",
        },
      },
      exchangeInstance: {
        notFound: {
          code: 5293,
          message: "Exchange instance not found",
          description: "The exchange instance was not found",
          documentation: "Raised when the exchange instance was not found",
        },
      },
      authentication: {
        oauthFlowInterruptedByTheUser: {
          code: 5301,
          message: "Connection has been interrupted. Please try again.",
          description: "Connection has been interrupted. Please try again.",
          documentation:
            "Raised when the OAuth flow is interrupted by the user (e.g. user closes the authorization popup)",
        },
      },
      nft: {
        notSupportedNfts: {
          code: 5400,
          message: "NFTs are not supported",
          description: `${ErrorPlaceholder.ACCOUNT}: NFTs aren't supported.`,
          documentation: "NFTs aren't supported on the given exchange",
        },
      },
    },
  },
  api: {
    invalidRequest: {
      code: 6000,
      message: "Error encountered when sending a request to the api",
      description: "Request failed",
      documentation: "Raised when an API request failed",
    },
  },
};
