// ----------------------------------------------------------------------------

// PLEASE DO NOT EDIT THIS FILE, IT IS GENERATED AND WILL BE OVERWRITTEN:
// https://github.com/ccxt/ccxt/blob/master/CONTRIBUTING.md#how-to-contribute-code
// EDIT THE CORRESPONDENT .ts FILE INSTEAD

import { SdkErrorCodes, SdkErrorMessages } from "./errorsMetadata.js";
import { CedeSDKError } from "./default.js";
/**
 * @description Default error coming from the cede server API
 */
export class ApiError extends CedeSDKError {
    constructor(code = SdkErrorCodes.API_ERROR, message = SdkErrorMessages.API_ERROR) {
        super(code, message);
        this.name = "ApiError";
    }
}
/**
 * @description Thrown when the API request failed
 */
export class InvalidApiRequestError extends ApiError {
    constructor(errorMessage) {
        super(SdkErrorCodes.INVALID_API_REQUEST, SdkErrorMessages.INVALID_API_REQUEST(errorMessage));
        this.name = "InvalidApiRequestError";
    }
}
