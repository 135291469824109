import { forgeTokenURL, QueryKeys, WITHDRAWABLE_BALANCES_TTL, formatBalance } from "@cede/utils";
import { useDependencies } from "../../hooks/useDependencies";
import { BalancesQueriesResult, BalancesWithQueryKey } from "./types";
import { UseQueryOptions, UseQueryResult, useQueries } from "@tanstack/react-query";

const getTotalBalancesByAccountId = (balances: UseQueryResult<BalancesWithQueryKey, Error>[]) => {
  const result: BalancesQueriesResult = {
    totalBalances: {},
    droplistCurrenciesByAccountId: {},
    areBalancesLoading: false,
    areBalancesError: false,
  };

  if (!balances || !balances.length) return result;

  return balances.reduce((acc, query) => {
    if (query.isLoading || query.isFetching || query.isRefetching) {
      acc.areBalancesLoading = true;
    }

    if (query.isError) {
      acc.areBalancesError = true;
    }

    if (!query.data) return acc;

    const { accountId, balances: queryBalances } = query.data;

    if (queryBalances) {
      if (!acc.totalBalances[accountId]) {
        acc.totalBalances[accountId] = { total: 0, isLoading: false, isError: query.isError };
      }

      Object.entries(queryBalances).forEach(([tokenSymbol, asset]) => {
        if (!acc.droplistCurrenciesByAccountId[accountId]) {
          acc.droplistCurrenciesByAccountId[accountId] = [];
        }
        acc.totalBalances[accountId]!.total += asset.refTotalBalance;

        const searchableTerms = [tokenSymbol];

        if (asset.tokenName) {
          searchableTerms.push(asset.tokenName);
        }

        acc.droplistCurrenciesByAccountId[accountId]!.push({
          img: forgeTokenURL(tokenSymbol),
          label: tokenSymbol,
          value: tokenSymbol,
          balance: formatBalance(asset.freeBalance),
          freeBalance: asset.freeBalance,
          amount: asset.refFreeBalance?.toFixed(2),
          ...(searchableTerms && {
            searchableTerms,
          }),
        });
      });
    }

    return acc;
  }, result);
};

export const useWithdrawableBalances = (network?: string) => {
  const { useVaults, backgroundHandler, useAccounts } = useDependencies();
  const { activeVault } = useVaults();
  const { accountsIds } = useAccounts();

  const enableQueries = !!backgroundHandler?.isReady && !!activeVault && !!accountsIds;

  const { balances, totalBalancesByAccountId } = useQueries({
    queries: accountsIds.map<UseQueryOptions<BalancesWithQueryKey>>((accountId: string) => ({
      queryKey: [QueryKeys.WITHDRAWABLE_CURRENCIES, accountId, network],
      queryFn: async () => {
        const result = await backgroundHandler.withdrawableTokens({
          accountId,
          network,
        });

        return {
          accountId,
          balances: result,
        };
      },
      gcTime: WITHDRAWABLE_BALANCES_TTL,
      staleTime: WITHDRAWABLE_BALANCES_TTL,
      enabled: enableQueries,
    })),
    combine: (queries) => {
      return {
        balances: queries,
        totalBalancesByAccountId: getTotalBalancesByAccountId(queries),
      };
    },
  });

  return {
    balances,
    totalBalancesByAccountId,
  };
};
