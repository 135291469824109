import { Any } from "../fakeTypes";

export type WidgetEventMessage<T = Any> = {
  type: string;
  payload: T;
};

export enum WidgetWindowEvents {
  UPDATE_EXCHANGE = "update-exchange",
  UPDATE_PAIR = "update-pair",
}

export const isWidgetEventMessage = (message: Any): message is WidgetEventMessage => {
  return message && typeof message === "object" && "type" in message && "payload" in message;
};

export type WidgetEventUpdatePairPayload = {
  pairSymbol: string;
  precision: number;
} | null;

export type WidgetEventUpdateExchangePayload = {
  exchangeId: string;
  accountId: string;
} | null;
