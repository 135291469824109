import { DiscordIcon, HoveredIcon, LinkedInIcon, TwitterIcon } from "@cede/ds";
import {
  CEDE_STORE_URL,
  CEDE_STORE_WIDGET_DOCUMENTATION_URL,
  DISCORD_INVITE_URL,
  LINKEDIN_URL,
  TWITTER_URL,
} from "@cede/utils";
import { Box, Typography, useTheme } from "@mui/material";

export const Socials = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginTop: theme.custom.smallGutterMargin,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant={"body1"}
        color={theme.palette.text.secondary}
        sx={{
          "textDecoration": "underline",
          ":hover": {
            cursor: "pointer",
            color: theme.palette.text.primary,
          },
        }}
        component="a"
        href={CEDE_STORE_URL}
        target="_blank"
      >
        Discover the cede.store browser extension
      </Typography>
      <Typography
        variant={"body1"}
        color={theme.palette.text.secondary}
        sx={{
          "textDecoration": "underline",
          ":hover": {
            cursor: "pointer",
            color: theme.palette.text.primary,
          },
          "mt": theme.custom.smallGutterMargin,
        }}
        component="a"
        href={CEDE_STORE_WIDGET_DOCUMENTATION_URL}
        target="_blank"
      >
        Send widget documentation
      </Typography>
      <Typography
        component="p"
        variant={"body1"}
        color={theme.palette.text.primary}
        marginTop={theme.custom.mediumGutterMargin}
        fontWeight={theme.typography.fontWeightBold}
      >
        Join us on our social networks:
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="150px"
        marginTop={theme.custom.smallGutterMargin}
      >
        <HoveredIcon fill={theme.palette.text.primary} redirect={() => window.open(DISCORD_INVITE_URL)}>
          <DiscordIcon />
        </HoveredIcon>
        <HoveredIcon fill={theme.palette.text.primary} redirect={() => window.open(TWITTER_URL)}>
          <TwitterIcon />
        </HoveredIcon>
        <HoveredIcon fill={theme.palette.text.primary} redirect={() => window.open(LINKEDIN_URL)}>
          <LinkedInIcon />
        </HoveredIcon>
      </Box>
    </Box>
  );
};
