import { TransactionStatusCardLite } from "@cede/ds";
import { isCefiInfoTx } from "@cede/types";
import { formatBalance } from "@cede/utils";
import { useDependencies } from "../../../hooks";

export const TransactionStatus = () => {
  const { useSettings, useSelectedTransaction } = useDependencies();
  const transaction = useSelectedTransaction((state) => state.transaction);
  const isReceive = useSelectedTransaction((state) => state.isReceive);
  const isTrade = useSelectedTransaction((state) => state.isTrade);
  const isSend = useSelectedTransaction((state) => state.isSend);
  const fiatCurrency = useSettings((state) => state.currency);

  if (!transaction) return null;

  if (isSend && isCefiInfoTx(transaction.from)) {
    return (
      <TransactionStatusCardLite
        content={formatBalance(transaction.from.transaction.amount) + " " + transaction.from.transaction.tokenSymbol}
        amountValue={transaction.from.transaction.refAmount ?? 0}
        assetTokenSymbol={transaction.from.transaction.tokenSymbol}
        currency={fiatCurrency}
        transactionType={"send"}
      />
    );
  }

  if (isReceive && isCefiInfoTx(transaction.to)) {
    return (
      <TransactionStatusCardLite
        content={formatBalance(transaction.to.transaction.amount) + " " + transaction.to.transaction.tokenSymbol}
        amountValue={transaction.to.transaction.refAmount ?? 0}
        assetTokenSymbol={transaction.to.transaction.tokenSymbol}
        currency={fiatCurrency}
        transactionType={"receive"}
      />
    );
  }

  if (isTrade && isCefiInfoTx(transaction.from) && isCefiInfoTx(transaction.to)) {
    return (
      <TransactionStatusCardLite
        content={formatBalance(transaction.to.transaction.amount, {
          maxDigits: 10,
        })}
        amountValue={transaction.from.transaction.amount}
        assetTokenSymbol={transaction.to.transaction.tokenSymbol}
        cryptoCurrency={transaction.from.transaction.tokenSymbol}
        cryptoCurrencyTo={transaction.to.transaction.tokenSymbol}
        transactionType="trade"
      />
    );
  }

  return null;
};
