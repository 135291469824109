import GasIcon from "./asset/gasIcon.svg";
import { forwardRef } from "react";
import { Icon } from "../Icon";
import { SelectGasPriceProps } from "./types";
import { Box, IconButton, Typography } from "@mui/material";

export const SelectGasPrice = forwardRef(
  ({ icon, value, isActive = false, setIsActive, ...props }: SelectGasPriceProps, ref) => {
    return (
      <Box ref={ref} flex="1" display={"flex"} justifyContent="flex-end">
        <Box display="flex" alignItems="center">
          <Icon iconUrl={GasIcon} iconName={"gas-icon"} iconChildUrl={icon} />
          <Typography ml="11px">{value}</Typography>

          <IconButton
            onClick={() => setIsActive(!isActive)}
            sx={{
              transition: "transform 0.3s",
              transformOrigin: "center",
              transform: isActive ? "rotate(-180deg)" : "rotate(0)",
              ml: "16px",
            }}
            data-testid={`${props.testId || ""}-btn`}
          >
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.59 0.294922L6 4.87492L1.41 0.294922L0 1.70492L6 7.70492L12 1.70492L10.59 0.294922Z"
                fill="white"
                fillOpacity="0.56"
              />
            </svg>
          </IconButton>
        </Box>
      </Box>
    );
  },
);
