import { formatCurrencyAmount } from "@cede/utils";
import { CurrencyAmountFormatterProps, CurrencyAmountSizes } from "./types";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const getTypography = (size: CurrencyAmountSizes) => {
  const typographies = {
    small: "body2",
    medium: "h5",
    large: "h1",
  };

  return typographies[size];
};

export const CurrencyAmountFormatter = ({
  amount,
  colors,
  size,
  currency,
  local = "en-US",
  id,
  sx,
  hideBalance = false,
}: CurrencyAmountFormatterProps) => {
  const formattedNumber = formatCurrencyAmount(amount * (currency?.rate ?? 1), local, currency?.symbol, hideBalance);
  const separatorIndex: number = formattedNumber.search(/[,.]/);
  return (
    <Box data-testid={id} display={"inline-flex"}>
      <Typography
        typography={() => getTypography(size)}
        component="span"
        sx={{ color: colors.integer, ...(sx || {}) }}
        data-testid={`${id || ""}-integer`}
      >
        {separatorIndex === -1 ? formattedNumber : formattedNumber.slice(0, separatorIndex + 1)}
      </Typography>
      {separatorIndex !== -1 && (
        <Typography
          typography={() => getTypography(size)}
          component="span"
          sx={{ color: colors.decimal, ...(sx || {}) }}
          data-testid={`${id || ""}-decimal`}
        >
          {formattedNumber.slice(separatorIndex + 1)}
        </Typography>
      )}
    </Box>
  );
};
