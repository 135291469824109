import { Any } from "@cede/types";
import { Icon } from "../Icon";
import { IconTextProps } from "./types";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";

export const IconText = ({
  iconName,
  iconUrl,
  iconChildUrl,
  text,
  disabledText,
  iconSize = "medium",
  fontSize = "14px",
  sx,
  hover = true,
  textTag = "p",
  backgroundColor,
  isDisabled,
  isStateLoading = false,
  ...props
}: IconTextProps) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding={theme.custom.smallGutterMargin}
      sx={{
        "transition": "0.3s",
        ":hover": {
          backgroundColor: hover ? "rgba(117, 179, 255, 0.08)" : "transparent",
        },
        "backgroundColor": backgroundColor ?? "transparent",
        ...(Array.isArray(sx) ? sx : [sx]),
        ...(hover && { cursor: isDisabled ? "not-allowed" : "pointer" }),
        "filter": isDisabled ? "grayscale(1)" : "none",
      }}
      {...props}
      onClick={isDisabled ? undefined : props.onClick}
    >
      <Box display="flex" alignItems="center">
        <Icon iconUrl={iconUrl} iconName={iconName} iconSize={iconSize} iconChildUrl={iconChildUrl} />
        <Typography
          noWrap
          component={textTag as Any}
          color={!isDisabled ? theme.palette.text.primary : theme.palette.text.disabled}
          ml="10px"
          fontSize={fontSize}
        >
          {text}
        </Typography>
      </Box>

      {isStateLoading && <CircularProgress size={fontSize} />}
      {!isStateLoading && disabledText && (
        <Typography noWrap component={textTag as Any} color={theme.palette.text.disabled} ml="10px" fontSize={fontSize}>
          {disabledText}
        </Typography>
      )}
    </Box>
  );
};
