import { Button } from "@cede/ds";
import { SdkErrorCodes } from "@cede/types";
import { MainFooter } from "../../..";
import { useDependencies } from "../../../hooks/useDependencies";
import { useTheme } from "@mui/material";

/**
 * @description
 * Custom footer for the Onramp Money widget.
 */
export const OnrampMoneyCustomFooter = () => {
  const theme = useTheme();

  const { useBackgroundHandler, useCompletedTransaction } = useDependencies();
  const { transaction, errorCode, goTo } = useCompletedTransaction();

  if (!transaction) return null;

  const actionButtonLabel = () => {
    if (errorCode === SdkErrorCodes.EXCHANGE_INVALID_PERMISSION) return "Update permissions";
    if (errorCode === SdkErrorCodes.WHITELIST_CHECK_FAILED) return "Update whitelisted addresses";
    return null;
  };
  const { backgroundHandler } = useBackgroundHandler();

  const onClose = () => {
    backgroundHandler?.emit("widgetClosed");
  };

  return (
    <MainFooter>
      {actionButtonLabel() ? (
        <Button
          fullWidth
          data-testid="submit-btn"
          height={"tall"}
          variant={"contained"}
          onClick={goTo}
          sx={{
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
          {actionButtonLabel()}
        </Button>
      ) : (
        <Button
          fullWidth
          height={"tall"}
          variant={"text"}
          onClick={onClose}
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          Close
        </Button>
      )}
    </MainFooter>
  );
};
