import { ApiPermissions, ExchangeStatus } from "@cede/types";
import { TWO_KEYS_PERMISSIONS_WITH_PROXY, TWO_KEYS_PERMISSIONS_WITHOUT_PROXY } from "../constants";
import { advancedRandomId } from "../random";
import { CEDE_CEX_LABELS } from "./constants";

export const getCexLabel = (cexValue: string): string => {
  return CEDE_CEX_LABELS[cexValue] || cexValue;
};

/**
 * Computes the account id for a given cexId and key. The key can be the API key or the access token.
 */
export const computeExchangeInstanceId = (): string => {
  return advancedRandomId(5);
};

/**
 * Check if the permissions include some of the proxied permissions.
 */
export function hasPermissionsWithProxy(permissions: ApiPermissions[], cex: string): boolean {
  return permissions.some((permission) => TWO_KEYS_PERMISSIONS_WITH_PROXY[cex]?.includes(permission));
}

export function hasPermissionsWithoutProxy(permissions: ApiPermissions[], cex: string): boolean {
  return permissions.some((permission) => TWO_KEYS_PERMISSIONS_WITHOUT_PROXY[cex]?.includes(permission));
}

export function externalExchangeStatusToInternalExchangeStatus(externalStatus?: string): ExchangeStatus {
  for (const key in ExchangeStatus) {
    const internalExchangeStatus = ExchangeStatus[key as keyof typeof ExchangeStatus];
    if (internalExchangeStatus === externalStatus) {
      return internalExchangeStatus;
    }
  }
  return ExchangeStatus.Unknown;
}
