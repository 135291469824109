import { CefiServices } from "@cede/types";
import { CreditCardOutlined, TollOutlined } from "@mui/icons-material";

export const SERVICE_ICONS = {
  [CefiServices.CARD]: <CreditCardOutlined />,
  [CefiServices.STAKING]: <TollOutlined />,
};

function getServiceIcon(type: CefiServices) {
  return SERVICE_ICONS[type];
}

type Props = {
  type: CefiServices;
};

export function ServiceIcon({ type }: Props) {
  return getServiceIcon(type);
}
