import { useEffect } from "react";
import { ProviderHandler } from "@cede/types";
import providerHandlerFactory from "../utils/background/handlerFactory";
// @TODO: import from root path when we will be using vitest
import { CedeProvider, detectCedeProvider } from "@cedelabs/providers/src";
import { useQuery } from "@tanstack/react-query";

export type UseProviderProps = {
  onProviderNotFound?: () => void;
  onProviderFound?: (provider: ProviderHandler & CedeProvider) => void;
};

/**
 * This hook is only used in the client side for untrusted connections.
 */
export const useBackgroundHandler = ({ onProviderNotFound, onProviderFound }: UseProviderProps = {}) => {
  const { data: backgroundHandler, refetch: refetchProvider } = useQuery({
    queryKey: ["detectCedeProvider"],
    queryFn: async () => {
      const provider = await detectCedeProvider();

      if (!provider) {
        return null;
      }

      const backgroundHandler = providerHandlerFactory(provider);

      return backgroundHandler;
    },
    gcTime: Infinity,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (backgroundHandler) {
      onProviderFound?.(backgroundHandler);
    } else if (backgroundHandler === null) {
      onProviderNotFound?.();
    }
  }, [backgroundHandler]);

  return {
    backgroundHandler,
    refetchProvider,
  };
};
