import { Box, BoxProps, useTheme } from "@mui/material";

export type MainFooterProps = BoxProps;

export const MainFooter = ({ children, sx, ...boxProps }: MainFooterProps) => {
  const theme = useTheme();
  return (
    <Box
      paddingY={theme.custom.gutterMargin}
      {...boxProps}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        gap: theme.custom.gutterMargin,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
