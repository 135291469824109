import { ExternalLinkProps } from "./types";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, Box, useTheme } from "@mui/material";

export const ExternalLink = ({ label, url, inNewTab = true, color, onClick, ...props }: ExternalLinkProps) => {
  const theme = useTheme();
  const linkColor = color || theme.palette.primary.main;

  return (
    <Button
      onClick={onClick}
      {...props}
      sx={{
        textTransform: "none",
        ...props.sx,
      }}
    >
      <Box
        component="a"
        href={url}
        target={inNewTab ? "_blank" : ""}
        rel="noopener noreferrer"
        data-testid="anchor"
        sx={{
          "fontSize": "14px",
          "color": linkColor,
          "textDecoration": "none",
          "display": "inline-flex",
          "alignItems": "center",
          "&:hover": {
            color: linkColor,
          },
        }}
      >
        {label}
        <OpenInNewIcon
          sx={{
            height: "16px",
            width: "16px",
            ml: "5px",
          }}
        />
      </Box>
    </Button>
  );
};
