import { useRef } from "react";
import { VaultItem } from "@cede/types";
import { QueryKeys, generateRandomNumber } from "@cede/utils";
import { useDependencies } from "./useDependencies";
import { useQuery } from "@tanstack/react-query";

export type UseProviderEventsProps = {
  onUnlock?: () => void;
  onLock?: () => void;
  onAccountsChanged?: (vaultsPreview: VaultItem[]) => void;
  queryKey?: number | string;
};

export const useProviderEvents = ({ onUnlock, onLock, onAccountsChanged, queryKey }: UseProviderEventsProps = {}) => {
  const { useBackgroundHandler } = useDependencies();
  const { backgroundHandler } = useBackgroundHandler();
  const hasBeenUnlockedRef = useRef<boolean>(false);
  const queryKeyRef = useRef<number | string>(queryKey ?? generateRandomNumber(0, 1000000000));

  const onUnlockHandler = () => {
    if (!hasBeenUnlockedRef.current) {
      hasBeenUnlockedRef.current = true;
      onUnlock?.();
    }
  };

  const onLockHandler = () => {
    hasBeenUnlockedRef.current = false;
    onLock?.();
  };

  const onAccountsChangedHandler = (...args: unknown[]) => {
    onAccountsChanged?.(args as VaultItem[]);
  };

  useQuery({
    queryKey: [QueryKeys.PROVIDER_EVENTS_QUERY, queryKeyRef.current],
    queryFn: () => {
      if (!backgroundHandler) return;

      backgroundHandler.off("unlock", onUnlockHandler);
      backgroundHandler.off("lock", onLockHandler);
      backgroundHandler.off("accountsChanged", onAccountsChangedHandler);

      backgroundHandler.on("unlock", onUnlockHandler);
      backgroundHandler.on("lock", onLockHandler);
      backgroundHandler.on("accountsChanged", onAccountsChangedHandler);
    },
    enabled: !!backgroundHandler?.isReady,
    staleTime: Infinity,
    gcTime: Infinity,
  });
};
