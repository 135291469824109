import { useEffect, useMemo, useState } from "react";
import { ReactElement } from "react-markdown/lib/react-markdown";
import ccxt from "@cede/ccxt";
import { DropListWithIconItem } from "@cede/ds";
import {
  Any,
  AssetItemListProps,
  MarketPair,
  OrderSide,
  TradeForm,
  ViewEnvironment,
  userFacingMessages,
} from "@cede/types";
import { CEDE_CEX_LABELS, STORE_ROUTES, WIDGET_ROUTES, getErrorMessage, orderToTransactionMapper } from "@cede/utils";
import { useDebounceValue } from "../../../hooks/useDebounceFn";
import { useDependencies } from "../../../hooks/useDependencies";
import { getTradePathLabel } from "../../Send/hooks/utils";
import { AvailableAssets, UseTradeHook, UseTradeOpts } from "../types";
import { useRepeatTrade } from "./useRepeatTrade";
import { tradeTabs, useTradeTabs } from "./useTradeTabs";
import { AlertTypes, DroplistMissingPermission, generateFakeTradeTransactionTradeForm } from "@cedelabs/react-utils";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

export const useTrade: UseTradeHook = (opts?: UseTradeOpts) => {
  const {
    useLocation,
    useAlert,
    usePrice,
    useVaults,
    useSettings,
    useAccounts,
    useTradeApi,
    useTradeForm,
    useSupportedExchanges,
    useViewEnvironment,
    useBackgroundHandler,
    useSelectedTransaction,
    useLoading,
    useNavigate,
    useTradeMinAmount,
    useTradeFee,
    useMultipleTransactions,
    useTradeAmountComputation,
    useTradeEventDispatcher,
  } = useDependencies();

  /**
   * ************************ Utils ************************
   */
  const {
    tradeForm,
    dispatchForm,
    setFocusedInput,
    focusedInput,
    resetForm,
    addMax,
    resetAmounts,
    hasSelectedBothTokens,
  } = useTradeForm();

  // we need to debounce the amountFrom to avoid multiple requests while the user is typing
  const { debouncedValue: debouncedAmountFrom, isDebouncing } = useDebounceValue(tradeForm.amountFrom, 500);
  const config = tradeForm.initialProps;

  const {
    assetsFrom,
    assetsTo,
    underlyingMarkets,
    loadMarkets,
    marketRate,
    isLoadingBalances,
    isLoadingUnderlyingMarkets,
    tradePath,
    droplistDestinationTokensError,
    shouldUseTradeSquare,
    droplistDestinationTokensAreLoading,
    popularTokens,
  } = useTradeApi({
    selectedAccount: tradeForm.selectedAccount,
    dispatchForm,
    tokenFrom: tradeForm.tokenFrom,
    form: tradeForm,
  });
  const {
    fee,
    isLoading: feeIsLoading,
    preparedOrder,
  } = useTradeFee({
    tradeForm,
    debouncedAmountFrom,
    marketRate,
    shouldUseTradeSquare,
  });

  const { minAmount: minAmountFrom } = useTradeMinAmount({
    tradeForm,
    marketRate,
    shouldUseTradeSquare,
  });

  const {
    loadTransactions: loadMultipleTransactions,
    transactions,
    clearState: clearMultipleTransactions,
    isTxLoading: isMultipleTransactionsLoading,
  } = useMultipleTransactions({
    accountId: tradeForm.selectedAccount?.value || "",
    fromTokenAmount: tradeForm.amountFrom || "",
    tradePath,
  });

  const { computeAmountFrom, computeAmountTo } = useTradeAmountComputation({
    tradeForm,
    dispatchForm,
    marketRate,
    shouldUseTradeSquare,
    transactions,
  });

  const [enabledEventDispatcher, setEnabledEventDispatcher] = useState(false);
  useTradeEventDispatcher({ tradeForm, tradePath, enabled: enabledEventDispatcher });

  /**
   * ************************ State ************************
   */

  // we don't allow to trade to other tokens than the ones in the config, except if the token from is the token to trade
  const filteredAssetsTo = useMemo(() => {
    if (!config?.tokenSymbols || config?.tokenSymbols?.includes(tradeForm.tokenFrom?.value || "")) return assetsTo;

    return assetsTo.filter((asset) => config.tokenSymbols?.includes(asset.value));
  }, [assetsTo, config?.tokenSymbols, tradeForm.tokenFrom?.value]);

  // Split the assetsTo in 2 parts: the popular ones and the rest
  const derivedAssetsTo = useMemo(() => {
    const popularAssetsTo: AvailableAssets = [];
    const allAssetsTo: AvailableAssets = [];
    filteredAssetsTo.forEach((asset) => {
      if (popularTokens.includes(asset.value)) {
        popularAssetsTo.push(asset);
      } else {
        allAssetsTo.push(asset);
      }
    });
    const data: Record<string, AvailableAssets> = {};
    if (popularAssetsTo.length) data.popular = popularAssetsTo;
    if (allAssetsTo.length) data.rest = allAssetsTo;
    return data;
  }, [filteredAssetsTo, popularTokens]);

  const { droplistAccounts: availableAccounts } = useAccounts();
  const { shouldDisableCex, disabledCexText, isFeatureSupportedByCex, isSupportedExchangesLoading } =
    useSupportedExchanges();
  const { backgroundHandler } = useBackgroundHandler();
  const { environment } = useViewEnvironment();
  const setTransaction = useSelectedTransaction((state) => state.setTransaction);
  const setError = useSelectedTransaction((state) => state.setError);
  const addBigPendingRequest = useLoading((state) => state.addBigPendingRequest);
  const removeBigPendingRequest = useLoading((state) => state.removeBigPendingRequest);
  const setDescription = useLoading((state) => state.setDescription);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const appendAlert = useAlert((state) => state.append);

  const availableForTradeAccounts = useMemo(
    () => availableAccounts.filter((account) => isFeatureSupportedByCex(account.cexValue, "trade")),
    [availableAccounts],
  );

  const { assetFromLoading, assetToLoading } = useRepeatTrade({
    availableAccounts: availableForTradeAccounts,
    tradeForm,
    dispatchForm,
    useAlert,
    useLocation,
    assetsFrom,
    assetsTo,
    setFocusedInput,
    resetAmounts,
  });

  const { activeVault } = useVaults();
  const _navigate = useNavigate();
  const { selectedTab, setSelectedTab } = useTradeTabs();

  const [isSwitchIconHovered, setIsSwitchIconHovered] = useState(false);

  const assetBalance = useMemo(() => {
    const balance = assetsFrom.find((asset) => asset.value === tradeForm.tokenFrom?.value);

    return balance?.freeBalance ?? 0;
  }, [assetsFrom, tradeForm.tokenFrom]);

  const tokenFromPrice = usePrice(tradeForm.tokenFrom?.value || "");
  const tokenToPrice = usePrice(tradeForm.tokenTo?.value || "");
  const currency = useSettings((state) => state.currency);

  const warningMessage = useMemo(() => {
    if (
      tradeForm.selectedAccount &&
      !isSupportedExchangesLoading &&
      !isFeatureSupportedByCex(tradeForm.selectedAccount.cexValue, "trade")
    ) {
      return `${CEDE_CEX_LABELS[tradeForm.selectedAccount.cexValue]} will be supported soon`;
    }

    return "";
  }, [tradeForm.selectedAccount]);

  const isSubmitButtonActive = useMemo(() => {
    return (
      verifyTradeValidity(tradeForm, assetBalance, assetsFrom, minAmountFrom) && !feeIsLoading && preparedOrder !== null
    );
  }, [
    tradeForm.tokenFrom,
    tradeForm.tokenTo,
    tradeForm.amountFrom,
    tradeForm.amountTo,
    assetBalance,
    tradeForm.market,
    assetsFrom,
    minAmountFrom,
    feeIsLoading,
  ]);

  const formErrors = useMemo(() => {
    const errors: Record<string, ReactElement | string> = {};
    if (assetBalance < Number(minAmountFrom)) {
      errors["amountFrom"] = (
        <Typography component={"span"} color={"inherit"}>
          {`Your ${tradeForm.tokenFrom?.label} balance available is lower than the minimum required to make a trade. `}
          <Typography
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "inherit",
            }}
            component={"span"}
            onClick={() =>
              environment === ViewEnvironment.WIDGET
                ? backgroundHandler?.openTab({
                    route:
                      STORE_ROUTES.RECEIVE_ROUTE +
                      `?currency=${tradeForm.tokenFrom?.value}&accountId=${tradeForm.selectedAccount?.value}`,
                  })
                : navigate(STORE_ROUTES.RECEIVE_ROUTE, {
                    state: { currency: tradeForm.tokenFrom, account: tradeForm.selectedAccount },
                  })
            }
          >
            Refill {tradeForm.tokenFrom?.label}
          </Typography>
        </Typography>
      );
      return errors;
    }

    if (tradeForm.amountFrom === "0") return errors;

    if (tradeForm.amountFrom ? parseFloat(tradeForm.amountFrom.replace(/\s/g, "") as string) > assetBalance : false) {
      errors["amountFrom"] = "The from amount is higher than the balance available.";
    }

    if (
      minAmountFrom &&
      tradeForm.amountFrom &&
      ccxt.Precise.stringGt(minAmountFrom, tradeForm.amountFrom.replace(/\s/g, ""))
    ) {
      errors["amountFrom"] = "The from amount is under the minimum amount.";
    }

    return errors;
  }, [tradeForm.amountFrom, assetBalance, minAmountFrom]);

  const hasTokenTo = useMemo(
    () => assetsFrom.find((asset: DropListWithIconItem) => asset.value === tradeForm.tokenTo?.value),
    [assetsFrom, tradeForm.tokenTo?.value],
  );

  const { isSwitchable, disabledSwitchMessage } = useMemo(() => {
    if (tradeForm.tokenFrom && tradeForm.tokenTo && hasTokenTo) {
      return {
        isSwitchable: true,
        disabledSwitchMessage: "",
      };
    }

    if (hasSelectedBothTokens) {
      return {
        isSwitchable: false,
        disabledSwitchMessage: "You must select both tokens to switch.",
      };
    }

    if (tradeForm.tokenFrom && tradeForm.tokenTo && !hasTokenTo) {
      return {
        isSwitchable: false,
        disabledSwitchMessage: `You don't have ${tradeForm.tokenTo.label}`,
      };
    }

    return {
      isSwitchable: false,
      disabledSwitchMessage: "",
    };
  }, [tradeForm.tokenTo, hasTokenTo, hasSelectedBothTokens, tradeForm.tokenFrom]);

  const addCexRedirect = () => {
    if (opts?.addCex) {
      opts.addCex();
      return;
    }

    if (environment === ViewEnvironment.WIDGET) {
      backgroundHandler?.addCex({});
    }
  };

  /**
   * ************************ Public Functions ************************
   */

  const goBack = () => {
    return _navigate(-1);
  };

  const submitTrade = async () => {
    if (environment === ViewEnvironment.WIDGET) {
      setDescription(
        <>
          {userFacingMessages.WIDGETS.TRADE.TRANSACTION_LOADING_FIRST_LINE}
          <br />
          {userFacingMessages.WIDGETS.TRADE.TRANSACTION_LOADING_SECOND_LINE}
        </>,
      );

      if (shouldUseTradeSquare) {
        navigate(WIDGET_ROUTES.MULTIPLE_TRANSACTIONS);

        return;
      }

      if (backgroundHandler && preparedOrder && tradeForm.selectedAccount) {
        addBigPendingRequest();
        try {
          const order = await backgroundHandler.createOrder({
            accountId: tradeForm.selectedAccount.value,
            ...preparedOrder.createOrderRequest,
            metadata: {
              defiAddress: localStorage.getItem("defiAddress") ?? undefined,
            },
          });

          setTransaction(
            orderToTransactionMapper({
              ...order,
              accountId: tradeForm.selectedAccount.value,
              exchangeId: tradeForm.selectedAccount.cexValue,
            }),
          );
        } catch (error: Any) {
          setError(getErrorMessage(error), error?.code);
          setTransaction(generateFakeTradeTransactionTradeForm(tradeForm));
        } finally {
          setDescription(null);
          queryClient.invalidateQueries({
            queryKey: ["fetchPortfolioAssetPeriodicaly", tradeForm.selectedAccount.value, activeVault],
          });
          removeBigPendingRequest();
          navigate(WIDGET_ROUTES.COMPLETED_TRANSACTION_ROUTE);
        }
      }
      return;
    }
    const amountFrom = tradeForm.amountFrom?.replace(/\s/g, "") || "";
    const amountTo = tradeForm.amountTo?.replace(/\s/g, "") || "";
    // todo replace by constant the path
    return _navigate(STORE_ROUTES.CONFIRM_TRADE_ROUTE, {
      state: {
        tokenFrom: tradeForm.tokenFrom,
        tokenTo: tradeForm.tokenTo,
        amountFrom,
        amountTo,
        market: tradeForm.market,
        createOrderRequest: preparedOrder,
        account: tradeForm.selectedAccount, // TODO : update the location in confirm trade page
        vault: activeVault,
        rate: tradeForm.orderSide === "buy" ? marketRate.ask : marketRate.bid,
        orderSide: tradeForm.orderSide,
      },
    });
  };

  const switchToken = () => {
    if (
      tradeForm.tokenFrom &&
      tradeForm.tokenTo &&
      assetsFrom.map((asset: DropListWithIconItem) => asset.value === tradeForm.tokenTo?.value).includes(true)
    ) {
      dispatchForm({
        field: "amountFrom",
        value: "0",
      });
      dispatchForm({
        field: "amountTo",
        value: "0",
      });
      dispatchForm({
        field: "revertToken",
      });
    }
  };

  /**
   * ************************ Private Functions ************************
   */

  /**
   * @description Get the common market between the two tokens.
   * If there is no common market, we set the noCommonMarket flag to true to enable the trade square feature.
   */
  const getTheCurrentMarket = (newMarketsTokenFrom = underlyingMarkets) => {
    // find market from underlying markets
    let market: MarketPair | undefined;
    let orderSide: OrderSide | undefined;

    if (tradeForm.tokenFrom && tradeForm.tokenTo) {
      newMarketsTokenFrom.forEach((marketPair: MarketPair) => {
        if (marketPair.base === tradeForm.tokenFrom?.value && marketPair.quote === tradeForm.tokenTo?.value) {
          market = marketPair;
          orderSide = "sell";
        } else if (marketPair.base === tradeForm.tokenTo?.value && marketPair.quote === tradeForm.tokenFrom?.value) {
          market = marketPair;
          orderSide = "buy";
        }
      });
    }

    if (!market || !orderSide) {
      if (tradeForm.tokenFrom && tradeForm.tokenTo) {
        dispatchForm({
          field: "noCommonMarket",
          value: true,
        });
      }
      dispatchForm({
        field: "market",
        value: null,
      });
      dispatchForm({
        field: "orderSide",
        value: null,
      });
      return;
    }

    dispatchForm({
      field: "noCommonMarket",
      value: false,
    });

    dispatchForm({
      field: "market",
      value: market,
    });

    dispatchForm({
      field: "orderSide",
      value: orderSide,
    });
  };

  function verifyTradeSquareValidity() {
    return shouldUseTradeSquare && tradePath?.length && !isMultipleTransactionsLoading && transactions.length;
  }

  function verifyTradeValidity(
    tradeForm: TradeForm,
    assetBalance: number,
    portfolioAssets: DropListWithIconItem[] | AssetItemListProps[],
    minAmountFrom: string | undefined,
  ): boolean {
    return !!(
      tradeForm.tokenFrom &&
      tradeForm.tokenTo &&
      tradeForm.amountFrom &&
      tradeForm.amountFrom === debouncedAmountFrom && // check if the debounced value is up to date
      tradeForm.amountTo &&
      assetBalance &&
      (tradeForm.market || verifyTradeSquareValidity()) &&
      portfolioAssets.map((asset: DropListWithIconItem) => asset.label === tradeForm.tokenFrom?.label).includes(true) &&
      parseFloat(tradeForm.amountFrom.replace(/\s/g, "") as string) <= assetBalance &&
      parseFloat(tradeForm.amountFrom.replace(/\s/g, "") as string) > 0 &&
      minAmountFrom &&
      ccxt.Precise.stringLe(minAmountFrom, tradeForm.amountFrom.replace(/\s/g, ""))
    );
  }

  const _clearTokenToIfNotAvailable = () => {
    // Check if the assetsTo contain the tokenTo, and if not, clear the value
    if (
      tradeForm.tokenTo &&
      !filteredAssetsTo.find((asset: DropListWithIconItem) => asset.value === tradeForm.tokenTo?.value)
    ) {
      dispatchForm({
        field: "tokenTo",
        value: null,
      });
    }
  };

  /**
   * ************************ Effects ************************
   */

  useEffect(() => {
    _clearTokenToIfNotAvailable();
  }, [filteredAssetsTo]);

  useEffect(() => {
    getTheCurrentMarket();
  }, [tradeForm.tokenTo, underlyingMarkets]);

  useEffect(() => {
    if (!tradeForm.tokenFrom) return;
    computeAmountTo();
  }, [marketRate, tradeForm.orderSide, tradeForm.amountFrom, shouldUseTradeSquare, transactions]);

  useEffect(() => {
    if (focusedInput.current === "amountTo") {
      computeAmountFrom();
    }
  }, [tradeForm.amountTo]);

  useEffect(() => {
    resetForm(true);
    loadMarkets();
  }, [tradeForm.selectedAccount]);

  /** Trade path */

  useEffect(() => {
    if (shouldUseTradeSquare && tradeForm.tokenTo) {
      if (tradePath && tradePath[0]) {
        appendAlert(
          userFacingMessages.WIDGETS.TRADE_SQUARE.PERFORM_TRADE_PATH(
            getTradePathLabel(tradePath),
            tradeForm.tokenTo?.label || "",
          ),
          AlertTypes.INFO,
        );
        return;
      }
    }
  }, [shouldUseTradeSquare, tradeForm.tokenTo, tradePath]);

  useEffect(() => {
    if (droplistDestinationTokensError) {
      appendAlert(getErrorMessage(droplistDestinationTokensError.message), AlertTypes.ERROR);
    }
  }, [droplistDestinationTokensError]);

  /**
   * Compute the transactions each time the trade path or the amount from are updated
   * It will be used to compute the amountTo and the fees
   */
  useEffect(() => {
    // clear the existing transactions in the store when the trade path is updated
    clearMultipleTransactions();
    if (!tradePath || tradeForm.amountFrom === "0" || !shouldUseTradeSquare) return;
    loadMultipleTransactions();
  }, [tradePath, debouncedAmountFrom, shouldUseTradeSquare]);

  useEffect(() => {
    // we need to clear the existing transactions in the store when the trade page is mounted
    clearMultipleTransactions();
    // We need to reset the form when the trade page is mounted
    resetForm(false);

    setEnabledEventDispatcher(true);
  }, []);
  /**
   * ************************ Return ************************
   */

  return {
    goBack,
    tabs: tradeTabs,
    selectedTab,
    setSelectedTab,
    availableAccounts: availableForTradeAccounts,
    portfolioAssets: assetsFrom,
    tradeAbleAsset: derivedAssetsTo,
    tradeForm,
    tradePath,
    dispatchForm,
    tokenFromPrice,
    tokenToPrice,
    marketRate,
    currency,
    switchToken,
    isSwitchable,
    isSwitchIconHovered,
    setIsSwitchIconHovered,
    disabledSwitchMessage,
    assetFromLoading,
    assetToLoading: shouldUseTradeSquare
      ? droplistDestinationTokensAreLoading
      : assetToLoading || isLoadingUnderlyingMarkets,
    isSubmitButtonActive,
    cexFeeIsLoading: feeIsLoading || isDebouncing,
    minAmountFrom,
    addMax: () => addMax(assetBalance),
    assetBalance,
    warningMessage,
    addCexRedirect,
    submitTrade,
    setFocusedInput,
    fee,
    formErrors,
    isLoadingBalances,
    isLoadingUnderlyingMarkets,
    vaultName: activeVault?.name,
    shouldDisableCex: shouldDisableCex("trade"),
    disabledCexText: disabledCexText("trade", DroplistMissingPermission),
    /**
     *  TODO: implement the reverse computation of the multiple transactions to allow the user to update the amountTo
     * when the trade square feature is active
     */
    allowUserInputAmountTo: !shouldUseTradeSquare,
  };
};
