import ccxt from "@cede/ccxt";
import { DropListWithIconItem, MarketRate, MinAmounts } from "@cede/types";
import { getCurrentRate, getErrorMessage } from "@cede/utils";
import { AlertTypes, useDependencies } from "..";
import { useQuery } from "@tanstack/react-query";

type MultipleTransactionsMinAmountProps = {
  enabled: boolean;
  accountId: string;
  fromToken: string;
  toToken: string;
  isInternalTransfer?: boolean;
  network?: DropListWithIconItem<{
    withdrawMin?: string;
    withdrawFee?: string;
  }>;
  activeWithdrawal: boolean;
};

/**
 * @description Get the biggest min amount for the orders and the withdrawal in the original currency
 *
 */
export const useMultipleTransactionsMinAmount = ({
  enabled,
  accountId,
  network,
  fromToken,
  toToken,
  isInternalTransfer = false,
  activeWithdrawal,
}: MultipleTransactionsMinAmountProps) => {
  const { backgroundHandler, useAlert, useNetworks, useTradePath } = useDependencies();
  const { tradePath, isLoading: tradePathIsLoading } = useTradePath({
    enabled,
    accountId,
    fromToken,
    toToken,
    network: network?.value,
    activeWithdrawal,
  });
  const appendAlert = useAlert((state) => state.append);
  const { droplistNetworks: networksForDestinationCurrency } = useNetworks({
    toDeposit: false,
    toWithdraw: true,
    accountId,
    tokenSymbol: toToken, // in order to get the withdrawMin and withdrawFee
    isInternalTransfer,
  });

  const _computeBiggestMinAmount = async () => {
    if (!tradePath) return "0";
    try {
      const safeMargin = "0.2";
      const networkForDestinationCurrency = networksForDestinationCurrency.find(
        (destinationCurrencyNetwork) => destinationCurrencyNetwork.value === network?.value,
      );
      let _minAmounts = [
        ccxt.Precise.stringAdd(
          networkForDestinationCurrency?.withdrawMin || "0",
          networkForDestinationCurrency?.withdrawFee || "0",
        ),
      ];

      // We need to reverse the trade path in order to start from the final currency
      // and convert through the iterations to the first one
      for (const tradePathData of [...tradePath].reverse()) {
        const { pairSymbol, orderSide } = tradePathData;

        const marketRate: MarketRate = await backgroundHandler.getMarketRate({
          accountId,
          pairSymbol: pairSymbol,
        });

        const price = getCurrentRate(marketRate, orderSide);
        // We compute all the min amounts in the current currency
        // We do the opposite operation regarding the classic order since we are reverting the order side
        // If the orderSide is "buy" we need to multiply the min amount by ask price of the market
        // If the orderSide is "sell" we need to divide the min amount by bid price of the market
        if (orderSide === "buy") {
          _minAmounts = _minAmounts.map((minAmount) => ccxt.Precise.stringMul(minAmount, price));
        } else {
          _minAmounts = _minAmounts.map((minAmount) => ccxt.Precise.stringDiv(minAmount, price));
        }
        // We add the min trade amount to the array
        // We do it at the end of the loop to avoid converting the min trade amount in the current currency
        // because it's already in the current currency
        const minTradeAmount: MinAmounts = await backgroundHandler.getMinAmounts({
          accountId,
          pairSymbol,
          orderSide,
          price,
        });

        _minAmounts.push(orderSide === "sell" ? minTradeAmount.minBaseAmount : minTradeAmount.minQuoteAmount);
      }
      const maxMinAmount = String(Math.max(..._minAmounts.map((minAmount) => parseFloat(minAmount))));
      const finalMinAmount = ccxt.Precise.stringAdd(maxMinAmount, ccxt.Precise.stringMul(maxMinAmount, safeMargin));
      return finalMinAmount;
    } catch (error) {
      appendAlert(getErrorMessage(error), AlertTypes.ERROR);
      return "0";
    }
  };

  const { data: minAmount, isFetching } = useQuery({
    queryKey: ["minAmount", tradePath, accountId],
    queryFn: _computeBiggestMinAmount,
    enabled:
      !!backgroundHandler?.isReady &&
      !!tradePath &&
      tradePath.length > 0 &&
      enabled &&
      (!activeWithdrawal || networksForDestinationCurrency.length > 0),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return {
    minAmount,
    isLoading: isFetching || tradePathIsLoading,
    tradePath: tradePath || [],
  };
};
