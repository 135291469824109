import { ProviderHandler, SendWidgetProps, TradeWidgetProps } from "@cede/types";
import type { DefaultDependenciesWithFeatures } from "../contexts/dependencies/dependencies";
import { CedeProvider } from "@cedelabs/providers/src";
import { useDependencies as useDependenciesUtil } from "@cedelabs/react-utils";

export function useDependencies<
  T extends {
    backgroundHandler: ProviderHandler & CedeProvider;
    widgetProps: SendWidgetProps | TradeWidgetProps;
    setBackgroundHandler: React.Dispatch<(ProviderHandler & CedeProvider) | undefined>;
  },
>() {
  return useDependenciesUtil<T & DefaultDependenciesWithFeatures>();
}
