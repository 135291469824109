import { TutorialInfoProps } from "./types";
import { Box, useTheme } from "@mui/material";

export function TutorialInfo({ children }: TutorialInfoProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.background.darkPaper,
        position: "relative",
        padding: `20px ${theme.custom.gutterMargin}`,
        borderRadius: "16px",
        textAlign: "left",
      }}
    >
      <Box
        sx={{
          "& p": {
            color: theme.palette.text.disabled,
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
