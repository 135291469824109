import { PublicAccount, VaultItem } from "@cede/types";

export enum AlertTypes {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

export type AlertItem = {
  id: string;
  message: string;
  type: AlertTypes;
  vault?: VaultItem;
  account?: PublicAccount;
  onClick?: VoidFunction;
};

export type AlertData = {
  vault?: VaultItem;
  account?: PublicAccount;
  customMsTimeout?: number;
  noTimeout?: boolean;
};

export type UseAlertState = {
  alertQueue: AlertItem[];
  append: (message: string, type: AlertItem["type"], data?: AlertData, onClick?: VoidFunction) => void;
  remove: (id: string) => void;
  removeByMessage: (message: string) => void;
};
