import { useMemo } from "react";
import { DependenciesContext } from "./context";
import { reactSdkDefaultDependencies } from "./dependencies";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export type CedeDependenciesProviderProps<T> = {
  children: React.ReactNode;
  dependencies?: T;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export function DependenciesProvider<T extends Record<string, unknown>>({
  children,
  dependencies,
}: CedeDependenciesProviderProps<T>) {
  const finalDependencies = useMemo(() => {
    return {
      ...reactSdkDefaultDependencies,
      ...dependencies,
    };
  }, [dependencies]);

  return (
    <QueryClientProvider client={queryClient}>
      <DependenciesContext.Provider value={finalDependencies}>{children}</DependenciesContext.Provider>
    </QueryClientProvider>
  );
}
