// ----------------------------------------------------------------------------

// PLEASE DO NOT EDIT THIS FILE, IT IS GENERATED AND WILL BE OVERWRITTEN:
// https://github.com/ccxt/ccxt/blob/master/CONTRIBUTING.md#how-to-contribute-code
// EDIT THE CORRESPONDENT .ts FILE INSTEAD

export var CustomThrottlePriority;
(function (CustomThrottlePriority) {
    CustomThrottlePriority[CustomThrottlePriority["HIGH"] = 1] = "HIGH";
    CustomThrottlePriority[CustomThrottlePriority["MEDIUM"] = 2] = "MEDIUM";
    CustomThrottlePriority[CustomThrottlePriority["LOW"] = 3] = "LOW";
})(CustomThrottlePriority || (CustomThrottlePriority = {}));
