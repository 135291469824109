import { Icon } from "@cede/ds";
import { cropTextInMiddle, forgeTokenURL } from "@cede/utils";
import { SingleTransactionProps } from "./type";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Typography, useTheme } from "@mui/material";

export const SingleTransaction = ({ transaction, number, isLast }: SingleTransactionProps) => {
  const theme = useTheme();

  const TopLine = () => {
    if (transaction.type === "order") {
      return (
        <>
          {transaction.from.amount}{" "}
          <Icon
            iconUrl={forgeTokenURL(transaction.from.tokenSymbol)}
            iconName={transaction.from.tokenSymbol}
            sx={{ ml: "12px", mr: "4px" }}
            iconSize={"medium"}
          />{" "}
          {transaction.from.tokenSymbol}
          <ArrowForwardIcon
            sx={{ mx: "5px", color: theme.palette.text.secondary, width: "0.7em", height: "0.7em" }}
          />{" "}
          {transaction.to.amount}{" "}
          <Icon
            iconUrl={forgeTokenURL(transaction.to.tokenSymbol)}
            iconName={transaction.to.tokenSymbol}
            iconSize={"medium"}
            sx={{ ml: "12px", mr: "4px" }}
          />{" "}
          {transaction.to.tokenSymbol}
        </>
      );
    }

    if (transaction.type === "withdrawal") {
      return (
        <>
          Send {transaction.from.amount}{" "}
          <Icon
            iconUrl={forgeTokenURL(transaction.from.tokenSymbol)}
            iconName={transaction.from.tokenSymbol}
            sx={{ mx: "4px" }}
            iconSize={"medium"}
          />{" "}
          {transaction.from.tokenSymbol} to {transaction.to.network?.label} Network
          <Icon
            iconUrl={transaction.to.network?.img || ""}
            iconName={transaction.from.tokenSymbol}
            iconSize={"medium"}
            sx={{ ml: "12px", mx: "4px" }}
          />
        </>
      );
    }

    return null;
  };

  const BottomLine = () => {
    if (transaction.type === "order") {
      return (
        <>
          Trade on {transaction.account?.label}{" "}
          <Icon
            iconUrl={transaction.account?.img || ""}
            iconName={"Binance"}
            customSize={"10px"}
            sx={{ ml: "8px", mr: "2px" }}
          />
        </>
      );
    }

    if (transaction.type === "withdrawal") {
      return (
        <>
          From {transaction.account?.label} to {cropTextInMiddle(transaction.to.address || "", 8)} on{" "}
          {transaction.to.network?.label} Network
        </>
      );
    }

    return null;
  };

  return (
    <Box width="100%">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            "display": "flex",
            "alignItems": "center",
            "justifyContent": "center",
            "borderRadius": "100%",
            "backgroundColor": transaction.isExecuted ? theme.palette.primary.main : theme.palette.text.secondary,
            "height": "30px",
            "width": "30px",
            "color": theme.palette.background.darkPaper,
            "fontWeight": "bold",
            "position": "relative",
            "&::before": {},
            "flexShrink": 0,
          }}
        >
          {number}
        </Typography>
        <Box
          sx={{
            paddingLeft: theme.custom.smallGutterMargin,
          }}
        >
          <Typography
            sx={{
              flexWrap: "wrap",
              borderRadius: "100%",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
              lineHeight: "1.2",
            }}
          >
            <TopLine />
          </Typography>
          <Typography
            sx={{
              borderRadius: "100%",
              display: "flex",
              alignItems: "center",
              fontSize: "12px",
              color: theme.palette.text.secondary,
              mt: "2px",
            }}
          >
            <BottomLine />
          </Typography>
        </Box>
      </Box>
      {!isLast && (
        <Box
          sx={{
            height: "32px",
            width: "2px",
            backgroundColor: transaction.isExecuted ? theme.palette.primary.main : theme.palette.text.secondary,
            ml: "13px",
            my: "10px",
          }}
        ></Box>
      )}
    </Box>
  );
};
