import { useMemo } from "react";
import { VaultItem } from "@cede/types";
import { QueryKeys } from "@cede/utils";
import { useDependencies } from "./useDependencies";
import { useQuery } from "@tanstack/react-query";

export const useVaults = () => {
  const { useBackgroundHandler, useProviderEvents, useLoginStore } = useDependencies();
  const { isLoggedIn } = useLoginStore();
  const { backgroundHandler } = useBackgroundHandler();

  const {
    data: fetchedVaults,
    refetch: refetchVaults,
    isLoading: areVaultsLoading,
  } = useQuery<VaultItem[] | undefined, Error>({
    queryKey: [QueryKeys.VAULTS_QUERY_KEY],
    queryFn: async () => {
      try {
        return await backgroundHandler?.vaults();
      } catch {
        return [];
      }
    },
    enabled: !!backgroundHandler?.isReady && isLoggedIn,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  useProviderEvents({
    onAccountsChanged: () => refetchVaults(),
    onLock: () => {
      refetchVaults();
    },
    onUnlock: () => {
      refetchVaults();
    },
    queryKey: QueryKeys.VAULTS_EVENTS_QUERY_KEY,
  });

  const { activeVault, vaults, vaultsById } = useMemo(() => {
    if (!fetchedVaults) return { vaults: [], activeVault: null, vaultsById: {} };

    return {
      vaults: fetchedVaults,
      activeVault: fetchedVaults.find((vault) => vault.isActive) || null,
      vaultsById: fetchedVaults.reduce((acc, vault) => {
        acc[vault.id] = vault;
        return acc;
      }, {} as Record<string, VaultItem>),
    };
  }, [fetchedVaults]);

  return {
    refetchVaults,
    areVaultsLoading,
    activeVault,
    vaultsById,
    vaults,
  };
};
