import { isCefiInfoTx, userFacingMessages } from "@cede/types";
import { firstLetterToUpperCase } from "@cede/utils";
import { useDependencies } from "../../hooks";
import { Typography, useTheme } from "@mui/material";

export const ConfirmEmail = () => {
  const theme = useTheme();
  const { useSelectedTransaction } = useDependencies();

  const { transaction, isSend } = useSelectedTransaction(({ transaction, isSend }) => ({
    transaction,
    isSend,
  }));

  if (!transaction || !isSend) return null;

  const from = transaction.from;

  if (!isCefiInfoTx(from)) return null;

  const exchangeId = from.account.exchangeId;

  return (
    <Typography
      data-testid={"confirm-email"}
      fontSize={"12px"}
      fontWeight={"bold"}
      textAlign={"center"}
      color={theme.palette.warning.main}
      width={"100%"}
    >
      {userFacingMessages.SEND.CONFIRM_EMAIL(firstLetterToUpperCase(exchangeId))}
    </Typography>
  );
};
