import React from "react";
import { Logo, LogoSizeEnum } from "@cede/ds";
import { BackButton } from "./components/BackButton";
import { Title } from "./components/Title";
import { create } from "zustand";

interface StoreState {
  leftSide?: React.ReactElement | null;
  center?: React.ReactElement | null;
  rightSide?: React.ReactElement | null;
  isDisplayed: boolean;
}

interface ZustandNavBarStore extends StoreState {
  setLeftSide: (component: React.ReactElement | null) => void;
  setCenter: (component: React.ReactElement | null) => void;
  setRightSide: (component: React.ReactElement | null) => void;
  setIsDisplayed: (newValue: boolean) => void;
  toggleDisplay: () => void;
  setLogo: (isLoading?: boolean) => void;
  setGoBack: (onClick: () => void) => void;
  setTitle: (title: string) => void;
  setGeneral: ({ leftSide, center, rightSide, isDisplayed }: StoreState) => void;
}

export const useNavBar = create<ZustandNavBarStore>((set) => ({
  leftSide: null,
  center: null,
  rightSide: null,
  isDisplayed: false,
  setLeftSide: (component: React.ReactElement | null) => set(() => ({ leftSide: component })),
  setCenter: (component: React.ReactElement | null) => set(() => ({ center: component })),
  setRightSide: (component: React.ReactElement | null) => set(() => ({ rightSide: component })),
  setIsDisplayed: (newValue: boolean) => set(() => ({ isDisplayed: newValue })),
  toggleDisplay: () => set((state: StoreState) => ({ isDisplayed: !state.isDisplayed })),
  setLogo: (isLoading?: boolean) =>
    set(() => ({
      leftSide: <Logo size={LogoSizeEnum.SMALL} isLoading={isLoading} />,
    })),
  setGoBack: (onClick: () => void) => set(() => ({ leftSide: <BackButton onClick={onClick} /> })),
  setTitle: (title: string) => set(() => ({ center: <Title title={title} /> })),
  setGeneral: ({ leftSide = null, center = null, rightSide = null, isDisplayed }: StoreState) =>
    set(() => ({
      leftSide,
      center,
      rightSide,
      isDisplayed,
    })),
}));
