import { Button } from "../Button";
import { AddNewCexButtonProps } from "./types";
import { useTheme } from "@mui/material";

export const AddNewCexButton = (props: AddNewCexButtonProps) => {
  const theme = useTheme();
  // @TODO: Add this to a SVG sprite so we don't lose tracking of our SVGs by centralizing them
  return (
    <Button
      data-testid="add-new-cex-button"
      variant="text"
      onClick={props.onClick}
      sx={{
        gap: theme.custom.smallGutterMargin,
        display: "flex",
        marginTop: theme.custom.smallGutterMargin,
        marginLeft: "auto",
        marginRight: "auto",
        color: theme.palette.primary.main,
      }}
    >
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="currentColor" />
      </svg>
      Add new CEX
    </Button>
  );
};
