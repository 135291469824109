export type RouteValue = (typeof STORE_ROUTES)[keyof typeof STORE_ROUTES];

export const STORE_ROUTES = {
  DEFAULT_ROUTE: "/",
  LOGIN_ROUTE: "/login",
  ON_BOARDING_ROUTE: "/on-boarding",
  ONBOARDING_SHOWCASE_TRACK_ASSETS_ROUTE: "/onboarding-track",
  ONBOARDING_SHOWCASE_TRADE_ROUTE: "/onboarding-trade",
  ONBOARDING_SHOWCASE_TRANSFER_ROUTE: "/onboarding-transfer",
  ON_BOARDING_REFERRAL_CODE_ROUTE: "/on-boarding-referralCode",
  ON_BOARDING_SUCCESS_ROUTE: "/on-boarding-success",
  RECREATE_VAULT_ROUTE: "/recreate-vault",
  NAME_YOUR_VAULT_ROUTE: "/name-your-vault",
  CREATE_PASSWORD_ROUTE: "/create-password",
  TERMS_OF_SERVICE_ROUTE: "/terms-of-service",
  ACCEPT_METRICS_ROUTE: "/accept-metrics",
  ADD_CEX_ROUTE: "/add-cex",
  SET_PERMISSIONS_ROUTE: "/set-permissions",
  ADD_CEX_KEY_ROUTE: "/add-cex-key",
  CHOOSE_STORAGE_ROUTE: "/choose-storage",
  CHOOSE_KEY_ROUTE: "/choose-key",
  API_KEYS_TUTO: "/api-keys-tuto",
  SET_CEX_KEY_ROUTE: "/set-cex-key",
  OAUTH_ROUTE: "/oauth",
  CONNECTED_CEXS_ROUTE: "/connected-cexs",
  EDIT_ASSETS_ROUTE: "/edit-assets",
  VAULT_DETAILS_ROUTE: "/vault-details",
  CONNECTED_DAPPS_ROUTE: "/connected-dapps",
  DELETE_DAPP_ROUTE: "/delete-dapp",
  RECEIVE_ROUTE: "/receive",
  CONFIRM_RECEIVE_ROUTE: "/receive/confirm",
  SEND_ROUTE: "/send",
  CONFIRM_SEND_ROUTE: "/send-confirmation",
  TRADE_ROUTE: "/trade",
  CONFIRM_TRADE_ROUTE: "/trade-confirmation",
  COMPLETED_TRANSACTION_ROUTE: "/completed-transaction",
  COMPLETED_TRANSACTION_DETAILS_ROUTE: "/completed-transaction-details",
  SETTINGS_ROUTE: "/settings",
  SUPPORT_ROUTE: "/support",
  FEEDBACK_ROUTE: "/feedback",
  ASSET_ROUTE: "/asset",
  SECURITY_ROUTE: "/security",
  PRIVACY_POLICY_ROUTE: "/privacy-policy",
  VAULT_SETTINGS_ROUTE: "/vault-settings",
  DELETE_VAULT_ROUTE: "/delete-vault",
  DELETE_ACCOUNT_ROUTE: "/delete-account",
  CHANGE_PASSWORD_ROUTE: "/change-password",
  SECURITY_VERIFICATION_ROUTE: "/security-verification",
  ADDRESS_BOOK_ROUTE: "/address-book",
  ADD_ADDRESS_ROUTE: "/add-address",
  DELETE_ADDRESS_ROUTE: "/delete-address",
  SEND_OAUTH_TUTORIAL_ROUTE: "/send-oauth-tutorial",
  AFFILIATION_ROUTE: "/affiliation",
  API_KEY_TO_OAUTH: "/api-key-to-oauth",
  SECURITY_POINT_ROUTE: "/security-point",
  EDIT_OAUTH_ROUTE: "/edit-oauth",
  EDIT_API_KEYS_PERMISSIONS_ROUTE: "/edit-api-keys-permissions",
  RECONNECT_OAUTH: "/reconnect-oauth",
  UPDATE_PERMISSIONS_TUTORIAL: "/update-permissions-tutorial",
  MULTIPLE_TRANSACTIONS: "/multiple-transaction",
  FIX_INVALID_PERMISSIONS_TUTORIAL: "/fix-invalid-permissions-tutorial",
  STATE_MACHINE_ROUTER: "/state-machine-router",
  POINTS: "/points",

  //Request routes
  REQUEST_CONNECT: "/connect",
  REQUEST_SILENT_CONNECT: "/silentConnect",
  REQUEST_CONNECTED_VAULTS: "/connectVaults",
  REQUEST_WITHDRAW_TO_DEFI: "/withdrawToDefi",
  REQUEST_WITHDRAW_TO_CEFI: "/withdrawTokenToAnotherAccount",
  REQUEST_CREATE_ORDER: "/createOrder",
  REQUEST_EDIT_ORDER: "/editOrder",
  REQUEST_MARKET_ORDER: "/marketOrder",
  REQUEST_CANCEL_ORDER: "/cancelOrder",
  REQUEST_GET_DEPOSIT_ADDRESS: "/getDepositAddress",
  REQUEST_TRANSACTIONS: "/transactions",
  REQUEST_ADD_CEX: "/addCex",
  REQUEST_ADD_CEX_SELECT_VAULT: "/addCexSelectVault",
  REQUEST_ADD_CEX_RESULT: "/addCexResult",
  REQUEST_ONBOARDING: "/connect",
  REQUEST_INSTALLATION_COMPLETE: "/installationComplete",
};

export const WIDGET_ROUTES = {
  LOGIN: "/login",
  DEFAULT_ROUTE: "/",
  ACTION_ROUTE: "/action",
  COMPLETED_TRANSACTION_DETAILS_ROUTE: "/completed-transaction-details",
  FIX_INVALID_PERMISSIONS_TUTORIAL: STORE_ROUTES.FIX_INVALID_PERMISSIONS_TUTORIAL,
  MULTIPLE_TRANSACTIONS: "/multiple-transaction",
  COMPLETED_TRANSACTION_ROUTE: "/completed-transaction",
};

export const TRADE_WIDGET_ROUTES = {
  ...WIDGET_ROUTES,
  ADD_DEFI_ADDRESS: "/add-defi-address",
};
