import { useEffect, useRef, useState } from "react";
import { ValidateFunctionReturnValue } from "../../types/utils";
import { ValidationProps } from "./types";

export const useValidation = ({ validate, value, validateOnMount = true }: ValidationProps) => {
  const [validationStatus, setValidationStatus] = useState<ValidateFunctionReturnValue>();
  const allowValidationRef = useRef<boolean>(validateOnMount);

  const _validate = () => {
    setValidationStatus(validate(value || ""));
  };

  useEffect(() => {
    if (!allowValidationRef.current) {
      allowValidationRef.current = true;
      return;
    }
    _validate();
  }, [value]);

  const cancelValidation = () => {
    setValidationStatus(undefined);
  };

  return { validationStatus, triggerValidate: _validate, cancelValidation };
};
