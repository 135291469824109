import { Breakpoints, PaletteOptions } from "./types";

export const colors = {
  primaryDarkBlue: "#3D4E5B", //Grey Blue
  secondaryDarkBlue: "#247CEC", //Navy blue
  cedePrimaryColor: "#5EA6FF", //Blue
  cedePrimaryColorLight: "#9acfed", // This color is not part of the DS can change at any time
  cedeSecondaryColor: "#A6FB74", //Green
  cedeSuccessColor: "#A6FB74", // Same color as above but this is not a primary color it is a success color
  cedeWarningColor: "#FFA726",
  cedeWarningLightColor: "#E2C395",
  cedeErrorColor: "#f44335",
  cedeSecondaryColorDark: "#0B7400", // This color is not part of the DS can change at any time
  cedePrimaryBackgroundColor: "#0A0B0b", //Black
  cedeTextPrimaryColor: "#FFFFFF", //White
  cedeTextSecondaryColor: "#9B9B9B",
  cedeSecondaryBackgroundColor: "#1B1B1B", //Black
  cedeTextGrey: "rgba(255, 255, 255, 0.5)",
  cedeTextDisabled: "rgba(255, 255, 255, 0.7)",
  cedeSkeleton: "rgba(255, 255, 255, 0.2)",
  cedeComponentHr: "rgba(255, 255, 255, 0.12)",
  cedeComponentBackgroundColor: "rgba(255, 255, 255, 0.08)",
  cedeComponentBorderGreyColor: "#A3A3A3",
  cedeComponentHoveredBackgroundColor: "rgba(255, 255, 255, 0.18)",
  cedePaperBackgroundColor:
    "linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), #121212",
  cedePaperLightBackgroundColor: "#373737",
  cedePaperDarkBackgroundColor: "#1F1F1F",
  cedePaperGradientBackground:
    "linear-gradient(180deg, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.13) 100%), #121212",
  cedePaperModalGradientBackground:
    "linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #121212",
  cedeBubbleBackgroundColor: "#616161E5",
  cedeGradientPrimaryBackground: "linear-gradient(140.84deg, #5EA6FF -14.8%, #A6FB75 127.29%);",
  cedeBuyColor: "#11cb81",
  cedeSellColor: "#f5465d",
  cedeHelpBackgroundColor: "#404040",
};

export const lightColors: typeof colors = {
  ...colors,
  cedePrimaryBackgroundColor: "#FFFEFD",
  cedeTextPrimaryColor: "#1B2026",
  cedeTextSecondaryColor: "#525860",
  cedeHelpBackgroundColor: "#EDEDED",
  cedePaperLightBackgroundColor: "#EDEDED",
  cedePaperDarkBackgroundColor: "#F6F6F6",
  cedePaperBackgroundColor: "#FFFEFD",
  cedeTextDisabled: "rgba(54, 63, 77, 0.5)",
  cedeSkeleton: "rgba(54, 63, 77, 0.2)",
  cedeComponentBackgroundColor: "#F6F6F6",
  cedeSuccessColor: "rgb(91, 188, 33)",
  cedeComponentHr: "#EDEDED",
};

type CustomColors = typeof colors;

const palette = (colors: CustomColors, mode: "dark" | "light" = "dark"): PaletteOptions => ({
  mode,
  background: {
    default: colors.cedePrimaryBackgroundColor,
    paper: colors.cedePaperBackgroundColor,
    lightPaper: colors.cedePaperLightBackgroundColor,
    darkPaper: colors.cedePaperDarkBackgroundColor,
    component: colors.cedeComponentBackgroundColor,
    help: colors.cedeHelpBackgroundColor,
  },
  primary: { main: colors.cedePrimaryColor },
  secondary: { main: colors.cedeSecondaryColor },
  text: {
    primary: colors.cedeTextPrimaryColor,
    secondary: colors.cedeTextSecondaryColor,
    disabled: colors.cedeTextDisabled,
  },
  action: {
    disabledBackground: colors.cedeComponentHr,
    disabled: colors.cedeTextDisabled,
  },
  success: {
    main: colors.cedeSuccessColor,
  },
  warning: {
    main: colors.cedeWarningColor,
    light: colors.cedeWarningLightColor,
  },
  error: {
    main: colors.cedeErrorColor,
  },
  logoBorderColor: colors.cedePrimaryColor,
  ...colors,
});

const custom = {
  zIndex: {
    baseLayer: 1,
    mediumLayer: 5,
    topLayer: 10,
    extraLayer: 15,
  },
  storeSize: {
    width: "370px",
    tabWidth: "450px",
    bigTabWidth: "1265px",
    requestTabWidth: "600px",
    height: "600px",
    tabHeight: "600px",
    bigTabHeight: "800px",
    requestTabHeight: "500px",
    widgetWidth: "385px",
    tradeWidgetHeight: "600px",
    widgetHeight: "565px",
    widgetMinWidth: "330px",
    widgetMaxWidth: "500px",
  },
  navBarSize: {
    height: "50px",
    widgetHeight: "35px",
  },
  defaultBoxRadius: 1,
  largeBoxRadius: 3.5,
  mediumBoxRadius: 2,
  buttonSmallRadius: 3,
  buttonDefaultRadius: 3.5,
  buttonRoundedRadius: 100,
  smallGutterMargin: "8px",
  gutterMargin: "16px",
  mediumGutterMargin: "28px",
  bigGutterMargin: "40px",
  cedeLinkBigContentMargin: "60px",
  cedeLinkSmallContentMargin: "16px",
  cedeLinkBigContentMaxWidth: "1315px",
  inputRadius: {
    default: 4,
    rounded: 5,
  },
  imgSize: {
    small: "32px",
    medium: "56px",
    large: "110px",
  },
  iconSize: {
    small: "16px",
    medium: "20px",
    large: "29px",
  },
  linkLogoWidth: {
    small: "99px",
    medium: "151px",
    large: "230px",
  },
  linkLogoHeight: {
    small: "15px",
    medium: "23px",
    large: "35px",
  },
  inputWidth: {
    small: "256px",
  },
  circleSize: {
    small: "8px",
  },
  opacity: {
    alert: 0.6,
    legend: 0.7,
  },
  textSize: {
    small: "9px",
    medium: "11px",
    large: "13px",
  },
  iconUnderlayMargin: 4,
  portfolioItemWidth: "170px",
  portfolioItemHeight: "60px",
  linkInactiveFilterWidth: "160px",
  linkActiveFilterWidth: "200px",
  txHistoryItem: {
    height: 50,
  },
  txHistoryList: {
    dateLineHeight: 74,
    dateLineMargin: 16,
    txDate: {
      margin: 15,
      height: 35,
    },
    txPlace: {
      margin: 16,
      height: 50,
    },
    txItem: {
      collapsed: 75,
      toggled: 145,
    },
  },
  dappListItemHeight: 55,
  gradientButtonBackground: colors.cedeGradientPrimaryBackground,
};

export const InterFont = "'Inter', sans-serif";

export const typography = (palette: PaletteOptions) => ({
  fontFamily: InterFont,
  h1: {
    fontWeight: "600",
    marginTop: "16px",
    fontSize: "24px",
    color: palette.text?.primary,
  },
  h2: {
    fontWeight: "500",
    fontSize: "20px",
    marginBottom: "15px",
    lineHeight: "18px",
    color: palette.text?.secondary,
  },
  h3: {
    fontSize: "16px",
    color: palette.text?.primary,
  },
  h4: {
    color: palette.text?.primary,
  },
  h5: {
    color: palette.text?.primary,
  },
  subtitle1: {
    color: palette.text?.primary,
  },
  subtitle2: {
    color: palette.text?.secondary,
  },
  body1: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: 400,
    color: palette.text?.primary,
  },
  body2: {
    color: palette.text?.secondary,
    fontSize: "14px",
  },
  caption: {
    fontSize: "11px",
    color: palette.text?.secondary,
  },
});

export const breakpoints: Breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1480,
    xl: 1920,
  },
};

export const shape = {
  borderRadius: 4,
};

export const themeConfig = { palette, custom, typography, breakpoints, shape };
