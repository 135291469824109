import { PricesByTokenSymbol, TokenPrices } from "@cede/types";

/**
 * Converts the prices objects array to a dictionary.
 * @param prices
 */
export const convertToDictionary = (prices: PricesByTokenSymbol) => {
  return Object.keys(prices).reduce(
    (r: TokenPrices, tokenSymbol: string) => ((r[tokenSymbol] = prices[tokenSymbol] || 0), r),
    {},
  );
};

export * from "./candles";
