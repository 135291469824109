import { useRef, useEffect } from "react";
import { useDependencies } from ".";

type UseTriggerLoadingConditionallyProps = {
  isLoading: boolean;
};

/**
 * This hook is used to trigger a loading state conditionally.
 * If isLoading is true, it will add a big pending request to the loading store (only once).
 * If isLoading is false, it will remove the big pending request from the loading store (only once).
 */
export const useTriggerLoadingConditionally = ({ isLoading }: UseTriggerLoadingConditionallyProps) => {
  const { useLoading } = useDependencies();
  const addBigPendingRequest = useLoading((state) => state.addBigPendingRequest);
  const removeBigPendingRequest = useLoading((state) => state.removeBigPendingRequest);
  const hasStarted = useRef(false);
  const hasFinished = useRef(false);

  useEffect(() => {
    if (isLoading && !hasStarted.current) {
      addBigPendingRequest();
      hasStarted.current = true;
    } else if (!isLoading && hasStarted.current && !hasFinished.current) {
      hasFinished.current = true;
      removeBigPendingRequest();
    }
  }, [isLoading]);

  // We need to reset the states if the hook is unmounted
  // while the request is still pending
  useEffect(() => {
    return () => {
      if (hasStarted.current && !hasFinished.current) {
        hasStarted.current = false;
        removeBigPendingRequest();
      }
    };
  }, []);
};
