export const PandaHappy = () => {
  return (
    <svg viewBox="0 0 115 115" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.00779067 27.599C0.00779067 12.3521 12.3668 0 27.605 0H87.4028C102.649 0 115 12.3599 115 27.599V87.4009C115 102.648 102.641 115 87.4028 115H27.4645C12.2966 115 0 102.703 0 87.5336V27.5913L0.00779067 27.599Z"
        fill="url(#paint0_linear_2403_134167)"
      />
      <path
        d="M6.64611 31.0476C6.64611 17.5641 17.5773 6.63214 31.0599 6.63214H83.9604C97.443 6.63214 108.374 17.5641 108.374 31.0476V83.9517C108.374 97.4352 97.443 108.367 83.9604 108.367H30.9351C17.5149 108.367 6.6383 97.4898 6.6383 84.0687V31.0398L6.64611 31.0476Z"
        fill="black"
      />
      <path
        d="M13.2766 34.4968C13.2766 22.769 22.78 13.265 34.507 13.265H80.5101C92.2372 13.265 101.741 22.769 101.741 34.4968V80.5031C101.741 92.231 92.2372 101.735 80.5101 101.735H34.4056C22.741 101.735 13.2844 92.2778 13.2844 80.6045V34.4968H13.2766Z"
        fill="white"
      />
      <path
        d="M47.815 61.914C52.1457 54.4124 50.9067 45.5889 45.0477 42.2059C39.1887 38.823 30.9284 42.1618 26.5977 49.6633C22.267 57.1648 23.5059 65.9884 29.3649 69.3713C35.2239 72.7542 43.4843 69.4155 47.815 61.914Z"
        fill="black"
      />
      <path
        d="M85.8952 69.4338C91.7542 66.0508 92.9931 57.2273 88.6624 49.7258C84.3317 42.2243 76.0713 38.8855 70.2123 42.2684C64.3533 45.6513 63.1144 54.4749 67.4451 61.9764C71.7758 69.4779 80.0362 72.8167 85.8952 69.4338Z"
        fill="black"
      />
      <path
        d="M72.0841 50C74.6308 50 76.6953 53.15 76.6953 50.6031C76.6953 48.0562 74.6308 45.9915 72.0841 45.9915C69.5374 45.9915 67.4729 48.0562 67.4729 50.6031C67.4729 53.15 69.5374 50 72.0841 50Z"
        fill="white"
      />
      <path
        d="M57.629 74.6895C57.629 74.6895 54.3363 74.5569 53.1582 75.3996C51.98 76.2423 52.058 77.5454 52.058 77.5454C52.058 77.5454 51.902 78.8797 52.2921 79.7458C52.6822 80.612 54.9683 80.4637 56.0919 82.3911C56.0919 82.3911 57.1218 82.7422 57.629 82.6408"
        fill="black"
      />
      <path
        d="M57.629 74.6895C57.629 74.6895 60.9217 74.5567 62.0999 75.3994C63.2781 76.2421 63.2001 77.5452 63.2001 77.5452C63.2001 77.5452 63.3561 78.8795 62.966 79.7457C62.5759 80.6118 60.2897 80.4635 59.1662 82.3909C59.1662 82.3909 58.1361 82.7422 57.629 82.6408"
        fill="black"
      />
      <path
        d="M42.912 50.0002C45.4587 50.0002 47.5232 53.1502 47.5232 50.6033C47.5232 48.0564 45.4587 45.9917 42.912 45.9917C40.3653 45.9917 38.3008 48.0564 38.3008 50.6033C38.3008 53.1502 40.3653 50.0002 42.912 50.0002Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2403_134167"
          x1="57.5"
          y1="0"
          x2="57.5"
          y2="128.642"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" stopOpacity="0.4" />
        </linearGradient>
      </defs>
    </svg>
  );
};
