import { CEDE_CDN_URL, resolveCDNMiss } from "@cede/utils";
import { theme } from "../ThemeProvider";
import { IconProps } from "./types";
import { Box } from "@mui/material";

export const Icon = ({
  iconUrl,
  iconName,
  defaultIcon,
  iconSize = "medium",
  disabled = false,
  iconChildUrl,
  customSize,
  customChildIconPosition,
  testId,
  ...props
}: IconProps) => {
  const size = () => {
    if (customSize) return customSize;
    switch (iconSize) {
      case "small":
        return theme.custom.iconSize.small;
      case "medium":
        return theme.custom.iconSize.medium;
      case "large":
        return theme.custom.iconSize.large;
      default:
        return theme.custom.iconSize.medium;
    }
  };

  const positionChildIcon = () => {
    if (customChildIconPosition) return customChildIconPosition;
    switch (iconSize) {
      case "small":
        return "9px";
      case "medium":
        return "12px";
      case "large":
        return "18px";
      default:
        return "12px";
    }
  };

  return (
    <Box
      component="div"
      sx={{
        position: "relative",
        display: "inline-flex",
        ...props.sx,
      }}
      data-testid={testId}
    >
      <Box
        component="img"
        src={iconUrl}
        alt={iconName + "-icon"}
        sx={{
          height: size,
          width: size,
          filter: disabled ? "grayscale(0.9)" : "grayscale(0)",
          display: "inline-flex",
        }}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          if (defaultIcon) currentTarget.src = defaultIcon;

          const url = new URL(currentTarget.src);
          const isCDNIcon = url.origin === CEDE_CDN_URL;
          if (isCDNIcon) {
            currentTarget.src = resolveCDNMiss(url);
          } else currentTarget.src = "";
        }}
      />
      {iconChildUrl && (
        <Box
          component="div"
          sx={{
            bgcolor: theme.palette.background.default,
            padding: "2px",
            borderRadius: "10px",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: positionChildIcon,
            left: positionChildIcon,
          }}
        >
          <Box
            component="img"
            src={iconChildUrl}
            alt={iconName + "-child-icon"}
            sx={{
              height: "10px",
              width: "10px",
              filter: disabled ? "grayscale(0.9)" : "grayscale(0)",
            }}
          />
        </Box>
      )}
    </Box>
  );
};
