import { useEffect, useRef, useState } from "react";
import { useDependencies } from "../../../hooks";
import { UseWhitelistAddressTutorialsHook } from "./types";

export const useWhitelistAddressTutorials: UseWhitelistAddressTutorialsHook = ({
  onClose,
  exchangeId,
  checkWhitelist,
}) => {
  const { useExtensionAsset, useSupportedExchanges } = useDependencies();
  const { supportedExchangesById } = useSupportedExchanges();
  const [currentPage, setCurrentPage] = useState(0);
  const [status, setStatus] = useState<"waiting" | "success" | "failed">("waiting" as const);
  const [maxPage, setMaxPage] = useState(0);
  const currentPageRef = useRef(currentPage);
  const { asset: mdContent } = useExtensionAsset({
    path: `tutorials/whitelist/${exchangeId}/${exchangeId}.md`,
    onAssetNotFound: () => {
      onClose();
      setCurrentPage(0);
    },
  });

  const onNext = () => {
    if (currentPageRef.current === maxPage) {
      onClose();
      setCurrentPage(0);
    } else {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const goBack = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const checkAddress = () => {
    if (!checkWhitelist) return;
    setStatus("waiting" as const);
    checkWhitelist().then((res) => {
      if (res) {
        setStatus("success" as const);
      } else {
        setStatus("failed" as const);
      }
    });
  };

  useEffect(() => {
    checkAddress();
  }, []);

  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  return {
    goBack,
    tutorialType: "whitelist/" + exchangeId,
    currentPage,
    ipWhitelistAddresses: supportedExchangesById[exchangeId]?.ipWhitelistAddresses ?? [],
    onNext,
    mdContent: mdContent ?? "",
    maxPage,
    setMaxPage,
    setCurrentPage,
    status,
    checkAddress,
  };
};
