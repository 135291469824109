import { SdkErrorCodes, Transaction, ViewEnvironment, isCefiInfoTx } from "@cede/types";
import { CEDE_CEX_LABELS, STORE_ROUTES, TweetDataKey, WIDGET_ROUTES, tweets } from "@cede/utils";
import { useDependencies } from "../../hooks/useDependencies";

export const useCompletedTransaction = () => {
  const {
    useNavigate,
    useSendStore,
    useSupportedExchanges,
    useViewEnvironment,
    useTriggerUpdateTransaction,
    useSelectedTransaction,
    useShareOnTwitter,
  } = useDependencies();
  const navigate = useNavigate();
  const { environment } = useViewEnvironment();
  const { isSupportedExchangesLoading, supportedExchangesById } = useSupportedExchanges();
  const { reset, dispatchPrefill, setTutorialIsOpen } = useSendStore();
  const { error, resetError, errorCode } = useSelectedTransaction((state) => ({
    error: state.error,
    resetError: state.resetError,
    errorCode: state.errorCode,
  }));

  const transaction: Transaction | null = useTriggerUpdateTransaction();

  const { networkName, partnerName, share } = useShareOnTwitter(transaction);

  const onTransactionDetails = () => {
    navigate(WIDGET_ROUTES.COMPLETED_TRANSACTION_DETAILS_ROUTE);
  };

  const goToSend = () => {
    if (environment === ViewEnvironment.WIDGET) {
      if (!error) {
        reset({ keepInitialProps: true });
        // We want to reactivate the prefill for the widget
        dispatchPrefill({
          tokenSymbol: true,
          network: true,
          address: true,
          amount: true,
        });
      }

      if (error && errorCode === SdkErrorCodes.EXCHANGE_INVALID_PERMISSION) {
        navigate(WIDGET_ROUTES.FIX_INVALID_PERMISSIONS_TUTORIAL);
      } else {
        if (errorCode === SdkErrorCodes.WHITELIST_CHECK_FAILED) {
          setTutorialIsOpen(true);
        }
        resetError();
        navigate(WIDGET_ROUTES.ACTION_ROUTE);
      }

      return;
    }

    reset();

    if (error && errorCode === SdkErrorCodes.EXCHANGE_INVALID_PERMISSION) {
      navigate(STORE_ROUTES.FIX_INVALID_PERMISSIONS_TUTORIAL);
    } else {
      resetError();
      navigate(STORE_ROUTES.DEFAULT_ROUTE);
    }

    return;
  };

  const copy = () => {
    navigator.clipboard.writeText(
      (transaction?.to.account && "address" in transaction.to.account && transaction?.to.account.address) || "",
    );
  };

  const shareOnTwitter = () => {
    const from = transaction?.from;
    if (!from || !isCefiInfoTx(from)) return;
    const placeHolders: Record<string, string> =
      transaction.type === "order"
        ? {
            [TweetDataKey.CEX_LABEL]: CEDE_CEX_LABELS[from.account.exchangeId] ?? "",
          }
        : {
            [TweetDataKey.CEX_LABEL]: CEDE_CEX_LABELS[from.account.exchangeId] ?? "",
            [TweetDataKey.NETWORK]: networkName ?? transaction.to.account.network ?? "",
            [TweetDataKey.PARTNER]: partnerName ?? "",
          };
    const content =
      transaction.type === "order"
        ? tweets.TRADE
        : partnerName
        ? tweets.SEND_WITH_PARTNER
        : tweets.SEND_WITHOUT_PARTNER;
    share(content, placeHolders);
  };

  const isRequiringEmailConfirmation =
    transaction?.from.account &&
    isCefiInfoTx(transaction.from) &&
    !!supportedExchangesById[transaction?.from.account.exchangeId]?.isRequiringEmailConfirmation;

  return {
    transaction,
    onTransactionDetails,
    goTo: goToSend,
    copy,
    isRequiringEmailConfirmation,
    isSupportedExchangesLoading,
    error,
    errorCode,
    networkName,
    partnerName,
    shareOnTwitter,
  };
};
