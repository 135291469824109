import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { Bubble } from "../Popups/Bubble";
import { CopyCardProps } from "./types";
import { Card, CardActionArea, Typography, useTheme } from "@mui/material";

export const CopyCard = ({ title, content, onCopy, testId = "" }: CopyCardProps) => {
  const theme = useTheme();
  const [isCopied, setIsCopied] = useState(false);
  const anchor = useRef<HTMLDivElement>(null);

  const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onCopy();
    setIsCopied(true);
  };

  useEffect(() => {
    const onClickBody = () => {
      setIsCopied(false);
    };
    document.body.addEventListener("click", onClickBody);

    return () => document.body.removeEventListener("click", onClickBody);
  }, []);

  return (
    <>
      {content && (
        <>
          <Card
            data-testid={testId}
            ref={anchor}
            sx={{
              width: "100%",
              backgroundImage: "none",
              backgroundColor: theme.palette.background.darkPaper,
              borderRadius: theme.custom.largeBoxRadius,
            }}
          >
            <CardActionArea
              data-testid={`${testId}-action`}
              onClick={onClick}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                paddingY: theme.custom.smallGutterMargin,
                paddingX: theme.custom.gutterMargin,
              }}
            >
              <Typography variant="caption" marginBottom={theme.custom.smallGutterMargin}>
                {title}
              </Typography>
              <Typography variant="body1" sx={{ wordBreak: "break-all", whiteSpace: "pre-line", textAlign: "start" }}>
                {content}
              </Typography>
            </CardActionArea>
          </Card>
          <Bubble anchor={anchor.current} isOpen={isCopied} sx={{ zIndex: 999 }}>
            <Typography variant="caption" color={theme.palette.text.primary}>
              Copied!
            </Typography>
          </Bubble>
        </>
      )}
    </>
  );
};
